import subjectsService from 'services/subjects.service';
import types from '../actionTypes';

export const filterSubjects = (filter, page, size) => (dispatch, getState) => {
    if (getState().subjectsData.isFetchingAllSubjectsList) {
        return;
    }

    dispatch({
        type: types.SUBJECTS_FETCH_REQUEST,
    });

    return subjectsService.getAllSubjectsParam(filter, page, size)
        .then((subjects) => {
            dispatch({
                type: types.SUBJECTS_PARAM_FETCH_SUCCESS,
                payload: { subjects },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SUBJECTS_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const createSubject = (subject) => (dispatch, getState) => {
    if (getState().subjectsData.isCreating) {
        return;
    }

    dispatch({
        type: types.SUBJECT_CREATE_REQUEST,
    });

    return subjectsService.createSubject(subject)
        .then((subjectNew) => {
            dispatch({
                type: types.SUBJECT_CREATE_SUCCESS,
                payload: { subjectNew },
            });

            return subjectNew;
        })
        .catch((error) => {
            dispatch({
                type: types.SUBJECT_CREATE_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const updateSubject = (subject) => (dispatch, getState) => {
    if (getState().subjectsData.isUpdating) {
        return;
    }

    dispatch({
        type: types.SUBJECT_UPDATE_REQUEST,
    });

    return subjectsService.updateSubject(subject)
        .then(() => {
            dispatch({
                type: types.SUBJECT_UPDATE_SUCCESS,
                payload: { subject },
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SUBJECT_UPDATE_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchAllSubjects = () => (dispatch, getState) => {
    if (getState().subjectsData.isFetchingAllSubjectsList) {
        return Promise.reject();
    }

    dispatch({
        type: types.SUBJECTS_FETCH_REQUEST,
    });

    return subjectsService.getAllSubjects()
        .then((subjects) => {
            dispatch({
                type: types.SUBJECTS_FETCH_SUCCESS,
                payload: { subjects },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SUBJECTS_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
