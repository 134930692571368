import httpService from './http.service';

const getCardPayment = (cardNumber) => httpService
  .get(`/cardpayment/card/${cardNumber}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getCardPayment,
};
