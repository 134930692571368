import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Add from "@material-ui/icons/Add";
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ProductMenus from "./ProductTypeMenu";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 1, 2),
    },
    toolbarInner1: {
        width: '100%',
        border: '1px solid #E0E0E0',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: theme.palette.shadow.main,
        padding: theme.spacing(2, 1, 1, 2),

    },
}));

function ToolBar(props) {
    const classes = useStyles();

    const {
        validities,
        parkings,
        productTypes,
        branches,
        selectedBranch,
        selectedProductType,
        selectedParking,
        selectedValidity,
        selectedName,
        handleSelectBranch,
        handleSelectValidity,
        handleSelectParking,
        handleSelectType,
        handleSelectedName,
        userUseCases,
        handleProductDetail,
    } = props;

    const {t} = useTranslation();


    return (
        <div className={classes.toolbar}>
            <div className={classes.toolbarInner1}>

                <form autoComplete='off'>
                    <Box mb={2} height="100%" width="100%">
                        <Grid container spacing={2} style={{height: '100%', width: '100%'}}>

                            <Grid item sm={2}>

                                <FormControl fullWidth>
                                    <InputLabel id="parking-label">
                                        {t('VALIDITY')}
                                    </InputLabel>
                                    <Select
                                        autoWidth
                                        value={selectedValidity}
                                        id="selectedValidity"
                                        onChange={handleSelectValidity}
                                    >
                                        {validities.map(a => {
                                            return (
                                                <MenuItem value={a.value}>{a.label}</MenuItem>
                                            )
                                        })
                                        }
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item sm={2}>

                                <Autocomplete
                                    multiple
                                    limitTags={3}
                                    value={selectedProductType}
                                    onChange={(event, newValue) => {
                                        handleSelectType(newValue);
                                    }}
                                    filterSelectedOptions
                                    id="productTypes"
                                    options={productTypes || []}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('PRODUCT_TYPE')} fullWidth
                                        />
                                    )}
                                    size="small"

                                />

                            </Grid>
                            <Grid item sm={2}>
                                <Autocomplete
                                    multiple
                                    limitTags={3}
                                    value={selectedParking}
                                    onChange={(event, newValue) => {
                                        handleSelectParking(newValue);
                                    }}
                                    filterSelectedOptions
                                    id="parkings"
                                    options={parkings || []}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('PARKING')} fullWidth
                                        />
                                    )}
                                    size="small"

                                />
                            </Grid>
                            <Grid item sm={2}>
                                <TextField
                                    label={t('NAME')}
                                    fullWidth
                                    value={selectedName || ''}
                                    onChange={handleSelectedName}
                                />

                            </Grid>
                            <Grid item sm={2}>

                                <TextField
                                    label={t('BRANCH')}
                                    fullWidth
                                    value={selectedBranch || ''}
                                    onChange={handleSelectBranch}
                                />

                            </Grid>
                            <Grid item sm={2}>
                                <ProductMenus userUseCases={userUseCases} handleProductDetail={handleProductDetail}/>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </div>
        </div>

    )
        ;
}

ToolBar.propTypes = {
    handleSelectParking: PropTypes.func.isRequired,
    handleSelectType: PropTypes.func.isRequired,
    handleSelectValidity: PropTypes.func.isRequired,
    handleSelectBranch: PropTypes.func.isRequired,
};

ToolBar.defaultProps = {};

const UC_PRODUCT_CREATE = 'UC0062';

export default ToolBar;
