import downloadService from 'services/download.service';
import types from '../actionTypes';

export const fetchDownloadList = () => (dispatch, getState) => {
  if (getState().downloadData.isFetchingDownloadList) {
    return Promise.reject();
  }

  dispatch({
    type: types.PARKING_DOWNLOAD_FETCH_REQUEST,
  });

  return downloadService.getDownloadAll()
    .then((downloadList) => {
      dispatch({
        type: types.PARKING_DOWNLOAD_FETCH_SUCCESS,
        payload: { downloadList },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.PARKING_DOWNLOAD_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const fetchDownloadFile = (id, onDownloadProgress) => (dispatch, getState) => {
  if (getState().downloadData.isFetchingDownloadFile) {
    return Promise.reject();
  }
  dispatch({
    type: types.PARKING_DOWNLOAD_FILE_FETCH_REQUEST,
  });

  return downloadService.getDownloadFile(id, onDownloadProgress)
    .then((downloadFile) => {
      dispatch({
        type: types.PARKING_DOWNLOAD_FILE_FETCH_SUCCESS,
        payload: { downloadFile },
      });

      return downloadFile;
    })
    .catch((error) => {
      dispatch({
        type: types.PARKING_DOWNLOAD_FILE_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};
