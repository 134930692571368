import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {activateCard, blockCard} from 'redux/actions/cards';
import PMCDialog from "../common/PMCDialog";

const useStyles = makeStyles((theme) => ({
    labelText: {
        width: '100%',
        color: '#A2A2A2',
        textAlign: 'center'
    },
}));

function ConfirmCard(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        event,
        opened,
        type,
        handleClose,
        blockCard,
        activateCard,
    } = props;


    const handleSubmit = () => {
        if (type === 'activate') {
            activateCard(event.cardNumber, event.parkingId, true)
                .then(handleClose);
        } else if (type === 'deactivate') {
            activateCard(event.cardNumber, event.parkingId, false)
                .then(handleClose);
        } else {
            blockCard(event.cardNumber, event.parkingId, false)
                .then(handleClose);
        }
    };

    let title = 'UNBLOCK_CARD';
    let title2 = 'UNBLOCK2';
    if (type === 'activate') {
        title = 'ACTIVATE_CARD';
        title2 = 'ACTIVATE2';
    } else if (type === 'deactivate') {
        title = 'DEACTIVATE_CARD';
        title2 = 'DEACTIVATE2';
    }

    return (
        <PMCDialog
            size="xs"
            height={250}
            headerColor={event.type === 'ST' ? '#50A933' : '#5985EE'}
            actionType="confirmModal"
            open={opened}
            title={`${t(title)} - ${event.cardNumber}`}
            onClose={handleClose}
            onSubmit={handleSubmit}
        >

            <Typography className={classes.labelText}>
                {t('CONFIRM_DEACTIVE_CARD', {type: t(title2)})}
            </Typography>
        </PMCDialog>

    );
}

ConfirmCard.propTypes = {
    event: PropTypes.object.isRequired,
    opened: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    blockCard: PropTypes.func.isRequired,
    activateCard: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    blockCard,
    activateCard,
}, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(ConfirmCard);
