import zonesService from 'services/zones.service';
import types from '../actionTypes';

export const fetchParkingZones = (parkingId) => (dispatch, getState) => {
  if (getState().zonesData.isFetchingZones) {
    return Promise.reject();
  }

  dispatch({
    type: types.PARKING_ZONES_FETCH_REQUEST,
  });

  const { parkings } = getState().parkingsData;
  const parking = parkings.find((p) => p.parkingId === parkingId);
  const parkingZones = parking.zoneStatus;

  if (parkingZones.length > 0) {
    parkingZones.sort((a, b) => {
      const nameA = a.zoneName.toUpperCase();
      const nameB = b.zoneName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      return (nameA > nameB) ? 1 : 0;
    });
  }

  dispatch({
    type: types.PARKING_ZONES_FETCH_SUCCESS,
    payload: { parkingZones },
  });

  return true;
};

export const updateZone = (zone) => (dispatch, getState) => {
  if (getState().zonesData.isUpdating) {
    return;
  }

  dispatch({
    type: types.ZONE_UPDATE_REQUEST,
  });

  return zonesService.updateZone(zone)
    .then(() => {
      const { parkingZones } = getState().zonesData;
      const changedIndex = parkingZones.findIndex((z) => z.zoneId === zone.zoneId);
      parkingZones[changedIndex] = zone;

      dispatch({
        type: types.ZONE_UPDATE_SUCCESS,
        payload: {
          parkingZones,
        },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.ZONE_UPDATE_FAIL,
        payload: { error },
      });

      throw error;
    });
};
