import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingList: false,
    isChangingCurrency: false,
    isPaying: false,
    isCanceling: false,
    isExecutingProduct: false,
    fiscalizedReceipts: [],

    receipts: [],
    receipt: {},
/*       receipt: {
            "receiptId": 30,
            "currency": "CZK",
            "branchId": 1,
            "branchName": "Pobocka Kbely",
            "branchAddress": "Mladoboleslavska 2131, Praha 22",
            "userId": "1687db85-25ff-4717-86fe-4314fbf89102",
            "userFirstName": "John",
            "userSurname": "Test",
            "number": null,
            "date": "23.12.2020 10:20",
            "dateTS": 1608715237948,
            "type": "R_COMMON",
            "priceWithVAT": 610.0,
            "createdBy": "John Test",
            "state": "NEW",
            "notice": null,
            "copyCount": 0,
            "pkp": null,
            "bkp": null,
            "fik": null,
            "company": null,
            "address": null,
            "companyId": null,
            "vatNumber": null,
            "contact": null,
            "phoneNumber": null,
            "email": null,
            "note": null,
            "paymentType": null,
            "paymentCurrency": "",
            "paymentCurrencyId": -1,
            "pos": "pos001",
            "posId": 3,
            "receiptItems": [
                {
                    "receiptItemId": 70,
                    "receiptItemName": "Tisk duplikatu karty",
                    "type": null,
                    "vatCharge": "10.0",
                    "priceWithVAT": 10.0,
                    "count": 1,
                    "description": null,
                    "notice": null,
                    "discountName": null,
                    "receiptId": 30,
                    "runningProductId": 143,
                    "receiptItemCardId": 11,
                    "receiptItemDiscountId": -1,
                    "receiptItemValueId": -1,
                    "receiptItemVoucherId": -1,
                    "runningProduct": null,
                    "receiptItemCard": {
                        "receiptItemCardId": 11,
                        "cardFrom": "1961726677471",
                        "cardTo": "1961726677471",
                        "validFrom": "05.06.2019 10:27",
                        "validFromTS": 1559723246000,
                        "validTo": "05.07.2019 10:27",
                        "validToTS": 1562315246000,
                        "paidFrom": null,
                        "paidFromTS": 0,
                        "paidTo": null,
                        "paidToTS": 0,
                        "cardNotice": null,
                        "licencePlate": null,
                        "parkingAccess": {
                            "parkingAcccesId": 145,
                            "gmtpId": 2,
                            "parkingAccessName": "Shortterm parking",
                            "visible": true
                        }
                    },
                    "receiptItemDiscount": null,
                    "receiptItemValue": null,
                    "receiptItemVoucher": null
                },
                {
                    "receiptItemId": 71,
                    "receiptItemName": "Změna přístupu karty",
                    "type": null,
                    "vatCharge": "20.0",
                    "priceWithVAT": 300.0,
                    "count": 1,
                    "description": null,
                    "notice": "JN test",
                    "discountName": null,
                    "receiptId": 30,
                    "runningProductId": 145,
                    "receiptItemCardId": 12,
                    "receiptItemDiscountId": -1,
                    "receiptItemValueId": -1,
                    "receiptItemVoucherId": -1,
                    "runningProduct": null,
                    "receiptItemCard": {
                        "receiptItemCardId": 12,
                        "cardFrom": "1961726677471",
                        "cardTo": "1961726677470",
                        "validFrom": "05.06.2019 10:27",
                        "validFromTS": 1559723246000,
                        "validTo": "05.07.2019 10:27",
                        "validToTS": 1562315246000,
                        "paidFrom": null,
                        "paidFromTS": 0,
                        "paidTo": null,
                        "paidToTS": 0,
                        "cardNotice": null,
                        "licencePlate": null,
                        "parkingAccess": {
                            "parkingAcccesId": 146,
                            "gmtpId": 1,
                            "parkingAccessName": "Longterm parking",
                            "visible": true
                        }
                    },
                    "receiptItemDiscount": null,
                    "receiptItemValue": null,
                    "receiptItemVoucher": null
                },
                {
                    "receiptItemId": 73,
                    "receiptItemName": "Platba za prujezd",
                    "type": null,
                    "vatCharge": "20.0",
                    "priceWithVAT": 300.0,
                    "count": 1,
                    "description": "Dodatečný popis, rate: Charging",
                    "notice": null,
                    "discountName": null,
                    "receiptId": 30,
                    "runningProductId": 149,
                    "receiptItemCardId": 14,
                    "receiptItemDiscountId": -1,
                    "receiptItemValueId": -1,
                    "receiptItemVoucherId": -1,
                    "runningProduct": null,
                    "receiptItemCard": {
                        "receiptItemCardId": 14,
                        "cardFrom": "1961726677471",
                        "cardTo": "1961726677471",
                        "validFrom": "05.06.2019 10:27",
                        "validFromTS": 1559723246000,
                        "validTo": "05.07.2019 10:27",
                        "validToTS": 1562315246000,
                        "paidFrom": null,
                        "paidFromTS": 0,
                        "paidTo": null,
                        "paidToTS": 0,
                        "cardNotice": null,
                        "licencePlate": null,
                        "parkingAccess": {
                            "parkingAcccesId": 145,
                            "gmtpId": 2,
                            "parkingAccessName": "Shortterm parking",
                            "visible": true
                        }
                    },
                    "receiptItemDiscount": null,
                    "receiptItemValue": null,
                    "receiptItemVoucher": null
                }
            ]
        },*/
    paymentError: {},
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RECEIPTS_LIST_REQUEST:
            return {
                ...state,
                isFetchingList: true,
            };
        case types.RECEIPTS_FISCALIZED_LIST_SUCCESS:
            return {
                ...state,
                isFetchingList: false,
                fiscalizedReceipts: action.payload,
            };
        case types.RECEIPTS_FISCALIZED_LIST_FAIL:
            return {
                ...state,
                isFetchingList: false,
                errors: action.payload.error,
            };
        case types.RECEIPTS_LIST_SUCCESS:
            return {
                ...state,
                isFetchingList: false,
                receipts: action.payload,
            };
        case types.RECEIPTS_LIST_FAIL:
            return {
                ...state,
                isFetchingList: false,
                errors: action.payload.error,
            };
        case types.RECEIPT_CANCEL_REQUEST:
            return {
                ...state,
                isCanceling: true,
            };
        case types.RECEIPT_CANCEL_SUCCESS:
            return {
                ...state,
                isCanceling: false,
                receipt: {},
            };
        case types.RECEIPT_CANCEL_FAIL:
            return {
                ...state,
                isCanceling: false,
                errors: action.payload.error,
            };
        case types.RECEIPT_PAY_REQUEST:
            return {
                ...state,
                isPaying: true,
            };
        case types.RECEIPT_PAY_SUCCESS:
            return {
                ...state,
                isPaying: false,
                paymentError: action.payload.paymentError,
                receipt: action.payload.receipt,
            };
        case types.RECEIPT_PAY_FAIL:
            return {
                ...state,
                isPaying: false,
                errors: action.payload.error,
            };
        case types.RECEIPT_PAY_CARD_REQUEST:
            return {
                ...state,
                isPaying: true,
            };
        case types.RECEIPT_PAY_CARD_SUCCESS:
            return {
                ...state,
                isPaying: false,
                paymentError: action.payload.paymentError,
                receipt: action.payload.receipt,
            };
        case types.RECEIPT_PAY_CARD_FAIL:
            return {
                ...state,
                isPaying: false,
                errors: action.payload.error,
            };
        case types.PRODUCT_EXECUTE_REQUEST:
            return {
                ...state,
                isExecutingProduct: true,
            };
        case types.PRODUCT_EXECUTE_SUCCESS:
            return {
                ...state,
                isExecutingProduct: false,
                receipt: action.payload.receipt,
            };
        case types.PRODUCT_EXECUTE_FAIL:
            return {
                ...state,
                isExecutingProduct: false,
                errors: action.payload.error,
            };
        case types.RECEIPT_CHANGE_CURRENCY_REQUEST:
            return {
                ...state,
                isChangingCurrency: true,
            };
        case types.RECEIPT_CHANGE_CURRENCY_SUCCESS:
            return {
                ...state,
                isChangingCurrency: false,
                receipt: action.payload.receipt,
            };
        case types.RECEIPT_CHANGE_CURRENCY_FAIL:
            return {
                ...state,
                isChangingCurrency: false,
                errors: action.payload.error,
            };
        default:
            return state;
    }
};
