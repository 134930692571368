import httpService from './http.service';

const getParkingErrors = (parkingId) => httpService
  .get(`/hosterror/agg/${parkingId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getParkingErrors,
};
