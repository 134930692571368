import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingAllBranchesList : false,
  branches: [],
  branchesParam: [],
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.BRANCHES_FETCH_REQUEST:
      return {
        ...state,
        isFetchingAllBranchesList: true,
      };
    case types.BRANCHES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAllBranchesList: false,
        branches: action.payload.branches,
      };
    case types.BRANCHES_PARAM_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAllBranchesList: false,
        branchesParam: action.payload.branches,
      };
    case types.BRANCHES_FETCH_FAIL:
      return {
        ...state,
        isFetchingAllBranchesList: false,
        errors: action.payload.error,
      };
    default:
      return state;
  }
};
