import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import RGL, {WidthProvider} from "react-grid-layout";
import {
    getHostLayout,
    getGridSize,
    hasStream,
    isComponentEnabledForUser,
    isComponentVisibleForUser,
    SIP_STATUS
} from 'utils';
import {getIcon} from "../../../containers/Parking/LocalParking/LocalParkingHosts/Utils";
import {ReactSortable} from "react-sortablejs";
import {Sortable, Swap} from "sortablejs"
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {CustomSwitch, Loading} from "../../elements";
import {ActionButton} from "../../common";
import TextField from "@material-ui/core/TextField";
import {fetchParkingHosts, updateHost, updateHostsPosition} from "../../../redux/actions/hosts";
import VideocamIcon from '@material-ui/icons/Videocam';
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from '@material-ui/icons/Phone';
import HostStream from "../../common/HostStream";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from '@material-ui/core/Slider';

Sortable.mount(new Swap());


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    icons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    hostTooltipEmpty: {
        width: props => `calc(${100 / props.columnI}% - 10px)`,
        height: 170,
        flexGrow: 1,
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.base.white,
        borderStyle: 'solid',
        borderWidth: '2px',
        // borderColor: theme.palette.base.white,
        cursor: 'pointer',
    },
    hostTooltipCard: {
        height: 170,
        width: props => `calc(${100 / props.columnI}% - 10px)`,
        flexGrow: 1,
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.base.white,
        borderStyle: 'solid',
        borderWidth: '2px',
        // borderColor: theme.palette.base.white,
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent:'space-between'

    },
    hostTooltipCardGreen: {
        borderColor: theme.palette.icon.green,
    },
    hostTooltipCardRed: {
        borderColor: theme.palette.icon.red,
    },
    hostTooltipCardYellow: {
        borderColor: theme.palette.icon.yellow,
    },
    image: {
        maxHeight: '80%',
        maxWidth: '80%',

    },
    hostTooltipCardActive: {
        borderColor: theme.palette.secondary.main,
        opacity: '1',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    },
    shortName: {
        height: 24,
        fontSize: 16,
        marginLeft: 3,
        marginRight: 3,
        width: '100%',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    table: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        padding: theme.spacing(1, 1, 1, 1),
        justifyContent: 'space-between',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        padding: theme.spacing(2, 2, 2, 2),
        width: '100%',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    videoOn: {
        color: theme.palette.secondary.main,
    },
    phoneOn: {
        color: theme.palette.primary.main,
    },
    showOff: {
        color: theme.palette.base[400],
    },

}))


const HostDetail = ({host, handleChange, handleNumberChange}) => {

    const [error, setError] = useState({});

    const {t} = useTranslation();
    const classes = useStyles();


    return (
        <form autoComplete='off'>
            <Grid container>
                <Grid container spacing={1} style={{width: '100%'}}>
                    <Grid item xs sm={12}>
                        <TextField
                            helperText={error && error.hostName
                                ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                : null}
                            fullWidth
                            required
                            onChange={handleChange('hostName')}
                            value={host.hostName || ''}
                            label={t('NAME')}/>
                    </Grid>
                    <Grid item xs sm={8}>
                        <TextField
                            helperText={error && error.hostShortName
                                ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                : null}
                            fullWidth
                            required
                            onChange={handleChange('hostShortName')}
                            value={host.hostShortName || ''}
                            label={t('SHORT_NAME')}/>
                    </Grid>
                    <Grid item xs sm={4}>
                        <FormControlLabel
                            control={<CustomSwitch
                                checked={host.show}
                                color="secondary"
                                handleSwitch={handleChange('show')}/>
                            }
                            label={<span
                                style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}>{t('VISIBLE')}</span>}
                            labelPlacement="top"
                        />

                    </Grid>
                    <Grid item xs sm={6}>
                        <TextField
                            helperText={error && error.hostName
                                ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                : null}
                            fullWidth
                            type="number"
                            required
                            onChange={handleNumberChange('hapAddress')}
                            value={host.hapAddress || ''}
                            label={t('HAP_ADDRESS')}/>
                    </Grid>
                    <Grid item xs sm={6}>
                        <TextField
                            helperText={error && error.hostShortName
                                ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                : null}
                            fullWidth
                            type="number"
                            required
                            onChange={handleNumberChange('enumTimeout')}
                            value={host.enumTimeout || ''}
                            label={t('ENUM_TIMEOUT')}/>
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{width: '100%'}}>
                    <Grid item xs sm={6}>
                        <TextField
                            fullWidth
                            onChange={handleChange('sipPhoneNumber')}
                            value={host.sipPhoneNumber || ''}
                            label={t('SIP_PHONE')}/>
                    </Grid>
                    <Grid item xs sm={6}>
                        <TextField
                            fullWidth
                            onChange={handleChange('sipUserName')}
                            value={host.sipUserName || ''}
                            label={t('SIP_USERNAME')}/>
                    </Grid>
                    <Grid item xs sm={12}>
                        <TextField
                            type="password"
                            fullWidth
                            onChange={handleChange('sipPassword')}
                            value={host.sipPassword || ''}
                            label={t('SIP_PASSWORD')}/>
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{width: '100%'}}>
                    <Grid item xs sm={12}>
                        <TextField
                            fullWidth
                            onChange={handleChange('cameraUrl')}
                            value={host.cameraUrl || ''}
                            label={t('CAMERA_URL')}/>
                    </Grid>
                    <Grid item xs sm={6}>
                        <TextField
                            fullWidth
                            onChange={handleChange('cameraUserName')}
                            value={host.cameraUserName || ''}
                            label={t('CAMERA_USERNAME')}/>
                    </Grid>
                    <Grid item xs sm={6}>
                        <TextField
                            type="cameraPassword"
                            fullWidth
                            onChange={handleChange('cameraPassword')}
                            value={host.cameraPassword || ''}
                            label={t('CAMERA_PASSWORD')}/>
                    </Grid>
                </Grid>
            </Grid>

        </form>
    )
        ;
}

const CAMERA = 'CAMERA';
const COMMON = 'COMMON';
const SIP = 'SIP';

const rows = [
    {
        value: 20,
        label: '5',
    },
    {
        value: 40,
        label: '4',
    },
    {
        value: 60,
        label: '3',
    },
    {
        value: 80,
        label: '2',
    },
    {
        value: 100,
        label: '1',
    },

];


function getColumnValue(hosts, index, step) {
    if (!hosts || hosts.length === 0) {
        return 0;
    }
    return hosts.length - 1 === index ? 100 : step * (index + 1);
}

function getColumns(hosts, step) {
    const res = [];
    if (!hosts || hosts.length === 0) {
        return res;
    }
    for (let i = 1; i <= hosts.length; i++) {
        res.push({
            value: i === hosts.length ? 100 : step * i,
            label: i,
        });
    }
    return res;

}

function WizardStep1(props) {

    const {
        parking,
        error,
        readOnly,
        userUseCases,
        parkingHosts,
        fetchParkingHosts,
        isLoading,
        updateHostsPosition,
        sseParkingHosts,
        updateHost,
        sipCall,
        sipStatus,
    } = props;

    const {t} = useTranslation();

    const ReactGridLayout = WidthProvider(RGL);

    const [gridSize, setGridSize] = useState(null);
    const [step, setStep] = useState(null);
    const classes = useStyles({columnI: gridSize ? gridSize.column : 5});
    const [layout, setLayout] = useState([]);
    const [selectedHost, setSelectedHost] = useState();
    const [mode, setMode] = useState();
    const [isUpdating, setIsUpdating] = useState(false);
    const [columns, setColumns] = useState([]);


    useEffect(() => {
        if (parking) {
            fetchParkingHosts(parking.parkingId);
        }
    }, [parking]);


    useEffect(() => {
        if (parking && parkingHosts && parkingHosts.length > 0) {
            const stepInner = 100 / parkingHosts.length;
            setStep(stepInner);
            setColumns(getColumns(parkingHosts, stepInner));
            if (gridSize) {
                setLayout(getHostLayout(parkingHosts, gridSize));
            } else {
                const coordinates = getGridSize(parkingHosts);
                setGridSize({column: coordinates.maxColumn, row: coordinates.maxRow});

            }
            if (selectedHost && mode === CAMERA) {
                const sseHost = parkingHosts.find(a => a.parkingHostId == selectedHost.parkingHostId);
                setSelectedHost(sseHost);
            }
        }
    }, [parkingHosts]);


    useEffect(() => {
        if (gridSize) {
            const layout = getHostLayout(parkingHosts, gridSize);
            setLayout(layout);
            updateSwapInner(layout);
        }
    }, [gridSize]);


    useEffect(() => {
        if (selectedHost && mode === CAMERA && sseParkingHosts) {
            const sseHost = sseParkingHosts.find(a => a.parkingHostId == selectedHost.parkingHostId);
            if (sseHost) {
                setSelectedHost(sseHost);
            }
        }
    }, [sseParkingHosts]);


    const getDetailForm = (innerMode, innerSelectedHost) => {
        if (innerMode === COMMON) {
            return (
                <>
                    <div className={classes.title}>
                        <h2>{t('UPDATE_HOST')}</h2>
                    </div>

                    <Paper className={classes.table}>
                        <HostDetail
                            host={innerSelectedHost} handleChange={handleChange}
                            handleNumberChange={handleNumberChange}
                        />
                        <div className={classes.buttonNavigation}>
                            <ActionButton
                                action="close"
                                handleClick={() => {
                                    closeDetailAction(undefined);
                                }}
                            />
                            {isComponentVisibleForUser(UC_MENU_HOST_EDIT, userUseCases) &&
                                <ActionButton
                                    disabled={!isComponentEnabledForUser(UC_MENU_HOST_EDIT, userUseCases)}
                                    action={"save"}
                                    handleClick={() => {
                                        updateHostInner(selectedHost)
                                    }}
                                />}
                        </div>
                    </Paper>
                </>
            )
        } else if (innerMode === CAMERA) {
            return <HostStream host={innerSelectedHost} active={true}
                               withDetail={false}/>;

        }


    };


    if (isLoading || isUpdating) {
        return (<Loading></Loading>);
    }


    const handleDetailAction = (host, actionMode) => {
        setMode(actionMode);
        const sseHost = parkingHosts.find(a => a.parkingHostId == host.parkingHostId);
        setSelectedHost(sseHost);
    }

    const closeDetailAction = () => {
        setMode(null);
        setSelectedHost(null);
    }


    function updateSwap(event) {
        updateSwapInner(layout, event);
    };


    function updateSwapInner(layoutParam, event) {
        console.warn(layoutParam, event);
        const hostsPositions = [];
        for (let i = 0; i < layoutParam.length; i++) {
            const hostItem = !layoutParam[i].id.startsWith('empty');
            if (hostItem) {
                if (event && (event.oldIndex === i)) {
                    hostsPositions.push({
                        parkingHostId: layoutParam[i].id,
                        column: layoutParam[event.newIndex].x,
                        row: layoutParam[event.newIndex].y
                    });
                } else if (event && (event.newIndex === i)) {
                    hostsPositions.push({
                        parkingHostId: layoutParam[i].id,
                        column: layoutParam[event.oldIndex].x,
                        row: layoutParam[event.oldIndex].y
                    });
                } else {
                    hostsPositions.push({
                        parkingHostId: layoutParam[i].id,
                        column: layoutParam[i].x,
                        row: layoutParam[i].y
                    });
                }
            }
        }
        updateHostsPosition(hostsPositions).then(() => {
        }).catch((err) => {
            console.warn("err", err);
        })
    };


    const handleChange = (name) => (event) => {
        if (event.target) {
            setSelectedHost({...selectedHost, [name]: event.target.value});
        } else {
            setSelectedHost({...selectedHost, [name]: event});
        }
    }

    const handleNumberChange = (name) => (event) => {
        setSelectedHost({...selectedHost, [name]: parseInt(event.target.value)});

    }

    const updateHostInner = (host) => {
        setIsUpdating(true);
        updateHost(host).then(
            () => {
                setSelectedHost(null);
                setIsUpdating(false);

            }
        ).catch(() => {
            setIsUpdating(false);
        });
    }


    function testSip(host) {
        sipCall('call-audio', host.sipPhoneNumber);
    }


    return (

        <Grid container spacing={2}>


            <Grid item md={selectedHost ? 8 : 11}>
                <Slider
                    onChangeCommitted={(event, newValue) => {
                        const searchedValue = Math.floor(newValue);
                        const selectedStep = columns.find(a => Math.floor(a.value) === searchedValue);
                        setGridSize({...gridSize, column: parseInt(selectedStep.label)});
                    }}
                    min={step}
                    defaultValue={50}
                    value={gridSize ? gridSize.column * step : step}
                    aria-labelledby="vertical-slider"
                    marks={columns}
                    step={step}
                />
                <ReactSortable
                    animation={200}
                    delayOnTouchStart={true}
                    delay={2}
                    className={classes.container}
                    list={layout}
                    swap={true}
                    setList={() => {
                    }}
                    onUpdate={(ev) => updateSwap(ev)}
                    swapClass="highlighted"
                    dragClass="sortable-drag"
                >

                    {layout.map((item, index) => {
                            const host = parkingHosts.find(a => a.parkingHostId == item.id);
                            return host ? (

                                <div
                                    onClick={() => handleDetailAction(host, COMMON)}
                                    className={`
                  ${classes.hostTooltipCard}
                  ${(selectedHost && selectedHost.parkingHostId === host.parkingHostId) && classes.hostTooltipCardActive}
                  ${(host.status === 'STATUS_GREEN') && classes.hostTooltipCardGreen}
                  ${(host.status === 'STATUS_RED') && classes.hostTooltipCardRed}
                  ${(host.status === 'STATUS_YELLOW') && classes.hostTooltipCardYellow}
                `}
                                    key={`zone_tooltip_image_${index}`}
                                >

                                    <div className={classes.icons}>

                                        {isComponentVisibleForUser(UC_CAMERA_HOST, userUseCases) &&
                                            <IconButton
                                                size="small"
                                                color="inherit"
                                                aria-label="open edit"
                                                disabled={!isComponentEnabledForUser(UC_CAMERA_HOST, userUseCases) || !hasStream(host)}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDetailAction(host, CAMERA);
                                                }}
                                            >
                                                <VideocamIcon
                                                    className={hasStream(host) ? classes.videoOn : classes.showOff}/>
                                            </IconButton>
                                        }

                                        {isComponentVisibleForUser(UC_HOST_CALL, userUseCases) &&
                                            <IconButton
                                                size="small"
                                                color="inherit"
                                                aria-label="open edit"
                                                disabled={(!host.sipPhoneNumber || !isComponentEnabledForUser(UC_HOST_CALL, userUseCases) || sipStatus !== SIP_STATUS.CONNECTED)}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    testSip(host)
                                                }}
                                            >
                                                <PhoneIcon
                                                    className={(!host.sipPhoneNumber || !isComponentEnabledForUser(UC_HOST_CALL, userUseCases) || sipStatus !== SIP_STATUS.CONNECTED) ? classes.showOff : classes.phoneOn}/>
                                            </IconButton>
                                        }

                                    </div>


                                    <img
                                        src={getIcon(host.iconName)}
                                        className={classes.image}
                                        alt={host.hostName}
                                        title={host.hostName}
                                    />
                                    <div className={classes.shortName}>{host.hostShortName}</div>
                                </div>

                            ) : <div className={classes.hostTooltipEmpty} key={index}></div>
                        }
                    )}
                </ReactSortable>
            </Grid>
            <Grid item md={1} style={{paddingTop: '20px'}}>
                <Slider
                    onChangeCommitted={(event, newValue) => {
                        const newRowI = (100 - newValue) / 20 + 1;
                        setGridSize({...gridSize, row: newRowI});
                    }}
                    orientation="vertical"
                    min={20}
                    defaultValue={80}
                    value={gridSize ? 100 - (gridSize.row - 1) * 20 : 80}
                    aria-labelledby="vertical-slider"
                    marks={rows}
                    step={20}
                />
            </Grid>
            <Grid item md={3}>
                {!!selectedHost ? getDetailForm(mode, selectedHost) : null}
            </Grid>
        </Grid>


    );
}

const UC_HOST_CALL = 'UC0010';
const UC_MENU_HOST_EDIT = 'UC0083';
const UC_CAMERA_HOST = 'UC0083';

WizardStep1.propTypes = {};

WizardStep1.defaultProps = {};

const mapStateToProps = (store) => ({
    parkingHosts: store.hostsData.parkingHosts,
    isLoading: store.hostsData.isFetchingHosts,
    sseParkingHosts: store.parkingsData.parkingHosts,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingHosts, updateHostsPosition, updateHost,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep1);


