import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    shortName: {
        height: 24,
        fontSize: 16,
        marginLeft: 3,
        marginRight: 3,
        width: '100%',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    video: {
        width: '150px',
        height: '150px',
        marginLeft: '20px',
        marginRight: '20px'
    },
    iconTrash: {
        marginLeft: '-20px',
    },
}));

const CustomVideo = (props) => {
    const classes = useStyles();

    const {
        data, videoName, handleDelete, showDelete, file
    } = props;

    const videoPlay = (event) => {
        event.target.play();
    }

    const videoStop = (event) => {
        event.target.pause();
    }

    return (
        <div>
            <video
                className={classes.video}
                onMouseOver={videoPlay}
                onMouseOut={videoStop}
            >
                <source src={data}  />
            </video>
            {showDelete &&
                <Delete className={classes.iconTrash} onClick={handleDelete(file)}/>
            }
            <div className={classes.shortName}>{videoName}</div>
        </div>
    )
};

CustomVideo.propTypes = {
    key: PropTypes.string,
    data: PropTypes.string,
    videoName: PropTypes.string,
};

CustomVideo.defaultProps = {
    key: 'key',
    data: '',
    videoName: 'name',
};

export default CustomVideo;
