import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import {ActionButton} from 'components/common';
import {CircularProgress} from "@material-ui/core";
import PlayListView from './PlayListView';
import {fetchPlayListDetail} from "../../../redux/actions/playlists";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    button: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));

function PlayListModal(props) {
    const {
        userUseCases,
        playList, fetchPlayListDetail, isFetchedPlayListDetail, setIsFetchedPlayListDetail,
        hosts,
        handleClose,
        savePlayList,
        playListConfigModel,
        saving,
        setSaving,
        parkings,
} = props;


    const classes = useStyles();
    const {t} = useTranslation();

    const [updatedPlayList, setUpdatedPLayList] = useState();
    const [errorStep1, setErrorStep1] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseLoc = () => {
        setIsFetchedPlayListDetail(false);
        handleClose();
    }

    useEffect(() => {
        if (!isFetchedPlayListDetail && playListConfigModel.playList && playListConfigModel.playList.playListId) {
            setIsLoading(true);
            fetchPlayListDetail(playListConfigModel.playList.playListId).then(()=>{
                setIsFetchedPlayListDetail(true);
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsFetchedPlayListDetail(false);
            });
        }
    }, [playListConfigModel, fetchPlayListDetail, isFetchedPlayListDetail]);


    const isValid = (isNew) => {
        let ret = true;
        const error = {};
        if (!updatedPlayList.name || updatedPlayList.name.trim().length === 0) {
            error.name = 'required';
            ret = false;
        }
        if (!updatedPlayList.active) {
            error.active = 'required';
            ret = false;
        }
        setErrorStep1(error);
        return ret;
    }

    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedPLayList({...updatedPlayList, [name]: event.target.value});
        } else {
            setUpdatedPLayList({...updatedPlayList, [name]: event});
        }
    }

    useEffect(()=>{
        if ( playList ) {
            setUpdatedPLayList({...playList, initialized:false});
        }
    }, [playList]);

    
    return (
        <Dialog
            className={classes.container}
            open={playListConfigModel.open||false}
            onClose={handleCloseLoc}
        >
            <DialogTitle className={classnames(classes.title)}>
                <span>
                    {t(playListConfigModel.readOnly?'DETAIL_PLAY_LIST':(playListConfigModel.isNew ? 'CREATE_PLAY_LIST' : 'UPDATE_PLAY_LIST'))}
                </span>
            </DialogTitle>

            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    <PlayListView 
                        handleChange={handleChange}
                        updatedPlayList={updatedPlayList}
                        setUpdatedPLayList={setUpdatedPLayList}
                        error={errorStep1}
                        readOnly={playListConfigModel.readOnly}
                        isNew={playListConfigModel.isNew}
                        hosts={hosts}
                        parkings={parkings}
                        loading={isLoading}
                        saving={saving}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>
                    <div className={classes.wrapper}>
                        {!playListConfigModel.readOnly &&
                        <ActionButton
                            action="close"
                            handleClick={handleCloseLoc}
                            className={classes.button}
                        />
                        }
                    </div>

                </div>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        {!playListConfigModel.readOnly &&
                            <>
                                <ActionButton
                                    disabled={saving}
                                    action="save" handleClick={() => {
                                        const ret = isValid(playListConfigModel.isNew);
                                        if (ret) {
                                            savePlayList(updatedPlayList);
                                            setSaving(true);
                                        }
                                    }}
                                    className={classes.button}
                                />
                                {saving && <CircularProgress size={24} className={classes.buttonProgress} color='success'/>}
                            </>
                        }
                        {playListConfigModel.readOnly &&
                            <ActionButton
                                action="close"
                                handleClick={handleCloseLoc}
                                className={classes.button}
                            />
                        }                          
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

// PlayListModal.propTypes = {};

const mapStateToProps = (store) => ({
    playList: store.playListData.playList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPlayListDetail
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PlayListModal);


