import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import {
    fetchParkingAssignedUsers,
    fetchParkingNotAssignedUsers,
    addParkingIdToUser,
    removeParkingIdFromUser,
} from 'redux/actions/users';
import {CustomTable, CustomButton} from 'components/elements';
import {ActionButton} from 'components/common';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import {UC_PARKING_EDIT} from "../../../components/modals/Management";
import PMCDialog from "../../../components/common/PMCDialog";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: 834,
            maxWidth: 'unset',
            height: 'fit-content',
            padding: theme.spacing(4.25, 3.25),
            boxShadow: theme.palette.shadow.main,
        },
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mr: {
        marginRight: theme.spacing(2.5),
    },
    title: {
        marginBottom: theme.spacing(4.25),
        color: theme.palette.base[500],

        '& .MuiTypography-root': {
            fontSize: 24,
        },
    },
    labelText: {
        width: '100%',
        color: '#A2A2A2',
        textAlign: 'center'
    },
}));

const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#60636B',
    textAlign: 'center',
};

function ParkingAccess(props) {
    const classes = useStyles();

    const {
        active,
        parking,
        parkingAssignedUsers,
        parkingNotAssignedUsers,
        fetchParkingAssignedUsers,
        fetchParkingNotAssignedUsers,
        addParkingIdToUser,
        removeParkingIdFromUser,
        isFetchingParkingUserList,
        userUseCases,
    } = props;

    const {t} = useTranslation();

    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    const [isLoading, setIsLoading] = useState(true);
    const [confirmModal, setConfirmModal] = useState({
        opened: false,
        rowData: null,
    });
    const [addUserModal, setAddUserModal] = useState({
        opened: false,
        users: null,
    });

    const fetchUsersAssigned = useCallback(() => {
        fetchParkingAssignedUsers(parking.subjectId, parking.parkingId)
            .then(() => {
                if (!isUnMounted.current) {
                    setIsLoading(false);
                }
            }).catch(() => {
            if (!isUnMounted.current) {
                setIsLoading(false);
            }
        });
    }, [fetchParkingAssignedUsers, parking.subjectId, parking.parkingId]);

    const fetchUsersNotAssigned = useCallback(() => {
        fetchParkingNotAssignedUsers(parking.subjectId, parking.parkingId)
            .then(() => {
                if (!isUnMounted.current) {
                    setIsLoading(false);
                }
            }).catch(() => {
            if (!isUnMounted.current) {
                setIsLoading(false);
            }
        });
    }, [fetchParkingNotAssignedUsers, parking.subjectId, parking.parkingId]);

    const removeParkingFromUser = (rowData) => {
        setConfirmModal({
            opened: true,
            rowData,
        });
    };

    const handleConfirm = (rowData) => {
        removeParkingIdFromUser(parking.subjectId, rowData.userId, parking.parkingId);
        setConfirmModal({
            opened: false,
            rowData: null,
        });
    };

    const handleAddUser = (rowData) => {
        addParkingIdToUser(parking.subjectId, rowData.userId, parking.parkingId);
        setAddUserModal({
            opened: false,
            users: null,
        });
    };

    const handleCloseConfirm = () => {
        setConfirmModal({
            opened: false,
            rowData: null,
        });
    };

    const modalAddParkingToUser = () => {
        setAddUserModal({
            opened: true,
            users: parkingNotAssignedUsers,
        });
    };

    const handleCloseAddUser = () => {
        setAddUserModal({
            opened: false,
            users: null,
        });
    };

    const columns = [
        {
            title: t('USER'),
            field: 'name',
            cellStyle,
        },
        {
            title: t('ROLE'),
            field: 'roles',
            cellStyle,
            render: (rowData) => (
                <div className={classes.wrapper}>
                    {t('ROLE_' + rowData.roles)}
                </div>
            ),
        },
        {
            title: t('EMAIL'),
            field: 'email',
            cellStyle,
        },
        {
            title: t('ACTION'),
            field: 'action',
            sorting: false,
            cellStyle,
            headerStyle: {
                padding: '0 34px 0 34px',
            },
            render: (rowData) => {
                return isComponentVisibleForUser(UC_REMOVE_USER, userUseCases) ?
                    <div className={classes.wrapper}>
                        <ActionButton
                            disabled={!isComponentEnabledForUser(UC_REMOVE_USER, userUseCases)}
                            action="remove_user"
                            handleClick={() => removeParkingFromUser(rowData)}
                        />
                    </div> : null
            },
        },
        {
            title: isComponentVisibleForUser(UC_ADD_USER, userUseCases) ?
                <CustomButton
                    disabled={!isComponentEnabledForUser(UC_ADD_USER, userUseCases)}
                    label={t('ADD_USER')}
                    width={110}
                    height={32}
                    color="blue"
                    onClick={modalAddParkingToUser}
                /> : null,
            field: 'action',
            sorting: false,
            width: '120',
            cellStyle,
        },
    ];

    const addUserColumns = [
        {
            title: t('USER'),
            field: 'name',
            cellStyle,
        },
        {
            title: t('ROLE'),
            field: 'roles',
            cellStyle,
            render: (rowData) => (
                <div className={classes.wrapper}>
                    {t('ROLE_' + rowData.roles)}
                </div>
            ),
        },
        {
            title: t('EMAIL'),
            field: 'email',
            cellStyle,
        },
        {
            title: t('ACTION'),
            field: 'action',
            sorting: false,
            cellStyle,
            headerStyle: {
                padding: '0 34px 0 34px',
            },
            render: (rowData) => {
                return isComponentVisibleForUser(UC_ADD_USER, userUseCases) ?
                    <div className={classes.wrapper}>
                        <CustomButton
                            disabled={!isComponentEnabledForUser(UC_ADD_USER, userUseCases)}
                            label={t('ADD_USER')}
                            width={110}
                            height={32}
                            color="blue"
                            onClick={() => handleAddUser(rowData)}
                        />
                    </div> : null
            },
        },
    ];

    const getUsers = (data) => {
        if (data === 'undefined') {
            return [];
        }
        return data.map((row) => ({
            name: `${row.firstName} ${row.lastName}`,
            email: row.email,
            roles: row.roleCodes,
            userId: row.userId,
        }));
    };

    const addUserContent = () => (
        <CustomTable
            title=""
            columns={addUserColumns}
            data={getUsers(parkingNotAssignedUsers)}
            options={{
                paging: false,
                rowsPerPage: 20,
                headerStyle: {
                    padding: '15px 8px 15px 34px',
                    borderTop: '1px solid #DEE2E5',
                    lineHeight: '12px',
                    color: '#1B1B28',
                    textAlign: 'center',
                },
            }}
            isLoading={isLoading}
        />
    );

    useEffect(() => {
        if (active) {
            setIsLoading(true);
            fetchUsersAssigned();
            fetchUsersNotAssigned();
        }
    }, [active, fetchUsersAssigned, fetchUsersNotAssigned]);

    return (
        <div className={classes.container}>
            <CustomTable
                title=""
                columns={columns}
                data={getUsers(parkingAssignedUsers)}
                options={{
                    headerStyle: {
                        padding: '15px 8px 15px 34px',
                        borderTop: '1px solid #DEE2E5',
                        lineHeight: '12px',
                        color: '#1B1B28',
                        textAlign: 'center',
                    },
                }}
                isLoading={isLoading || isFetchingParkingUserList}
            />

            {confirmModal.opened &&
            <PMCDialog
                height={300}
                size="xs"
                headerColor={"#333da9"}
                actionType="confirmModal"
                open={confirmModal.opened}
                onSubmit={() => handleConfirm(confirmModal.rowData)}
                title={t('REMOVE_USER_TITLE')}
                onClose={handleCloseConfirm}
            >

                <Typography className={classes.labelText}>
                    {t('CONFIRM_REMOVE_USER', {userName: confirmModal.rowData.name, parkingName: parking.parkingName})}
                </Typography>

            </PMCDialog>
            }

            {addUserModal.opened &&

            <PMCDialog
                size="md"
                headerColor={"#333da9"}
                actionType="confirmModal"
                open={addUserModal.opened}
                title={t('ADD_USER')}
                onClose={handleCloseAddUser}
            >

                <DialogContentText id="alert-dialog-description">
                    {addUserContent()}
                </DialogContentText>

            </PMCDialog>
            }
        </div>
    );
}

ParkingAccess.propTypes = {
    active: PropTypes.bool.isRequired,
    isFetchingParkingUserList: PropTypes.bool.isRequired,
    parking: PropTypes.object.isRequired,
    parkingAssignedUsers: PropTypes.array.isRequired,
    parkingNotAssignedUsers: PropTypes.array.isRequired,
    fetchParkingAssignedUsers: PropTypes.func.isRequired,
    fetchParkingNotAssignedUsers: PropTypes.func.isRequired,
    addParkingIdToUser: PropTypes.func.isRequired,
    removeParkingIdFromUser: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
    parkingAssignedUsers: store.usersData.parkingAssignedUsers,
    parkingNotAssignedUsers: store.usersData.parkingNotAssignedUsers,
    isFetchingParkingUserList: store.usersData.isFetchingParkingUserList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingAssignedUsers,
    fetchParkingNotAssignedUsers,
    addParkingIdToUser,
    removeParkingIdFromUser,
}, dispatch);

const UC_ADD_USER = 'UC0063';
const UC_REMOVE_USER = 'UC0064';

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingAccess);
