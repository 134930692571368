import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingParkingList: false,
  isFetchingImageList: false,
  isFetchingStatistics: false,
  isCreating: false,
  isCanceling: false,
  isRenewing: false,

  parkingTasks: [],
  parkingTasksStatistics: [],
  taskImages: [],

  tasksPaging: {
    pageSize: 5,
    currentPage: 0,
    totalSize: 0,
  },

  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARKING_TASKS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingList: true,
      };
    case types.PARKING_TASKS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingList: false,
        parkingTasks: action.payload.parkingTasks,
        tasksPaging: action.payload.tasksPaging,
      };
    case types.PARKING_TASKS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingList: false,
        errors: action.payload.error,
      };

    case types.PARKING_TASKS_STATISTICS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingStatistics: true,
      };
    case types.PARKING_TASKS_STATISTICS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingStatistics: false,
        parkingTasksStatistics: action.payload.parkingTasksStatistics,
      };
    case types.PARKING_TASKS_STATISTICS_FETCH_FAIL:
      return {
        ...state,
        isFetchingStatistics: false,
        errors: action.payload.error,
      };

    case types.TASK_CREATE_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case types.TASK_CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        parkingTasks: action.payload.parkingTasks,
      };
    case types.TASK_CREATE_FAIL:
      return {
        ...state,
        isCreating: false,
        errors: action.payload.error,
      };

    case types.TASK_CANCEL_REQUEST:
      return {
        ...state,
        isCanceling: true,
      };
    case types.TASK_CANCEL_SUCCESS:
      return {
        ...state,
        isCanceling: false,
        parkingTasks: action.payload.parkingTasks,
      };
    case types.TASK_CANCEL_FAIL:
      return {
        ...state,
        isCanceling: false,
        errors: action.payload.error,
      };

    case types.TASK_RENEW_REQUEST:
      return {
        ...state,
        isRenewing: true,
      };
    case types.TASK_RENEW_SUCCESS:
      return {
        ...state,
        isRenewing: false,
        parkingTasks: action.payload.parkingTasks,
      };
    case types.TASK_RENEW_FAIL:
      return {
        ...state,
        isRenewing: false,
        errors: action.payload.error,
      };
  
    case types.TASK_IMAGES_FETCH_REQUEST:
      return {
        ...state,
        isFetchingImageList: true,
      };
    case types.TASK_IMAGES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingImageList: false,
        taskImages: action.payload.taskImages,
      };
    case types.TASK_IMAGES_FETCH_FAIL:
      return {
        ...state,
        isFetchingImageList: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
