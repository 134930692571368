import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import {getQueryVariable, isComponentVisibleForUser} from 'utils';
import {CustomSwitch} from 'components/elements';
import ParkingList from './ParkingList';
import LocalParking from './LocalParking';
import ParkingModals from './ParkingModals';
import SubjectParking from './SubjectParking';
import {bindActionCreators} from "redux";
import {processAction} from "../../redux/actions/parkings";
import Fulltext from "../../components/modals/Fulltext";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    onlineSwitch: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1.5, 0),
    },
    onlineSwitchLabel: {
        marginRight: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'uppercase',
    },
    filterName: {
        paddingBottom: '20px',
        paddingLeft: '0px'
    },
    subjectLink: {
        marginLeft: theme.spacing(1.5),
        textDecoration: 'none',
        cursor: 'pointer',
    },
    searchIcon: {
        paddingLeft: '20px',
        color: 'black'
    },
}));

function ParkingMainContent(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        user,
        isSM,
        isFLM,
        parkings,
        events,
        sseParkingHosts,
        occupancy,
        selectedIds,
        setSelectedIds,
        selectedHostName,
        setSelectedHostName,
        subjectOwners,
        parkingHosts,
        parkingCards,
        allCodeList,
        toggleAllParking,
        createTask,
        updateZone,
        createCard,
        bulkEditCards,
        selectParking,
        fetchSubjectOwners,
        calls,
        sipCall,
        sipStatus,
        userUseCases,
        cardNumber,
        processAction,
        openFulltext,
        handleFulltextClose
    } = props;

    const [mySubjectData, setMySubjectData] = useState(true);
    const handleMySubjectData = () => {
        setMySubjectData(!mySubjectData);
    };

    const [myLocalParkingData, setMyLocalParkingData] = useState(true);
    const handleMyLocalParkingData = () => {
        setMyLocalParkingData(!myLocalParkingData);
    };

    const handleHostNameClick = (hostname, parkingId) => {
        if (window.opener) {
            window.opener.location.href = `/${process.env.REACT_APP_PATH}/parking?defaultParkingId=${parkingId}&defaultHostName=${hostname}`;
        } else {
            setSelectedIds([parkingId]);
            setSelectedHostName(hostname);
        }
    };

    const [createTaskModal, setCreateTaskModal] = useState({
        opened: false,
        event: null,
    });
    const handleTaskCreateOpen = (event) => {
        setCreateTaskModal({
            opened: true,
            event,
        });
    };
    const handleCreateClose = () => {
        setCreateTaskModal({
            opened: false,
            event: null,
        });
    };

    const [taskDetailModal, setTaskDetailModal] = useState({
        opened: false,
        event: null,
    });
    const handleTaskDetailOpen = (event) => () => {
        setTaskDetailModal({
            opened: true,
            event,
        });
    };
    const handleTaskDetailClose = () => {
        setTaskDetailModal({
            opened: false,
            event: null,
        });
    };

    const [cameraDetailModal, setCameraDetailModal] = useState({
        opened: false,
        event: null,
    });
    const handleCameraDetailOpen = (event) => () => {
        setCameraDetailModal({
            opened: true,
            event,
        });
    };
    const handleCameraDetailClose = () => {
        setCameraDetailModal({
            opened: false,
            event: null,
        });
    };

    const [editZoneModal, setEditZoneModal] = useState({
        opened: false,
        event: null,
    });
    const handleEditZoneOpen = (event) => {
        setEditZoneModal({
            opened: true,
            event,
        });
    };
    const handleEditZoneClose = () => {
        setEditZoneModal({
            opened: false,
            event: null,
        });
    };

    const [cardDetailModal, setCardDetailModal] = useState({
        opened: false,
        event: null,
    });
    const handleCardDetailOpen = (event) => {
        setCardDetailModal({
            opened: true,
            event,
        });
    };
    const handleCardDetailClose = () => {
        setCardDetailModal({
            opened: false,
            event: null,
        });
    };

    const [ownerDetailModal, setOwnerDetailModal] = useState({
        opened: false,
        event: null,
    });
    const handleOwnerDetailOpen = (event) => {
        setOwnerDetailModal({
            opened: true,
            event,
        });
    };
    const handleOwnerDetailClose = () => {
        setOwnerDetailModal({
            opened: false,
            event: null,
        });
    };

    const [createCardModal, setCrateCardModal] = useState({
        opened: false,
        event: null,
    });

    const [createCardModalParams, setCrateCardModalParams] = useState({
        parkingId: null,
        cardNumber: null,
    });


    useEffect(() => {
        if (createCardModal.opened === false && createCardModalParams.parkingId !== null && createCardModalParams.cardNumber !== null) {
            handleCardNameClick(createCardModalParams.parkingId, createCardModalParams.cardNumber);

            setCrateCardModalParams({
                parkingId: null,
                cardNumber: null,
            });
        }
    }, [createCardModal.opened]);

    const handleCreateCardOpen = (event) => {
        setCrateCardModal({
            opened: true,
            event,
        });
    };

    const handleCreateCardClose = () => {
        setCrateCardModal({
            opened: false,
            event: null,
        });
    };

    const [editCardModal, setEditCardModal] = useState({
        opened: false,
        event: null,
    });

    const handleCreateCardCloseAndEdit = (parkingId, cardNumber) => () => {
        setCrateCardModal({
            opened: false,
            event: null,
        });
        handleCardDetailOpen({parkingId, cardNo: cardNumber, type: 'LT'});
    };

    const handleEditCardOpen = (event) => {
        setEditCardModal({
            opened: true,
            event,
        });
    };
    const handleEditCardClose = () => {
        setEditCardModal({
            opened: false,
            event: null,
        });
    };

    const [blockCardModal, setBlockCardModal] = useState({
        opened: false,
        event: null,
    });
    const handleBlockCardOpen = (event) => {
        setBlockCardModal({
            opened: true,
            event,
        });
    };
    const handleBlockCardClose = () => {
        setBlockCardModal({
            opened: false,
            event: null,
        });

        handleCardDetailClose();
    };

    const [confirmCardModal, setConfirmCardModal] = useState({
        opened: false,
        event: null,
        type: null,
    });
    const handleConfirmCardOpen = (event, type) => {
        setConfirmCardModal({
            opened: true,
            event,
            type,
        });
    };
    const handleConfirmCardClose = () => {
        handleCardDetailClose();

        setConfirmCardModal({
            opened: false,
            event: null,
            type: null,
        });
    };

    const openMySubject = () => {
        window.open(`/${process.env.REACT_APP_PATH}/parking?simple=Y`, '_blank', 'toolbar=0,location=0,menubar=0');
    };

    const handleClickNavLink = () => {
        toggleAllParking();
    };

    const [isQueryParamSet, setIsQueryParamSet] = useState(false);

    const [simple, setSimple] = useState('N');

    const [filterName, setFilterName] = useState('');
    const [filterParkings, setFilterParkings] = useState([]);

    const handleCardNameClick = (parkingId, cardNumber, hostname) => {
        if (window.opener) {
            window.opener.location.href = `/${process.env.REACT_APP_PATH}/parking?defaultParkingId=${parkingId}&defaultHostName=${hostname}`;
        } else {
            setSelectedIds([parkingId]);
            setSelectedHostName(hostname);
        }

        handleCardDetailOpen({parkingId, cardNo: cardNumber});
    };

    const handleChangeFilterName = (event) => {
        setFilterName(event.target.value);
    }


    useEffect(() => {
        if (!isQueryParamSet && parkings && parkings.length > 0) {
            const simpleParam = getQueryVariable('simple');
            if (simpleParam && simpleParam === 'Y') {
                setMyLocalParkingData(false);
                setSimple('Y');
            }

            const parkingId = getQueryVariable('defaultParkingId');
            const hostname = getQueryVariable('defaultHostName');
            const cardNumber = getQueryVariable('cardNumber');

            if (parkingId) {
                const parkingIdNumber = parseInt(parkingId, 10);
                if (hostname) {
                    setSelectedIds([parkingIdNumber]);
                    setSelectedHostName(hostname);
                } else if (cardNumber) {
                    handleCardDetailOpen({parkingId: parkingIdNumber, cardNo: cardNumber});
                }
            }

            setIsQueryParamSet(true);
        }
    }, [isQueryParamSet, parkings, setSelectedHostName, setSelectedIds]);

    useEffect(() => {
            if (cardNumber) {
                if (createCardModal.opened === false) {
                    console.log('card detail open, card number', cardNumber);
                    processAction();
                    handleCardDetailOpen({parkingId: 0, cardNo: cardNumber});
                }
            }
        }, [cardNumber]
    );

    useEffect(()=>{
        if ( parkings ) {
            let tmp = [...parkings.filter(item=>item.parkingName.toUpperCase().includes(filterName.toUpperCase()))];
            setFilterParkings(tmp);
        }
    }, [filterName, parkings]);

    return (
        <>
            {simple === 'N' && isComponentVisibleForUser(UC_PARKING_LIST, userUseCases) && (
                <div className={classes.breadcrumb}>
                    <Typography
                        variant="button"
                        className={classnames(selectedIds.length !== 1 && classes.activeLink, classes.navLink)}
                        onClick={handleClickNavLink}
                    >
                        <div className={classes.onlineSwitch}>
                            <CustomSwitch color="secondary" checked={myLocalParkingData}
                                          handleSwitch={handleMyLocalParkingData}/>
                            <div className={classes.onlineSwitchLabel}>
                                {t('MY_PARKING_LIST')}
                            </div>

                            <div className={classes.searchIcon}>
                                <SearchIcon fontSize="large"/>
                            </div>
                            <div className={classes.filterName}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="filterName"
                                    value={filterName}
                                    onChange={handleChangeFilterName}
                                    label={t('SEARCH_PARKING')}
                                />
                            </div>
                        </div>

                    </Typography>

                    {myLocalParkingData && selectedIds.length === 1 && (
                        <>
                            <Typography variant="button" className={classes.separator}>&gt;</Typography>
                            <Typography
                                variant="button"
                                className={classnames(classes.activeLink, classes.navLink)}
                            >
                                {t('PARKING_DETAIL')}
                            </Typography>
                        </>
                    )}
                </div>
            )}

            {myLocalParkingData && simple === 'N' && isComponentVisibleForUser(UC_PARKING_LIST, userUseCases) && (
                <>
                    <ParkingList
                        userUseCases={userUseCases}
                        parkings={filterParkings}
                        selectedIds={selectedIds}
                        selectParking={selectParking}
                        toggleAllParking={toggleAllParking}
                        calls={calls}
                        sseParkingHosts={sseParkingHosts}
                    />

                    {selectedIds.length === 1 && isComponentVisibleForUser(UC_PARKING_DETAIL, userUseCases) && (
                        <LocalParking
                            userUseCases={userUseCases}
                            sipCall={sipCall}
                            sipStatus={sipStatus}
                            calls={calls}
                            isSM={isSM}
                            isFLM={isFLM}
                            selected={parkings.find((p) => p.parkingId === selectedIds[0]) || {}}
                            events={events}
                            sseParkingHosts={sseParkingHosts}
                            occupancy={occupancy}
                            selectedHostName={selectedHostName}
                            setSelectedHostName={setSelectedHostName}
                            handleTaskCreateOpen={handleTaskCreateOpen}
                            handleTaskDetailOpen={handleTaskDetailOpen}
                            handleCameraDetailOpen={handleCameraDetailOpen}
                            withDetail={cameraDetailModal.opened}
                            handleOwnerDetailOpen={handleOwnerDetailOpen}
                            handleEditZoneOpen={handleEditZoneOpen}
                            handleCardDetailOpen={handleCardDetailOpen}
                            handleCreateCardOpen={handleCreateCardOpen}
                            handleEditCardOpen={handleEditCardOpen}
                            handleBlockCardOpen={handleBlockCardOpen}
                            handleConfirmCardOpen={handleConfirmCardOpen}
                        />
                    )}
                </>
            )}

            {simple === 'N' && isComponentVisibleForUser(UC_OPERATOR_DATA, userUseCases) && (
                <div className={classes.onlineSwitch}>
                    <CustomSwitch color="secondary" checked={mySubjectData} handleSwitch={handleMySubjectData}/>
                    <div className={classes.onlineSwitchLabel}>
                        {t('MY_SUBJECT_DATA')}
                        {isComponentVisibleForUser(UC_OPERATOR_DATA_NEW_WINDOW, userUseCases) &&
                        <OpenInNewIcon href="#" onClick={openMySubject} fontSize="small"
                                       className={classes.subjectLink}/>
                        }
                    </div>
                </div>
            )}

            {mySubjectData && isComponentVisibleForUser(UC_OPERATOR_DATA, userUseCases) && (
                <SubjectParking
                    userUseCases={userUseCases}
                    user={user}
                    parkings={parkings}
                    events={events}
                    allCodeList={allCodeList}
                    selectParking={selectParking}
                    handleHostNameClick={handleHostNameClick}
                    handleTaskCreateOpen={handleTaskCreateOpen}
                    handleOwnerDetailOpen={handleOwnerDetailOpen}
                    handleCardNameClick={handleCardNameClick}
                    simple={simple === 'Y'}
                />
            )}

            <ParkingModals
                cardNumber={cardNumber}
                user={user}
                userUseCases={userUseCases}
                parkingId={selectedIds.length === 1 ? selectedIds[0] : null}
                parkings={parkings}
                hostname={selectedHostName}
                events={events}
                subjectOwners={subjectOwners}
                parkingCards={parkingCards}
                parkingHosts={sseParkingHosts}
                allCodeList={allCodeList}
                taskDetailModal={taskDetailModal}
                cameraDetailModal={cameraDetailModal}
                ownerDetailModal={ownerDetailModal}
                createTaskModal={createTaskModal}
                editZoneModal={editZoneModal}
                cardDetailModal={cardDetailModal}
                createCardModal={createCardModal}
                editCardModal={editCardModal}
                blockCardModal={blockCardModal}
                confirmCardModal={confirmCardModal}
                handleTaskDetailClose={handleTaskDetailClose}
                handleCameraDetailClose={handleCameraDetailClose}
                handleOwnerDetailClose={handleOwnerDetailClose}
                handleCreateClose={handleCreateClose}
                handleCreateCardClose={handleCreateCardClose}
                handleCreateCardCloseAndEdit={handleCreateCardCloseAndEdit}
                handleCardDetailClose={handleCardDetailClose}
                handleEditZoneClose={handleEditZoneClose}
                handleEditCardClose={handleEditCardClose}
                handleBlockCardOpen={handleBlockCardOpen}
                handleBlockCardClose={handleBlockCardClose}
                handleConfirmCardOpen={handleConfirmCardOpen}
                handleConfirmCardClose={handleConfirmCardClose}
                createTask={createTask}
                updateZone={updateZone}
                createCard={createCard}
                bulkEditCards={bulkEditCards}
                fetchSubjectOwners={fetchSubjectOwners}
            />

            <Fulltext opened={openFulltext}
                      handleClose={handleFulltextClose}
                      parkings={parkings}
                      userUseCases={userUseCases}
                      handleHostNameClick={handleHostNameClick}
                      handleCardNameClick={handleCardNameClick}
                      selectParking={selectParking}


            />
        </>
    );
}

ParkingMainContent.propTypes = {
    user: PropTypes.object,
    selectedHostName: PropTypes.string,
    parkings: PropTypes.array.isRequired,
    events: PropTypes.array.isRequired,
    sseParkingHosts: PropTypes.array.isRequired,
    occupancy: PropTypes.array.isRequired,
    subjectOwners: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    parkingCards: PropTypes.array.isRequired,
    parkingHosts: PropTypes.array.isRequired,
    allCodeList: PropTypes.array.isRequired,
    createTask: PropTypes.func.isRequired,
    updateZone: PropTypes.func.isRequired,
    createCard: PropTypes.func.isRequired,
    bulkEditCards: PropTypes.func.isRequired,
    selectParking: PropTypes.func.isRequired,
    toggleAllParking: PropTypes.func.isRequired,
    fetchSubjectOwners: PropTypes.func.isRequired,
    setSelectedIds: PropTypes.func.isRequired,
    setSelectedHostName: PropTypes.func.isRequired,
};

ParkingMainContent.defaultProps = {
    user: null,
    selectedHostName: null,
};

const mapStateToProps = (store) => ({
    parkingHosts: store.hostsData.parkingHosts,
    parkingCards: store.cardsData.parkingCards,
    allCodeList: store.codesData.allCodeList,
    subjectOwners: store.ownersData.subjectOwners,
    cardNumber: store.parkingsData.cardNumber,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    processAction,
}, dispatch);


const UC_PARKING_LIST = 'UC0003';
const UC_PARKING_DETAIL = 'UC0004';
const UC_OPERATOR_DATA = 'UC0039';
const UC_OPERATOR_DATA_NEW_WINDOW = 'UC0040';


export default connect(
    mapStateToProps, mapDispatchToProps
)(ParkingMainContent);
