import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment/min/moment-with-locales';
import {CustomTable} from 'components/elements';
import {ParkingName} from 'components/common';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoDetail from 'components/modals/PhotoDetail';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '100%',
    },
    tableWrapper: {
        marginTop: '20px',
        borderTop: `3px solid ${theme.palette.base[200]}`,
    },
    tableWrapperEmpty: {},
    hostLink: {
        textDecoration: 'none',
        borderBottom: '1px dotted grey',
        color: '#1B1B28',
        cursor: 'pointer',
    },
}));

const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

function FulltextTable(props) {
    const classes = useStyles();

    const {
        advancedFilterOpen,
        parkings,
        isLoading,
        startTimeStr,
        endTimeStr,
        events,
        hosts,
        filteredEvents,
        subjectEventsPaging,
        subjectEvents,
        parkingNames,
        eventTypes,
        selectedHosts,
        selectedTypes,
        selectedParkingNames,
        selectParking,
        handleOnline,
        handleSelectParkingNames,
        handleSelectHosts,
        handleSelectTypes,
        handleLPChange,
        handleStartDate,
        handleEndDate,
        handleSearch,
        handleHostNameClick,
        handleCardNameClick,
        handleChangeRowsPerPage,
        handleChangePage,
        userUseCases,
        handleClose,
        searchQuery,
        owner,
        cardNumber,
        lpn,
    } = props;

    const {t} = useTranslation();


    function getHighlightedText(text, highlight, mainTerm) {
        const searchString = mainTerm && mainTerm.length > 0 ? mainTerm : (highlight && highlight.length > 0 ? highlight : null);
        if (!searchString || !text) {
            return text;
        }
        console.info(highlight, mainTerm);
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${searchString})`, 'gi'));
        return <span> {parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === searchString.toLowerCase() ? {fontWeight: 'bold'} : {}}>
            {part}
        </span>)
        } </span>;
    }

    const [columns, setColumns] = useState([]);

    const [showPhoto, setShowPhoto] = useState(false);
    const [rowData, setRowData] = useState(null);

    const hadlePhotoClick = (rowData) => () => {
        setShowPhoto(true);
        setRowData(rowData);
    }

    useEffect(() => {
        setColumns([
                {
                    title: t('LICENSE_PLATE'),
                    field: 'lpn',
                    cellStyle,
                    render: (rowData) => <>{getHighlightedText(rowData.lpn, searchQuery, lpn)}</>,
                },
                {
                    title: t('PHOTO'),
                    field: 'photo',
                    cellStyle,
                    render: (rowData) =>   <>{((rowData.photoKeyPic && rowData.photoKeyPic!=='0000000000000000') || (rowData.photoKeyLpn && rowData.photoKeyLpn!=='0000000000000000')) &&
                      <PhotoCameraIcon onClick={hadlePhotoClick(rowData)} className={classes.pointer}/>
                    }</>,
                },
                {
                    title: t('CARD_NUMBER'),
                    field: 'detail',
                    cellStyle,
                    render: (rowData) => (
                        <span
                            className={classes.hostLink}
                            title={t('OPEN_CARD_DETAIL')}
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    handleClose();
                                    handleCardNameClick(rowData.parkingId, rowData.cardNumber, rowData.parkingHost);
                                }
                            }
                        >
              {getHighlightedText(rowData.cardNumber, searchQuery, cardNumber)}
            </span>
                    ),
                },
                {
                    title: t('CARD_OWNER'),
                    field: 'ownerSurname',
                    cellStyle,
                    render: (rowData) => <>{getHighlightedText(`${rowData.ownerFirstName ? rowData.ownerFirstName : ''} ${rowData.ownerSurname ? rowData.ownerSurname : ''}`, searchQuery, owner)}</>,
                },
                {
                    title: t('PARKING_NAME'),
                    field: 'parkingId',
                    headerStyle: {textAlign: 'left', paddingLeft: 5},
                    cellStyle,
                    render: (rowData) => {
                        const parking = parkings.find((p) => p.parkingId === rowData.parkingId);
                        return (
                            <div className={classes.wrapper}>
                                <ParkingName parking={parking} handleSymbolClick={() => {
                                    handleClose();
                                    selectParking(rowData.parkingId)
                                }}/>
                            </div>
                        );
                    },
                },
                {
                    title: t('PARKING_HOST'),
                    field: 'parkingHost',
                    cellStyle,
                    render: (rowData) => {

                        return (<span
                            className={classes.hostLink}
                            title={t('OPEN_HOST_DETAIL')}
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    handleClose();
                                    handleHostNameClick(rowData.parkingHost, rowData.parkingId);
                                }
                            }
                        >
          {rowData.parkingHost}

        </span>);
                    },
                },
                {
                    title: t('DATE'),
                    field: 'date',
                    cellStyle,
                    render: (rowData) => (
                        <>
                            {rowData.date ? moment(new Date(rowData.date)).locale(i18next.language).format('L LTS') : ''}
                        </>
                    ),
                },
                {
                    title: t('EVENT'),
                    field: 'eventType',
                    cellStyle,
                    render: (rowData) => {
                        var message = t(rowData.event);
                        return message.replace('{1}', rowData.cardNumber);
                    },
                },
            ]
        )
    }, [searchQuery, cardNumber, lpn, owner]);


    const tableOptions = {
        headerStyle: {
            padding: '15px 8px 15px 34px',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
        },
        paging: false
    };

    return (
        <div className={classes.container}>
            <div className={advancedFilterOpen ? classes.tableWrapper : classes.tableWrapperEmpty}>
                <CustomTable
                    title=""
                    columns={columns}
                    data={events}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                />
            </div>
            {showPhoto &&
                <PhotoDetail showPhoto={showPhoto} setShowPhoto={setShowPhoto} rowData={rowData} setRowData={setRowData}/>
            }
        </div>
    );
}

FulltextTable.propTypes = {
    parkings: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    events: PropTypes.array.isRequired,
    hosts: PropTypes.array.isRequired,
    filteredEvents: PropTypes.array,
    parkingNames: PropTypes.array.isRequired,
    eventTypes: PropTypes.array.isRequired,
    selectedHosts: PropTypes.array.isRequired,
    selectedTypes: PropTypes.array.isRequired,
    selectedParkingNames: PropTypes.array.isRequired,
    selectParking: PropTypes.func.isRequired,
    handleSelectParkingNames: PropTypes.func.isRequired,
    handleSelectHosts: PropTypes.func.isRequired,
    handleSelectTypes: PropTypes.func.isRequired,
    handleHostNameClick: PropTypes.func.isRequired,
    handleCardNameClick: PropTypes.func.isRequired,
    handleSearch: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    handleChangePage: PropTypes.func.isRequired,
    simple: PropTypes.bool.isRequired,
};

FulltextTable.defaultProps = {
    filteredEvents: null,
    startTimeStr: null,
    endTimeStr: null,
    handleSearch: () => {
    },
};

const UC_OFFLINE_EVENTS = 'UC0041';
const UC_HOST_DETAIL = 'UC0006';
const UC_TASK_CREATE = 'UC0038';
const UC_CARD_DETAIL_LT = 'UC0025';
const UC_CARD_DETAIL_ST = 'UC0033';


export default FulltextTable;
