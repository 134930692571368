import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { CustomTable } from 'components/elements';

import StatisticsToolbar from './ToolBar';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mr: {
    marginRight: theme.spacing(2.5),
  },
}));

const cellStyle = {
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: '19px',
  color: '#60636B',
  textAlign: 'center',
};

function MobileOperatorStatistics(props) {
  const classes = useStyles();

  const {
    active,
    parking,
    fetchParkingTasksStatistics,
    parkingTasksStatistics,
  } = props;

  const { t } = useTranslation();

  const today = new Date();
  const prevWeek = new Date();
  prevWeek.setDate(prevWeek.getDate() - 7);

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [startTimeStr, setStartTimeStr] = useState(prevWeek);
  const [endTimeStr, setEndTimeStr] = useState(today);

  const [isLoading, setIsLoading] = useState(true);
  const [isFetchedStatistics, setIsFetchedStatistics] = useState(false);
  const fetchStatistics = useCallback(() => {
    fetchParkingTasksStatistics(parking.parkingId, startTimeStr, endTimeStr)
      .then(() => {
        if (!isUnMounted.current) {
          setIsLoading(false);
          setIsFetchedStatistics(true);
        }
      }).catch(() => {
        if (!isUnMounted.current) {
          setIsLoading(false);
        }
      });
  }, [parking.parkingId, fetchParkingTasksStatistics, startTimeStr, endTimeStr]);

  const handleStartDate = (value) => {
    setIsFetchedStatistics(false);
    setStartTimeStr(value);
  };
  const handleEndDate = (value) => {
    setIsFetchedStatistics(false);
    setEndTimeStr(value);
  };


  useEffect(() => {
    if (!isFetchedStatistics && active) {
      setIsLoading(true);
      fetchStatistics();
    }
  }, [active, isFetchedStatistics, fetchStatistics, startTimeStr, endTimeStr, parking.parkingId]);

  const columns = [
    { title: t('OPERATOR'), field: 'solver', cellStyle },
    { title: t('TOTAL_TASKS'), field: 'totalAssigned', cellStyle },
    { title: t('DAILY_TASKS'), field: 'assignedInToday', cellStyle },
    { title: t('TOTAL_UNRESOLVED'), field: 'totalUnresolved', cellStyle },
    { title: t('TOTAL_COMPLETED'), field: 'totalCompleted', cellStyle },
    { title: t('COMPLETED_TOTAL_DURATION'), field: 'totalDuration', cellStyle },
    { title: t('AVERAGE_ON_TASK'), field: 'averageOnTaskDuration', cellStyle },
  ];

  return (
    <div className={classes.container}>
      <StatisticsToolbar
        startTimeStr={startTimeStr}
        endTimeStr={endTimeStr}
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
      />
      <CustomTable
        title=""
        columns={columns}
        data={parkingTasksStatistics}
        options={{
          headerStyle: {
            padding: '15px 8px 15px 34px',
            borderTop: '1px solid #DEE2E5',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
          },
        }}
        isLoading={isLoading}
      />
    </div>
  );
}

MobileOperatorStatistics.propTypes = {
  active: PropTypes.bool.isRequired,
  parking: PropTypes.object.isRequired,
  fetchParkingTasksStatistics: PropTypes.func.isRequired,
  parkingTasksStatistics: PropTypes.array.isRequired,
};

export default MobileOperatorStatistics;
