import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import {CustomButton, CustomSelect} from 'components/elements';
import {ParkingName} from 'components/common';
import {getEventDescription} from 'utils';
import PMCDialog from "../common/PMCDialog";

const useStyles = makeStyles((theme) => ({
    formGroup: {
        marginBottom: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    label: {
        width: theme.spacing(11),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    content: {
        padding: theme.spacing(1, 0),
        flex: 1,
        borderBottom: `1px solid ${theme.palette.base[200]}`,
    },
    hostSelect: {
        padding: theme.spacing(1, 0),
        flex: 1,

        '& .MuiSelect-select': {
            borderBottom: `1px solid ${theme.palette.base[200]}`,
            color: theme.palette.base[800],
            fontSize: 16,
        },

        '& svg': {
            width: 24,
            height: 24,
            fill: theme.palette.base[300],
            marginTop: 0,
        },
    },
    textField: {
        flex: 1,
    },
    errorMessage: {
        color: theme.palette.primary.red,
        fontSize: 13,
    },
    select: {
        '& .MuiSelect-select': {
            lineHeight: '20px',
        },
    },
}));

function CreateGeneralTask(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        user,
        event,
        opened,
        parkings,
        parkingHosts,
        createTask,
        handleClose,
    } = props;

    const [error, setError] = useState(false);
    const [state, setState] = useState({});
    const handleChange = (field) => (event) => {
        setError(false);
        setState({
            ...state,
            [field]: event.target.value,
        });
    };

    const parking = parkings.find((p) => p.parkingId === event.parkingId);
    const parkingHostsFilterd = parkingHosts.filter((h) => h.parkingId === parking.parkingId);

    const hosts = parkingHostsFilterd && parkingHostsFilterd.length > 0 ? parkingHostsFilterd.map((item) => ({
        value: item.parkingHostId,
        label: t(item.hostName),
        key: `host_${item.parkingHostId}`,
    })) : [];
    const [host, setHost] = useState('');
    const handleChangeHost = (value) => {
        setHost(value);
        setState({
            ...state,
            parkingHostId: value,
        });
    };

    const handleSubmit = () => {
        if (!state.problem) {
            setError(true);

            return;
        }

        setError(false);
        createTask({
            creationDate: moment().format('DD.MM.YYYY HH:mm'),
            creationBy: `${user.firstName} ${user.lastName}`,
            creationById: user.userId,
            description: state.problem,
            parkingHostId: parseInt(host ? state.parkingHostId : event.parkingHostId, 10),
            parkingId: event.parkingId,
            status: 'CR',
        })
            .then(handleClose);
    };

    return (
        <PMCDialog
            open={opened}
            title={t('CREATE_GENERAL_TASK')}
            onClose={handleClose}
            onSubmit={handleSubmit}
            headerColor="#800000"

        >

            <Box className={classes.formGroup}>
                <Typography variant="button" className={classes.label}>{t('CARD_PARK')}</Typography>
                <Box className={classes.content}>
                    <ParkingName parking={parking}/>
                </Box>
            </Box>

            <Box className={classes.formGroup}>
                <Typography variant="button" className={classes.label}>{t('HOST')}</Typography>
                {event.taskHosts ? (
                    <Box className={classes.hostSelect}>
                        <CustomSelect
                            autoFocus={event.taskHosts}
                            value={host}
                            items={hosts}
                            placeholder={t('SELECT_PARKING_HOST')}
                            className={classes.select}
                            handleChange={handleChangeHost}
                        />
                    </Box>
                ) : (
                    <Box className={classes.content}>
                        <Typography variant="subtitle2">{event.parkingHost}</Typography>
                    </Box>
                )}
            </Box>

            {!event.taskHosts && (
                <Box className={classes.formGroup}>
                    <Typography variant="button" className={classes.label}>{t('EVENT_DETAIL')}</Typography>
                    <Box className={classes.content}>
                        <Typography variant="subtitle2">
                            {getEventDescription(t(event.message && event.message.startsWith('message.') ? event.message.substring(8, event.message.length) : event.message), event.messageParam)}
                        </Typography>
                    </Box>
                </Box>
            )}

            <Box className={classes.formGroup}>
                <Typography variant="button" className={classes.label}>{t('TYPE_PROBLEM')}</Typography>
                <TextField
                    autoFocus={!event.taskHosts}
                    required
                    className={classes.textField}
                    hiddenLabel
                    multiline
                    rowsMax="2"
                    value={state.problem}
                    inputProps={{
                        maxLength: 150,
                    }}
                    onChange={handleChange('problem')}
                    helperText={error
                        ? <span
                            className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED_PLEASE_INPUT_DESCRIPTION')}</span>
                        : <span>{`${150 - (state.problem ? state.problem.length : 0)}`} {t('CHARACTERS_LEFT')}</span>}
                />
            </Box>
        </PMCDialog>
    );
}

CreateGeneralTask.propTypes = {
    user: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    opened: PropTypes.bool.isRequired,
    parkings: PropTypes.array.isRequired,
    parkingHosts: PropTypes.array.isRequired,
    createTask: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CreateGeneralTask;
