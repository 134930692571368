import eventsService from 'services/events.service';
import types from '../actionTypes';


export const search = (queryParams) => (dispatch, getState) => {
    if (getState().fulltextData.isFetching) {
        return Promise.reject();
    }
    dispatch({
        type: types.FULLTEXT_FETCH_REQUEST,
    });
    return eventsService.getFulltextEvents(
        queryParams.hosts,
        queryParams.parkings,
        queryParams.search,
        queryParams.dateFrom,
        queryParams.dateTo,
        queryParams.lpn,
        queryParams.cardNumber,
        queryParams.owner
    )
        .then((data) => {
            dispatch({
                type: types.FULLTEXT_FETCH_SUCCESS,
                payload: data,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.FULLTEXT_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};
