import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingAllAdminUseCases: false,
  useCases: [],
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.USECASE_FETCH_REQUEST:
      return {
        ...state,
        isFetchingAllAdminUseCases: true,
      };
    case types.USECASE_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAllAdminUseCases: false,
        useCases: action.payload.useCases,
      };
    case types.PARKING_USERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingAllAdminUseCases: false,
        errors: action.payload.error,
      };


    default:
      return state;
  }
};
