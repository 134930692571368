import httpService from './http.service';

const fetchAllAdminUseCases = () => httpService
    .get('/usecases/alladmin')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    fetchAllAdminUseCases,
};
