import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ParkingSymbol from "../../../common/ParkingSymbol";


const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
        paddingTop: theme.spacing(4),
        height: '100%',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
        height: '100%',
        margin: '12px 12px',
    },
    cardSelected: {
        display: 'flex',
        flexDirection: 'column',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#5985EE',
        boxShadow: '10px 10px 8px #5985EE',
        height: '100%',
        margin: '12px 12px',
    },
    cardTop: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 1, 0, 1),
        height: '100%',
        alignContent: 'space-around',
    },
    cardContent: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'space-around',
        padding: theme.spacing(5),
        background: theme.palette.base[50],
        textAlign: 'center',
        cursor: 'pointer',
    },
    cardTooltip: {
        flex: 0,
        padding: theme.spacing(2),
        background: theme.palette.base[100],
        textAlign: 'center'
    },
    parking: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxHeight: '35px',

    },
    name: {
        marginLeft: theme.spacing(1),
    },
}));

function getProductName(product) {
    if (product.productSystemParam) {
        let o = product.productSystemParam.find(a => a.paramName === 'name');
        if (o) {
            return o.valueVarchar;
        }
    }
    return '';
}


const Card = ({title, selected, onParkingSelected, tooltip, product}) => {
    const classes = useStyles();
    const smallTitle = title && title.length > 20;
    const onClick = () => {
        onParkingSelected(product);
    }

    return (
        <Grid item xs={12} sm={6} md={3}>
            <div className={selected ? classes.cardSelected : classes.card}>
                <div className={classes.cardTop}>

                    <div className={classes.cardContent} onClick={onClick}>
                        <Typography variant={!!smallTitle ? "h3" : "h2"}
                                    className={classes.title}>{getProductName(product)}</Typography>
                    </div>
                    <div className={classes.parking}>
                        <ParkingSymbol variant="h4" color={product.color}/>
                        <Typography variant="subtitle2" className={classes.name}>
                            {product.parkingName}
                        </Typography>
                    </div>
                </div>
                <div className={classes.cardTooltip}>
                    <Tooltip disableFocusListener title={tooltip}>
                        <AddIcon color="primary"/>
                    </Tooltip>
                </div>
            </div>
        </Grid>
    );
};

function WizardStep1(props) {
    const classes = useStyles();

    const {
        sameGroupAndTypeProducts,
        onParkingSelected,
        selectedProductParking,
    } = props;

    const {t} = useTranslation();


    return (
        <div className={classes.container}>
            <Grid container spacing={2}>
                {
                    sameGroupAndTypeProducts && sameGroupAndTypeProducts.map((product, index) => (
                        <Card
                            selected={selectedProductParking && (
                                product.productId === selectedProductParking.productId)
                            }
                            title={product.parkingName}
                            tooltip={product.parkingName}
                            product={product}
                            onParkingSelected={onParkingSelected}
                        />

                    ))
                }

            </Grid>
        </div>
    );


}

WizardStep1.propTypes = {};

WizardStep1.defaultProps = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep1);


