import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchedPhoto: false,
  isStopFetchedPhoto: false,
  errors: '',
  photo: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PHOTO_START_FETCH_REQUEST:
      return {
        ...state,
        isFetchedPhoto: true,
      };
    case types.PHOTO_START_FETCH_SUCCESS:
      return {
        ...state,
        isFetchedPhoto: false,
      };
    case types.PHOTO_START_FETCH_FAIL:
      return {
        ...state,
        isFetchedPhoto: false,
        errors: action.payload.error,
      };
    case types.PHOTO_STOP_FETCH_REQUEST:
      return {
        ...state,
        isStopFetchedPhoto: true,
      };
    case types.PHOTO_STOP_FETCH_SUCCESS:
      return {
        ...state,
        isStopFetchedPhoto: false,
      };
    case types.PHOTO_STOP_FETCH_FAIL:
      return {
        ...state,
        isStopFetchedPhoto: false,
        errors: action.payload.error,
      };
    case types.PHOTODETAIL_FETCH_REQUEST:
      return {
        ...state,
        isStopFetchedPhoto: true,
      };
    case types.PHOTODETAIL_FETCH_SUCCESS:
      return {
        ...state,
        isStopFetchedPhoto: false,
        photo: action.payload.photo,
      };
    case types.PHOTODETAIL_FETCH_FAILT:
      return {
        ...state,
        isStopFetchedPhoto: false,
        errors: action.payload.error,
      };
    

    default:
      return state;
  }
};