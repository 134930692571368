import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingHosts: false,
    isCreatingCommand: false,
    isCreatingAction: false,
    isFetchingEnum: false,
    isUpdating: false,
    parkingHosts: [],
    enums: null,
    isUpdatingHostsPosition: false,
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PARKING_HOSTS_UPDATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case types.PARKING_HOSTS_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                parkingHosts: action.payload.parkingHosts,
            };
        case types.PARKING_HOSTS_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };


        case types.PARKING_HOSTS_FETCH_REQUEST:
            return {
                ...state,
                errors: '',
                isFetchingHosts: true,
            };
        case types.PARKING_HOSTS_FETCH_SUCCESS:
            return {
                ...state,
                errors: '',
                isFetchingHosts: false,
                parkingHosts: action.payload.parkingHosts,
            };
        case types.PARKING_HOSTS_FETCH_FAIL:
            return {
                ...state,
                isFetchingHosts: false,
                errors: action.payload.error,
            };

        case types.PARKING_HOSTS_BY_SUBJECT_FETCH_REQUEST:
            return {
                ...state,
                errors: '',
                isFetchingHosts: true,
            };
        case types.PARKING_HOSTS_BY_SUBJECT_FETCH_SUCCESS:
            return {
                ...state,
                errors: '',
                isFetchingHosts: false,
                parkingHosts: action.payload.parkingHosts,
            };
        case types.PARKING_HOSTS_BY_SUBJECT_FETCH_FAIL:
            return {
                ...state,
                isFetchingHosts: false,
                errors: action.payload.error,
            };
    
        case types.PARKING_HOSTS_ENUM_FETCH_REQUEST:
            return {
                ...state,
                errors: '',
                isFetchingEnum: true,
                enums: null,
            };
        case types.PARKING_HOSTS_ENUM_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingEnum: false,
                errors: '',
                enums: action.payload.enums,
            };
        case types.PARKING_HOSTS_ENUM_FETCH_FAIL:
            return {
                ...state,
                errors: action.payload.error,
                isFetchingEnum: false,
            };

        case types.PARKING_HOSTS_CREATE_COMMAND_REQUEST:
            return {
                ...state,
                errors: '',
                enums: null,
                isCreatingCommand: true,
            };
        case types.PARKING_HOSTS_CREATE_COMMAND_SUCCESS:
            return {
                ...state,
                errors: '',
                isCreatingCommand: false,
            };
        case types.PARKING_HOSTS_CREATE_COMMAND_FAIL:
            return {
                ...state,
                isCreatingCommand: false,
                errors: action.payload.error,
            };

        case types.PARKING_HOSTS_CREATE_ACTION_REQUEST:
            return {
                ...state,
                errors: '',
                enums: null,
                isCreatingAction: true,
            };
        case types.PARKING_HOSTS_CREATE_ACTION_SUCCESS:
            return {
                ...state,
                isCreatingAction: false,
                errors: '',
            };
        case types.PARKING_HOSTS_CREATE_ACTION_FAIL:
            return {
                ...state,
                isCreatingAction: false,
                errors: action.payload.error,
            };

        case types.PARKING_HOSTS_POSITION_UPDATE_REQUEST:
            return {
                ...state,
                errors: '',
                isUpdatingHostsPosition: true,
            };
        case types.PARKING_HOSTS_POSITION_UPDATE_SUCCESS:
            return {
                ...state,
                errors: '',
                isUpdatingHostsPosition: false,
                parkingHosts: action.payload.parkingHosts,
            };
        case types.PARKING_HOSTS_POSITION_UPDATE_FAIL:
            return {
                ...state,
                isUpdatingHostsPosition: false,
                errors: action.payload.error,
            };


        default:
            return state;
    }
};
