import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {CustomButton} from "../../elements";
import PMCDialog from "../../common/PMCDialog";
import Loading from "../../elements/Loading";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 300,
        },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'space-between'
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-around',
        margin: 0,
        height: '100%'

    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    actionButton: {
        marginLeft: theme.spacing(1),
        '@media print': {
            display: 'none'
        },
        paddingBottom: '20px'
    },

}));

const cellStyle = {
    padding: '4px 4px',
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};


function ParkingRestart(props) {
    const {
        userUseCases,
        handleClose,
        restart,
        restartModel,
        isRestarting,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    return (

        <PMCDialog
            open={restartModel.open}
            title={`${restartModel.parking.parkingName} - ${t('RESTART')}`}
            onClose={handleClose}
            headerColor="#800000"
            size="xs"
            height={300}
        >


            {isRestarting ? <Loading/> :
                <div
                    className={classes.content}>
                    <CustomButton
                        label={t('RESTART')}
                        color="green"
                        width={180}
                        height={60}
                        onClick={() => {
                            restart(restartModel.parking, false)
                        }}
                    />
                    <CustomButton
                        label={t('FORCE_RESTART')}
                        color="blue"
                        width={180}
                        height={60}
                        onClick={() => {
                            restart(restartModel.parking, true)
                        }}
                    />
                </div>}

        </PMCDialog>

    );
}

ParkingRestart.propTypes = {};


const mapStateToProps = (store) => ({
    isRestarting: store.parkingsData.isRestarting,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingRestart);


