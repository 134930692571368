import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {numberFormatPriceCustom} from 'utils';
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import classnames from "classnames";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {ActionButton} from "../../../common";
import WizardStep3Table from "../ProductCashDesk/WizardStep3Table";


const PriceInputSmall = withStyles((theme) => ({
    input: {
        fontSize: 25,
        textAlign: 'right',
        color: '#A2A2A2',
    },
}))(InputBase);


const PriceInput = withStyles((theme) => ({
    input: {
        fontSize: 35,
        textAlign: 'right',
        color: '#1B1B28',
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    label: {
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'left',
        fontSize: '35px',
    },
    select: {
        fontSize: '35px',
        color: '#1B1B28',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
    },
    button: {
        width: 200,
        height: 75,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 25,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '75px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },

}));


function ReceiptItemDetail(props) {
    const {
        userUseCases,
        receiptItemProductDetail,
        opened,
        handleClose,
        currency,
        productTemplates,
    } = props;


    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Dialog
            maxWidth="md"
            open={opened}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
              <span>
                    {receiptItemProductDetail && receiptItemProductDetail.receiptItemName}
              </span>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Box mb={2} style={{height: '100%'}}>
                    <Grid container style={{height: '100%'}}>
                        <Grid item xs sm={8}>
                            <div className={classes.label}>{t('TOTAL_PAY')}</div>
                        </Grid>
                        <Grid item xs sm={4}>
                            <PriceInput
                                fullWidth={true}
                                disabled={true}
                                value={receiptItemProductDetail && receiptItemProductDetail.priceWithVAT}
                                endAdornment={<InputAdornment
                                    disableTypography
                                    classes={{root: classes.select}}
                                    position="end">{currency}</InputAdornment>}
                                inputComponent={numberFormatPriceCustom}
                            />
                        </Grid>
                        <Grid item xs sm={8}>
                            <div className={classes.label}>{t('VAT')}</div>
                        </Grid>
                        <Grid item xs sm={4}>
                            <PriceInput
                                disabled={true}
                                fullWidth
                                id="noVat"
                                value={receiptItemProductDetail && (receiptItemProductDetail.vatCharge)}
                                endAdornment={<InputAdornment
                                    disableTypography
                                    classes={{root: classes.select}}
                                    position="end">%</InputAdornment>}
                                inputComponent={numberFormatPriceCustom}
                            />
                        </Grid>
                        <Grid item xs sm={8}>
                            <div className={classes.label}>{t('TOTAL_NO_VAT')}</div>
                        </Grid>
                        <Grid item xs sm={4}>
                            <PriceInput
                                disabled={true}
                                fullWidth
                                id="noVat"
                                value={receiptItemProductDetail && (receiptItemProductDetail.priceWithVAT - receiptItemProductDetail.priceWithVAT * receiptItemProductDetail.vatCharge /100)}
                                endAdornment={<InputAdornment
                                    disableTypography
                                    classes={{root: classes.select}}
                                    position="end">{currency}</InputAdornment>}
                                inputComponent={numberFormatPriceCustom}
                            />
                        </Grid>
                        <Grid item xs sm={12}>
                            {receiptItemProductDetail &&
                            <WizardStep3Table
                                error={{}}
                                productTemplates={productTemplates}
                                handleParamDefaultValueChange={() => {
                                }}
                                product={receiptItemProductDetail.runningProduct}
                                readOnly
                            />
                            }
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigation}>
                    <ActionButton
                        action="close"
                        handleClick={handleClose}
                        className={classes.button}
                    />
                </div>
            </DialogActions>
        </Dialog>
    )


}

ReceiptItemDetail.propTypes = {};


const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReceiptItemDetail);

