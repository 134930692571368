import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { CustomTabs } from 'components/elements';
import CardOwners from './CardOwners';
import OnlineEvents from './OnlineEvents';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(7.5),
  },
}));

function SubjectParking(props) {
  const classes = useStyles();

  const {
    user,
    parkings,
    allCodeList,
    selectParking,
    handleOwnerDetailOpen,
    handleTaskCreateOpen,
    handleHostNameClick,
    handleCardNameClick,
    simple,
    events,
    userUseCases,
  } = props;

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (value) => {
    setActiveTab(value);
  };
  const checkActivated = (value) => value === activeTab;

  const tabs = [
  ];

  const tabContents = [
  ];


  if (isComponentVisibleForUser(UC_TAB_EVENTS, userUseCases)) {
    tabs.push( t('ONLINE_EVENTS'));
    tabContents.push(   <OnlineEvents
        user={user}
        active={checkActivated(tabContents.length)}
        parkings={parkings}
        allCodeList={allCodeList}
        selectParking={selectParking}
        handleTaskCreateOpen={handleTaskCreateOpen}
        handleHostNameClick={handleHostNameClick}
        handleCardNameClick={handleCardNameClick}
        sseEvents={events}
        simple={simple}
        userUseCases={userUseCases}
    />);
  };

  if (isComponentVisibleForUser(UC_TAB_OWNERS, userUseCases)) {
    tabs.push( t('CARD_OWNERS'));
    tabContents.push(<CardOwners
        userUseCases={userUseCases}
        user={user}
        active={checkActivated(tabContents.length)}
        handleOwnerDetailOpen={handleOwnerDetailOpen}
    />);
  };

  return (
    <div className={classes.container}>
      {simple ? tabContents[0] : (
          <CustomTabs
            tabs={tabs}
            tabContents={tabContents}
            variant="fullWidth"
            handleChangeTab={handleChangeTab}
          />
      )}
    </div>
  );
}

SubjectParking.propTypes = {
  user: PropTypes.object.isRequired,
  parkings: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  allCodeList: PropTypes.array.isRequired,
  selectParking: PropTypes.func.isRequired,
  handleOwnerDetailOpen: PropTypes.func.isRequired,
  handleTaskCreateOpen: PropTypes.func.isRequired,
  handleHostNameClick: PropTypes.func.isRequired,
  simple: PropTypes.bool.isRequired,
  handleCardNameClick: PropTypes.func.isRequired,
};

const UC_TAB_EVENTS = 'UC0042';
const UC_TAB_OWNERS = 'UC0044';

export default SubjectParking;
