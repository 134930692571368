import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import {withStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {MDBProgress} from 'mdbreact';

import {DisappointedIcon} from 'assets/Icons';
import { /* CustomCheckbox, */ CustomOption} from 'components/elements';
import Badge from '@material-ui/core/Badge';
import ParkingSymbol from './ParkingSymbol';
import {CustomSwitch} from 'components/elements';
import Chip from '@material-ui/core/Chip';
import PhoneIcon from '@material-ui/icons/Phone';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";


const useStyles = makeStyles((theme) => ({
    chip: {
        justifyContent: 'center',
        height: "30px",
        borderRadius: "30px",
        padding: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        paddingTop: "20px",
    },
    sip: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'right',
        flexWrap: 'wrap',
    },
    sipShown: {
        marginBottom: '-10px',
    },
    container: {
        width: 370,
        // height: 250,
        margin: theme.spacing(2.5, 3.75, 3.75, 0),
        padding: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        boxShadow: theme.palette.shadow.main,
    },
    editIcon: {
        position: 'relative',
        top: '0px',
        left: '300px',
        color: theme.palette.primary.main,
    },
    titleWrapper: {
        marginTop: theme.spacing(-5),
        display: 'flex',
        alignItems: 'flex-end',
    },
    title: {
        marginLeft: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginTop: theme.spacing(2.5),
        color: theme.palette.base[400],
        textTransform: 'uppercase',
    },
    checkboxWrapper: {
        marginTop: theme.spacing(2),
        display: 'flex',
    },
    missedCalls: {
        height: '40px',
        top: '18px',
        left: '365px',
        '& > span': {
            height: '40px',
            width: '40px',
            fontSize: '18px',
            borderRadius: '20px',
        },
    },
    checkboxInner: {
        '& .MuiFormControlLabel-root:first-child': {
            marginBottom: theme.spacing(1),
        },

        '& .MuiIconButton-root': {
            padding: 0,
        },
    },
    optionsWrapper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    disconnected: {
        color: theme.palette.primary.red,
    },
    statusBad: {
        '& svg': {
            fill: theme.palette.primary.red,
        },
    },
    disconnectedContent: {
        width: '100%',
        minHeight: 150,
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(0, 5),
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.base[300],
        textAlign: 'center',
    },
    disconnectedIcon: {
        marginBottom: theme.spacing(1.5),
    },
    zonesWrapper: {
        marginTop: theme.spacing(4),
    },
    averageZone: {
        marginTop: theme.spacing(7),
    },
    zoneLabel: {
        width: 100,
        marginRight: theme.spacing(1),
        color: theme.palette.base[400],
        wordBreak: 'break-all',
    },
    zoneTooltipLabel: {
        width: 200,
        marginRight: theme.spacing(1),
        color: theme.palette.base[400],
        wordBreak: 'break-all',

    },
    zoneStatus: {
        height: 35,
        display: 'flex',
        alignItems: 'center',
    },
    zoneProgressBar: {
        position: 'relative',
        width: '100%',
        height: 18,
        backgroundColor: theme.palette.background.default,
        borderRadius: 9,

        '& .progress-bar': {
            paddingRight: theme.spacing(0.5),
            borderRadius: 9,
            fontSize: 10,
            lineHeight: 1.6,
            color: '#484848',
            textAlign: 'center',
        },
    },
    progressBarRed: {
        '& .progress-bar': {
            backgroundColor: theme.palette.primary.red,
        },
    },
    progressBarGreen: {
        '& .progress-bar': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    progressBarYellow: {
        '& .progress-bar': {
            backgroundColor: theme.palette.primary.yellow,
        },
    },
    error: {
        '& .progress-bar': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.red,
        },
    },
    progressValue: {
        width: '100%',
        position: 'absolute',
    },
    emptyProgressBar: {
        '& .progress-bar': {
            backgroundColor: 'transparent',
        },
    },

}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: '250px',
    },
}))(Tooltip);

function ParkingCard({
                         parking,
                         handleClick,
                         calls,
                         sseParkingHosts,
                         sipParkingIds,
                         addSipParkingIdToUserAction,
                         removeSipParkingIdToUserAction,
                         userUseCases,
                         editable,
                         handleEdit,
                     }) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [missedCalls, setMissedCalls] = useState(0);


    useEffect(() => {
        if (calls && sseParkingHosts) {
            let missedCalls = 0;
            for (let i = 0; i < sseParkingHosts.length; i++) {
                for (let j = 0; j < calls.length; j++) {
                    if (calls[j].missed && sseParkingHosts[i].parkingId === parking.parkingId && sseParkingHosts[i].sipUserName === calls[j].number) {
                        missedCalls++;
                    }
                }
            }
            setMissedCalls(missedCalls);
        }
    }, [sseParkingHosts, calls]);


    const zones = parking.zoneStatus
        .filter((zone) => zone.show)
        .map((zone) => ({
            id: zone.zoneId,
            label: zone.zoneName,
            capacity: zone.capacity,
            occupancy: zone.occupancy+zone.occupancyReserved,
            /* eslint-disable-next-line no-mixed-operators */
            progress: zone.capacity > zone.occupancy ? Math.floor((zone.occupancy+zone.occupancyReserved) * 100 / zone.capacity) : 100,
            validData: zone.occupancy >= 0 && zone.capacity >= 0,
        }));
    const getOccupancyColor = (occupancy, capacity) => {
        const occupancyRatio = Math.floor(occupancy * 100 / capacity);

        if (occupancyRatio >= 80) return classes.progressBarRed;
        if (occupancyRatio >= 50) return classes.progressBarYellow;
        return classes.progressBarGreen;
    };

    const zoneStatus = (zones, aggregate) => {
        if (aggregate) {
            let averageProgress = 0;
            let validData = true;
            let totalCapacity = 0;
            let totalOccupancy = 0;
            zones.forEach((zone) => {
                totalCapacity += zone.capacity;
                totalOccupancy += zone.occupancy;
                if (!zone.validData) {
                    validData = false;
                }
            });

            // eslint-disable-next-line no-mixed-operators
            averageProgress = validData ? Math.floor(totalOccupancy * 100 / totalCapacity) : 0;
            if ( averageProgress > 100 ) averageProgress = 100;
            if ( averageProgress < 0 ) averageProgress = 0;
            
            const progressBarClass = averageProgress >= 50
                ? classes.progressBarYellow
                : classes.progressBarGreen;

            return (
                <HtmlTooltip title={zoneStatus(zones, false)}>
                    <div className={classes.zoneStatus}>
                        <Typography variant="subtitle1" className={classes.zoneLabel}>
                            {t('OCCUPANCY')}
                        </Typography>
                        <MDBProgress
                            material
                            className={
                                classnames(
                                    classes.zoneProgressBar,
                                    averageProgress === 0 && classes.emptyProgressBar,
                                    !validData && classes.error,
                                    getOccupancyColor(totalOccupancy, totalCapacity),
                                )
                            }
                            value={averageProgress}
                        >
                            <div className={classes.progressValue}>
                                {validData ? `${averageProgress}%` : t('INVALID_DATA')}
                            </div>
                        </MDBProgress>
                    </div>
                </HtmlTooltip>
            );
        }
        return zones.map((zone) => (
            <div className={classes.zoneStatus} key={`zone_progress_${zone.id}`}>
                <Typography variant="subtitle1" className={classes.zoneLabel}>
                    {zone.label}
                </Typography>
                <MDBProgress
                    material
                    className={
                        classnames(
                            classes.zoneProgressBar,
                            !zone.validData && classes.error,
                            zone.progress === 0 && classes.emptyProgressBar,
                            getOccupancyColor(zone.occupancy, zone.capacity),
                        )
                    }
                    value={zone.validData ? zone.progress : 100}
                >
                    <div
                        className={classes.progressValue}
                    >
                        {zone.validData ? 
                            ( zone.occupancy!=null ? `${zone.occupancy}/${zone.capacity}` : '-/-' )
                            : t('INVALID_DATA')
                        }
                    </div>
                </MDBProgress>
            </div>
        ));
    };

    return (
        <>
            <Badge badgeContent={missedCalls} color="error" anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                   overlap="rectangle" className={classes.missedCalls}/>
            <div className={classes.container}>
                {editable && <div className={classes.editIcon}>
                    <IconButton onClick={() => {
                        handleEdit(parking)
                    }}>
                        <EditIcon/>
                    </IconButton>
                </div>}
                <div className={classes.titleWrapper}>
                    <ParkingSymbol
                        className={classes.symbol}
                        color={parking.color}
                        handleClick={handleClick(parking.parkingId)}
                        connectionType={parking.connectionType}
                        width={'66px'}              
                    />
                    <div className={classes.title}>
                        <Typography variant="h3">{parking.parkingName}</Typography>
                        <Typography
                            variant="caption"
                            color="primary"
                            className={parking.isConnected ? '' : classes.disconnected}
                        >
                            {parking.isConnected ? t('CONNECTED') : t('DISCONNECTED')}

                            {sipParkingIds && isComponentVisibleForUser(UC_SIP_SETTING, userUseCases) &&
                            <Chip className={classes.chip} icon={<PhoneIcon/>} color="primary" label={<CustomSwitch
                                color="primary"
                                disabled={!isComponentEnabledForUser(UC_SIP_SETTING, userUseCases)}
                                checked={sipParkingIds.find(a => a == parking.parkingId) !== undefined}
                                handleSwitch={(e) => {
                                    e ? addSipParkingIdToUserAction(parking.parkingId) : removeSipParkingIdToUserAction(parking.parkingId);
                                }}
                            />} variant="outlined"/>}

                        </Typography>
                    </div>
                </div>

                {parking.isConnected ? (
                    <>
                        {isComponentVisibleForUser(UC_HOST_DETAIL_STATUS, userUseCases) && parking.connectionType !== 'WIMQ' &&
                            <>
                                <Typography variant="subtitle1" className={classes.label}>
                                    {t('LOCAL_EQUIPMENT_STATUS')}
                                </Typography>
                                <div className={classes.optionsWrapper}>
                                    <CustomOption
                                        label={`${parking.statusRedYellowGreen[0]} ${t('items')}`}
                                        color={parking.statusRedYellowGreen[0] == 0 ? '#A9A9A9' : '#F34734'}
                                    />
                                    <CustomOption
                                        label={`${parking.statusRedYellowGreen[1]} ${t('items')}`}
                                        color={parking.statusRedYellowGreen[1] == 0 ? '#A9A9A9' : '#FDBA45'}
                                    />
                                    <CustomOption
                                        label={`${parking.statusRedYellowGreen[2]} ${t('items')}`}
                                        color={parking.statusRedYellowGreen[2] == 0 ? '#A9A9A9' : '#46CC93'}
                                    />
                                </div>
                                    </>
                        }
                        {isComponentVisibleForUser(UC_HOST_DETAIL_ZONES, userUseCases) &&
                        <div className={classnames(classes.zonesWrapper, zones.length > 4 && classes.averageZone)}>
                            {zoneStatus(zones, zones.length > 3)}
                        </div>}
                    </>
                ) : (
                    <div className={classes.disconnectedContent}>
                        <DisappointedIcon className={classes.disconnectedIcon}/>
                        <Typography variant="subtitle1">
                            {t('PARKING_DATA_IS_NOT_AVAILABLE_A_THE_MOMENT_DUE_TO_LOST_OF_CONNECTION_WITH_PARKING')}
                        </Typography>
                    </div>
                )}
            </div>
        </>
    );
}

ParkingCard.propTypes = {
        parking: PropTypes.object,
        handleClick: PropTypes.func,
        sipToggle: PropTypes.object,
};

ParkingCard.defaultProps = {
        parking: {},
        handleClick: undefined,
};

const UC_HOST_DETAIL_STATUS = 'UC0007';
const UC_HOST_DETAIL_ZONES = 'UC0008';
const UC_SIP_SETTING = 'UC0059';


export default ParkingCard;
