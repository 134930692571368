import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import {CustomTable} from 'components/elements';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionButton} from '../../common';
import {createSubject, fetchAllSubjects, filterSubjects, updateSubject} from 'redux/actions/subjects';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Add from "@material-ui/icons/Add";
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import InputAdornment from "@material-ui/core/InputAdornment";


const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
    },
    content: {
        background: theme.palette.base[200],
        height: '100%',
        width: '100%',
    },
    button: {
        width: 200,
        marginLeft: 20,
        marginRight: 20,
    },
    table: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        padding: theme.spacing(1, 1, 1, 1),
        justifyContent: 'space-between',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '60px',
        padding: theme.spacing(1, 1, 1, 1),
        width: '100%',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(10),
        padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
    title: {
        height: 60,
        width: '100%',
        background: 'green',
        paddingRight: theme.spacing(2),
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            marginBlockStart: 0,
            marginBlockEnd: 0,
            lineHeight: '20px',
            color: theme.palette.base.white,
            '& .MuiButtonBase-root': {
                color: theme.palette.base.white,
            },
            '& input + fieldset': {
                borderColor: theme.palette.base.white
            },
        },
    },
}));

const SubjectDetail = ({subject, handleChange}) => {

    const [error, setError] = useState({});

    const {t} = useTranslation();
    const classes = useStyles();


    return (
        <form autoComplete='off'>
            <Grid container alignItems="stretch" style={{height: '100%'}}>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.name
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        required
                        onChange={handleChange('name')}
                        value={subject.name || ''}
                        label={t('NAME')}/>
                </Grid>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.companyId
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        onChange={handleChange('companyId')}
                        value={subject.companyId || ''}
                        label={t('COMPANY_ID')}/>
                </Grid>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.vatNumber
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        onChange={handleChange('vatNumber')}
                        value={subject.vatNumber || ''}
                        label={t('VAT_NUMBER')}/>
                </Grid>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.adress
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        required
                        onChange={handleChange('adress')}
                        value={subject.adress || ''}
                        label={t('ADDRESS')}/>
                </Grid>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.city
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        required
                        onChange={handleChange('city')}
                        value={subject.city || ''}
                        label={t('CITY')}/>
                </Grid>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.email
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        onChange={handleChange('email')}
                        value={subject.email || ''}
                        label={t('EMAIL')}/>
                </Grid>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.phoneNumber
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        onChange={handleChange('phoneNumber')}
                        value={subject.phoneNumber || ''}
                        label={t('PHONE')}/>
                </Grid>
            </Grid>
        </form>
    );
}


const SubjectsFilter = ({handleFilterChange, setSelectedSubject, values, ...props}) => {
    const {t} = useTranslation();
    const classes = useStyles();


    return (
        <Paper>
            <Box p={1}>
                <Grid container spacing={1}>
                    <Grid item md>
                        <TextField
                            value={values.name}
                            name="name"
                            onChange={handleFilterChange}
                            label={t("NAME")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            value={values.companyId}
                            name="companyId"
                            onChange={handleFilterChange}
                            label={t("COMPANY_ID")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            value={values.vatNumber}
                            name="vatNumber"
                            onChange={handleFilterChange}
                            label={t("VAT_NUMBER")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            value={values.adress}
                            name="address"
                            onChange={handleFilterChange}
                            label={t("ADDRESS")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            value={values.city}
                            name="city"
                            onChange={handleFilterChange}
                            label={t("CITY")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            value={values.email}
                            name="email"
                            onChange={handleFilterChange}
                            label={t("EMAIL")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            value={values.phoneNumber}
                            name="phoneNumber"
                            onChange={handleFilterChange}
                            label={t("PHONE")}/>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() => {
                                setSelectedSubject({});
                            }}
                            className={classes.addButton}
                            edge="start"
                            color="inherit"
                            aria-label="add subject">
                            <Add className={classes.addIcon}/>
                        </IconButton>

                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

const cellStyle = {
    padding: '4px 4px',
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

const SubjectsList = ({
                          createSubject, updateSubject,
                          subjects, isFetchingList, filterSubjects, fetchAllSubjects, setMode, handleSubjectChange,
                          setSnackbarError, setSnackbarOpen, userUseCases,
                      }) => {
    const classes = useStyles();
    const [selectedSubject, setSelectedSubject] = useState();
    const {t} = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const [values, setValues] = useState({});
    const timeoutRef = useRef(null);

    const handleFilterChange = (e) => {
        const newValues = {...values, [e.target.name]: e.target.value};
        setValues(newValues);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            refreshFilter(newValues);
        }, 1000);
    };

    useEffect(() => () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, []);


    const resetPagination = () => {
        setPage(0);
    };

    const handleChangeRowsPerPage = (value) => {
        resetPagination();
        setSize(value);
    };
    const handleChangePage = (value) => {
        setPage(value);
    };

    const refreshFilter = (filter, lpage, lsize) => {
        return filterSubjects(filter, lpage || page, lsize || size);
    };


    const handleChange = (name) => (event) => {
        setSelectedSubject({...selectedSubject, [name]: event.target.value});
    }


    useEffect(() => {
        if (!loaded) {
            refreshFilter(values).then(() => {
                setLoaded(loaded);
            });
        }
    }, [loaded]);

    const createSubjectInner = (subject) => {
        createSubject(subject).then(
            (subject) => {
                fetchAllSubjects().then(
                    () => {
                        handleSubjectChange({value: subject.subjectId});
                        setMode('COMMON');
                    }
                )
            }
        ).catch((error) => {
            setSnackbarError(getErrorMessage(error, t));
            setSnackbarOpen(true);
        });

    }

    const updateSubjectInner = (subject) => {
        updateSubject(subject).then(
            () => {
                refreshFilter(values)
            }
        ).catch((error) => {
            setSnackbarError(getErrorMessage(error, t));
            setSnackbarOpen(true);

        });
    }


    let columns = [
        {
            title: t('NAME'),
            field: 'name',
            cellStyle,
            render: (rowData) => (
                rowData.name
            ),
        },
        {
            title: t('COMPANY_ID'),
            field: 'companyId',
            cellStyle,
            render: (rowData) => (
                rowData.companyId
            ),
        },
        {
            title: t('VAT_NUMBER'),
            field: 'vatNumber',
            cellStyle,
            render: (rowData) => (
                rowData.vatNumber
            ),
        },
        {
            title: t('ADDRESS'),
            field: 'adress',
            cellStyle,
            render: (rowData) => (
                rowData.adress
            ),
        },
        {
            title: t('CITY'),
            field: 'city',
            cellStyle,
            render: (rowData) => (
                rowData.city
            ),
        },
        {
            title: t('PHONE'),
            field: 'phone',
            cellStyle,
            render: (rowData) => (
                rowData.phoneNumber
            ),
        },
        {
            title: t('EMAIL'),
            field: 'email',
            cellStyle,
            render: (rowData) => (
                rowData.email
            ),
        },
    ];

    return (
        <div
            className={classes.container}
        >
            <div className={classes.content}>
                {/* Filter */}
                <Box my={1} >
                    <SubjectsFilter values={values} handleFilterChange={handleFilterChange}
                                    setSelectedSubject={setSelectedSubject}
                    />
                </Box>
                <Grid container spacing={2} >
                    <Grid item md={selectedSubject ? 9 : 12}>
                            <CustomTable
                                title=""
                                columns={columns}
                                data={
                                    subjects.subjects}
                                options={{
                                    maxBodyHeight: 500,
                                    headerStyle: {
                                        padding: '0 8px 0 34px',
                                        borderTop: '1px solid #DEE2E5',
                                        lineHeight: '12px',
                                        color: '#1B1B28',
                                        textAlign: 'center',
                                    },
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedSubject && selectedSubject.tableData && selectedSubject.tableData.id === rowData.tableData.id) ? '#5985EE' : '#FFF'
                                    })
                                }}
                                isLoading={isFetchingList}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                handleChangePage={handleChangePage}
                                rowsPerPage={size}
                                pageNumber={page}
                                count={subjects.paging ? subjects.paging.totalSize : 0}
                                loadAllData
                                onRowClick={((evt, selectedSubject) => {
                                    setSelectedSubject(selectedSubject);
                                })}
                            />
                    </Grid>
                    <Grid item md={3}>
                        {!!selectedSubject ? (
                            <>
                                <div className={classes.title}>
                                    <h2>{selectedSubject.subjectId ? t('SUBJECT_UPDATE') : t('SUBJECT_CREATE')}</h2>
                                </div>

                                <Paper className={classes.table}>
                                    <SubjectDetail
                                        subject={selectedSubject} handleChange={handleChange}
                                    />
                                    <div className={classes.buttonNavigation}>
                                        <ActionButton
                                            action="close"
                                            handleClick={() => {
                                                setSelectedSubject(undefined);
                                            }}
                                        />
                                        {isComponentVisibleForUser(UC_SUBJECT_UPDATE, userUseCases) &&
                                            <ActionButton
                                                disabled={!isComponentEnabledForUser(UC_SUBJECT_UPDATE, userUseCases)}
                                                action={selectedSubject.subjectId ? "save" : "saveAndClose"}
                                                handleClick={() => {
                                                    selectedSubject.subjectId ?
                                                        updateSubjectInner(selectedSubject) : createSubjectInner(selectedSubject)
                                                }}
                                            />}
                                    </div>
                                </Paper>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const mapStateToProps = (store) => ({
    subjects: store.subjectsData.subjectsParam,
    isFetchingList: store.subjectsData.isFetchingAllSubjectsList,

});

const UC_SUBJECT_UPDATE = 'UC0202';


const mapDispatchToProps = (dispatch) => bindActionCreators({
    filterSubjects,
    fetchAllSubjects,
    updateSubject,
    createSubject
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SubjectsList);
