import parkingsService from 'services/parkings.service';
import types from '../actionTypes';


export const fetchAllparkingsForParkingConfiguration = (subjectId) => (dispatch, getState) => {

    if (getState().parkingsData.isFetchingParkingsForParkingConfiguration) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_CONFIGURATION_FETCH_REQUEST,
    });

    return parkingsService.fetchParkingForConfiguration(subjectId)
        .then((parkingsForParkingConfiguration) => {
            dispatch({
                type: types.PARKING_CONFIGURATION_FETCH_SUCCESS,
                payload: {parkingsForParkingConfiguration},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_CONFIGURATION_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const fetchParkingAccesses = (parkingId, accessType) => (dispatch, getState) => {
    if (getState().parkingsData.isFetchingAccessList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_ACCESS_FETCH_REQUEST,
    });

    return parkingsService.fetchParkingAccesses(parkingId, accessType)
        .then((parkingAccesses) => {
            dispatch({
                type: types.PARKING_ACCESS_FETCH_SUCCESS,
                payload: {parkingAccesses},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_ACCESS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const fetchParkingEventCategories = (parkingId) => (dispatch, getState) => {
    if (getState().parkingsData.isFetchingEventCategoriesForParkingConfiguration) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_REQUEST,
    });

    return parkingsService.fetchParkingEventCategories(parkingId)
        .then((eventCategories) => {
            dispatch({
                type: types.PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_SUCCESS,
                payload: {eventCategories},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateParkingEventCategory = (category) => (dispatch, getState) => {
    if (getState().parkingsData.isUpdatingEventCategoriesForParkingConfiguration) {
        return;
    }

    dispatch({
        type: types.PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_REQUEST,
    });

    return parkingsService.updateEventCategory([category])
        .then(() => {
            const {eventCategoriesForParkingConfiguration} = getState().parkingsData;
            const index = eventCategoriesForParkingConfiguration.findIndex((p) => p.eventCategoryId === category.eventCategoryId);
            eventCategoriesForParkingConfiguration[index] = {...category};

            dispatch({
                type: types.PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_SUCCESS,
                payload: {eventCategories: [...eventCategoriesForParkingConfiguration]},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const updateZoneParkingVisibility = (parkingId, zoneId, show) => (dispatch, getState) => {
    if (getState().parkingsData.isUpdatingZoneVisibilityForParkingConfiguration) {
        return;
    }

    dispatch({
        type: types.PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_REQUEST,
    });

    return parkingsService.updateZoneVisibility(zoneId, show)
        .then(() => {
            const {parkingsForParkingConfiguration} = getState().parkingsData;
            const index = parkingsForParkingConfiguration.findIndex((p) => p.parkingId === parkingId);
            const parking = parkingsForParkingConfiguration[index];
            const zoneIndex = parking.zoneStatus.findIndex((p) => p.zoneId === zoneId);
            const parkingCopy = {...parking, zoneStatus: [...parking.zoneStatus]};
            parkingCopy.zoneStatus[zoneIndex].show = show;
            const parkingsForParkingConfigurationCopy = [...parkingsForParkingConfiguration];
            parkingsForParkingConfigurationCopy[index] = parkingCopy;
            dispatch({
                type: types.PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_SUCCESS,
                payload: {parkingsForParkingConfiguration: parkingsForParkingConfigurationCopy},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const deleteParking = (parking) => (dispatch, getState) => {
    if (getState().parkingsData.isUpdating) {
        return;
    }

    dispatch({
        type: types.PARKINGS_UPDATE_REQUEST,
    });

    return parkingsService.updateParking(parking)
        .then(() => {
            const {parkings} = getState().parkingsData;
            const parkingIndex = parkings.findIndex((p) => p.parkingId === parking.parkingId);
            parkings[parkingIndex] = {...parking};

            dispatch({
                type: types.PARKINGS_UPDATE_SUCCESS,
                payload: {parkings: [...parkings]},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKINGS_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateParking = (parking) => (dispatch, getState) => {
    if (getState().parkingsData.isUpdating) {
        return;
    }

    dispatch({
        type: types.PARKINGS_UPDATE_REQUEST,
    });

    return parkingsService.updateParking(parking)
        .then(() => {
            const {parkings} = getState().parkingsData;
            const parkingIndex = parkings.findIndex((p) => p.parkingId === parking.parkingId);
            parkings[parkingIndex] = {...parking};

            dispatch({
                type: types.PARKINGS_UPDATE_SUCCESS,
                payload: {parkings: [...parkings]},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKINGS_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const createParking = (parking) => (dispatch, getState) => {
    if (getState().parkingsData.isCreating) {
        return;
    }

    dispatch({
        type: types.PARKINGS_CREATE_REQUEST,
    });

    return parkingsService.createParking(parking)
        .then((parking) => {
            const {parkings} = getState().parkingsData;
            const copy = [...parkings];
            copy.push(parking);
            dispatch({
                type: types.PARKINGS_CREATE_SUCCESS,
                payload: {parkings: copy},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKINGS_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const tryConnectParking = (parking) => (dispatch, getState) => {
    if (getState().parkingsData.isConnecting) {
        return;
    }

    dispatch({
        type: types.PARKINGS_CONNECT_REQUEST,
    });

    return parkingsService.tryConnectParking(parking)
        .then(() => {
            dispatch({
                type: types.PARKINGS_CONNECT_SUCCESS,
                payload: {},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKINGS_CONNECT_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const tryTerminate = (parking) => (dispatch, getState) => {
    if (getState().parkingsData.isConnecting) {
        debugger
        return;
    }

    dispatch({
        type: types.PARKINGS_CONNECT_REQUEST,
    });

    return parkingsService.tryTerminate(parking)
        .then((s) => {
            debugger
            dispatch({
                type: types.PARKINGS_CONNECT_SUCCESS,
                payload: {},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKINGS_CONNECT_FAIL,
                payload: {error},
            });

            throw error;
        });
};




export const setHostName = (hostname) => (dispatch) => {

    dispatch({
        type: types.PARKING_HOSTNAME_SELECT,
        payload: {selectedHostName: hostname}
    });

};


export const processAction = () => (dispatch) => {

    dispatch({
        type: types.PROCESS_ACTION,
    });

};

export const setParking = (parking) => (dispatch) => {

    dispatch({
        type: types.PARKING_PARKING_SELECT,
        payload: {selectedParking: parking}
    });

};


export const restart = (parking, force) => (dispatch, getState) => {
    if (getState().parkingsData.isRestarting) {
        return;
    }

    dispatch({
        type: types.PARKING_RESTART_REQUEST,
    });

    return parkingsService.reconnect(parking.parkingId, force)
        .then(() => {
            dispatch({
                type: types.PARKING_RESTART_SUCCESS,
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_RESTART_FAIL,
                payload: {error},
            });

            throw error;
        });
};