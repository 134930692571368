import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import { ParkingName } from 'components/common';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: theme.spacing(20),
    width: '100%',
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiList-root': {
      marginTop: theme.spacing(10),
    },
  },
  selectEmpty: {
    borderRadius: 0,
    '&:before, &:hover:before': {
      border: 0,
    },
    '& > select': {
      height: '200px',
      padding: theme.spacing(1.25, 5, 1.25, 1.25),
      fontFamily: 'Segoe UI',
      fontSize: 13,
      lineHeight: '17px',
      color: theme.palette.base[300],
    },
    '& > svg': {
      width: 16,
      height: 16,
      fill: theme.palette.base[300],
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  option: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.base.white,
    },
  },
  parkingSymbol: {
    width: 22,
    height: 22,
    borderRadius: 2,
    background: theme.palette.base[300],
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    fontSize: 14,

    '& span': {
      margin: theme.spacing(0, 1.5),
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const ParkingSelect = (props) => {
  const classes = useStyles();

  const {
    parkings,
    placeholder,
    items,
    handleChange,
  } = props;

  const onChange = (event) => {
    handleChange(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
  //      height: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        marginTop: 50,
      },
    },
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <Select
          value=""
          onChange={onChange}
          className={classes.selectEmpty}
          variant="standard"
            MenuProps={MenuProps}
          displayEmpty
        >
          <MenuItem
            value=""
            className={classes.option}
            disabled
          >
            <div className={classes.placeholder}>
              <div className={classes.parkingSymbol} />
              <span>{placeholder}</span>
            </div>
          </MenuItem>
          {items.map((item) => (
            <MenuItem
              value={item.value}
              key={item.key}
              className={classes.option}
            >
              <ParkingName parking={parkings.find((p) => p.parkingId === item.id)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

ParkingSelect.propTypes = {
  parkings: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  items: PropTypes.array,
  handleChange: PropTypes.func,
};

ParkingSelect.defaultProps = {
  placeholder: '',
  items: [],
  handleChange: null,
};

export default ParkingSelect;
