import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';

import {CustomSwitch} from 'components/elements';
import CreateAction from 'components/modals/CreateAction';
import {getActionLabel} from './Utils';
import HostActionButton from './HostActionButton';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';

const useStyles = makeStyles((theme) => ({
    hostManagement: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(3, 0, 3, 3),
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '2px solid',
        borderLeftColor: theme.palette.base[100],
    },
    hostStatus: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    hostStatusColumn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 13,

        '& .MuiSwitch-root': {
            width: 48,
            paddingRight: 0,
        },
    },
    actionButton: {
        height: 40,
        display: 'flex',
        flexDirection: 'column',

        '& button': {
            width: '100%',
            minWidth: 'unset',
        },
    },
    hostStatusLabel: {
        color: theme.palette.base[300],
        textTransform: 'uppercase',
    },
    hostStatusValue: {
        color: theme.palette.base[800],
        textTransform: 'uppercase',
    },
}));

function HostActions(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        host,
        createParkingHostAction,
        handleCreateTaskOpen,
        userUseCases,
    } = props;

    const [openModal, setOpenModal] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);

    const clickAction = (co) => () => {
        setSelectedAction(co);
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const hostSettingsTag = (setting) => {
        return isComponentVisibleForUser(`${UC_HOST_ACTION}${setting.actionName}`, userUseCases) ?
            <div className={classes.hostStatusColumn} key={`setting_${setting.actionName}`}>
                <div className={classes.hostStatusValue}>{getActionLabel(setting.actionName, t)}</div>
                <CustomSwitch
                    disabled={!isComponentEnabledForUser(`${UC_HOST_ACTION}${setting.actionName}`, userUseCases) || host.status === 'STATUS_RED'}
                    color="primary"
                    checked={setting.actionValue}
                    handleSwitch={clickAction(setting)}
                />
            </div> : null
    };

    return (
        <div className={classes.hostManagement} /*onKeyUpCapture={(event) => {
      event.stopPropagation();
    }}*/>
            <div className={classes.actionButton}>
                {host && isComponentVisibleForUser(UC_TASK_CREATE, userUseCases) && (
                    <HostActionButton
                        action="createTask"
                        disabled={!isComponentEnabledForUser(UC_TASK_CREATE, userUseCases)}
                        icon={[<Edit/>]}
                        handleClick={(event) => {
                            if (event.screenX && event.screenY && event.screenY !== 0 && event.screenX !== 0) {
                                handleCreateTaskOpen({
                                    parkingId: host.parkingId,
                                    parkingHostId: host.parkingHostId,
                                    parkingHost: host.hostName,
                                });
                            }
                        }}
                    />
                )}
            </div>

            <div className={classes.hostStatus}>
                {host && host.actions && host.actions.map((hs) => hostSettingsTag(hs))}
            </div>

            {openModal && (
                <CreateAction
                    action={selectedAction}
                    host={host}
                    opened={openModal}
                    handleClose={handleClose}
                    createParkingHostAction={createParkingHostAction}
                />
            )}
        </div>
    );
}

HostActions.propTypes = {
    host: PropTypes.object,
    createParkingHostAction: PropTypes.func.isRequired,
    handleCreateTaskOpen: PropTypes.func.isRequired,
};

HostActions.defaultProps = {
    host: {actions: []},
};

const UC_TASK_CREATE = 'UC0038';
const UC_HOST_ACTION = 'UC0012_';

export default HostActions;
