import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import {CustomTable} from 'components/elements';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionButton} from '../../common';
import {createBranch, fetchAllBranches, filterBranches, updateBranch} from 'redux/actions/branches';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Add from "@material-ui/icons/Add";
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import {fetchAllSubjects} from "../../../redux/actions/subjects";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";


const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
    },
    content: {
        background: theme.palette.base[200],
        height: '100%',
        width: '100%',
    },
    button: {
        width: 200,
        marginLeft: 20,
        marginRight: 20,
    },
    buttonReceiptActions: {
        width: 20,
    },
    table: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        padding: theme.spacing(1, 1, 1, 1),
        justifyContent: 'space-between',
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '60px',
        padding: theme.spacing(1, 1, 1, 1),
        width: '100%',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(10),
        padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
    title: {
        height: 60,
        width: '100%',
        background: '#50A932',
        paddingRight: theme.spacing(2),
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            marginBlockStart: 0,
            marginBlockEnd: 0,
            lineHeight: '20px',
            color: theme.palette.base.white,
            '& .MuiButtonBase-root': {
                color: theme.palette.base.white,
            },
            '& input + fieldset': {
                borderColor: theme.palette.base.white
            },
        },
    },
}));

const BranchDetail = ({branch, handleChange, subjects, userUseCases}) => {

    const [error, setError] = useState({});

    const {t} = useTranslation();
    const classes = useStyles();


    return (
        <form autoComplete='off'>
            <Grid container alignItems="stretch" style={{height: '100%'}}>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.name
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        required
                        onChange={handleChange('branchName')}
                        value={branch.branchName || ''}
                        label={t('NAME')}/>
                </Grid>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.adress
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        required
                        onChange={handleChange('adress')}
                        value={branch.adress || ''}
                        label={t('ADDRESS')}/>
                </Grid>
                <Grid item xs sm={12}>
                    <TextField
                        helperText={error && error.city
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        fullWidth
                        onChange={handleChange('city')}
                        value={branch.city || ''}
                        label={t('CITY')}/>
                </Grid>
                {isComponentVisibleForUser(UC_SUBJECT_BRANCH_CHANGE, userUseCases) && <Grid item xs sm={12}>
                    <FormControl required fullWidth>
                        <InputLabel id="product-type-label">
                            {t('SUBJECT')}
                        </InputLabel>
                        <Select
                            disabled={!isComponentEnabledForUser(UC_SUBJECT_BRANCH_CHANGE, userUseCases)}
                            autoWidth
                            value={branch && branch.subjectId || ''}
                            id="productType"
                            onChange={handleChange('subjectId')}
                        >
                            {subjects.map(a => {
                                return (
                                    <MenuItem value={a.subjectId}>{a.name}</MenuItem>
                                )
                            })
                            }
                        </Select>
                        <FormHelperText>  {error && error.subjectId && (
                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                        )}</FormHelperText>
                    </FormControl>
                </Grid>}

            </Grid>
        </form>
    );
}


const BranchesFilter = ({onFilterChange, setSelectedBranch, handleChange, values, ...props}) => {
    const {t} = useTranslation();
    const classes = useStyles();


    return (
        <Paper>
            <Box p={1}>
                <Grid container spacing={1}>
                    <Grid item md>
                        <TextField
                            value={values.name}
                            name="name"
                            onChange={handleChange}
                            label={t("NAME")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            value={values.adress}
                            name="address"
                            onChange={handleChange}
                            label={t("ADDRESS")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            value={values.city}
                            name="city"
                            onChange={handleChange}
                            label={t("CITY")}/>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() => {
                                setSelectedBranch({});
                            }}
                            className={classes.addButton}
                            edge="start"
                            color="inherit"
                            aria-label="add branch">
                            <Add className={classes.addIcon}/>
                        </IconButton>

                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

const cellStyle = {
    padding: '4px 4px',
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

const BranchesList = ({
                          createBranch,
                          updateBranch,
                          branches,
                          isFetchingList,
                          filterBranches,
                          setMode,
                          handleBranchChange,
                          fetchAllBranches,
                          subjects,
                          fetchAllSubjects,
                          setSnackbarError,
                          setSnackbarOpen,
                          userUseCases,
                      }) => {
    const classes = useStyles();
    const [selectedBranch, setSelectedBranch] = useState();
    const timeoutRef = useRef(null);
    const [values, setValues] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const {t} = useTranslation();

    const handleFilterChange = (e) => {
        const newValues = {...values, [e.target.name]: e.target.value};
        setValues(newValues);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            refreshFilter(newValues);
        }, 1000);
    };

    useEffect(() => () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, []);


    const resetPagination = () => {
        setPage(0);
    };

    const handleChangeRowsPerPage = (value) => {
        resetPagination();
        setSize(value);
    };
    const handleChangePage = (value) => {
        setPage(value);
    };

    const refreshFilter = (filter, lpage, lsize) => {
        return filterBranches(filter, lpage || page, lsize || size);
    };


    const handleChange = (name) => (event) => {
        setSelectedBranch({...selectedBranch, [name]: event.target.value});
    }

    const createBranchInner = (branch) => {
        createBranch(branch).then(
            (newBranch) => {
                fetchAllBranches().then(
                    () => {
                        handleBranchChange({value: newBranch.id});
                        setMode('COMMON');
                    }
                )
            }
        ).catch((error) => {
            setSnackbarError(getErrorMessage(error, t));
            setSnackbarOpen(true);
        });

    }

    const updateBranchInner = (branch) => {
        updateBranch(branch).then(
            () => {
                refreshFilter(values)
            }
        ).catch((error) => {
            setSnackbarError(getErrorMessage(error, t));
            setSnackbarOpen(true);
        });
    }

    useEffect(() => {
        if (!loaded) {
            fetchAllSubjects();
            refreshFilter(values).then(() => {
                setLoaded(loaded);
            });
        }
    }, [loaded]);


    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (subjects) {

            let columns = [
                {
                    title: t('NAME'),
                    field: 'name',
                    cellStyle,
                    render: (rowData) => (
                        rowData.branchName
                    ),
                },
                {
                    title: t('ADDRESS'),
                    field: 'adress',
                    cellStyle,
                    render: (rowData) => (
                        rowData.adress
                    ),
                },
                {
                    title: t('CITY'),
                    field: 'city',
                    cellStyle,
                    render: (rowData) => (
                        rowData.city
                    ),
                },
                {
                    title: t('SUBJECT'),
                    field: 'subject',
                    cellStyle,
                    render: (rowData) => {
                        let sub = subjects.find(a => a.subjectId == rowData.subjectId);
                        return sub ? sub.name : ""
                    },
                },
            ];
            setColumns(columns);

        }
    }, [subjects]);


    return (
        <div
            className={classes.container}
        >
            <div className={classes.content}>
                {/* Filter */}
                <Box my={2}>
                    <BranchesFilter values={values} setSelectedBranch={setSelectedBranch}
                                    handleChange={handleFilterChange}
                    />
                </Box>
                <Grid container spacing={1} style={{flex: 1, height: '80%'}}>
                    <Grid item md={selectedBranch ? 9 : 12}>
                        <Paper className={classes.table}>
                            <CustomTable
                                title=""
                                columns={columns}
                                data={
                                    branches.branches}
                                options={{
                                    headerStyle: {
                                        padding: '0 8px 0 34px',
                                        borderTop: '1px solid #DEE2E5',
                                        lineHeight: '12px',
                                        color: '#1B1B28',
                                        textAlign: 'center',
                                    },
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedBranch && selectedBranch.tableData && selectedBranch.tableData.id === rowData.tableData.id) ? '#5985EE' : '#FFF'
                                    })
                                }}
                                isLoading={isFetchingList}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                handleChangePage={handleChangePage}
                                rowsPerPage={size}
                                pageNumber={page}
                                count={branches.paging ? branches.paging.totalSize : 0}
                                onRowClick={((evt, selectedBranch) => {
                                    setSelectedBranch(selectedBranch);
                                })}
                            />
                        </Paper>
                    </Grid>
                    <Grid item md={3}>
                        {!!selectedBranch ? (
                            <>
                                <div className={classes.title}>
                                    <h2>{selectedBranch.id ? t('BRANCH_UPDATE') : t('BRANCH_CREATE')}</h2>
                                </div>
                                <Paper className={classes.table}>
                                    <BranchDetail
                                        branch={selectedBranch} handleChange={handleChange} subjects={subjects} userUseCases={userUseCases}
                                    />
                                    <div className={classes.buttonNavigation}>
                                        <ActionButton
                                            action="close"
                                            handleClick={() => {
                                                setSelectedBranch(undefined);
                                            }}
                                        />
                                        {isComponentVisibleForUser(UC_BRANCH_UPDATE, userUseCases) &&
                                        <ActionButton
                                            disabled={!isComponentEnabledForUser(UC_BRANCH_UPDATE, userUseCases)}
                                            action={selectedBranch.id ? "save" : "saveAndClose"}
                                            handleClick={() => {
                                                selectedBranch.id ?
                                                    updateBranchInner(selectedBranch) : createBranchInner(selectedBranch)
                                            }}
                                        />}
                                    </div>
                                </Paper>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const UC_BRANCH_UPDATE = 'UC0252';
const UC_SUBJECT_BRANCH_CHANGE = 'UC0254';


const mapStateToProps = (store) => ({
    branches: store.branchesData.branchesParam,
    isFetchingList: store.branchesData.isFetchingAllBranchesList,
    subjects: store.subjectsData.subjects,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    filterBranches,
    fetchAllBranches,
    updateBranch,
    createBranch,
    fetchAllSubjects,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BranchesList);
