import reportsService from 'services/reports.service';
import types from '../actionTypes';

export const filterZReports = (filter, page, size) => async (dispatch, getState) => {
    dispatch({
        type: types.ZREPORTS_LIST_REQUEST,
        payload: filter,
    });

    try {
        const reports = await reportsService.getZReports(filter, page, size);
        dispatch({
            type: types.ZREPORTS_LIST_SUCCESS,
            payload: reports,
        });
    } catch (error) {
        dispatch({
            type: types.ZREPORTS_LIST_FAIL,
            payload: {error},
        });
    }
};

export const zReportDetail = (computerId, zReportId) => async (dispatch, getState) => {
    dispatch({
        type: types.ZREPORTS_DETAIL_REQUEST,
    });

    try {
        const report = await reportsService.getZReportDetail(computerId, zReportId);
        dispatch({
            type: types.ZREPORTS_DETAIL_SUCCESS,
            payload: report,
        });
    } catch (error) {
        dispatch({
            type: types.ZREPORTS_DETAIL_FAIL,
            payload: {error},
        });
    }
};


export const zReportCreate = (computerId, zReportId) => async (dispatch, getState) => {
    dispatch({
        type: types.ZREPORTS_DETAIL_REQUEST,
    });

    try {
        await reportsService.createZReport(computerId);
        dispatch({
            type: types.ZREPORTS_CREATION_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: types.ZREPORTS_DETAIL_FAIL,
            payload: {error},
        });
    }
};

export const zReportPrint = (computerId, zReportId) => async (dispatch, getState) => {
    dispatch({
        type: types.ZREPORTS_PRINT_REQUEST,
    });

    try {
        const res = await reportsService.printZReport(computerId, zReportId);
        dispatch({
            type: types.ZREPORTS_PRINT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: types.ZREPORTS_PRINT_FAIL,
            payload: {error},
        });
    }
};

export const xReportPrint = (computerId) => async (dispatch, getState) => {
    dispatch({
        type: types.XREPORTS_PRINT_REQUEST,
    });

    try {
        const res = await reportsService.printXReport(computerId);
        dispatch({
            type: types.XREPORTS_PRINT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: types.XREPORTS_PRINT_FAIL,
            payload: {error},
        });
    }
};



