import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import PMCDialog from "../common/PMCDialog";
import PMCSnackbar from 'components/common/Snackbar';
import { getErrorMessage2 } from 'utils';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        marginBottom: theme.spacing(4.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    label: {
        width: theme.spacing(11),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    content: {
        padding: theme.spacing(0, 10),
    },
    textField: {
        flex: 1,

        '& .MuiInputBase-input': {
            textAlign: 'right',
        },
    },
    zoneStatus: {
        marginTop: theme.spacing(4.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

        '&:last-child': {
            marginLeft: theme.spacing(6),
        },
    },
    zoneStatusLabel: {
        fontSize: 14,
        color: theme.palette.secondary.main,
    },
    zoneStatusValue: {
        fontWeight: 'bold',
    },
    error: {
        color: theme.palette.primary.red,

        '& .MuiInput-underline:before': {
            borderColor: theme.palette.primary.red,
        },

        '& .MuiInputBase-input': {
            color: theme.palette.primary.red,
        },

        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderColor: theme.palette.primary.red,
        },
    },
}));

function EditZone(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        opened,
        event,
        updateZone,
        handleClose,
    } = props;

    const [state, setState] = useState({
        capacity: event.capacity || 0,
        occupancy: event.occupancy || 0,
        occupancyReserved: event.occupancyReserved || 0,
        zoneGmtpId: event.zoneGmtpId || 0,
        parkingId: event.parkingId,
        show: event.show || false,
        zoneId: event.zoneId,
        zoneName: event.zoneName || '',
        reserved: event.reserved || 0,
        freeOthers: event.capacity - event.reserved - event.occupancy,
        freeReserved: event.reserved - event.occupancyReserved,
    });

    const [errorMessage, setErrorMessage] = useState(undefined);

    const handleChange = (field) => (event) => {
        const newValue = event.target.value ? parseInt(event.target.value, 10) : 0;
        setState({
            ...state,
            [field]: newValue,
        });
    };

    const checkValidity = (field) => {
        switch (field) {
            case 'reserved':
                return state.reserved >= 0 && state.capacity >= state.reserved;
            case 'freeReserved':
                return state.freeReserved <= state.reserved;
            case 'freeOthers':
                return state.freeOthers <= state.capacity - state.reserved;
            default:
                return true;
        }
    };

    const handleErrorClose = ()=> {
        setErrorMessage(undefined);
    }

    const handleSubmit = () => {
        if (!checkValidity('reserved') || !checkValidity('freeReserved') || !checkValidity('freeOthers')) {
            return;
        }

        updateZone({
            capacity: state.capacity,
            occupancy: state.capacity - state.reserved - state.freeOthers,
            occupancyReserved: state.reserved - state.freeReserved,
            zoneGmtpId: state.zoneGmtpId,
            parkingId: event.parkingId,
            show: state.show,
            zoneId: event.zoneId,
            zoneName: state.zoneName,
            reserved: state.reserved,
        })
            .then(handleClose)
            .catch((error) => {
                setErrorMessage(getErrorMessage2(error, t));
            });
    };

    return (

        <PMCDialog
            size="xs"
            headerColor={"#a96e33"}
            actionType="saveModal"
            open={opened}
            title={`${state.zoneName} - detail`}
            onClose={handleClose}
            onSubmit={handleSubmit}
        >
            <Box className={classes.formGroup}>
                <PMCSnackbar message={errorMessage} onClose={handleErrorClose} severity='error' open={errorMessage}/>
                <div className={classes.zoneStatus}>
                    <Typography variant="h6" className={classes.zoneStatusLabel}>{t('CAPACITY')}</Typography>
                    <Typography
                        variant="h1"
                        className={classnames(classes.zoneStatusValue)}
                    >
                        {state.capacity}
                    </Typography>
                </div>
                <div className={classes.zoneStatus}>
                    <Typography variant="h6" className={classes.zoneStatusLabel}>{t('OTHERS')}</Typography>
                    <Typography
                        variant="h1"
                        className={classnames(classes.zoneStatusValue)}
                    >
                        {state.capacity >= state.reserved ? state.capacity - state.reserved : 0}
                    </Typography>
                </div>
            </Box>

            <Box className={classes.formGroup}>
                <Typography
                    variant="button"
                    className={classnames(classes.label, !checkValidity('reserved') && classes.error)}
                >
                    {t('RESERVED')}
                </Typography>
                <TextField
                    autoFocus
                    required
                    className={classnames(classes.textField, !checkValidity('reserved') && classes.error)}
                    hiddenLabel
                    type="number"
                    value={state.reserved.toString()}
                    onChange={handleChange('reserved')}
                />
            </Box>

            <Box className={classes.formGroup}>
                <Typography
                    variant="button"
                    className={classnames(classes.label, !checkValidity('freeReserved') && classes.error)}
                >
                    {t('FREE_RESERVED')}
                </Typography>
                <TextField
                    required
                    className={classnames(classes.textField, !checkValidity('freeReserved') && classes.error)}
                    hiddenLabel
                    type="number"
                    value={state.freeReserved.toString()}
                    onChange={handleChange('freeReserved')}
                />
            </Box>

            <Box className={classes.formGroup}>
                <Typography
                    variant="button"
                    className={classnames(classes.label, !checkValidity('freeOthers') && classes.error)}
                >
                    {t('FREE_OTHERS')}
                </Typography>
                <TextField
                    required
                    className={classnames(classes.textField, !checkValidity('freeOthers') && classes.error)}
                    hiddenLabel
                    type="number"
                    value={state.freeOthers.toString()}
                    onChange={handleChange('freeOthers')}
                />
            </Box>
        </PMCDialog>

    );
}

EditZone.propTypes = {
    opened: PropTypes.bool.isRequired,
    event: PropTypes.object.isRequired,
    updateZone: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default EditZone;
