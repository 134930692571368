import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { CustomTabs } from 'components/elements';
import { DisappointedIcon } from 'assets/Icons';
import MobileOperatorStatistics from './MobileOperatorStatistics/index';
import MobileOperatorTasks from './MobileOperatorTasks';
import ParkingAccess from './ParkingAccess';
import EquipmentErrors from './EquipmentErrors';
import {isComponentVisibleForUser} from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  disconnectedContent: {
    width: '100%',
    height: '416px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.base[300],
    textAlign: 'center',
  },
  disconnectedIcon: {
    marginBottom: theme.spacing(1.5),
  },
}));

function ParkingManagement(props) {
  const {
    userUseCases,
    parking,
    allCodeList,
    tasks,
    taskImages,
    isCanceling,
    isRenewing,
    parkingAssignedUsers,
    parkingNotAssignedUsers,
    errors,
    handleTaskDetailOpen,
    handleCreateTaskOpen,
    fetchParkingErrors,
    fetchParkingTasks,
    fetchParkingAssignedUsers,
    fetchParkingNotAssignedUsers,
    cancelTask,
    renewTask,
    fetchParkingTasksStatistics,
    parkingTasksStatistics,
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (value) => {
    setActiveTab(value);
  };
  const checkActivated = (value) => {
    if (activeTab === -1) return true;

    return value === activeTab;
  };

  const tabs = [
  ];

  const notConnectedTabContent = [
    <div className={classes.disconnectedContent}>
      <DisappointedIcon className={classes.disconnectedIcon} />
      <Typography variant="subtitle1">
        {t('PARKING_DATA_IS_NOT_AVAILABLE_A_THE_MOMENT_DUE_TO_LOST_OF_CONNECTION_WITH_PARKING')}
      </Typography>
    </div>,
  ];

  const tabContents = [
  ];

  if (isComponentVisibleForUser(UC_TAB_ACCESS, userUseCases)) {
    tabs.push(t('PARKING_ACCESS'));
    tabContents.push(<ParkingAccess
        userUseCases={userUseCases}
        active={checkActivated(tabContents.length)}
        parking={parking}
        parkingAssignedUsers={parkingAssignedUsers}
        parkingNotAssignedUsers={parkingNotAssignedUsers}
        fetchParkingAssignedUsers={fetchParkingAssignedUsers}
        fetchParkingNotAssignedUsers={fetchParkingNotAssignedUsers}
    />);
  };

  if (isComponentVisibleForUser(UC_TAB_ERRORS, userUseCases)) {
    tabs.push(t('EQUIPMENT_ERRORS'));
    tabContents.push(    <EquipmentErrors
        userUseCases={userUseCases}
        active={checkActivated(tabContents.length)}
        parking={parking}
        allCodeList={allCodeList}
        equipmentErrors={errors}
        fetchParkingErrors={fetchParkingErrors}
    />);
  };

  if (isComponentVisibleForUser(UC_TAB_TASKS, userUseCases)) {
    tabs.push(t('MOBILE_OPERATOR_TASKS'));
    tabContents.push(
        <MobileOperatorTasks
            userUseCases={userUseCases}
            active={checkActivated(tabContents.length)}
            parking={parking}
            allCodeList={allCodeList}
            parkingTasks={tasks}
            taskImages={taskImages}
            isCanceling={isCanceling}
            isRenewing={isRenewing}
            handleTaskDetailOpen={handleTaskDetailOpen}
            handleCreateOpen={handleCreateTaskOpen}
            fetchParkingTasks={fetchParkingTasks}
            cancelTask={cancelTask}
            renewTask={renewTask}
        />);
  };

  if (isComponentVisibleForUser(UC_TAB_STATISTICS, userUseCases)) {
    tabs.push(t('MOBILE_OPERATOR_STATISTICS'));
    tabContents.push(<MobileOperatorStatistics
        userUseCases={userUseCases}
        active={checkActivated(tabContents.length)}
        parking={parking}
        fetchParkingTasksStatistics={fetchParkingTasksStatistics}
        parkingTasksStatistics={parkingTasksStatistics}
    />);
  };

  return (
    <div className={classes.container}>
      <CustomTabs
        tabs={tabs}
        tabContents={parking.isConnected ? tabContents : notConnectedTabContent}
        disabled={!parking.isConnected}
        variant="fullWidth"
        handleChangeTab={handleChangeTab}
      />
    </div>
  );
}

ParkingManagement.propTypes = {
  parking: PropTypes.object.isRequired,
  allCodeList: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  taskImages: PropTypes.array.isRequired,
  isCanceling: PropTypes.bool.isRequired,
  isRenewing: PropTypes.bool.isRequired,
  parkingAssignedUsers: PropTypes.array.isRequired,
  parkingNotAssignedUsers: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  handleTaskDetailOpen: PropTypes.func.isRequired,
  handleCreateTaskOpen: PropTypes.func.isRequired,
  fetchParkingErrors: PropTypes.func.isRequired,
  fetchParkingTasks: PropTypes.func.isRequired,
  fetchParkingAssignedUsers: PropTypes.func.isRequired,
  fetchParkingNotAssignedUsers: PropTypes.func.isRequired,
  cancelTask: PropTypes.func.isRequired,
  renewTask: PropTypes.func.isRequired,
  fetchParkingTasksStatistics: PropTypes.func.isRequired,
  parkingTasksStatistics: PropTypes.array.isRequired,
};

const UC_TAB_ACCESS = 'UC0062';
const UC_TAB_ERRORS = 'UC0065';
const UC_TAB_TASKS = 'UC0066';
const UC_TAB_STATISTICS = 'UC0067';

export default ParkingManagement;
