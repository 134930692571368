import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { CustomMultiSelect, CustomSearchInput } from 'components/elements';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    padding: theme.spacing(0, 1.25),
  },
  filter: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      width: 150,
      marginLeft: theme.spacing(4),
    },

    '& > div:first-child': {
      marginLeft: 0,
    },
  },
  toolbarInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  addButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    backgroundColor: theme.palette.base[100],
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1.125),
  },
  addIcon: {
    width: 30,
    height: 30,
  },
}));

const getOperators = (mobileDevices) => {
  const userIds = [];
  const mobileOperators = [];
  mobileDevices.forEach((device) => {
    if (userIds.indexOf(device.mobileUserId) === -1) {
      mobileOperators.push({
        value: device.mobileUserId,
        label: device.mobileUser,
        key: `mobile_operator_${device.mobileUserId}`,
      });
      userIds.push(device.mobileUserId);
    }
  });

  return mobileOperators;
};

const getDevices = (mobileDevices) => {
  const deviceIds = [];
  const devices = [];
  mobileDevices.forEach((device) => {
    if (deviceIds.indexOf(device.mobileDeviceId) === -1) {
      devices.push({
        value: device.mobileDeviceId,
        label: device.deviceId,
        key: `mobile_device_${device.mobileDeviceId}`,
      });
      deviceIds.push(device.mobileDeviceId);
    }
  });

  return devices;
};

const getDeactivateUsers = (mobileDevices) => {
  const deactivatedUserIds = [];
  const deactivateUsers = [];
  mobileDevices.forEach((device) => {
    if (device.deactivatedUserId && deactivatedUserIds.indexOf(device.deactivatedUserId) === -1) {
      deactivateUsers.push({
        value: device.deactivatedUserId,
        label: device.deactivatedUser,
        key: `mobile_deactivate_by_${device.deactivatedUserId}`,
      });
      deactivatedUserIds.push(device.deactivatedUserId);
    }
  });

  return deactivateUsers;
};

function MobileDevicesToolBar(props) {
  const classes = useStyles();

  const {
    mobileDevices,
    mobileOperator,
    device,
    deactivatedBy,
    handleMobileOperator,
    handleDevice,
    handleDeactivatedBy,
    handleSearch,
  } = props;

  const mobileOperators = getOperators(mobileDevices);
  const devices = getDevices(mobileDevices);
  const deactivateUsers = getDeactivateUsers(mobileDevices);

  const { t } = useTranslation();

  return (
    <div className={classes.toolbar}>
      <div className={classes.filter}>
        <CustomMultiSelect
          items={mobileOperators}
          label={t('MOBILE_OPERATOR')}
          value={mobileOperator}
          handleChange={handleMobileOperator}
        />
        <CustomMultiSelect
          items={devices}
          label={t('ALL_DEVICES')}
          value={device}
          handleChange={handleDevice}
        />
        <CustomMultiSelect
          items={deactivateUsers}
          label={t('DEACTIVATED_BY')}
          value={deactivatedBy}
          handleChange={handleDeactivatedBy}
        />
      </div>

      <div className={classes.toolbarInput}>
        <div className={classes.addButtonWrapper}>
          <CustomSearchInput
            placeholder={t('DEVICE_ID_OR_OPERATOR')}
            handleChange={handleSearch}
          />
        </div>
      </div>
    </div>
  );
}

MobileDevicesToolBar.propTypes = {
  mobileDevices: PropTypes.array.isRequired,
  mobileOperator: PropTypes.array.isRequired,
  device: PropTypes.array.isRequired,
  deactivatedBy: PropTypes.array.isRequired,
  handleMobileOperator: PropTypes.func.isRequired,
  handleDevice: PropTypes.func.isRequired,
  handleDeactivatedBy: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default MobileDevicesToolBar;
