import httpService from './http.service';

const getParkingUsers = (parkingId) => httpService
    .get(`/users/${parkingId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getParkingAssignedUsers = (subjectId, parkingId) => httpService
    .get(`/allusers/${subjectId}/${parkingId}?assigned=true`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getParkingNotAssignedUsers = (subjectId, parkingId) => httpService
    .get(`/allusers/${subjectId}/${parkingId}?assigned=false`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getUsersForSubject = (subjectId) => httpService
    .get(`/users/subject/${subjectId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getUsersAll = () => httpService
    .get(`/user/all`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));
const getUsersAllByRole = (roleId) => httpService
    .get(`/user/all/role/${roleId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const addParkingToUser = (subjectId, userId, parkingId) => httpService
    .put(`/users/${subjectId}/${userId}/${parkingId}?add=true`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const removeParkingFromUser = (subjectId, userId, parkingId) => httpService
    .put(`/users/${subjectId}/${userId}/${parkingId}?add=false`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const searchUsers = (search) => httpService
    .get(`/user/all`)
    .then(({data}) => data)
    .catch((err) => {throw err});

const createUser = (user) => httpService
    .post(`/user/create`, user)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateUser = (user) => httpService
    .put(`/user/update`, user)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const updateUserState = (userId, active) => httpService
    .put(`/user/${userId}?active=${active}`)
    .then()
    .catch((err) => Promise.reject(err));

export default {
    getUsersForSubject,
    searchUsers,
    getParkingUsers,
    getParkingAssignedUsers,
    getParkingNotAssignedUsers,
    addParkingToUser,
    removeParkingFromUser,
    getUsersAll,
    getUsersAllByRole,
    createUser,
    updateUser,
    updateUserState,
};
