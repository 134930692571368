import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  CreateGeneralTask,
  TaskDetails,
  EditZone,
  CardDetails,
  OwnerDetails,
  CreateGeneralCard,
  EditMultiCards,
  BlockCard,
  ConfirmCard,
  ConfirmMultipleCards,
  CameraDetails
} from 'components/modals';
import { getCards } from 'utils';

function ParkingModals(props) {
  const {
    cardNumber,
    user,
    parkingId,
    hostname,
    parkings,
    parkingCards,
    parkingHosts,
    allCodeList,
    taskDetailModal,
    cameraDetailModal,
    ownerDetailModal,
    createTaskModal,
    editZoneModal,
    cardDetailModal,
    createCardModal,
    editCardModal,
    blockCardModal,
    confirmCardModal,
    handleTaskDetailClose,
    handleCameraDetailClose,
    handleOwnerDetailClose,
    handleCreateClose,
    handleCreateCardClose,
    handleCreateCardCloseAndEdit,
    handleCardDetailClose,
    handleEditZoneClose,
    handleEditCardClose,
    handleBlockCardOpen,
    handleBlockCardClose,
    handleConfirmCardOpen,
    handleConfirmCardClose,
    createTask,
    updateZone,
    createCard,
    bulkEditCards,
    fetchSubjectOwners,
    userUseCases,
  } = props;

  const [confirmMultipleCardsModal, setConfirmMultipleCardsModal] = useState({
    opened: false,
    event: null,
    blockingReason: null,
    type: null,
  });
  const handleConfirmMultipleCardsOpen = (event, blockingReason, type) => {
    setConfirmMultipleCardsModal({
      opened: true,
      event,
      blockingReason,
      type,
    });
  };
  const handleConfirmMultipleCardsClose = () => {
    setConfirmMultipleCardsModal({
      opened: false,
      event: null,
      blockingReason: null,
      type: null,
    });
  };

  function getHost() {
    let result;
    parkingHosts.forEach(function (host) {
      if ( host.hostName == hostname ) {
        result = host;
      }
    });
    return result;
  }

  return (
    <>
      {taskDetailModal.event && (
        <TaskDetails
          opened={taskDetailModal.opened}
          parkings={parkings}
          task={taskDetailModal.event.task}
          image={taskDetailModal.event.image}
          imageModal={taskDetailModal.event.imageModal}
          handleClose={handleTaskDetailClose}
          userUseCases={userUseCases}
        />
      )}

      {cameraDetailModal.event && (
        <CameraDetails
          parkingHosts={parkingHosts}
          opened={cameraDetailModal.opened}
          host={cameraDetailModal.event.host}
          imageModal={cameraDetailModal.event.imageModal}
          handleClose={handleCameraDetailClose}
          userUseCases={userUseCases}
        />
      )}

      {ownerDetailModal.event && (
        <OwnerDetails
          opened={ownerDetailModal.opened}
          parkings={parkings}
          owner={ownerDetailModal.event}
          handleClose={handleOwnerDetailClose}
          user={user}
          userUseCases={userUseCases}
        />
      )}

      {createTaskModal.event && (
        <CreateGeneralTask
          user={user}
          event={createTaskModal.event}
          opened={createTaskModal.opened}
          parkings={parkings}
          parkingHosts={parkingHosts}
          createTask={createTask}
          handleClose={handleCreateClose}
          userUseCases={userUseCases}
        />
      )}

      {editZoneModal.event && (
        <EditZone
          opened={editZoneModal.opened}
          event={editZoneModal.event}
          updateZone={updateZone}
          handleClose={handleEditZoneClose}
          userUseCases={userUseCases}
        />
      )}

      {cardDetailModal.event && (
        <CardDetails
          opened={cardDetailModal.opened}
          parkingId={parkingId}
          card={cardDetailModal.event}
          parkingCards={getCards(parkingCards)}
          parkings={parkings}
          handleClose={handleCardDetailClose}
          handleBlockCardOpen={handleBlockCardOpen}
          handleConfirmCardOpen={handleConfirmCardOpen}
          userUseCases={userUseCases}
        />
      )}

      {createCardModal.event && (
        <CreateGeneralCard
          cardNumber={cardNumber}
          user={user}
          opened={createCardModal.opened}
          parkings={parkings}
          allCodeList={allCodeList}
          event={createCardModal.event}
          createCard={createCard}
          handleClose={handleCreateCardClose}
          handleCloseAndEdit={handleCreateCardCloseAndEdit}
          fetchSubjectOwners={fetchSubjectOwners}
          userUseCases={userUseCases}
        />
      )}

      {editCardModal.event && (
        <EditMultiCards
          opened={editCardModal.opened}
          event={editCardModal.event}
          handleClose={handleEditCardClose}
          bulkEditCards={bulkEditCards}
          handleConfirmMultipleCardsOpen={handleConfirmMultipleCardsOpen}
          userUseCases={userUseCases}
        />
      )}

      {blockCardModal.event && (
        <BlockCard
          opened={blockCardModal.opened}
          event={blockCardModal.event}
          handleClose={handleBlockCardClose}
          userUseCases={userUseCases}
        />
      )}

      {confirmCardModal.event && (
        <ConfirmCard
          opened={confirmCardModal.opened}
          event={confirmCardModal.event}
          type={confirmCardModal.type}
          handleClose={handleConfirmCardClose}
          userUseCases={userUseCases}
        />
      )}

      {confirmMultipleCardsModal.event && (
        <ConfirmMultipleCards
          opened={confirmMultipleCardsModal.opened}
          event={confirmMultipleCardsModal.event}
          blockingReason={confirmMultipleCardsModal.blockingReason}
          type={confirmMultipleCardsModal.type}
          bulkEditCards={bulkEditCards}
          handleClose={handleConfirmMultipleCardsClose}
          userUseCases={userUseCases}
        />
      )}
    </>
  );
}

ParkingModals.propTypes = {
  user: PropTypes.object,
  parkingId: PropTypes.number,
  parkings: PropTypes.array.isRequired,
  parkingCards: PropTypes.array.isRequired,
  parkingHosts: PropTypes.array.isRequired,
  allCodeList: PropTypes.array.isRequired,
  taskDetailModal: PropTypes.object.isRequired,
  ownerDetailModal: PropTypes.object.isRequired,
  createTaskModal: PropTypes.object.isRequired,
  editZoneModal: PropTypes.object.isRequired,
  cardDetailModal: PropTypes.object.isRequired,
  createCardModal: PropTypes.object.isRequired,
  editCardModal: PropTypes.object.isRequired,
  blockCardModal: PropTypes.object.isRequired,
  confirmCardModal: PropTypes.object.isRequired,
  handleTaskDetailClose: PropTypes.func.isRequired,
  handleOwnerDetailClose: PropTypes.func.isRequired,
  handleCreateClose: PropTypes.func.isRequired,
  handleCreateCardClose: PropTypes.func.isRequired,
  handleCardDetailClose: PropTypes.func.isRequired,
  handleEditZoneClose: PropTypes.func.isRequired,
  handleEditCardClose: PropTypes.func.isRequired,
  handleBlockCardOpen: PropTypes.func.isRequired,
  handleBlockCardClose: PropTypes.func.isRequired,
  handleConfirmCardOpen: PropTypes.func.isRequired,
  handleConfirmCardClose: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  updateZone: PropTypes.func.isRequired,
  createCard: PropTypes.func.isRequired,
  bulkEditCards: PropTypes.func.isRequired,
  fetchSubjectOwners: PropTypes.func.isRequired,
};

ParkingModals.defaultProps = {
  user: null,
  parkingId: -1,
};

export default ParkingModals;
