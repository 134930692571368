import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment/min/moment-with-locales';

import {fetchEnum} from 'redux/actions/hosts';
import {CustomButton, Loading} from 'components/elements';
import {getCommandProps} from 'containers/Parking/LocalParking/LocalParkingHosts/Utils';
import EnumerationTable from './EnumerationTable';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import Print from "@material-ui/icons/Print";
import i18next from "i18next";
import {CsvBuilder} from 'filefy';
import Download from '@material-ui/icons/GetApp';

import {getErrorMessage2} from 'utils';
import PMCSnackbar from '../common/Snackbar';

const useStyles = makeStyles((theme) => ({
    title: {
        background: "#004980",
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    formGroup: {
        marginBottom: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    formGroup2: {
        marginBottom: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    label: {
        width: theme.spacing(13),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    textField: {
        flex: 1,
        marginLeft: theme.spacing(3),
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    actionButton: {
        marginLeft: theme.spacing(1),
        '@media print': {
            display: 'none'
        }
    },
    noPrint: {
        '@media print': {
            display: 'none'
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.base.white,
    },
    labelText: {
        width: '100%',
        color: '#A2A2A2',
        textAlign: 'center'
    },
    dialogPaper: {
        height: props => `${props.height}px`,
    },
}));

function CreateCommand(props) {
    const {t} = useTranslation();

    const {
        command,
        opened,
        handleClose,
        createParkingHostCommand,
        host,
        fetchEnum,
        enums,
        isFetchingEnum,
        isCreatingCommand,
        errors,
    } = props;


    const [state, setState] = useState({
        commandName: command.commandName,
        commandType: command.commandType,
        commandCode: command.commandCode,
    });

    const handleChange = (field, index) => (event) => {
        let items = state.commandDialogMessage;
        if ( ! items ) items = new Array();
        items[index?index:0] = event.target.value;

        setState({
            ...state,
            ['commandDialogMessage']: items,
        });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleSubmit = useCallback(() => {
        createParkingHostCommand(host.parkingHostId, {
            commandName: state.commandName,
            commandType: state.commandType,
            commandCode: state.commandCode,
            commandDialogMessage: state.commandDialogMessage,
        })
            .then(handleClose)
            .catch((error) => {
                console.log(error);
                setSnackbarOpen(true);
                setErrorAction(getErrorMessage2(error, t));
            });
    });

    const [isFetchedEnums, setIsFetchedEnums] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorAction, setErrorAction] = useState('');


    const fetchEnumForHost = () => {
        fetchEnum(host.parkingHostId);
    };

    const getEnumerations = useCallback(() => {
        createParkingHostCommand(host.parkingHostId, {
            commandName: state.commandName,
            commandType: state.commandType,
            commandCode: state.commandCode,
            commandDialogMessage: state.commandDialogMessage,
        })
            .then(fetchEnumForHost)
            .catch((err)=>{
                setSnackbarOpen(true);
                setErrorAction(getErrorMessage2(err, t));
            });
    });

    const fetchEnumerations = useCallback(() => {
        if (command && !isFetchedEnums && command.commandName === 'cmd_BtnEnumeration') {
            getEnumerations();
            setIsFetchedEnums(true);
        }
    }, [command, getEnumerations, isFetchedEnums]);

    useEffect(() => {
        fetchEnumerations();
    }, [fetchEnumerations]);


    const [enumsItems, setEnumsItems] = useState([]);
    const [enumDate, setEnumDate] = useState();


    useEffect(() => {

        if (enums && enums.items) {
            setEnumsItems(enums.items.concat(
                {
                    "nominalValue": t('TOTAL'),
                    "dispensable": enums.sumDispensable,
                    "cashBox": enums.sumCashBox,
                    "sum": enums.tolalSum
                })
            );
            setEnumDate(moment(new Date(enums && enums.date ? enums.date : new Date())).locale(i18next.language).format('L LTS'));
        } else {
            setEnumsItems([]);
            setEnumDate(null);
        }
    }, [enums]);

    const getDialogHeight = (command) => {
        if (command.commandDialogType  === 'CMD_DIALOG_TYPE_YES_NO')
            return 200
        else if (command.commandDialogType === 'CMD_DIALOG_TYPE_REASON')
            return 300
        else 
            return 750
    }

    const getDialogWidth = (command) => {
        if (command.commandDialogType  === 'CMD_DIALOG_TYPE_YES_NO')
            return "xs";
        else if (command.commandDialogType === 'CMD_DIALOG_TYPE_REASON')
            return "md"
        else 
            return "lg"
    }



    const classes = useStyles({height: getDialogHeight(command),
        flexDirection: 'column',
    }

    );

    const items = [];
    for ( let i=0; i<host.rowCount; i++) {
        items.push(
        <Box className={classes.formGroup}>
        <Typography
            variant="button"
            className={classes.label}
        >
            {t('DISPLAY_TEXT')} {i}:
        </Typography>
        <TextField
            autoFocus
            required
            className={classes.textField}
            hiddenLabel
            type="commandDialogMessage"
            value={ state.commandDialogMessage ? state.commandDialogMessage[i] : ''}
            onChange={handleChange('commandDialogMessage',i)}
            key={'commandDialogMessage-'||i}
        />
        <br></br>
        </Box>);
    }

    return (
        <Dialog
            maxWidth={getDialogWidth(command)}
            classes={{paper: classes.dialogPaper}}
            disableEnforceFocus
            open={opened}
            onClose={handleClose}
            onKeyUp={(e) => {
                if (opened) {
                    console.info("Dialog submit");
                    const ENTER = 13;
                    if (e.keyCode === ENTER) {
                        handleSubmit && handleSubmit();
                    }
                }
            }
            }
        >


            <DialogTitle
                className={classes.title}>{command.commandName === 'cmd_BtnEnumeration' ? host.hostShortName : t('SEND_COMMAND_TITLE')}
                {command.commandName === 'cmd_BtnEnumeration' &&
                <Typography style={{
                    fontWeight: 400, paddingRight: '60px', fontSize: 17,
                }}>
                    {enumDate}
                </Typography>
                }
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>

            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={errorAction}/>

            <DialogContent className={classes.content}>

                {(isFetchingEnum || isCreatingCommand) && <Loading/>}

                {command.commandName === 'cmd_BtnEnumeration' && enums && enums.items && enums.items[0].nominalValue===null && (
                    <div style={{margin: '0 auto'}}>{t('EMPTY_ENUM_MESSAGE')}</div>
                )}

                {command.commandName === 'cmd_BtnEnumeration' && enums && enums.items && enums.items[0].nominalValue!==null && (
                    <EnumerationTable
                        items={enumsItems}
                        isLoading={isFetchingEnum}/>
                )}

                {command.commandName === 'cmd_BtnEnumeration' && errors && errors.data && errors.data.message && (
                    errors.data.message
                )}

                {command.commandDialogType === 'CMD_DIALOG_TYPE_YES_NO' && (
                    <Typography className={classes.labelText}>
                        {t('CREATE_COMMAND_YES_NO', {reason: getCommandProps(command.commandName, t).label})}
                    </Typography>
                )}

                {command.commandDialogType === 'CMD_DIALOG_TYPE_REASON' && (
                    <Box className={classes.formGroup}>
                        <Typography
                            variant="button"
                            className={classes.label}
                        >
                            {t('REASON')}
                        </Typography>
                        <TextField
                            autoFocus
                            required
                            className={classes.textField}
                            hiddenLabel
                            type="commandDialogMessage"
                            value={state.commandDialogMessage || ''}
                            onChange={handleChange('commandDialogMessage')}
                        />
                    </Box>
                )}

                {command.commandDialogType === 'CMD_DIALOG_TYPE_TEXT' && (
                    <Box className={classes.formGroup2}>
                        {items}
                    </Box>
                )}
            </DialogContent>

            <DialogActions className={classes.actions}>
                <CustomButton
                    className={classes.actionButton}
                    label={t(command.commandDialogType === 'CMD_DIALOG_TYPE_YES_NO' ? 'NO' : 'CLOSE')}
                    color="grey"
                    width={180}
                    onClick={handleClose}
                />
                {command.commandName !== 'cmd_BtnEnumeration' && (
                    <CustomButton
                        label={t(command.commandDialogType === 'CMD_DIALOG_TYPE_YES_NO' ? 'YES' : 'CONFIRM')}
                        width={180}
                        muiIcon={<Check/>}
                        color="green"
                        onClick={handleSubmit}
                    />
                )}
                {command.commandName === 'cmd_BtnEnumeration' && (
                    <CustomButton
                        className={classes.noPrint}
                        label={t('PRINT')}
                        width={180}
                        muiIcon={<Print/>}
                        color="blue"
                        onClick={() => {
                            window.print();
                        }}
                    />
                )}
                {command.commandName === 'cmd_BtnEnumeration' && (
                    <CustomButton
                        className={classes.noPrint}
                        label={t('EXPORT')}
                        width={180}
                        muiIcon={<Download/>}
                        color="blue"
                        onClick={() => {
                            new CsvBuilder(`${host.hostShortName}-${enumDate}.csv`)
                                .setColumns([t('NOMINAL'), t('DISPENSABLE'), t('CASHBOX'), t('SUM')])
                                .addRows(enumsItems.map(a => [a.nominalValue, a.dispensable, a.cashBox, a.sum]))
                                .exportFile();
                        }}
                    />
                )}
            </DialogActions>
        </Dialog>
    );
}

CreateCommand.propTypes = {
    command: PropTypes.object,
    host: PropTypes.object,
    opened: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    createParkingHostCommand: PropTypes.func.isRequired,
    fetchEnum: PropTypes.func,
    enums: PropTypes.object,
};

CreateCommand.defaultProps = {
    command: null,
    host: null,
    fetchEnum: null,
    enums: {items: []},
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    enums: store.hostsData.enums,
    isFetchingEnum: store.hostsData.isFetchingEnum,
    errors: store.hostsData.errors,
    isCreatingCommand: store.hostsData.isCreatingCommand,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchEnum,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateCommand);
