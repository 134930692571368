import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {ArrowBackOutlined, ArrowForwardOutlined} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import SubjectsList from '../SubjectsList';
import IconButton from "@material-ui/core/IconButton";
import {isComponentVisibleForUser} from "../../../utils";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {CustomSwitch} from "../../elements";
import {tryConnectParking} from "../../../redux/actions/parkings";
import BranchesList from "../BranchesList";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Box, Checkbox, Chip, Divider, Paper, Tooltip } from '@material-ui/core';

const filter = createFilterOptions();


const useStyles = makeStyles((theme) => ({

    editor: {
        height: '100%',
        width: '100%',
        marginTop: '4px',
    },
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-start',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    description: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.06px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    icon: {
        height: '20px',
        width: '20px',
    },
    iconButton: {
        backgroundColor: theme.palette.base[100],
        padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
        marginBottom: '10px',
        marginRight: '5px',
    },
    root: {
        alignItems: 'flex-start',
    },
    addButton: {
        width: '100%',
        minWidth: '0px',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },

}));

function PosDetail(props) {
    const classes = useStyles();

    const {
        userUseCases,
        handleChange,
        updatedPos,
        subjectValues,
        productValues,
        branchValues,
        error,
        isValid,
        subjects,
        mode,
        setMode,
        handleSubject,
        handleProduct,
        handleBranch,
        readOnly,
        getSubject,
        getBranch,
        setSnackbarError,
        setSnackbarOpen,
        handleNumberChange,
        currencyValues,
        hasCertificate,
    } = props;

    const {t} = useTranslation();

    const [currentKey, setCurrentKey] = useState('');
    const [currentValue, setCurrentValue] = useState('');
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const handleToggleSelectAll = () => {
        setSelectAll((prev) => {
          if (!prev) handleProduct([...productValues]);
          else handleProduct([]);
          return !prev;
        });
      };

    return (
        <div className={classes.formFields}>
            {mode === 'COMMON' && <form autoComplete='off' style={{width: '100%'}}>

                <Grid container spacing={2}>
                    <Grid item xs sm={12}>
                        <TextField
                            helperText={error && error.name
                                ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                : null}
                            fullWidth
                            required
                            disabled={readOnly || hasCertificate}
                            id="name"
                            value={updatedPos.name || ''}
                            onChange={handleChange('name')}
                            label={t('NAME')}/>
                    </Grid>
                    <Grid item sm={12}>
                        <Autocomplete
                            value={getSubject(updatedPos.subjectId, subjectValues)}
                            disabled={readOnly || hasCertificate}
                            disableClearable
                            onChange={(event, newValue) => {
                                handleSubject(newValue);
                            }}
                            forcePopupIcon={false}
                            filterSelectedOptions
                            options={subjectValues}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            style={{width: '100%'}}
                            renderInput={(params) => (
                                <TextField {...params} label={t('SUBJECT')} required fullWidth
                                           helperText={error && error.subjectId
                                               ? <span
                                                   className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                               : null}
                                           InputProps={{
                                               ...params.InputProps,
                                               endAdornment: (
                                                   <InputAdornment position="end">
                                                       {isComponentVisibleForUser(UC_MENU_SUBJECT_SEARCH, userUseCases) &&
                                                           <IconButton onClick={() => {
                                                               setMode('SUBJECT')
                                                           }} className={classes.iconButton}>
                                                               <ArrowForwardOutlined className={classes.icon}/>
                                                           </IconButton>}
                                                   </InputAdornment>
                                               ),
                                           }}

                                />
                            )}
                            size="small"

                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Autocomplete
                            value={getBranch(updatedPos, branchValues)}
                            onChange={(event, newValue) => {
                                handleBranch(newValue);
                            }}
                            forcePopupIcon={false}
                            disableClearable
                            filterSelectedOptions
                            disabled={readOnly || hasCertificate}
                            options={branchValues}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            style={{width: '100%'}}
                            renderInput={(params) => (
                                <TextField {...params} label={t('BRANCH')} required fullWidth
                                           helperText={error && error.branchId
                                               ? <span
                                                   className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                               : null}
                                           InputProps={{
                                               ...params.InputProps,
                                               endAdornment: (
                                                   <InputAdornment position="end">
                                                       {isComponentVisibleForUser(UC_MENU_BRANCH_SEARCH, userUseCases) &&
                                                           <IconButton onClick={() => {
                                                               setMode('BRANCH')
                                                           }} className={classes.iconButton}>
                                                               <ArrowForwardOutlined className={classes.icon}/>
                                                           </IconButton>}
                                                   </InputAdornment>
                                               ),
                                           }}

                                />
                            )}
                            size="small"

                        />
                    </Grid>

                    <Grid item xs sm={4}>
                        <FormControl required fullWidth>
                            <InputLabel id="parking-label">
                                {t('CURRENCY')}
                            </InputLabel>
                            <Select
                                autoWidth
                                disabled={readOnly}
                                value={(updatedPos && (updatedPos.currencyId || updatedPos.currencyId == 0)) ? updatedPos.currencyId : ''}
                                id="currencyId"
                                onChange={handleChange('currencyId')}
                            >
                                {currencyValues.map(a => {
                                    return (
                                        <MenuItem value={a.value}>{a.label}</MenuItem>
                                    )
                                })
                                }
                            </Select>
                            <FormHelperText>  {error && error.currencyId && (
                                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            )}</FormHelperText>
                        </FormControl>

                    </Grid>

                    <Grid item xs sm={4}>
                        <FormControlLabel
                            control={<CustomSwitch
                                disabled={readOnly}
                                checked={updatedPos.globalReceiptId}
                                color="secondary"
                                handleSwitch={handleChange('globalReceiptId')}/>
                            }
                            label={<span
                                style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}>{t('GLOBAL_RECEIPT_ID')}</span>}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs sm={4}>
                        <Tooltip  title={t('POS_STATE_DESCRIPTION')}>
                        <FormControlLabel
                            control={<CustomSwitch
                                disabled={readOnly}
                                checked={updatedPos.active}
                                color="secondary"
                                handleSwitch={handleChange('active')}/>
                            }
                            label={<span
                                style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                }}>{t('ACTIVE_STATE')}</span>}
                            labelPlacement="start"
                        />
                        </Tooltip>
                    </Grid>


                    <Grid item sm={12}>
                        <Autocomplete
                            multiple disableCloseOnSelect
                            limitTags={5}
                            value={updatedPos && updatedPos.supportedProduct && Array.isArray(updatedPos.supportedProduct) ? updatedPos.supportedProduct.map(a => {
                                    const p = productValues && productValues.find(b => b.value === a);
                                    if (p) {
                                        return p;
                                    } else {
                                        return {value: a, label: 'NOT FOUND'}
                                    }
                                }
                            ) : []}
                            onChange={(event, newValue, reason) => {
                                if (reason === "clear" || reason === "removeOption") {
                                    setSelectAll(false);
                                } 
                                if (reason === "selectOption" && newValue.length === productValues.length) {
                                    setSelectAll(true);
                                }
                                handleProduct(newValue);
                            }}

                            filterSelectedOptions
                            id="supportedProduct"
                            options={productValues}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            style={{width: '100%'}}
                            renderInput={(params) => (
                                <TextField {...params} label={t('SUPPORTED_PRODUCT')} fullWidth
                                           helperText={error && error.supportedProduct
                                               ? <span
                                                   className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                               : null}

                                />
                            )}
                            size="small"
                            PaperComponent={(paperProps) => {
                                const { children, ...restPaperProps } = paperProps;
                                return (
                                  <Paper {...restPaperProps}>
                                    <Box
                                      onMouseDown={(e) => e.preventDefault()} // prevent blur
                                      pl={1.5}
                                      py={0.5}
                                    >
                                      <FormControlLabel
                                        onClick={(e) => {
                                          e.preventDefault(); // prevent blur
                                          handleToggleSelectAll();
                                        }}
                                        label={t('CHECK_ALL')}
                                        control={
                                          <Checkbox id="select-all-checkbox" checked={selectAll} />
                                        }
                                      />
                                    </Box>
                                    <Divider />
                                    {children}
                                  </Paper>
                                );
                              }
                            }
                        />
                    </Grid>


                </Grid>
            </form>}
            {mode !== 'COMMON' &&
                <IconButton onClick={() => {
                    setMode('COMMON')
                }} className={classes.iconButton}>
                    <ArrowBackOutlined className={classes.icon}/>
                </IconButton>
            }
            {mode === 'SUBJECT' && <SubjectsList
                userUseCases={userUseCases}
                setSnackbarError={setSnackbarError}
                setSnackbarOpen={setSnackbarOpen}
                setMode={setMode} handleSubjectChange={handleSubject}/>}

            {mode === 'BRANCH' && <BranchesList
                userUseCases={userUseCases}
                setSnackbarError={setSnackbarError}
                setSnackbarOpen={setSnackbarOpen}
                setMode={setMode} handleBranchChange={handleBranch}/>}


        </div>
    )
}

PosDetail.propTypes = {};

PosDetail.defaultProps = {};

const UC_MENU_BRANCH_SEARCH = 'UC0250';
const UC_MENU_SUBJECT_SEARCH = 'UC0200';


const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    tryConnectParking,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PosDetail);


