import React, {useCallback, useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {registrePhoto, unRegistrePhoto} from 'redux/actions/photo';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Loading} from 'components/elements';
import TvOffIcon from '@material-ui/icons/TvOff';
import {hasStream} from "../../utils";

const useStyles = makeStyles((theme) => ({
    hostManagement: {
        width: '100%',
        minHeight: '250px',
        padding: theme.spacing(2.5, 2.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeft: '2px solid',
        borderLeftColor: theme.palette.base[100],
    },
    cameraImage: {
        width: "100%",
        cursor: "pointer",
    },
    textInImage: {
        position: "absolute",
        bottom: "8px",
        right: "16px",
    }
}));

function HostStream(props) {
    const classes = useStyles();
    const {host, registrePhoto, unRegistrePhoto, handleCameraDetailOpen, withDetail} = props;

    const streamHostRef = useRef(null);

    const [streamHost, setStreamHost] = useState(null);

    const [registrationSent, setRegistrationSent] = useState(false);

    const setStreamHostRef = (streamHost) => {
        setStreamHost(streamHost);
        streamHostRef.current = streamHost;
    }

    const cleanUp = () => {
        if (streamHostRef.current) {
            unRegistrePhoto(streamHostRef.current.parkingHostId);
            setStreamHostRef(null);
        }
        setRegistrationSent(false);
    }

    useEffect(() => {
        return () => {
            cleanUp();
        };
    }, []);


    const newHostDisplayed = (host) => {
        return (!streamHostRef.current) || (host.parkingHostId != streamHostRef.current.parkingHostId)
    }

    useEffect(() => {
            if (!host) {
                cleanUp();
            }
            if (newHostDisplayed(host)) {
                if (streamHostRef.current) {
                    unRegistrePhoto(streamHostRef.current.parkingHostId);
                    setRegistrationSent(false);
                }
                if (hasStream(host)) {
                    setStreamHostRef(host);
                    registrePhoto(host.parkingHostId, withDetail);
                    if (!host.photo) {
                        setRegistrationSent(true);
                    }
                } else {
                    setStreamHostRef(null);
                }
            } else {
                if (host.photo) {
                    setRegistrationSent(false);
                    setStreamHostRef(host);
                }
            }
        }, [host]
    );

    if (!streamHost) {
        return <div className={classes.hostManagement}>
            <TvOffIcon fontSize="large"/>
        </div>
    }

    if (registrationSent) {
        return <Loading/>
    }


    return (
        <div className={classes.hostManagement}>
            <img src={`data:image/jpg;base64,${streamHost.photo}`} alt={streamHost.photoTime}
                 onClick={handleCameraDetailOpen ? handleCameraDetailOpen({
                     host: streamHost,
                     imageModal: true,
                 }) : () => {
                 }} className={classes.cameraImage}/>
        </div>

    );
}

HostStream.propTypes = {
    // host: PropTypes.object,
    registrePhoto: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
};

HostStream.defaultProps = {
    host: {photo: null},
};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    unRegistrePhoto,
    registrePhoto,
}, dispatch);

// export default HostStream;

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HostStream);
