import {getEventsQuery, getFulltextQuery} from 'utils';
import httpService from './http.service';

const getParkingEvents = (parkingId, dateFrom, dateTo, hostType, eventType, lpn, lastId, size, page, orderedColumnId, orderDirection) => httpService
    .get(`/events/parking/${parkingId}${getEventsQuery(dateFrom, dateTo, hostType, eventType, lpn, lastId, size, page, orderedColumnId, orderDirection)}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getSubjectEvents = (subjectId, dateFrom, dateTo, hostType, eventType, lpn, lastId, size, page) => httpService
    .get(`/events/subject/${subjectId}${getEventsQuery(dateFrom, dateTo, hostType, eventType, lpn, lastId, size, page)}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getFulltextEvents = (hosts, parkings, search, dateFrom, dateTo, lpn, cardNumber, owner) => httpService
    .get(`/cards/byparams${getFulltextQuery(hosts, parkings, search, dateFrom, dateTo, lpn, cardNumber, owner)}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getParkingEvents,
    getSubjectEvents,
    getFulltextEvents,


};
