import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {ParkingCard} from 'components/common';
import {
    TaskDetails, CreateGeneralTask,
} from 'components/modals';
import ParkingList from 'containers/Parking/ParkingList';
import MainContact from './MainContact';
import MainChart from './MainChart';
import ParkingManagement from './ParkingManagement';
import {connect} from 'react-redux';
import {isComponentVisibleForUser} from "../../utils";
import ParkingSetup from 'components/modals/Management';

const useStyles = makeStyles((theme) => ({
    mainWrapper: {
        marginTop: theme.spacing(6),
    },
    mainTop: {
        marginBottom: theme.spacing(3.75),
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
    },
    mainTopLeft: {
        width: 370,
        marginRight: theme.spacing(3.75),
        marginTop: theme.spacing(-2.5),
        display: 'flex',
        flexDirection: 'column',
    },
}));

function ManagementMainContent(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        updateParking,
        user,
        userUseCases,
        parkings,
        allCodeList,
        selectedParking,
        selectedIds,
        parkingTasks,
        taskImages,
        isCanceling,
        isRenewing,
        parkingAssignedUsers,
        parkingNotAssignedUsers,
        parkingErrors,
        showParkingList,
        selectParking,
        toggleAllParking,
        fetchParkingErrors,
        fetchParkingTasks,
        fetchParkingAssignedUsers,
        fetchParkingNotAssignedUsers,
        createTask,
        cancelTask,
        renewTask,
        fetchParkingTasksStatistics,
        parkingTasksStatistics,
        parkingHosts,
        occupancy,
        sipParkingIds,
        removeSipParkingIdToUserAction,
        addSipParkingIdToUserAction,
    } = props;

    const [taskDetailModal, setTaskDetailModal] = useState({
        opened: false,
        event: null,
    });
    const handleTaskDetailOpen = (event) => () => {
        setTaskDetailModal({
            opened: true,
            event,
        });
    };
    const handleTaskDetailClose = () => {
        setTaskDetailModal({
            opened: false,
            event: null,
        });
    };


    const [updateParkingModal, setUpdateParkingModal] = useState({
        opened: false,
        parking: null,
    });


    const handleUpdateParkingClose = () => {
        setUpdateParkingModal({
            opened: false,
            parking: null,
        });
    };


    const [createTaskModal, setCreateTaskModal] = useState({
        opened: false,
        event: null,
    });
    const handleCreateTaskOpen = (event) => {
        setCreateTaskModal({
            opened: true,
            event,
        });
    };
    const handleCreateTaskClose = () => {
        setCreateTaskModal({
            opened: false,
            event: null,
        });
    };

    const handleEdit = (parking) => {
        setUpdateParkingModal({
            opened: true,
            parking,
        });
    };


    return (
        <>
            {showParkingList ? (
                <ParkingList
                    editable={true}
                    handleEdit={handleEdit}
                    userUseCases={userUseCases}
                    parkings={parkings}
                    selectedIds={selectedIds}
                    selectParking={selectParking}
                    toggleAllParking={toggleAllParking}
                    sipParkingIds={sipParkingIds}
                    addSipParkingIdToUserAction={addSipParkingIdToUserAction}
                    removeSipParkingIdToUserAction={removeSipParkingIdToUserAction}
                    parkingLabel={t('MY_PARKING_LIST')}

                />
            ) : (
                <div className={classes.mainWrapper}>
                    <div className={classes.mainTop}>
                        <div className={classes.mainTopLeft}>
                            {selectedParking &&
                            <ParkingCard
                                editable={true}
                                handleEdit={handleEdit}
                                userUseCases={userUseCases}
                                parking={selectedParking}
                                handleClick={selectParking}
                                key={selectedParking.parkingId}
                                sipParkingIds={sipParkingIds}
                                addSipParkingIdToUserAction={addSipParkingIdToUserAction}
                                removeSipParkingIdToUserAction={removeSipParkingIdToUserAction}
                            />
                            }
                            {selectedParking &&
                            <MainContact parking={selectedParking}/>
                            }
                        </div>
                        {selectedParking && isComponentVisibleForUser(UC_MAN_GRAPH, userUseCases) &&
                        <MainChart parking={selectedParking} occupancy={occupancy}/>
                        }
                    </div>
                    {selectedParking &&
                    <ParkingManagement
                        userUseCases={userUseCases}
                        parking={selectedParking}
                        allCodeList={allCodeList}
                        tasks={parkingTasks}
                        taskImages={taskImages}
                        isCanceling={isCanceling}
                        isRenewing={isRenewing}
                        parkingAssignedUsers={parkingAssignedUsers}
                        parkingNotAssignedUsers={parkingNotAssignedUsers}
                        errors={parkingErrors}
                        handleTaskDetailOpen={handleTaskDetailOpen}
                        handleCreateTaskOpen={handleCreateTaskOpen}
                        fetchParkingErrors={fetchParkingErrors}
                        fetchParkingTasks={fetchParkingTasks}
                        fetchParkingAssignedUsers={fetchParkingAssignedUsers}
                        fetchParkingNotAssignedUsers={fetchParkingNotAssignedUsers}
                        cancelTask={cancelTask}
                        renewTask={renewTask}
                        fetchParkingTasksStatistics={fetchParkingTasksStatistics}
                        parkingTasksStatistics={parkingTasksStatistics}
                    />
                    }
                </div>
            )}

            {updateParkingModal.parking && (
                <ParkingSetup
                    updateParking={updateParking}
                    userUseCases={userUseCases}
                    opened={updateParkingModal.opened}
                    parking={updateParkingModal.parking}
                    handleClose={handleUpdateParkingClose}
                />
            )}

            {taskDetailModal.event && (
                <TaskDetails
                    userUseCases={userUseCases}
                    opened={taskDetailModal.opened}
                    task={taskDetailModal.event.task}
                    image={taskDetailModal.event.image}
                    imageModal={taskDetailModal.event.imageModal}
                    parkings={parkings}
                    handleClose={handleTaskDetailClose}
                />
            )}

            {createTaskModal.event && (
                <CreateGeneralTask
                    user={user}
                    opened={createTaskModal.opened}
                    parkings={parkings}
                    event={createTaskModal.event}
                    createTask={createTask}
                    handleClose={handleCreateTaskClose}
                    parkingHosts={parkingHosts}
                />
            )}
        </>
    );
}

ManagementMainContent.propTypes = {
    user: PropTypes.object,
    parkings: PropTypes.array.isRequired,
    allCodeList: PropTypes.array.isRequired,
    selectedParking: PropTypes.object,
    selectedIds: PropTypes.array.isRequired,
    parkingTasks: PropTypes.array.isRequired,
    taskImages: PropTypes.array.isRequired,
    isCanceling: PropTypes.bool.isRequired,
    isRenewing: PropTypes.bool.isRequired,
    parkingAssignedUsers: PropTypes.array.isRequired,
    parkingNotAssignedUsers: PropTypes.array.isRequired,
    parkingErrors: PropTypes.array.isRequired,
    showParkingList: PropTypes.bool.isRequired,
    selectParking: PropTypes.func.isRequired,
    toggleAllParking: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    cancelTask: PropTypes.func.isRequired,
    renewTask: PropTypes.func.isRequired,
    fetchParkingErrors: PropTypes.func.isRequired,
    fetchParkingTasks: PropTypes.func.isRequired,
    fetchParkingAssignedUsers: PropTypes.func.isRequired,
    fetchParkingNotAssignedUsers: PropTypes.func.isRequired,
    fetchParkingTasksStatistics: PropTypes.func.isRequired,
    parkingTasksStatistics: PropTypes.array.isRequired,
    parkingHosts: PropTypes.array.isRequired,
    occupancy: PropTypes.array.isRequired,
};

ManagementMainContent.defaultProps = {
    user: null,
    selectedParking: null,
    selectedHostName: null,
};

const mapStateToProps = (store) => ({
    parkingHosts: store.hostsData.parkingHosts,
});

const UC_MAN_GRAPH = 'UC0060';

export default connect(
    mapStateToProps,
)(ManagementMainContent);
