/* eslint-disable no-console */
import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {EventSourcePolyfill} from 'event-source-polyfill';
import PropTypes from 'prop-types';
import PerfectScrollbar from "react-perfect-scrollbar";

const baseURL = process.env.REACT_APP_API;

class LiveData extends Component {
    constructor(props) {
        super(props);
        this.memoryES = null;
        this.state = {
            heap: 0,
            nonHeap: 0,
            cardNumber: "",
            ts: 0,
            events: null,
            parkings: null,
            parkingHosts: null,
            occupationHistory: null,
        };
    }

    componentDidMount() {
        this.initializeMemoryES();
        this.initializeSseParkingES();
    }

    componentWillUnmount() {
        console.log('unmounting');
        this.memoryES.close();
        this.sseParkingES.close();
    }

    messageMemoryES = (eventResponse) => {
        const msg = JSON.parse(eventResponse.data);
        this.setState({
            heap: msg.heap,
            nonHeap: msg.nonHeap,
            ts: msg.ts,
        });
    };

    messageSseParkingES = (eventResponse) => {
        if (eventResponse === null)
            console.log(JSON.parse(eventResponse));
        else
            console.log('null eventResponse');

        const eventData = JSON.parse(eventResponse.data);
        this.setState({
            events: eventData.events,
        });
        this.setState({
            occupationHistory: eventData.occupancy,
        });
        this.setState({
            parkings: eventData.parkings,
        });
        this.setState({
            parkingHosts: eventData.parkingHosts,
        });
        this.setState({
            cardNumber: eventData.cardNumbersDto != null ? eventData.cardNumbersDto[0].cardNumber : null,
        });
    };

    onopenMemoryES = () => {
        // Do stuff here
    };

    onopenSseParkingES = () => {
        // Do stuff here
    };

    onerrorMemoryES = (e) => {
        // Do stuff here
        if (e.readyState === this.memoryES.CLOSED) {
            console.log('event source is closed');
            this.memoryES.close();
        } else {
            console.log('Not a event source closed error');
        }
    };

    onerrorSseParkingES = (e) => {
        // Do stuff here
        if (e.readyState === this.memoryES.CLOSED) {
            console.log('event source is closed');
            this.memoryES.close();
        } else {
            console.log('Not a event source closed error');
        }
    };

    onopenSseParkingES = (e) => {
        // Do stuff here
        if (e.readyState === this.sseParkingES.CLOSED) {
            console.log('event source is closed');
            this.parkingsES.close();
        } else {
            console.log('Not a event source closed error');
        }
    };

    startMemoryES = () => {
        const token = localStorage.getItem('kc_token');
        this.memoryES = new EventSourcePolyfill(
            `${baseURL}/sse/memory`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
    };

    startSseParkingES = () => {
        console.log('startSseParkingES');
        const token = localStorage.getItem('kc_token');
        this.sseParkingES = new EventSourcePolyfill(
            `${baseURL}/sse/parking`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
    };

    initializeMemoryES() {
        this.startMemoryES();
        this.memoryES.addEventListener('open', this.onopenMemoryES);
        this.memoryES.addEventListener('message', this.messageMemoryES);
        this.memoryES.addEventListener('error', this.onerrorMemoryES);
    }

    initializeSseParkingES() {
        this.startSseParkingES();
        this.sseParkingES.addEventListener('open', this.onopenSseParkingES);
        this.sseParkingES.addEventListener('message', this.messageSseParkingES);
        this.sseParkingES.addEventListener('error', this.onerrorSseParkingES);
    }


    renderMemoryInfo = (heap, nonHeap, ts, cardNumber) => (
        <div>
            <span>CardNumber</span>
            <span>{cardNumber}</span>
            <br></br> <br></br>
            <span>MemoryInfo</span>
            <table>
                <tbody>
                <tr>
                    <td>
                        Heap:
                    </td>
                    <td>
                        {heap}
                    </td>
                </tr>
                <tr>
                    <td>
                        NonHeap:
                    </td>
                    <td>
                        {nonHeap}
                    </td>
                </tr>
                <tr>
                    <td>
                        TimeStamp:
                    </td>
                    <td>
                        {ts}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );

    renderEvents = (events) => {
        const rows = [];
        if (events == null) {
            console.log('null input');
        } else {
            events.forEach((element) => {
                rows.push(
                    <tr key={element.eventId}>
                        <td>{element.eventId}</td>
                        <td>{element.description}</td>
                        <td>{element.message}</td>
                    </tr>,
                );
            });
        }

        return (
            <div>
                <span>Events</span>
                <table>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        );
    };

    renderParkings = (parkings) => {
        const rows = [];
        if (parkings == null) {
            console.log('null input');
        } else {
            parkings.forEach((element) => {
                rows.push(
                    <tr key={element.parkingId}>
                        <td>{element.parkingId}</td>
                        <td>{element.parkingName}</td>
                        <td>{element.address}</td>
                        <td>{element.communicationStatus}</td>
                    </tr>,
                );
            });
        }

        return (
            <div>
                <span>Parkings</span>
                <table>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        );
    };

    renderParkingHosts = (parkingHosts) => {
        const rows = [];
        if (parkingHosts == null) {
            console.log('null input');
        } else {
            parkingHosts.forEach((element) => {
                rows.push(
                    <tr key={element.parkingHostId}>
                        <td>{element.parkingHostId}</td>
                        <td>{element.hostName}</td>
                        <td>{element.message}</td>
                        <td>{element.cameraUrl}</td>
                    </tr>,
                );
            });
        }

        return (
            <div>
                <span>ParkingHosts</span>
                <table>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        );
    };

    renderOccupationHistory = (occupationHistory) => {
        const rows = [];
        if (occupationHistory == null) {
            console.log('null input');
        } else {
            occupationHistory.forEach((element) => {
                rows.push(
                    <tr key={element.occupationHistoryId}>
                        <td>{element.occupationHistoryId}</td>
                        <td>{element.occupancy}</td>
                        <td>{element.reserved}</td>
                        <td>{element.capacity}</td>
                        <td>{element.date}</td>
                        <td>{element.parkingId}</td>
                    </tr>,
                );
            });
        }

        return (
            <div>
                <span>OccupationHistory</span>
                <table>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        const {
            heap,
            nonHeap,
            ts,
            events,
            parkings,
            parkingHosts,
            occupationHistory,
            cardNumber,
        } = this.state;
        const {history, authData} = this.props;

        if (authData.isRO) {
            history.push(`/${process.env.REACT_APP_PATH}/parking`);
        }
        if (authData.isMO) {
            history.push(`/${process.env.REACT_APP_PATH}/about`);
        }

        return (
            <PerfectScrollbar
                options={{
                    suppressScrollX: true,
                    minScrollbarLength: 50,
                }}
            >
                <div>
                    {this.renderMemoryInfo(heap, nonHeap, ts, cardNumber)}
                </div>
                <div>
                    {this.renderEvents(events)}
                </div>
                <div>
                    {this.renderParkings(parkings)}
                </div>
                <div>
                    {this.renderParkingHosts(parkingHosts)}
                </div>
                <div>
                    {this.renderOccupationHistory(occupationHistory)}
                </div>
            </PerfectScrollbar>
        );
    }
}

LiveData.propTypes = {
    history: PropTypes.object.isRequired,
    authData: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
    authData: store.authData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LiveData);
