import hostsService from 'services/hosts.service';
import types from '../actionTypes';

export const fetchParkingHosts = (parkingId) => (dispatch, getState) => {
    if (getState().hostsData.isFetchingHosts) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_HOSTS_FETCH_REQUEST,
    });

    return hostsService.getParkingHosts(parkingId)
        .then((parkingHosts) => {
            dispatch({
                type: types.PARKING_HOSTS_FETCH_SUCCESS,
                payload: {parkingHosts},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_HOSTS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchParkingHostsBySubject = (hostType) => (dispatch, getState) => {
    if (getState().hostsData.isFetchingHosts) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_HOSTS_BY_SUBJECT_FETCH_REQUEST,
    });

    return hostsService.getParkingHostsBySubject(hostType)
        .then((parkingHosts) => {
            dispatch({
                type: types.PARKING_HOSTS_BY_SUBJECT_FETCH_SUCCESS,
                payload: {parkingHosts},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_HOSTS_BY_SUBJECT_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const createParkingHostCommand = (hostId, command) => (dispatch, getState) => {
    if (getState().hostsData.isCreatingCommand) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_HOSTS_CREATE_COMMAND_REQUEST,
    });

    return hostsService.createParkingHostCommand(hostId, command)
        .then(() => {
            dispatch({
                type: types.PARKING_HOSTS_CREATE_COMMAND_SUCCESS,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_HOSTS_CREATE_COMMAND_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const createParkingHostAction = (hostId, actionData) => (dispatch, getState) => {
    if (getState().hostsData.isCreatingAction) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_HOSTS_CREATE_ACTION_REQUEST,
    });

    return hostsService.createParkingHostAction(hostId, actionData)
        .then(() => {
            dispatch({
                type: types.PARKING_HOSTS_CREATE_ACTION_SUCCESS,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_HOSTS_CREATE_ACTION_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchEnum = (parkingHostId) => (dispatch, getState) => {
    if (getState().hostsData.isFetchingEnum) {
        return Promise.reject();
    }
    dispatch({
        type: types.PARKING_HOSTS_ENUM_FETCH_REQUEST,
    });

    return hostsService.getEnum(parkingHostId)
        .then((enums) => {
            dispatch({
                type: types.PARKING_HOSTS_ENUM_FETCH_SUCCESS,
                payload: {enums},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_HOSTS_ENUM_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const updateHostsPosition = (hostsPosition) => (dispatch, getState) => {
/*
    if (getState().hostsData.isUpdatingHostsPosition) {
        return;
    }
*/

    dispatch({
        type: types.PARKING_HOSTS_POSITION_UPDATE_REQUEST,
    });

    return hostsService.updateHostsPosition(hostsPosition)
        .then(() => {
            const {parkingHosts} = getState().hostsData;
            const parkingHostsCopy = [...parkingHosts];
            for (let i = 0; i < hostsPosition.length; i++) {
                const index = parkingHosts.findIndex((p) => p.parkingHostId == hostsPosition[i].parkingHostId);
                const host = parkingHostsCopy[index];
                const hostCopy = {...host, column: hostsPosition[i].column, row: hostsPosition[i].row};
                parkingHostsCopy[index] = hostCopy;
            }
            dispatch({
                type: types.PARKING_HOSTS_POSITION_UPDATE_SUCCESS,
                payload: {parkingHosts: parkingHostsCopy},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_HOSTS_POSITION_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const updateHost = (host) => (dispatch, getState) => {
    if (getState().hostsData.isUpdating) {
        return;
    }

    dispatch({
        type: types.PARKING_HOSTS_UPDATE_REQUEST,
    });

    return hostsService.updateHost(host)
        .then(() => {
            const {parkingHosts} = getState().hostsData;
            const hostIndex = parkingHosts.findIndex((p) => p.parkingHostId === host.parkingHostId);
            parkingHosts[hostIndex] = {...host};

            dispatch({
                type: types.PARKING_HOSTS_UPDATE_SUCCESS,
                payload: {parkingHosts: [...parkingHosts]},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_HOSTS_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

