import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingList: false,
    zReports: [],
    errors: '',
    zReport: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.ZREPORTS_LIST_REQUEST:
            return {
                ...state,
                isFetchingList: true,
            };
        case types.ZREPORTS_LIST_SUCCESS:
            return {
                ...state,
                isFetchingList: false,
                zReports: action.payload,
            };
        case types.ZREPORTS_LIST_FAIL:
            return {
                ...state,
                isFetchingList: false,
                errors: action.payload.error,
            };
        case types.ZREPORTS_DETAIL_REQUEST:
            return {
                ...state,
                isFetchingList: true,
            };
        case types.ZREPORTS_DETAIL_SUCCESS:
            return {
                ...state,
                isFetchingList: false,
                zReport: action.payload,
            };
        case types.ZREPORTS_DETAIL_FAIL:
            return {
                ...state,
                isFetchingList: false,
                errors: action.payload.error,
            };
        case types.ZREPORTS_CREATION_SUCCESS:
            return {
                ...state,
                isFetchingList: false,
            };
        default:
            return state;
    }
};
