import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classnames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import {logout} from 'redux/actions/auth';
import gpIcon from 'assets/icon-gp-pl.png';
import gcIcon from 'assets/gcIcon.png';
import swIcon from 'assets/icon-scrw.png';
import Fade from '@material-ui/core/Fade';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from "@material-ui/core/Badge";
import {InputBase} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";


const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: 70,
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        height: 70,
        paddingRight: theme.spacing(3), // keep right padding when drawer closed
        color: theme.palette.base[800],
    },
    menuButton: {
        marginRight: theme.spacing(4.5),
    },
    menuButtonHidden: {
        display: 'none',
    },
    username: {
        marginLeft: 'auto',
        padding: theme.spacing(0.5, 2),
        color: '#000',
    },
    signOut: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '16px',
        color: '#C8C8C8',
        textTransform: 'none',

        '&:hover': {
            backgroundColor: 'transparent',
            color: '#000',
        },
    },
    menuIcon: {
        width: '30px',
        height: '30px',
    },
    POSMenu: {
        '& div': {
            width: '300px',
            margin: '5px',
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: '250px',
    },
}))(Tooltip);


function status(posStatutes) {
    return <div dangerouslySetInnerHTML={
        {
            __html: posStatutes && posStatutes.length && posStatutes.length > 0 ? posStatutes[0].message : ""
        }
    }>
    </div>;
}

const PosStatus = ({t, posStatutes}) => {

    const [posId, setPosId] = React.useState(localStorage.getItem('pos_id') || null);


    const getStatusColor = (posStatutes) => {
        if (posStatutes && posStatutes.length && posStatutes.length > 0) {
            if (posStatutes[0].state === 'OK') {
                return '#50A933';
            } else if (posStatutes[0].state === 'INIT') {
                return '#FDBA45';
            } else if (posStatutes[0].state === 'OK') {
                return "#F34734"
            }
        }
        return '#000000';
    }

    const classes = useStyles();

    return (
        <React.Fragment>
            {!posStatutes && <>
                <PrintOutlinedIcon style={{
                    fontSize: 40,
                    color: getStatusColor(posStatutes),
                    paddingRight: '5px'
                }}/>
            </>}
            {posStatutes && <HtmlTooltip title={status(posStatutes)}>
                <PrintOutlinedIcon style={{
                    fontSize: 40,
                    color: getStatusColor(posStatutes),
                    paddingRight: '5px'
                }}/>
            </HtmlTooltip>}
        </React.Fragment>
    );
}


function TopBar(props) {
    const classes = useStyles();
    const {user, open, simpleWindow, userUseCases, posStatutes, handleFulltextOpen, showFulltext} = props;
    const {t} = useTranslation();


    return (
        <AppBar position="absolute" className={classnames(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                {!simpleWindow && (<>
                    <Fade in={!open} style={{transitionDelay: '100ms'}}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.handleDrawerOpen}
                            className={classnames(classes.menuButton)}
                        >

                            {process.env.REACT_APP_PUBLIC_LOGO === 'gc' && (
                                <img src={gcIcon} className={classes.menuIcon} alt=""/>)}
                            {process.env.REACT_APP_PUBLIC_LOGO === 'gp' && (
                                <img src={gpIcon} className={classes.menuIcon} alt=""/>)}
                            {process.env.REACT_APP_PUBLIC_LOGO === 'scrum' && (
                                <img src={swIcon} className={classes.menuIcon} alt=""/>)}
                        </IconButton>
                    </Fade>

                    {showFulltext && <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon fontSize="large"/>
                        </div>
                        <InputBase
                            onClick={handleFulltextOpen}
                            placeholder={t('SEARCH_DOT')}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{'aria-label': 'search'}}
                        />
                    </div>
                    }

                </>)}
                {user && (
                    <>
                        <Typography variant="body1" className={classes.username}>
                            { user.lastName &&  user.firstName && `${user.firstName} ${user.lastName}` }
                            { user.lastName &&  !user.firstName && `${user.lastName}` }
                            { !user.lastName && user.username && `${user.username}` }
                        </Typography>
                        <Badge
                            showZero
                            badgeContent={posStatutes && posStatutes.length && posStatutes.length > 0 ? posStatutes[0].nonFiscalizedReceipt : 0}
                            color="error"/>
                        <PosStatus t={t}
                                   posStatutes={posStatutes}/>

                        <Button
                            color="primary"
                            variant="text"
                            className={classes.signOut}
                            onClick={props.logout}
                        >
                            {t('SIGN_OUT')}
                        </Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    user: PropTypes.object,
    open: PropTypes.bool.isRequired,
    simpleWindow: PropTypes.bool.isRequired,
    handleDrawerOpen: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
};

TopBar.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logout,
}, dispatch);

const UC_LOGOUT = 'UC0001';

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
