import httpService from './http.service';


const createPos = (pos) => httpService
    .post(`/pos/create`, pos)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const getAllPos = () => httpService
    .get('/pos')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const updatePos = (pos) => httpService
    .put(`/pos/update`, pos)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const blacklist = (pos) => httpService
    .post(`/pos/blacklistcert?posName=${pos.name}`, {})
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getPosHistory = (id) => httpService
    .get(`/pos/${id}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getAllPos,
    createPos,
    updatePos,
    getPosHistory,
    blacklist,
};
