import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Add from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Chip} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {ArrowForwardOutlined} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 1, 2),
    },
    toolbarInner1: {
        width: '100%',
        border: '1px solid #E0E0E0',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: theme.palette.shadow.main,
        padding: theme.spacing(2, 1, 1, 2),

    },
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(10),
        padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));

function ToolBar(props) {
    const classes = useStyles();

    const {
        selectedName,
        handleSelectedName,
        userUseCases,
        handlePosDetail,
        certifications,
        statuses,
        selectedBranch,
        handleSelectBranch,
        handleSelectStatus,
        handleSelectCertification,
        handleSelectedValidTo,
        selectedStatus,
        selectedCertification,
        selectedValidTo,
        branchValues
    } = props;

    const {t} = useTranslation();


    return (
        <div className={classes.toolbar}>
            <div className={classes.toolbarInner1}>
                <form autoComplete='off'>
                    <Box mb={2} height="100%" width="100%">
                        <Grid container spacing={2} style={{height: '100%', width: '100%'}}>
                            <Grid item sm={1}>
                                <TextField
                                    type="search"
                                    label={t('NAME')}
                                    fullWidth
                                    value={selectedName || ''}
                                    onChange={handleSelectedName}
                                />

                            </Grid>
                            <Grid item sm={3}>
                                <Autocomplete
                                    multiple
                                    value={selectedBranch}
                                    onChange={(event, newValue) => {
                                        handleSelectBranch(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="branch"
                                    options={branchValues}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('BRANCH')} fullWidth


                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item sm={3}>

                                <Autocomplete
                                    multiple
                                    value={selectedCertification}
                                    onChange={(event, newValue) => {
                                        handleSelectCertification(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="certification"
                                    options={certifications}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('CERT_STATE')} fullWidth


                                        />
                                    )}
                                    renderOption={(option) => (
                                        <div style={{
                                            color: option.color,
                                        }}> {option.label}</div>

                                    )}
                                    renderTags={(value, getTagProps) => (
                                        value.map((option, index) => {
                                                return <Chip
                                                    style={{
                                                        backgroundColor: option.color,
                                                        color: "white"
                                                    }}
                                                    variant="outlined"
                                                    label={option.label}
                                                    {...getTagProps({index})}
                                                />
                                            }
                                        )
                                    )
                                    }
                                />
                            </Grid>
                            <Grid item sm={3}>

                                <Autocomplete
                                    multiple
                                    value={selectedStatus}
                                    onChange={(event, newValue) => {
                                        handleSelectStatus(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="statuses"
                                    options={statuses}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('POS_STATE')} fullWidth


                                        />
                                    )}
                                    renderOption={(option) => (
                                        <div style={{
                                            color: option.color,
                                        }}> {option.label}</div>

                                    )}
                                    renderTags={(value, getTagProps) => (
                                        value.map((option, index) => {
                                                return <Chip
                                                    style={{
                                                        backgroundColor: option.color,
                                                        color: "white"
                                                    }}
                                                    variant="outlined"
                                                    label={option.label}
                                                    {...getTagProps({index})}
                                                />
                                            }
                                        )
                                    )
                                    }
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <IconButton
                                    className={classes.addButton}
                                    edge="start"
                                    color="inherit"
                                    aria-label="add POS"
                                    onClick={() => {
                                        handlePosDetail({globalReceiptId: false, active: true}, true);
                                    }}
                                    disabled={!isComponentEnabledForUser(UC_POS_CREATE, userUseCases)}
                                >
                                    <Add className={classes.addIcon}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    </Box>
                </form>
            </div>
        </div>

    )
        ;
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

const UC_POS_CREATE = 'UC0641';

export default ToolBar;
