import eventsService from 'services/events.service';
import types from '../actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchParkingEvents = (parkingId, queryParams) => (dispatch, getState) => {
  if (getState().eventsData.isFetchingParkingList) {
    return Promise.reject();
  }

  dispatch({
    type: types.PARKING_EVENTS_FETCH_REQUEST,
  });

  return eventsService.getParkingEvents(
    parkingId,
    queryParams.dateFrom,
    queryParams.dateTo,
    queryParams.hostType.toString().slice(1),
    queryParams.eventType.toString().slice(1),
      queryParams.lpn,
    queryParams.lastId,
    queryParams.size,
    queryParams.page,
    queryParams.orderedColumnId,
    queryParams.orderDirection,
  )
    .then((data) => {
      dispatch({
        type: types.PARKING_EVENTS_FETCH_SUCCESS,
        payload: { data },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.PARKING_EVENTS_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};


export const fetchSubjectEvents = (subjectId, queryParams) => (dispatch, getState) => {
  if (getState().eventsData.isFetchingSubjectList) {
    return Promise.reject();
  }
  dispatch({
    type: types.SUBJECT_EVENTS_FETCH_REQUEST,
  });
  return eventsService.getSubjectEvents(
      subjectId,
      queryParams.dateFrom,
      queryParams.dateTo,
      queryParams.hostType.toString().slice(1),
      queryParams.eventType.toString().slice(1),
      queryParams.lpn,
      queryParams.lastId,
      queryParams.size,
      queryParams.page,
  )
      .then((data) => {
        dispatch({
          type: types.SUBJECT_EVENTS_FETCH_SUCCESS,
          payload: { data },
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.SUBJECT_EVENTS_FETCH_FAIL,
          payload: { error },
        });

        throw error;
      });
};
