import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';


import { getOwnerItems } from 'utils';
import { CustomSearchInput } from 'components/elements';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';

import IconButton from "@material-ui/core/IconButton/IconButton";
import {Add} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  owner: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '40px'
  },
  space: {
    marginRight: theme.spacing(1),
  },
  icon: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    color: 'white',
  },
}));

function CardOwner(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    selectedParking,
    subjectSearchOwners,
    fetchSubjectSearchOwners,
    handleMenuItemClick,
    userUseCases,
    setMode
  } = props;

  const [showEdit, setShowEdit] = useState(false);
  const handleClick = () => {
    setShowEdit(!showEdit);
  };

  const owners = getOwnerItems(subjectSearchOwners);

  let selectedCardZone = null;
  if (selectedParking.zones && selectedParking.zones.length > 0) {
    selectedCardZone = selectedParking.zones[0];
  }

  let owner = (selectedCardZone && selectedCardZone.ownerId)
    ? owners.find((owner) => owner.id === selectedCardZone.ownerId)
    : null;

  if ( (owner == null || owner == undefined) && selectedCardZone)
    owner = {id:selectedCardZone.ownerId, value:'owner_'+selectedCardZone.ownerId, label:selectedCardZone.owner, key:'owner_'+selectedCardZone.ownerId};

  const handleChange = (value) => {
    fetchSubjectSearchOwners(value);
  };

  return (
    <div className={classes.owner}>
      <span className={classes.space}>{`${t('OWNER')}:`}</span>
      <span className={classes.space}>{owner ? owner.label : ''}</span>

      {showEdit && (
        <CustomSearchInput
          menuData={owners}
          placeholder={t('OWNER')}
          handleChange={handleChange}
          menuItemClick={handleMenuItemClick}
          showIcon={false}
        />
      )}
      
        <ButtonGroup size="large" aria-label="small button group">
          {
          isComponentVisibleForUser(UC_LT_CARD_OWNER_CHANGE, userUseCases) &&

            <Button className={classes.iconButton} >
              <EditIcon className={classes.icon} onClick={isComponentEnabledForUser(UC_LT_CARD_OWNER_CHANGE, userUseCases) ? handleClick : ()=>{}}/>
            </Button>
          }
          {
          isComponentVisibleForUser(UC_MENU_SUBJECT_SEARCH, userUseCases) &&
            <Button onClick={() => {
              setMode('OWNER')
            }} className={classes.iconButton}>
              <Add className={classes.icon}/>
            </Button>
          }
      </ButtonGroup>
    </div>
  );
}

CardOwner.propTypes = {
  selectedParking: PropTypes.object,
  subjectSearchOwners: PropTypes.array.isRequired,
  fetchSubjectSearchOwners: PropTypes.func.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired,
};

CardOwner.defaultProps = {
  selectedParking: {},
};

const UC_MENU_SUBJECT_SEARCH = 'UC0200';


export const UC_LT_CARD_OWNER_CHANGE = 'UC0028';

export default CardOwner;
