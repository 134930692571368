import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import {
    CustomSwitch, CustomDateTimePicker, CustomMultiSelect, CustomSearchInput,
} from 'components/elements';
import { getLocaleDateTimeString } from 'i18n';
import i18next from 'i18next';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        height: 55,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E0E0E0',
        paddingBottom: theme.spacing(4.5),
    },
    onlineSwitch: {
        display: 'flex',
        alignItems: 'center',
    },
    onlineSwitchLabel: {
        marginLeft: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    toolbarInput: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
    },
    filter: {
        display: 'flex',
        alignItems: 'center',

        '& > div': {
            marginLeft: theme.spacing(4),
        },

        '& > div:first-child': {
            marginLeft: 0,
        },
    },
}));

function EventsToolbar(props) {
    const classes = useStyles();

    const {
        isOnline,
        hosts,
        startTimeStr,
        endTimeStr,
        selectedHosts,
        eventTypes,
        selectedTypes,
        handleOnline,
        handleStartDate,
        handleEndDate,
        handleSelectHosts,
        handleSelectTypes,
        handleLPChange,
        disabled,
    } = props;

    const {t} = useTranslation();

    return (
        <div className={classes.toolbar}>
            <div className={classes.filter}>
                <div className={classes.onlineSwitch}>
                    <CustomSwitch color="secondary" checked={isOnline} handleSwitch={handleOnline} disabled={disabled}/>
                    <div className={classes.onlineSwitchLabel} style={{color: isOnline ? '#5985EE' : '#60636B'}}>
                        {t('ONLINE_EVENTS')}
                    </div>
                </div>
                <CustomDateTimePicker
                    label={t('FROM')}
                    disabled={isOnline}
                    value={startTimeStr}
                    icon={<ArrowDropDown/>}
                    handleChange={handleStartDate}
                    format={getLocaleDateTimeString(i18next.language)}
                />
                <CustomDateTimePicker
                    label={t('TO')}
                    disabled={isOnline}
                    value={endTimeStr}
                    icon={<ArrowDropDown/>}
                    handleChange={handleEndDate}
                    format={getLocaleDateTimeString(i18next.language)}
                />
                <CustomMultiSelect
                    items={hosts}
                    label={t('HOST')}
                    value={selectedHosts}
                    handleChange={handleSelectHosts}
                />
                <CustomMultiSelect
                    items={eventTypes}
                    label={t('EVENT_TYPE')}
                    value={selectedTypes}
                    handleChange={handleSelectTypes}
                />
            </div>

            <div className={classes.toolbarInput}>
                <CustomSearchInput
                    placeholder={t('LICENSE_PLATE')}
                    handleChange={handleLPChange}
                />
            </div>
        </div>
    );
}

EventsToolbar.propTypes = {
    isOnline: PropTypes.bool.isRequired,
    hosts: PropTypes.array.isRequired,
    selectedHosts: PropTypes.array.isRequired,
    eventTypes: PropTypes.array.isRequired,
    selectedTypes: PropTypes.array.isRequired,
    startTimeStr: PropTypes.object,
    endTimeStr: PropTypes.object,
    handleOnline: PropTypes.func.isRequired,
    handleStartDate: PropTypes.func.isRequired,
    handleEndDate: PropTypes.func.isRequired,
    handleSelectHosts: PropTypes.func.isRequired,
    handleSelectTypes: PropTypes.func.isRequired,
    handleLPChange: PropTypes.func.isRequired,
};

EventsToolbar.defaultProps = {
    startTimeStr: null,
    endTimeStr: null,
};

export default EventsToolbar;
