import React, {useEffect, useRef,} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {Loading} from 'components/elements';
import AdministrationMainContent from './MainContent';
import PerfectScrollbar from "react-perfect-scrollbar";

function Administration(props) {
    const {
        user,
        userUseCases,
        sipCall,
        sipStatus,
    } = props;


    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);


    if (!user) {
        return (
            <Loading/>
        );
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <AdministrationMainContent
                user={user}
                userUseCases={userUseCases}
                sipCall={sipCall}
                sipStatus={sipStatus}
            />
        </PerfectScrollbar>
    );
}

Administration.propTypes = {
    user: PropTypes.object,
};

Administration.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Administration);
