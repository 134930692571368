import React, {useCallback, useEffect, useRef, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux/es';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';

import {CustomButton, Loading} from 'components/elements';
import {
    getCardGroups,
    getParkingAccess,
    getZoneItems,
    isComponentEnabledForUser,
    isComponentVisibleForUser
} from 'utils';
import {fetchParkingAccesses} from 'redux/actions/parkings';
import {fetchParkingCardGroups} from 'redux/actions/cards';
import EditCardsContent from './EditCardsContent';
import {UC_LT_CARD_BLOCK, UC_LT_CARD_DEACTIVATE} from "../CardDetails/CardParkingDetail/CardJournals";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";


const useStyles = makeStyles((theme) => ({
    mb: {
        marginBottom: theme.spacing(1.125),
    },
    container: {
        '& .MuiDialog-paper': {
            width: 834,
            height: 'fit-content',
            maxWidth: 'unset',
            minHeight: 500,
            boxShadow: theme.palette.shadow.main,
            overflow: 'hidden',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.base.white,
    },
    emptyContent: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        height: '15%',
        background: theme.palette.secondary.main,
        color: theme.palette.base.white,
        display: 'flex',
        alignItems: 'center',

        '& h2': {
            fontSize: 25,
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            color: theme.palette.base.white,

            '& span': {
                fontSize: 18,
                paddingTop: theme.spacing(0.5),
            },
        },
    },
    label: {
        width: theme.spacing(11),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    actions: {
        marginBottom: theme.spacing(2),
        justifyContent: 'center',
        textTransform: 'uppercase',
    }
}));

function EditMultiCards(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        opened,
        event,
        isUpdating,
        isBlocking,
        isActivating,
        parkingAccesses,
        parkingCardGroups,
        handleClose,
        bulkEditCards,
        fetchParkingAccesses,
        fetchParkingCardGroups,
        handleConfirmMultipleCardsOpen,
        userUseCases,
    } = props;

    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const fetchCardDetail = useCallback(() => {
        setIsLoading(true);
        const actions = [
            fetchParkingAccesses(event.parkingId, 'LT'),
            fetchParkingCardGroups(event.parkingId),
        ];

        Promise.all(actions)
            .then(() => {
                if (!isUnMounted.current) {
                    setIsLoading(false);
                }
            });
    }, [event, fetchParkingAccesses, fetchParkingCardGroups]);

    useEffect(() => {
        fetchCardDetail();
    }, [fetchCardDetail]);

    const accesses = getParkingAccess(parkingAccesses);
    const cardGroups = getCardGroups(parkingCardGroups);
    const zones = getZoneItems(event.zoneStatus);

    const [state, setState] = useState({
        access: null,
        cardGroups: [],
    });

    const handleChange = (field) => (change) => {
        setState({
            ...state,
            [field]: (field === 'blockingReason' || field === 'comment')
                ? change.target.value
                : change,
        });
    };

    const handleSubmit = (field) => () => {
        if (isUpdating || !state[field]) {
            return;
        }

        let cards = [];

        const {type} = event.cards[0];

        const zoneId = state.zone ? parseInt(state.zone.replace('zone_', ''), 10) : null;
        const selectedZone = zoneId ? zones.find((z) => z.zoneId === zoneId) : null;

        if (field === 'validFrom') {
            cards = event.cards.map((card) => ({
                cardNumber: card.cardNumber,
                validFromTS: state.validFrom ? new Date(state.validFrom).getTime() : null,
                validToTS: null,
                note: null,
                zoneId: null,
                zoneReservationId: null,
                block: null,
                valid: null,
                blockingReason: null,
                cardGroups: null,
                parkingAccessId: null,
            }));
        } else if (field === 'validTo') {
            cards = event.cards.map((card) => ({
                cardNumber: card.cardNumber,
                validFromTS: null,
                validToTS: state.validTo ? new Date(state.validTo).getTime() : null,
                note: null,
                zoneId: null,
                zoneReservationId: null,
                block: null,
                valid: null,
                blockingReason: null,
                cardGroups: null,
                parkingAccessId: null,
            }));
        } else if (field === 'access') {
            cards = event.cards.map((card) => ({
                cardNumber: card.cardNumber,
                validFromTS: null,
                validToTS: null,
                note: null,
                zoneId: null,
                zoneReservationId: null,
                block: null,
                valid: null,
                blockingReason: null,
                cardGroups: null,
                parkingAccessId: state.access ? accesses.find((a) => a.value === state.access).id : null,
            }));
        } else if (field === 'cardGroups') {
            cards = event.cards.map((card) => ({
                cardNumber: card.cardNumber,
                validFromTS: null,
                validToTS: null,
                note: null,
                zoneId: null,
                zoneReservationId: null,
                block: null,
                valid: null,
                blockingReason: null,
                cardGroups: state.cardGroups,
                parkingAccessId: null,
            }));
        } else if (field === 'zone') {
            cards = event.cards.map((card) => ({
                cardNumber: card.cardNumber,
                validFromTS: null,
                validToTS: null,
                note: null,
                zoneId,
                zoneReservationId: null,
                block: null,
                valid: null,
                blockingReason: null,
                cardGroups: null,
                parkingAccessId: null,
            }));
        } else if (field === 'zoneReservationId') {
            cards = event.cards.map((card) => ({
                cardNumber: card.cardNumber,
                validFromTS: null,
                validToTS: null,
                note: null,
                zoneId: null,
                zoneReservationId: state.zoneReservationId
                    ? parseInt(state.zoneReservationId.replace('zone_', ''), 10)
                    : null,
                block: null,
                valid: null,
                blockingReason: null,
                cardGroups: null,
                parkingAccessId: null,
            }));
        } else if (field === 'comment') {
            cards = event.cards.map((card) => ({
                cardNumber: card.cardNumber,
                validFromTS: null,
                validToTS: null,
                note: state.comment || null,
                zoneId: null,
                zoneReservationId: null,
                block: null,
                valid: null,
                blockingReason: null,
                cardGroups: null,
                parkingAccessId: null,
            }));
        }

        switch (field) {
            case 'access':
                return bulkEditCards(
                    event.parkingId,
                    cards,
                    {
                        parkingAccessId: state.access ? accesses.find((a) => a.value === state.access).id : null,
                    },
                    type,
                ).then(handleClose);
            case 'cardGroups':
                return bulkEditCards(
                    event.parkingId,
                    cards,
                    {
                        cardGroups: state.cardGroups || null,
                    },
                    type,
                ).then(handleClose);
            case 'zone':
                return bulkEditCards(
                    event.parkingId,
                    cards,
                    {
                        zoneId,
                        zoneName: selectedZone ? selectedZone.label : null,
                    },
                    type,
                ).then(handleClose);
            case 'validFrom':
                return bulkEditCards(
                    event.parkingId,
                    cards,
                    {
                        validFromTS: state.validFrom ? new Date(state.validFrom).getTime() : null,
                    },
                    type,
                ).then(handleClose);
            case 'validTo':
                return bulkEditCards(
                    event.parkingId,
                    cards,
                    {
                        validToTS: state.validTo ? new Date(state.validTo).getTime() : null,
                    },
                    type,
                ).then(handleClose);
            case 'comment':
                return bulkEditCards(
                    event.parkingId,
                    cards,
                    {
                        note: state.comment || null,
                    },
                    type,
                ).then(handleClose);
            case 'zoneReservationId':
                return bulkEditCards(
                    event.parkingId,
                    cards,
                    {
                        zoneReservationId: state.zoneReservationId
                            ? parseInt(state.zoneReservationId.replace('zone_', ''), 10)
                            : null,
                    },
                    type,
                ).then(handleClose);
            default:
                return null;
        }
    };

    const handleActivateCards = () => {
        if (isActivating) {
            return;
        }

        handleConfirmMultipleCardsOpen(event, null, 'deactivate');
    };

    const handleBlockCards = () => {
        if (isBlocking || !state.blockingReason) {
            return;
        }

        handleConfirmMultipleCardsOpen(event, state.blockingReason, 'block');
    };

    return (
        <Dialog
            disableEnforceFocus
            className={classes.container}
            open={opened}
            onClose={handleClose}
        >
            <DialogTitle className={classes.title}>
                {t('BULK_ACTION')}
                <span>{event.parkingName}</span>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>

            <DialogContent className={isLoading ? classes.emptyContent : null}>
                {isLoading ? (
                    <Loading/>
                ) : (
                    <EditCardsContent
                        state={state}
                        zones={zones}
                        type={event.cards[0].type}
                        accesses={accesses}
                        cardGroups={cardGroups}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DialogContent>

            <DialogActions className={classes.actions}>
                <CustomButton
                    label={t('CLOSE')}
                    color="grey"
                    width={180}
                    onClick={handleClose}
                />
                {isComponentVisibleForUser(UC_LT_CARD_BLOCK, userUseCases) &&
                <CustomButton
                    label={t('BLOCK')}
                    width={180}
                    disabled={!state.blockingReason || !isComponentEnabledForUser(UC_LT_CARD_BLOCK, userUseCases)}
                    onClick={handleBlockCards}
                />
                }
                {isComponentVisibleForUser(UC_LT_CARD_DEACTIVATE, userUseCases) &&
                <CustomButton
                    label={t('INVALID')}
                    color="red"
                    width={180}
                    disabled={!isComponentEnabledForUser(UC_LT_CARD_DEACTIVATE, userUseCases)}
                    onClick={handleActivateCards}
                />
                }
            </DialogActions>
        </Dialog>
    );
}

EditMultiCards.propTypes = {
    opened: PropTypes.bool.isRequired,
    event: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isBlocking: PropTypes.bool.isRequired,
    isActivating: PropTypes.bool.isRequired,
    parkingAccesses: PropTypes.array.isRequired,
    parkingCardGroups: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    bulkEditCards: PropTypes.func.isRequired,
    fetchParkingAccesses: PropTypes.func.isRequired,
    fetchParkingCardGroups: PropTypes.func.isRequired,
    handleConfirmMultipleCardsOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
    isUpdating: store.cardsData.isUpdating,
    isBlocking: store.cardsData.isBlocking,
    isActivating: store.cardsData.isBlocking,
    parkingAccesses: store.parkingsData.parkingAccesses,
    parkingCardGroups: store.cardsData.parkingCardGroups,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingAccesses,
    fetchParkingCardGroups,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditMultiCards);
