import { getOwnersQuery } from 'utils';
import httpService from './http.service';

const getSubjectOwners = (subjectId, validity, search, size, page) => httpService
  .get(`/cardsowners/subject/${subjectId}${getOwnersQuery(validity, search, size, page)}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const getSubjectSearchOwners = (keyword) => httpService
  .get(`/cardsowners/search?searchText=${keyword}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const getOwnerDetail = (ownerId) => httpService
  .get(`/cardsowners/detail/${ownerId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const updateOwner = (owner) => httpService
  .post('/cardsowners/detail', owner)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const setOwnerActivate = (ownerId, activate) => httpService
  .post(`/cardsowners/${ownerId}/activate?active=${activate}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getSubjectOwners,
  getOwnerDetail,
  updateOwner,
  setOwnerActivate,
  getSubjectSearchOwners,
};
