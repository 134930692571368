import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import {ActionButton} from 'components/common';
import PMCSnackbar from "../../common/Snackbar";
import WizardStep1 from "./WizardStep1";
import WizardStep2 from "./WizardStep2";
import WizardStep3 from "./WizardStep3";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
    },
    containerMax: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height:'100%'
    },
    actions: {
        padding: 0,
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    contentGrey: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
        background: theme.palette.base[200],

    },
    stepNavigation: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: theme.palette.base.white,
        boxShadow: theme.palette.shadow.main,
        '& .MuiStepIcon-active': {
            color: theme.palette.secondary.main,
        }

    },
    root: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    root1: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
        paddingBottom: '24px',
    },

    desc: {
        width: '100%',
        height: '35px',
        textAlign: 'center',
        color: '#A2A2A2',
        fontSize: '25px',
    },
    descBelow: {
        width: '100%',
        height: '35px',
        display: 'flex',
        flexDirection: 'row',
        color: '#A2A2A2',
        fontSize: '20px',
        justifyContent: 'center'
    },
    descItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: '4px',
    },
    descLabel: {
        color: '#A2A2A2',
        fontSize: '10px',
    },
    descValue: {
        color: '#1B1B28',
        fontSize: '12px',
    },
    button: {
        width: 200,
        height: 55,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 25,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
}));


function ParkingWizard(props) {
    const {
        userUseCases,
        subjects,
        roles,
        save,
        handleClose,
        readOnly,
        snackbarOpen,
        error,
        setSnackbarOpen,
        setError,
        parkingConfigurationModel,
        sipCall,
        sipStatus,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updatedParking, setUpdatedParking] = useState();

    const [parkingTypeInner, setParkingTypeInner] = useState();

    const sounds = useSelector((state) => state.soundsData.sounds);

    useEffect(() => {
        if (parkingConfigurationModel.parking) {
            setUpdatedParking(parkingConfigurationModel.parking);
            if (parkingConfigurationModel.parkingDetailOpen) {
                setActiveStep(0);
            }
        }
    }, [parkingConfigurationModel]);


    const [subjectValues, setSubjectValues] = useState([]);


    useEffect(() => {
        if (subjects) {
            setSubjectValues(subjects.map(a => {
                return {value: a.subjectId, label: a.name, key: a.subjectId}
            }));
        }
    }, [subjects]);


    const getParkingSubject = () => {
        if (updatedParking && subjectValues) {
            const subject = subjectValues.find(a => a.key == updatedParking.subjectId);
            if (subject) {
                return subject.label;
            }
        }
        return '';
    }


    const getSteps = () => {
        return ['Host configuration', 'Zone configuration', 'Event configuration'];
    }


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const getSubject = (parkingSubject, subjects) => {
        if (parkingSubject) {
            const p = subjects && subjects.find(b => b.value == parkingSubject);
            if (!p) {
                return {label: 'Not Found', value: parkingSubject};
            }
            return p;
        }
        return null;
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <WizardStep1
                    parking={updatedParking}
                    readOnly={readOnly}
                    userUseCases={userUseCases}
                    sipCall={sipCall}
                    sipStatus={sipStatus}
                />
            case 1:
                return <WizardStep2
                    parking={updatedParking}
                    readOnly={readOnly}
                    userUseCases={userUseCases}
                />
            case 2:
                return <WizardStep3
                    parking={updatedParking}
                    readOnly={readOnly}
                    userUseCases={userUseCases}
                    sounds={sounds}
                />
            default:
                return 'Unknown step';
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((activeStep) => activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1);
    };


    return (
        <Dialog
            className={classes.container}
            open={parkingConfigurationModel.parkingDetailOpen}
            onClose={handleClose}
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
                <span>
                    {t('UPDATE_PARKING')}
                </span>
            </DialogTitle>


            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={error}/>

            <div className={classes.stepNavigation}>
                <Stepper activeStep={activeStep} color="secondary"
                         classes={{root: classes.root}}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div className={classes.descBelow}>
                    <div className={classes.descItem}>
                        <div className={classes.descLabel}>
                            {t('PARKING_NAME')}
                        </div>
                        <div className={classes.descValue}>
                            {updatedParking && updatedParking.parkingName}
                        </div>
                    </div>
                    <div className={classes.descItem}>
                        <div className={classes.descLabel}>
                            {t('SUBJECT')}
                        </div>
                        <div className={classes.descValue}>
                            {getParkingSubject()}
                        </div>
                    </div>
                </div>
            </div>
            <DialogContent
                className={classes.content}>
                <div className={activeStep==2 ? classes.containerMax: classes.container}>
                    {getStepContent(activeStep)}
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>

                    <ActionButton
                        action="close"
                        handleClick={handleClose}
                        className={classes.button}
                    />
                </div>
                <div className={classes.buttonNavigation}>

                    <ActionButton
                        disabled={activeStep === 0} onClick={handleBack} className={classes.button}
                        action="back"
                        handleClick={handleBack}
                        className={classes.button}
                    />
                    <ActionButton
                        disabled={(activeStep === (steps.length - 1))}
                        action="next"
                        handleClick={handleNext}
                        className={classes.button}
                    />
                </div>
            </DialogActions>
        </Dialog>

    );
}

ParkingWizard.propTypes = {};


const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingWizard);


