import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import {CustomButton} from 'components/elements';
import {getActionLabel} from '../../containers/Parking/LocalParking/LocalParkingHosts/Utils';
import Check from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { getErrorMessage2 } from 'utils';
import PMCSnackbar from 'components/common/Snackbar';

const useStyles = makeStyles((theme) => ({
    title: {
        background: "#004980",
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    formGroup: {
        marginBottom: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    label: {
        width: theme.spacing(13),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    textField: {
        flex: 1,
        marginLeft: theme.spacing(3),
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    actionButton: {
        marginLeft: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.base.white,
    },
    labelText: {
        width: '100%',
        color: '#A2A2A2',
        textAlign: 'center'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    dialogPaper: {
        height: props => `${props.height}px`,
    },
}));

function CreateAction(props) {
    const {t} = useTranslation();

    const {
        action,
        host,
        opened,
        handleClose,
        createParkingHostAction,
    } = props;

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorAction, setErrorAction] = useState('');

    const [state, setState] = useState({
        actionName: action.actionName,
        actionType: action.actionType,
        actionCode: action.actionCode,
    });
    const handleChange = (field) => (event) => {
        setState({
            ...state,
            [field]: event.target.value,
        });
    };

    const handleSubmit = () => {
        const actionData = {
            actionName: action.actionName,
            actionType: action.actionType,
            actionCode: action.actionCode,
            actionDialogType: action.actionDialogType,
            actionDialogMessage: state.commandDialogMessage,
            actionValue: true,
        };

        createParkingHostAction(host.parkingHostId, actionData)
            .then(handleClose)
            .catch((error) => {
                console.log(error);
                setSnackbarOpen(true);
                setErrorAction(getErrorMessage2(error, t));
            });
    };

    const classes = useStyles({height: action.actionDialogType === 'CMD_DIALOG_TYPE_YES_NO' ? 200 : 550});

    const handleCloseSnackbar = (event, reason) => {
        if ( reason === 'ckuecjawat' ) {
            return;
        }

        setSnackbarOpen(false);
    }

    return (
        <Dialog
            open={opened}
            onClose={handleClose}
            maxWidth={action.actionDialogType === 'CMD_DIALOG_TYPE_YES_NO' ? "xs" : "md"}
            classes={{paper: classes.dialogPaper}}
            disableEnforceFocus
            onKeyUp={(e) => {
                if (opened) {
                    const ENTER = 13;
                    if (e.keyCode === ENTER) {
                        handleSubmit && handleSubmit();
                    }
                }
            }}
        >

            <DialogTitle className={classes.title}>
                {t('ACTION_CONFIRMATION')}
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <PMCSnackbar onep={snackbarOpen} onClose={handleCloseSnackbar} severity="error" message={errorAction} />

                {action.actionDialogType === 'CMD_DIALOG_TYPE_YES_NO' && (
                    <Typography className={classes.labelText}>
                        {t('CREATE_ACTION_YES_NO', {reason: getActionLabel(action.actionName, t)})}
                    </Typography>
                )}

                {action.actionDialogType === 'CMD_DIALOG_TYPE_REASON' && (
                    <Box className={classes.formGroup}>
                        <Typography
                            variant="button"
                            className={classes.label}
                        >
                            {t('REASON')}
                        </Typography>
                        <TextField
                            autoFocus
                            required
                            className={classes.textField}
                            hiddenLabel
                            type="commandDialogMessage"
                            value={state.commandDialogMessage || ''}
                            onChange={handleChange('commandDialogMessage')}
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <CustomButton
                    className={classes.actionButton}
                    label={t(action.actionDialogType === 'CMD_DIALOG_TYPE_YES_NO' ? 'NO' : 'CLOSE')}
                    color="grey"
                    width={180}
                    onClick={handleClose}
                />
                <CustomButton
                    label={t(action.actionDialogType === 'CMD_DIALOG_TYPE_YES_NO' ? 'YES' : 'OK')}
                    width={180}
                    muiIcon={<Check/>}
                    color="green"
                    onClick={handleSubmit}
                />
            </DialogActions>
        </Dialog>
    );
}

CreateAction.propTypes = {
    action: PropTypes.object,
    host: PropTypes.object,
    opened: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    createParkingHostAction: PropTypes.func.isRequired,
};

CreateAction.defaultProps = {
    action: null,
    host: null,
};

export default CreateAction;
