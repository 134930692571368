import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {numberFormatPriceCustom} from 'utils';
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import { LinearProgress } from '@material-ui/core';
import { ActionButton } from 'components/common';


const PriceInputSmall = withStyles((theme) => ({
    input: {
        fontSize: 25,
        textAlign: 'right',
        color: '#A2A2A2',
    },
}))(InputBase);


const PriceInput = withStyles((theme) => ({
    input: {
        fontSize: 35,
        textAlign: 'right',
        color: '#1B1B28',
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    paymentContainer:{
        height: '30%',
        backgroundColor: '#0075ff2b',
    },
    label: {
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'left',
        fontSize: '47.5px',
        paddingTop: theme.spacing(1.5),
    },
    paymentLabel: {
        textAlign: 'center',
        fontSize: '36px',
        lineHeight: '43px'
    },
    paymentLabel2: {
        textAlign: 'center',
        fontSize: '18px',
    },
    value: {
        width: theme.spacing(13),
        marginRight: theme.spacing(3),
        color: '#1B1B28',
        textAlign: 'right',
        fontSize: '35px',
    },
    valueKC: {
        width: theme.spacing(13),
        marginRight: theme.spacing(3),
        color: '#95989A',
        textAlign: 'right',
        fontSize: '35px',
    },
    price: {
        width: '25%',
    },
    select: {
        fontSize: '35px',
        color: '#1B1B28',
    },
}));


function CardPayment(props) {
    const {
        userUseCases,
        receipt,
        rates,
        currencies,
        selectedCurrency,
        onCurrencyChange,

        paymentOpen,
        paymentMessage,

        ccState,
        handleCloseReceipt
    } = props;


    const classes = useStyles();
    const {t} = useTranslation();
    const [rate, setRate] = useState(1);
    const [displayRate, setDisplayRate] = useState(1);


    useEffect(() => {
            if (rates && selectedCurrency && receipt) {
                if (selectedCurrency === receipt.currency) {
                    setRate(1);
                    setDisplayRate(1);
                    return;
                }
                let found = false;
                for (let i = 0; i < rates.length; i++) {
                    if ((rates[i].currencyFrom === receipt.currency) && (rates[i].currencyTo === selectedCurrency)) {
                        setRate(rates[i].rate);
                        setDisplayRate(rates[i].rate);
                        return;
                    } else if ((rates[i].currencyTo === receipt.currency) && (rates[i].currencyFrom === selectedCurrency)) {
                        const newRate = 1 / rates[i].rate;
                        setRate(newRate);
                        setDisplayRate(rates[i].rate);
                        return;
                    }
                }
                setRate(undefined);
            }
        }, [selectedCurrency, rates, receipt]
    );


    return (
        <div className={classes.container}>
            <Box mb={2} style={{height: '100%'}}>
                <Grid container style={paymentOpen ? {height: '70%'} : {height: '100%'}}>
                    <Grid item xs sm={8}>
                        <div className={classes.label}>{t('TOTAL_PAY')}</div>
                    </Grid>
                    <Grid item xs sm={4}>
                        <Grid container>
                            {receipt && selectedCurrency != receipt.currency &&
                            <Grid item xs sm={12}>
                                <PriceInput
                                    fullWidth={true}
                                    disabled={true}
                                    value={receipt.priceWithVAT * rate}
                                    endAdornment={<InputAdornment
                                        disableTypography
                                        classes={{root: classes.select}}
                                        position="end">{selectedCurrency}</InputAdornment>}
                                    inputComponent={numberFormatPriceCustom}
                                />
                            </Grid>
                            }
                            <Grid item xs sm={12}>

                                <PriceInput
                                    fullWidth={true}
                                    disabled={true}
                                    value={receipt.priceWithVAT}
                                    endAdornment={<InputAdornment
                                        disableTypography
                                        classes={{root: classes.select}}
                                        position="end">{receipt.currency}</InputAdornment>}
                                    inputComponent={numberFormatPriceCustom}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs sm={8}>

                    </Grid>
                    <Grid item container xs sm={4} direction="column">
                        <FormControl required fullWidth>
                            <InputLabel id="currency-label">
                                {t('CURRENCY')}
                            </InputLabel>
                            <Select
                                className={classes.select}
                                autoWidth
                                value={selectedCurrency}
                                id="currency"
                                onChange={onCurrencyChange}
                                disabled={paymentOpen}
                            >
                                {currencies && currencies.map(a => {
                                    return (
                                        <MenuItem value={a.value}>{a.label}</MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                        {receipt && selectedCurrency != receipt.currency &&
                        <PriceInputSmall
                            fullWidth
                            size="small"
                            disabled={true}
                            id="rate"
                            value={displayRate || ''}
                            inputComponent={numberFormatPriceCustom}
                        />
                        }
                    </Grid>
                </Grid>
                { paymentOpen && 
                    <Grid container className={classes.paymentContainer}>
                        <Grid item xs sm={12}>
                            <LinearProgress color="secondary" />
                        </Grid>
                        <Grid item xs sm={12}>
                            <div className={classes.paymentLabel}>{paymentMessage}</div>
                        </Grid>
                        <Grid item xs sm={12}>
                            <div className={classes.paymentLabel2}>{t('PAYMENT_WAITING_MESSAGE')}</div>
                        </Grid>

                        {ccState==='TIMEOUT' &&
                            <>
                                <Grid item xs sm={2}></Grid>
                                <Grid item xs sm={2}>
                                    <ActionButton action='ok' handleClick={handleCloseReceipt('FINISHED')} />
                                </Grid>
                                <Grid item xs sm={2}></Grid>
                                <Grid item xs sm={2}></Grid>
                                <Grid item xs sm={2}>
                                    <ActionButton action='cancel' handleClick={handleCloseReceipt('CANCELED')} />
                                </Grid>
                                <Grid item xs sm={2}></Grid>
                            </>
                        }
                    </Grid>                
                }
            </Box>
        </div>
    );
}

CardPayment.propTypes = {};

const UC_TAB_ACCESS = 'UC0062';
const UC_PRODUCT_ADMINISTRATION = 'UC0083';

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardPayment);

