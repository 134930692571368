import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';

import {CustomButton} from 'components/elements';
import CardZoneSettings from './CardZoneSettings';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';
import {ActionButton} from "../../../common";


const useStyles = makeStyles((theme) => ({
    container: {
        width: '45%',
        minWidth: 400,
        height: '100%',
    },
    container100: {
        width: '100%',
        minWidth: 400,
        height: '100%',
    },
    content: {
        padding: theme.spacing(1, 0),
        flex: 1,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.base[200]}`,
    },
    formGroup: {
        height: 39,
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        fontSize: 14,
        width: theme.spacing(10),
        marginRight: theme.spacing(3),
        color: theme.palette.base[300],
        textAlign: 'right',
    },
    zoneLabel: {
        marginLeft: 0,
        textAlign: 'left',
        width: theme.spacing(15),
    },
    zones: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    zone: {
        width: '33%',
        display: 'flex',
        alignItems: 'center',
    },
    settingActions: {
        marginLeft: 0,
        position: 'absolute',
        bottom: theme.spacing(3),
    },
    actionButton: {
        marginLeft: theme.spacing(1),
    },
}));

function CardParkingSettings(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        isLT,
        card,
        error,
        cardGroups,
        accesses,
        selectedParking,
        zones,
        cardZone,
        handleSubmitChange,
        handleClose,
        handleUpdateCard,
        userUseCases,
        productMode,
    } = props;

    const getDefaultZoneValue = (field) => {
        if (zones.length === 0) {
            return '';
        }

        let defaultZone;
        if (field === 'zoneReservationId') {
            defaultZone = cardZone.zoneReservationId
                ? selectedParking.zoneStatus.find((z) => z.zoneId === cardZone.zoneReservationId)
                : null;
        } else {
            defaultZone = cardZone.zoneId
                ? selectedParking.zoneStatus.find((z) => z.zoneId === cardZone.zoneId)
                : null;
        }

        return defaultZone
            ? zones.find((z) => z.zoneId === defaultZone.zoneId).value
            : '';
    };

    const getDefaultAccess = () => {
        const parkingAccessId = cardZone.parkingAccessId || null;
        if (parkingAccessId !== null) {
            const selectedAccess = accesses.find((a) => a.id === parkingAccessId);
            return selectedAccess ? selectedAccess.value : '';
        }
        if (cardZone.prevParkingAccessName) {
            const accessWithSameName = accesses.find((a) => a.value === cardZone.prevParkingAccessName);
            if (accessWithSameName) {
                return cardZone.prevParkingAccessName;
            }
        }
        return '';
    };

    const defaultSetting = {
        cardGroups: cardZone.cardGroups || [],
        carNumber: cardZone.lpn || '',
        comment: cardZone.note || '',
        blockingReason: cardZone.blockingReason || '',
        access: getDefaultAccess(),
        validFrom: cardZone.validFromTS ? new Date(cardZone.validFromTS) : '',
        validTo: cardZone.validFromTS ? new Date(cardZone.validToTS) : '',
        entryTime: cardZone.validFromTS ? new Date(cardZone.entryTimeTS) : '',
        exitTime: cardZone.validFromTS ? new Date(cardZone.exitTimeTS) : '',
        credit: cardZone.credit || '',
        zone: getDefaultZoneValue('zone'),
        zoneReservationId: getDefaultZoneValue('zoneReservationId'),
    };

    const [setting, setSetting] = useState(defaultSetting);

    useEffect(() => {
        setSetting((prevState) => ({
            ...prevState,
            access: getDefaultAccess(),
        }));
    }, [cardZone, accesses]);

    const handleChange = (field) => (event) => {
        switch (field) {
            case 'credit':
            case 'carNumber':
            case 'comment':
                setSetting({
                    ...setting,
                    [field]: event.target.value,
                });

                handleSubmitChange({
                    ...setting,
                    [field]: event.target.value,
                });
                return;
            case 'cardGroups':
                setSetting({
                    ...setting,
                    [field]: event,
                });

                handleSubmitChange({
                    ...setting,
                    [field]: event,
                });
                return;
            default:
                setSetting({
                    ...setting,
                    [field]: event,
                });

                handleSubmitChange({
                    ...setting,
                    [field]: event,
                });
        }
    };

    const handleSaveChanges = () => {
        handleSubmitChange(setting);
    };

    const updateCard = () => {
        handleSaveChanges();
        handleUpdateCard(setting);
    };

    const handleCalculateCredit = () => {
        const validToTS = new Date(setting.validTo).getTime();
        const validFromTS = new Date(setting.validFrom).getTime();
        setSetting({
            ...setting,
            credit: Math.floor((validToTS - validFromTS) / 60000),
        });
    };

    return (
        <div className={productMode ? classes.container100 : classes.container}>
            <CardZoneSettings
                card={card}
                error={error}
                selectedParking={selectedParking}
                isLT={isLT}
                setting={setting}
                cardGroups={cardGroups}
                accesses={accesses}
                zones={zones}
                cardZone={cardZone}
                handleChange={handleChange}
                handleCalculateCredit={handleCalculateCredit}
            />

            {!productMode && <div className={classes.settingActions}>
                <CustomButton
                    className={classes.actionButton}
                    label={t('CLOSE')}
                    color="grey"
                    width={180}
                    onClick={handleClose}
                />
                {isLT && isComponentVisibleForUser(UC_LT_CARD_EDIT, userUseCases) && (
                    <ActionButton
                        className={classes.actionButton}
                        type="submit"
                        disabled={!isComponentVisibleForUser(UC_LT_CARD_EDIT, userUseCases)}
                        action="saveModal"
                        width={180}
                        handleClick={updateCard}
                    />
                )}
            </div>}
        </div>
    );
}

CardParkingSettings.propTypes = {
    isLT: PropTypes.bool.isRequired,
    error: PropTypes.object,
    card: PropTypes.object.isRequired,
    cardGroups: PropTypes.array.isRequired,
    accesses: PropTypes.array.isRequired,
    selectedParking: PropTypes.object.isRequired,
    zones: PropTypes.array.isRequired,
    cardZone: PropTypes.object.isRequired,
    handleSubmitChange: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleUpdateCard: PropTypes.func.isRequired,
};

CardZoneSettings.defaultProps = {
    error: null,
};

const UC_LT_CARD_EDIT = 'UC0026';


export default CardParkingSettings;
