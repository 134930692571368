import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {CustomSwitch, CustomTable, Loading} from 'components/elements';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CustomCheckbox from 'components/elements/CustomCheckbox';
import {fetchProductTemplateParamSelect} from 'redux/actions/products';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextInnerField from "./TextInnerField";
import DateStep from "../../elements/DateStep";


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        background: theme.palette.base.white,
        padding: theme.spacing(0, 1),
        marginTop: theme.spacing(-0.5),
        '& .MuiTableFooter-root': {
            display: 'none !important',
        },
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },

}));

const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

function WizardStep2Table(props) {
    const classes = useStyles();

    const {
        productTemplateParams,
        product,
        handleParamDefaultValueChange,
        handleNameChange,
        handleEditableChange,
        handleVisibleChange,
        fetchProductTemplateParamSelect,
        productTemplateSelectValues,
        error,
        readOnly,
        handleStepperChange,
    } = props;

    const {t} = useTranslation();


    const loadSelectValues = (productTemplateParams, parkingId) => {
        const actions = [];
        for (let i = 0; i < productTemplateParams.length; i++) {
            if (productTemplateParams[i].paramType === 'PRODUCT_TYPE_SELECT') {
                actions.push(productTemplateParams[i].paramName);
            }
        }
        fetchProductTemplateParamSelect(actions, [parkingId]);
    };


    const [mergedValues, setMergedValues] = useState([]);

    const [loadedSelectValuesForParking, setLoadedSelectValuesForParking] = useState();


    useEffect(() => {
        if (productTemplateParams) {
            if (!loadedSelectValuesForParking || loadedSelectValuesForParking !== product.parkingId) {
                loadSelectValues(productTemplateParams, product.parkingId);
                setLoadedSelectValuesForParking(product.parkingId);
            }
        }
    }, [productTemplateParams, product]);


    useEffect(() => {
        if (productTemplateParams && product) {
            const values = [];
            for (let i = 0; i < productTemplateParams.length; i++) {
                const productParamValue = product.productParam.find(a => a.paramName === productTemplateParams[i].paramName);

                if (productParamValue) {
                    const required = productTemplateParams[i].required && productTemplateParams[i].required === true && (!productParamValue.visible
                        || !productParamValue.editable);
                    values.push({
                        ...productTemplateParams[i],
                        paramLabel: productParamValue.paramLabel,
                        productValueNumber: productParamValue.valueNumber,
                        productValueVarchar: productParamValue.valueVarchar,
                        productValueDate: productParamValue.valueDate,
                        productValueBoolean: productParamValue.valueBoolean,
                        productValueSelect: productParamValue.valueSelect,
                        productEditable: productParamValue.editable,
                        productVisible: productParamValue.visible,
                        parkingId: product.parkingId,
                        stepper: productParamValue.stepper,
                        required

                    });
                }
            }
            setMergedValues(values);
        }
    }, [product, productTemplateParams]);


    const getSelectValues = (rowData, items) => {

        if (rowData.multiValue) {
            return rowData.productValueSelect ?
                rowData.productValueSelect.map(a => {
                    const p = items && items.find(b => b.value == a);
                    if (!p) {
                        return {label: 'Not Found', value: a};
                    }
                    return p;
                }) :
                [];
        } else {
            if (rowData.productValueSelect) {
                const p = items && items.find(b => b.value == rowData.productValueSelect);
                if (!p) {
                    return {label: 'Not Found', value: rowData.productValueSelect};
                }
                return p;
            }
            return null;
        }
    }


    const
        defaultComponent = (rowData, productTemplateSelectValues, error) => {
            switch (rowData.paramType) {
                case 'PRODUCT_TYPE_NUMBER':
                    console.warn(rowData.paramName, rowData.productValueNumber);
                    return <TextInnerField
                        type="number"
                        required={rowData.required}
                        label={t('DEFAULT')}
                        helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        rowData={rowData}
                        initialValue={rowData.productValueNumber}
                        onBlur={handleParamDefaultValueChange}
                    />;
                case 'PRODUCT_TYPE_TEXT':
                    return <TextInnerField
                        rowData={rowData}
                        initialValue={rowData.productValueVarchar}
                        onBlur={handleParamDefaultValueChange}
                        label={t('DEFAULT')}
                        required={rowData.required}
                        helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}/>;
                case 'PRODUCT_TYPE_DATE':
                    return <DateStep
                        value={rowData.productValueVarchar}
                        label={t('DEFAULT')}
                        required={rowData.required}
                        disabled={readOnly}
                        showMode={true}
                        stepper={rowData.stepper}
                        handleStepperChange={handleStepperChange(rowData.paramName)}
                        handleChange={handleParamDefaultValueChange(rowData.paramName)}
                        helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}>
                    </DateStep>
                case 'PRODUCT_TYPE_BOOLEAN':
                    return <CustomSwitch color="secondary"
                                         disabled={readOnly}
                                         checked={rowData.productValueBoolean === true}
                                         handleSwitch={handleParamDefaultValueChange(rowData.paramName)}/>
                case 'PRODUCT_TYPE_SELECT':
                    const items = productTemplateSelectValues[`${rowData.paramName}${rowData.parkingId}`];
                    if (items) {
                        return <Autocomplete
                            multiple={rowData.multiValue}
                            limitTags={3}
                            disabled={readOnly}
                            value={getSelectValues(rowData, items)}
                            onChange={(event, newValue) => {
                                handleParamDefaultValueChange(rowData.paramName)(newValue);

                            }}
                            filterSelectedOptions
                            id={rowData.paramName}
                            options={items}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            style={{width: '100%'}}
                            renderInput={(params) => (
                                <TextField {...params} label={t('DEFAULT')} required={rowData.required} fullWidth
                                           helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                                               ? <span
                                                   className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                               : null}

                                />
                            )}
                            size="small"

                        />

                    } else {
                        return <Loading/>
                    }
                default:
                    return <div>Not Supported</div>;

            }
        }


    const columns = [
        {
            title: t('NAME'),
            field: 'name',
            cellStyle,
            render: (rowData) => {
                return (<TextInnerField rowData={rowData}
                                        onBlur={handleNameChange}
                                        initialValue={t(rowData.paramLabel)}
                                        readOnly={readOnly}
                                        label={t(rowData.paramName)}
                                        helperText={error && error[rowData.paramName] && error[rowData.paramName].paramLabel
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                />)
            },
        },
        {
            title: t('EDITABLE'),
            field: 'editable',
            cellStyle,
            render: (rowData) => {
                return (
                    <CustomCheckbox
                        disabled={readOnly}
                        checked={rowData.productEditable}
                        handleChange={handleEditableChange(rowData.paramName)}
                    />
                )
            }
        },
        {
            title: t('VISIBLE'),
            field: 'visible',
            cellStyle,
            render: (rowData) => (
                <CustomCheckbox
                    disabled={rowData.productEditable || readOnly}
                    checked={rowData.productVisible}
                    handleChange={handleVisibleChange(rowData.paramName)}
                />
            ),
        },
        {
            title: t('DEFAULT'),
            field: 'default',
            cellStyle,
            render: (rowData) => {
                return (
                    <Box className={classes.content}>
                        {defaultComponent(rowData, productTemplateSelectValues, error)}
                    </Box>
                );
            },
        },
    ];


    const tableOptions = {
        headerStyle: {
            padding: '15px 8px 15px 34px',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
        },
    };


    return (
        <div className={classes.container}>
            <CustomTable
                title=""
                columns={columns}
                data={mergedValues}
                options={{
                    ...tableOptions,
                    sorting: false,
                    draggable: false,
                    paging: false,
                }}
                isLoading={false}
                count={mergedValues.length}
                loadAllData={false}
            />
        </div>
    );
}

WizardStep2Table.propTypes = {};

WizardStep2Table.defaultProps = {};

const mapStateToProps = (store) => ({
    productTemplate: store.productsData.productTemplate,
    productTemplateSelectValues: store.productsData.productTemplateSelectValues,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchProductTemplateParamSelect,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep2Table);


