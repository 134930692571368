import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingCardPaymentList: false,

  cardPayment: [],

  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CARD_PAYMENT_FETCH_REQUEST:
      return {
        ...state,
        isFetchingCardPaymentList: true,
      };
    case types.CARD_PAYMENT_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingCardPaymentList: false,
        cardPayment: action.payload.cardPayment,
      };
    case types.CARD_PAYMENT_FETCH_FAIL:
      return {
        ...state,
        isFetchingCardPaymentList: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
