import actionTypes from '../actionTypes';

const INITIAL_STATE = {
  user: null,
  errors: '',
  sipParkingIds: [],
  userUseCases: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        user: action.payload.user,
        sipParkingIds: action.payload.sipParkingIds,
        userUseCases: action.payload.userUseCases,
      };
    case actionTypes.LOG_OUT:
      return {
        ...state,
        user: null,
      };
    case actionTypes.ADD_SIP_PARKING_TO_USER_SUCCESS:
      return {
        ...state,
        sipParkingIds: action.payload.sipParkingIds,
      };
    case actionTypes.REMOVE_SIP_PARKING_TO_USER_SUCCESS:
      return {
        ...state,
        sipParkingIds: action.payload.sipParkingIds,
      };
    default:
      return state;
  }
};
