import axios from 'axios';
import types from '../redux/actionTypes';
import store from '../redux/store';

import { checkKeycloakAuthentication } from './keycloak.service';

const USER_TOKEN_KEY = 'kc_token';
const baseURL = process.env.REACT_APP_API;

const http = axios.create({ baseURL: `${baseURL}/` });

function get(url, headers = {}, params = {}) {
  checkKeycloakAuthentication();

  const accessToken = localStorage.getItem(USER_TOKEN_KEY);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.get(url, {
    ...params,
    headers: { ...authHeader, ...headers },
  })
  .then((data) => {
    // store.dispatch({
    //   type: types.API_SUCCESS,
    //   payload: {apiName: url, method: 'GET'},
    // });
    return data;
  })
  .catch((error) => {
    store.dispatch({
      type: types.API_ERROR,
      payload: {apiName: url, error: error, method: 'GET'},
    });
    return error;
  });
}

function post(url, data, headers = {}, params = {}) {
  checkKeycloakAuthentication();

  const accessToken = localStorage.getItem(USER_TOKEN_KEY);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.post(url, data, {
    ...params,
    headers: { ...authHeader, ...headers },
  })
  .then((data) => {
    store.dispatch({
      type: types.API_SUCCESS,
      payload: {apiName: url, method: 'POST'},
    });
    return data;
  })
  .catch((error) => {
    store.dispatch({
      type: types.API_ERROR,
      payload: {apiName: url, error: error, method: 'POST'},
    });
    return error;
  });
}

function put(url, data, headers = {}) {
  checkKeycloakAuthentication();

  const accessToken = localStorage.getItem(USER_TOKEN_KEY);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.put(url, data, { 
    headers: { ...authHeader, ...headers } 
  }) .then((data) => {
    store.dispatch({
      type: types.API_SUCCESS,
      payload: {apiName: url, method: 'PUT'},
    });
    return data;
  })
  .catch((error) => {
    store.dispatch({
      type: types.API_ERROR,
      payload: {apiName: url, error: error, method: 'PUT'},
    });
    return error;
  });
}

function remove(url, data, headers = {}) {
  checkKeycloakAuthentication();

  const accessToken = localStorage.getItem(USER_TOKEN_KEY);
  const authHeader = { Authorization: `Bearer ${accessToken}` };
  return http.delete(url, {
    headers: { ...authHeader, ...headers },
    data,
  })
  .then((data) => {
    store.dispatch({
      type: types.API_SUCCESS,
      payload: {apiName: url, method: 'DELETE'},
    });
    return data;
  })
  .catch((error) => {
    store.dispatch({
      type: types.API_ERROR,
      payload: {apiName: url, error: error, method: 'DELETE'},
    });
    return error;
  });  
}

export default {
  http,
  get,
  post,
  put,
  remove,
};
