import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';

import {CustomTable} from 'components/elements';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionButton} from "../../common";
import i18next from "i18next";
import moment from "moment";
import {getFiscalizedReceipts, sendToEET} from "../../../redux/actions/receipts";
import PMCSnackbar from "../../common/Snackbar";
import {getErrorMessage} from "../../../utils";


const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        background: theme.palette.base[200],
        height: '100%',
        width: '100%',
    },
    actions: {
        padding: 0,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '75px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '50px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '100%',
        justifyContent: 'left',
    },
    button: {
        width: 200,
        marginLeft: 20,
        marginRight: 20,
    },
    buttonReceiptActions: {
        width: 30,
    },
    table: {
        height: '90%',
        width: '100%',
    },
}));


const cellStyle = {
    padding: '4px 4px',
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

const ReceiptsFiscalizedList = ({
                                    open, handleClose, getFiscalizedReceipts,
                                    fiscalizedReceipts, isFetchingList, userUseCases, sendToEET,
                                }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorAction, setErrorAction] = useState('');
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const resetPagination = () => {
        setPage(0);
    };

    const handleChangeRowsPerPage = (value) => {
        resetPagination();
        setSize(value);
        getFiscalizedReceipts(0, value, localStorage.getItem('pos_id'));
    };
    const handleChangePage = (value) => {
        setPage(value);
        getFiscalizedReceipts(value, size, localStorage.getItem('pos_id'));
    };


    useEffect(() => {
        if (!!open) {
            getFiscalizedReceipts(0, 10, localStorage.getItem('pos_id'));

        }
    }, [open]);


    let columns = [
        {
            title: t('RECEIPT_NUMBER'),
            field: 'receiptId',
            cellStyle,
            render: (rowData) => (
                rowData.receiptId
            ),
        },
        {
            title: t('CREATION_DATE'), field: 'owner', cellStyle, render: (rowData) => (
                <>
                    {rowData.date
                        ? moment(new Date(rowData.date)).locale(i18next.language).format('L LTS')
                        : ''}
                </>
            ),
        },
        {
            title: t('POS_IDENT'),
            field: 'pos',
            cellStyle,
            render: (rowData) => (
                rowData.pos
            ),
        },
        {
            title: t('CREATED_BY'),
            field: 'createdBy',
            cellStyle,
            render: (rowData) => (
                rowData.createdBy
            ),
        },
        {
            title: t('RECEIPT_PLACE'),
            field: 'branchName',
            cellStyle,
            render: (rowData) => (
                rowData.branchName
            ),
        },
        {
            title: t('TOTAL_AMOUNT'),
            field: 'priceWithVAT',
            cellStyle,
            render: (rowData) => (
                rowData.priceWithVAT
            ),
        },
        {
            title: t('PAYMENT_TYPE'),
            field: 'paymentType',
            cellStyle,
            render: (rowData) => (
                t(rowData.paymentType)
            ),
        },
        {
            title: t('FISCALIZED'),
            field: 'fik',
            cellStyle,
            render: (rowData) => (
                rowData.fik
            ),
        },
        {
            title: t('STATE'),
            field: 'state',
            cellStyle,
            render: (rowData) => (
                t(rowData.state)
            ),
        },
    ];

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.container}
        >
            <DialogTitle className={classes.title}>
              <span>
                    {t('RECEIPTS_NON_FISCALIZED')}
              </span>
            </DialogTitle>

            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={errorAction}/>

            <DialogContent className={classes.content}>
                {/* Filter */}
                <Grid container spacing={2} style={{flex: 1, height: '100%'}}>
                    <Grid item md={12}>
                        <Paper className={classes.table}>
                            <Box p={3} height={1}>
                                <CustomTable
                                    title=""
                                    columns={columns}
                                    data={fiscalizedReceipts.receipts}
                                    options={{
                                        headerStyle: {
                                            padding: '0 8px 0 34px',
                                            borderTop: '1px solid #DEE2E5',
                                            lineHeight: '12px',
                                            color: '#1B1B28',
                                            textAlign: 'center',
                                        },
                                        rowStyle: rowData => ({
                                            backgroundColor: '#FFF'
                                        })
                                    }}
                                    isLoading={isFetchingList}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handleChangePage={handleChangePage}
                                    rowsPerPage={size}
                                    pageNumber={page}
                                    count={fiscalizedReceipts.paging ? fiscalizedReceipts.paging.totalSize : 0}
                                    loadAllData
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>

                    <ActionButton
                        action="close"
                        handleClick={handleClose}
                        className={classes.button}
                    />
                </div>
                <div className={classes.buttonNavigation}>
                    <ActionButton
                        action="eet"
                        handleClick={() => {

                            sendToEET(localStorage.getItem('pos_id')).then(() => {
                                    handleClose();
                                }
                            ).catch((error) => {
                                setSnackbarOpen(true);
                                setErrorAction(getErrorMessage(error, t));
                            });

                        }}
                        className={classes.button}
                    />
                </div>

            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (store) => ({
    fiscalizedReceipts: store.receiptsData.fiscalizedReceipts,
    isFetchingList: store.receiptsData.isFetchingList,
});

const UC_DUPLICATE_RECEIPT = 'UC0514';
const UC_CANCEL_RECEIPT = 'UC0513';

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getFiscalizedReceipts,
    sendToEET,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReceiptsFiscalizedList);
