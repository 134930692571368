import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingAboutList: false,
  aboutList: [],
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARKING_ABOUT_FETCH_REQUEST:
      return {
        ...state,
        isFetchingAboutList: true,
      };
    case types.PARKING_ABOUT_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAboutList: false,
        aboutList: action.payload.aboutList,
      };
    case types.PARKING_ABOUT_FETCH_FAIL:
      return {
        ...state,
        isFetchingAboutList: false,
        errors: action.payload.error,
      };


    default:
      return state;
  }
};
