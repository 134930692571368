import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import debounce from 'lodash.debounce';

import {getCardDetail, fetchParkingCardGroups, updateCard} from 'redux/actions/cards';
import {fetchSubjectOwners, fetchSubjectSearchOwners} from 'redux/actions/owners';
import {fetchParkingAccesses} from 'redux/actions/parkings';
import {CustomTabs, Loading} from 'components/elements';
import ParkingStatus from './ParkingStatus';
import CardParkingDetail from './CardParkingDetail';
import ParkingSelect from './ParkingSelect';
import CardOwner from './CardOwner';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            height: 855,
            maxWidth: 'unset',
            boxShadow: theme.palette.shadow.main,
        },
    },
    title: {
        height: '15%',
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    shortTermTitle: {
        background: theme.palette.primary.main,
    },
    formGroup: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
            marginBottom: 0,
        },

        '& .MuiFormControl-root': {
            width: 225,
        },
    },
    label: {
        width: theme.spacing(12),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    content: {
        overflow: 'hidden',
        padding: 0,

        '& > div': {
            height: '100%',
            paddingBottom: theme.spacing(10),
        },

        '& > div > .MuiTypography-root': {
            height: 'calc(100% - 60px)',
            overflowY: 'auto',
            overflowX: 'hidden',
        },

        '& > div > .MuiTypography-root > div': {
            flex: 1,
            height: '100%',
        },

        '& .MuiTabs-root': {
            height: 45,
            alignItems: 'center',

            '& .MuiTabs-indicator': {
                top: theme.spacing(6),
            },
        },
    },
    addParking: {
        display: 'flex',
        alignItems: 'center',
        pointerEvents: 'all',

        '& svg': {
            width: 24,
            height: 24,
            fill: theme.palette.base[300],
            marginTop: 0,
        },
    },
    parkingSymbol: {
        width: 22,
        height: 22,
        borderRadius: 2,
        background: theme.palette.base[300],
    },
    error: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 40,
        color: theme.palette.primary.red,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.base.white,
    },
}));

function CardDetails(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        user,
        parkingId,
        opened,
        card,
        parkingCards,
        parkingAccesses,
        cardDetail,
        parkingCardGroups,
        isUpdating,
        isBlocking,
        isActivating,
        subjectOwners,
        subjectSearchOwners,
        parkings,
        handleClose,
        fetchParkingAccesses,
        getCardDetail,
        fetchParkingCardGroups,
        fetchSubjectOwners,
        fetchSubjectSearchOwners,
        updateCard,
        handleBlockCardOpen,
        handleConfirmCardOpen,
        userUseCases,
        productMode,
    } = props;

    const [tab, setTab] = useState(0);

    let selectedCard = null;
    if (cardDetail && cardDetail.parkings) {
        let p = cardDetail.parkings.find((parking) => parking.parkingId === card.parkingId);
        if ( !p && card.parkingId===0 && cardDetail && cardDetail.parkings[0] ) {
            p = cardDetail.parkings[0];
        }
        if (p) {
            selectedCard = p.zones[0];
        }
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorLocal, setErrorLocal] = useState(null);

    const [werror, setWerror] = useState({});


    const [currentCardDetail, setCurrentCardDetail] = useState(null);
    const fetchCardDetail = useCallback(() => {
        if (
            card
            && card.cardNo
            && user
            && user.subjectId
        ) {
            setLoading(true);

            const actions = [
                getCardDetail(card.cardNo),
                // fetchSubjectOwners(user.attributes.subject_id[0], '', 9999),
                // fetchSubjectSearchOwners(''),
                fetchParkingCardGroups(card.parkingId),
            ];

            Promise.all(actions)
                .then(() => {
                    setErrorLocal(null);
                })
                .catch((err) => {
                    if ( err.status===404) {
                        setErrorLocal(err);
                    } else {
                        setErrorLocal(null);
                    }
                    setError(err);
                    setLoading(false);
                });
        }
    }, [card, fetchParkingAccesses, fetchParkingCardGroups, fetchSubjectOwners, getCardDetail, user]);

    useEffect(() => {
        fetchCardDetail();
    }, [fetchCardDetail]);

    useEffect(() => {
        if (cardDetail) {
            setCurrentCardDetail(cardDetail);

            if (loading) {
                Promise.all([fetchParkingAccesses(card.parkingId, cardDetail.cardType)])
                    .then(() => {
                        setLoading(false);
                        setError(null);
                    })
                    .catch((err) => {
                        console.error(err);
                        setError(err);
                        setLoading(false);
                    });
            }
        }
    }, [cardDetail]);

    const defaultCardOwner = subjectOwners.find((owner) => owner.ownerId === card.ownerId) || null;
    const [cardOwner, setCardOwner] = useState(defaultCardOwner);

    const handleOwnerSelect = (value) => {
        const ownerId = parseInt(value.replace('owner_', ''), 10);
        const selectedOwner = subjectSearchOwners.find((owner) => owner.ownerId === ownerId);
        setCardOwner(selectedOwner);

        const newCardDetail = {
            ...currentCardDetail,
            parkings: currentCardDetail.parkings.map((parking) => ({
                ...parking,
                zones: parking.zones.map((zone) => ({
                    ...zone,
                    ownerId: selectedOwner.ownerId || null,
                    owner: `${selectedOwner.firstname} ${selectedOwner.surname}`,
                })),
            })),
        };

        setCurrentCardDetail(newCardDetail);
    };

    const handleOwnerSelectTab = (ownerId) => {
        const selectedOwner = subjectSearchOwners.find((owner) => owner.ownerId == ownerId);
        setCardOwner(selectedOwner);

        const newCardDetail = {
            ...currentCardDetail,
            parkings: currentCardDetail.parkings.map((parking) => ({
                ...parking,
                zones: parking.zones.map((zone) => ({
                    ...zone,
                    ownerId: selectedOwner.ownerId || null,
                    owner: `${selectedOwner.firstname} ${selectedOwner.surname}`,
                })),
            })),
        };

        setCurrentCardDetail(newCardDetail);
    };

    const handleOwnerSelectObject = (owner) => {
        setCardOwner(owner);

        const newCardDetail = {
            ...currentCardDetail,
            parkings: currentCardDetail.parkings.map((parking) => ({
                ...parking,
                zones: parking.zones.map((zone) => ({
                    ...zone,
                    ownerId: owner.ownerId || null,
                    owner: `${owner.firstname} ${owner.surname}`,
                })),
            })),
        };

        setCurrentCardDetail(newCardDetail);
    };


    const getCardParkings = (field) => {
        if (!currentCardDetail || !currentCardDetail.parkings) {
            return [];
        }

        const parkingIds = [];
        currentCardDetail.parkings.forEach((parking) => {
            parkingIds.push(parking.parkingId);
        });

        switch (field) {
            case 'current':
                return parkings.filter((parking) => parkingIds.indexOf(parking.parkingId) > -1);
            case 'other':
                return parkings.filter((parking) => parkingIds.indexOf(parking.parkingId) === -1);
            case 'detail':
                return currentCardDetail.parkings;
            default:
                return [];
        }
    };

    const cardParkings = getCardParkings('current');
    const otherParkings = getCardParkings('other');
    let detailParkings = getCardParkings('detail');

    const selectedParking = detailParkings.find((p) => p.parkingId === parkingId);
    if (selectedParking) {
        detailParkings = [
            selectedParking,
            ...detailParkings.filter((p) => p.parkingId !== parkingId),
        ];
    }

    const newParkings = otherParkings.length > 0 ? otherParkings.map((parking) => ({
        id: parking.parkingId,
        name: parking.parkingName,
        value: `new_parking_${parking.parkingId}`,
        key: `new_parking_${parking.parkingId}`,
    })) : [];

    const fetchData = debounce((newTab) => {
        if (detailParkings.length > newTab) {
            Promise.all([
                fetchParkingAccesses(detailParkings[newTab].parkingId, card.type? card.type: cardDetail.cardType),
                fetchParkingCardGroups(detailParkings[newTab].parkingId),
            ])
                .then(() => {
                })
                .catch(() => {
                });
        }
    }, 500);

    const handleChangeTab = (newTab) => {
        setTab(newTab);
        fetchData(newTab);
    };

    const getNewParking = (value) => {
        const parkingId = newParkings.find((parking) => parking.value === value).id;
        return parkings.find((parking) => parking.parkingId === parkingId);
    };

    const handleAddNewParking = (cardParking) => {
        const selectedAccess = detailParkings[0].zones[0] && parkingAccesses.find(
            (t) => t.parkingAcccesId === detailParkings[0].zones[0].parkingAccessId,
        );
        const sParking = parkings.find((parking) => parking.parkingId === getNewParking(cardParking).parkingId);
        const newZones = detailParkings[0].zones
            .map((zone) => ({
                ...zone,
                cardZoneId: null,
                parkingId: sParking.parkingId,
                parkingName: sParking.parkingName,
                ownerId: cardOwner ? cardOwner.ownerId : null,
                owner: cardOwner ? `${cardOwner.firstname} ${cardOwner.surname}` : null,
                zoneId: null,
                zoneName: null,
                startZoneId: null,
                parkingAccessId: null,
                prevParkingAccessName: selectedAccess ? selectedAccess.parkingAccessName : null,
                cardGroups: [],
                blockingReason: null,
                note: null,
                blocked: false,
                valid: true,

            }));
        const newParkings = [
            ...detailParkings,
            {
                parkingId: sParking.parkingId,
                parkingName: sParking.parkingName,
                zones: newZones,
            },
        ];
        setCurrentCardDetail({
            ...currentCardDetail,
            parkings: newParkings,
        });
    };

    const handleRemoveNewParking = (cardParking) => () => {
        const removeIndex = detailParkings.findIndex((p) => p.parkingId === cardParking.parkingId);
        if (removeIndex > -1) {
            const newParkings = detailParkings.filter((p) => p.parkingId !== cardParking.parkingId);
            setCurrentCardDetail({
                ...currentCardDetail,
                parkings: newParkings,
            });

            if (removeIndex === tab) {
                setTimeout(() => {
                    handleChangeTab(Math.max(removeIndex - 1, 0));
                }, 100);
            }
        }
    };

    const handleUpdateCard = (cardDetail) => {
        if (isUpdating) {
            return;
        }

        updateCard(cardDetail, parkingCards[0] && parkingCards[0].parkingId)
            .then(handleClose);
    };

    const handleActivateCard = (valid) => () => {
        if (isActivating) {
            return;
        }

        handleConfirmCardOpen(
            {...selectedCard, parkingId: detailParkings[tab].parkingId},
            !valid ? 'activate' : 'deactivate',
        );
    };

    const handleBlockCard = (blocked) => () => {
        if (isBlocking) {
            return;
        }

        if (blocked) {
            handleConfirmCardOpen(
                {...selectedCard, parkingId: detailParkings[tab].parkingId},
                'unblock',
            );
        } else {
            handleBlockCardOpen({
                ...selectedCard,
                parkingId: detailParkings[tab].parkingId,
                cardNo: selectedCard.cardNumber,
            });
        }
    };

    const handleChangeCard = (card) => {
        setCurrentCardDetail(card);
    };

    const tabToolbar = [
        <div className={classes.addParking} key="tab_toolbar">
            <ParkingSelect
                items={newParkings}
                placeholder={t('ADD_TO_NEW_PARKING')}
                parkings={parkings}
                handleChange={handleAddNewParking}
            />
        </div>,
    ];

    const isLT = useCallback(() => {
        if (selectedCard) {
            return selectedCard.type === 'LT';
        }
        return card.type === 'LT';
    }, [card, selectedCard]);

    const [mode, setMode] = useState('COMMON');

    const currentParkings = (cardDetail && cardDetail.parkings) || [];
    const tabs = detailParkings.map((parking, index) => (
        <ParkingStatus
            isNew={currentParkings.findIndex((p) => p.parkingId === parking.parkingId) === -1}
            detailParking={parking}
            detailParkings={detailParkings}
            cardParkings={cardParkings}
            tabIndex={index}
            handleRemove={handleRemoveNewParking}
        />
    ));

    const tabContents = detailParkings.length > 0
        ? detailParkings.map((parking) => (
            <CardParkingDetail
                user={user}
                error={werror}
                setError={setWerror}
                detailParkings={detailParkings}
                handleChangeTab={handleChangeTab}
                userUseCases={userUseCases}
                isLT={isLT()}
                card={card}
                cardDetail={currentCardDetail}
                selectedCard={selectedCard}
                cardParkings={cardParkings}
                detailParking={parking}
                parkingAccesses={parkingAccesses}
                parkingCardGroups={parkingCardGroups}
                handleClose={handleClose}
                handleChangeCard={handleChangeCard}
                handleActivateCard={handleActivateCard}
                handleBlockCard={handleBlockCard}
                updateCard={handleUpdateCard}
                mode={mode}
                setMode={setMode}
                handleOwnerSelectTab={handleOwnerSelectTab}
                handleOwnerSelectObject={handleOwnerSelectObject}
            />
        ))
        : [
            <Loading/>,
        ];

    return (
        productMode ?
            (loading || detailParkings.length < 1 ? (
                <Loading/>
            ) : (
                <CardParkingDetail
                    error={werror}
                    setError={setWerror}
                    detailParkings={detailParkings}
                    handleChangeTab={handleChangeTab}
                    userUseCases={userUseCases}
                    isLT={isLT()}
                    card={card}
                    cardDetail={currentCardDetail}
                    selectedCard={selectedCard}
                    cardParkings={cardParkings}
                    detailParking={detailParkings[0]}
                    parkingAccesses={parkingAccesses}
                    parkingCardGroups={parkingCardGroups}
                    handleClose={handleClose}
                    handleChangeCard={handleChangeCard}
                    handleActivateCard={handleActivateCard}
                    handleBlockCard={handleBlockCard}
                    handleOwnerSelectTab={handleOwnerSelectTab}
                    handleOwnerSelectObject={handleOwnerSelectObject}
                    updateCard={handleUpdateCard}
                    productMode={productMode}
                    subjectOwners={subjectOwners}
                    subjectSearchOwners={subjectSearchOwners}
                    fetchSubjectSearchOwners={fetchSubjectSearchOwners}
                    mode={mode}
                    setMode={setMode}
                />

            ))
            :
            <Dialog
                className={classes.container}
                open={opened}
                onClose={handleClose}
                disableEnforceFocus
            >
                <DialogTitle
                    className={
                        classnames(classes.title, !isLT() && classes.shortTermTitle)
                    }
                >
        <span>
          {isLT()
              ? `${t('LONG_TERM_CARD_DETAIL')}: ${card.cardNo}`
              : `${t('SHORT_TERM_CARD_DETAIL')}: ${card.cardNo}`}
        </span>

                    {isLT() && (
                        <CardOwner
                            userUseCases={userUseCases}
                            selectedParking={selectedParking}
                            subjectOwners={subjectOwners}
                            subjectSearchOwners={subjectSearchOwners}
                            fetchSubjectSearchOwners={fetchSubjectSearchOwners}
                            handleMenuItemClick={handleOwnerSelect}
                            setMode={setMode}
                        />
                    )}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>

                <DialogContent className={classes.content}>
                    {errorLocal ? (
                        <div className={classes.error}>{t('CARD_NOT_FOUND')}</div>
                    ) : (loading ? (
                        <CustomTabs
                            tabs={[]}
                            tabContents={[<Loading/>]}
                            activeTab={tab}
                            toolbar={isLT() ? tabToolbar : null}
                        />
                    ) : (
                        <CustomTabs
                            activeTab={tab}
                            tabs={mode==='COMMON' ? tabs : []}
                            tabContents={tabContents}
                            toolbar={isLT() && mode==='COMMON' ? tabToolbar : null}
                            handleChangeTab={handleChangeTab}
                        />
                    ))}
                </DialogContent>
            </Dialog>
    );
}

CardDetails.propTypes = {
    user: PropTypes.object.isRequired,
    parkingId: PropTypes.number.isRequired,
    opened: PropTypes.bool.isRequired,
    card: PropTypes.object.isRequired,
    parkingCards: PropTypes.array.isRequired,
    parkingAccesses: PropTypes.array.isRequired,
    cardDetail: PropTypes.object.isRequired,
    parkingCardGroups: PropTypes.array.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isBlocking: PropTypes.bool.isRequired,
    isActivating: PropTypes.bool.isRequired,
    subjectOwners: PropTypes.array.isRequired,
    subjectSearchOwners: PropTypes.array.isRequired,
    parkings: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    fetchParkingAccesses: PropTypes.func.isRequired,
    getCardDetail: PropTypes.func.isRequired,
    fetchParkingCardGroups: PropTypes.func.isRequired,
    fetchSubjectOwners: PropTypes.func.isRequired,
    fetchSubjectSearchOwners: PropTypes.func.isRequired,
    updateCard: PropTypes.func.isRequired,
    handleBlockCardOpen: PropTypes.func.isRequired,
    handleConfirmCardOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    parkingAccesses: store.parkingsData.parkingAccesses,
    cardDetail: store.cardsData.cardDetail,
    parkingCardGroups: store.cardsData.parkingCardGroups,
    isUpdating: store.cardsData.isUpdating,
    isBlocking: store.cardsData.isBlocking,
    isActivating: store.cardsData.isBlocking,
    subjectOwners: store.ownersData.subjectOwners,
    subjectSearchOwners: store.ownersData.subjectSearchOwners,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingAccesses,
    getCardDetail,
    fetchParkingCardGroups,
    fetchSubjectOwners,
    fetchSubjectSearchOwners,
    updateCard,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardDetails);
