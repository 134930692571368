import types from '../actionTypes';

const INITIAL_STATE = {
    isCreating: false,
    isUpdating: false,
    isFetchingProduct: false,
    isInitializingProduct: false,
    isFetchingAllList: false,
    isFetchingAllGroupsList: false,
    isFetchingAllTemplatesList: false,
    isFetchingProductTemplateParamSelect: false,
    products: [],
    productsForCashDesk: [],
    productGroups: [],
    productTemplates: [],
    product: {
        rolesId: [],
        dateFrom: new Date(),
        productId: 0,
        productParam: [],
    },
    executedInitializedProduct: null,
    productTemplateSelectValues: {},
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PRODUCT_INIT_REQUEST:
            return {
                ...state,
                isInitializingProduct: true,
            };
        case types.PRODUCT_INIT_SUCCESS:
            return {
                ...state,
                isInitializingProduct: false,
                executedInitializedProduct: action.payload.product,
            };
        case types.PRODUCT_INIT_FAIL:
            return {
                ...state,
                isInitializingProduct: false,
                errors: action.payload.error,
            };

        case types.PRODUCT_FETCH_REQUEST:
            return {
                ...state,
                isFetchingProduct: true,
            };
        case types.PRODUCT_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingProduct: false,
                product: action.payload.product,
            };
        case types.PRODUCT_FETCH_FAIL:
            return {
                ...state,
                isFetchingProduct: false,
                errors: action.payload.error,
            };
        case types.PRODUCTS_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        case types.PRODUCTS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                products: action.payload.products,
            };
        case types.PRODUCTS_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.CASHDESK_PRODUCTS_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        case types.CASHDESK_PRODUCTS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                productsForCashDesk: action.payload.productsForCashDesk,
            };
        case types.CASHDESK_PRODUCTS_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };

        case types.PRODUCT_GROUPS_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllGroupsList: true,
            };
        case types.PRODUCT_GROUPS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllGroupsList: false,
                productGroups: action.payload.productGroups,
            };
        case types.PRODUCT_GROUPS_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllGroupsList: false,
                errors: action.payload.error,
            };
        case types.PRODUCT_TEMPLATES_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllTemplatesList: true,
            };
        case types.PRODUCT_TEMPLATES_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllTemplatesList: false,
                productTemplates: action.payload.templates,
            };
        case types.PRODUCT_TEMPLATES_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllTemplatesList: false,
                errors: action.payload.error,
            };
        case types.PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_REQUEST:
            return {
                ...state,
                isFetchingProductTemplateParamSelect: true,
            };
        case types.PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingProductTemplateParamSelect: false,
                productTemplateSelectValues: action.payload.productTemplateSelectValues,
            };
        case types.PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_FAIL:
            return {
                ...state,
                isFetchingProductTemplateParamSelect: false,
                errors: action.payload.error,
            };
        default:
            return state;
    }
};
