import httpService from './http.service';

const updateZone = (zone) => httpService
  .post('/zone', zone)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  updateZone,
};
