import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {CustomTabs} from 'components/elements';
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import ProductCategories from './ProductCategories';
import {
    createProduct,
    fetchAllProductGroups,
    fetchAllProducts,
    fetchAllProductTemplates,
    fetchProduct,
    updateProduct
} from 'redux/actions/products';
import ToolBar from './ToolBar';
import Typography from "@material-ui/core/Typography";
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import Product from 'components/modals/Product';
import PerfectScrollbar from "react-perfect-scrollbar";
import PMCSnackbar from 'components/common/Snackbar';


const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));


function ProductAdministration(props) {
    const {
        userUseCases,
        products,
        productGroups,
        productTemplates,
        parkings,
        fetchAllProducts,
        fetchAllProductGroups,
        fetchAllProductTemplates,
        isFetchingAllList,
        fetchProduct,
        createProduct,
        updateProduct,
        product,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();

    const validities = [
        {
            value: 'ACTUAL',
            label: t('ACTIVE'),
            key: 'active',
        },
        {
            value: 'FUTURE',
            label: t('FUTURE'),
            key: 'future',
        },
        {
            value: 'ALL',
            label: t('ALL'),
            key: 'all',
        },
    ];


    const [isFetchedProducts, setIsFetchedProducts] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedValidity, setSelectedValidity] = useState(validities[0].value);
    const [deletionSnackbarOpen, setDeletionSnackbarOpen] = useState(false);
    const [deletionSnackbarError, setDeletionSnackbarError] = useState('');


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDeletionSnackbarOpen(false);
    };


    useEffect(() => {
        if (!isFetchedProducts) {
            setIsLoading(true);
            fetchAllProducts(selectedValidity).then(
                () => {
                    fetchAllProductGroups().then(() => {

                        fetchAllProductTemplates().then(
                            () => {
                                setIsFetchedProducts(true);
                                setIsLoading(false);
                            }
                        ).catch((err) => {
                            setIsFetchedProducts(true);
                            setIsLoading(false);
                        });


                    }).catch((err) => {
                        setIsFetchedProducts(true);
                        setIsLoading(false)
                    });
                }
            ).catch((err) => {
                setIsFetchedProducts(true);
                setIsLoading(false)
            });
        }

    }, [fetchAllProducts, fetchAllProductGroups, fetchAllProductTemplates, isFetchedProducts]);


    const [activeTab, setActiveTab] = useState(0);
    const handleChangeTab = (value) => {
        setActiveTab(value);
    };
    const checkActivated = (value) => {
        if (activeTab === -1) return true;

        return value === activeTab;
    };


    const [tabContents, setTabContents] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');


    useEffect(() => {
        if (productGroups) {
            const tabs1 = [];
            const tabContents1 = [];
            const categories = [t('ALL')];
            for (let i = 0; i < products.length; i++) {
                const category = products[i].productGroupName;
                const alreadyAddess = categories.find(a => a === category);
                if (!alreadyAddess) {
                    categories.push(category);
                }
            }

            for (let j = 0; j < categories.length; j++) {
                tabs1.push(categories[j]);
                tabContents1.push(<ProductCategories
                    refresh={() => {
                        setIsFetchedProducts(false);
                    }}
                    userUseCases={userUseCases}
                    products={filteredProducts}
                    handleProductDetail={handleProductDetail}
                    productCategory={categories[j]}
                    isLoading={isFetchingAllList}
                    setDeletionSnackbarOpen={setDeletionSnackbarOpen}
                    setDeletionSnackbarError={setDeletionSnackbarError}
                />);
            }
            ;
            setTabs(tabs1);
            setTabContents(tabContents1);


        }
    }, [productGroups, filteredProducts, isLoading]);


    const [parkingsValues, setParkingsValues] = useState([]);

    useEffect(() => {
        if (parkings) {
            setParkingsValues(parkings.map(a => {
                return {value: a.parkingId, label: a.parkingName, key: a.parkingId}
            }));
        }
    }, [parkings]);


    const [productDetailOpen, setProductDetailOpen] = useState(false);
//    const [productType, setProductType]  = useState('MP');
    const [productType, setProductType] = useState();


    const handleProductDetail = (id, type) => {
        fetchProduct(id);
        setProductType(type);
        setProductDetailOpen(true);
    };

    const closeProductDetail = () => {
        setProductDetailOpen(false);
    }

    const [productTemplatesValues, setProductTemplatesValues] = useState([]);

    useEffect(() => {
        if (productTemplates) {
            setProductTemplatesValues(productTemplates.map(a => {
                return {value: a.productTemplateId, label: a.productTemplateName, key: a.productTemplateId}
            }));
        }
    }, [productTemplates]);


    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedProductType, setSelectedProductType] = useState([]);
    const [selectedParking, setSelectedParking] = useState([]);
    const [selectedName, setSelectedName] = useState('');


    const handleSelectedBranch = (value) => {
        setSelectedBranch(value.target.value);

    };
    const handleSelectedValidity = (value) => {
        setSelectedValidity(value.target.value);
    };
    const handleSelectedProductType = (value) => {
        setSelectedProductType(value);
    };
    const handleSelectedParking = (value) => {
        setSelectedParking(value);
    };

    const handleSelectedName = (value) => {
        setSelectedName(value.target.value);
    };

    useEffect(() => {
        if (selectedValidity) {
            fetchAllProducts(selectedValidity);
        }
    }, [selectedValidity, fetchAllProducts]);


    useEffect(() => {
        const filProd = [];
        for (let i = 0; i < products.length; i++) {
            let matchCriteria = true;
            if (selectedBranch && selectedBranch.trim().length > 0) {
                if (!(
                    products[i].branchName && products[i].branchName.includes(selectedBranch) ||
                    products[i].branchAddress && products[i].branchAddress.includes(selectedBranch)
                )) {
                    matchCriteria = false;
                }
            }
            if (selectedName && selectedName.trim().length > 0) {
                if (products[i].productSystemParam) {
                    let property = products[i].productSystemParam.find(a => a.paramName === 'name');
                    if (!(property && property['valueVarchar'].includes(selectedName))) {
                        matchCriteria = false;
                    }
                }
            }
            if (selectedProductType && selectedProductType.length > 0) {
                let matchSelectedProductType = false;
                for (let j = 0; j < selectedProductType.length; j++) {
                    if (products[i].productTemplateId == selectedProductType[j].value) {
                        matchSelectedProductType = true;
                    }
                }
                matchCriteria = matchSelectedProductType;
            }
            if (selectedParking && selectedParking.length > 0) {
                let matchSelectedParking = false;
                for (let j = 0; j < selectedParking.length; j++) {
                    if (products[i].parkingId == selectedParking[j].value) {
                        matchSelectedParking = true;
                    }
                }
                matchCriteria = matchSelectedParking;
            }
            if (matchCriteria) {
                filProd.push(products[i]);
            }
        }
        setFilteredProducts(filProd);
    }, [products, selectedBranch, selectedProductType, selectedParking, selectedName]);

    const saveProduct = (product) => {
        const action = product.productId === 0 ? createProduct : updateProduct;
        action(product).then((prod) => {
            setIsLoading(true);
            setIsFetchedProducts(false);
            closeProductDetail();
        }).catch((error) => {
            setSnackbarOpen(true);
            setError(getErrorMessage(error, t));
        });
    };

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >

            <div className={classes.breadcrumb}>
                {isComponentVisibleForUser(UC_PRODUCT_ADMINISTRATION, userUseCases) &&

                <Link style={{textDecoration: 'none'}}
                      to={isComponentEnabledForUser(UC_PRODUCT_ADMINISTRATION, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration` : "#"}>

                    <Typography variant="button" className={classnames(classes.activeLink, classes.navLink)}>
                        {t('ADMINISTRATION')}
                    </Typography>
                </Link>
                }
            </div>

            <Typography variant="h4" className={classes.title}>
                {t('PRODUCT_ADMINISTRATION')}
            </Typography>


            <PMCSnackbar open={deletionSnackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={deletionSnackbarError}/>

            <div className={classes.container}>

              <ToolBar
                    validities={validities}
                    parkings={parkingsValues}
                    productTypes={productTemplatesValues}
                    branches={selectedBranch}
                    selectedBranch={selectedBranch}
                    selectedProductType={selectedProductType}
                    selectedParking={selectedParking}
                    selectedValidity={selectedValidity}
                    selectedName={selectedName}
                    handleSelectBranch={handleSelectedBranch}
                    handleSelectValidity={handleSelectedValidity}
                    handleSelectParking={handleSelectedParking}
                    handleSelectType={handleSelectedProductType}
                    handleSelectedName={handleSelectedName}
                    userUseCases={userUseCases}
                    handleProductDetail={handleProductDetail}
                />

                <CustomTabs
                    tabs={tabs}
                    tabContents={tabContents}
                    variant="fullWidth"
                    handleChangeTab={handleChangeTab}
                />
            </div>


            <Product opened={productDetailOpen} product={product} userUseCases={userUseCases} save={saveProduct}
                     handleClose={closeProductDetail} productType={productType}
                     snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen}
                     error={error}
                     setError={setError}
            />


        </PerfectScrollbar>
    );
}

ProductAdministration.propTypes = {};

const UC_PRODUCT_ADMINISTRATION = 'UC0610';

const mapStateToProps = (store) => ({
    products: store.productsData.products,
    product: store.productsData.product,
    productGroups: store.productsData.productGroups,
    productTemplates: store.productsData.productTemplates,
    parkings: store.parkingsData.parkings,
    isFetchingAllList: store.productsData.isFetchingAllList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllProducts,
    fetchAllProductGroups,
    fetchAllProductTemplates,
    createProduct,
    updateProduct,
    fetchProduct,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductAdministration);
