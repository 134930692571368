import playlistsService from 'services/playlists.service.js';
import types from '../actionTypes';


export const fetchPlayLists = () => (dispatch, getState) => {
    if (getState().playListData.isFetchingPlayList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PLAYLIST_FETCH_REQUEST,
    });

    return playlistsService.getPlayLists()
        .then((playLists) => {
            dispatch({
                type: types.PLAYLIST_FETCH_SUCCESS,
                payload: {playLists},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLAYLIST_FETCH_FAILT,
                payload: {error},
            });

            throw error;
        });
};

export const fetchPlayListDetail = (playListId) => (dispatch, getState) => {
    if (getState().playListData.isFetchingPlayListDetail) {
        return Promise.reject();
    }

    dispatch({
        type: types.PLAYLIST_DETAIL_FETCH_REQUEST,
    });

    return playlistsService.getPlayList(playListId)
        .then((playList) => {
            dispatch({
                type: types.PLAYLIST_DETAIL_FETCH_SUCCESS,
                payload: {playList},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLAYLIST_DETAIL_FETCH_FAILT,
                payload: {error},
            });

            throw error;
        });
};

export const createPlayList = (playList) => (dispatch, getState) => {
    if (getState().playListData.isCreating) {
        return;
    }

    dispatch({
        type: types.PLAYLIST_CREATE_REQUEST,
    });

    return playlistsService.createPlayList(playList)
        .then(() => {
            dispatch({
                type: types.PLAYLIST_CREATE_SUCCESS,
                payload: {playList: playList},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLAYLIST_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updatePlayList = (playList) => (dispatch, getState) => {
    if (getState().playListData.isUpdating) {
        return;
    }

    dispatch({
        type: types.PLAYLIST_UPDATE_REQUEST,
    });

    return playlistsService.updatePlayList(playList)
        .then(() => {
            dispatch({
                type: types.PLAYLIST_UPDATE_SUCCESS,
                payload: {playList: playList},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLAYLIST_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const deletePlayList = (playList) => (dispatch, getState) => {
    if (getState().playListData.isDeleting) {
        return;
    }

    dispatch({
        type: types.PLAYLIST_DELETE_REQUEST,
    });

    return playlistsService.deletePlayList(playList)
        .then(() => {
            dispatch({
                type: types.PLAYLIST_DELETE_SUCCESS,
                payload: {playList: playList},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLAYLIST_DELETE_FAIL,
                payload: {error},
            });

            throw error;
        });
};