import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

const options = {
  stroke: {
    colors: ['transparent'],
  },
  plotOptions: {
    radialBar: {
      startAngle: 0,
      endAngle: 360,
      track: {
        background: '#EEEEEE',
        strokeWidth: '97%',
        shadow: {
          enabled: false,
        },
      },
      hollow: {
        size: '35%',
      },
      dataLabels: {
        name: {
          offsetY: 0,
          show: true,
          fontSize: '18px',
        },
        value: {
          offsetY: -4,
          fontSize: '27px',
        },
      },
    },
  },
  colors: [function({ value, seriesIndex, w }) {
    if (value  >= 80 ) {
      return '#F34734'
    } else if (value >= 50) {
      return '#FDBA45'
    } else {
      return '#50A933'
    }
  }],
  fill: {
    type: 'solid',
  },
  labels: [''],
};

const CustomCircleChart = (props) => {
  const {
    height,
    series,
    label,
  } = props;

  options.labels = [label];
  options.plotOptions.radialBar.dataLabels.name.offsetY = height / 2 - 22;

  return (
    <Chart
      options={options}
      series={series}
      type="radialBar"
      height={height}
    />
  );
};

CustomCircleChart.propTypes = {
  height: PropTypes.number.isRequired,
  series: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomCircleChart;
