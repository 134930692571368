import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { fetchParkingCards, fetchParkingCardNumbers } from 'redux/actions/cards';
import ParkingCardsContent from './Content';

const filterParams = {
  validTill: null,
  validity: [''],
  blocked: [''],
  zone: [''],
  size: 5,
  page: 0,
  cardNumber: 0,
  owner: ''
};

function ParkingCards(props) {
  const {
    cardType,
    parking,
    active,
    parkingLTCards,
    parkingSTCards,
    cardsPaging,
    handleCardDetailOpen,
    handleCreateCardOpen,
    handleEditCardOpen,
    handleBlockCardOpen,
    handleConfirmCardOpen,
    fetchParkingCards,
    fetchParkingCardNumbers,
    userUseCases,
  } = props;


  console.warn("ParkingCards", active, cardType);

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [isFetchedCards, setIsFetchedCards] = useState(false);
  const [isFetchedCardNumbers, setIsFetchedCardNumbers] = useState(false);

  const fetchCards = useCallback((owner, validTill, validity, blocked, zone, size, page, orderedColumnId, orderDirection) => {
    setIsLoading(true);
    const validityStr = validity.map((valid) => {
      if (!valid) {
        return '';
      }

      return valid === 'valid';
    });

    const blockedStr = blocked.map((block) => {
      if (!block) {
        return '';
      }

      return block === 'blocked';
    });

    fetchParkingCards(
      parking.parkingId,
      {
        owner, validTill, validity: validityStr, blocked: blockedStr, zone, cardType, size, page, orderedColumnId, orderDirection
      },
    )
      .then(() => {
        if (!isUnMounted.current) {
          setIsLoading(false);
          setIsFetchedCards(true);
        }
      }).catch(() => {
        if (!isUnMounted.current) {
          setIsLoading(false);
        }
      });
  }, [cardType, fetchParkingCards, parking.parkingId]);

  const fetchCardNumbers = useCallback((cardNumber) => {
    const queryCardNumber = cardNumber.toString().length > 2 ? cardNumber : 0;
    fetchParkingCardNumbers(parking.parkingId, queryCardNumber, cardType)
      .then(() => {
        if (!isUnMounted.current) {
          if (filterParams.cardNumber !== cardNumber) {
            setIsFetchedCardNumbers(false);
          } else {
            setIsFetchedCardNumbers(true);
          }
        }
      })
      .catch(() => {
        if (!isUnMounted.current) {
          setIsFetchedCardNumbers(false);
        }
      });
  }, [cardType, fetchParkingCardNumbers, parking.parkingId]);

  useEffect(() => {
    if (active) {
      setIsFetchedCards(false);
      setIsFetchedCardNumbers(false);
    } else {
      setIsFetchedCards(true);
      setIsFetchedCardNumbers(true);
    }
  }, [active, parking.parkingId, parkingSTCards, parkingLTCards, cardType]);

  const [owner, setOwner] = useState(null);
  const [validTill, setValidTill] = useState(null);
  const [validity, setValidity] = useState(cardType === 'ST' ? ['', 'valid'] : ['']);
  const [blocked, setBlocked] = useState(['']);
  const [zone, setZone] = useState(['']);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(0);
  const [orderId, setOrderId] = useState(null);
  const [orderDirection, setOrderDirection] = useState('asc');

  const resetPagination = () => {
    filterParams.page = 0;
    setPage(0);
  };
  const handleOwner = (event) => {
    setSearchTerm(event.target.value);
  }

  const handleValidTill = (value) => {
    filterParams.validTill = value;
    resetPagination();
    setIsFetchedCards(false);
    setValidTill(value);
  };
  const handleValidity = (value) => {
    let validity = value;
    if (validity.length === 3) {
      validity = [''];
    }
    filterParams.validity = validity;
    resetPagination();
    setIsFetchedCards(false);
    setValidity(validity);
  };
  const handleFilterBlocked = (value) => {
    let blocked = value;
    if (value.length === 3) {
      blocked = [''];
    }
    filterParams.blocked = blocked;
    resetPagination();
    setIsFetchedCards(false);
    setBlocked(blocked);
  };
  const handleSearch = (value) => {
    // console.log('serching value: '+value);
    filterParams.cardNumber = value;
    if (value.toString().length > 2) {
      setIsFetchedCardNumbers(false);
    }
    setSearchKeyword(value);
  };
  const handleZone = (value) => {
    filterParams.zone = value;
    resetPagination();
    setIsFetchedCards(false);
    setZone(value);
  };
  const handleChangeRowsPerPage = (value) => {
    filterParams.size = value;
    resetPagination();
    setIsFetchedCards(false);
    setSize(value);
  };
  const handleChangePage = (value) => {
    filterParams.page = value;
    setIsFetchedCards(false);
    setPage(value);
  };

  const handleOrderChange = (orderedColumnId, orderDirection) => {
    resetPagination();
    setOrderId(orderedColumnId);
    setOrderDirection(orderDirection);
    setIsFetchedCards(false);
  }

  useEffect(() => {
    if (!isFetchedCards && active) {
      fetchCards(owner, validTill, validity, blocked, zone, size, page, orderId, orderDirection);
    }

    if (!active) {
      resetPagination();
    }
  }, [blocked, zone, validTill, validity, size, page, isFetchedCards, active, fetchCards]);

  useEffect(() => {
    if (!isFetchedCardNumbers && active) {
      fetchCardNumbers(searchKeyword);
    }
  }, [active, fetchCardNumbers, isFetchedCardNumbers, searchKeyword, cardType]);

  useEffect(() => {
    if (active) {
      setIsFetchedCards(false);
      setIsFetchedCardNumbers(false);
      setIsLoading(true);
    }
  }, [active]);


  // Our hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Return a cleanup function that will be called every time ...
        // ... useEffect is re-called. useEffect will only be re-called ...
        // ... if value changes (see the inputs array below).
        // This is how we prevent debouncedValue from changing if value is ...
        // ... changed within the delay period. Timeout gets cleared and restarted.
        // To put it in context, if the user is typing within our app's ...
        // ... search box, we don't want the debouncedValue to update until ...
        // ... they've stopped typing for more than 500ms.
        return () => {
          clearTimeout(handler);
        };
      },
      // Only re-call effect if value changes
      // You could also add the "delay" var to inputs array if you ...
      // ... need to be able to change that dynamically.
      [value]
    );

    return debouncedValue;
  }

  // State and setter for search term
  const [searchTerm, setSearchTerm] = useState('');

  // Now we call our hook, passing in the current searchTerm value.
  // The hook will only return the latest value (what we passed in) ...
  // ... if it's been more than 500ms since it was last called.
  // Otherwise, it will return the previous value of searchTerm.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Here's where the API call happens
  // We use useEffect since this is an asynchronous action
  useEffect(
    () => {
      filterParams.owner = debouncedSearchTerm;
      resetPagination();
      setIsFetchedCards(false);
      setOwner(debouncedSearchTerm);
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm]
  );

  return (
    <ParkingCardsContent
      parking={parking}
      cardType={cardType}
      cards={cardType === 'LT' ? parkingLTCards : parkingSTCards}
      isLoading={isLoading}
      validity={validity}
      blocked={blocked}
      validTill={validTill}
      zone={zone}
      searchKeyword={searchKeyword}
      handleCreateCardOpen={handleCreateCardOpen}
      handleCardDetailOpen={handleCardDetailOpen}
      handleEditCardOpen={handleEditCardOpen}
      handleBlockCardOpen={handleBlockCardOpen}
      handleConfirmCardOpen={handleConfirmCardOpen}
      cardsPaging={cardsPaging}
      handleOwner={handleOwner}
      handleValidTill={handleValidTill}
      handleValidity={handleValidity}
      handleFilterBlocked={handleFilterBlocked}
      handleSearch={handleSearch}
      handleZone={handleZone}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      userUseCases={userUseCases}
      handleOrderChange={handleOrderChange}
    />
  );
}

ParkingCards.propTypes = {
  cardType: PropTypes.string.isRequired,
  parking: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  parkingLTCards: PropTypes.array.isRequired,
  parkingSTCards: PropTypes.array.isRequired,
  cardsPaging: PropTypes.object.isRequired,
  handleCardDetailOpen: PropTypes.func.isRequired,
  handleCreateCardOpen: PropTypes.func.isRequired,
  handleEditCardOpen: PropTypes.func.isRequired,
  handleBlockCardOpen: PropTypes.func.isRequired,
  handleConfirmCardOpen: PropTypes.func.isRequired,
  fetchParkingCards: PropTypes.func.isRequired,
  fetchParkingCardNumbers: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  parkingLTCards: store.cardsData.parkingLTCards,
  parkingSTCards: store.cardsData.parkingSTCards,
  cardsPaging: store.cardsData.cardsPaging,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchParkingCards,
  fetchParkingCardNumbers,
}, dispatch);

export default React.memo(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingCards), (o1, o2)=>{
  return o2.active===false
});

