import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Phone from '@material-ui/icons/Phone';

import CreateCommand from 'components/modals/CreateCommand';
import HostActionButton from './HostActionButton';
import {isComponentEnabledForUser, isComponentVisibleForUser, SIP_STATUS} from 'utils';

const useStyles = makeStyles((theme) => ({
    hostManagement: {
        width: '100%',
        height: '100%',
        padding: theme.spacing(3, 0, 3, 3),
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '2px solid',
        borderLeftColor: theme.palette.base[100],
    },
    hostActions: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    hostButton: {
        minWidth: 'calc(50% - 10px)',
        marginBottom: theme.spacing(1),
        '&:last-child': {
            minWidth: '100%',
        },
    },
    actionButton: {
        minHeight: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(1),

        '& button': {
            width: '100%',
            minWidth: 'unset',
        },
    },
}));

function HostCommands(props) {
        const classes = useStyles();

        const [openModal, setOpenModal] = useState(false);
        const [selectedCommand, setSelectedCommand] = useState(null);

        const clickCommandButton = (co) => {
            setSelectedCommand(co);
            setOpenModal(true);
        };

        const handleClose = () => {
            setOpenModal(false);
        };

        const {
            host, createParkingHostCommand, sipCall, userUseCases, sipStatus,
        } = props;

        return (
            <div className={classes.hostManagement} /*onKeyUpCapture={(event) => {
                console.info("div capture",openModal );
                !openModal && event.stopPropagation();

            }}*/>
                <div className={classes.actionButton}>
                    {host && isComponentVisibleForUser(UC_HOST_CALL, userUseCases) && (
                        <HostActionButton
                            key="command_call"
                            disabled={!host.sipPhoneNumber || !isComponentEnabledForUser(UC_HOST_CALL, userUseCases) || sipStatus!==SIP_STATUS.CONNECTED}
                            action="callIntercom"
                            icon={[<Phone/>]}
                            handleClick={() => {
                                sipCall('call-audio', host.sipPhoneNumber);
                            }}
                        />
                    )}
                </div>

                <div className={classes.hostActions}>
                    {host && host.commands && host.commands.map((co, index) => {
                        return isComponentVisibleForUser(`${UC_HOST_COMMAND}${co.commandName}`, userUseCases) ?
                            <HostActionButton
                                key={`command_${index}`}
                                className={classes.hostButton}
                                disabled={!isComponentEnabledForUser(`${UC_HOST_COMMAND}${co.commandName}`, userUseCases) || host.status === 'STATUS_RED'}
                                action={co.commandName}
                                handleClick={(event) => {
                                    if (event.screenX && event.screenY && event.screenY !== 0 && event.screenX !== 0) {
                                        clickCommandButton(co);
                                    }
                                }}
                            /> : null

                    })}
                </div>

                {openModal && (
                    <CreateCommand
                        opened={openModal}
                        command={selectedCommand}
                        host={host}
                        createParkingHostCommand={createParkingHostCommand}
                        handleClose={handleClose}
                    />
                )}
            </div>
        );
}

HostCommands.propTypes = {
    host: PropTypes.object,
    createParkingHostCommand: PropTypes.func,
};

HostCommands.defaultProps = {
    host: {commands: []},
    createParkingHostCommand: null,
};

const UC_HOST_CALL = 'UC0010';
const UC_HOST_COMMAND = 'UC0011_';

export default HostCommands;
