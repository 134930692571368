import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { TextField } from '@material-ui/core';
import {ActionButton} from "../../components/common";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        paddingLeft: '20px',
        paddingRight: '20px',
        backgroundColor: 'white',
    },
    searchButton: {
        color: theme.palette.base.white,
        width: '100%',
        minWidth: '100%',
        height: '55px',
        fontSize: 25,

    }
}));

const ToolBar = (props) => {
    const {
        handleFilter,
        setValue,
        value,
        isGettingDetail,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [sarchString, setSearchString] = useState({});

    const handleChange = (field) => (change) => {
        let tmp = {...value, [field]: change.target.value};
        setSearchString(tmp);
    };


    const onSearch = ()=> {
        setValue(sarchString);
        handleFilter(sarchString);
    }


    return(
        <div className={classes.toolbar}>
            <Grid container spacing={3}  justifyContent="space-around"   alignItems="center"
            >
                <Grid item xs={9} md={5}>
                    <TextField value={sarchString.cardNumber} label={t('TYPE_CARD_NUMBER')} onChange={handleChange('cardNumber')} fullWidth />
                </Grid>
                <Grid item xs={3} md={1}>
                    <ActionButton disabled={isGettingDetail} action='search' className={classes.searchButton} handleClick={onSearch}/>
                </Grid>
            </Grid>
        </div>
    );
}

export default ToolBar;