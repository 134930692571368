import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Add from '@material-ui/icons/Add';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';

import { CustomMultiSelect, CustomSearchInput } from 'components/elements';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: theme.spacing(4.5),
  },
  filter: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      width: 150,
      marginLeft: theme.spacing(4),
    },

    '& > div:first-child': {
      marginLeft: 0,
    },
  },
  toolbarInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  addButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    backgroundColor: theme.palette.base[100],
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1.125),
  },
  addIcon: {
    width: 30,
    height: 30,
  },
}));

function TasksToolbar(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    taskHosts,
    state,
    selectedHosts,
    createdByName,
    users,
    handleState,
    handleSelectHosts,
    handleUser,
    handleSearch,
    handleAddTask,
    userUseCases,
  } = props;

  const states = [
    {
      value: 'CR',
      label: t('CREATED'),
      key: 'CR',
    },
    {
      value: 'AS',
      label: t('ASSIGNED'),
      key: 'AS',
    },
    {
      value: 'CO',
      label: t('COMPLETED'),
      key: 'CO',
    },
    {
      value: 'CA',
      label: t('CANCELED'),
      key: 'CA',
    },
  ];

  return (
    <div className={classes.toolbar}>
      <div className={classes.filter}>
        <CustomMultiSelect
          items={states}
          label={t('STATE')}
          value={state}
          handleChange={handleState}
        />
        <CustomMultiSelect
          items={taskHosts}
          label={t('HOST')}
          value={selectedHosts}
          handleChange={handleSelectHosts}
        />
        <CustomMultiSelect
          items={users}
          label={t('USER')}
          value={createdByName}
          handleChange={handleUser}
        />
      </div>

      <div className={classes.toolbarInput}>
        <CustomSearchInput
          placeholder={t('USER')}
          handleChange={handleSearch}
        />
        <div className={classes.addButtonWrapper}>
          {isComponentVisibleForUser(UC_TASK_CREATE, userUseCases) && <IconButton
              className={classes.addButton}
              edge="start"
              color="inherit"
              aria-label="add task"
              onClick={handleAddTask}
              disabled={!isComponentEnabledForUser(UC_TASK_CREATE, userUseCases)}
          >
            <Add className={classes.addIcon}/>
          </IconButton>
          }
        </div>
      </div>
    </div>
  );
}

TasksToolbar.propTypes = {
  taskHosts: PropTypes.array.isRequired,
  state: PropTypes.array.isRequired,
  selectedHosts: PropTypes.array.isRequired,
  createdByName: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  handleState: PropTypes.func.isRequired,
  handleSelectHosts: PropTypes.func.isRequired,
  handleUser: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleAddTask: PropTypes.func.isRequired,
};

const UC_TASK_CREATE = 'UC0038';


export default TasksToolbar;
