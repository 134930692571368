import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {useTranslation} from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";
import CustomCheckbox from "./CustomCheckbox";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    separator: {
        width: '10px',
    },

}));


function DateStep(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    let paramRefs = [];

    const steps = [
        {
            value: '',
            label: '',
            key: '',
        },
        {
            value: 'm',
            label: t('MINUTE'),
            key: 'minute',
        },
        {
            value: 'H',
            label: t('HOUR'),
            key: 'hour',
        },
        {
            value: 'D',
            label: t('DAY'),
            key: 'day',
        },
        {
            value: 'Y',
            label: t('YEAR'),
            key: 'year',
        },
        {
            value: 'M',
            label: t('MONTH'),
            key: 'month',
        },
    ];

    const {
        value,
        handleChange,
        disabled,
        label,
        helperText,
        required,
        onKeyPressHandler,
        inputRef,
        handleStepperChange,
        showMode,
        stepper,
    } = props;


    const [count, setCount] = useState();
    const [step, setStep] = useState();


    useEffect(() => {
            if (value) {
                const stepCount = value.split(" ");
                if (stepCount.length === 1) {
                    if (Number.isInteger(value)) {
                        setCount(value);
                    } else {
                        setStep(value);
                    }

                } else if (stepCount.length === 2) {
                    setCount(stepCount[0]);
                    setStep(stepCount[1]);
                }
            }
        }, [value]
    );


    return (
        <div className={classes.container}>
            <TextField
                type="number"
                disabled={disabled}
                fullWidth
                inputRef={el => {
                    paramRefs[0] = el;
                    inputRef && inputRef(el);
                }}
                required={required}
                label={`${t(label)} ${t('COUNT')}`}
                value={count || ''}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        if (paramRefs[1]) {
                            paramRefs[1].focus();
                        }
                    }
                }}
                onChange={(e) => {
                    setCount(e.target.value);
                    handleChange && handleChange(`${e.target.value} ${step ? step : ''}`);
                }}
                helperText={(!count) ? helperText : ''}
            />
            <div className={classes.separator}></div>
            <FormControl required={required} fullWidth
            >
                <InputLabel>
                    {`${t(label)} ${t('STEP')}`}
                </InputLabel>
                <Select
                    native
                    disabled={disabled}
                    inputRef={el => {
                        paramRefs[1] = el;
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            onKeyPressHandler && onKeyPressHandler(event);
                            event.stopPropagation();
                        }
                    }}
                    autoWidth
                    value={step || ''}
                    onChange={(e) => {
                        setStep(e.target.value);
                        handleChange && handleChange(`${count ? count:''} ${e.target.value}`);

                    }}

                >
                    {steps.map(a => {
                        return (
                            <option value={a.value}>{a.label}</option>
                        )
                    })
                    }
                </Select>

                <FormHelperText>  {!step || step.length === 0 ?
                    helperText : ''
                }</FormHelperText>
            </FormControl>

            {showMode && <>
                <div className={classes.separator}></div>
                <CustomCheckbox
                    label={t('STEPPER_ON_EXECUTION')}
                    checked={stepper}
                    handleChange={handleStepperChange}
                />
            </>}
        </div>
    );


}

DateStep.propTypes = {};

DateStep.defaultProps = {};

export default DateStep;
