import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {ActionButton} from "../common";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
    
    content: {
        display: 'flex',
        flexDirection: props => props.flexDirection,
        justifyContent: 'space-evenly',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.base.white,
    },
    title: {
        background: props => props.headerColor,
        paddingRight: theme.spacing(2),
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
            '& .MuiButtonBase-root': {
                color: theme.palette.base.white,
            },
            '& input + fieldset': {
                borderColor: theme.palette.base.white
            },
        },
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    dialogPaper: {
        height: props => `${props.height}px`,
    },
}));

const PMCDialog = ({
                       open,
                       title,
                       onClose,
                       onSubmit,
                       size,
                       height,
                       headerColor,
                       children,
                       actionType,
                       flexDirection,
                   }) => {
    const classes = useStyles({headerColor, height, flexDirection});
    const {t} = useTranslation();

    return (
        <Dialog
            disableEnforceFocus
            maxWidth={size}
            open={open}
            onClose={onClose}
            classes={{paper: classes.dialogPaper}}
            onKeyUp={(e) => {
                if (open) {
                    const ENTER = 13;
                    console.warn("submittting");
                    if (e.keyCode === ENTER && !e.shiftKey) {
                        onSubmit && onSubmit();
                    }
                }
            }}
        >
            <DialogTitle className={classes.title}>
                <Typography variant="h2">{title}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>

            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <ActionButton
                    action="closeModal"
                    handleClick={onClose}
                />
                {onSubmit && <ActionButton
                    muiIcon={<Check/>}
                    type="submit"
                    action={actionType}
                    handleClick={onSubmit}
                />}
            </DialogActions>
        </Dialog>
    );
}

PMCDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    height: PropTypes.number,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    headerColor: PropTypes.string,
    actionType: PropTypes.string,
    flexDirection: PropTypes.string,    
};


PMCDialog.defaultProps = {
    children: null,
    height: 550,
    size: 'sm',
    headerColor: '#F5894A',
    actionType: 'saveModal',
    flexDirection: 'column',
};

export default PMCDialog;


