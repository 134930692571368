import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    TextField
} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useEffect, useReducer, useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {getLocale} from "../../i18n";
import i18next from "i18next";


const MuiThemePure = createMuiTheme({
    overrides: {
        MuiPickersClock: {
            clock: {
                backgroundColor: '#EDEDED',
                color: 'white',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 18,
                "&$focused": {
                    color: '#50A933'
                }
            },
        },
        MuiInputBase: {
            root: {
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#fff', // Solid underline on focus
                },
            },
        },
    },
});

const useStyles = makeStyles(theme => ({
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit"
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%"
    },
    nonCurrentMonthDay: {
        opacity: 0,
        pointerEvents: "none"
    },
    highlight: {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    firstHighlight: {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%"
    },
    endHighlight: {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%"
    },
    dialogPaper: {
        maxWidth: 310 + theme.spacing(6)
    },
    dialogActions: {
        padding: theme.spacing(2, 3)
    },
    menu: {
        height: 300
    }
}));

const initialErrors = {
    startDate: null,
    endDate: null
};

const DATE_TYPES = {
    START_DATE: "startDate",
    END_DATE: "endDate"
};

const DATE_RANGE_ACTIONS = {
    SET_START_DATE: "setStartDate",
    SET_END_DATE: "setEndDate",
    RESET: "reset"
};

const dateRangeReducer = (state, {type, payload}) => {
    switch (type) {
        case DATE_RANGE_ACTIONS.SET_START_DATE:
            return {
                [DATE_TYPES.START_DATE]: payload,
                [DATE_TYPES.END_DATE]: null
            };
        case DATE_RANGE_ACTIONS.SET_END_DATE:
            return {
                ...state,
                [DATE_TYPES.END_DATE]: payload
            };
        case DATE_RANGE_ACTIONS.RESET:
            return {...payload};
        default:
            throw new Error();
    }
};

const createDecrementedRange = (start, stop, step = 1) =>
    Array.from({length: (stop - start) / step + 1}, (_, i) => stop - i * step);

const DateRangePicker = ({
                             title,
                             initialDateRange,
                             minDate,
                             maxDate,
                             open,
                             onAccept,
                             onCancel
                             // disableToolbar
                         }) => {
    const classes = useStyles();

    const {t} = useTranslation();


    const yearsList = createDecrementedRange(
        minDate.getFullYear(),
        maxDate.getFullYear()
    );

    const [dateRange, dispatchDateRange] = useReducer(
        dateRangeReducer,
        initialDateRange
    );
    const [errorMessages, setErrorMessages] = useState(initialErrors);
    const [isStartDateClick, setIsStartDateClick] = useState(true);
    const [year, setYear] = useState(
        moment(dateRange.startDate).isValid()
            ? moment(dateRange.startDate).year()
            : minDate.getFullYear()
    );
    const [hasErrors, setHasErrors] = useState(false);
    const [isYearDropdownChanged, setIsYearDropdownChanged] = useState(false);

    const {startDate, endDate} = dateRange;


    const renderDayAsDateRange = (date, _selectedDate, dayInCurrentMonth) => {

        let dateMoment = moment(date);

        const dayIsBetween = dateMoment.isBetween(startDate, endDate);
        const isBeforeStartDateOnEndDateSelection =
            dateMoment.isBefore(startDate, "day") && !isStartDateClick;
        const isStartDate = dateMoment.isSame(startDate, "day");
        const isEndDate = dateMoment.isSame(endDate, "day");

        const wrapperClassName = classNames({
            [classes.highlight]:
            dayInCurrentMonth &&
            !isYearDropdownChanged &&
            (dayIsBetween || isStartDate || isEndDate),
            [classes.firstHighlight]: isStartDate,
            [classes.endHighlight]: isEndDate
        });

        const dayClassName = classNames(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth
        });

        return (
            <div className={wrapperClassName}>
                <IconButton
                    className={dayClassName}
                    disabled={isBeforeStartDateOnEndDateSelection}
                >
                    <span> {dateMoment.format("D")} </span>
                </IconButton>
            </div>
        );
    };



    const handleYearListChange = e => {
        const year = e.target.value;
        const actionType = isStartDateClick
            ? DATE_RANGE_ACTIONS.SET_START_DATE
            : DATE_RANGE_ACTIONS.SET_END_DATE;
        const momentDate = isStartDateClick ? moment(startDate) : moment(endDate);
        const fallbackDate = isStartDateClick
            ? moment(`${year}-01-01`, "YYYY-MM-DD").toDate()
            : moment(startDate)
                .set({year})
                .toDate();

        dispatchDateRange({
            type: actionType,
            payload: momentDate.isValid()
                ? momentDate.set({year}).toDate()
                : fallbackDate
        });

        setYear(year);
        setIsYearDropdownChanged(true);
        console.log("handleYearListChange", year, isYearDropdownChanged);
    };

    const handleChange = momentDate => {

        console.log("onChange");

        if (momentDate !== null) {
            dispatchDateRange({
                type: isStartDateClick
                    ? DATE_RANGE_ACTIONS.SET_START_DATE
                    : DATE_RANGE_ACTIONS.SET_END_DATE,
                payload: momentDate
            });

            setIsStartDateClick(prevState => !prevState);
            isYearDropdownChanged && setIsYearDropdownChanged(false);
        }
    };

    const handleMonthChange = momentDate => {
        const dateYear = momentDate.getYear();
        dateYear !== year && setYear(dateYear);
    };

    const handleCancelPicker = () => {
        dispatchDateRange({
            type: DATE_RANGE_ACTIONS.RESET,
            payload: initialDateRange
        });
        setIsStartDateClick(true);
        moment(startDate).isValid() && setYear(startDate.getFullYear());
        typeof onCancel === "function" && onCancel(startDate, endDate);
    };

    const handleOkPicker = () => {
        setIsStartDateClick(true);
        moment(startDate).isValid() && setYear(startDate.getFullYear());
        typeof onAccept === "function" && onAccept(startDate, endDate);
    };

    useEffect(() => {
        setHasErrors(
            !moment(startDate).isValid() ||
            !moment(endDate).isValid() ||
            endDate < startDate
        );
        if (!hasErrors) {
            setErrorMessages({...initialErrors});
        }
    }, [startDate, endDate, hasErrors]);

    return (
        <Dialog
            aria-labelledby="dateRangePicker-dialog-title"
            open={open}
            onClose={onCancel}
            classes={{
                paper: classes.dialogPaper
            }}
        >

            <MuiThemeProvider theme={MuiThemePure}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(i18next.language)}>

                    <DialogTitle
                        id="dateRangePicker-dialog-title"
                        style={{paddingBottom: 0}}
                    >
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        {
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <TextField
                                        label={t('FROM')}
                                        value={moment(startDate).format("DD.MM.YYYY")}
                                        variant="outlined"
                                        margin="dense"
                                        helperText="mm.dd.yyyy"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        label={t('TO')}
                                        value={moment(endDate).format("DD.MM.YYYY")}
                                        variant="outlined"
                                        margin="dense"
                                        helperText="mm.dd.yyyy"
                                        fullWidth
                                    />
                                </Grid>
                                {
                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('YEAR')}
                                            value={year}
                                            onChange={handleYearListChange}
                                            variant="outlined"
                                            margin="dense"
                                            SelectProps={{style: {maxHeight: 300}}}
                                            fullWidth
                                            select
                                        >
                                            {yearsList.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                            </Grid>
                        }
                        <DatePicker
                            value={
                                isStartDateClick
                                    ? moment(startDate)
                                    : moment(endDate).isValid()
                                    ? moment(endDate)
                                    : moment(startDate)
                            }
                            renderDay={renderDayAsDateRange}
                            minDate={!isStartDateClick ? startDate : minDate}
                            maxDate={maxDate}
                            onChange={handleChange}
                            onAccept={_date => console.log("onAccept")}
                            // onYearChange={handleYearChange}
                            onMonthChange={handleMonthChange}
                            // disableToolbar={disableToolbar}
                            disableToolbar
                            variant="static"
                        />
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleOkPicker}
                            disabled={hasErrors}
                        >
                            {t('OK')}
                        </Button>
                        <Button variant="outlined" onClick={handleCancelPicker}>
                            {t('CANCEL')}
                        </Button>
                    </DialogActions>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </Dialog>
    );
};

DateRangePicker.propTypes = {
    title: PropTypes.string,
    startLabel: PropTypes.string,
    endLabel: PropTypes.string,
    initialDateRange: PropTypes.shape({
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date)
    }),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool
    // disableToolbar: PropTypes.bool
};

DateRangePicker.defaultProps = {
    title: "Select date range",
    startLabel: "Start",
    endLabel: "End",
    initialDateRange: {
        startDate: null,
        endDate: null
    },
    minDate: moment("1900-01-01").toDate(),
    maxDate: moment("2099-12-31").toDate(),
    onAccept: null,
    onCancel: null,
    open: false
    // disableToolbar: false
};

export default DateRangePicker;
