import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';

import {fetchParkingEvents} from 'redux/actions/events';
import EventsMainContent from './EventsMainContent';

const filterParams = {
    startDate: null,
    endDate: null,
    hostType: [''],
    eventType: [''],
    lpn: null,
    size: 5,
    page: 0,
};

function LocalParkingEvents(props) {
    const {
        parking,
        parkingEvents,
        eventsPaging,
        allCodeList,
        active,
        handleCreateOpen,
        fetchParkingEvents,
        handleHostClick,
        handleCardDetailOpen,
        events,
        userUseCases,
    } = props;


    console.warn("LocalParkingEvents", active);
    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    const [isOnline, setIsOnline] = useState(true);
    const [subjectSSEEvents, setSubjectSSEEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetchedEvents, setIsFetchedEvents] = useState(false);
    const handleOnline = () => {
        setIsOnline(!isOnline);
    };

    useEffect(() => {
        if (active) {
            setIsFetchedEvents(false);
        }
    }, [active, parking.parkingId]);

    useEffect(() => {
        if (isOnline && active) {
            setSubjectSSEEvents(events);
            setIsLoading(false);
        }
    }, [active, isOnline, events]);

    const parkingSSEEvents = subjectSSEEvents.filter((event) => event.parkingId === parking.parkingId);

    const [startTimeStr, setStartTimeStr] = useState(null);
    const [endTimeStr, setEndTimeStr] = useState(null)
    const [lpn, setLpn] = useState(null);
    const [selectedHosts, setSelectedHosts] = useState(['']);
    const [selectedTypes, setSelectedTypes] = useState(['']);
    const [size, setSize] = useState(5);
    const [page, setPage] = useState(0);
    const resetPagination = () => {
        filterParams.page = 0;
        setPage(0);
    };
    const [orderId, setOrderId] = useState(null);
    const [orderDirection, setOrderDirection] = useState('desc');

    const handleStartDate = (value) => {
        filterParams.startDate = value;
        resetPagination();
        setIsFetchedEvents(false);
        setStartTimeStr(value);
    };
    const handleEndDate = (value) => {
        filterParams.endDate = value;
        resetPagination();
        setIsFetchedEvents(false);
        setEndTimeStr(value);
    };
    const handleSelectHosts = (value) => {
        filterParams.hostType = value;
        resetPagination();
        setIsFetchedEvents(false);
        setSelectedHosts(value);
    };
    const handleSelectTypes = (value) => {
        filterParams.eventType = value;
        resetPagination();
        setIsFetchedEvents(false);
        setSelectedTypes(value);
    }
    const handleLPChange = (value) => {
        filterParams.lpn = value;
        resetPagination();
        setIsFetchedEvents(false);
        setLpn(value);
    }

    const handleChangeRowsPerPage = (value) => {
        filterParams.size = value;
        resetPagination();
        setIsFetchedEvents(false);
        setSize(value);
    };
    const handleChangePage = (value) => {
        filterParams.page = value;
        setIsFetchedEvents(false);
        setPage(value);
    };

    const handleOrderChange = (orderedColumnId, orderDirection) => {
        resetPagination();
        setOrderId(orderedColumnId);
        setOrderDirection(orderDirection);
        setIsFetchedEvents(false);
    }

    const fetchEvents = useCallback((dateFrom, dateTo, hostType, eventType, lpn, size, page, orderedColumnId, orderDirection) => {
        setIsLoading(true);

        fetchParkingEvents(parking.parkingId, {
            dateFrom,
            dateTo,
            hostType,
            eventType,
            lpn,
            size,
            page,
            orderedColumnId,
            orderDirection,
        }).then(() => {
            if (!isUnMounted.current) {
                setIsLoading(false);
                setIsFetchedEvents(true);

                // if (
                //     filterParams.startDate !== dateFrom
                //     || filterParams.endDate !== dateTo
                //     || JSON.stringify(filterParams.hostType) !== JSON.stringify(hostType)
                //     || JSON.stringify(filterParams.eventType) !== JSON.stringify(eventType)
                //     || JSON.stringify(filterParams.lpn) !== JSON.stringify(lpn)
                //     || filterParams.size !== size
                //     || filterParams.page !== page
                // ) {
                //     setIsFetchedEvents(false);
                // }
            }
        }).catch(() => {
            if (!isUnMounted.current) {
                setIsLoading(false);
            }
        });
    }, [parking, fetchParkingEvents]);

    useEffect(() => {
        if (!isOnline && !isFetchedEvents && active) {
            fetchEvents(startTimeStr, endTimeStr, selectedHosts, selectedTypes, lpn, size, page, orderId, orderDirection);
        }
    }, [
        active,
        parking,
        isOnline,
        isFetchedEvents,
        selectedHosts,
        selectedTypes,
        lpn,
        startTimeStr,
        endTimeStr,
        size,
        page,
        fetchEvents,
    ]);

    const [filteredSSEEvents, setFilteredSSEEvents] = useState(null);
    const filter = useCallback((filterHosts, filterTypes, lpn) => {
        let newEvents = subjectSSEEvents.filter((event) => event.parkingId === parking.parkingId);
        if (filterHosts.length > 1) {
            newEvents = newEvents.filter((event) => filterHosts.indexOf(event.parkingHostType.toString()) > -1);
        }

        if (filterTypes.length > 1) {
            newEvents = newEvents.filter((event) => filterTypes.indexOf(event.category.category) > -1);
        }

        if (lpn && lpn.length > 0) {
            newEvents = newEvents.filter((event) => event.lpn && event.lpn.toUpperCase().indexOf(lpn.toUpperCase()) !== -1);
        }
        setFilteredSSEEvents(newEvents);
    }, [parking.parkingId, subjectSSEEvents]);

    useEffect(() => {
        filter(selectedHosts, selectedTypes, lpn);
    }, [subjectSSEEvents, selectedHosts, selectedTypes, lpn,  filter]);

    useEffect(() => {
        setFilteredSSEEvents(null);
        setSelectedTypes(['']);
        setSelectedHosts(['']);
    }, [isOnline]);

    return (
        <EventsMainContent
            userUseCases={userUseCases}
            isOnline={isOnline}
            isLoading={isLoading}
            parkingEvents={parkingEvents}
            parkingSSEEvents={filteredSSEEvents || parkingSSEEvents}
            selectedHosts={selectedHosts}
            selectedTypes={selectedTypes}
            startTimeStr={startTimeStr}
            endTimeStr={endTimeStr}
            allCodeList={allCodeList}
            eventsPaging={eventsPaging}
            handleCreateOpen={handleCreateOpen}
            handleOnline={handleOnline}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            handleLPChange={handleLPChange}
            handleSelectHosts={handleSelectHosts}
            handleSelectTypes={handleSelectTypes}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            handleHostClick={handleHostClick}
            handleCardDetailOpen={handleCardDetailOpen}
            handleOrderChange={handleOrderChange}
        />
    );
}



LocalParkingEvents.propTypes = {
    parking: PropTypes.object.isRequired,
    parkingEvents: PropTypes.array.isRequired,
    eventsPaging: PropTypes.object.isRequired,
    allCodeList: PropTypes.array.isRequired,
    active: PropTypes.bool.isRequired,
    handleCreateOpen: PropTypes.func.isRequired,
    fetchParkingEvents: PropTypes.func.isRequired,
    handleHostClick: PropTypes.func.isRequired,
    handleCardDetailOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
    parkingEvents: store.eventsData.parkingEvents,
    eventsPaging: store.eventsData.eventsPaging,
    allCodeList: store.codesData.allCodeList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingEvents,
}, dispatch);



export default React.memo(connect(
    mapStateToProps,
    mapDispatchToProps,
)(LocalParkingEvents), (o1, o2)=>{return o2.active===false});
