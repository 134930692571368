import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import i18next from 'i18next';
import {getAMPM, getLocale, getLocaleDateTimeString} from '../../../../i18n';
import Grid from "@material-ui/core/Grid";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import CustomDateTimePicker from "../../../elements/CustomDateTimePicker";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 1, 2),
    },
    toolbarInner1: {
        width: '100%',
        border: '1px solid #E0E0E0',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: theme.palette.shadow.main,
        padding: theme.spacing(2, 1, 1, 2),

    },

}));

function CardsToolbar(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        parking,
        validTill,
        validity,
        blocked,
        lpn,
        cardNumber,
        handleValidTill,
        handleValidity,
        handleBlock,
        handleLPN,
        handleCardNumber,
        userUseCases,
    } = props;

    const validityStatus = [
        {
            value: 'valid',
            label: t('VALID'),
            key: 'valid',
        },
        {
            value: 'invalid',
            label: t('NON_VALID'),
            key: 'invalid',
        },
        {
            value: 'all',
            label: t('ALL'),
            key: 'all',
        },
    ];

    const blockedStatus = [
        {
            value: 'blocked',
            label: t('YES'),
            key: 'blocked',
        },
        {
            value: 'unblocked',
            label: t('NO'),
            key: 'unblocked',
        },
        {
            value: 'all',
            label: t('ALL'),
            key: 'all',
        },
    ];


    return (
        <Paper>
            <Box p={3}>
                <Grid container spacing={3}>
                    <Grid item md>
                        <TextField
                            fullWidth
                            type="number"
                            id="cardNumber"
                            value={cardNumber || ''}
                            onChange={(event) => {
                                handleCardNumber(event.target.value)
                            }}
                            label={t('CARD_NUMBER')}/>
                    </Grid>

                    <Grid item md>
                        <TextField
                            fullWidth
                            id="lpn"
                            value={lpn}
                            onChange={(event) => {
                                handleLPN(event.target.value)
                            }}
                            label={t('LPN')}/>
                    </Grid>
                    <Grid item md>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(i18next.language)}>
                            <CustomDateTimePicker
                                pure={true}
                                handleChange={handleValidTill}
                                fullWidth={true}
                                value={validTill}
                                label={t('VALID_TILL')}
                                format={getLocaleDateTimeString(i18next.language)}
                            >
                            </CustomDateTimePicker>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel id="validity">
                                {t('VALIDITY')}
                            </InputLabel>
                            <Select
                                autoWidth
                                value={validity}
                                id="validity"
                                onChange={handleValidity}
                            >
                                {validityStatus.map(a => {
                                    return (
                                        <MenuItem value={a.value}>{a.label}</MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel id="blocked">
                                {t('BLOCKED')}
                            </InputLabel>
                            <Select
                                autoWidth
                                value={blocked}
                                id="blocked"
                                onChange={handleBlock}
                            >
                                {blockedStatus.map(a => {
                                    return (
                                        <MenuItem value={a.value}>{a.label}</MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Paper>

    );
}

CardsToolbar.propTypes = {
    parking: PropTypes.object,
    validTill: PropTypes.object,
    validity: PropTypes.array.isRequired,
    blocked: PropTypes.array.isRequired,
    handleValidTill: PropTypes.func.isRequired,
    handleValidity: PropTypes.func.isRequired,
    handleBlock: PropTypes.func.isRequired,
};

CardsToolbar.defaultProps = {
    parking: null,
    validTill: null,
};

const UC_CARD_CREATE = 'UC0027';

export default CardsToolbar;
