import React, {useEffect, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';


import {Loading} from 'components/elements';
import {connect} from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import {blue, grey} from "@material-ui/core/colors";
import ParkingSymbol from 'components/common/ParkingSymbol';
import NoteIcon from '@material-ui/icons/Note';
import LayersIcon from '@material-ui/icons/Layers';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RGL, {WidthProvider} from 'react-grid-layout';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ToggleButton} from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {isComponentEnabledForUser} from "../../../utils";


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        width: '250px',
    },
}))(Tooltip);


const cols = [
    {
        value: 1,
        label: '1',
        key: '1',
    },
    {
        value: 3,
        label: '3',
        key: '3',
    },
    {
        value: 5,
        label: '5',
        key: '5',
    },
    {
        value: 7,
        label: '7',
        key: '7',
    },
];


const useStyles = makeStyles((theme) => ({
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 14,
    },
    title: {
        fontWeight: '400',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        height: "inherit",
    },
    cardTop: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 1, 0, 1),
        height: '100%',
        justifyContent: 'space-between',
    },
    cardContent: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'space-around',
        background: theme.palette.base[50],
        textAlign: 'center',
        cursor: 'pointer',
        height: '100%',
    },
    parking: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxHeight: '35px',

    },
    cardTooltip: {
        flex: 0,
        padding: theme.spacing(0.5),
        background: theme.palette.base[100],
        textAlign: 'center',

    },
    productGroup: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxHeight: '35px',
        color: '#000',
        fontWeight: 300,
    },
    productType: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxHeight: '35px',
        color: '#000',
        fontWeight: 300,
        justifyContent: 'space-between'
    },
    empty: {
        width: '100%',
        height: 416,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
    },
    name: {
        marginLeft: theme.spacing(1),
    },
    gridMenu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        flex: 1,
    },

}));

function getProductName(product) {
    if (product.productSystemParam) {
        let o = product.productSystemParam.find(a => a.paramName === 'name');
        if (o) {
            return o.valueVarchar;
        }
    }
    return '';
}


function HTMLTooltip(tooltip) {
    return <div dangerouslySetInnerHTML={
        {
            __html: tooltip
        }
    }>
    </div>;

}

const getProductIcon = (productType) => {
    if (productType === 'P') {
        return <NoteIcon fontSize="small" style={{color: grey[500]}}/>
    }
    if (productType === 'MP') {
        return <LayersIcon fontSize="small" style={{color: grey[500]}}/>
    }

    if (productType === 'D') {
        return <LocalOfferIcon fontSize="small" style={{color: grey[500]}}/>
    }
    return <NoteIcon fontSize="small" style={{color: grey[500]}}/>;
}


const Card = ({onClick, handleProductDetail, product, aggregated, productCategory, editable, userUseCases}) => {
    const classes = useStyles();
    const title = getProductName(product);
    const smallTitle = title && title.length > 25;
    const {t} = useTranslation();
    return (

        <div className={classes.card} onClick={() => {
            !editable && isComponentEnabledForUser(UC_MENU_CASHDESK_WIZARD, userUseCases)   && handleProductDetail(product);
        }}>
            <div className={classes.cardTop}>

                {!aggregated && <Tooltip title={t('PRODUCT_TYPE')}>
                    <div className={classes.productType}>
                        <div>{product.productTemplateName}</div>
                        {getProductIcon(product.productTemplateType)}
                    </div>
                </Tooltip>}
                {!aggregated && <div className={classes.cardContent} onClick={onClick}>
                    <Typography variant={!!smallTitle ? "h3" : "h2"} className={classes.title}>{title}</Typography>
                </div>}
                {aggregated && <div className={classes.cardContent} onClick={onClick}>
                    <Typography variant="h2" className={classes.title}>{product.productTemplateName}
                    </Typography>
                    {getProductIcon(product.productTemplateType)}
                </div>}

                {!aggregated && <div className={classes.parking}>
                    <ParkingSymbol variant="h4" color={product.color}/>
                    <Typography variant="subtitle2" className={classes.name}>
                        {product.parkingName}
                    </Typography>
                </div>
                }
                <div className={classes.productGroup}><Tooltip title={t('PRODUCT_GROUP')}>
                    <div>{product.productGroupName}</div>
                </Tooltip></div>
            </div>
            <div className={classes.cardTooltip}>

                {product.description && <HtmlTooltip title={HTMLTooltip(product.description)}>
                    <InfoIcon style={{color: blue[500]}}/>
                </HtmlTooltip>}
                {!product.description && <>
                    <InfoIcon style={{color: grey[500]}}/>
                </>}
            </div>
        </div>
    );
};


function ProductCategories(props) {
    const classes = useStyles();
    const ReactGridLayout = WidthProvider(RGL);

    const {t} = useTranslation();

    const {
        product,
        products,
        productCategory,
        userUseCases,
        isLoading,
        handleProductDetail,
        aggregated,
        activeTab,
        tabIndex,
        setChangingLayout,
    } = props;


    const [productsFilteredByCategory, setProductsFilteredByCategory] = useState([]);


    const [layout, setLayout] = useState();

    const localCol = JSON.parse(localStorage.getItem(`col{productCategory}${aggregated}`)) || 3;
    const [col, setCol] = useState(localCol);
    const [editable, setEditable] = useState(false);


    const changeCol = (event) => {
        setCol(event.target.value);
        localStorage.setItem(`col{productCategory}${aggregated}`, event.target.value);
        const fil = products.filter((product) => (productCategory === t('ALL') || (product.productGroupName === productCategory)));
        const filterFil = aggregated ? removeDuplicities(fil) : fil;
        setLayout(getLayout(filterFil, event.target.value))
        setChangingLayout(true);
    }


    const reset = () => {
        localStorage.removeItem(`col${productCategory}${aggregated}`);
        localStorage.removeItem(`layout${productCategory}${aggregated}`);
        const fil = products.filter((product) => (productCategory === t('ALL') || (product.productGroupName === productCategory)));
        const filterFil = aggregated ? removeDuplicities(fil) : fil;
        setLayout(getLayout(filterFil, 3))
        setCol(3);
        setChangingLayout(true);
    }


    const removeDuplicities = (fil) => {
        const ret = [];
        const keys = {};
        for (let i = 0; i < fil.length; i++) {
            if (keys[fil[i].productTemplateName]) {
                continue;
            } else {
                keys[fil[i].productTemplateName] = true;
                ret.push(fil[i]);
            }
        }
        return ret;
    }

    useEffect(() => {
        if (products) {
            const fil = products.filter((product) => (productCategory === t('ALL') || (product.productGroupName === productCategory)));
            const filterFil = aggregated ? removeDuplicities(fil) : fil;
            const originalLayout = JSON.parse(localStorage.getItem(`layout${productCategory}${aggregated}`));

            if (originalLayout) {
                setLayout(originalLayout);
            } else {
                setLayout(getLayout(filterFil));
            }
            setProductsFilteredByCategory(filterFil);
        }
    }, [products, productCategory, aggregated]);


    const EmptyPage = () => (isLoading ? (
        <div className={classes.empty}>
            <Loading/>
        </div>
    ) : (
        <div className={classes.empty}>
        </div>
    ));

    const onLayoutChange = (layout) => {
        if (editable) {
            localStorage.setItem(
                `layout${productCategory}${aggregated}`,
                JSON.stringify(layout)
            );
        }
        setLayout(layout);
    }

    const getLayout = (dat, newCol) => {
        const res = [];
        const colInner = newCol || col;

        dat.sort(function (a, b) {
            const arow = a.row || 0;
            const acolumn = a.column || 0;
            const brow = b.row || 0;
            const bcolumn = b.column || 0;
            let res = arow - brow;
            if (res === 0) {
                res = acolumn - bcolumn;
                if (res === 0) {
                    const aname = getProductName(a);
                    const bname = getProductName(b);
                    if (aname > bname) {
                        res = 1;
                    } else if (bname > aname) {
                        res = -1;
                    }
                }
            }
            return res;
        });


        for (let i = 0; i < dat.length; i++) {
            const x = i % colInner;
            const y = Math.floor(i / colInner);
            if (!res[x]) {
                res[x] = [];
            }
            res[x][y] = dat[i];
        }

        //swap
        for (let i = 0; i < res.length; i++) {
            if (dat[i]) {
                for (let j = 0; j < res[i].length; j++) {
                    if (res[i][j]) {
                        const prod = res[i][j];
                        if (prod.row && prod.column) {
                            const column = prod.column > colInner ? colInner - 1 : prod.column - 1;
                            if (!res[column]) {
                                res[column] = [];
                            }
                            let prodToSwap;
                            if (res[column][prod.row - 1]) {
                                prodToSwap = res[column][prod.row - 1];
                            }
                            res[i][j] = prodToSwap;
                            res[column][prod.row - 1] = prod;
                        }
                    }
                }
            }
        }

        const layout = [];
        for (let i = 0; i < res.length; i++) {
            if (dat[i]) {
                for (let j = 0; j < res[i].length; j++) {
                    if (res[i][j]) {
                        layout.push({
                                x: i,
                                y: j,
                                w: 1,
                                h: 1,
                                i: res[i][j].productId.toString(),
                            }
                        );
                    }
                }
            }
        }
        return layout;
    }

    return (
        (activeTab === tabIndex) && layout && productsFilteredByCategory.length > 0 ? <>
                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <FormControl>
                            <Select
                                style={{width: '30px', height: '30px'}}
                                value={col}
                                onChange={changeCol}
                            >
                                {cols.map((item, key) => (
                                    <MenuItem key={item.key}
                                              value={item.value}>{item.label}</MenuItem>))}
                            </Select>
                        </FormControl>
                        <IconButton
                            style={{width: '30px', height: '30px'}}
                            onClick={() => {
                                reset();
                            }}
                        >
                            <DeleteIcon/>
                        </IconButton>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={editable}
                            onChange={() => {
                                setEditable(!editable);
                            }}
                        >
                            <EditIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>
                <ReactGridLayout
                    verticalCompact={false}
                    onLayoutChange={onLayoutChange}
                    rowHeight={240}
                    isDraggable={editable}
                    layout={layout}
                    isResizable={editable}
                    cols={col}
                    width={'100%'}
                    measureBeforeMount={false}
                    useCSSTransforms={false}
                >
                    {productsFilteredByCategory.map((product, index) => (
                        <div key={product.productId.toString()} style={{
                            'borderStyle': 'solid',
                            'borderWidth': '2px',
                            'boxShadow': '2px 2px 2px #EEEEEE',
                            'borderColor': '#DEE2E5',
                        }
                        }>
                            <Card handleProductDetail={handleProductDetail}
                                  productCategory={productCategory}
                                  product={product}
                                  editable={editable}
                                  userUseCases={userUseCases}
                                  aggregated={aggregated}/>
                        </div>
                    ))}
                </ReactGridLayout>
            </>
            :
            <EmptyPage/>
    );
}

ProductCategories.propTypes =
    {
        products: PropTypes.array.isRequired,

    }
;

ProductCategories.defaultProps =
    {}
;

const mapStateToProps = (store) => (
    {}
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {}
    , dispatch);


const UC_MENU_CASHDESK_WIZARD = 'UC0405';



export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCategories);
