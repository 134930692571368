import httpService from './http.service';

const registrePhoto = (parkingHostId, withDetail) => httpService
  .post(`/photoregistration/${parkingHostId}?withDetail=${withDetail}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const unRegistrePhoto = (parkingHostId) => httpService
  .post(`/photounregistration/${parkingHostId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

  const getPhotoDetail = (photoKey, parkingId) => httpService
  .get(`/photodetail/${photoKey}?parkingId=${parkingId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  registrePhoto,
  unRegistrePhoto,
  getPhotoDetail
};