import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';

import {createTask} from 'redux/actions/tasks';
import {updateZone} from 'redux/actions/zones';
import {createCard, bulkEditCards} from 'redux/actions/cards';
import {fetchAllCodeList} from 'redux/actions/codes';
import {fetchSubjectOwners} from 'redux/actions/owners';
import {fetchParkingHosts} from 'redux/actions/hosts';
import {Loading} from 'components/elements';
import ParkingMainContent from './MainContent';
import {setHostName, setParking} from 'redux/actions/parkings';
import PerfectScrollbar from "react-perfect-scrollbar";
import Fulltext from "../../components/modals/Fulltext";

function Parking(props) {
    const {
        user,
        history,
        parkings,
        sseParkingHosts,
        events,
        isFetchingAllList,
        fetchAllCodeList,
        fetchSubjectOwners,
        fetchParkingHosts,
        createTask,
        updateZone,
        createCard,
        bulkEditCards,
        occupancy,
        selectedHostName,
        selectedParking,
        calls,
        sipCall,
        sipStatus,
        setHostName,
        setParking,
        userUseCases,
    } = props;


    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    const [isFetchedCodeList, setIsFetchedCodeList] = useState(false);
    const [isLoadingCodeList, setIsLoadingCodeList] = useState(true);
    const fetchCodeList = useCallback(() => {
        if (
            user
            && user.subjectId
            && !isFetchingAllList
        ) {
            fetchAllCodeList().then(() => {
                if (!isUnMounted.current) {
                    setIsFetchedCodeList(true);
                    setIsLoadingCodeList(false);
                }
            }).catch(() => {
                if (!isUnMounted.current) {
                    setIsFetchedCodeList(false);
                    setIsLoadingCodeList(false);
                }
            });
        }
    }, [fetchAllCodeList, isFetchingAllList, user]);

    useEffect(() => {
        if (!isFetchedCodeList) {
            fetchCodeList();
        }
    }, [fetchCodeList, isFetchedCodeList, user]);

    const [selectedIds, setSelectedIds] = useState([]);
    const selectParking = (id) => () => {
        setHostName(null);
        setParking(null);
        if (selectedIds.length === 0) {
            setSelectedIds([id]);
            fetchParkingHosts(id);
        } else if (selectedIds.length === 1) {
            if (id === selectedIds[0]) {
                setSelectedIds([]);
            } else {
                setSelectedIds([id]);
                fetchParkingHosts(id);
            }
        }
    };


    const toggleAllParking = () => {
        setSelectedIds([]);
    };

    useEffect(() => {
        if (selectedParking !== null) {
            setSelectedIds([selectedParking]);
        }
    }, [selectedParking]);


    if (!user || isLoadingCodeList || parkings.length === 0) {
        return (
            <Loading/>
        );
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <ParkingMainContent
                user={user}
                userUseCases={userUseCases}
                parkings={parkings}
                events={events}
                sseParkingHosts={sseParkingHosts}
                occupancy={occupancy}
                setSelectedIds={setSelectedIds}
                selectedHostName={selectedHostName}
                setSelectedHostName={setHostName}
                selectedIds={selectedIds}
                toggleAllParking={toggleAllParking}
                selectParking={selectParking}
                createTask={createTask}
                updateZone={updateZone}
                createCard={createCard}
                bulkEditCards={bulkEditCards}
                fetchSubjectOwners={fetchSubjectOwners}
                calls={calls}
                sipCall={sipCall}
                sipStatus={sipStatus}
                openFulltext={props.openFulltext}
                handleFulltextClose={props.handleFulltextClose}
            />



        </PerfectScrollbar>
    );
}

Parking.propTypes = {
    user: PropTypes.object,
    cardNumber: PropTypes.string,
    parkings: PropTypes.array.isRequired,
    events: PropTypes.array.isRequired,
    sseParkingHosts: PropTypes.array.isRequired,
    occupancy: PropTypes.array.isRequired,
    isFetchingAllList: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    fetchAllCodeList: PropTypes.func.isRequired,
    fetchSubjectOwners: PropTypes.func.isRequired,
    fetchParkingHosts: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    updateZone: PropTypes.func.isRequired,
    createCard: PropTypes.func.isRequired,
    bulkEditCards: PropTypes.func.isRequired,
};

Parking.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    selectedHostName: store.parkingsData.selectedHostName,
    selectedParking: store.parkingsData.selectedParking,
    parkings: store.parkingsData.parkings || [],
    sseParkingHosts: store.parkingsData.parkingHosts || [],
    events: store.parkingsData.events || [],
    occupancy: store.parkingsData.occupancy || [],
    isFetchingAllList: store.codesData.isFetchingAllList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllCodeList,
    fetchSubjectOwners,
    fetchParkingHosts,
    createTask,
    updateZone,
    createCard,
    bulkEditCards,
    setHostName,
    setParking,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Parking);
