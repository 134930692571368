import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingPlayList : false,
  isFetchingPlayListDetail: false,
  isCreating: false,
  isUpdating: false,
  siDeleting: false,
  playLists: [],
  playList: {},
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PLAYLIST_FETCH_REQUEST:
      return {
        ...state,
        isFetchingPlayList: true,
      };
    case types.PLAYLIST_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingPlayList: false,
        playLists: action.payload.playLists,
      };
    case types.PLAYLIST_FETCH_FAILT:
      return {
        ...state,
        isFetchingPlayList: false,
        errors: action.payload.error,
      };

    case types.PLAYLIST_DETAIL_FETCH_REQUEST:
      return {
        ...state,
        isFetchingPlayListDetail: true,
      };
    case types.PLAYLIST_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingPlayListDetail: false,
        playList: action.payload.playList,
      };
    case types.PLAYLIST_DETAIL_FETCH_FAILT:
      return {
        ...state,
        isFetchingPlayListDetail: false,
        errors: action.payload.error,
      };
  

    default:
      return state;
  }
};
