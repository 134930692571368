import httpService from './http.service';

const getPlayLists = () => httpService
    .get('/playlist')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getPlayList = (playListId) => httpService
    .get('/playlist/'+playListId)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const createPlayList = (playList) => httpService
    .post(`/playlist/create`, playList)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updatePlayList = (playList) => httpService
    .put(`/playlist/update`, playList)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const deletePlayList = (playListId) => httpService
    .remove(`/playlist/delete/${playListId}`)
    .then()
    .catch((err) => Promise.reject(err));

export default {
    getPlayLists,
    getPlayList,
    createPlayList,
    updatePlayList,
    deletePlayList
};
