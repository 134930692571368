import actionTypes from '../actionTypes';

const INITIAL_STATE = {
  sounds: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_SOUNDS:
      return {
        ...state,
        sounds: action.payload.sounds,
      };
    default:
      return state;
  }
};
