import codesService from 'services/codes.service';
import types from '../actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchAllCodeList = () => (dispatch, getState) => {
  if (getState().codesData.isFetchingAllList) {
    return Promise.reject();
  }

  dispatch({
    type: types.ALL_CODE_FETCH_REQUEST,
  });

  return codesService.getAllCodes()
    .then((allCodeList) => {
      dispatch({
        type: types.ALL_CODE_FETCH_SUCCESS,
        payload: { allCodeList },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.ALL_CODE_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};
