import httpService from './http.service';

const getAllCodes = () => httpService
  .get('/codelist')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getAllCodes,
};
