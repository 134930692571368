import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingParkingList: false,
  isFetchingSubjectList: false,
  parkingEvents: [],
  subjectEvents: [],
  eventsPaging: {
    pageSize: 5,
    currentPage: 0,
    totalSize: 0,
  },
  subjectEventsPaging: {
    pageSize: 5,
    currentPage: 0,
    totalSize: 0,
  },

  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SUBJECT_EVENTS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingSubjectList: true,
      };
    case types.SUBJECT_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingSubjectList: false,
        subjectEvents: action.payload.data.events,
        subjectEventsPaging: action.payload.data.paging,
      };
    case types.SUBJECT_EVENTS_FETCH_FAIL:
      return {
        ...state,
        isFetchingSubjectList: false,
        errors: action.payload.error,
      };
    case types.PARKING_EVENTS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingList: true,
      };
    case types.PARKING_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingList: false,
        parkingEvents: action.payload.data.events,
        eventsPaging: action.payload.data.paging,
      };
    case types.PARKING_EVENTS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingList: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
