import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { Loading } from 'components/elements';
import * as actions from 'redux/actions/hosts';
import { connect } from 'react-redux';
import HostDetails from './HostDetails';
import HostActions from './HostActions';
import HostCommands from './HostCommands';
import HostStatuses from './HostStatuses';
import HostStream from '../../../../components/common/HostStream';
import HostInfo from './HostInfo';
import {isComponentVisibleForUser, hasStream} from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
    display: 'flex',
  },
  hostName: {
    fontSize: 22,
    color: theme.palette.base[800],
  },
  leftContent: {
    width: '130%',
    minWidth: '680px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectWrapper: {
    width: 160,
    color: theme.palette.base[800],

    '& svg': {
      width: 24,
      height: 24,
      fill: theme.palette.base[300],
      marginTop: 0,
    },
  },
  emptyHosts: {
    width: '100%',
    height: 416,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
}));

function LocalParkingHosts(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    parking,
    active,
    createParkingHostCommand,
    createParkingHostAction,
    handleCreateTaskOpen,
    handleCameraDetailOpen,
    allCodeList,
    selectedHostName,
    setSelectedHostName,
    sseParkingHosts,
    withDetail,
    sipCall,
    calls,
    sipStatus,
    userUseCases
  } = props;

  console.warn("LocalParkingHosts", active);


  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [parkingSSEHosts, setParkingSSEHosts] = useState([]);

  useEffect(() => {
    if (active) {
      setParkingSSEHosts(sseParkingHosts);
      setIsLoading(false);
    }
  }, [active, sseParkingHosts]);

  const parkingHosts = parkingSSEHosts.length > 0
    ? parkingSSEHosts.filter((host) => host.parkingId === parking.parkingId)
    : [];

  const [host, setHost] = useState({});

  useEffect(() => {
    if (selectedHostName && parkingHosts) {
      const s = parkingHosts.filter((s) => s.hostName === selectedHostName);
      if (s && s.length > 0) {
        setHost(s[0]);
      } else {
        setHost(null);
      }
    }
  }, [parkingHosts, selectedHostName]);

  const handleHostNameClick = (host) => {
    setSelectedHostName(host.hostName);
  };

  const EmptyPage = () => (isLoading ? (
    <div className={classes.emptyHosts}>
      <Loading />
    </div>
  ) : (
    <div className={classes.emptyHosts}>
      {t('NO_RECORDS_TO_DISPLAY')}
    </div>
  ));

  const columnSize = hasStream(host) ? 3 : 4;

  return (
    <div className={classes.container}>
      {parkingHosts.length > 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={columnSize}>
            <HostInfo host={host}/>
            {isComponentVisibleForUser(UC_HOST_DETAIL, userUseCases)  && isComponentVisibleForUser(UC_HOST_STATUSES, userUseCases) &&
            <HostStatuses host={host}/>
            }
          </Grid>

          <Grid item xs={columnSize}>
            {isComponentVisibleForUser(UC_HOST_DETAIL, userUseCases)  &&
            <HostCommands
              sipCall={sipCall}
              sipStatus={sipStatus}
              host={host}
              createParkingHostCommand={createParkingHostCommand}
              userUseCases={userUseCases}
            />
            }
          </Grid>
          <Grid item xs={columnSize}>
            {isComponentVisibleForUser(UC_HOST_DETAIL, userUseCases) &&
            <HostActions
                host={host}
                createParkingHostAction={createParkingHostAction}
                handleCreateTaskOpen={handleCreateTaskOpen}
                allCodeList={allCodeList}
                userUseCases={userUseCases}
            />
            }
          </Grid>

          {hasStream(host) && <Grid item xs={columnSize}>
            {isComponentVisibleForUser(UC_HOST_DETAIL, userUseCases)  && isComponentVisibleForUser(UC_HOST_CAMERA, userUseCases) &&
            <HostStream host={host} active={active} handleCameraDetailOpen={handleCameraDetailOpen} withDetail={withDetail} />
            }
          </Grid>}

          <Grid item xs={12}>
            <HostDetails
              calls={calls}
              parkingId={parking.parkingId}
              parkingHosts={parkingHosts}
              handleSelectChange={handleHostNameClick}
              selectedHost={host}
              selectedHostName={selectedHostName}
            />
          </Grid>
        </Grid>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
}

LocalParkingHosts.propTypes = {
  selectedHostName: PropTypes.string,
  parking: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  createParkingHostCommand: PropTypes.func.isRequired,
  createParkingHostAction: PropTypes.func.isRequired,
  handleCreateTaskOpen: PropTypes.func.isRequired,
  handleCameraDetailOpen: PropTypes.func.isRequired,
  allCodeList: PropTypes.array.isRequired,
  setSelectedHostName: PropTypes.func.isRequired,
  sseParkingHosts: PropTypes.array.isRequired,
};

LocalParkingHosts.defaultProps = {
  selectedHostName: null,
};

const mapStateToProps = (store) => ({
  isCreatingCommand: store.hostsData.isCreatingCommand,
  allCodeList: store.codesData.allCodeList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createParkingHostCommand: actions.createParkingHostCommand,
  createParkingHostAction: actions.createParkingHostAction,
}, dispatch);

const UC_HOST_DETAIL = 'UC0006';
const UC_HOST_STATUSES = 'UC0009';
const UC_HOST_CAMERA = 'UC0013';

export default React.memo(connect(
    mapStateToProps,
    mapDispatchToProps,
)(LocalParkingHosts), (o1, o2)=>{return o2.active===false});



