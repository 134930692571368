import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { getIcon } from './Utils';

const useStyles = makeStyles((theme) => ({
  hostManagement: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
  },
  hostStatusImg: {
    height: 30,
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  hostStatusName: {
    height: 30,
    marginBottom: theme.spacing(1.5),
    fontSize: 19,
    fontWeight: 500,
    color: theme.palette.base[800],
    textAlign: 'left',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

function HostInfo(props) {
  const classes = useStyles();

  const { host } = props;

  return (
    <div className={classes.hostManagement}>
      {host && host.iconName && (
        <div className={classes.hostStatusImg}>
          <img
            src={getIcon(host.iconName)}
            className={classes.image}
            alt={host.hostName}
            title={host.hostName}
          />
        </div>
      )}

      {host && <div className={classes.hostStatusName}>{host.hostName}</div>}
    </div>
  );
}

HostInfo.propTypes = {
  host: PropTypes.object,
};

HostInfo.defaultProps = {
  host: { iconName: '' },
};

export default HostInfo;
