import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CloseIcon from '@material-ui/icons/Close';

import { ParkingName } from 'components/common';
import { CustomCheckbox } from 'components/elements';

const useStyles = makeStyles((theme) => ({
  parkingStatus: {
    height: 28,
    display: 'flex',
    alignItems: 'center',
  },
  blocked: {
    marginLeft: theme.spacing(1.5),
  },
  closeButton: {
    marginRight: theme.spacing(-1.5),
  },
  lock: {
    marginTop: 6,
  },
  inactive: {
    color: '#ADB0B8',
  },
}));

function ParkingStatus(props) {
  const classes = useStyles();

  const {
    isNew,
    detailParking,
    detailParkings,
    cardParkings,
    tabIndex,
    handleRemove,
  } = props;

  const selectedParking = cardParkings.find((p) => p.parkingId === detailParking.parkingId);
  const active = detailParkings[tabIndex].zones[0].valid;

  return (
    <div className={classes.parkingStatus}>
      <ParkingName parking={selectedParking} />

      <div className={classes.blocked}>
        {detailParkings[tabIndex].zones[0].blocked ? (
          <LockIcon className={classnames(classes.lock, active ? '' : classes.inactive)} color="error" />
        ) : (
          <LockOpenIcon className={classnames(classes.lock, active ? '' : classes.inactive)} color="primary" />
        )}
      </div>

      <CustomCheckbox isST checked disabled={!active} />

      {isNew && (
        <CloseIcon
          className={classes.closeIcon}
          onClick={handleRemove(detailParking)}
        />
      )}
    </div>
  );
}

ParkingStatus.propTypes = {
  isNew: PropTypes.bool.isRequired,
  detailParking: PropTypes.object.isRequired,
  detailParkings: PropTypes.array.isRequired,
  cardParkings: PropTypes.array.isRequired,
  tabIndex: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default ParkingStatus;
