import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingDownloadList: false,
  isFetchingDownloadFile: false,
  downloadList: [],
  downloadFile: null,
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARKING_DOWNLOAD_FETCH_REQUEST:
      return {
        ...state,
        isFetchingDownloadList: true,
      };
    case types.PARKING_DOWNLOAD_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingDownloadList: false,
        downloadList: action.payload.downloadList,
      };
    case types.PARKING_DOWNLOAD_FETCH_FAIL:
      return {
        ...state,
        isFetchingDownloadList: false,
        errors: action.payload.error,
      };

    case types.PARKING_DOWNLOAD_FILE_FETCH_REQUEST:
      return {
        ...state,
        isFetchingDownloadFile: true,
      };
    case types.PARKING_DOWNLOAD_FILE_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingDownloadFile: false,
        downloadFile: action.payload.downloadFile,
      };
    case types.PARKING_DOWNLOAD_FILE_FETCH_FAIL:
      return {
        ...state,
        isFetchingDownloadFile: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};

