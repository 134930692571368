import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getCodeList } from 'utils';
import OnlineEventsTable from './OnlineEventsTable';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchSubjectEvents} from 'redux/actions/events';

const filterParams = {
  startDate: null,
  endDate: null,
  hostType: [''],
  eventType: [''],
  lpn: null,
  size: 5,
  page: 0,
};

function OnlineEvents(props) {
  const {
    user,
    parkings,
    allCodeList,
    fetchSubjectEvents,
    subjectEvents,
    subjectEventsPaging,
    selectParking,
    handleTaskCreateOpen,
    handleHostNameClick,
    handleCardNameClick,
    sseEvents,
    subjectId,
    simple,
    userUseCases,
  } = props;
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState(true);

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const handleOnline = () => {
    if ( !isOnline ) {
      setIsFetchedEvents(false);
    }
    setIsOnline(!isOnline);
  };

  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchedEvents, setIsFetchedEvents] = useState(false);

  const [startTimeStr, setStartTimeStr] = useState(null);
  const [endTimeStr, setEndTimeStr] = useState(null);
  const [lpn, setLpn] = useState(null);
  const [selectedHosts, setSelectedHosts] = useState(['']);
  const [selectedTypes, setSelectedTypes] = useState(['']);
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(0);
  const resetPagination = () => {
    filterParams.page = 0;
    setPage(0);
  };

  const handleStartDate = (value) => {
    filterParams.startDate = value;
    resetPagination();
    setIsFetchedEvents(false);
    setStartTimeStr(value);
  };
  const handleEndDate = (value) => {
    filterParams.endDate = value;
    resetPagination();
    setIsFetchedEvents(false);
    setEndTimeStr(value);
  };
  const handleSelectHosts = (value) => {
    filterParams.hostType = value;
    resetPagination();
    setIsFetchedEvents(false);
    setSelectedHosts(value);
  };
  const handleSelectTypes = (value) => {
    filterParams.eventType = value;
    resetPagination();
    setIsFetchedEvents(false);
    setSelectedTypes(value);
  };
  const handleLPChange = (value) => {
    filterParams.lpn = value;
    resetPagination();
    setIsFetchedEvents(false);
    setLpn(value);
  }
  const handleChangeRowsPerPage = (value) => {
    filterParams.size = value;
    resetPagination();
    setIsFetchedEvents(false);
    setSize(value);
  };
  const handleChangePage = (value) => {
    filterParams.page = value;
    setIsFetchedEvents(false);
    setPage(value);
  };

  useEffect(() => {
    if (user && isOnline) {
      setEvents(sseEvents);
      setIsLoading(false);
    }
  }, [user, isOnline, sseEvents]);

  const getEventTypes = (allCodeList) => {
    const typeItems = getCodeList(allCodeList, 'EVENT_TYPE');
    return typeItems.length > 0 ? typeItems.map((item) => ({
      value: item.code,
      label: t(item.description),
      key: `event_type_${item.codeListItemId}`,
    })) : [];
  };
  const getEventHosts = (allCodeList) => {
    const hostItems = getCodeList(allCodeList, 'HOST_TYPE');
    return hostItems.length > 0 ? hostItems.map((item) => ({
      value: item.code,
      label: t(item.description),
      key: `host_type_${item.codeListItemId}`,
    })) : [];
  };

  const getParkingNames = (parkings) => (parkings.length > 0 ? parkings.map((parking) => ({
    value: parking.parkingId,
    label: parking.parkingName,
    key: `host_type_${parking.parkingId}`,
  })) : []);

  const parkingNames = getParkingNames(parkings);
  const eventTypes = getEventTypes(allCodeList);
  const hosts = getEventHosts(allCodeList);

  const [filteredEvents, setFilteredEvents] = useState(null);
  const [selectedParkingNames, setSelectedParkingNames] = useState(['']);
  const filter = useCallback((filterParkingNames, filterHosts, filterTypes, lpn) => {
    let newEvents = events;
    if (filterParkingNames.length > 1) {
      newEvents = newEvents.filter((event) => filterParkingNames.indexOf(event.parkingId) > -1);
    }

    if (filterHosts.length > 1) {
      newEvents = newEvents.filter((event) => filterHosts.indexOf(event.parkingHostType.toString()) > -1);
    }

    if (filterTypes.length > 1) {
      newEvents = newEvents.filter((event) => filterTypes.indexOf(event.category.category) > -1);
    }
    if (lpn && lpn.length > 0) {
      newEvents = newEvents.filter((event) => event.lpn && event.lpn.toUpperCase().indexOf(lpn.toUpperCase()) !== -1);
    }

    setFilteredEvents(newEvents);
  }, [events]);

  const handleSelectParkingNames = (value) => {
    setSelectedParkingNames(value);
  };

  useEffect(() => {
    filter(selectedParkingNames, selectedHosts, selectedTypes, lpn);
  }, [events, selectedHosts, selectedParkingNames, selectedTypes, lpn, filter]);

  const handleCreateTask = (rowData) => () => {
    handleTaskCreateOpen(rowData);
  };


  const fetchEvents = useCallback((dateFrom, dateTo, hostType, eventType, lpn, size, page) => {
    setIsLoading(true);

    fetchSubjectEvents(user.subjectId, {
      dateFrom,
      dateTo,
      hostType,
      eventType,
      lpn,
      size,
      page,
    }).then(() => {
      if (!isUnMounted.current) {
        setIsLoading(false);
        setIsFetchedEvents(true);

        if (
            filterParams.startDate !== dateFrom
            || filterParams.endDate !== dateTo
            || JSON.stringify(filterParams.hostType) !== JSON.stringify(hostType)
            || JSON.stringify(filterParams.eventType) !== JSON.stringify(eventType)
            || JSON.stringify(filterParams.lpn) !== JSON.stringify(lpn)
            || filterParams.size !== size
            || filterParams.page !== page
        ) {
          setIsFetchedEvents(false);
        }
      }
    }).catch(() => {
      if (!isUnMounted.current) {
        setIsLoading(false);
      }
    });
  }, [fetchSubjectEvents]);

  useEffect(() => {
    if (!isOnline && !isFetchedEvents) {
      fetchEvents(startTimeStr, endTimeStr, selectedHosts, selectedTypes, lpn, size, page);
    }
  }, [
    isOnline,
    isFetchedEvents,
    selectedHosts,
    selectedTypes,
      lpn,
    startTimeStr,
    endTimeStr,
    size,
    page,
    fetchEvents,
  ]);



  return (
    <OnlineEventsTable
      userUseCases={userUseCases}
      isOnline={isOnline}
      parkings={parkings}
      isLoading={isLoading}
      events={events}
      startTimeStr={startTimeStr}
      endTimeStr={endTimeStr}
      hosts={hosts}
      handleOnline={handleOnline}
      handleStartDate={handleStartDate}
      handleEndDate={handleEndDate}
      filteredEvents={filteredEvents}
      parkingNames={parkingNames}
      subjectEventsPaging={subjectEventsPaging}
      subjectEvents={subjectEvents}
      eventTypes={eventTypes}
      selectedHosts={selectedHosts}
      selectedTypes={selectedTypes}
      selectedParkingNames={selectedParkingNames}
      selectParking={selectParking}
      handleSelectParkingNames={handleSelectParkingNames}
      handleSelectHosts={handleSelectHosts}
      handleCreateTask={handleCreateTask}
      handleSelectTypes={handleSelectTypes}
      handleHostNameClick={handleHostNameClick}
      handleCardNameClick={handleCardNameClick}
      handleLPChange={handleLPChange}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      simple={simple}
    />
  );
}

OnlineEvents.propTypes = {
  user: PropTypes.object.isRequired,
  selectParking: PropTypes.func.isRequired,
  handleTaskCreateOpen: PropTypes.func.isRequired,
  handleHostNameClick: PropTypes.func.isRequired,
  handleCardNameClick: PropTypes.func.isRequired,
  parkings: PropTypes.array.isRequired,
  allCodeList: PropTypes.array.isRequired,
  simple: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  subjectEvents: store.eventsData.subjectEvents,
  subjectEventsPaging: store.eventsData.subjectEventsPaging,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSubjectEvents,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OnlineEvents);

