import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Add from '@material-ui/icons/Add';

import {
  CustomDateTimePicker, CustomMultiSelect, CustomSearchInput,
} from 'components/elements';
import i18next from 'i18next';
import { getLocaleDateTimeString, getAMPM } from '../../../../i18n';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';
import { TextField } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: theme.spacing(4.5),
  },
  filter: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      width: 180,
      marginLeft: theme.spacing(4),
    },

    '& > div:first-child': {
      marginLeft: 0,
    },
  },
  toolbarInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  addButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    backgroundColor: theme.palette.base[100],
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1.125),
  },
  addIcon: {
    width: 30,
    height: 30,
  },
}));

function CardsToolbar(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    parking,
    cardType,
    validTill,
    validity,
    blocked,
    zone,
    parkingCardNumbers,
    handleValidTill,
    handleValidity,
    handleBlock,
    handleSearch,
    handleZone,
    handleCreateCardOpen,
    handleOwner,
    searchMenuItemClick,
    userUseCases,
  } = props;

  const validityStatus = [
    {
      value: 'valid',
      label: t('VALID'),
      key: 'valid',
    },
    {
      value: 'invalid',
      label: t('NON_VALID'),
      key: 'invalid',
    },
  ];

  const blockedStatus = [
    {
      value: 'blocked',
      label: t('YES'),
      key: 'blocked',
    },
    {
      value: 'unblocked',
      label: t('NO'),
      key: 'unblocked',
    },
  ];

  const handleAddCard = () => {
    handleCreateCardOpen({
      parkingId: parking.parkingId,
      subjectId: parking.subjectId,
      cardType,
    });
  };

  const searchMenuData = parkingCardNumbers.length > 0
    ? parkingCardNumbers.map((data) => ({
      key: data.cardNumber,
      label: data.cardNumber,
      value: data.cardNumber,
    }))
    : [];

  const zones = parking.zoneStatus || [];
  const zoneItems = zones.length > 0 ? zones.map((z) => ({
    id: z.zoneId,
    value: z.zoneId,
    label: z.zoneName,
    key: `zone_${z.zoneId}`,
  })) : [];

  return (
    <div className={classes.toolbar}>
      <div className={classes.filter}>
        {cardType==='LT' &&
          <TextField 
            label={t('OWNER')}
            onChange={handleOwner}
            InputProps={{
              backgroundColor: "red",
              style: { color: '#5985EE' }
            }}
            InputLabelProps={{
              style: { color: '#5985EE' },
            }}
          />
        }
        <CustomDateTimePicker
          label={t('VALID_TILL')}
          format={getLocaleDateTimeString(i18next.language)}
          ampm={getAMPM(i18next.language)}
          value={validTill}
          icon={<ArrowDropDown />}
          handleChange={handleValidTill}
        />
        <CustomMultiSelect
          items={validityStatus}
          label={t('VALIDITY')}
          value={validity}
          handleChange={handleValidity}
        />
        <CustomMultiSelect
          items={blockedStatus}
          label={t('BLOCKED')}
          value={blocked}
          handleChange={handleBlock}
        />
        <CustomMultiSelect
          items={zoneItems}
          label={t('ZONE')}
          value={zone}
          handleChange={handleZone}
        />
      </div>

      <div className={classes.toolbarInput}>
        <CustomSearchInput
          placeholder={t('CARD_NUMBER')}
          menuData={searchMenuData}
          menuItemClick={searchMenuItemClick}
          handleChange={handleSearch}
        />

        {cardType !== 'ST' && isComponentVisibleForUser(UC_CARD_CREATE, userUseCases) &&(
          <div className={classes.addButtonWrapper}>
            <IconButton
              className={classes.addButton}
              edge="start"
              color="inherit"
              aria-label="add task"
              onClick={handleAddCard}
              disabled={!isComponentEnabledForUser(UC_CARD_CREATE, userUseCases)}
            >
              <Add className={classes.addIcon} />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}

CardsToolbar.propTypes = {
  parking: PropTypes.object,
  cardType: PropTypes.string.isRequired,
  validTill: PropTypes.object,
  validity: PropTypes.array.isRequired,
  blocked: PropTypes.array.isRequired,
  zone: PropTypes.array.isRequired,
  parkingCardNumbers: PropTypes.array.isRequired,
  handleValidTill: PropTypes.func.isRequired,
  handleValidity: PropTypes.func.isRequired,
  handleBlock: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleZone: PropTypes.func.isRequired,
  handleCreateCardOpen: PropTypes.func.isRequired,
  searchMenuItemClick: PropTypes.func,
};

CardsToolbar.defaultProps = {
  parking: null,
  validTill: null,
  searchMenuItemClick: () => {},
};

const UC_CARD_CREATE = 'UC0027';

export default CardsToolbar;
