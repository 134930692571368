import React, {useEffect, useRef, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';

import {ActionButton} from 'components/common';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: 834,
            maxWidth: 'unset',
            height: 'fit-content',
            boxShadow: theme.palette.shadow.main,
        },
    },
    imageModalContainer: {
        '& .MuiDialog-paper': {
            maxWidth: '100%',
            maxHeight: '100%',
            justifyContent: 'center',
            boxShadow: theme.palette.shadow.main,
        },
    },
    imageModalContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        background: "#004980",
        paddingRight: theme.spacing(2),
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
            '& .MuiButtonBase-root': {
                color: theme.palette.base.white,
            },
            '& input + fieldset': {
                borderColor: theme.palette.base.white
            },
        },
    },
    formGroup: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    label: {
        width: theme.spacing(12),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    imageWrapper: {
        width: 400,
        height: 250,
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(4),
    },
    attachment: {
        // width: 800,
        width: '100%',
        // height: '100%',
        // maxWidth:'100%',
        // maxHeight:'100%',
        objectFit: 'contain',
    },
    content: {
        padding: theme.spacing(1, 0),
        flex: 1,
        borderBottom: `1px solid ${theme.palette.base[200]}`,
    },
    actions: {
        marginTop: theme.spacing(5),
        justifyContent: 'center',
    },
    cancel: {
        height: 40,
        marginLeft: theme.spacing(3.75),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        color: theme.palette.base.white,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.base.white,
    },
}));

function CameraDetail(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        opened,
        host,
        imageModal,
        handleClose,
        parkingHosts
    } = props;

    const [image, setImage] = useState(null);


    useEffect(() => {
        if (opened && host && parkingHosts) {
            const newHost = parkingHosts.find(a => a.parkingHostId === host.parkingHostId);
            setImage(newHost ? newHost.photoDetail : null);
        }
    }, [parkingHosts]);

    const getHostImage = (image) => {
        return <img src={`data:image/png;base64,${image}`} className={classes.attachment} alt=""/>
    }

    return (
        <Dialog
            className={imageModal ? classes.imageModalContainer : classes.container}
            open={opened}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle className={classes.title}>
                {`${t('HOST')} ${host.hostName}`}
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.imageModalContent} titleStyle={{textAlign: "center"}}>
                {image && getHostImage(image)}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <ActionButton
                    action="closeModal"
                    handleClick={handleClose}
                />
            </DialogActions>
        </Dialog>
    );
}

CameraDetail.propTypes = {
    opened: PropTypes.bool.isRequired,
    host: PropTypes.object.isRequired,
    image: PropTypes.string,
    imageModal: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
};

CameraDetail.defaultProps = {
    image: null,
    imageModal: false,
};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CameraDetail);
