import React, {
    useEffect, useState, useCallback, useRef,
} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import Typography from '@material-ui/core/Typography/Typography';
import {makeStyles} from '@material-ui/core/styles';

import {
    fetchAboutList,
} from 'redux/actions/about';
import {Loading} from 'components/elements';
import About from './About';
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    title: {
        marginBottom: theme.spacing(1),
        textTransform: 'uppercase',
    },
    container: {
        marginTop: theme.spacing(0),
    },
}));

function AboutManagement(props) {
    const classes = useStyles();

    const {
        history,
        user,
        isRO,
        fetchAboutList,
    } = props;

    const {t} = useTranslation();

    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    const [isFetchedAbout, setIsFetchedAbout] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const aboutList = useCallback(() => {
        if (user) {
            fetchAboutList()
                .then(() => {
                    if (!isUnMounted.current) {
                        setIsLoading(false);
                        setIsFetchedAbout(true);
                    }
                })
                .catch(() => {
                    if (!isUnMounted.current) {
                        setIsLoading(false);
                        setIsFetchedAbout(false);
                    }
                });
        }
    }, [fetchAboutList, user]);

    useEffect(() => {
        if (!isFetchedAbout) {
            fetchAboutList();
        }
    }, [fetchAboutList, isFetchedAbout, user]);

    if (!user) {
        return (
            <Loading/>
        );
    }

    if (isRO) {
        history.push(`/${process.env.REACT_APP_PATH}/parking`);
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>
                <Typography
                    variant="h4"
                    className={classes.title}
                >
                    {t('ABOUT_WINDOW')}
                </Typography>
                <About
                    user={user}
                    isLoading={isLoading}
                    aboutList={aboutList}
                    fetchAboutList={fetchAboutList}
                />
            </div>
        </PerfectScrollbar>
    );

}

AboutManagement.propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    isRO: PropTypes.bool.isRequired,
    aboutList: PropTypes.array.isRequired,
    fetchAboutList: PropTypes.func.isRequired,
};

AboutManagement.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    isRO: store.authData.isRO,
    aboutList: store.aboutData.aboutList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAboutList,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AboutManagement);
