import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCardData, getCards } from 'utils';
import CardsTable from './CardsTable';

function ParkingCardsContent(props) {
  const {
    parking,
    cards,
    isLoading,
    isActivating,
    isBlocking,
    validity,
    blocked,
    lpn,
    cardNumber,
    validTill,
    cardsPaging,
    handleCardDetailOpen,
    handleValidTill,
    handleValidity,
    handleFilterBlocked,
    handleChangeRowsPerPage,
    handleChangePage,
    userUseCases,
    selectedCard,
    setSelectedCard,
    handleLPN,
    handleCardNumber,
    parkings,
  } = props;

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const cardData = getCardData(cards);



  return (
    <CardsTable
      parkings={parkings}
      selectedCard={selectedCard}
      setSelectedCard={setSelectedCard}
      parking={parking}
      cardData={cardData}
      isLoading={isLoading}
      validity={validity}
      validTill={validTill}
      blocked={blocked}
      lpn={lpn}
      cardNumber={cardNumber}
      cardsPaging={cardsPaging}
      handleCardDetail={handleCardDetailOpen}
      handleValidTill={handleValidTill}
      handleLPN={handleLPN}
      handleCardNumber={handleCardNumber}
      handleValidity={handleValidity}
      handleFilterBlocked={handleFilterBlocked}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      userUseCases={userUseCases}
    />
  );
}

ParkingCardsContent.propTypes = {
  parking: PropTypes.object.isRequired,
  cardType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  cards: PropTypes.array.isRequired,
  isActivating: PropTypes.bool.isRequired,
  isBlocking: PropTypes.bool.isRequired,
  validity: PropTypes.array.isRequired,
  blocked: PropTypes.array.isRequired,
  validTill: PropTypes.object,
  zone: PropTypes.array.isRequired,
  cardsPaging: PropTypes.object.isRequired,
  parkingCardNumbers: PropTypes.object.isRequired,
  handleCardDetailOpen: PropTypes.func.isRequired,
  handleCreateCardOpen: PropTypes.func.isRequired,
  handleEditCardOpen: PropTypes.func.isRequired,
  handleBlockCardOpen: PropTypes.func.isRequired,
  handleConfirmCardOpen: PropTypes.func.isRequired,
  handleValidTill: PropTypes.func.isRequired,
  handleValidity: PropTypes.func.isRequired,
  handleFilterBlocked: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleZone: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

ParkingCardsContent.defaultProps = {
  validTill: null,
};

const mapStateToProps = (store) => ({
  isActivating: store.cardsData.isActivating,
  isBlocking: store.cardsData.isBlocking,
  parkingCardNumbers: store.cardsData.parkingCardNumbers,
});

export default connect(
  mapStateToProps,
)(ParkingCardsContent);
