import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { fetchParkingTasks, cancelTask, renewTask } from 'redux/actions/tasks';
import { fetchParkingUsers } from 'redux/actions/users';
import { getTaskHosts, getUsers } from 'utils';
import TasksTable from './TasksTable';

let filterParams = {
  createdById: [''],
  hostType: [''],
  state: [''],
  size: 5,
  page: 0,
  keyword: '',
};

function MobileOperatorTasks(props) {
  const { t } = useTranslation();

  const {
    parking,
    active,
    parkingTasks,
    tasksPaging,
    allCodeList,
    isCanceling,
    isRenewing,
    parkingUsers,
    handleTaskDetailOpen,
    handleCreateOpen,
    fetchParkingTasks,
    cancelTask,
    renewTask,
    fetchParkingUsers,
    userUseCases,
  } = props;
  console.warn("MobileOperatorTasks", active);

  const isUnMounted = useRef(false);

  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [isFetchedTasks, setIsFetchedTasks] = useState(false);
  const [isFetchedUsers, setIsFetchedUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsFetchedTasks(false);
  }, [parking.parkingId]);

  const fetchTasks = useCallback((
    filterUser,
    filterHosts,
    filterState,
    rowsPerPage,
    pageNumber,
    keyword,
  ) => {
    setIsLoading(true);
    fetchParkingTasks(
      parking.parkingId,
      {
        createdById: filterUser,
        hostType: filterHosts,
        state: filterState,
        size: rowsPerPage,
        page: pageNumber,
        keyword,
      },
    ).then(() => {
      if (!isUnMounted.current) {
        setIsLoading(false);

        if (
          JSON.stringify(filterParams.createdById) !== JSON.stringify(filterUser)
          || JSON.stringify(filterParams.hostType) !== JSON.stringify(filterHosts)
          || JSON.stringify(filterParams.state) !== JSON.stringify(filterState)
          || filterParams.size !== rowsPerPage
          || filterParams.page !== pageNumber
          || filterParams.keyword !== keyword
        ) {
          setIsFetchedTasks(false);
        } else {
          setIsFetchedTasks(true);
        }
      }
    }).catch(() => {
      if (!isUnMounted.current) {
        setIsLoading(false);
      }
    });
  }, [fetchParkingTasks, parking.parkingId]);

  const fetchUsers = useCallback(() => {
    if (parking) {
      fetchParkingUsers(parking.parkingId)
        .then(() => {
          if (!isUnMounted.current) {
            setIsFetchedUsers(true);
          }
        });
    }
  }, [fetchParkingUsers, parking.parkingId]);

  useEffect(() => {
    if (active) {
      setIsFetchedTasks(false);
      setIsFetchedUsers(false);
    } else {
      setIsFetchedTasks(true);
      setIsFetchedUsers(true);
    }
  }, [active]);

  useEffect(() => {
    if (!isFetchedUsers && active) {
      fetchUsers();
    }
  }, [active, isFetchedUsers, fetchUsers]);

  const taskHosts = getTaskHosts(allCodeList, t);
  const users = getUsers(parkingUsers);

  const [state, setState] = useState(['']);
  const [hosts, setHosts] = useState(['']);
  const [createdByName, setCreatedByName] = useState(['']);
  const [createdById, setCreatedById] = useState(['']);
  const [keyword, setKeyword] = useState(['']);
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(0);
  const resetPagination = () => {
    filterParams.page = 0;
    setPage(0);
  };
  const handleState = (value) => {
    filterParams.state = value;
    resetPagination();
    setIsFetchedTasks(false);
    setState(value);
  };
  const handleSelectHosts = (value) => {
    filterParams.hostType = value;
    resetPagination();
    setIsFetchedTasks(false);
    setHosts(value);
  };
  const handleUser = (valueSB) => {
    let usersId = [''];

    for ( let j=0; j<valueSB.length; j++) {
      for ( let i=0; i<users.length; i++) {
        if ( users[i].value === valueSB[j]) {
          usersId[j] = users[i].key;
          break;
        }
      }
    }

    filterParams.createdById = usersId;
    resetPagination();
    setIsFetchedTasks(false);
    setCreatedById(usersId);
    setCreatedByName(valueSB);
  };
  const handleChangeRowsPerPage = (value) => {
    filterParams.size = value;
    resetPagination();
    setIsFetchedTasks(false);
    setSize(value);
  };
  const handleChangePage = (value) => {
    filterParams.page = value;
    setIsFetchedTasks(false);
    setPage(value);
  };
  const handleSearch = (value) => {
    filterParams.keyword = value;
    resetPagination();
    setIsFetchedTasks(false);
    setKeyword(value);
  };
  const handleAddTask = () => {
    handleCreateOpen({
      parkingId: parking.parkingId,
      taskHosts,
    });
  };

  useEffect(() => {
    if (!isFetchedTasks && active) {
      fetchTasks(createdById, hosts, state, size, page, keyword);
    }
  }, [active, isFetchedTasks, createdById, hosts, state, size, page, keyword, fetchTasks]);

  const clearFilters = useCallback(() => {
    filterParams = {
      createdById: [''],
      hostType: [''],
      state: [''],
      user: '',
      page: 0,
      size: 5,
      keyword: '',
    };
    setState(['']);
    setHosts(['']);
    setCreatedByName(['']);
    setSize(5);
    setPage(0);
    setKeyword('');
  }, []);

  useEffect(() => {
    clearFilters();
  }, [parking.parkingId, clearFilters]);

  const handleCancel = (rowData) => () => {
    if (isCanceling) {
      return;
    }

    cancelTask(rowData)
      .then(() => {
        setIsFetchedTasks(false);
      });
  };

  const handleRenew = (rowData) => () => {
    if (isRenewing) {
      return;
    }

    renewTask(rowData)
      .then(() => {
        setIsFetchedTasks(false);
      });
  };

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      setIsFetchedTasks(false);
      setIsFetchedUsers(false);
    }, 30000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <TasksTable
      userUseCases={userUseCases}
      parking={parking}
      state={state}
      isLoading={isLoading}
      parkingTasks={parkingTasks}
      tasksPaging={tasksPaging}
      taskHosts={taskHosts}
      createdByName={createdByName}
      users={users}
      hosts={hosts}
      handleState={handleState}
      handleSelectHosts={handleSelectHosts}
      handleUser={handleUser}
      handleSearch={handleSearch}
      handleAddTask={handleAddTask}
      handleCancel={handleCancel}
      handleRenew={handleRenew}
      handleTaskDetailOpen={handleTaskDetailOpen}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
    />
  );
}

MobileOperatorTasks.propTypes = {
  parking: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  parkingTasks: PropTypes.array.isRequired,
  tasksPaging: PropTypes.object.isRequired,
  allCodeList: PropTypes.array.isRequired,
  isCanceling: PropTypes.bool.isRequired,
  isRenewing: PropTypes.bool.isRequired,
  parkingUsers: PropTypes.array.isRequired,
  handleTaskDetailOpen: PropTypes.func.isRequired,
  handleCreateOpen: PropTypes.func.isRequired,
  fetchParkingTasks: PropTypes.func.isRequired,
  cancelTask: PropTypes.func.isRequired,
  renewTask: PropTypes.func.isRequired,
  fetchParkingUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  parkingTasks: store.tasksData.parkingTasks,
  tasksPaging: store.tasksData.tasksPaging,
  allCodeList: store.codesData.allCodeList,
  taskImages: store.tasksData.taskImages,
  isCanceling: store.tasksData.isCanceling,
  isRenewing: store.tasksData.isRenewing,
  parkingUsers: store.usersData.parkingUsers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchParkingTasks,
  cancelTask,
  renewTask,
  fetchParkingUsers,
}, dispatch);


export default React.memo(connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileOperatorTasks), (o1, o2)=>{return o2.active===false});



