import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { KeycloakProvider } from 'react-keycloak';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from 'theme';
import 'react-perfect-scrollbar/dist/css/styles.css';

import store, { history } from './redux/store';
import {
  keycloak,
  keycloakInitConfig,
  onKeycloakEvent,
  onKeycloakTokens,
} from './services/keycloak.service';
import './i18n';
import AppWrapper from './AppMain';
import * as serviceWorker from './serviceWorker';

import './style.css';

ReactDOM.render(
  <KeycloakProvider
    keycloak={keycloak}
    initConfig={keycloakInitConfig}
    onEvent={onKeycloakEvent(store)}
    onTokens={onKeycloakTokens}
  >
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Theme>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <AppWrapper />
        </Theme>
      </ConnectedRouter>
    </Provider>
  </KeycloakProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
