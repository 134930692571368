import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from '@material-ui/core/styles';

import { CustomTable } from 'components/elements';
import { ActionButton, EventType } from 'components/common';
import {
  getCodeList, getEventDescription, getCardNumber, isCardEvent, isComponentVisibleForUser, isComponentEnabledForUser,
} from 'utils';
import i18next from 'i18next';
import EventsToolbar from 'components/common/ToolBar';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoDetail from 'components/modals/PhotoDetail';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
  },
  onlineContainer: {
    maxWidth: '100%',
    borderBottom: '2px solid #E0E0E0',

    '& .MuiTableFooter-root': {
      display: 'none !important',
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  toolbar: {
    width: '100%',
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: theme.spacing(4.5),
  },
  onlineSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  onlineSwitchLabel: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  toolbarInput: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  hostLink: {
    textDecoration: 'none',
    borderBottom: '1px dotted grey',
    color: '#1B1B28',
    cursor: 'pointer',
  },
  pointer: {
    cursor: "pointer",
  },
}));

const cellStyle = {
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: '19px',
  color: '#1B1B28',
  textAlign: 'center',
};

function EventsMainContent(props) {
  const classes = useStyles();

  const {
    isOnline,
    isLoading,
    parkingEvents,
    parkingSSEEvents,
    allCodeList,
    startTimeStr,
    endTimeStr,
    selectedHosts,
    selectedTypes,
    eventsPaging,
    handleCreateOpen,
    handleOnline,
    handleStartDate,
    handleEndDate,
    handleSelectHosts,
    handleSelectTypes,
    handleLPChange,
    handleSearch,
    handleChangeRowsPerPage,
    handleChangePage,
    handleHostClick,
    handleCardDetailOpen,
    userUseCases,
    handleOrderChange,
  } = props;

  const { t } = useTranslation();

  const getEventTypes = (allCodeList) => {
    const typeItems = getCodeList(allCodeList, 'EVENT_TYPE');
    return typeItems.length > 0 ? typeItems.map((item) => ({
      value: item.code,
      label: t(item.description),
      key: `event_type_${item.codeListItemId}`,
    })) : [];
  };
  const getEventHosts = (allCodeList) => {
    const hostItems = getCodeList(allCodeList, 'HOST_TYPE');
    return hostItems.length > 0 ? hostItems.map((item) => ({
      value: item.code,
      label: t(item.description),
      key: `host_type_${item.codeListItemId}`,
    })) : [];
  };
  const eventTypes = getEventTypes(allCodeList);
  const hosts = getEventHosts(allCodeList);

  const handleCreateTask = (rowData) => () => {
    handleCreateOpen(rowData);
  };

  const [showPhoto, setShowPhoto] = useState(false);
  const [rowData, setRowData] = useState(null);

  const hadlePhotoClick = (rowData) => () => {
    setShowPhoto(true);
    setRowData(rowData);
  }

  const columns = [
    {
      title: t('PARKING_HOST'),
      field: 'parkingHost',
      cellStyle,
      render: (rowData) => {
        return isComponentVisibleForUser(UC_HOST_DETAIL, userUseCases) ?
        <span
          className={classes.hostLink}
          title={t('OPEN_HOST_DETAIL')}
          onClick={
            (e) => {
              e.preventDefault();
              handleHostClick(rowData.parkingHost);
            }
          }
        >
          {rowData.parkingHost}
        </span> :    <>{rowData.parkingHost}</>
      },
    }, {
      title: t('LICENSE_PLATE'),
      field: 'lpn',
      cellStyle,
      render: (rowData) =>   <>{rowData.lpn}</>,
    }, {
      title: t('PHOTO'),
      field: 'photo',
      cellStyle,
      render: (rowData) =>   <>{((rowData.photoKeyPic && rowData.photoKeyPic!=='0000000000000000') || (rowData.photoKeyLpn && rowData.photoKeyLpn!=='0000000000000000')) &&
        <PhotoCameraIcon onClick={hadlePhotoClick(rowData)} className={classes.pointer}/>
      }</>,
    },
    {
      title: t('DATE'),
      field: 'date',
      cellStyle,
      render: (rowData) => (
        <>{rowData.date ? moment(new Date(rowData.date)).locale(i18next.language).format('L') : ''}</>
      ),
    },
    {
      title: t('TIME'),
      field: 'date',
      cellStyle,
      render: (rowData) => (
        <>{rowData.date ? moment(new Date(rowData.date)).locale(i18next.language).format('LTS') : ''}</>
      ),
    },
    {
      title: t('EVENT_DETAIL'),
      field: 'detail',
      cellStyle,
      render: (rowData) => (
        isCardEvent(rowData.description)
          ? (
            <span
              className={classes.hostLink}
              title={t('OPEN_CARD_DETAIL')}
              onClick={
                (e) => {
                  e.preventDefault();
                  handleCardDetailOpen({ parkingId: rowData.parkingId, cardNo: getCardNumber(rowData.messageParam), hostName: rowData.parkingHost});
                }
              }
            >
              <>{getEventDescription(t(rowData.message && rowData.message.startsWith('message.') ? rowData.message.substring(8, rowData.message.length) : rowData.message), rowData.messageParam)}</>
            </span>
          )
          : <>{getEventDescription(t(rowData.message && rowData.message.startsWith('message.') ? rowData.message.substring(8, rowData.message.length) : rowData.message), rowData.messageParam)}</>
      ),
    },
    {
      title: t('EVENT_TYPE'),
      field: 'eventType',
      cellStyle,
      render: (rowData) => {
        const descStrs = rowData.category.category.split('_');
        const type = descStrs[descStrs.length - 1].toLowerCase();
        return (
          <EventType type={type} border chipColor={rowData.category.color} />
        );
      },
    } ];

  if (isComponentVisibleForUser(UC_TASK_CREATE, userUseCases)) {
    columns.push({
      title: t('ACTION'),
      field: 'action',
      cellStyle,
      render: (rowData) => {
        return <ActionButton
            disabled={!isComponentEnabledForUser(UC_TASK_CREATE, userUseCases)}
            action="assign"
            handleClick={
              (rowData.action && rowData.action !== 'action')
                  ? null
                  : handleCreateTask(rowData)
            }
        />
      },
    });
  };

  const tableOptions = {
    headerStyle: {
      padding: '15px 8px 15px 34px',
      lineHeight: '12px',
      color: '#1B1B28',
      textAlign: 'center',
    },
  };

  return (
    <div className={isOnline ? classes.onlineContainer : classes.container}>
      {isComponentVisibleForUser(UC_OFFLINE_EVENTS, userUseCases) && <EventsToolbar
        disabled={!isComponentEnabledForUser(UC_OFFLINE_EVENTS, userUseCases)}
        isOnline={isOnline}
        hosts={hosts}
        selectedHosts={selectedHosts}
        eventTypes={eventTypes}
        selectedTypes={selectedTypes}
        startTimeStr={startTimeStr}
        endTimeStr={endTimeStr}
        handleOnline={handleOnline}
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        handleSelectHosts={handleSelectHosts}
        handleSelectTypes={handleSelectTypes}
        handleLPChange={handleLPChange}
        handleSearch={handleSearch}
      />}
      <CustomTable
        title=""
        columns={columns}
        data={isOnline ? parkingSSEEvents : parkingEvents}
        options={isOnline ? {
          ...tableOptions,
          pageSize: parkingSSEEvents.length === 0 ? 5 : parkingSSEEvents.length,
          maxBodyHeight: 343,
        } : tableOptions}
        isLoading={isLoading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        rowsPerPage={eventsPaging.pageSize}
        pageNumber={eventsPaging.currentPage}
        count={!isOnline ? eventsPaging.totalSize : parkingSSEEvents.length}
        loadAllData={!isOnline}
        handleOrderChange={handleOrderChange}
      />
      {showPhoto &&
        <PhotoDetail showPhoto={showPhoto} setShowPhoto={setShowPhoto} rowData={rowData} setRowData={setRowData}/>
      }
    </div>
  );
}

EventsMainContent.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  parkingEvents: PropTypes.array.isRequired,
  parkingSSEEvents: PropTypes.array.isRequired,
  selectedHosts: PropTypes.array.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  startTimeStr: PropTypes.string,
  endTimeStr: PropTypes.string,
  allCodeList: PropTypes.array.isRequired,
  eventsPaging: PropTypes.object.isRequired,
  handleCreateOpen: PropTypes.func.isRequired,
  handleOnline: PropTypes.func.isRequired,
  handleStartDate: PropTypes.func.isRequired,
  handleEndDate: PropTypes.func.isRequired,
  handleSelectHosts: PropTypes.func.isRequired,
  handleSelectTypes: PropTypes.func.isRequired,
  handleSearch: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleHostClick: PropTypes.func.isRequired,
  handleCardDetailOpen: PropTypes.func.isRequired,
};

EventsMainContent.defaultProps = {
  startTimeStr: null,
  endTimeStr: null,
  handleSearch: () => {},
};

const UC_HOST_DETAIL = 'UC0016';
const UC_OFFLINE_EVENTS = 'UC0017';
const UC_TASK_CREATE = 'UC0038';

export default EventsMainContent;
