import { ro } from 'date-fns/locale';
import rolesService from 'services/roles.service';
import types from '../actionTypes';


export const createRole = (role) => (dispatch, getState) => {
    if (getState().rolesData.isCreating) {
        return;
    }

    dispatch({
        type: types.ROLES_CREATE_REQUEST,
    });

    return rolesService.createRole(role)
        .then(() => {
            dispatch({
                type: types.ROLES_CREATE_SUCCESS,
                payload: {role},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ROLES_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateRole = (role) => (dispatch, getState) => {
    if (getState().rolesData.isUpdating) {
        return;
    }

    dispatch({
        type: types.ROLES_UPDATE_REQUEST,
    });

    return rolesService.updateRole(role)
        .then(() => {
            dispatch({
                type: types.ROLES_UPDATE_SUCCESS,
                payload: {role},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ROLES_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const activeRole = (roleId, active) => (dispatch, getState) => {
    if (getState().rolesData.isUpdating) {
        return;
    }

    dispatch({
        type: types.ROLES_ACTIVE_REQUEST,
    });

    return rolesService.activeRole(roleId, active)
        .then((role) => {
            dispatch({
                type: types.ROLES_ACTIVE_SUCCESS,
                payload: {role},
            });
            return role;
        })
        .catch((error) => {
            dispatch({
                type: types.ROLES_ACTIVE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllRoles = () => (dispatch, getState) => {
    if (getState().rolesData.isFetchingAllRolesList) {
        return Promise.reject();
    }

    dispatch({
        type: types.ROLES_FETCH_REQUEST,
    });

    return rolesService.getAllRoles()
        .then((roles) => {
            dispatch({
                type: types.ROLES_FETCH_SUCCESS,
                payload: { roles },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ROLES_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const addUserToRole = (userId, roleId) => (dispatch, getState) => {
    if (getState().rolesData.isUpdating) {
        return;
    }

    dispatch({
        type: types.ROLES_ADD_ROLE_REQUEST,
    });

    return rolesService.addUserToRole(userId, roleId)
        .then(() => {
            dispatch({
                type: types.ROLES_ADD_ROLE_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ROLES_ADD_ROLE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const removeUserToRole = (userId, roleId) => (dispatch, getState) => {
    if (getState().rolesData.isUpdating) {
        return;
    }

    dispatch({
        type: types.ROLES_REMOVE_ROLE_REQUEST,
    });

    return rolesService.removeUserToRole(userId, roleId)
        .then(() => {
            dispatch({
                type: types.ROLES_REMOVE_ROLE_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ROLES_REMOVE_ROLE_FAIL,
                payload: {error},
            });

            throw error;
        });
};