import types from '../actionTypes';
import posService from "../../services/pos.service";



export const fetchPosHistory = (id) => (dispatch, getState) => {

    if (getState().posData.isFetchingHistory) {
        return;
    }

    dispatch({
        type: types.POS_HISTORY_FETCH_REQUEST,
    });

    return posService.getPosHistory(id)
        .then((posHistory) => {
            dispatch({
                type: types.POS_HISTORY_FETCH_SUCCESS,
                payload: {posHistory},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.POS_HISTORY_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};



export const createPos= (pos) => (dispatch, getState) => {
    if (getState().posData.isCreating) {
        return;
    }

    dispatch({
        type: types.POS_CREATE_REQUEST,
    });

    return posService.createPos(pos)
        .then(() => {
            dispatch({
                type: types.POS_CREATE_SUCCESS,
                payload: {pos},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.POS_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updatePos = (pos) => (dispatch, getState) => {
    if (getState().posData.isUpdating) {
        return;
    }

    dispatch({
        type: types.POS_UPDATE_REQUEST,
    });

    return posService.updatePos(pos)
        .then(() => {
            dispatch({
                type: types.POS_UPDATE_SUCCESS,
                payload: {pos},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.POS_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const blacklist = (pos) => (dispatch, getState) => {
    if (getState().posData.isUpdating) {
        return;
    }

    dispatch({
        type: types.POS_UPDATE_REQUEST,
    });

    return posService.blacklist(pos)
        .then(() => {
            dispatch({
                type: types.POS_UPDATE_SUCCESS,
                payload: {pos},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.POS_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const fetchAllPos = () => (dispatch, getState) => {
    if (getState().posData.isFetchingAllList) {
        return;
    }

    dispatch({
        type: types.POS_FETCH_REQUEST,
    });

    return posService.getAllPos()
        .then((pos) => {
            dispatch({
                type: types.POS_FETCH_SUCCESS,
                payload: {pos},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.POS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


