import usecasesService from 'services/usecases.service';
import types from '../actionTypes';


export const fetchAllAdminUseCases = () => (dispatch, getState) => {
    if (getState().useCasesData.isFetchingAllAdminUseCases) {
        return Promise.reject();
    }

    dispatch({
        type: types.USECASE_FETCH_REQUEST,
    });

    return usecasesService.fetchAllAdminUseCases()
        .then((useCases) => {
            dispatch({
                type: types.USECASE_FETCH_SUCCESS,
                payload: {useCases},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_USERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};