import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CustomButton } from 'components/elements';
import { capitalize } from 'utils';
import { getCommandProps } from './Utils';

/**
 * @return {null}
 */
function HostActionButton(props) {
  const { t } = useTranslation();

  const {
    className,
    action,
    icon,
    handleClick,
    disabled,
  } = props;

  if (action === '') {
    return null;
  }

  return (
    <CustomButton
      className={className}
      label={capitalize(action)}
      {...getCommandProps(action, t)}
      icon={icon}
      onClick={handleClick}
      disabled={disabled}
      size="medium"
    />
  );
}

HostActionButton.propTypes = {
  className: PropTypes.string,
  action: PropTypes.string,
  icon: PropTypes.array,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

HostActionButton.defaultProps = {
  className: '',
  action: 'open',
  handleClick: null,
  disabled: false,
  icon: [],
};

export default HostActionButton;
