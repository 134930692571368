import journalsService from 'services/journals.service';
import types from '../actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchCardJournals = (cardId) => (dispatch, getState) => {
  if (getState().journalsData.isFetchingCardJournalList) {
    return;
  }

  dispatch({
    type: types.CARD_JOURNALS_FETCH_REQUEST,
  });

  return journalsService.getCardJournals(cardId)
    .then((cardJournals) => {
      dispatch({
        type: types.CARD_JOURNALS_FETCH_SUCCESS,
        payload: { cardJournals },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.CARD_JOURNALS_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};
