import httpService from './http.service';

const getAbout = () => httpService
  .get(`/about`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getAbout,
};
