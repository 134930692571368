import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import {CustomButton} from 'components/elements';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: 600,
            maxWidth: 'unset',
            height: 'fit-content',
            padding: theme.spacing(4.25, 3.25),
            boxShadow: theme.palette.shadow.main,
        },
    },
    title: {
        marginBottom: theme.spacing(4.25),
        color: theme.palette.base[500],

        '& .MuiTypography-root': {
            fontSize: 24,
        },
    },
    label: {
        width: '100%',
        color: '#A2A2A2',
    },
    actions: {
        marginTop: theme.spacing(5),
        justifyContent: 'center',
        textTransform: 'uppercase',
    },
    cancel: {
        height: 40,
        marginLeft: theme.spacing(3.75),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        color: theme.palette.primary.red,
        textTransform: 'uppercase',
    },
}));

function Error(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        paymentError,
        opened,
        handleClose,
        submit,
    } = props;

    const handleSubmit = () => {
        submit();
    };

    return (
        <Dialog
            className={classes.container}
            open={opened}
            onClose={handleClose}
        >
            <DialogTitle className={classes.title}>
                {t('PAYMENT_ERROR')}
            </DialogTitle>

            <DialogContent>
                <Typography className={classes.label}>
                    {paymentError ? paymentError.message : ''}
                </Typography>
            </DialogContent>

            <DialogActions className={classes.actions}>
                <Button
                    className={classes.cancel}
                    onClick={submit}
                >
                    {t('REMOVE')}
                </Button>
                <Button
                    className={classes.cancel}
                    onClick={handleClose}
                >
                    {t('CANCEL')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

Error.propTypes = {
    paymentError: PropTypes.object.isRequired,
};

Error.defaultProps = {
    paymentError: null,
};

export default Error;
