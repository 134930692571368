/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

import Badge from '@material-ui/core/Badge';
import {getIcon} from './Utils';
import {getHostLayout, getGridSize, getQueryVariable} from '../../../../utils';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    hostTooltipEmpty: {
        width: props => `calc(${100 / props.columnI}% - 10px)`,
        height: 120,
        flexGrow: 1,
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.base.white,
    },
    hostTooltipCard: {
        height: 120,
        width: props => `calc(${100 / props.columnI}% - 10px)`,
        flexGrow: 1,
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        // background: theme.palette.base.white,
        borderStyle: 'solid',
        borderWidth: '1px',
        // borderColor: theme.palette.base.white,
        cursor: 'pointer',
        opacity: '0.9',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    hostTooltipCardGreen: {
        borderColor: theme.palette.icon.green,
    },
    hostTooltipCardRed: {
        borderColor: theme.palette.icon.red,
    },
    hostTooltipCardYellow: {
        borderColor: theme.palette.icon.yellow,
    },

    hostBorderCardGreen: {
        background: theme.palette.icon.green3,
    },
    hostBorderCardRed: {
        background: theme.palette.icon.red3,
    },
    hostBorderCardYellow: {
        background: theme.palette.icon.yellow3,
    },


    image: {
        maxHeight: '50%',
    },
    hostTooltipCardActive: {
        borderWidth: '5px',
        opacity: '1',
        // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    },
    shortName: {
        height: 24,
        fontSize: 22,
        marginLeft: 3,
        marginRight: 3,
        width: '100%',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));


function HostDetails(props) {


    const {
        parkingId,
        parkingHosts,
        handleSelectChange,
        selectedHost,
        selectedHostName,
        calls,
    } = props;


    const [gridSize, setGridSize] = useState(getGridSize(parkingHosts));
    const classes = useStyles({columnI: gridSize ? gridSize.maxColumn : 5});
    const [currentParking, setCurrentParking] = useState(null);


    const [isQueryParamSet, setIsQueryParamSet] = useState(false);

    const [missedCalls, setMissedCalls] = useState({});
    const [layout, setLayout] = useState([]);
    useEffect(() => {
        setLayout(getHostLayout(parkingHosts, {column: gridSize.maxColumn, row: gridSize.maxRow}));

    }, [parkingHosts]);

    const getFirstHost = () => {
        for (let i = 0; i < layout.length; i++) {
            const hostItem = !layout[i].id.startsWith('empty');
            if (hostItem) {
                return parkingHosts.find(a => a.parkingHostId == layout[i].id);
            }
        }
    }


    useEffect(() => {
        if (calls && parkingHosts) {
            const missedCalls = {};
            for (let i = 0; i < parkingHosts.length; i++) {
                for (let j = 0; j < calls.length; j++) {
                    if (calls[j].missed && parkingHosts[i].sipUserName === calls[j].number) {
                        const missedCallsForHost = missedCalls[parkingHosts[i].parkingHostId];
                        if (missedCallsForHost) {
                            missedCalls[parkingHosts[i].parkingHostId] = missedCallsForHost + 1;
                        } else {
                            missedCalls[parkingHosts[i].parkingHostId] = 1;
                        }
                    }
                }
            }
            setMissedCalls(missedCalls);
        }
    }, [parkingHosts, calls]);

    useEffect(() => {
        if (!isQueryParamSet && layout.length > 0 && parkingHosts.length > 0) {
            const defaultHostName = getQueryVariable('defaultHostName');
            const defaultParkingId = getQueryVariable('defaultParkingId');
            if (defaultHostName && defaultParkingId) {
                let defaultHost = parkingHosts.find(a => a.parkingHostName == defaultHostName && a.parkingId == defaultParkingId);
                if (defaultHost) {
                    handleSelectChange(defaultHost);
                    setCurrentParking(defaultParkingId);
                }
            } else {
                const defaultHost = getFirstHost();
                if (!selectedHostName) {
                    if (defaultHost) {
                        handleSelectChange(defaultHost);
                    }
                }
                setCurrentParking(parkingId);
            }
            setIsQueryParamSet(true);
        }
    }, [isQueryParamSet, handleSelectChange, selectedHostName, layout, currentParking, parkingId]);

    useEffect(() => {
        if (!selectedHostName) {
            const host = getFirstHost();
            if (host) {
                handleSelectChange(host);
            }
        }
    }, [selectedHostName, parkingHosts]);

    return (
        <div className={classes.container}>
            {layout.map((item, index) => {
                const host = parkingHosts.find(a => a.parkingHostId == item.id);
                return host ? (

                    <div
                        onClick={() => handleSelectChange(host)}
                        className={`
                  ${classes.hostTooltipCard}
                  ${(selectedHost && selectedHost.parkingHostId === host.parkingHostId) && classes.hostTooltipCardActive}
                  ${(selectedHost && selectedHost.parkingHostId === host.parkingHostId) && (host.status === 'STATUS_GREEN') && classes.hostBorderCardGreen}
                  ${(selectedHost && selectedHost.parkingHostId === host.parkingHostId) && (host.status === 'STATUS_RED') && classes.hostBorderCardRed}
                  ${(selectedHost && selectedHost.parkingHostId === host.parkingHostId) && (host.status === 'STATUS_YELLOW') && classes.hostBorderCardYellow}
                  ${(host.status === 'STATUS_GREEN') && classes.hostTooltipCardGreen}
                  ${(host.status === 'STATUS_RED') && classes.hostTooltipCardRed}
                  ${(host.status === 'STATUS_YELLOW') && classes.hostTooltipCardYellow}
                `}
                        key={`zone_tooltip_image_${index}`}
                    >
                        <Badge badgeContent={missedCalls[host.parkingHostId]} color="error"/>
                        <img
                            src={getIcon(host.iconName)}
                            className={classes.image}
                            alt={host.hostName}
                            title={host.hostName}
                        />
                        <div className={classes.shortName}>{host.hostShortName}</div>
                    </div>
                ) : <div className={classes.hostTooltipEmpty}/>
            })}
        </div>
    );
}

HostDetails.propTypes = {
    parkingId: PropTypes.number,
    parkingHosts: PropTypes.array,
    handleSelectChange: PropTypes.func,
    selectedHost: PropTypes.object,
    selectedHostName: PropTypes.string,
};

HostDetails.defaultProps = {
    parkingId: -1,
    parkingHosts: [],
    handleSelectChange: null,
    selectedHost: null,
    selectedHostName: null,
};

export default HostDetails;
