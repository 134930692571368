import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    isUpdating: false,
    isRemoving: false,

    mobileDevices: [],

    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.MOBILE_DEVICES_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        case types.MOBILE_DEVICES_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                mobileDevices: action.payload.mobileDevices,
            };
        case types.MOBILE_DEVICES_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };

        case types.UPDATE_MOBILE_DEVICE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case types.UPDATE_MOBILE_DEVICE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                mobileDevices: action.payload.mobileDevices,
            };
        case types.UPDATE_MOBILE_DEVICE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };

        case types.UPDATE_MULTIPLE_DEVICES_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case types.UPDATE_MULTIPLE_DEVICES_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                mobileDevices: action.payload.mobileDevices,
            };
        case types.UPDATE_MULTIPLE_DEVICES_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };

        case types.DELETE_MOBILE_DEVICE_REQUEST:
            return {
                ...state,
                isRemoving: true,
            };
        case types.DELETE_MOBILE_DEVICE_SUCCESS:
            return {
                ...state,
                isRemoving: false,
                mobileDevices: action.payload.mobileDevices,
            };
        case types.DELETE_MOBILE_DEVICE_FAIL:
            return {
                ...state,
                isRemoving: false,
                errors: action.payload.error,
            };

        default:
            return state;
    }
};
