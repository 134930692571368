import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingParkingList: false,
  isFetchingParkingAssignedUserList: false,
  isFetchingSubjectUsers: false,
  isAddingParkingToUser: false,
  isRemovingParkingFromUser: false,
  isFetchingRoleUser: false,
  isCreating: false,
  isUpdating: false,

  subjectUsers: [],
  parkingUsers: [],
  parkingAssignedUsers: [],
  parkingNotAssignedUsers: [],
  searchedUsers: [],
  roleUsers: [],
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARKING_USERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingList: true,
      };
    case types.PARKING_USERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingList: false,
        parkingUsers: action.payload.parkingUsers,
      };
    case types.PARKING_USERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingList: false,
        errors: action.payload.error,
      };
    case types.PARKING_ROLE_FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetchingRoleUser: true,
      };
    case types.PARKING_ROLE_FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetchingRoleUser: false,
        roleUsers: action.payload.roleUsers,
      };
    case types.PARKING_ROLE_FETCH_ALL_FAIL:
      return {
        ...state,
        isFetchingRoleUser: false,
        errors: action.payload.error,
      };
    case types.SEARCH_USERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingList: true,
      };
    case types.SEARCH_USERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingList: false,
        searchedUsers: action.payload.searchedUsers,
      };
    case types.SEARCH_USERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingList: false,
        errors: action.payload.error,
      };
    case types.PARKING_ASSIGNED_USERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingAssignedUserList: true,
      };
    case types.PARKING_ASSIGNED_USERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingAssignedUserList: false,
        parkingAssignedUsers: action.payload.parkingAssignedUsers,
      };
    case types.PARKING_ASSIGNED_USERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingAssignedUserList: false,
        errors: action.payload.error,
      };
    case types.PARKING_SUBJECT_USERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingSubjectUsers: true,
      };
    case types.PARKING_SUBJECT_USERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingSubjectUsers: false,
        parkingUsers: action.payload.parkingUsers,
      };
    case types.PARKING_SUBJECT_USERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingSubjectUsers: false,
        errors: action.payload.error,
      };

    case types.PARKING_USERS_FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetchingSubjectUsers: true,
      };
    case types.PARKING_USERS_FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetchingSubjectUsers: false,
        parkingUsers: action.payload.parkingUsers,
      };
    case types.PARKING_SUBJECT_FETCH_ALL_FAIL:
      return {
        ...state,
        isFetchingSubjectUsers: false,
        errors: action.payload.error,
      };
    case types.PARKING_NOT_ASSIGNED_USERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingNotAssignedUserList: true,
      };
    case types.PARKING_NOT_ASSIGNED_USERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingNotAssignedUserList: false,
        parkingNotAssignedUsers: action.payload.parkingNotAssignedUsers,
      };
    case types.PARKING_NOT_ASSIGNED_USERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingNotAssignedUserList: false,
        errors: action.payload.error,
      };

    case types.ADD_PARKING_TO_USER_REQUEST:
      return {
        ...state,
        isAddingParkingToUser: true,
      };
    case types.ADD_PARKING_TO_USER_SUCCESS:
      const user = state.parkingNotAssignedUsers.find((u) => u.userId === action.payload.userId);
      return {
        ...state,
        isAddingParkingToUser: false,
        parkingNotAssignedUsers: state.parkingNotAssignedUsers.filter((u) => u.userId !== action.payload.userId),
        parkingAssignedUsers: [user, ...state.parkingAssignedUsers],
      };
    case types.ADD_PARKING_TO_USER_FAIL:
      return {
        ...state,
        isAddingParkingToUser: false,
        errors: action.payload.error,
      };

    case types.REMOVE_PARKING_FROM_USER_REQUEST:
      return {
        ...state,
        isRemovingParkingFromUser: true,
      };
    case types.REMOVE_PARKING_FROM_USER_SUCCESS:
      const userToRemove = state.parkingAssignedUsers.find((u) => u.userId === action.payload.userId);
      return {
        ...state,
        isRemovingParkingFromUser: false,
        parkingAssignedUsers: state.parkingAssignedUsers.filter((u) => u.userId !== action.payload.userId),
        parkingNotAssignedUsers: [userToRemove, ...state.parkingNotAssignedUsers],
      };
    case types.REMOVE_PARKING_FROM_USER_FAIL:
      return {
        ...state,
        isRemovingParkingFromUser: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
