import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Box from '@material-ui/core/Box';

import RichTextEditor from 'react-rte';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from "@material-ui/core/TextField";
import ColorPicker from "material-ui-color-picker";
import Typography from "@material-ui/core/Typography";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import FormHelperText from "@material-ui/core/FormHelperText";
import CustomDateTimePicker from "../../elements/CustomDateTimePicker";
import {ArrowBackOutlined, ArrowForwardOutlined} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import SubjectsList from '../SubjectsList';
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import BranchesList from "../BranchesList";
import {isComponentVisibleForUser} from "../../../utils";
import {CustomSwitch} from "../../elements";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getLocaleDateTimeString } from 'i18n';
import i18next from 'i18next';


const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
    ]
};

const filter = createFilterOptions();


const useStyles = makeStyles((theme) => ({

    editor: {
        height: '100%',
        width: '100%',
        marginTop: '4px',
    },
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    description: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.06px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    icon: {
        height: '20px',
        width: '20px',
    },
    iconButton: {
        backgroundColor: theme.palette.base[100],
        padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
        marginBottom: '10px',
        marginRight: '5px',
    },
    root: {
        alignItems: 'flex-start',
    }
}));

function WizardStep1(props) {
    const classes = useStyles();

    const {
        userUseCases,
        handleChange,
        updatedProduct,
        getProductSystemParam,
        productTemplatesValues,
        subjectValues,
        branchValues,
        parkingsValues,
        rolesValues,
        productGroupValues,
        description,
        error,
        isValid,
        setProductSystemParam,
        handleAddProductGroup,
        handleRoles,
        handleParking,
        subjects,
        mode,
        setMode,
        handleSubject,
        handleBranch,
        readOnly,
        productType,
        handleMacroParkings,
        getSubject,
        setSnackbarError,
        setSnackbarOpen,
    } = props;

    const {t} = useTranslation();


    const getBranch = (productBranch, branches) => {
        if (productBranch) {
            const p = branches && branches.find(b => b.value == productBranch);
            if (!p) {
                return {label: 'Not Found', value: productBranch};
            }
            return p;
        }
        return null;
    }


    return (
        <div className={classes.formFields}>
            <form autoComplete='off'>
                <Box mb={2} height="100%">
                    {mode === 'COMMON' && <Grid container alignItems="stretch" style={{height: '100%'}}>
                        <Grid container alignItems="stretch" spacing={1} sm={6} style={{height: '100%'}}>
                            <Grid item sm={6}>
                                <FormControl required fullWidth>
                                    <InputLabel id="product-type-label">
                                        {t('PRODUCT_TYPE')}
                                    </InputLabel>
                                    <Select
                                        disabled={readOnly}
                                        autoWidth
                                        value={updatedProduct && updatedProduct.productTemplateId || ''}
                                        id="productType"
                                        onChange={handleChange('productTemplateId')}
                                    >
                                        {productTemplatesValues.map(a => {
                                            return (
                                                <MenuItem value={a.value}>{a.label}</MenuItem>
                                            )
                                        })
                                        }
                                    </Select>
                                    <FormHelperText>  {error && error.productTemplateId && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs sm={6}>
                                <TextField
                                    helperText={error && error.name
                                        ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="name"
                                    value={getProductSystemParam('name', 'valueVarchar') || ''}
                                    onChange={(value) => setProductSystemParam('name', 'valueVarchar', 'PRODUCT_SYSTEM_TYPE_TEXT', value.target.value)}
                                    label={t('NAME')}/>
                            </Grid>
                            <Grid item sm={12}>
                                {productType !== 'MP' && <Autocomplete
                                    value={getSubject(updatedProduct.subjectId, subjectValues)}
                                    disabled={readOnly}
                                    disableClearable
                                    onChange={(event, newValue) => {
                                        handleSubject(newValue);
                                    }}
                                    forcePopupIcon={false}
                                    filterSelectedOptions
                                    options={subjectValues}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('SUBJECT')} required fullWidth
                                                   helperText={error && error.subjectId
                                                       ? <span
                                                           className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                       : null}
                                                   InputProps={{
                                                       ...params.InputProps,
                                                       endAdornment: (
                                                           <InputAdornment position="end">
                                                               {isComponentVisibleForUser(UC_MENU_SUBJECT_SEARCH, userUseCases) &&
                                                               <IconButton onClick={() => {
                                                                   setMode('SUBJECT')
                                                               }} className={classes.iconButton}>
                                                                   <ArrowForwardOutlined className={classes.icon}/>
                                                               </IconButton>}
                                                           </InputAdornment>
                                                       ),
                                                   }}

                                        />
                                    )}
                                    size="small"

                                />}
                            </Grid>

                            <Grid item sm={12}>
                                <Autocomplete
                                    value={getBranch(updatedProduct.branchId, branchValues)}
                                    onChange={(event, newValue) => {
                                        handleBranch(newValue);
                                    }}
                                    forcePopupIcon={false}
                                    disableClearable
                                    filterSelectedOptions
                                    disabled={readOnly}
                                    options={branchValues}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('BRANCH')} required fullWidth
                                                   helperText={error && error.branchId
                                                       ? <span
                                                           className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                       : null}
                                                   InputProps={{
                                                       ...params.InputProps,
                                                       endAdornment: (
                                                           <InputAdornment position="end">
                                                               {isComponentVisibleForUser(UC_MENU_BRANCH_SEARCH, userUseCases) &&
                                                               <IconButton onClick={() => {
                                                                   setMode('BRANCH')
                                                               }} className={classes.iconButton}>
                                                                   <ArrowForwardOutlined className={classes.icon}/>
                                                               </IconButton>}
                                                           </InputAdornment>
                                                       ),
                                                   }}

                                        />
                                    )}
                                    size="small"

                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomDateTimePicker
                                    pure={true}
                                    disabled={readOnly}
                                    required={true}
                                    handleChange={handleChange('dateFrom')}
                                    fullWidth={true}
                                    value={updatedProduct && updatedProduct.dateFrom || null}
                                    label={t('VALID_FROM')}
                                    helperText={error && error.dateFrom
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                        format={getLocaleDateTimeString(i18next.language)}
                                >
                                </CustomDateTimePicker>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomDateTimePicker
                                    pure={true}
                                    disabled={readOnly}
                                    handleChange={handleChange('dateTo')}
                                    fullWidth={true}
                                    value={updatedProduct && updatedProduct.dateTo || null}
                                    label={t('VALID_TO')}
                                    format={getLocaleDateTimeString(i18next.language)}
                                >
                                </CustomDateTimePicker>
                            </Grid>
                            <Grid item sm={6}>
                                {productType === 'MP' ?
                                    <Autocomplete
                                        multiple
                                        value={updatedProduct && updatedProduct.productParkingsSubjects ? updatedProduct.productParkingsSubjects.map(a => {
                                                const p = parkingsValues && parkingsValues.find(b => b.value === a.parkingId);
                                                if (p) {
                                                    return p;
                                                } else {
                                                    return {value: a, label: 'NOT FOUND'}
                                                }
                                            }
                                        ) : []}
                                        onChange={(event, newValue) => {
                                            handleMacroParkings(newValue);
                                        }}
                                        getOptionSelected={(option, value) => option.value === value.value}
                                        filterSelectedOptions
                                        id="parkingId"
                                        options={parkingsValues}
                                        getOptionLabel={(option) => {
                                            return option.label;
                                        }}
                                        style={{width: '100%'}}
                                        renderInput={(params) => (
                                            <TextField {...params} label={t('PARKING')} required fullWidth
                                                       helperText={error && error.parkingId
                                                           ? <span
                                                               className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                           : null}

                                            />
                                        )}
                                        size="small"

                                    /> :

                                    <FormControl required fullWidth>
                                        <InputLabel id="parking-label">
                                            {t('PARKING')}
                                        </InputLabel>
                                        <Select
                                            autoWidth
                                            disabled={readOnly}
                                            value={(updatedProduct && (updatedProduct.parkingId || updatedProduct.parkingId == 0)) ? updatedProduct.parkingId : ''}
                                            id="parkingId"
                                            onChange={handleChange('parkingId')}
                                        >
                                            {parkingsValues.map(a => {
                                                return (
                                                    <MenuItem value={a.value}>{a.label}</MenuItem>
                                                )
                                            })
                                            }
                                        </Select>
                                        <FormHelperText>  {error && error.parkingId && (
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        )}</FormHelperText>
                                    </FormControl>}

                            </Grid>
                            <Grid item sm={2}>
                                <ColorPicker
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    floatingLabelText={t('COLOR')}
                                    name='color'
                                    defaultValue={updatedProduct && updatedProduct.color || '#5985EE'}
                                    value={updatedProduct && updatedProduct.color || '#5985EE'}
                                    onChange={handleChange('color')}
                                />

                            </Grid>
                            <Grid item sm={2}>
                                <TextField
                                    fullWidth id="row"
                                    disabled={readOnly}
                                    type='number'
                                    label={t('ROW')}
                                    value={updatedProduct && updatedProduct.row || ''}
                                    onChange={handleChange('row')}
                                />

                            </Grid>
                            <Grid item sm={2}>
                                <TextField
                                    fullWidth id="column"
                                    disabled={readOnly}
                                    type='number'
                                    label={t('COLUMN')}
                                    value={updatedProduct && updatedProduct.column || ''}
                                    onChange={handleChange('column')}
                                />

                            </Grid>

                            <Grid item sm={6}>
                                <Autocomplete
                                    inputProps={{
                                        autoComplete: 'off'
                                    }}
                                    value={updatedProduct && updatedProduct.productGroupName ? {
                                        value: updatedProduct.productGroupId,
                                        label: updatedProduct.productGroupName
                                    } : null}
                                    disabled={readOnly}
                                    onChange={(event, newValue) => {
                                        handleAddProductGroup(newValue);
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                label: `Add "${params.inputValue}"`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    options={productGroupValues}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }

                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }

                                        // Regular option
                                        return option.label;
                                    }}
                                    renderOption={(option) => option.label}
                                    freeSolo
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                                   type='text'
                                                   label={t('PRODUCT_GROUP')}
                                                   required
                                                   fullWidth
                                                   helperText={error && error.productGroupId
                                                       ? <span
                                                           className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                       : null}

                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item sm={3}>

                                <TextField fullWidth id="readOrder"
                                           type='number'
                                           disabled={readOnly}
                                           value={getProductSystemParam('readOrder', 'valueVarchar')}
                                           onChange={(value) => setProductSystemParam('readOrder', 'valueVarchar', 'PRODUCT_SYSTEM_TYPE_TEXT', value.target.value)}
                                           label={t('READ_ORDER')}/>
                            </Grid>
                            <Grid item sm={3}>
                                <FormControlLabel
                                    classes={{
                                        labelPlacementTop: classes.labelPlacementTop,
                                        root: classes.root,
                                    }}
                                    control={<CustomSwitch
                                        disabled={readOnly}
                                        checked={getProductSystemParam('readerOrderContinue', 'valueBoolean')}
                                        color="secondary"
                                        handleSwitch={(value) => setProductSystemParam('readerOrderContinue', 'valueBoolean', 'PRODUCT_SYSTEM_TYPE_BOOLEAN', value)}/>
                                    }
                                    label={<span
                                        style={{
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            color: 'rgba(0, 0, 0, 0.54)',
                                        }}>{t('READ_ORDER_CONTINUE')}</span>}
                                    labelPlacement="top"
                                />
                            </Grid>


                            <Grid item sm={6}>
                                <Autocomplete
                                    multiple disableCloseOnSelect
                                    limitTags={3}
                                    value={updatedProduct && updatedProduct.rolesId ? updatedProduct.rolesId.map(a => {
                                            const p = rolesValues && rolesValues.find(b => b.value === a);
                                            if (p) {
                                                return p;
                                            } else {
                                                return {value: a, label: 'NOT FOUND'}
                                            }
                                        }
                                    ) : []}
                                    onChange={(event, newValue) => {
                                        handleRoles(newValue);
                                    }}
                                    filterSelectedOptions
                                    id="roles"
                                    options={rolesValues}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('ROLE')} required fullWidth
                                                   helperText={error && error.rolesId
                                                       ? <span
                                                           className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                       : null}

                                        />
                                    )}
                                    size="small"

                                />
                            </Grid>


                            <Grid item sm={6}>

                                <TextField fullWidth id="('account', "
                                           value={getProductSystemParam('account', 'valueVarchar')}
                                           onChange={(value) => setProductSystemParam('account', 'valueVarchar', 'PRODUCT_SYSTEM_TYPE_TEXT', value.target.value)}
                                           label={t('ACCOUNT')}/>
                            </Grid>

                        </Grid>
                        <Grid item sm={6}>
                            <div className={classes.editorWrapper}>
                                <Typography variant="button" className={classes.description}>
                                    {t('DESCRIPTION')}
                                </Typography>
                                <RichTextEditor
                                    toolbarConfig={toolbarConfig}
                                    value={description}
                                    onChange={handleChange('description')}
                                    className={classes.editor}

                                />
                            </div>
                        </Grid>
                    </Grid>}
                </Box>
            </form>
            {mode !== 'COMMON' &&
            <IconButton onClick={() => {
                setMode('COMMON')
            }} className={classes.iconButton}>
                <ArrowBackOutlined className={classes.icon}/>
            </IconButton>
            }
            {mode === 'SUBJECT' && <SubjectsList
                userUseCases={userUseCases}
                setSnackbarError={setSnackbarError}
                setSnackbarOpen={setSnackbarOpen}
                setMode={setMode} handleSubjectChange={handleSubject}/>}
            {mode === 'BRANCH' && <BranchesList
                userUseCases={userUseCases}
                setSnackbarError={setSnackbarError}
                setSnackbarOpen={setSnackbarOpen}
                setMode={setMode} handleBranchChange={handleBranch}/>}

        </div>
    )
}

WizardStep1.propTypes = {};

WizardStep1.defaultProps = {};

const UC_MENU_BRANCH_SEARCH = 'UC0250';
const UC_MENU_SUBJECT_SEARCH = 'UC0200';


const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep1);


