import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {CustomTable} from 'components/elements';

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: '100%',
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const cellStyle = (data, rowData, items, isBold) => {
    const isLast = items && rowData.tableData.id === (items.length - 1);
    return {
        color: isLast ? '#ffffff' : 'black',
        border: '1px solid black',
        lineHeight: '15px',
        textAlign: 'right',
        fontWeight: isLast || isBold ? 'bold': 'normal',
    }
};

function EnumerationTable(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        items,
        isLoading,
    } = props;


    const [columns, setColumns] = useState([]);


    useEffect(() => {
        setColumns([
            {
                title: t('NOMINAL'),
                field: 'nominalValue',
                cellStyle: (data, rowData) => cellStyle(data, rowData, items, true)
            },
            {
                title: t('DISPENSABLE'),
                field: 'dispensable',
                cellStyle: (data, rowData) => cellStyle(data, rowData, items, false)
            },
            {
                title: t('CASHBOX'),
                field: 'cashBox',
                cellStyle: (data, rowData) => cellStyle(data, rowData, items, false)
            },
            {title: t('SUM'), field: 'sum', cellStyle: (data, rowData) => cellStyle(data, rowData, items, true)},
        ]);

    }, [items]);


    return (
        <div className={classes.container}>
            <CustomTable
                title=""
                columns={columns}
                data={items}
                options={{
                    headerStyle: {
                        color: '#ffffff',
                        textAlign: 'center',
                        backgroundColor: 'grey',
                        border: '1px solid black',
                        lineHeight: '15px',
                    },
                    rowStyle: x => {
                        return {
                            backgroundColor: x.tableData.id === (items.length - 1) ? 'grey' : (x.tableData.id % 2) ? "#f2f2f2" : "fff",
                        }
                    },
                    paging: false,
                    sorting: false,
                }
                }
                isLoading={isLoading}
                loadAllData
            />
        </div>
    );
}

EnumerationTable.propTypes = {
    items: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

EnumerationTable.defaultProps = {
    isLoading: false,
};

export default EnumerationTable;
