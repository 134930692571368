import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingZones: false,
  isUpdating: false,

  parkingZones: [],

  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARKING_ZONES_FETCH_REQUEST:
      return {
        ...state,
        isFetchingZones: true,
      };
    case types.PARKING_ZONES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingZones: false,
        parkingZones: action.payload.parkingZones,
      };
    case types.PARKING_ZONES_FETCH_FAIL:
      return {
        ...state,
        isFetchingZones: false,
        errors: action.payload.error,
      };

    case types.ZONE_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.ZONE_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        parkingZones: action.payload.parkingZones,
      };
    case types.ZONE_UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
