import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import ToolBar from './ToolBar';
import Typography from "@material-ui/core/Typography";
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import PMCSnackbar from 'components/common/Snackbar';
import {fetchAllSubjects} from "../../../redux/actions/subjects";
import PosList from "./PosList";
import {blacklist, createPos, fetchAllPos, updatePos} from "../../../redux/actions/pos";
import Pos from "../../../components/modals/Pos";
import {fetchAllProductTemplates} from "../../../redux/actions/products";
import {fetchAllBranches} from "../../../redux/actions/branches";
import {fetchAllCurrencies} from "../../../redux/actions/currencies";
import PosHistory from "../../../components/modals/PosHistory";
import {Grid} from '@material-ui/core';
import {ToggleButton} from '@material-ui/lab';
import TocIcon from '@material-ui/icons/Toc';
import Tooltip from "@material-ui/core/Tooltip";
import SecurityIcon from "@material-ui/icons/Security";
import i18next from "i18next";
import CertificateIcon from "@material-ui/icons/VerifiedUser";
import moment from 'moment/min/moment-with-locales';


const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));


function PosConfiguration(props) {
    const {
        userUseCases,
        pos,
        fetchAllPos,
        isFetchingAllList,
        createPos,
        updatePos,
        user,
        subjects,
        fetchAllSubjects,
        templates,
        fetchAllProductTemplates,
        fetchAllBranches,
        fetchAllCurrencies,
        branches,
        currencies,
        blacklist,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const renderCertificateState = (param) => {

        if (!param) {
            return <Tooltip title={t('CERTIFICATE_INVALID')}><SecurityIcon color="error"/></Tooltip>
        }
        const validTo = (param) ? moment(param).locale(i18next.language).format('lll') : ' ';
        const diff = (param) ? moment(param).diff(moment(Date.now()), 'days') : 0;
        if (diff < 5) {
            return <Tooltip title={t('CERTIFICATE_EXPIRES_SOON')}><Typography variant="h6"><CertificateIcon
                color="error" style={{fontStyle: '13px'}}/> {validTo}</Typography></Tooltip>
        } else if (diff < 20) {
            return <><Tooltip title={t('CERTIFICATE_EXPIRES_SOON')}><CertificateIcon color="secondary"
                                                                                     style={{fontStyle: '13px'}}/></Tooltip> {validTo}</>
        } else {
            return <><Tooltip title={t('CERTIFICATE_IS_VALID')}><CertificateIcon color="primary"
                                                                                 style={{fontStyle: '13px'}}/></Tooltip> {validTo}</>
        }
    }

    const getDayDiff = (date1, date2) => {
        return moment(date1).diff(moment(date2), 'days');
    }

    const certifications = [
        {
            value: 'POS_STATUS_NO_BLACKLISTED',
            label: t('POS_STATUS_NO_BLACKLISTED'),
            key: 'POS_STATUS_NO_BLACKLISTED',
            color: '#0046e5',
            eval: (pos, currentDate) => {
                return !pos.blackList;
            }
        },
        {
            value: 'POS_STATUS_BLACKLISTED',
            label: t('POS_STATUS_BLACKLISTED'),
            key: 'POS_STATUS_BLACKLISTED',
            color: '#928686',
            eval: (pos, currentDate) => {
                return pos.blackList;
            }

        },
        {
            value: 'POS_STATUS_EXPIRED',
            label: t('POS_STATUS_EXPIRED'),
            key: 'POS_STATUS_EXPIRED',
            color: '#ff0404',
            eval: (pos, currentDate) => {
                if (!pos.validTo) {
                    return false;
                }
                return getDayDiff(pos.validTo, currentDate) < 0;
            }
        },
        {
            value: 'POS_STATUS_LE5D',
            label: t('POS_STATUS_LE5D'),
            key: 'POS_STATUS_LE5D',
            color: '#f85c87',
            eval: (pos, currentDate) => {
                if (!pos.validTo) {
                    return false;
                }
                let dayDiff = getDayDiff(pos.validTo, currentDate);
                return dayDiff > 0 && dayDiff <= 5;
            }
        },
        {
            value: 'POS_STATUS_LE20D',
            label: t('POS_STATUS_LE20D'),
            key: 'POS_STATUS_LE20D',
            color: '#ffd3e0',
            eval: (pos, currentDate) => {
                if (!pos.validTo) {
                    return false;
                }
                let dayDiff = getDayDiff(pos.validTo, currentDate);
                return dayDiff > 0 && dayDiff <= 20;
            }
        },
        {
            value: 'POS_STATUS_LE30D',
            label: t('POS_STATUS_LE30D'),
            key: 'POS_STATUS_LE30D',
            color: '#52f53b',
            eval: (pos, currentDate) => {
                if (!pos.validTo) {
                    return false;
                }
                let dayDiff = getDayDiff(pos.validTo, currentDate);
                return dayDiff > 0 && dayDiff <= 30;
            }
        },
        {
            value: 'POS_STATUS_G30D',
            label: t('POS_STATUS_G30D'),
            key: 'POS_STATUS_G30D',
            color: '#3eb835',
            eval: (pos, currentDate) => {
                if (!pos.validTo) {
                    return false;
                }
                return getDayDiff(pos.validTo, currentDate) > 30;
            }
        },
    ];


    const statuses = [
        {
            value: 'POS_STATUS_USED',
            label: t('POS_STATUS_USED'),
            key: 'POS_STATUS_USED',
            color: '#0046e5',
            eval: (pos, currentDate) => {
                return pos.active;
            }
        },
        {
            value: 'POS_STATUS_NOT_USED',
            label: t('POS_STATUS_NOT_USED'),
            key: 'POS_STATUS_NOT_USED',
            color: '#f85c87',
            eval: (pos, currentDate) => {
                return !pos.active;
            }
        },
        {
            value: 'POS_STATUS_NEW',
            label: t('POS_STATUS_NEW'),
            key: 'POS_STATUS_NEW',
            color: '#c6cde8',
            eval: (pos, currentDate) => {
                return (pos.state === 'NEW' || pos.state === 'INIT');
            }
        },
        {
            value: 'POS_STATUS_ISSUED',
            label: t('POS_STATUS_ISSUED'),
            key: 'POS_STATUS_ISSUED',
            color: '#e7b932',
            eval: (pos, currentDate) => {
                return pos.state === 'CERT_ISSUED';
            }
        },
        {
            value: 'POS_STATUS_WITH_PROBLEM',
            label: t('POS_STATUS_WITH_PROBLEM'),
            key: 'POS_STATUS_WITH_PROBLEM',
            color: '#ff0404',
            eval: (pos, currentDate) => {
                return pos.state === 'ERROR';
            }
        },
        {
            value: 'POS_STATUS_NOT_CONNECTED',
            label: t('POS_STATUS_NOT_CONNECTED'),
            key: 'POS_STATUS_NOT_CONNECTED',
            color: '#ee5997',
            eval: (pos, currentDate) => {
                return pos.state === 'POS_STATE_COMMENT_NOT_CONNECTED';
            }
        },
    ];


    const [isFetchedPos, setIsFetchedPos] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [deletionSnackbarOpen, setDeletionSnackbarOpen] = useState(false);
    const [deletionSnackbarError, setDeletionSnackbarError] = useState('');

    const [posConfigurationModel, setPosConfigurationModel] = useState({
        posDetailOpen: false,
        pos: {},
        isNew: true
    });

    const [posHistoryModel, setPosHistoryModel] = useState({
        open: false,
        pos: {},
    });


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDeletionSnackbarOpen(false);
    };


    useEffect(() => {
        if (!isFetchedPos) {
            setIsLoading(true);
            fetchAllPos().then(
                () => {
                    fetchAllSubjects().then(
                        () => {
                            fetchAllProductTemplates().then(() => {
                                fetchAllBranches().then(() => {
                                    fetchAllCurrencies().then(() => {
                                        setIsFetchedPos(true);
                                        setIsLoading(false);
                                    }).catch((err) => {
                                        setIsFetchedPos(true);
                                        setIsLoading(false);
                                    });
                                }).catch((err) => {
                                    setIsFetchedPos(true);
                                    setIsLoading(false);
                                });
                            }).catch((err) => {
                                setIsFetchedPos(true);
                                setIsLoading(false);
                            });
                        }
                    ).catch((err) => {
                        setIsFetchedPos(true);
                        setIsLoading(false);
                    });
                }
            ).catch((err) => {
                setIsFetchedPos(true);
                setIsLoading(false)
            });
        }

    }, [fetchAllPos, isFetchedPos]);


    const [branchValues, setBranchValues] = useState([]);


    useEffect(() => {
        if (branches) {
            const filBranches = [];
            for (let i = 0; i < branches.length; i++) {
                const a = branches[i];
                filBranches.push({value: a.id, label: a.branchName, key: a.id});
            }
            setBranchValues(filBranches);
        }
    }, [branches]);

    const [selectedName, setSelectedName] = useState('');
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedCertification, setSelectedCertification] = useState([certifications[0]]);
    const [selectedStatus, setSelectedStatus] = useState([]);

    const [selectedValidTo, setSelectedValidTo] = useState();
    const [table, setTable] = useState(false);


    const handleSelectedName = (value) => {
        setSelectedName(value.target.value);
    };
    const handleSelectedBranch = (value) => {
        setSelectedBranch(value);

    };
    const handleSelectCertification = (value) => {
        setSelectedCertification(value);
    };

    const handleSelectStatus = (value) => {
        setSelectedStatus(value);
    };

    const handleSelectedValidTo = (value) => {
        setSelectedValidTo(value);
    };

    const matchCertification = (pos) => {
        let res = false;
        let currentDate = new Date();
        for (let i = 0; i < selectedCertification.length; i++) {
            res = selectedCertification[i].eval(pos, currentDate);
            if (res) {
                return true;
            }
        }
        return false;
    }

    const matchBranch = (pos) => {
        let res = false;
        for (let i = 0; i < selectedBranch.length; i++) {
            res = selectedBranch[i].value == pos.branchId;
            if (res) {
                return true;
            }
        }
        return false;
    }

    const matchStatus = (pos) => {
        let res = false;
        let currentDate = new Date();
        for (let i = 0; i < selectedStatus.length; i++) {
            res = selectedStatus[i].eval(pos, currentDate);
            if (res) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        const filPos = [];
        for (let i = 0; i < pos.length; i++) {
            let matchCriteria = true;
            if (selectedName && selectedName.trim().length > 0) {
                if (pos[i].name) {
                    if (!(pos[i].name.toLowerCase().includes(selectedName.toLowerCase()))) {
                        matchCriteria = false;
                    }
                }
            }

            if (selectedCertification && selectedCertification.length > 0) {
                const res = matchCertification(pos[i]);
                if (!res) {
                    matchCriteria = false;
                }
            }

            if (selectedStatus && selectedStatus.length > 0) {
                const res = matchStatus(pos[i]);
                if (!res) {
                    matchCriteria = false;
                }
            }

            if (selectedBranch && selectedBranch.length > 0) {
                const res = matchBranch(pos[i]);
                if (!res) {
                    matchCriteria = false;
                }
            }

            if (selectedValidTo) {
                if (pos[i].validTo) {
                    const validTo = new Date(pos[i].validTo);
                    if (validTo < selectedValidTo) {
                        matchCriteria = false;
                    }
                }
            }

            if (matchCriteria) {
                filPos.push(pos[i]);
            }
        }
        setFilteredPos(filPos);
    }, [pos, selectedName, selectedCertification, selectedStatus, selectedBranch, selectedValidTo]);


    const [filteredPos, setFilteredPos] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');


    const handlePosDetail = (pos, isNew,) => {
        setError('');
        setSnackbarOpen(false);
        setPosConfigurationModel({isNew, pos, posDetailOpen: true});
    };

    const closePosDetail = () => {
        setError('');
        setSnackbarOpen(false);
        setPosConfigurationModel({isNew: false, pos: {}, posDetailOpen: false});
    }

    const closePosHistory = () => {
        setPosHistoryModel({pos: {}, open: false});
    }

    const handlePosHistory = (pos,) => {
        setPosHistoryModel({pos, open: true});
    };


    const savePos = (pos) => {
        const action = (pos && (pos.posId || pos.posId == 0)) ? updatePos : createPos;
        action(pos).then((prod) => {
            setIsLoading(true);
            setIsFetchedPos(false);
            closePosDetail();
        }).catch((error) => {
            setSnackbarOpen(true);
            setError(getErrorMessage(error, t));
        });
    };


    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >

            <div className={classes.breadcrumb}>
                {isComponentVisibleForUser(UC_POS_CONFIGURATION, userUseCases) &&

                    <Link style={{textDecoration: 'none'}}
                          to={isComponentEnabledForUser(UC_POS_CONFIGURATION, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration` : "#"}>

                        <Typography variant="button" className={classnames(classes.activeLink, classes.navLink)}>
                            {t('ADMINISTRATION')}
                        </Typography>
                    </Link>
                }
            </div>

            <Typography variant="h4" className={classes.title}>
                {t('POS_CONFIGURATION')}
            </Typography>


            <PMCSnackbar open={deletionSnackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={deletionSnackbarError}/>

            <div className={classes.container}>

                <ToolBar
                    branchValues={branchValues}
                    certifications={certifications}
                    statuses={statuses}
                    selectedName={selectedName}
                    handleSelectedName={handleSelectedName}
                    userUseCases={userUseCases}
                    handlePosDetail={handlePosDetail}
                    handleSelectBranch={handleSelectedBranch}
                    handleSelectStatus={handleSelectStatus}
                    handleSelectCertification={handleSelectCertification}
                    handleSelectedValidTo={handleSelectedValidTo}
                    selectedCertification={selectedCertification}
                    selectedStatus={selectedStatus}
                    selectedBranch={selectedBranch}
                    selectedValidTo={selectedValidTo}

                />
                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>
                <PosList
                    refresh={() => {
                        setIsFetchedPos(false);
                    }}
                    table={table}
                    save={updatePos}
                    userUseCases={userUseCases}
                    pos={filteredPos}
                    handlePosDetail={handlePosDetail}
                    isLoading={isFetchingAllList}
                    setDeletionSnackbarOpen={setDeletionSnackbarOpen}
                    setDeletionSnackbarError={setDeletionSnackbarError}
                    handlePosHistory={handlePosHistory}
                    blacklist={blacklist}
                />

                <PosHistory
                    userUseCases={userUseCases}
                    posHistoryModel={posHistoryModel}
                    handleClose={closePosHistory}

                />
            </div>


            <Pos posConfigurationModel={posConfigurationModel}
                 userUseCases={userUseCases}
                 save={savePos}
                 error={error}
                 handleClose={closePosDetail}
                 snackbarOpen={snackbarOpen}
                 setSnackbarOpen={setSnackbarOpen}
                 subjects={subjects}
                 templates={templates}
                 branches={branches}
                 currencies={currencies}
                 hasCertificate={(posConfigurationModel.pos.validTo)}
            />


        </PerfectScrollbar>
    );
}

PosConfiguration.propTypes = {};

const UC_POS_CONFIGURATION = 'UC0640';

const mapStateToProps = (store) => ({
    user: store.authData.user,
    pos: store.posData.pos,
    isFetchingAllList: store.posData.isFetchingAllList,
    subjects: store.subjectsData.subjects,
    templates: store.productsData.productTemplates,
    branches: store.branchesData.branches,
    currencies: store.currenciesData.currencies,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllPos,
    createPos,
    blacklist,
    updatePos,
    fetchAllSubjects,
    fetchAllProductTemplates,
    fetchAllBranches,
    fetchAllCurrencies,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PosConfiguration);
