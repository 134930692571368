import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 270,
        height: 70,
        marginRight: theme.spacing(3.75),
        marginBottom: theme.spacing(3.75),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: (props) => props.color,
        borderRadius: 2,
        boxShadow: theme.palette.shadow.main,
        color: theme.palette.base.white,
        cursor: 'pointer',
    }, missedCalls: {
        height: '40px',
        top: '0px',
        left: '270px',
        minWidth: '40px',
        '& > span': {
            height: '40px',
            width: '40px',
            fontSize: '18px',
            borderRadius: '20px',
        },
    },
}));

function ParkingCardCollapsed({parking, handleClick, calls, sseParkingHosts}) {
    const classes = useStyles({color: parking.color});
    const {t} = useTranslation();
    const [missedCalls, setMissedCalls] = useState(0);
    useEffect(() => {
        if (calls && sseParkingHosts) {
            let missedCalls = 0;
            for (let i = 0; i < sseParkingHosts.length; i++) {
                for (let j = 0; j < calls.length; j++) {
                    if (calls[j].missed && sseParkingHosts[i].parkingId === parking.parkingId && sseParkingHosts[i].sipUserName === calls[j].number) {
                        missedCalls++;
                    }
                }
            }
            setMissedCalls(missedCalls);
        }
    }, [sseParkingHosts, calls]);
    return (
        <>
            <Badge badgeContent={missedCalls} color="error" anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                   overlap="rectangle" className={classes.missedCalls}/>
            <div
                className={classes.container}
                onClick={handleClick(parking.parkingId)}
            >
                <Typography variant="h3">{parking.parkingName}</Typography>
                <Typography variant="caption">
                    {parking.isConnected ? t('CONNECTED') : t('DISCONNECTED')}
                </Typography>
            </div>
        </>
    );
}

ParkingCardCollapsed.propTypes = {
    parking: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default ParkingCardCollapsed;
