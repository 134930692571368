import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Add, Remove} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {numberFormat0, numberFormat1, numberFormat2, numberFormat3, numberFormat4} from "../../../utils";

const useStyles = makeStyles((theme) => ({
    icon: {
        height: '20px',
        width: '20px',
    },
    iconButton: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
        marginBottom: '10px',
        marginRight: '5px',
    },
    iconButtonRemove: {
        backgroundColor: theme.palette.primary.red,
        padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
        marginBottom: '10px',
        marginRight: '5px',
    },

}));

function TextInnerField(props) {
    const classes = useStyles();

    const {
        rowData,
        type = 'text',
        initialValue,
        onBlur,
        addChildToMacroSelect,
        removeChildToMacroSelect,
        helperText,
        label,
        onKeyPress,
        inputRef,
        required,
        disabled,
    } = props;


    const [value, setValue] = useState('');


    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);


    const getNumberFormat = (precision) => {
        if (precision) {
            if (precision == "1") {
                return numberFormat1;
            }
            ;
            if (precision == "2") {
                return numberFormat2;
            }
            ;
            if (precision == "3") {
                return numberFormat3;
            }
            ;
            if (precision == "4") {
                return numberFormat4;
            }
            ;
        }
        return numberFormat0;
    }

    const getInputProps = (rowData) => {
        if (rowData.paramType === 'PRODUCT_TYPE_MACRO_SELECT' && rowData.parent === true) {
            return {
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={
                                addChildToMacroSelect(rowData.paramName)
                            } className={classes.iconButton}>
                                <Add className={classes.icon}/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }
            }
        } else if (rowData.paramType === 'PRODUCT_TYPE_MACRO_SELECT' && rowData.parent === false) {
            return {
                InputProps: {
                    style: {
                        marginLeft: 20
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={
                                (removeChildToMacroSelect(rowData.paramName)).bind(this, rowData.index)
                            } className={classes.iconButtonRemove}>
                                <Remove className={classes.icon}/>
                            </IconButton>
                        </InputAdornment>
                    ),
                },
                InputLabelProps: {
                    style: {
                        marginLeft: 20
                    },
                }
            }
        }
        return {};
    }

    if (type === 'number') {
        return (<TextField
            disabled={disabled}
            helperText={helperText}
            fullWidth
            required={required}
            label={label}
            value={value || ''}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            onBlur={onBlur(rowData.paramName, rowData.index)}
            InputProps={{
                inputComponent: getNumberFormat(rowData.precision),
            }}
            onKeyPress={onKeyPress ? onKeyPress : () => {
            }}
            inputRef={inputRef}
        />)
    }
    return (
        <TextField
            disabled={disabled}
            helperText={helperText}
            fullWidth
            required={required}
            label={label}
            value={value || ''}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            onBlur={onBlur(rowData.paramName, rowData.index)}
            onKeyPress={onKeyPress ? onKeyPress : () => {
            }}
            inputRef={inputRef}
            {...getInputProps(rowData)}
        />
    );


}

TextInnerField.propTypes =
    {
        rowData: PropTypes.object.isRequired,
    };

TextInnerField.defaultProps =
    {
        rowData: null,
    };

export default TextInnerField;
