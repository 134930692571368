import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';


const getColor = (theme) => {
    return {
        base: theme.palette.secondary.main,
        hover: '#769CF9',
        active: '#5F74FB',
        disabled: '#A8C1FF',
    };
};


const useStyles = makeStyles((theme) => ({
    button: {
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: (props) => props.width,
        height: (props) => props.height,
        backgroundColor: (props) => getColor(theme).base,
        borderRadius: 2,
        boxShadow: theme.palette.shadow.main,
        color: (props) => (props.color === 'grey' || props.color === 'white' ? '#60636B' : 'white'),

        '&:hover': {
            backgroundColor: (props) => getColor(theme).hover,
        },

        '&:active': {
            backgroundColor: (props) => getColor(theme).active,
        },

        '&:disabled': {
            backgroundColor: (props) => getColor(theme).disabled,
            color: (props) => (props.color === 'grey' ? '#D4D5D9' : 'white'),
        },
    },
    buttonIcon: {
        display: 'flex',
        alignItems: 'right',
        flexDirection: 'row',
        marginLeft: theme.spacing(3.5),
        justifyContent: 'center',

    },
}));

function SipCustomButton({
                             className,
                             label,
                             width,
                             height,
                             color,
                             disabled,
                             icon,
                             onClick,
                         }) {
    const classes = useStyles({width, height, color});

    return (
        <Box display="flex" p={1} flexDirection="row-reverse"
             className={classnames(classes.button, className)}
             disabled={disabled}
             onClick={onClick}
             variant="text"
        >
            <Box p={1} order={2}>
                {label}
            </Box>
            <Box p={1} order={1}>
                <div className={classes.buttonIcon}>{icon}</div>
            </Box>


        </Box>
    );
}

SipCustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.array,
    onClick: PropTypes.func,
};

SipCustomButton.defaultProps = {
    width: 160,
    height: 40,
    disabled: false,
    icon: [],
    onClick: undefined,
};

export default SipCustomButton;
