import {keycloak, addSipParkingToUser, removeSipParkingToUser} from 'services/keycloak.service';
import actionTypes from '../actionTypes';


// eslint-disable-next-line import/prefer-default-export
export const logout = () => (dispatch) => {
    localStorage.removeItem('sip_calls');
    localStorage.removeItem('kc_token');
    localStorage.removeItem('kc_idToken');
    localStorage.removeItem('kc_refreshToken');

    dispatch({
        type: actionTypes.LOG_OUT,
    });

    keycloak.logout();
};

export const removeSipParkingIdToUserAction = (parkingId) => (dispatch, getState) => {
    const userId = getState().authData.user.userId;
    return removeSipParkingToUser(userId, parkingId)
        .then((data) => {
            dispatch({
                type: actionTypes.REMOVE_SIP_PARKING_TO_USER_SUCCESS,
                payload: {sipParkingIds: data.sipParkingIds || []},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: actionTypes.REMOVE_SIP_PARKING_TO_USER_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const addSipParkingIdToUserAction = (parkingId) => (dispatch, getState) => {
    const userId = getState().authData.user.userId;
    return addSipParkingToUser(userId, parkingId)
        .then((data) => {
            dispatch({
                type: actionTypes.ADD_SIP_PARKING_TO_USER_SUCCESS,
                payload: {sipParkingIds: data.sipParkingIds || []},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: actionTypes.ADD_SIP_PARKING_TO_USER_FAIL,
                payload: {error},
            });

            throw error;
        });
};
