import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCardData, getCards } from 'utils';
import CardsTable from './CardsTable';

function ParkingCardsContent(props) {
  const {
    parking,
    cardType,
    cards,
    isLoading,
    isActivating,
    isBlocking,
    validity,
    blocked,
    validTill,
    zone,
    cardsPaging,
    parkingCardNumbers,
    handleCardDetailOpen,
    handleCreateCardOpen,
    handleEditCardOpen,
    handleBlockCardOpen,
    handleConfirmCardOpen,
    handleOwner,
    handleValidTill,
    handleValidity,
    handleFilterBlocked,
    handleSearch,
    handleZone,
    handleChangeRowsPerPage,
    handleChangePage,
    userUseCases,
    handleOrderChange,
  } = props;

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  useEffect(()=>{
    setCardData(getCardData(cards));
  }, [cards]);

  const [cardData, setCardData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = (checked) => {
    if (checked) {
      setSelected((cardData).map((d) => d.cardNo));
    } else {
      setSelected([]);
    }
    setSelectAll(checked);
  };
  const handleCheck = (rowData) => () => {
    const selectedIndex = selected.indexOf(rowData.cardNo);
    if (selectedIndex > -1) {
      const selectedCardNumbers = selected.filter((cardNumber) => cardNumber !== rowData.cardNo);

      setSelected(selectedCardNumbers);
    } else {
      setSelected([
        ...selected,
        rowData.cardNo,
      ]);
    }
  };

  useEffect(() => {
    setSelected([]);
    setSelectAll(false);
  }, [cards.length]);

  const handleActivate = (rowData) => () => {
    if (isActivating) {
      return;
    }

    const cardData = {
      ...rowData,
      cardNumber: rowData.cardNo,
    };
    handleConfirmCardOpen(cardData, !cardData.valid ? 'activate' : 'deactivate');
  };

  const handleBlock = (rowData) => () => {
    if (isBlocking) {
      return;
    }

    if (rowData.blocked) {
      const cardData = {
        ...rowData,
        cardNumber: rowData.cardNo,
      };
      handleConfirmCardOpen(cardData, 'unblock');
    } else {
      handleBlockCardOpen(rowData);
    }
  };

  const handleEditCardModal = () => {
    if (selected.length === 0) {
      return;
    }

    const selectedCards = getCards(cards).filter((c) => selected.indexOf(c.cardNumber) > -1);

    handleEditCardOpen({
      cards: selectedCards,
      parkingId: parking.parkingId,
      parkingName: parking.parkingName,
      zoneStatus: parking.zoneStatus,
    });
  };

  return (
    <CardsTable
      parking={parking}
      cardType={cardType}
      cardData={cardData}
      isLoading={isLoading}
      selectAll={selectAll}
      selected={selected}
      validity={validity}
      validTill={validTill}
      blocked={blocked}
      zone={zone}
      cardsPaging={cardsPaging[cardType]}
      parkingCardNumbers={parkingCardNumbers[cardType]}
      handleOwner={handleOwner}
      handleCreateCardOpen={handleCreateCardOpen}
      handleCardDetail={handleCardDetailOpen}
      handleSelectAll={handleSelectAll}
      handleCheck={handleCheck}
      handleEditCardModal={handleEditCardModal}
      handleActivate={handleActivate}
      handleBlock={handleBlock}
      handleValidTill={handleValidTill}
      handleValidity={handleValidity}
      handleFilterBlocked={handleFilterBlocked}
      handleSearch={handleSearch}
      handleZone={handleZone}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      userUseCases={userUseCases}
      handleOrderChange={handleOrderChange}
    />
  );
}

ParkingCardsContent.propTypes = {
  parking: PropTypes.object.isRequired,
  cardType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  cards: PropTypes.array.isRequired,
  isActivating: PropTypes.bool.isRequired,
  isBlocking: PropTypes.bool.isRequired,
  validity: PropTypes.array.isRequired,
  blocked: PropTypes.array.isRequired,
  validTill: PropTypes.object,
  zone: PropTypes.array.isRequired,
  cardsPaging: PropTypes.object.isRequired,
  parkingCardNumbers: PropTypes.object.isRequired,
  handleCardDetailOpen: PropTypes.func.isRequired,
  handleCreateCardOpen: PropTypes.func.isRequired,
  handleEditCardOpen: PropTypes.func.isRequired,
  handleBlockCardOpen: PropTypes.func.isRequired,
  handleConfirmCardOpen: PropTypes.func.isRequired,
  handleValidTill: PropTypes.func.isRequired,
  handleValidity: PropTypes.func.isRequired,
  handleFilterBlocked: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleZone: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

ParkingCardsContent.defaultProps = {
  validTill: null,
};

const mapStateToProps = (store) => ({
  isActivating: store.cardsData.isActivating,
  isBlocking: store.cardsData.isBlocking,
  parkingCardNumbers: store.cardsData.parkingCardNumbers,
});

export default connect(
  mapStateToProps,
)(ParkingCardsContent);
