import { getTasksQuery, getTasksStatisticQuery } from 'utils';
import httpService from './http.service';

const getParkingTasks = (parkingId, createdById, hostType, state, size, page, keyword) => httpService
  .get(`/tasks/parking/${parkingId}${getTasksQuery(createdById, hostType, state, size, page, keyword)}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const getTaskImage = (taskId) => httpService
  .get(`/taskimage/${taskId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const createTask = (event) => httpService
  .post('/task/create', event)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const cancelTask = (event) => httpService
  .post('/task/cancle', event)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const renewTask = (event) => httpService
  .post('/task/renew', event)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const getTasksStatistic = (parkingId, dateFrom, dateTo) => httpService
  .get(`/taskstatistic/${parkingId}${getTasksStatisticQuery(dateFrom, dateTo)}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getParkingTasks,
  getTaskImage,
  createTask,
  cancelTask,
  renewTask,
  getTasksStatistic,
};
