import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

const CustomAreaChart = (props) => {
  const { options, series } = props;

  return (
    <Chart
      width="100%"
      height="100%"
      options={options}
      series={series}
      type="area"
    />
  );
};

CustomAreaChart.propTypes = {
  options: PropTypes.object,
  series: PropTypes.array,
};

CustomAreaChart.defaultProps = {
  options: {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['transparent'],
    },
    grid: {
      borderColor: 'transparent',
    },
    fill: {
      type: 'solid',
    },
    xaxis: {
      type: 'string',
      categories: [],
    },
  },
  series: [],
};

export default CustomAreaChart;
