import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import HighlightOff from '@material-ui/icons/HighlightOff';
import i18next from 'i18next';
import {getLocale, getLocaleDateTimeString} from '../../i18n';
import {useTranslation} from 'react-i18next';
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";


const MuiThemePure = createMuiTheme({
    overrides: {
        MuiInput: {
            underline: {
                "&:after": {
                    borderBottom: '2px solid #50A933'
                }
            }
        },
        MuiPickersClock: {
            clock: {
                backgroundColor: '#EDEDED',
                color: 'white',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 18,
                "&$focused": {
                    color: '#50A933'
                }
            },
        },
        MuiInputBase: {
            root: {
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#fff', // Solid underline on focus
                },
            },
        },
    },
});


const MuiTheme = createMuiTheme({
    overrides: {
        MuiFormControl: {
            root: {
                marginLeft: 10,
            },
        },
        MuiFormLabel: {
            root: {
                color: '#5985EE',
            },
        },
        MuiInputBase: {
            root: {
                '& .Mui-disabled': {
                    color: '#ADB0B8',
                },
            },
            input: {
                color: '#5985EE',
                fontSize: 14,
            },
        },
        MuiInputAdornment: {
            root: {
                '& .Mui-disabled svg': {
                    fill: '#ADB0B8',

                    '&:hover': {
                        fill: '#ADB0B8',
                    },
                },

                '& button:hover': {
                    background: 'unset',
                },

                '& button > span:nth-child(2)': {
                    display: 'none',
                },

                '& button > span > div': {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        },
        MuiIconButton: {
            root: {
                '& svg': {
                    fill: '#5985EE',

                    '&:hover': {
                        fill: '#1F58EE',
                    },
                },

                '& svg:first-child': {
                    width: 15,
                    height: 15,
                },
            },
        },
        MuiPickersClock: {
            clock: {
                backgroundColor: '#EDEDED',
                color: 'white',
            },
        },
    },
});

function CustomDateTimePicker(props) {
    const {
        label,
        format,
        disabled,
        value,
        icon,
        handleChange,
        ampm,
        fullWidth,
        showTodayButton,
        helperText,
        pure,
        required,
        inputRef,
        onKeyPress,
    } = props;

    const onChange = (value) => {
        handleChange(value);
    };

    const {t} = useTranslation();

    const handleSetDefault = (e) => {
        if (disabled) {
            return;
        }

        e.stopPropagation();
        handleChange(null);
    };

    return (

        <MuiThemeProvider theme={pure ? MuiThemePure : MuiTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(i18next.language)}>
                {pure ? <KeyboardDateTimePicker
                        maxDate={new Date('2999-12-31')}
                        ampm={ampm}
                        type="search"
                        fullWidth={fullWidth}
                        value={value}
                        showTodayButton={showTodayButton}
                        label={label}
                        okLabel={t('OK')}
                        cancelLabel={t('CANCEL')}
                        onChange={onChange}
                        format={format}
                        disabled={disabled}
                        helperText={helperText}
                        required={required}
                        inputRef={inputRef}
                        onKeyPress={onKeyPress ? onKeyPress : () => {
                        }}
                    /> :
                    <KeyboardDateTimePicker
                        maxDate={new Date('2999-12-31')}
                        ampm={ampm}
                        fullWidth={fullWidth}
                        value={value}
                        showTodayButton={showTodayButton}
                        label={label}
                        okLabel={t('OK')}
                        cancelLabel={t('CANCEL')}
                        onChange={onChange}
                        format={format}
                        disabled={disabled}
                        helperText={helperText}
                        required={required}
                        inputRef={inputRef}
                        onKeyPress={onKeyPress ? onKeyPress : () => {
                        }}
                        keyboardIcon={(
                            <div>
                                <HighlightOff onClick={(e) => handleSetDefault(e)}/>
                                {icon}
                            </div>
                        )}
                    />
                }
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
}

CustomDateTimePicker.propTypes = {
    label: PropTypes.string,
    format: PropTypes.string,
    value: PropTypes.object,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    handleChange: PropTypes.func,
    ampm: PropTypes.bool,
    fullWidth: PropTypes.bool,
    showTodayButton: PropTypes.bool,
    pure: PropTypes.bool,
    required: PropTypes.bool,

};

CustomDateTimePicker.defaultProps = {
    label: '',
    format: getLocaleDateTimeString(i18next.language),
    value: '',
    icon: undefined,
    disabled: false,
    handleChange: () => null,
    ampm: false,
    fullWidth: false,
    showTodayButton: false,
    pure: false,
    required: false,

};

export default CustomDateTimePicker;
