import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import ParkingIcon from '@material-ui/icons/LocalParking';
import CashDeskIcon from '@material-ui/icons/Storefront';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsApplications from '@material-ui/icons/Tune';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BarChart from '@material-ui/icons/BarChart';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';

import logoImgGC from 'assets/logo.gc.png';
import logoImgGP from 'assets/logo-gp-pl.png';
import logoImgSW from 'assets/logo.scrw.png';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    height: 70,
    paddingRight: theme.spacing(3), // keep right padding when drawer closed
    color: theme.palette.base[800],
  },
  leftbarIcon: {
    height: '55px',
    width: '230px',
    display: 'flex',
  },
  toolbarIcon: {
    height: 70,
    padding: theme.spacing(3.75, 2, 3.75, 3.75),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mixins.toolbar,
  },
  menuIcon: {
    width: '30px',
    height: '30px',
    color: theme.palette.primary.main,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    backgroundColor: theme.palette.background.drawer,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      // width: theme.spacing(9),
    },
  },
  link: {
    textDecoration: 'none',
  },
  listItem: {
    minHeight: 60,
    color: theme.palette.base[600],
  },
  listItemIcon: {
    minWidth: 40,
    color: 'inherit',
  },
  listItemText: {
    margin: 0,
    fontSize: 18,
    lineHeight: '24px',

    '& > span': {
      whiteSpace: 'normal',
    },
  },
}));

function LeftDrawer(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userUseCases,
    open,
    handleDrawerClose,
  } = props;

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classnames(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        {process.env.REACT_APP_PUBLIC_LOGO === 'gc' && (<img src={logoImgGC} alt="Green Center" />)}
        {process.env.REACT_APP_PUBLIC_LOGO === 'gp' && (<img className={classes.leftbarIcon}  src={logoImgGP} alt="Green Parking"  />)}
        {process.env.REACT_APP_PUBLIC_LOGO === 'scrum' && (<img src={logoImgSW} alt="Scrum ware" />)}
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon className={classes.menuIcon} />
        </IconButton>
      </div>

      <List>
        {isComponentVisibleForUser(UC_MENU_LOCAL_PARKING_DASHBOARD, userUseCases) && (
          <>
          {isComponentEnabledForUser(UC_MENU_LOCAL_PARKING_DASHBOARD, userUseCases) &&
            <Link to={`/${process.env.REACT_APP_PATH}/parking`} className={classes.link}>
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ParkingIcon />
                </ListItemIcon>
                <ListItemText primary={t('PARKING_DASHBOARD')} className={classes.listItemText} />
              </ListItem>
            </Link>
          }
          {!isComponentEnabledForUser(UC_MENU_LOCAL_PARKING_DASHBOARD, userUseCases) &&
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <ParkingIcon />
              </ListItemIcon>
              <ListItemText primary={t('PARKING_DASHBOARD')} className={classes.listItemText} />
            </ListItem>
          }
          </>
        )}

        {isComponentVisibleForUser(UC_MENU_MANAGEMENT_DASHBOARD, userUseCases) && (
          <>
          {isComponentEnabledForUser(UC_MENU_MANAGEMENT_DASHBOARD, userUseCases) &&
            <Link to={`/${process.env.REACT_APP_PATH}/management`} className={classes.link}>
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t('MANAGEMENT_DASHBOARD')} className={classes.listItemText} />
              </ListItem>
            </Link>
          }
          {!isComponentEnabledForUser(UC_MENU_MANAGEMENT_DASHBOARD, userUseCases) &&
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t('MANAGEMENT_DASHBOARD')} className={classes.listItemText} />
            </ListItem>
          }

          </>
        )}

        {isComponentVisibleForUser(UC_MENU_ACCESS, userUseCases) && (
          <>
          {isComponentEnabledForUser(UC_MENU_ACCESS, userUseCases) &&
            <Link to={`/${process.env.REACT_APP_PATH}/access`} className={classes.link}>
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText primary={t('ACCESS_DASHBOARD')} className={classes.listItemText} />
              </ListItem>
            </Link>
          }
          {!isComponentEnabledForUser(UC_MENU_ACCESS, userUseCases) &&
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary={t('ACCESS_DASHBOARD')} className={classes.listItemText} />
            </ListItem>
          }

          </>
        )}

        {isComponentVisibleForUser(UC_MENU_CASHDESK_DASHBOARD, userUseCases) && (
          <>
          {isComponentEnabledForUser(UC_MENU_CASHDESK_DASHBOARD, userUseCases) &&          
            <Link to={`/${process.env.REACT_APP_PATH}/cashdesk`} className={classes.link}>
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <CashDeskIcon />
                </ListItemIcon>
                <ListItemText primary={t('CASHDESK_DASHBOARD')} className={classes.listItemText} />
              </ListItem>
            </Link>
          }
          {!isComponentEnabledForUser(UC_MENU_CASHDESK_DASHBOARD, userUseCases) &&          
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <CashDeskIcon />
              </ListItemIcon>
              <ListItemText primary={t('CASHDESK_DASHBOARD')} className={classes.listItemText} />
            </ListItem>
          }
          </>
          )}
        
        {isComponentVisibleForUser(UC_MENU_ADMINISTRATION, userUseCases) && (
          <>
            {isComponentEnabledForUser(UC_MENU_ADMINISTRATION, userUseCases) &&
              <Link to={`/${process.env.REACT_APP_PATH}/administration`} className={classes.link}>
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <SettingsApplications />
                </ListItemIcon>
                <ListItemText primary={t('ADMINISTRATION')} className={classes.listItemText} />
              </ListItem>
            </Link>
            }
            {!isComponentEnabledForUser(UC_MENU_ADMINISTRATION, userUseCases) &&
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <SettingsApplications />
                </ListItemIcon>
                <ListItemText primary={t('ADMINISTRATION')} className={classes.listItemText} />
              </ListItem>
            }
          </>
        )}
        {isComponentVisibleForUser(UC_MENU_MDM, userUseCases) && (
          <>
          {isComponentEnabledForUser(UC_MENU_MDM, userUseCases) &&
            <Link to={`/${process.env.REACT_APP_PATH}/mobile`} className={classes.link}>
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={t('MOBILE_DEVICE_MANAGEMENT')} className={classes.listItemText} />
            </ListItem>
            </Link>
          }
          {!isComponentEnabledForUser(UC_MENU_MDM, userUseCases) &&
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={t('MOBILE_DEVICE_MANAGEMENT')} className={classes.listItemText} />
            </ListItem>
        }
          </>
          )}
        {isComponentVisibleForUser(UC_MENU_DOWNLOAD, userUseCases) && (
          <>
          {isComponentEnabledForUser(UC_MENU_DOWNLOAD, userUseCases) &&
            <Link to={`/${process.env.REACT_APP_PATH}/download`} className={classes.link}>
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary={t('DOWNLOAD')} className={classes.listItemText} />
              </ListItem>
            </Link>
          }
          {!isComponentEnabledForUser(UC_MENU_DOWNLOAD, userUseCases) &&
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary={t('DOWNLOAD')} className={classes.listItemText} />
            </ListItem>
          }
          </>

        )}
        {isComponentVisibleForUser(UC_MENU_REPORTING, userUseCases) && (
          <>
          {isComponentEnabledForUser(UC_MENU_REPORTING, userUseCases) &&
            <Link to={{ pathname: `${process.env.REACT_APP_REPORTING_PATH}` }} target="_blank">
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <BarChart />
                </ListItemIcon>
                <ListItemText primary={t('REPORTING')} className={classes.listItemText} />
              </ListItem>
            </Link>
          }
          {!isComponentEnabledForUser(UC_MENU_REPORTING, userUseCases) &&
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary={t('REPORTING')} className={classes.listItemText} />
            </ListItem>
          }
          </>
        )}
        {isComponentVisibleForUser(UC_MENU_ABOUT, userUseCases) && (
          <>
          {isComponentEnabledForUser(UC_MENU_ABOUT, userUseCases) &&
            <Link to={`/${process.env.REACT_APP_PATH}/about`} className={classes.link}>
              <ListItem button className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <MoreVertIcon />
                </ListItemIcon>
                <ListItemText primary={t('ABOUT')} className={classes.listItemText} />
              </ListItem>
            </Link>
          }
          {!isComponentEnabledForUser(UC_MENU_ABOUT, userUseCases) &&
            <ListItem button className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <MoreVertIcon />
              </ListItemIcon>
              <ListItemText primary={t('ABOUT')} className={classes.listItemText} />
            </ListItem>
          }
          </>
        )}
      </List>
    </Drawer>
  );
}

LeftDrawer.propTypes = {
  userUseCases: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

const UC_MENU_LOCAL_PARKING_DASHBOARD = 'UC0002';
const UC_MENU_MANAGEMENT_DASHBOARD = 'UC0057';
const UC_MENU_MDM = 'UC0068';
const UC_MENU_DOWNLOAD = 'UC0074';
const UC_MENU_ABOUT = 'UC0076';
const UC_MENU_ADMINISTRATION = 'UC0600';
const UC_MENU_CASHDESK_DASHBOARD = 'UC0402';
const UC_MENU_REPORTING = 'UC0800';
const UC_MENU_ACCESS = 'UC0900';

export default LeftDrawer;
