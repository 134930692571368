import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {Loading} from 'components/elements';
import {getCardDetailByNumberOrLpn, changeAccess} from 'redux/actions/cards';
import {fetchParkingAccesses} from 'redux/actions/parkings';
import PerfectScrollbar from "react-perfect-scrollbar";
import ToolBar from './ToolBar';
import CardAccess from './CardAccess';
import PMCDialog from 'components/common/PMCDialog';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(2),
        borderRadius: '4px',
    },
    container2: {
        height: '850px',
        background: theme.palette.base.white,
        marginTop: theme.spacing(2),
        borderRadius: '4px',
    },
}));

function Access(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        user,
        cardDetail,
        getCardDetailByNumberOrLpn,
        parkingAccesses,
        fetchParkingAccesses,
        changeAccess,
        isGettingDetail
    } = props;

    const handleFilter = (filter) => {
        if (!filter || !filter.cardNumber || filter.cardNumber === '') {
            setSelectedCard(null);
        } else {
            getCardDetailByNumberOrLpn(filter && filter.cardNumber);
        }
    }

    const hadleSaveAccess = (access) => {
        if (!access || !access.cardId || !access.tmp) {

            setShowHelper(true);
            return;
        }
        changeAccess(access.parkingId, access.cardId, access.tmp.id)
            .then(() => {
              //  getCardDetailByNumberOrLpn(access.cardId);
                setShowHelper(false);
                setShowDialog(true);
            });
    }

    const [selectedCard, setSelectedCard] = useState(null);
    const [value, setValue] = useState(null);
    const [showHelper, setShowHelper] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        if (cardDetail) {
            if (cardDetail.parkings) {
                fetchParkingAccesses(cardDetail.parkings[0].parkingId, 'ST');
            }
        }
        setSelectedCard(cardDetail);
    }, [cardDetail]);

    if (!user) {
        return (
            <Loading/>
        );
    }

    return (
        <>
            <div className={classes.container}>
                <ToolBar
                    handleFilter={handleFilter}
                    value={value}
                    setValue={setValue}
                    isGettingDetail={isGettingDetail}
                />
            </div>
            <div className={classes.container2}>
                <PerfectScrollbar options={{suppressScrollX: true, minScrollbarLength: 50,}}>
                    {isGettingDetail ? <Loading/> :
                        <CardAccess
                            cardDetail={selectedCard}
                            parkingAccesses={parkingAccesses}
                            hadleSaveAccess={hadleSaveAccess}
                            showHelper={showHelper}
                        />}
                </PerfectScrollbar>
            </div>
            <PMCDialog
                open={showDialog}
                title={t('CARD_UPDATE_ACCESS_TITLE')}
                onClose={() => {
                    setShowDialog(false);
                }}
                size="sm"
                headerColor='#5985EE'
                height={300}
            >
                {t('CARD_UPDATE_ACCESS_MESSAGE')}
            </PMCDialog>
        </>
    );
}

Access.propTypes = {
    user: PropTypes.object,
    getCardDetail: PropTypes.func.isRequired,
    fetchParkingAccesses: PropTypes.func.isRequired,
    changeAccess: PropTypes.func.isRequired,
};

Access.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    cardDetail: store.cardsData.cardDetail,
    parkingAccesses: store.parkingsData.parkingAccesses,
    isGettingDetail: store.cardsData.isGettingDetail
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getCardDetailByNumberOrLpn,
    fetchParkingAccesses,
    changeAccess
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Access);
