import Keycloak from 'keycloak-js';
import i18n from 'i18n';

import actionTypes from 'redux/actionTypes';
import httpService from "./http.service";

const keycloakUrl = process.env.REACT_APP_KEYCLOAK;
const keycloakRN = process.env.REACT_APP_KEYCLOAK_RN;

// Setup Keycloak instance as needed
export const keycloak = new Keycloak({
    clientId: 'pmc-front',
    realm: keycloakRN,
    bearerOnly: true,
    url: keycloakUrl,
    'auth-server-url': keycloakUrl,
    'ssl-required': 'none',
    resource: 'pmc-front',
    'public-client': false,
    // "confidential-port": 0,
    'enable-cors': true,
    'cors-allowed-methods': 'GET, PUT, POST, HEAD, OPTIONS',
    'cors-allowed-headers': 'Origin, Accept, X-Requested-With, Content-Type, Last-Event-ID',
    checkLoginIframe: false,
});

const token = localStorage.getItem('kc_token');
const refreshToken = localStorage.getItem('kc_refreshToken');
const timeSkew = 0;

const hasRole = (role) => keycloak && keycloak.hasResourceRole(role);


export const checkKeycloakAuthentication = () => {
    if (!keycloak.isTokenExpired(30)) {
        return;
    }

    updateToken();
};

export const updateToken = (callback, callbackWhenNotRefreshed, minValidity) => {
    keycloak.updateToken(minValidity || 30).success((refreshed) => {
        if (refreshed) {
            callback && callback(localStorage.getItem('kc_token'));
            console.log('Token was successfully refreshed');
        } else {
            callbackWhenNotRefreshed && callbackWhenNotRefreshed();
        }

    }).error((err) => {
        throw err;
    });
}

export const keycloakInitConfig = {
    onLoad: 'login-required',
    token: token || '',
    refreshToken: refreshToken || '',
    timeSkew,
    checkLoginIframe: false,
};

export const loadAndStoreUserFromPMC = (store, userInfo, userId) => {
    httpService
        .get(`/user/${userId}`)
        .then(({data}) => {
            const useCases = {};
            store.dispatch({
                type: actionTypes.SET_USER_INFO,
                payload: {
                    userUseCases: data && data.useCases && data.useCases.reduce((obj, item) => {
                        return {
                            ...obj,
                            [item['code']]: item,
                        };
                    }, useCases) || [],
                    user: data,
                },
            });
        })
        .catch((err) => Promise.reject(err.response));


    const userLocale = userInfo.attributes.locale ? userInfo.attributes.locale[0] : 'en_us';
    if (i18n.options.whitelist.includes(userLocale)) {
        i18n.changeLanguage(userLocale).catch((err) => {
            console.log('i18n changeLanguage: ', err);
        });
    }
};

export const loadSounds = (store) => {
    httpService
        .get('/sounds')
        .then(({data}) => {
            store.dispatch({
                type: actionTypes.SET_SOUNDS,
                payload: {
                    sounds: data 
                },
            });
        })
        .catch((err) => Promise.reject(err.response));
};

export const logout = (store) => {
    store.dispatch({
        type: actionTypes.LOG_OUT,
    });
    localStorage.removeItem('sip_calls');
    localStorage.removeItem('kc_token');
    localStorage.removeItem('kc_idToken');
    localStorage.removeItem('kc_refreshToken');

    keycloak.logout();
};


export const onKeycloakEvent = (store) => (event, error) => {
    if (event === 'onAuthSuccess') {
        keycloak.loadUserProfile()
            .success((userInfo) => {
                loadAndStoreUserFromPMC(store, userInfo, keycloak.subject);
                loadSounds(store);
            })
            .error((err) => {
                console.log('loadUserProfile: ', err);
                logout(store);
            });
    } else if (error) {
        console.log('onKeycloakEvent', event, error);
    }
};

export const onKeycloakTokens = (tokens) => {
    if (tokens.token && tokens.idToken && tokens.refreshToken) { // TODO sort of workaround. has to be fixed properly
        localStorage.setItem('kc_token', tokens.token);
        localStorage.setItem('kc_idToken', tokens.idToken);
        localStorage.setItem('kc_refreshToken', tokens.refreshToken);
    }
};

export const addSipParkingToUser = (userId, parkingId) => httpService
    .put(`/user/${userId}/add?sipParkingId=${parkingId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

export const removeSipParkingToUser = (userId, parkingId) => httpService
    .put(`/user/${userId}/remove?sipParkingId=${parkingId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));
