import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {CustomTable} from "../../elements";
import moment from "moment/min/moment-with-locales";
import i18next from "i18next";
import {fetchPosHistory} from "../../../redux/actions/pos";
import PMCDialog from "../../common/PMCDialog";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            // boxShadow: theme.palette.shadow.main,
        },
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    title: {
        background: "#004980",
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
        fontSize: '12px',

    },
    root: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    root1: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
        paddingBottom: '24px',
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    actionButton: {
        marginLeft: theme.spacing(1),
        '@media print': {
            display: 'none'
        }
    },

}));

const cellStyle = {
    border: '1px solid grey',
    padding: '4px 4px',
    lineHeight: '25px',
    color: '#1B1B28',
    textAlign: 'center',
    fontSize: '15px'
};


function getActions(actions, t) {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {actions.map((item, i) =>
                <div key={i}>  {t(item.name, {
                    oldValue: item.valueOld,
                    newValue: item.valueNew,
                })}</div>
            )}
        </div>
    );

}

function PosHistory(props) {
    const {
        userUseCases,
        posHistory,
        handleClose,
        fetchPosHistory,
        posHistoryModel,
        isFetchingHistory
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();

    const columns = [
        {
            title: t('VERSION'),
            field: 'number',
            cellStyle,
            render: (rowData) => (
                rowData.version
            ),
        },
        {
            title: t('DATE'), field: 'date', cellStyle, render: (rowData) => (
                <>
                    {rowData.date
                        ? moment(new Date(rowData.date)).locale(i18next.language).format('llll')
                        : ''}
                </>
            ),
        },
        {
            title: t('PERSON'),
            field: 'userName',
            cellStyle,
            render: (rowData) => (
                rowData.userName
            ),
        },
        {
            title: t('ACTION'),
            field: 'action',
            cellStyle,
            render: (rowData) => {
                return getActions(rowData.actions, t);
            },
        },


    ];


    useEffect(() => {
        if (posHistoryModel.open && posHistoryModel.pos && posHistoryModel.pos.posId) {
            fetchPosHistory(posHistoryModel.pos.posId).then(
                () => {
                }
            ).catch((err) => {

            });
        }
    }, [fetchPosHistory, posHistoryModel]);


    return (

        <PMCDialog
            open={posHistoryModel.open}
            title={`${posHistoryModel.pos.name} - ${t('HISTORY')}`}
            onClose={handleClose}
            size="lg"
            headerColor="#800000"
            height={800}
            flexDirection='initial'
        >

            <CustomTable
                title=""
                columns={columns}
                data={
                    posHistory}
                options={{
                    headerStyle: {
                        color: '#ffffff',
                        textAlign: 'center',
                        backgroundColor: 'grey',
                        border: '1px solid black',
                        lineHeight: '10px',
                    },
                    paging: false,
                    sorting: false,
                }}
                isLoading={isFetchingHistory}
                loadAllData
            />
        </PMCDialog>

    );
}

PosHistory.propTypes = {};


const mapStateToProps = (store) => ({
    posHistory: store.posData.posHistory,
    isFetchingHistory: store.posData.isFetchingHistory,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPosHistory,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PosHistory);


