import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';
import { CustomCircleChart, CustomLineChart } from 'components/elements';

const useStyles = makeStyles((theme) => ({
  mr: {
    marginRight: theme.spacing(1),
  },
  mb: {
    marginBottom: theme.spacing(1),
  },
  zoneCard: {
    height: 454,
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(2),
  },
  zoneTitle: {
    fontSize: 20,
    color: theme.palette.base[400],
  },
  editIcon: {
    color: theme.palette.base[400],
  },
  zoneCardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  circleChart: {
    fontWeight: 800,

    '& .apexcharts-datalabel-label': {
      fontWeight: 'normal',
    },
  },
  graphWrapper: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    height: 150,
    position: 'absolute',
    left: theme.spacing(1.5),
    bottom: theme.spacing(2),

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  zoneStatus: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(2),
    textAlign: 'right',
  },
  zoneStatusLabel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
  zoneStatusValue: {
    fontSize: 30,
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  error: {
    color: theme.palette.primary.red,
    fontSize: 20,
  },
}));

function ZoneCard(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    zone,
    cardWidth,
    histories,
    handleEditZoneOpen,
    userUseCases,
  } = props;

  const handleEditZone = (zone) => () => {
    handleEditZoneOpen(zone);
  };

  const occupationHistories = histories;
  const categories = [];
  const chartData = [];

  occupationHistories.sort((a, b) => {
    const dateA = a.dateTS;
    const dateB = b.dateTS;

    if (dateA < dateB) {
      return -1;
    }

    return (dateA > dateB) ? 1 : 0;
  });

  const getHour = (history) => parseInt(history.date.slice(11, 13), 10);

  const lastHour = occupationHistories.length > 0
    ? getHour(occupationHistories[occupationHistories.length - 1])
    : 0;

  for (let i = 0; i < 24; i++) {
    const historyByHour = occupationHistories.find((history) => getHour(history) === i);
    categories.push(i);
    if (occupationHistories!==null && occupationHistories.length > 0 && historyByHour) {
      /* eslint-disable-next-line no-mixed-operators */
      chartData.push(Math.floor(historyByHour.occupancy * 100 / historyByHour.capacity));
    } else {
      chartData.push(i + 1 < lastHour ? 0 : -100000);
    }
  }

  const isReservedValid = zone.capacity >= zone.reserved; // zone.reserved >= 0 &&
  const isFreeReservedValid = zone.freeReserved <= zone.reserved; // zone.freeReserved >= 0 &&
  const isFreeOthersValid = zone.freeOthers <= zone.capacity - zone.reserved; // zone.freeOthers >= 0 &&

  return (
    <div
      className={classes.zoneCard}
      style={{ width: cardWidth }}
    >
      <div className={classes.zoneCardHeader}>
        {isComponentVisibleForUser(UC_CARD_ZONE_EDIT, userUseCases) &&
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open edit"
          disabled={!isComponentEnabledForUser(UC_CARD_ZONE_EDIT, userUseCases)}
          onClick={handleEditZone(zone)}
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>
            }
        <div className={classes.zoneTitle}>
          {zone.zoneName || t('NO_NAME')}
        </div>
      </div>

      <div className={classes.circleChart}>
        <CustomCircleChart
          height={250}
          series={[zone.progress!==null?zone.progress:'-']}
          label={t('OCCUPANCY')}
        />
      </div>

      <div className={classes.zoneStatus}>
        <div className={classes.mb}>
          <Typography variant="h6" className={classes.zoneStatusLabel}>{t('CAPACITY')}</Typography>
          <Typography
            variant="h1"
            className={classnames(classes.zoneStatusValue, !zone.validData && classes.error)}
          >
            {zone.validData ? (zone.capacity!==null?zone.capacity:'-') : t('INVALID_DATA')}
          </Typography>
        </div>

        <div className={classes.mb}>
          <Typography variant="h6" className={classes.zoneStatusLabel}>{t('RESERVED')}</Typography>
          <Typography
            variant="h1"
            className={classnames(classes.zoneStatusValue, !isReservedValid && classes.error)}
          >
            {isReservedValid ? (zone.reserved!==null?zone.reserved:'-') : t('INVALID_DATA')}
          </Typography>
        </div>

        <div className={classes.mb}>
          <Typography variant="h6" className={classes.zoneStatusLabel}>{t('FREE_RESERVED')}</Typography>
          <Typography
            variant="h1"
            className={classnames(classes.zoneStatusValue, !isFreeReservedValid && classes.error)}
          >
            {isFreeReservedValid ? (zone.freeReserved!==null?zone.freeReserved:'-') : t('INVALID_DATA')}
          </Typography>
        </div>

        <div className={classes.mb}>
          <Typography variant="h6" className={classes.zoneStatusLabel}>{t('OTHERS')}</Typography>
          <Typography
            variant="h1"
            className={classnames(classes.zoneStatusValue, !isReservedValid && classes.error)}
          >
            {isReservedValid ? (zone.others!==null?zone.others:'-') : t('INVALID_DATA')}
          </Typography>
        </div>

        <div className={classes.mb}>
          <Typography variant="h6" className={classes.zoneStatusLabel}>{t('FREE_OTHERS')}</Typography>
          <Typography
            variant="h1"
            className={classnames(classes.zoneStatusValue, !isFreeOthersValid && classes.error)}
          >
            {isFreeOthersValid ? (zone.freeOthers!==null?zone.freeOthers:'-') : t('INVALID_DATA')}
          </Typography>
        </div>
      </div>

      <div className={classes.graphWrapper}>
        <CustomLineChart
          categories={categories}
          data={chartData}
          name={zone.zoneName || t('NO_NAME')}
        />
      </div>
    </div>
  );
}

ZoneCard.propTypes = {
  zone: PropTypes.object.isRequired,
  cardWidth: PropTypes.string.isRequired,
  histories: PropTypes.array.isRequired,
  handleEditZoneOpen: PropTypes.func.isRequired,
};

const UC_CARD_ZONE_EDIT = 'UC0019';



export default ZoneCard;
