import httpService from './http.service';

const getEnum = (hostId) => httpService
    .get(`/parkinghost/${hostId}/enum`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getParkingHosts = (parkingId) => httpService
    .get(`/parkinghosts/${parkingId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getParkingHostsBySubject = (hostType) => httpService
    .get('/parkinghosts/subject?hostType='+hostType)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const createParkingHostCommand = (hostId, command) => httpService
    .post(`/parkinghosts/${hostId}/command`, command)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const createParkingHostAction = (hostId, action) => httpService
    .post(`/parkinghosts/${hostId}/action`, action)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const updateHostsPosition = (hostsPosition) => httpService
    .put(`/parkinghosts/position`, hostsPosition)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateHost = (host) => httpService
    .put('/parkinghost', host)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    updateHost,
    updateHostsPosition,
    getParkingHosts, getParkingHostsBySubject, 
    createParkingHostCommand,
    createParkingHostAction,
    getEnum,
};
