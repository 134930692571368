import moment from 'moment';
import httpService from './http.service';


const getZReportsQuery = (filter, page, size) => {
    let query = '?';
    if (filter) {
        const queryParams = ['reportNumber', 'dateFrom', 'dateTo', 'userId'];

        for (let paramName in filter) {
            if (filter[paramName]) {
                query += `${paramName}=${encodeURIComponent((paramName === 'dateFrom' || paramName === 'dateTo') ?
                    moment(filter[paramName]).format('DD.MM.YYYY') : filter[paramName])}&`;
            }
        }
    }
    query += `page=${page}&size=${size}`;
    return query;
};


const getZReports = (filter, page, size) => httpService
    .get(`/zreport${getZReportsQuery(filter, page, size)}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const getZReportDetail = (computerId, zReportId) => httpService
    .get(`/zreport/detail?computerId=${computerId}&zReportId=${zReportId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const createZReport = (computerId) => httpService
    .post(`zreport/create?computerId=${computerId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const printZReport = (computerId, zReportId) => httpService
    .post(`zreport/print?computerId=${computerId}&zReportId=${zReportId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const printXReport = (computerId) => httpService
    .post(`xreport/print?computerId=${computerId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));



export default {
    printZReport,
    getZReports,
    getZReportDetail,
    createZReport,
    printXReport
};
