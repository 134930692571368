/* eslint-disable max-len */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { CustomSelect, CustomAreaChart } from 'components/elements';
import { createZone } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 548,
    padding: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.shadow.main,
  },
  date: {
    width: '100%',
    fontSize: 22,
    color: theme.palette.base[800],
  },
  selectWrapper: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(4),

    '& svg': {
      width: 24,
      height: 24,
      fill: theme.palette.base[300],
      marginTop: 0,
    },
  },
  graphWrapper: {
    width: '80%',
    height: '80%',
    paddingTop: theme.spacing(5),

    '& > div': {
      transform: 'scale(1.25)',
      transformOrigin: 'top left',
    },
  },
  emptyZones: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
}));

const MainChart = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    parking,
    occupancy
  } = props;

  const items = parking.zoneStatus.filter((zone) => zone.show).map((pz) => createZone(pz));

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [occupationHistory, setOccupationHistory] = useState([]);

  useEffect(() => {
      setOccupationHistory(occupancy);
  }, [occupancy]);

  const [selectValue, setSelectValue] = useState(items[0] ? items[0].value : '');

  const handleSelectChange = (value) => {
    setSelectValue(value);
  };

  const selectedItem = items.find((item) => item.value === selectValue);

  const getZoneHistory = (zoneId) => {
    if (occupationHistory && occupationHistory.length > 0) {
      return occupationHistory.filter((history) => history.zoneId === zoneId);
    }

    return [];
  };

  const occupationHistories = selectedItem ? getZoneHistory(selectedItem.zoneId) : [];
  const categories = [];
  const chartData = [];
  const occupancyData = [];
  const capacityData = [];

  occupationHistories.sort((a, b) => {
    const dateA = a.dateTS;
    const dateB = b.dateTS;

    if (dateA < dateB) {
      return -1;
    }

    return (dateA > dateB) ? 1 : 0;
  });

  const getHour = (history) => parseInt(history.date.slice(11, 13), 10);

  for (let i = 0; i < 24; i++) {
    const historyByHour = occupationHistories.find((history) => getHour(history) === i);
    categories.push(i > 9 ? `${i}:00` : `0${i}:00`);
    if (occupationHistories!==null && occupationHistories.length > 0 && historyByHour && historyByHour.capacity!==null) {
      /* eslint-disable-next-line no-mixed-operators */
      chartData.push(Math.floor(historyByHour.occupancy * 100 / historyByHour.capacity));
      occupancyData.push(historyByHour.occupancy);
      capacityData.push(historyByHour.capacity);
    } else {
      chartData.push(0);
      occupancyData.push(0);
      capacityData.push(0);
    }
  }

  const series = [
    {
      name: 'occupancy',
      data: chartData,
    },
  ];

  const areaChartOptions = {
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: true,
      // eslint-disable-next-line prefer-template
      custom: ({ series, seriesIndex, dataPointIndex }) => '<div>'
        + '    <div class="apexcharts-tooltip-title">'
        + categories[dataPointIndex]
        + '    </div>'
        + '    <div class="apexcharts-tooltip-series-group active" style="display: flex; align-items: flex-start;">'
        + '        <span class="apexcharts-tooltip-marker" style="margin-top: 10px; background-color: rgb(0, 143, 251);"></span>'
        + '        <div class="apexcharts-tooltip-text">'
        + '            <div class="apexcharts-tooltip-y-group">'
        + '                <span class="apexcharts-tooltip-text-label">'+t('CAPACITY')+': </span>'
        + '                <span class="apexcharts-tooltip-text-value">'
        + capacityData[dataPointIndex]
        + '                </span>'
        + '            </div>'
        + '            <div class="apexcharts-tooltip-y-group">'
        + '                <span class="apexcharts-tooltip-text-label">'+t('OCCUPANCY')+': </span>'
        + '                <span class="apexcharts-tooltip-text-value">'
        + occupancyData[dataPointIndex]
        + '                </span>'
        + '            </div>'
        + '            <div class="apexcharts-tooltip-y-group">'
        + '                <span class="apexcharts-tooltip-text-label">'+t('OCCUPANCY')+' %: </span>'
        + '                <span class="apexcharts-tooltip-text-value">'
        + series[seriesIndex][dataPointIndex]
        + '                </span>'
        + '            </div>'
        + '        </div>'
        + '    </div>'
        + '</div>',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      colors: ['transparent'],
    },
    grid: {
      borderColor: 'transparent',
    },
    fill: {
      type: 'solid',
    },
    xaxis: {
      type: 'string',
      categories,
    },
    yaxis: {
      min: 0,
      max: 100,
    },
  };

  return (
    <div className={classes.container}>
      {items.length > 0 ? (
        <>
          <div className={classes.date}>
            {`${t('OCCUPANCY')} ${t('FOR')} ${selectValue ? selectedItem.label : t('ALL_ZONES')}`}
          </div>
          <div className={classes.selectWrapper}>
            <CustomSelect
              value={selectValue}
              handleChange={handleSelectChange}
              items={items}
              placeholder={t('SELECT_YOUR_ZONE')}
              options={{ variant: 'filled' }}
            />
          </div>

          <div className={classes.graphWrapper}>
            <CustomAreaChart
              options={areaChartOptions}
              series={series}
            />
          </div>
        </>
      ) : (
        <div className={classes.emptyZones}>
          {t('NO_RECORDS_TO_DISPLAY')}
        </div>
      )}
    </div>
  );
};

MainChart.propTypes = {
  parking: PropTypes.object.isRequired,
  occupancy: PropTypes.array.isRequired,
};

export default MainChart;
