import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NoteIcon from '@material-ui/icons/Note';
import LayersIcon from '@material-ui/icons/Layers';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Add from "@material-ui/icons/Add";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


const useStyles = makeStyles((theme) => ({
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(10),
        padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));



export default function ProductMenus(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {t} = useTranslation();
    const classes = useStyles();

    const {handleProductDetail, userUseCases}= props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
                <IconButton
                    onClick={handleClick}
                    className={classes.addButton}
                    edge="start"
                    color="inherit"
                    aria-label="add product">
                    <Add className={classes.addIcon} />
                </IconButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {isComponentVisibleForUser(UC_PRODUCT, userUseCases) &&
                <StyledMenuItem onClick={() => {
                    {isComponentEnabledForUser(UC_PRODUCT, userUseCases) &&
                        handleProductDetail(0, 'P')
                    }
                }}>
                    <ListItemIcon>
                        <NoteIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={t('PRODUCT')}/>
                </StyledMenuItem>
                }
                {isComponentVisibleForUser(UC_MACRO, userUseCases) &&

                <StyledMenuItem onClick={() => {
                    {isComponentEnabledForUser(UC_MACRO, userUseCases) &&
                        handleProductDetail(0, 'MP')
                    }
                }}>
                    <ListItemIcon>
                        <LayersIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={t('MACRO_PRODUCT')}/>
                </StyledMenuItem>
                }
                {isComponentVisibleForUser(UC_DIC, userUseCases) &&
                <StyledMenuItem onClick={() => {
                    {isComponentEnabledForUser(UC_DIC, userUseCases) &&
                        handleProductDetail(0, 'D')
                    }
                }}>
                    <ListItemIcon>
                        <LocalOfferIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={t('DISCOUNT')}/>
                </StyledMenuItem>
                }
            </StyledMenu>
        </div>
    );
}
const UC_PRODUCT = 'UC0611';
const UC_MACRO = 'UC0618';
const UC_DIC = 'UC0619';
