import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {isComponentVisibleForUser} from 'utils';
import AdministrationList from './AdministrationList';


const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    subjectLink: {
        marginLeft: theme.spacing(1.5),
        textDecoration: 'none',
        cursor: 'pointer',
    },
}));

function AdministrationMainContent(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        user,
        userUseCases,
        sipCall,
        sipStatus,
    } = props;


    return (
        <>
            {isComponentVisibleForUser(UC_MENU_ADMINISTRATION, userUseCases) && (
                <div className={classes.breadcrumb}>
                    <Typography variant="button" className={classnames(classes.activeLink, classes.navLink)}>
                        {t('ADMINISTRATION')}
                    </Typography>
                </div>
            )}
            {isComponentVisibleForUser(UC_MENU_ADMINISTRATION, userUseCases) && (
                <AdministrationList
                    userUseCases={userUseCases}
                    sipCall={sipCall}
                    sipStatus={sipStatus}
                />
            )}
        </>
    );
}

AdministrationMainContent.propTypes = {
    user: PropTypes.object,
};

AdministrationMainContent.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({});

const UC_MENU_ADMINISTRATION = 'UC0600';

export default connect(
    mapStateToProps,
)(AdministrationMainContent);
