import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingList: false,
    isFetchingRates: false,
    currencies: [],
    rates: [],
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CURRENCIES_LIST_REQUEST:
            return {
                ...state,
                isFetchingList: true,
            };
        case types.CURRENCIES_LIST_SUCCESS:
            return {
                ...state,
                isFetchingList: false,
                currencies: action.payload,
            };
        case types.CURRENCIES_LIST_FAIL:
            return {
                ...state,
                isFetchingList: false,
                errors: action.payload.error,
            };
        case types.RATES_LIST_REQUEST:
            return {
                ...state,
                isFetchingRates: true,
            };
        case types.RATES_LIST_SUCCESS:
            return {
                ...state,
                isFetchingRates: false,
                rates: action.payload,
            };
        case types.RATES_LIST_FAIL:
            return {
                ...state,
                isFetchingRates: false,
                errors: action.payload.error,
            };
        default:
            return state;
    }
};
