import { getCardsQuery, getCardsQueryForProductExecution } from 'utils';
import httpService from './http.service';

const getParkingCards = (parkingId, owner, validTill, validity, blocked, zone, cardType, size, page, orderedColumnId, orderDirection) => httpService
  .get(`/cards/parking/${parkingId}${getCardsQuery(owner, validTill, validity, blocked, zone, cardType, size, page, orderedColumnId, orderDirection)}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const getParkingCardsForProductExecution = (parkingId, validTill, validity, blocked, lpn, cardNumber, size, page) => httpService
    .get(`/cards/parking/${parkingId}${getCardsQueryForProductExecution(validTill, validity, blocked, lpn, cardNumber, size, page)}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getParkingCardNumbers = (parkingId, cardNumber, cardType) => httpService
  .get(`/cards/parking/${parkingId}/cardNumber?cardNumber=${
    cardNumber === 0 ? '000000000000' : cardNumber
  }&cardType=${cardType}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const getCardDetail = (cardNumber) => httpService
  .get(`/carddetail/${cardNumber}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const getCardDetailByNumberOrLpn = (numberOrLpn) => httpService
  .get(`/carddetail/orlpn/${numberOrLpn}`)
  .then(({ data }) => data)
    .catch((err) => {
      throw err;
    });

const getCardGroups = (parkingId) => httpService
  .get(`/parkingcardgroup/${parkingId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const editMultiCards = (parkingId, cards) => httpService
  .post(`/carddetail/bulkaction?parkingId=${parkingId}`, cards)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const setCardActivate = (cardNumber, parkingId, activate) => httpService
  .post(`/carddetail/${cardNumber}/activate?active=${activate}&parkingId=${parkingId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const setCardBlock = (cardNumber, parkingId, block, blockingReason) => httpService
  // eslint-disable-next-line max-len
  .post(`/carddetail/${cardNumber}/block?block=${block}&parkingId=${parkingId}${blockingReason ? `&blockingReason=${blockingReason}` : ''}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const createCard = (card) => httpService
  .post(`/carddetail/${card.cardNumber}`, card)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const updateCard = (card) => httpService
  .put(`/carddetail/${card.cardNumber}`, card)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const changeAccess = (parkingId, accessId, cardId) => httpService
  .post(`/cards/access/${parkingId}/${cardId}/${accessId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getParkingCards,
  getParkingCardNumbers,
  getCardDetail,
  getCardDetailByNumberOrLpn,
  getCardGroups,
  setCardActivate,
  setCardBlock,
  createCard,
  updateCard,
  editMultiCards,
  getParkingCardsForProductExecution,
  changeAccess
};
