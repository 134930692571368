import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {CustomTable, Loading} from 'components/elements';
import {connect} from 'react-redux';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import {ActionButton} from "../../../components/common";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from "@material-ui/icons/Cancel";
import InfoIcon from '@material-ui/icons/Info';
import CertificateIcon from '@material-ui/icons/VerifiedUser';
import SecurityIcon from '@material-ui/icons/Security';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import PlusIcon from "@material-ui/icons/Add";
import PMCDialog from 'components/common/PMCDialog';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 20,
    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 20,
    },
    actions: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
    },
    parkingCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    yellowPaper: {
        background: theme.palette.secondary.lightYellow,

    },
    expiringPos: {
        backgroundColor: '#e5cfcc',
    },
    grey: {
        backgroundColor: theme.palette.base[100],
    },
    disabled: {
        backgroundImage: 'linear-gradient(135deg, #FFFFFF00 25%, #FFFFFF3F 25%, #FFFFFF3F 50%, #FFFFFF00 50%, #FFFFFF00 75%, #FFFFFF3F 75%, #FFFFFF3F 100%)',
        backgroundSize: '20px 20px',
    },
    root: {
        width: 380,
        height: 40,
        background: theme.palette.base[100],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    state: {
        display: 'flex',
    },
    row1: {
        width: 380,
        height: 90,
        fontSize: 14,
        padding: 5,
        marginTop: -10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        width: 380,
        height: 90,
        fontSize: 12,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: -30,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    row3: {
        width: 380,
        height: 20,
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 20,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    green2: {
        backgroundColor: theme.palette.primary.main,
        minWidth: '30%',
        color: theme.palette.base.white,
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue2: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 18,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
        marginLeft: 20
    },
    label2: {
        color: theme.palette.base[400],
        fontSize: 12,
        textAlign: 'right',
    },
    value2: {
        color: theme.palette.base[400],
        fontSize: 12,
        textAlign: 'right',
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function PlayList(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        playList,
        userUseCases,
        isLoading,
        handlePlayListDetail,
        table,
        handleDeletePlayList,
        confirmModalOpened,
        setConfirmModalOpened,
    } = props;
    
    const [actionInProgress, setActionInProgress] = useState(false);
    const [playListToDelete, setPlayListToDelete] = useState();

    const renderPlayListState = (param) => {
        if (!param) {
            return <Tooltip title={t('PLAY_LIST_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        } else {
            if ( param.active )
                return <Tooltip title={t('PLAY_LIST_ACTIVE')}><CertificateIcon color="primary" style={{ fontSize: 40 }}/></Tooltip>
            else                
                return <Tooltip title={t('PLAY_LIST_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        }
    }

    const renderHostName = (param) => {
        if ( param && param.hosts) {
            let hosts='';
            for (let i = 0; i < param.hosts.length; i++) {
                if ( hosts === '') {
                    hosts=param.hosts[i].hostName;
                } else {
                    hosts+=','+param.hosts[i].hostName;
                }
            }
            return <>{hosts}</>
        } 
    }

    const renderFileName = (param) => {
        if ( param && param.items) {
            let items='';
            for (let i = 0; i < param.items.length; i++) {
                if ( items === '') {
                    items=param.items[i].fileName;
                } else {
                    items+=','+param.items[i].fileName;
                }
            }
            return <>{items}</>
        } 
    }

    const columns = [
        {   title: t('STATE'),
            field: 'active',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                renderPlayListState(rowData)
            )
        },
        {
            title: t('NAME'),
            field: 'name',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.name
            )
        },
        {
            title: t('FILE_NAMES'),
            field: 'file',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderFileName(rowData)
            ),
        },
        {
            title: t('HOSTS_NAMES'),
            field: 'host',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderHostName(rowData)
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC_PLAY_LIST_DETAIL, userUseCases) &&
                        <Tooltip title={t('SHOW_PLAY_LIST_DETAIL')}>
                            <ActionButton 
                                handleClick={() => {handlePlayListDetail(rowData, false, true)}}
                                disabled={!isComponentEnabledForUser(UC_PLAY_LIST_DETAIL, userUseCases) || actionInProgress}
                                className={classes.green2}
                                action="detail"
                            />
                        </Tooltip>
                    }
                    {isComponentVisibleForUser(UC_PLAY_LIST_UPDATE, userUseCases) &&
                        <Tooltip title={t('SHOW_USER_EDIT')}>
                            <ActionButton 
                                handleClick={() => {handlePlayListDetail(rowData, false, false)}}
                                disabled={!isComponentEnabledForUser(UC_PLAY_LIST_UPDATE, userUseCases) || actionInProgress}
                                className={classes.red2}
                                action="edit"
                            />
                        </Tooltip>
                    }
                    {isComponentVisibleForUser(UC_PLAY_LIST_ACTIVATE, userUseCases) &&
                        <Tooltip title={t('SHOW_PLAY_LIST_DELETE')}>
                            <ActionButton 
                                handleClick={() => {
                                    setPlayListToDelete(rowData);
                                    setConfirmModalOpened(true);
                                }}
                                disabled={!isComponentEnabledForUser(UC_PLAY_LIST_ACTIVATE, userUseCases) || actionInProgress}
                                className={classes.blue2}
                                action={t('DELETE')}
                            />
                        </Tooltip>
                    }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {confirmModalOpened
                && (    
                <PMCDialog
                    open={confirmModalOpened}
                    title={t('DELETE_PLAY_LIST')}
                    onClose={() => {
                        setPlayListToDelete(null);
                        setConfirmModalOpened(false);
                    }}
                    size="sm"
                    headerColor='#5985EE'
                    height={300}
                    onSubmit={ () => {handleDeletePlayList(playListToDelete);}}
                    actionType={'delete'}
                >
                    
                    <div className={classes.content}>
                        {t('DELETE_PLAY_LIST_DIALOG', {playList: playListToDelete.name})}
                    </div>
                </PMCDialog>
            )}            
            {!isLoading ?
                table ? <CustomTable
                    title=""
                    columns={columns}
                    data={playList}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                />:
                playList.map((playListItem, index) => (
                    <div className={classes.parkingCard} key={'playlist_'+index}>

                        <div className={classes.row1}>
                            <div className={classes.state}>
                                {renderPlayListState(playListItem)}
                            </div>
                            <div className={classes.title}>
                                <Tooltip title={t('NAME')}>
                                    <Typography variant="h4">{playListItem.name}</Typography>
                                </Tooltip>
                            </div>
                            <div className={classes.actionIcon}>
                                {isComponentVisibleForUser(UC_PLAY_LIST_UPDATE, userUseCases) &&
                                <Tooltip title={t('EDIT')}>
                                    <IconButton onClick={() => {
                                            handlePlayListDetail(playListItem, false, false)
                                        }}
                                        disabled={!isComponentEnabledForUser(UC_PLAY_LIST_UPDATE, userUseCases)}>
                                        <EditIcon color="disabled" style={{ fontSize: 30 }}/>
                                    </IconButton>
                                </Tooltip>
                                }
                            </div>
                        </div>
                        <div className={classes.row3}>
                            <div className={classes.rowColumn}>
                                <div className={classes.label}>{t('FILE_COUNT')}</div>
                                <div className={classes.value}>{playListItem.itemCount}</div>
                            </div>
                            <div className={classes.rowColumn2}>
                            </div>
                            <div className={classes.rowColumn}>
                                <div className={classes.label}>{t('APM_COUNT')}</div>
                                <div className={classes.value}>{playListItem.hostsCount}</div>
                            </div>
                        </div>
                        <BottomNavigation
                            className={classes.root}
                        >
                            {isComponentVisibleForUser(UC_PLAY_LIST_DETAIL, userUseCases) &&
                                <Tooltip title={t('SHOW_PLAY_LIST_DETAIL')}>
                                    <BottomNavigationAction onClick={() => {handlePlayListDetail(playListItem, false, true)}}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC_PLAY_LIST_DETAIL, userUseCases)}
                                        className={classes.blue}
                                        label="Detail"
                                        icon={<InfoIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                            {isComponentVisibleForUser(UC_PLAY_LIST_ACTIVATE, userUseCases) &&
                                <Tooltip title={t('SHOW_PLAY_LIST_DELETE')}>
                                    <BottomNavigationAction onClick={() => {
                                        setPlayListToDelete(playListItem);
                                        setConfirmModalOpened(true);
                                    }}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC_PLAY_LIST_ACTIVATE, userUseCases)}
                                        className={classes.orange}
                                        label="Active"
                                        icon={playListItem.active?<CancelIcon className={classes.actionIcon}/>:<PlusIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                        </BottomNavigation>
                    </div>
                ))
                :
                <Loading/>
            }
        </div>
    );
}
PlayList.propTypes = {
    parkings: PropTypes.array.isRequired,
};
const UC_PLAY_LIST_DETAIL = 'UC0674';
const UC_PLAY_LIST_UPDATE = 'UC0672';
const UC_PLAY_LIST_ACTIVATE = 'UC0673';

PlayList.defaultProps = {};
const mapStateToProps = (store) => ({});

export default connect(
    mapStateToProps,
)(PlayList);