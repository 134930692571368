import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingParkingList: false,

  parkingErrors: [],

  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARKING_ERRORS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingList: true,
      };
    case types.PARKING_ERRORS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingList: false,
        parkingErrors: action.payload.parkingErrors,
      };
    case types.PARKING_ERRORS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingList: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
