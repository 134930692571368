import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { CustomTable } from 'components/elements';
import { getCodeList } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mr: {
    marginRight: theme.spacing(2.5),
  },
}));

const cellStyle = {
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: '19px',
  color: '#60636B',
  textAlign: 'center',
};

function EquipmentErrors(props) {
  const classes = useStyles();

  const {
    active,
    parking,
    allCodeList,
    equipmentErrors,
    fetchParkingErrors,
  } = props;

  const { t } = useTranslation();

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [isFetchedErrors, setIsFetchedErrors] = useState(false);
  const fetchCards = useCallback(() => {
    fetchParkingErrors(parking.parkingId)
      .then(() => {
        if (!isUnMounted.current) {
          setIsLoading(false);
          setIsFetchedErrors(true);
        }
      }).catch(() => {
        if (!isUnMounted.current) {
          setIsLoading(false);
        }
      });
  }, [fetchParkingErrors, parking.parkingId]);

  useEffect(() => {
    if (active) {
      setIsFetchedErrors(false);
    } else {
      setIsFetchedErrors(true);
    }
  }, [active]);

  useEffect(() => {
    if (!isFetchedErrors && active) {
      setIsLoading(true);
      fetchCards();
    }
  }, [active, fetchCards, isFetchedErrors]);

  const codeList = getCodeList(allCodeList, 'HOST_TYPE');
  const getCode = (type) => {
    const item = codeList.find((item) => item.code === type.toString());
    if (!item) {
      return null;
    }

    return item.description;
  };

  const columns = [
    {
      title: t('CODE'),
      field: 'type',
      cellStyle,
      render: (rowData) => (
        <>{getCode(rowData.type)}</>
      ),
    },
    { title: t('NAME'), field: 'hostName', cellStyle },
    { title: t('CRITICAL_ERRORS'), field: 'criticatError', cellStyle },
    { title: t('MINOR_ERRORS'), field: 'minorError', cellStyle },
  ];

  return (
    <div className={classes.container}>
      <CustomTable
        title=""
        columns={columns}
        data={equipmentErrors}
        options={{
          headerStyle: {
            padding: '15px 8px 15px 34px',
            borderTop: '1px solid #DEE2E5',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
          },
        }}
        isLoading={isLoading}
      />
    </div>
  );
}

EquipmentErrors.propTypes = {
  active: PropTypes.bool.isRequired,
  parking: PropTypes.object.isRequired,
  allCodeList: PropTypes.array.isRequired,
  equipmentErrors: PropTypes.array.isRequired,
  fetchParkingErrors: PropTypes.func.isRequired,
};

export default EquipmentErrors;
