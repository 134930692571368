import {EventSourcePolyfill} from 'event-source-polyfill';

import types from 'redux/actionTypes';
import {memorySizeOf} from '../utils';
import {updateToken} from './keycloak.service';


let sseParking;

const baseURL = process.env.REACT_APP_API;

const startSseParkingES = (token, posId) => {
    sseParking = new EventSourcePolyfill(
        `${baseURL}/sse/parking?computerId=${posId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
};

const onerrorSseParkingES = (token, store, posId, setToken) => (e) => {
    sseParking.close();
    updateToken(setToken, () => {
        initializeSseParkingES(token, store, posId, setToken);
    }, -1);
};

let i = 0;

const messageSseParkingES = (store) => (eventResponse) => {
    const data = JSON.parse(eventResponse.data);

  //  console.warn('data', data);
   // data.cardNumbersDto = [];
 //   data.cardNumbersDto[0] = {cardNumber: '3728046189797'};
//    data.cardNumbersDto[1] = {cardNumber: '111'};
   // console.log('sse object size:' + memorySizeOf(data));
  //  i++;*/
    store.dispatch({
        type: types.PARKINGS_FETCH_SUCCESS,
        payload: {
            cardNumber: data.cardNumbersDto != null ? data.cardNumbersDto[0].cardNumber : null,
      //     cardNumber: data.cardNumbersDto != null ? data.cardNumbersDto[i % 2].cardNumber : null,
            parkings: data.parkings && data.parkings.map((p) => ({
                ...p,
                isConnected: p.communicationStatus !== 'NOTOK',
            })),
            events: data.events && data.events.map((p) => ({
                ...p,
                isConnected: p.communicationStatus !== 'NOTOK',
            })),
            parkingHosts: data.parkingHosts && data.parkingHosts.map((p) => ({
                ...p,
                isConnected: p.communicationStatus !== 'NOTOK',
            })),
            occupancy: data.occupancy && data.occupancy.map((p) => ({
                ...p,
                isConnected: p.communicationStatus !== 'NOTOK',
            })),
            posStatutes: data.posStatutes,
            soundId: data.soundId
        },
    });
};

export const closeSseParkingES = () => {
    if (sseParking) {
        sseParking.close();
    }
};

export const initializeSseParkingES = (token, store, posId, setToken) => {
    startSseParkingES(token, posId);
    sseParking.addEventListener('message', messageSseParkingES(store));
    sseParking.addEventListener('error', onerrorSseParkingES(token, store, posId, setToken));
};
