import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ParkingCards from '../ParkingCards';


const useStyles = makeStyles((theme) => ({
    formFields: {
        width: '100%',
        height: '100%',
    },
}));

function WizardStep2(props) {
    const classes = useStyles();

    const {
        product,
        userUseCases,
        selectedCard,
        setSelectedCard,
        cardNumber,
        setCardNumber,
    } = props;

    const {t} = useTranslation();

    return (
        <div className={classes.formFields}>
            <ParkingCards
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                userUseCases={userUseCases}
                parking={{parkingId: product ? product.parkingId: undefined}}></ParkingCards>
        </div>);


}

WizardStep2.propTypes = {};

WizardStep2.defaultProps = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep2);


