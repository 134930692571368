export default {
    // Set user info
    SET_USER_INFO: 'SET_USER_INFO',
    // Logout
    LOG_OUT: 'LOG_OUT',
    // api error
    API_ERROR: 'API_ERROR',
    API_SUCCESS: 'API_SUCCESS',

    SET_SOUNDS: 'SET_SOUNDS',

    // sip parking to user
    ADD_SIP_PARKING_TO_USER_SUCCESS: 'ADD_SIP_PARKING_TO_USER_SUCCESS',
    ADD_SIP_PARKING_TO_USER_FAIL: 'ADD_SIP_PARKING_TO_USER_FAIL',
    REMOVE_SIP_PARKING_TO_USER_SUCCESS: 'REMOVE_SIP_PARKING_TO_USER_SUCCESS',
    REMOVE_SIP_PARKING_TO_USER_FAIL: 'REMOVE_SIP_PARKING_TO_USER_FAIL',

    // Fetch parking access
    PARKING_ACCESS_FETCH_REQUEST: 'PARKING_ACCESS_FETCH_REQUEST',
    PARKING_ACCESS_FETCH_SUCCESS: 'PARKING_ACCESS_FETCH_SUCCESS',
    PARKING_ACCESS_FETCH_FAIL: 'PARKING_ACCESS_FETCH_FAIL',

    // Fetch parking cnofiguration
    PARKING_CONFIGURATION_FETCH_REQUEST: 'PARKING_CONFIGURATION_FETCH_REQUEST',
    PARKING_CONFIGURATION_FETCH_SUCCESS: 'PARKING_CONFIGURATION_FETCH_SUCCESS',
    PARKING_CONFIGURATION_FETCH_FAIL: 'PARKING_CONFIGURATION_FETCH_FAIL',

    PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_REQUEST: 'PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_REQUEST',
    PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_SUCCESS: 'PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_SUCCESS',
    PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_FAIL: 'PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_FAIL',

    PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_REQUEST: 'PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_REQUEST',
    PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_SUCCESS: 'PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_SUCCESS',
    PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_FAIL: 'PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_FAIL',

    PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_REQUEST: 'PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_REQUEST',
    PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_SUCCESS: 'PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_SUCCESS',
    PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_FAIL: 'PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_FAIL',


    // Fetch parkings
    PARKINGS_FETCH_SUCCESS: 'PARKINGS_FETCH_SUCCESS',

    // Update parking
    PARKINGS_UPDATE_REQUEST: 'PARKINGS_UPDATE_REQUEST',
    PARKINGS_UPDATE_SUCCESS: 'PARKINGS_UPDATE_SUCCESS',
    PARKINGS_UPDATE_FAIL: 'PARKINGS_UPDATE_FAIL',


    // Create parking
    PARKINGS_CREATE_REQUEST: 'PARKINGS_CREATE_REQUEST',
    PARKINGS_CREATE_SUCCESS: 'PARKINGS_CREATE_SUCCESS',
    PARKINGS_CREATE_FAIL: 'PARKINGS_CREATE_FAIL',

    // Connect parking
    PARKINGS_CONNECT_REQUEST: 'PARKINGS_CONNECT_REQUEST',
    PARKINGS_CONNECT_SUCCESS: 'PARKINGS_CONNECT_SUCCESS',
    PARKINGS_CONNECT_FAIL: 'PARKINGS_CONNECT_FAIL',

    // Restart parking
    PARKING_RESTART_REQUEST: 'PARKING_RESTART_REQUEST',
    PARKING_RESTART_SUCCESS: 'PARKING_RESTART_SUCCESS',
    PARKING_RESTART_FAIL: 'PARKING_RESTART_FAIL',


    // Fetch parking events
    PARKING_EVENTS_FETCH_REQUEST: 'PARKING_EVENTS_FETCH_REQUEST',
    PARKING_EVENTS_FETCH_SUCCESS: 'PARKING_EVENTS_FETCH_SUCCESS',
    PARKING_EVENTS_FETCH_FAIL: 'PARKING_EVENTS_FETCH_FAIL',

    // Fetch subject events
    SUBJECT_EVENTS_FETCH_REQUEST: 'SUBJECT_EVENTS_FETCH_REQUEST',
    SUBJECT_EVENTS_FETCH_SUCCESS: 'SUBJECT_EVENTS_FETCH_SUCCESS',
    SUBJECT_EVENTS_FETCH_FAIL: 'SUBJECT_EVENTS_FETCH_FAIL',

    // Fetch parking tasks
    PARKING_TASKS_FETCH_REQUEST: 'PARKING_TASKS_FETCH_REQUEST',
    PARKING_TASKS_FETCH_SUCCESS: 'PARKING_TASKS_FETCH_SUCCESS',
    PARKING_TASKS_FETCH_FAIL: 'PARKING_TASKS_FETCH_FAIL',

    // Fetch parking tasks statistics
    PARKING_TASKS_STATISTICS_FETCH_REQUEST: 'PARKING_TASKS_STATISTICS_FETCH_REQUEST',
    PARKING_TASKS_STATISTICS_FETCH_SUCCESS: 'PARKING_TASKS_STATISTICS_FETCH_SUCCESS',
    PARKING_TASKS_STATISTICS_FETCH_FAIL: 'PARKING_TASKS_STATISTICS_FETCH_FAIL',

    // Fetch task images
    TASK_IMAGES_FETCH_REQUEST: 'TASK_IMAGES_FETCH_REQUEST',
    TASK_IMAGES_FETCH_SUCCESS: 'TASK_IMAGES_FETCH_SUCCESS',
    TASK_IMAGES_FETCH_FAIL: 'TASK_IMAGES_FETCH_FAIL',

    // Create task
    TASK_CREATE_REQUEST: 'TASK_CREATE_REQUEST',
    TASK_CREATE_SUCCESS: 'TASK_CREATE_SUCCESS',
    TASK_CREATE_FAIL: 'TASK_CREATE_FAIL',

    // Cancle task
    TASK_CANCEL_REQUEST: 'TASK_CANCEL_REQUEST',
    TASK_CANCEL_SUCCESS: 'TASK_CANCEL_SUCCESS',
    TASK_CANCEL_FAIL: 'TASK_CANCEL_FAIL',

    // Renew task
    TASK_RENEW_REQUEST: 'TASK_RENEW_REQUEST',
    TASK_RENEW_SUCCESS: 'TASK_RENEW_SUCCESS',
    TASK_RENEW_FAIL: 'TASK_RENEW_FAIL',

    // Fetch parking zones
    PARKING_ZONES_FETCH_REQUEST: 'PARKING_ZONES_FETCH_REQUEST',
    PARKING_ZONES_FETCH_SUCCESS: 'PARKING_ZONES_FETCH_SUCCESS',
    PARKING_ZONES_FETCH_FAIL: 'PARKING_ZONES_FETCH_FAIL',

    // Update zone
    ZONE_UPDATE_REQUEST: 'ZONE_UPDATE_REQUEST',
    ZONE_UPDATE_SUCCESS: 'ZONE_UPDATE_SUCCESS',
    ZONE_UPDATE_FAIL: 'ZONE_UPDATE_FAIL',

    // Fetch parking hosts
    PARKING_HOSTS_FETCH_REQUEST: 'PARKING_HOSTS_FETCH_REQUEST',
    PARKING_HOSTS_FETCH_SUCCESS: 'PARKING_HOSTS_FETCH_SUCCESS',
    PARKING_HOSTS_FETCH_FAIL: 'PARKING_HOSTS_FETCH_FAIL',

    // Fetch parking hosts by subject
    PARKING_HOSTS_BY_SUBJECT_FETCH_REQUEST: 'PARKING_HOSTS_BY_SUBJECT_FETCH_REQUEST',
    PARKING_HOSTS_BY_SUBJECT_FETCH_SUCCESS: 'PARKING_HOSTS_BY_SUBJECT_FETCH_SUCCESS',
    PARKING_HOSTS_BY_SUBJECT_FETCH_FAIL: 'PARKING_HOSTS_BY_SUBJECT_FETCH_FAIL',
    
    // Fetch parking hosts enum
    PARKING_HOSTS_ENUM_FETCH_REQUEST: 'PARKING_HOSTS_ENUM_FETCH_REQUEST',
    PARKING_HOSTS_ENUM_FETCH_SUCCESS: 'PARKING_HOSTS_ENUM_FETCH_SUCCESS',
    PARKING_HOSTS_ENUM_FETCH_FAIL: 'PARKING_HOSTS_ENUM_FETCH_FAIL',

    // Create host command
    PARKING_HOSTS_CREATE_COMMAND_REQUEST: 'PARKING_HOSTS_CREATE_COMMAND_REQUEST',
    PARKING_HOSTS_CREATE_COMMAND_SUCCESS: 'PARKING_HOSTS_CREATE_COMMAND_SUCCESS',
    PARKING_HOSTS_CREATE_COMMAND_FAIL: 'PARKING_HOSTS_CREATE_COMMAND_FAIL',

    // Create host action
    PARKING_HOSTS_CREATE_ACTION_REQUEST: 'PARKING_HOSTS_CREATE_ACTION_REQUEST',
    PARKING_HOSTS_CREATE_ACTION_SUCCESS: 'PARKING_HOSTS_CREATE_ACTION_SUCCESS',
    PARKING_HOSTS_CREATE_ACTION_FAIL: 'PARKING_HOSTS_CREATE_ACTION_FAIL',

    // Update host positions
    PARKING_HOSTS_POSITION_UPDATE_REQUEST: 'PARKING_HOSTS_POSITION_UPDATE_REQUEST',
    PARKING_HOSTS_POSITION_UPDATE_SUCCESS: 'PARKING_HOSTS_POSITION_UPDATE_SUCCESS',
    PARKING_HOSTS_POSITION_UPDATE_FAIL: 'PARKING_HOSTS_POSITION_UPDATE_FAIL',

    // Update parking
    PARKING_HOSTS_UPDATE_REQUEST: 'PARKING_HOSTS_UPDATE_REQUEST',
    PARKING_HOSTS_UPDATE_SUCCESS: 'PARKING_HOSTS_UPDATE_SUCCESS',
    PARKING_HOSTS_UPDATE_FAIL: 'PARKING_HOSTS_UPDATE_FAIL',



    // SIP Parking actions
    PARKING_PARKING_SELECT: 'PARKING_PARKING_SELECT',
    PARKING_HOSTNAME_SELECT: 'PARKING_HOSTNAME_SELECT',
    PROCESS_ACTION: 'PROCESS_ACTION',

    // Fetch parking cards
    PARKING_CARDS_FETCH_REQUEST: 'PARKING_CARDS_FETCH_REQUEST',
    PARKING_CARDS_FETCH_SUCCESS: 'PARKING_CARDS_FETCH_SUCCESS',
    PARKING_CARDS_FETCH_FAIL: 'PARKING_CARDS_FETCH_FAIL',

    // Fetch parking card numbers
    PARKING_CARD_NUMBERS_FETCH_REQUEST: 'PARKING_CARD_NUMBERS_FETCH_REQUEST',
    PARKING_CARD_NUMBERS_FETCH_SUCCESS: 'PARKING_CARD_NUMBERS_FETCH_SUCCESS',
    PARKING_CARD_NUMBERS_FETCH_FAIL: 'PARKING_CARD_NUMBERS_FETCH_FAIL',

    // Fetch parking card groups
    PARKING_CARD_GROUPS_FETCH_REQUEST: 'PARKING_CARD_GROUPS_FETCH_REQUEST',
    PARKING_CARD_GROUPS_FETCH_SUCCESS: 'PARKING_CARD_GROUPS_FETCH_SUCCESS',
    PARKING_CARD_GROUPS_FETCH_FAIL: 'PARKING_CARD_GROUPS_FETCH_FAIL',

    // Edit multiple card
    CARD_EDIT_BULK_REQUEST: 'CARD_EDIT_BULK_REQUEST',
    CARD_EDIT_BULK_SUCCESS: 'CARD_EDIT_BULK_SUCCESS',
    CARD_EDIT_BULK_FAIL: 'CARD_EDIT_BULK_FAIL',

    // Activate card
    CARD_ACTIVATE_REQUEST: 'CARD_ACTIVATE_REQUEST',
    CARD_ACTIVATE_SUCCESS: 'CARD_ACTIVATE_SUCCESS',
    CARD_ACTIVATE_FAIL: 'CARD_ACTIVATE_FAIL',

    // Block card
    CARD_BLOCK_REQUEST: 'CARD_BLOCK_REQUEST',
    CARD_BLOCK_SUCCESS: 'CARD_BLOCK_SUCCESS',
    CARD_BLOCK_FAIL: 'CARD_BLOCK_FAIL',

    // Create card
    CARD_CREATE_REQUEST: 'CARD_CREATE_REQUEST',
    CARD_CREATE_SUCCESS: 'CARD_CREATE_SUCCESS',
    CARD_CREATE_FAIL: 'CARD_CREATE_FAIL',

    // Update card
    CARD_UPDATE_REQUEST: 'CARD_UPDATE_REQUEST',
    CARD_UPDATE_SUCCESS: 'CARD_UPDATE_SUCCESS',
    CARD_UPDATE_FAIL: 'CARD_UPDATE_FAIL',

    // Create access change
    CARD_ACCESS_CHANGE_REQUEST: 'CARD_ACCESS_CHANGE_REQUEST',
    CARD_ACCESS_CHANGE_SUCCESS: 'CARD_ACCESS_CHANGE_SUCCESS',
    CARD_ACCESS_CHANGE_FAIL: 'CARD_ACCESS_CHANGE_FAIL',
    
    // Get card detail
    CARD_DETAIL_REQUEST: 'CARD_DETAIL_REQUEST',
    CARD_DETAIL_SUCCESS: 'CARD_DETAIL_SUCCESS',
    CARD_DETAIL_FAIL: 'CARD_DETAIL_FAIL',

    // Fetch subject owners
    SUBJECT_OWNERS_FETCH_REQUEST: 'SUBJECT_OWNERS_FETCH_REQUEST',
    SUBJECT_OWNERS_FETCH_SUCCESS: 'SUBJECT_OWNERS_FETCH_SUCCESS',
    SUBJECT_OWNERS_FETCH_FAIL: 'SUBJECT_OWNERS_FETCH_FAIL',

    // Fetch subject search owners
    SUBJECT_SEARCH_OWNERS_FETCH_REQUEST: 'SUBJECT_SEARCH_OWNERS_FETCH_REQUEST',
    SUBJECT_SEARCH_OWNERS_FETCH_SUCCESS: 'SUBJECT_SEARCH_OWNERS_FETCH_SUCCESS',
    SUBJECT_SEARCH_OWNERS_FETCH_FAIL: 'SUBJECT_SEARCH_OWNERS_FETCH_FAIL',

    // Fetch owner detail
    OWNER_DETAIL_REQUEST: 'OWNER_DETAIL_REQUEST',
    OWNER_DETAIL_SUCCESS: 'OWNER_DETAIL_SUCCESS',
    OWNER_DETAIL_FAIL: 'OWNER_DETAIL_FAIL',

    // Activate owner
    OWNER_ACTIVATE_REQUEST: 'OWNER_ACTIVATE_REQUEST',
    OWNER_ACTIVATE_SUCCESS: 'OWNER_ACTIVATE_SUCCESS',
    OWNER_ACTIVATE_FAIL: 'OWNER_ACTIVATE_FAIL',

    // New owner
    NEW_OWNER: 'NEW_OWNER',

    // Update owner
    OWNER_UPDATE_REQUEST: 'OWNER_UPDATE_REQUEST',
    OWNER_UPDATE_SUCCESS: 'OWNER_UPDATE_SUCCESS',
    OWNER_UPDATE_FAIL: 'OWNER_UPDATE_FAIL',

    // Fetch card journals
    CARD_JOURNALS_FETCH_REQUEST: 'CARD_JOURNALS_FETCH_REQUEST',
    CARD_JOURNALS_FETCH_SUCCESS: 'CARD_JOURNALS_FETCH_SUCCESS',
    CARD_JOURNALS_FETCH_FAIL: 'CARD_JOURNALS_FETCH_FAIL',

    // Fetch card payment
    CARD_PAYMENT_FETCH_REQUEST: 'CARD_PAYMENT_FETCH_REQUEST',
    CARD_PAYMENT_FETCH_SUCCESS: 'CARD_PAYMENT_FETCH_SUCCESS',
    CARD_PAYMENT_FETCH_FAIL: 'CARD_PAYMENT_FETCH_FAIL',

    // Fetch parking errors
    PARKING_ERRORS_FETCH_REQUEST: 'PARKING_ERRORS_FETCH_REQUEST',
    PARKING_ERRORS_FETCH_SUCCESS: 'PARKING_ERRORS_FETCH_SUCCESS',
    PARKING_ERRORS_FETCH_FAIL: 'PARKING_ERRORS_FETCH_FAIL',

    // Fetch code list
    ALL_CODE_FETCH_REQUEST: 'ALL_CODE_FETCH_REQUEST',
    ALL_CODE_FETCH_SUCCESS: 'ALL_CODE_FETCH_SUCCESS',
    ALL_CODE_FETCH_FAIL: 'ALL_CODE_FETCH_FAIL',

    // Fetch all mobile devices
    MOBILE_DEVICES_FETCH_REQUEST: 'MOBILE_DEVICES_FETCH_REQUEST',
    MOBILE_DEVICES_FETCH_SUCCESS: 'MOBILE_DEVICES_FETCH_SUCCESS',
    MOBILE_DEVICES_FETCH_FAIL: 'MOBILE_DEVICES_FETCH_FAIL',

    // Update mobile device
    UPDATE_MOBILE_DEVICE_REQUEST: 'UPDATE_MOBILE_DEVICE_REQUEST',
    UPDATE_MOBILE_DEVICE_SUCCESS: 'UPDATE_MOBILE_DEVICE_SUCCESS',
    UPDATE_MOBILE_DEVICE_FAIL: 'UPDATE_MOBILE_DEVICE_FAIL',

    // Update multiple devices
    UPDATE_MULTIPLE_DEVICES_REQUEST: 'UPDATE_MULTIPLE_DEVICES_REQUEST',
    UPDATE_MULTIPLE_DEVICES_SUCCESS: 'UPDATE_MULTIPLE_DEVICES_SUCCESS',
    UPDATE_MULTIPLE_DEVICES_FAIL: 'UPDATE_MULTIPLE_DEVICES_FAIL',

    // Delete mobile device
    DELETE_MOBILE_DEVICE_REQUEST: 'DELETE_MOBILE_DEVICE_REQUEST',
    DELETE_MOBILE_DEVICE_SUCCESS: 'DELETE_MOBILE_DEVICE_SUCCESS',
    DELETE_MOBILE_DEVICE_FAIL: 'DELETE_MOBILE_DEVICE_FAIL',

    // Fetch parking users
    PARKING_USERS_FETCH_REQUEST: 'PARKING_USERS_FETCH_REQUEST',
    PARKING_USERS_FETCH_SUCCESS: 'PARKING_USERS_FETCH_SUCCESS',
    PARKING_USERS_FETCH_FAIL: 'PARKING_USERS_FETCH_FAIL',

    // Fetch parking users
    PARKING_USERS_FETCH_ALL_REQUEST: 'PARKING_USERS_FETCH_ALL_REQUEST',
    PARKING_USERS_FETCH_ALL_SUCCESS: 'PARKING_USERS_FETCH_ALL_SUCCESS',
    PARKING_USERS_FETCH_ALL_FAIL: 'PARKING_USERS_FETCH_ALL_FAIL',
    
    // Fetch role users
    PARKING_ROLE_FETCH_ALL_REQUEST: 'PARKING_ROLE_FETCH_ALL_REQUEST',
    PARKING_ROLE_FETCH_ALL_SUCCESS: 'PARKING_ROLE_FETCH_ALL_SUCCESS',
    PARKING_ROLE_FETCH_ALL_FAIL: 'PARKING_ROLE_FETCH_ALL_FAIL',

    // Fetch parking users
    SEARCH_USERS_FETCH_REQUEST: 'SEARCH_USERS_FETCH_REQUEST',
    SEARCH_USERS_FETCH_SUCCESS: 'SEARCH_USERS_FETCH_SUCCESS',
    SEARCH_USERS_FETCH_FAIL: 'SEARCH_USERS_FETCH_FAIL',

    // Fetch subject users
    PARKING_ASSIGNED_USERS_FETCH_REQUEST: 'PARKING_ASSIGNED_USERS_FETCH_REQUEST',
    PARKING_ASSIGNED_USERS_FETCH_SUCCESS: 'PARKING_ASSIGNED_USERS_FETCH_SUCCESS',
    PARKING_ASSIGNED_USERS_FETCH_FAIL: 'PARKING_ASSIGNED_USERS_FETCH_FAIL',

    // Fetch parking users
    PARKING_NOT_ASSIGNED_USERS_FETCH_REQUEST: 'PARKING_NOT_ASSIGNED_USERS_FETCH_REQUEST',
    PARKING_NOT_ASSIGNED_USERS_FETCH_SUCCESS: 'PARKING_NOT_ASSIGNED_USERS_FETCH_SUCCESS',
    PARKING_NOT_ASSIGNED_USERS_FETCH_FAIL: 'PARKING_NOT_ASSIGNED_USERS_FETCH_FAIL',

    // Fetch subject users
    PARKING_SUBJECT_USERS_FETCH_REQUEST: 'PARKING_SUBJECT_USERS_FETCH_REQUEST',
    PARKING_SUBJECT_USERS_FETCH_SUCCESS: 'PARKING_SUBJECT_USERS_FETCH_SUCCESS',
    PARKING_SUBJECT_USERS_FETCH_FAIL: 'PARKING_SUBJECT_USERS_FETCH_FAIL',


    // Add parking to user
    ADD_PARKING_TO_USER_REQUEST: 'ADD_PARKING_TO_USER_REQUEST',
    ADD_PARKING_TO_USER_SUCCESS: 'ADD_PARKING_TO_USER_SUCCESS',
    ADD_PARKING_TO_USER_FAIL: 'ADD_PARKING_TO_USER_FAIL',

    // Remove parking from user
    REMOVE_PARKING_FROM_USER_REQUEST: 'REMOVE_PARKING_FROM_USER_REQUEST',
    REMOVE_PARKING_FROM_USER_SUCCESS: 'REMOVE_PARKING_FROM_USER_SUCCESS',
    REMOVE_PARKING_FROM_USER_FAIL: 'REMOVE_PARKING_FROM_USER_FAIL',

    // Create user
    PARKING_USER_CREATE_REQUEST: 'PARKING_USER_CREATE_REQUEST',
    PARKING_USER_CREATE_SUCCESS: 'PARKING_USER_CREATE_SUCCESS',
    PARKING_USER_CREATE_FAIL: 'PARKING_USER_CREATE_FAIL',

    // Update user
    PARKING_USER_UPDATE_REQUEST: 'PARKING_USER_UPDATE_REQUEST',
    PARKING_USER_UPDATE_SUCCESS: 'PARKING_USER_UPDATE_SUCCESS',
    PARKING_USER_UPDATE_FAIL: 'PARKING_USER_UPDATE_FAIL',

    // Fetch about info
    PARKING_ABOUT_FETCH_REQUEST: 'PARKING_ABOUT_FETCH_REQUEST',
    PARKING_ABOUT_FETCH_SUCCESS: 'PARKING_ABOUT_FETCH_SUCCESS',
    PARKING_ABOUT_FETCH_FAIL: 'PARKING_ABOUT_FETCH_FAIL',

    // Fetch download info
    PARKING_DOWNLOAD_FETCH_REQUEST: 'PARKING_DOWNLOAD_FETCH_REQUEST',
    PARKING_DOWNLOAD_FETCH_SUCCESS: 'PARKING_DOWNLOAD_FETCH_SUCCESS',
    PARKING_DOWNLOAD_FETCH_FAIL: 'PARKING_DOWNLOAD_FETCH_FAIL',

    // Fetch download file
    PARKING_DOWNLOAD_FILE_FETCH_REQUEST: 'PARKING_DOWNLOAD_FILE_FETCH_REQUEST',
    PARKING_DOWNLOAD_FILE_FETCH_SUCCESS: 'PARKING_DOWNLOAD_FILE_FETCH_SUCCESS',
    PARKING_DOWNLOAD_FILE_FETCH_FAIL: 'PARKING_DOWNLOAD_FILE_FETCH_FAIL',

    // Fetch about photo
    PHOTO_START_FETCH_REQUEST: 'PHOTO_START_FETCH_REQUEST',
    PHOTO_START_FETCH_SUCCESS: 'PHOTO_START_FETCH_SUCCESS',
    PHOTO_START_FETCH_FAIL: 'PHOTO_START_FETCH_FAIL',

    // Fetch about photo
    PHOTO_STOP_FETCH_REQUEST: 'PHOTO_STOP_FETCH_REQUEST',
    PHOTO_STOP_FETCH_SUCCESS: 'PHOTO_STOP_FETCH_SUCCESS',
    PHOTO_STOP_FETCH_FAIL: 'PHOTO_STOP_FETCH_FAIL',

    // init product
    PRODUCT_INIT_REQUEST: 'PRODUCT_INIT_REQUEST',
    PRODUCT_INIT_SUCCESS: 'PRODUCT_INIT_SUCCESS',
    PRODUCT_INIT_FAIL: 'PRODUCT_INIT_FAIL',


    // Fetch product
    PRODUCT_FETCH_REQUEST: 'PRODUCT_FETCH_REQUEST',
    PRODUCT_FETCH_SUCCESS: 'PRODUCT_FETCH_SUCCESS',
    PRODUCT_FETCH_FAIL: 'PRODUCT_FETCH_FAIL',

    // Fetch all products
    PRODUCTS_FETCH_REQUEST: 'PRODUCTS_FETCH_REQUEST',
    PRODUCTS_FETCH_SUCCESS: 'PRODUCTS_FETCH_SUCCESS',
    PRODUCTS_FETCH_FAIL: 'PRODUCTS_FETCH_FAIL',

    // Fetch all cashdesk products
    CASHDESK_PRODUCTS_FETCH_REQUEST: 'CASHDESK_PRODUCTS_FETCH_REQUEST',
    CASHDESK_PRODUCTS_FETCH_SUCCESS: 'CASHDESK_PRODUCTS_FETCH_SUCCESS',
    CASHDESK_PRODUCTS_FETCH_FAIL: 'CASHDESK_PRODUCTS_FETCH_FAIL',


    // Fetch product template select param
    PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_REQUEST: 'PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_REQUEST',
    PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_SUCCESS: 'PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_SUCCESS',
    PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_FAIL: 'PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_FAIL',

    // Fetch all product groups
    PRODUCT_GROUPS_FETCH_REQUEST: 'PRODUCT_GROUPS_FETCH_REQUEST',
    PRODUCT_GROUPS_FETCH_SUCCESS: 'PRODUCT_GROUPS_FETCH_SUCCESS',
    PRODUCT_GROUPS_FETCH_FAIL: 'PRODUCT_GROUPS_FETCH_FAIL',

    // Fetch all product template
    PRODUCT_TEMPLATES_FETCH_REQUEST: 'PRODUCT_TEMPLATES_FETCH_REQUEST',
    PRODUCT_TEMPLATES_FETCH_SUCCESS: 'PRODUCT_TEMPLATES_FETCH_SUCCESS',
    PRODUCT_TEMPLATES_FETCH_FAIL: 'PRODUCT_TEMPLATES_FETCH_FAIL',

    BRANCHES_FETCH_REQUEST: 'BRANCHES_FETCH_REQUEST',
    BRANCHES_FETCH_SUCCESS: 'BRANCHES_FETCH_SUCCESS',
    BRANCHES_PARAM_FETCH_SUCCESS: 'BRANCHES_PARAM_FETCH_SUCCESS',
    BRANCHES_FETCH_FAIL: 'BRANCHES_FETCH_FAIL',

    BRANCH_CREATE_REQUEST: 'BRANCH_CREATE_REQUEST',
    BRANCH_CREATE_SUCCESS: 'BRANCH_CREATE_SUCCESS',
    BRANCH_CREATE_FAIL: 'BRANCH_CREATE_FAIL',

    BRANCH_UPDATE_REQUEST: 'BRANCH_UPDATE_REQUEST',
    BRANCH_UPDATE_SUCCESS: 'BRANCH_UPDATE_SUCCESS',
    BRANCH_UPDATE_FAIL: 'BRANCH_UPDATE_FAIL',

    ROLES_FETCH_REQUEST: ' ROLES_FETCH_REQUEST',
    ROLES_FETCH_SUCCESS: 'ROLES_FETCH_SUCCESS',
    ROLES_FETCH_FAIL: ' ROLES_FETCH_FAIL',

    ROLES_UPDATE_REQUEST: 'ROLES_UPDATE_REQUEST',
    ROLES_UPDATE_SUCCESS: 'ROLES_UPDATE_SUCCESS',
    ROLES_UPDATE_FAIL: 'ROLES_UPDATE_FAIL',

    ROLES_ACTIVE_REQUEST: 'ROLES_ACTIVE_REQUEST',
    ROLES_ACTIVE_SUCCESS: 'ROLES_ACTIVE_SUCCESS',
    ROLES_ACTIVE_FAIL: 'ROLES_ACTIVE_FAIL',

    ROLES_CREATE_REQUEST: 'ROLES_CREATE_REQUEST',
    ROLES_CREATE_SUCCESS: 'ROLES_CREATE_SUCCESS',
    ROLES_CREATE_FAIL: 'ROLES_CREATE_FAIL',

    ROLES_ADD_ROLE_REQUEST: 'ROLES_ADD_ROLE_REQUEST',
    ROLES_ADD_ROLE_SUCCESS: 'ROLES_ADD_ROLE_SUCCESS',
    ROLES_ADD_ROLE_FAIL: 'ROLES_ADD_ROLE_FAIL',

    ROLES_REMOVE_ROLE_REQUEST: 'ROLES_REMOVE_ROLE_REQUEST',
    ROLES_REMOVE_ROLE_SUCCESS: 'ROLES_REMOVE_ROLE_SUCCESS',
    ROLES_REMOVE_ROLE_FAIL: 'ROLES_REMOVE_ROLE_FAIL',

    SUBJECTS_FETCH_REQUEST: 'SUBJECTS_FETCH_REQUEST',
    SUBJECTS_FETCH_SUCCESS: 'SUBJECTS_FETCH_SUCCESS',
    SUBJECTS_PARAM_FETCH_SUCCESS: 'SUBJECTS_PARAM_FETCH_SUCCESS',
    SUBJECTS_FETCH_FAIL: 'SUBJECTS_FETCH_FAIL',

    SUBJECT_CREATE_REQUEST: 'SUBJECT_CREATE_REQUEST',
    SUBJECT_CREATE_SUCCESS: 'SUBJECT_CREATE_SUCCESS',
    SUBJECT_CREATE_FAIL: 'SUBJECT_CREATE_FAIL',

    SUBJECT_UPDATE_REQUEST: 'SUBJECT_UPDATE_REQUEST',
    SUBJECT_UPDATE_SUCCESS: 'SUBJECT_UPDATE_SUCCESS',
    SUBJECT_UPDATE_FAIL: 'SUBJECT_UPDATE_FAIL',

    PRODUCT_UPDATE_REQUEST: 'PRODUCT_UPDATE_REQUEST',
    PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
    PRODUCT_UPDATE_FAIL: 'PRODUCT_UPDATE_FAIL',

    PRODUCT_CREATE_REQUEST: 'PRODUCT_CREATE_REQUEST',
    PRODUCT_CREATE_SUCCESS: 'PRODUCT_CREATE_SUCCESS',
    PRODUCT_CREATE_FAIL: 'PRODUCT_CREATE_FAIL',

    PRODUCT_EXECUTE_REQUEST: 'PRODUCT_EXECUTE_REQUEST',
    PRODUCT_EXECUTE_SUCCESS: 'PRODUCT_EXECUTE_SUCCESS',
    PRODUCT_EXECUTE_FAIL: 'PRODUCT_EXECUTE_FAIL',

    RECEIPTS_LIST_REQUEST: 'RECEIPTS_LIST_REQUEST',
    RECEIPTS_LIST_SUCCESS: 'RECEIPTS_LIST_SUCCESS',
    RECEIPTS_LIST_FAIL: 'RECEIPTS_LIST_FAIL',

    RECEIPTS_FISCALIZED_LIST_SUCCESS: 'RECEIPTS_FISCALIZED_LIST_SUCCESS',
    RECEIPTS_FISCALIZED_LIST_FAIL: 'RECEIPTS_FISCALIZED_LIST_FAIL',

    RECEIPT_CANCEL_FAIL: 'RECEIPT_CANCEL_FAIL',
    RECEIPT_CANCEL_SUCCESS: 'RECEIPT_CANCEL_SUCCESS',
    RECEIPT_CANCEL_REQUEST: 'RECEIPT_CANCEL_REQUEST',

    RECEIPT_OK_FAIL: 'RECEIPT_OK_FAIL',
    RECEIPT_OK_SUCCESS: 'RECEIPT_OK_SUCCESS',
    RECEIPT_OK_REQUEST: 'RECEIPT_OK_REQUEST',

    RECEIPT_CANCEL_BY_ID_FAIL: 'RECEIPT_CANCEL_BY_ID_FAIL',
    RECEIPT_CANCEL_BY_ID_SUCCESS: 'RECEIPT_CANCEL_BY_ID_SUCCESS',
    RECEIPT_CANCEL_BY_ID_REQUEST: 'RECEIPT_CANCEL_BY_ID_REQUEST',


    RECEIPT_PAY_FAIL: 'RECEIPT_PAY_FAIL',
    RECEIPT_PAY_SUCCESS: 'RECEIPT_PAY_SUCCESS',
    RECEIPT_PAY_REQUEST: 'RECEIPT_PAY_REQUEST',

    RECEIPT_PAY_CARD_FAIL: 'RECEIPT_PAY_CARD_FAIL',
    RECEIPT_PAY_CARD_SUCCESS: 'RECEIPT_PAY_CARD_SUCCESS',
    RECEIPT_PAY_CARD_REQUEST: 'RECEIPT_PAY_CARD_REQUEST',

    RECEIPT_CHANGE_CURRENCY_FAIL: 'RECEIPT_CHANGE_CURRENCY_FAIL',
    RECEIPT_CHANGE_CURRENCY_SUCCESS: 'RECEIPT_CHANGE_CURRENCY_SUCCESS',
    RECEIPT_CHANGE_CURRENCY_REQUEST: 'RECEIPT_CHANGE_CURRENCY_REQUEST',

    CURRENCIES_LIST_REQUEST: 'CURRENCIES_LIST_REQUEST',
    CURRENCIES_LIST_SUCCESS: 'CURRENCIES_LIST_SUCCESS',
    CURRENCIES_LIST_FAIL: 'CURRENCIES_LIST_FAIL',

    RATES_LIST_REQUEST: 'RATES_LIST_REQUEST',
    RATES_LIST_SUCCESS: 'RATES_LIST_SUCCESS',
    RATES_LIST_FAIL: 'RATES_LIST_FAIL',


    ZREPORTS_LIST_REQUEST: 'ZREPORTS_LIST_REQUEST',
    ZREPORTS_LIST_SUCCESS: 'ZREPORTS_LIST_SUCCESS',
    ZREPORTS_LIST_FAIL: 'ZREPORTS_LIST_FAIL',

    ZREPORTS_DETAIL_REQUEST: 'ZREPORTS_DETAIL_REQUEST',
    ZREPORTS_DETAIL_SUCCESS: 'ZREPORTS_DETAIL_SUCCESS',
    ZREPORTS_DETAIL_FAIL: 'ZREPORTS_DETAIL_FAIL',

    ZREPORTS_CREATION_SUCCESS: 'ZREPORTS_CREATION_SUCCESS',

    ZREPORTS_PRINT_REQUEST: 'ZREPORTS_PRINT_REQUEST',
    ZREPORTS_PRINT_SUCCESS: 'ZREPORTS_PRINT_SUCCESS',
    ZREPORTS_PRINT_FAIL: 'ZREPORTS_PRINT_FAIL',

    XREPORTS_PRINT_REQUEST: 'XREPORTS_PRINT_REQUEST',
    XREPORTS_PRINT_SUCCESS: 'XREPORTS_PRINT_SUCCESS',
    XREPORTS_PRINT_FAIL: 'XREPORTS_PRINT_FAIL',


    // Fetch subject events
    FULLTEXT_FETCH_REQUEST: 'FULLTEXT_FETCH_REQUEST',
    FULLTEXT_FETCH_SUCCESS: 'FULLTEXT_FETCH_SUCCESS',
    FULLTEXT_FETCH_FAIL: 'FULLTEXT_FETCH_FAIL',


    //POS
    POS_FETCH_REQUEST: 'POS_FETCH_REQUEST',
    POS_FETCH_SUCCESS: 'POS_FETCH_SUCCESS',
    POS_FETCH_FAIL: 'POS_FETCH_FAIL',


    POS_HISTORY_FETCH_REQUEST: 'POS_HISTORY_FETCH_REQUEST',
    POS_HISTORY_FETCH_SUCCESS: 'POS_HISTORY_FETCH_SUCCESS',
    POS_HISTORY_FETCH_FAIL: 'POS_HISTORY_FETCH_FAIL',

    POS_UPDATE_REQUEST: 'POS_UPDATE_REQUEST',
    POS_UPDATE_SUCCESS: 'POS_UPDATE_SUCCESS',
    POS_UPDATE_FAIL: 'POS_UPDATE_FAIL',

    POS_CREATE_REQUEST: 'POS_CREATE_REQUEST',
    POS_CREATE_SUCCESS: 'POS_CREATE_SUCCESS',
    POS_CREATE_FAIL: 'POS_CREATE_FAIL',

    // use cases
    USECASE_FETCH_REQUEST: 'USECASE_FETCH_REQUEST',
    USECASE_FETCH_SUCCESS: 'USECASE_FETCH_SUCCESS',
    USECASE_FETCH_FAIL: 'USECASE_FETCH_FAIL',

    PHOTODETAIL_FETCH_REQUEST: 'PHOTODETAIL_FETCH_REQUEST',
    PHOTODETAIL_FETCH_SUCCESS: 'PHOTODETAIL_FETCH_SUCCESS',
    PHOTODETAIL_FETCH_FAILT: 'PHOTODETAIL_FETCH_FAILT',


    PLAYLIST_FETCH_REQUEST: 'PLAYLIST_FETCH_REQUEST',
    PLAYLIST_FETCH_SUCCESS: 'PLAYLIST_FETCH_SUCCESS',
    PLAYLIST_FETCH_FAILT: 'PLAYLIST_FETCH_FAILT',

    PLAYLIST_DETAIL_FETCH_REQUEST: 'PLAYLIST_DETAIL_FETCH_REQUEST',
    PLAYLIST_DETAIL_FETCH_SUCCESS: 'PLAYLIST_DETAIL_FETCH_SUCCESS',
    PLAYLIST_DETAIL_FETCH_FAILT: 'PLAYLIST_DETAIL_FETCH_FAILT',

    PLAYLIST_CREATE_REQUEST: 'PLAYLIST_CREATE_REQUEST',
    PLAYLIST_CREATE_SUCCESS: 'PLAYLIST_CREATE_SUCCESS',
    PLAYLIST_CREATE_FAIL: 'PLAYLIST_CREATE_FAIL',

    PLAYLIST_UPDATE_REQUEST: 'PLAYLIST_UPDATE_REQUEST',
    PLAYLIST_UPDATE_SUCCESS: 'PLAYLIST_UPDATE_SUCCESS',
    PLAYLIST_UPDATE_FAIL: 'PLAYLIST_UPDATE_FAIL',

    PLAYLIST_DELETE_REQUEST: 'PLAYLIST_DELETE_REQUEST',
    PLAYLIST_DELETE_SUCCESS: 'PLAYLIST_DELETE_SUCCESS',
    PLAYLIST_DELETE_FAIL: 'PLAYLIST_DELETE_FAIL',

};
