import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { statuses } from './Utils';
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  hostStatus: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  hostStatusColumnGreen: {
    height: 20,
    width: '100%',
    fontSize: 13,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    borderBottom: theme.palette.icon.green2d,
  },
  hostStatusColumnRed: {
    height: 20,
    width: '100%',
    fontSize: 13,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    borderBottom: theme.palette.icon.red2d,
  },
  hostStatusColumnYellow: {
    height: 20,
    width: '100%',
    fontSize: 13,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    borderBottom: theme.palette.icon.yellow2d,
  },
  statusColorGreen: {
    width: 3,
    marginRight: '5px',
    opacity: 0.9,
    backgroundColor: theme.palette.icon.green,
  },
  statusColorRed: {
    width: 3,
    marginRight: '5px',
    opacity: 0.9,
    backgroundColor: theme.palette.icon.red,
  },
  statusColorYellow: {
    width: 3,
    marginRight: '5px',
    opacity: 0.9,
    backgroundColor: theme.palette.icon.yellow,
  },
  hostStatusLabel: {
    width: '50%',
    color: theme.palette.base[800],
    textTransform: 'uppercase',
    marginBottom: '5px',
  },
  hostStatusValue: {
    width: '50%',
    color: theme.palette.base[800],
    textTransform: 'uppercase',
    textAlign: 'right',
    paddingRight: 5,
  },
}));

function HostStatuses(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { host } = props;

  let hostStatus = host && host.message && host.message.split(',');
  hostStatus = hostStatus || [];

  const hostStatusTag = (status) => (
    status && status.name
      ? (
        <div
          className={
            status.color==='green' && classes.hostStatusColumnGreen ||
            status.color==='red' && classes.hostStatusColumnRed ||
            status.color==='yellow' && classes.hostStatusColumnYellow
        }
          key={`setting_${status.name}_${status.status}`}
        >
          <div
            className={
              status.color==='green' && classes.statusColorGreen ||
              status.color==='red' && classes.statusColorRed ||
              status.color==='yellow' && classes.statusColorYellow
           }
          />
          <div className={classes.hostStatusLabel}>{t(status.name)}</div>
          <div className={classes.hostStatusValue}>{status.name==='DisplayText'?host.displayText:t(status.status)}</div>
        </div>
      )
      : null
  );

  return (
    <div>
      {host && host.message ? (
        <div className={classes.hostStatus}>
          {hostStatus.map((hs) => {
            const status = hs && hs.replace('.', '_');
            return hostStatusTag(statuses[status]);
          })}
        </div>
      ) : null}
    </div>
  );
}

HostStatuses.propTypes = {
  host: PropTypes.object,
};

HostStatuses.defaultProps = {
  host: { message: '' },
};

export default HostStatuses;
