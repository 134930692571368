import receiptsService from 'services/receipts.service';
import types from '../actionTypes';

export const filterReceipts = (filter, page, size, sortingField, sortingDesc) => async (dispatch, getState) => {
    dispatch({
        type: types.RECEIPTS_LIST_REQUEST,
        payload: filter,
    });

    try {
        const receipts = await receiptsService.getReceipts(filter, page, size, sortingField, sortingDesc);
        dispatch({
            type: types.RECEIPTS_LIST_SUCCESS,
            payload: receipts,
        });
    } catch (error) {
        dispatch({
            type: types.RECEIPTS_LIST_FAIL,
            payload: {error},
        });
    }
};


export const sendToEET = (computerId) => async (dispatch, getState) => {
    return receiptsService.sendToEET(computerId)
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw error;
        });

}

export const getFiscalizedReceipts = (page, size, computerId) => async (dispatch, getState) => {
    const filter = {
        fiscalized: false,
        computerId,
    }

    dispatch({
        type: types.RECEIPTS_LIST_REQUEST,
        payload: filter,
    });

    try {
        const receipts = await receiptsService.getReceipts(filter, page, size);
        dispatch({
            type: types.RECEIPTS_FISCALIZED_LIST_SUCCESS,
            payload: receipts,
        });
    } catch (error) {
        dispatch({
            type: types.RECEIPTS_FISCALIZED_LIST_FAIL,
            payload: {error},
        });
    }
};


export const cancelReceiptById = (receiptId, computerId) => (dispatch, getState) => {
    if (getState().receiptsData.isCanceling) {
        return;
    }

    dispatch({
        type: types.RECEIPT_CANCEL_BY_ID_REQUEST,
    });

    return receiptsService.cancelReceiptById(receiptId, computerId)
        .then(() => {
            dispatch({
                type: types.RECEIPT_CANCEL_BY_ID_SUCCESS,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_CANCEL_BY_ID_FAIL,
                payload: {error},
            });

            throw error;
        });
}

export const duplicateReceipt = (receiptId, computerId) => (dispatch, getState) => {
    if (getState().receiptsData.isCanceling) {
        return;
    }

    dispatch({
        type: types.RECEIPT_CANCEL_BY_ID_REQUEST,
    });

    return receiptsService.duplicateReceipt(receiptId, computerId)
        .then(() => {
            dispatch({
                type: types.RECEIPT_CANCEL_BY_ID_SUCCESS,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_CANCEL_BY_ID_FAIL,
                payload: {error},
            });

            throw error;
        });
}


export const cancelReceipt = (computerId) => (dispatch, getState) => {
    if (getState().receiptsData.isCanceling) {
        return;
    }

    dispatch({
        type: types.RECEIPT_CANCEL_REQUEST,
    });

    return receiptsService.cancelReceipt(computerId)
        .then((receipt) => {
            dispatch({
                type: types.RECEIPT_CANCEL_SUCCESS,
            });
            dispatch({
                type: types.PRODUCT_EXECUTE_SUCCESS,
                payload: {receipt},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_CANCEL_FAIL,
                payload: {error},
            });

            throw error;
        });
}

export const okReceipt = (paymentStatus) => (dispatch, getState) => {
    if (getState().receiptsData.isCanceling) {
        return;
    }

    dispatch({
        type: types.RECEIPT_OK_REQUEST,
    });

    return receiptsService.okReceipt(paymentStatus)
        .then(() => {
            dispatch({
                type: types.RECEIPT_OK_SUCCESS,
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_OK_FAIL,
                payload: {error},
            });

            throw error;
        });
}


export const payReceipt = (receipt, computerId) => (dispatch, getState) => {
    if (getState().receiptsData.isPaying) {
        return;
    }

    dispatch({
        type: types.RECEIPT_PAY_REQUEST,
    });

    return receiptsService.payProduct(receipt, computerId)
        .then((paymentError) => {
            dispatch({
                type: types.RECEIPT_PAY_SUCCESS,
                payload: {paymentError, receipt: paymentError.receiptItemId ? getState().receiptsData.receipt : {}},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_PAY_FAIL,
                payload: {error},
            });

            throw error;
        });
}

export const payCardReceipt = (receiptDto) => (dispatch, getState) => {
    if (getState().receiptsData.isPaying) {
        return;
    }

    dispatch({
        type: types.RECEIPT_PAY_CARD_REQUEST,
    });

    return receiptsService.payCardProduct(receiptDto)
        .then((paymentError) => {
            dispatch({
                type: types.RECEIPT_PAY_CARD_SUCCESS,
                payload: {paymentError, receipt: getState().receiptsData.receipt},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_PAY_CARD_FAIL,
                payload: {error},
            });

            throw error;
        });
}

export const doFiscalisation = (receipt, computerId) => (dispatch, getState) => {
    if (getState().receiptsData.isPaying) {
        return;
    }

    dispatch({
        type: types.RECEIPT_PAY_REQUEST,
    });

    return receiptsService.doFiscalisation(receipt, computerId)
        .then((paymentError) => {
            dispatch({
                type: types.RECEIPT_PAY_SUCCESS,
                payload: {paymentError},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_PAY_FAIL,
                payload: {error},
            });

            throw error;
        });
}


export const deposit = (deposit) => (dispatch, getState) => {

    return receiptsService.deposit(deposit)
        .then((receipt) => {
            return true;
        })
        .catch((error) => {
            throw error;
        });
}

export const withdraw = (withdraw) => (dispatch, getState) => {

    return receiptsService.deposit(withdraw)
        .then((receipt) => {
            return true;
        })
        .catch((error) => {
            throw error;
        });
}


export const executeProduct = (product, cardNo, computerId) => (dispatch, getState) => {
    if (getState().receiptsData.isExecutingProduct) {
        return Promise.reject();
    }

    dispatch({
        type: types.PRODUCT_EXECUTE_REQUEST,
    });

    return receiptsService.executeProduct(product, cardNo, computerId)
        .then((receipt) => {
            dispatch({
                type: types.PRODUCT_EXECUTE_SUCCESS,
                payload: {receipt},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_EXECUTE_FAIL,
                payload: {error},
            });

            throw error;
        });
}


export const deleteReceiptItem = (id) => (dispatch, getState) => {
    if (getState().receiptsData.isExecutingProduct) {
        return;
    }

    dispatch({
        type: types.PRODUCT_EXECUTE_REQUEST,
    });

    return receiptsService.deleteReceiptItem(id)
        .then((receipt) => {
            dispatch({
                type: types.PRODUCT_EXECUTE_SUCCESS,
                payload: {receipt},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_EXECUTE_FAIL,
                payload: {error},
            });

            throw error;
        });
}


export const changeCurrency = (receiptId, currency) => (dispatch, getState) => {
    if (getState().receiptsData.isChangingCurrency) {
        return;
    }

    dispatch({
        type: types.RECEIPT_CHANGE_CURRENCY_REQUEST,
    });

    return receiptsService.changeCurrency(receiptId, currency)
        .then((receipt) => {
            dispatch({
                type: types.RECEIPT_CHANGE_CURRENCY_SUCCESS,
                payload: {receipt},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_CHANGE_CURRENCY_FAIL,
                payload: {error},
            });

            throw error;
        });
}

