import mobilesService from 'services/mobiles.service';
import types from '../actionTypes';

export const fetchAllMobileDevices = () => (dispatch, getState) => {
  if (getState().mobilesData.isFetchingAllList) {
    return;
  }

  dispatch({
    type: types.MOBILE_DEVICES_FETCH_REQUEST,
  });

  return mobilesService.getAllMobileDevices()
    .then((mobileDevices) => {
      dispatch({
        type: types.MOBILE_DEVICES_FETCH_SUCCESS,
        payload: { mobileDevices },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.MOBILE_DEVICES_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const activeDevice = (device) => (dispatch, getState) => {
  if (getState().mobilesData.isUpdating) {
    return;
  }

  dispatch({
    type: types.UPDATE_MOBILE_DEVICE_REQUEST,
  });

  const { mobileDevices } = getState().mobilesData;
  const deviceIndex = mobileDevices.findIndex((d) => d.mobileDeviceId === device.mobileDeviceId);
  mobileDevices[deviceIndex] = device;

  return mobilesService.updateMobileDevice(device)
    .then(() => {
      dispatch({
        type: types.UPDATE_MOBILE_DEVICE_SUCCESS,
        payload: { mobileDevices },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_MOBILE_DEVICE_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const activeMultipleDevices = (devices) => (dispatch, getState) => {
  if (getState().mobilesData.isUpdating) {
    return;
  }

  dispatch({
    type: types.UPDATE_MULTIPLE_DEVICES_REQUEST,
  });


  const { mobileDevices } = getState().mobilesData;
  devices.forEach((device) => {
    const deviceIndex = mobileDevices.findIndex((d) => d.mobileDeviceId === device.mobileDeviceId);
    mobileDevices[deviceIndex] = device;
  });

  return mobilesService.updateMultipleDevices(devices)
    .then(() => {
      dispatch({
        type: types.UPDATE_MULTIPLE_DEVICES_SUCCESS,
        payload: { mobileDevices },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.UPDATE_MULTIPLE_DEVICES_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const removeDevice = (device) => (dispatch, getState) => {
  if (getState().mobilesData.isRemoving) {
    return;
  }

  dispatch({
    type: types.DELETE_MOBILE_DEVICE_REQUEST,
  });

  return mobilesService.deleteMobileDevice(device)
    .then(() => {
      const { mobileDevices } = getState().mobilesData;
      const deviceIndex = mobileDevices.findIndex((d) => d.mobileDeviceId === device.mobileDeviceId);
      if (deviceIndex > -1) {
        mobileDevices.splice(deviceIndex, 1);
      }

      dispatch({
        type: types.DELETE_MOBILE_DEVICE_SUCCESS,
        payload: { mobileDevices },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.DELETE_MOBILE_DEVICE_FAIL,
        payload: { error },
      });

      throw error;
    });
};
