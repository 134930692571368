import httpService from './http.service';

const getAllMobileDevices = () => httpService
  .get('/mobiledevices')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const updateMobileDevice = (device) => httpService
  .post('/mobiledevice', device)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const updateMultipleDevices = (devices) => httpService
  .post('/mobiledevices', devices)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

const deleteMobileDevice = (device) => httpService
  .remove('/mobiledevice', device)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getAllMobileDevices,
  updateMobileDevice,
  updateMultipleDevices,
  deleteMobileDevice,
};
