import errorsService from 'services/errors.service';
import types from '../actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchParkingErrors = (parkingId) => (dispatch, getState) => {
  if (getState().errorsData.isFetchingParkingList) {
    return Promise.reject();
  }

  dispatch({
    type: types.PARKING_ERRORS_FETCH_REQUEST,
  });

  return errorsService.getParkingErrors(parkingId)
    .then((parkingErrors) => {
      dispatch({
        type: types.PARKING_ERRORS_FETCH_SUCCESS,
        payload: { parkingErrors },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.PARKING_ERRORS_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};
