import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 370,
    height: 268,
    padding: theme.spacing(3.75),
    background: theme.palette.background.paper,
    boxShadow: theme.palette.shadow.main,
    color: theme.palette.base[800],
  },
  contact: {
    marginBottom: theme.spacing(1.5),
    fontSize: 22,
  },
  label: {
    marginTop: theme.spacing(1.25),
    fontSize: 13,
    fontWeight: 300,
    color: theme.palette.base[300],
  },
  content: {
    marginTop: theme.spacing(0.5),
    fontSize: 13,
    color: theme.palette.base[500],
  },
}));

const MainContact = (props) => {
  const classes = useStyles();

  const { parking } = props;

  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.contact}>{t('CONTACT_DETAILS')}</div>
      <div className={classes.label}>{t('ADDRESS')}</div>
      <div className={classes.content}>{parking.address || t('NO_ADDRESS')}</div>
      <div className={classes.label}>{t('PHONE')}</div>
      <div className={classes.content}>{parking.phone || t('NO_PHONE')}</div>
      <div className={classes.label}>{t('HELP_DESK')}</div>
      <div className={classes.content}>{parking.helpdeskPhone || t('NO_HELP_DESK_PHONE')}</div>
    </div>
  );
};

MainContact.propTypes = {
  parking: PropTypes.object.isRequired,
};

export default MainContact;
