import {getFormattedStr} from 'utils';
import moment from 'moment';
import httpService from './http.service';


const sendToEET = (computerId) => httpService
    .post(`/receipt/fiscalize?computerId=${computerId}`, computerId)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const getReceiptsQuery = (filter, page, size, sortingField, sortingDesc) => {
    let query = '?';
    if (filter) {
        const queryParams = ['cardNumber', 'computerId', 'receiptId', 'branchId', 'parkingId', 'userId', 'dateFrom', 'dateTo', 'amountFrom', 'amountTo', 'currency', 'state', 'fiscalized'];

        for (let paramName in filter) {
            if (filter[paramName] !== undefined && (paramName==='dateFrom' || paramName==='dateTo' ||filter[paramName].length>0)) {
                query += `${paramName}=${encodeURIComponent((paramName === 'dateFrom' || paramName === 'dateTo') ?
                    moment(filter[paramName]).format('DD.MM.YYYY') : filter[paramName])}&`;
            }
        }
    }
    query += `page=${page}&size=${size}&orderBy=${sortingField}&desc=${sortingDesc}`;
    return query;
};


const getReceipts = (filter, page, size, sortingField, sortingDesc) => httpService
    .get(`/receipt${getReceiptsQuery(filter, page, size, sortingField, sortingDesc)}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const payProduct = (receipt, computerId) => httpService
    .post(`/product/run/doPayActions/?computerId=${computerId}`, receipt)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const payCardProduct = (receipt) => httpService
    .post('/product/run/doPayCardActions', receipt)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const doFiscalisation = (receipt, computerId) => httpService
    .post(`/product/run/doFiscalisation/${receipt.receiptId}?computerId=${computerId}`, {})
    .then(({data}) => data)
    .catch((err) => {
            throw err;
        }
    );

const deposit = (deposit) => httpService
    .post(`/cashregister/deposit`, deposit)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const withdraw = (withdraw) => httpService
    .post(`/cashregister/withdraw`, withdraw)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });


const executeProduct = (product, cardNo, computerId) => httpService
    .post(`/product/run/createRI?cardNumber=${cardNo}&computerId=${computerId}`, product)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const cancelReceiptById = (receiptId, computerId) => httpService
    .put(`/receipt/${receiptId}/cancellation?computerId=${computerId}`)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const duplicateReceipt = (receiptId, computerId) => httpService
    .put(`/receipt/${receiptId}/duplication?computerId=${computerId}`)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const cancelReceipt = (computerId) => httpService
    .put(`/receipt/clear?cancleProducts=true&computerId=${computerId}`)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const okReceipt = (paymentStatus) => httpService
    .put(`/receipt/closebystatus?paymentStatus=${paymentStatus}`)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const deleteReceiptItem = (id) => httpService
    .remove(`/receipt/item/${id}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const changeCurrency = (receiptId, currency) => httpService
    .put(`/receipt/${receiptId}/changecurrency/${currency}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

export default {
    sendToEET,
    changeCurrency,
    doFiscalisation,
    payProduct,
    payCardProduct,
    executeProduct,
    cancelReceipt,
    okReceipt,
    cancelReceiptById,
    duplicateReceipt,
    deleteReceiptItem,
    getReceipts,
    deposit,
    withdraw,
};
