import aboutService from 'services/about.service';
import types from '../actionTypes';

export const fetchAboutList = (parkingId) => (dispatch, getState) => {
  if (getState().aboutData.isFetchingAboutList) {
    return Promise.reject();
  }

  dispatch({
    type: types.PARKING_ABOUT_FETCH_REQUEST,
  });

  return aboutService.getAbout()
    .then((aboutList) => {
      dispatch({
        type: types.PARKING_ABOUT_FETCH_SUCCESS,
        payload: { aboutList },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.PARKING_ABOUT_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};
