import React, {useCallback, useEffect, useRef, useState,} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';

import {fetchParkingCardsForProductExecution} from 'redux/actions/cards';
import ParkingCardsContent from './Content';
import {makeStyles} from "@material-ui/core/styles";


const DEFAULT_VALIDITY_TILL = null;
const DEFAULT_VALIDITY = 'all';
const DEFAULT_BLOCKED = 'all';
const DEFAULT_LPN = null;
const DEFAULT_CARD_NUMBER = null;
const DEFAULT_SIZE = 5;
const DEFAULT_PAGE = 0;



const useStyles = makeStyles((theme) => ({
    table: {
        height: '90%',
        width: '100%',
    },
}));


function ParkingCards(props) {
    const {
        parking,
        parkingCards,
        cardsPaging,
        fetchParkingCardsForProductExecution,
        userUseCases,
        selectedCard,
        setSelectedCard,
        parkings,
        cardNumber,
        setCardNumber,
    } = props;

    const classes = useStyles();

    const timeoutRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isFetchedCards, setIsFetchedCards] = useState(false);



    const fetchCards = useCallback((validTill, validity, blocked, lpn, cardNumber, size, page) => {
        setIsLoading(true);
        fetchParkingCardsForProductExecution(
            parking.parkingId,
            {
                validTill, validity: validity, blocked: blocked, lpn: lpn, cardNumber: cardNumber, size, page,
            },
        ).then(() => {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
        setSelectedCard(null);
    }, [fetchParkingCardsForProductExecution, parking.parkingId, cardNumber]);


    useEffect(() => {
        setIsFetchedCards(false);
    }, [parking.parkingId, cardNumber]);


    const [lpn, setLPN] = useState(DEFAULT_LPN);
    const [validTill, setValidTill] = useState(DEFAULT_VALIDITY_TILL);
    const [validity, setValidity] = useState([DEFAULT_VALIDITY]);
    const [blocked, setBlocked] = useState([DEFAULT_BLOCKED]);
    const [size, setSize] = useState(DEFAULT_SIZE);
    const [page, setPage] = useState(DEFAULT_PAGE);


    const resetPagination = () => {
        setPage(DEFAULT_PAGE);
    };

    const handleCardNumber = (value) => {
        resetPagination();
        setCardNumber(value);
    };

    const handleLPN = (value) => {
        resetPagination();
        setLPN(value);
        filterChange({
            validTill, validity, blocked, lpn: value, cardNumber,
            size, page: DEFAULT_PAGE
        });
    };

    const handleValidTill = (value) => {
        resetPagination();
        setValidTill(value);
        filterChange({
            validTill: value, validity, blocked, lpn, cardNumber,
            size, page: DEFAULT_PAGE
        });
    };

    const handleValidity = (value) => {
        resetPagination();
        setValidity(value.target.value);
        filterChange({
            validTill, validity: value.target.value, blocked, lpn, cardNumber,
            size, page: DEFAULT_PAGE
        });
    };
    const handleFilterBlocked = (value) => {
        resetPagination();
        setBlocked(value.target.value);
        filterChange({
            validTill, validity, blocked: value.target.value, lpn, cardNumber,
            size, page: DEFAULT_PAGE
        });
    };
    const handleChangeRowsPerPage = (value) => {
        resetPagination();
        setSize(value);
        filterChange({
            validTill, validity, blocked, lpn, cardNumber,
            size: value, page: DEFAULT_PAGE
        });
    };
    const handleChangePage = (value) => {
        setPage(value);
        filterChange({
            validTill, validity, blocked, lpn, cardNumber,
            size, page: value
        });
    };


    const filterChange = (filterParams) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            fetchCards(filterParams.validTill, filterParams.validity, filterParams.blocked, filterParams.lpn, filterParams.cardNumber,
                filterParams.size, filterParams.page);
        }, 1000);
    };

    useEffect(() => () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, []);


    useEffect(() => {
        if (!isFetchedCards) {
            fetchCards(validTill, validity, blocked, lpn, cardNumber, size, page);
            setIsFetchedCards(true);
        }
    }, [isFetchedCards, fetchCards]);


    return (
        <ParkingCardsContent
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            parking={parking}
            parkings={parkings}
            cards={parkingCards}
            isLoading={isLoading}
            validity={validity}
            lpn={lpn}
            cardNumber={cardNumber}
            blocked={blocked}
            validTill={validTill}
            cardsPaging={cardsPaging}
            handleValidTill={handleValidTill}
            handleValidity={handleValidity}
            handleFilterBlocked={handleFilterBlocked}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            handleLPN={handleLPN}
            handleCardNumber={handleCardNumber}
            userUseCases={userUseCases}
        />
    );
}

ParkingCards.propTypes = {
    parkings: PropTypes.array.isRequired,
    parking: PropTypes.object.isRequired,
    cardsPaging: PropTypes.object.isRequired,
    fetchParkingCardsForProductExecution: PropTypes.func.isRequired,
    parkingAccesses: PropTypes.array,
    parkingCardGroups: PropTypes.array,
};

const mapStateToProps = (store) => ({
    parkingCards: store.cardsData.parkingCards,
    cardsPaging: store.cardsData.cardsPaging,
    parkings: store.parkingsData.parkings,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingCardsForProductExecution,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingCards);
