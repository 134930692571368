import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ParkingSymbol from './ParkingSymbol';

const useStyles = makeStyles((theme) => ({
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    marginLeft: theme.spacing(1),
  },
}));

function ParkingName(props) {
  const classes = useStyles();

  const { parking = {}, handleSymbolClick } = props;

  return (
    <div className={classes.nameWrapper}>
      <ParkingSymbol 
        variant="subtitle2" 
        color={parking.color} 
        handleClick={handleSymbolClick} 
        connectionType={parking.connectionType}
        width={'32px'}              
      />

      <Typography variant="subtitle2" className={classes.name}>
        {parking.parkingName}
      </Typography>
    </div>
  );
}

ParkingName.propTypes = {
  parking: PropTypes.object,
  handleSymbolClick: PropTypes.func,
};

ParkingName.defaultProps = {
  parking: {},
  handleSymbolClick: () => {},
};

export default ParkingName;
