import types from '../actionTypes';

const INITIAL_STATE = {
    isFetching: false,
    events: [],
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FULLTEXT_FETCH_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.FULLTEXT_FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                events: action.payload,
            };
        case types.FULLTEXT_FETCH_FAIL:
            return {
                ...state,
                isFetching: false,
                errors: action.payload.error,
            };
        default:
            return state;
    }
};
