import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import MaterialTable from 'material-table';
import GetAppIcon from '@material-ui/icons/GetApp';
import {bindActionCreators} from "redux";
import {fetchDownloadFile, fetchDownloadList} from "../../redux/actions/download";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0.5, 0.5),
        boxShadow: `3px 3px 5px ${theme.palette.base[200]}`,
        minHeight: "100vh",
        '& .MuiToolbar-root': {
            minHeight: 0,
        },

        '& .MuiTable-root': {
            borderCollapse: 'unset',
        },
    },
    downloadContent: {
        minHeight: "100vh",
        padding: theme.spacing(1.5),
        flexWrap: 'wrap',
    },
    row: {
        marginTop: theme.spacing(1.5),
    },
    download: {
        cursor: "pointer",
    }
}));

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function DownloadFile(props) {

    const classes = useStyles();

    const {
        user,
        downloadList,
        isFetchingDownloadFile,
        fetchDownloadFile,
        isFetchingDownloadList,
        fetchDownloadList,
    } = props;


    const {t} = useTranslation();
    const baseURL = process.env.REACT_APP_API;

    const cellStyle = (rowData) => ({
        paddingLeft: 4,
        paddingRight: 4,
        borderRight: '1px solid white',
        lineHeight: '19px',
        color: '#1B1B28',
        // color: checkAssigned(rowData) ? '#1B1B28' : '#FFFFFF',
        textAlign: 'left',
    });

    const [progress, setProgress] = useState(0);

    const handleDownloadClick = (download) => () => {
        setDownloadId(download.downloadId);
        setProgress(0);
        fetchDownloadFile(download.downloadId, setProgress).then((downloadFile) => {
                const link = document.createElement('a');
                // const type = lookup(downloadFile.fileName);
                // console.warn(type);
                const url = window.URL.createObjectURL(new Blob([downloadFile.data]));
                link.href = url;
                link.setAttribute('download', download.fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                setDownloadId(null);
            }
        ).catch((error) => {
            console.error("error", error);
            setDownloadId(null);
        });
    };

    const [downloadId, setDownloadId] = useState();



    const columns = [
        {
            title: '', field: 'downloadId', cellStyle: (data, rowData) => cellStyle(rowData),
            render: (rowData) => (
                !isFetchingDownloadFile ?
                    <GetAppIcon className={classes.download} onClick={handleDownloadClick(rowData)}/>
                    : rowData.downloadId == downloadId ? <CircularProgressWithLabel value={progress} />
                        : <div></div>
            ),
        },
        {title: t('DOWNLOAD_DATE'), field: 'date', cellStyle: (data, rowData) => cellStyle(rowData)},
        {title: t('DOWNLOAD_NAME'), field: 'downloadName', cellStyle: (data, rowData) => cellStyle(rowData)},
        {title: t('DOWNLOAD_FILE_NAME'), field: 'fileName', cellStyle: (data, rowData) => cellStyle(rowData)},
        {title: t('DESCRIPTION'), field: 'description', cellStyle: (data, rowData) => cellStyle(rowData)},
    ];

    return (
        <div className={classes.container}>
            <MaterialTable
                title=""
                localization={{
                    body: {
                        emptyDataSourceMessage: t('NO_RECORD'),
                    },
                    pagination: {
                        firstTooltip: t('FIRST_PAGE'),
                        firstAriaLabel: t('FIRST_PAGE'),
                        previousTooltip: t('PREVIOUS_PAGE'),
                        previousAriaLabel: t('PREVIOUS_PAGE'),
                        nextTooltip: t('NEXT_PAGE'),
                        nextAriaLabel: t('NEXT_PAGE'),
                        labelDisplayedRows: t('ROWS_PER_PAGE'),
                        lastTooltip: t('LAST_PAGE'),
                        lastAriaLabel: t('LAST_PAGE'),
                        labelRowsSelect: t('ROWS_SELECT'),
                    },
                }}
                columns={columns}
                data={downloadList}
                isLoading={isFetchingDownloadList}
                options={{
                    search: false,
                    paging: false,
                    sorting: false,
                    loadingType: 'overlay',
                }}
                style={{
                    boxShadow: 'none',
                    padding: '0 10px',
                }}
            />
        </div>
    );
}


DownloadFile.propTypes = {
    user: PropTypes.object,
    downloadList: PropTypes.array.isRequired,
    fetchDownloadFile: PropTypes.func.isRequired,
    fetchDownloadList: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    downloadList: store.downloadData.downloadList,
    fetchDownloadFile: store.downloadData.fetchDownloadFile,
    isFetchingDownloadFile: store.downloadData.isFetchingDownloadFile,
    isFetchingDownloadList: store.downloadData.isFetchingDownloadList,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDownloadFile,
    fetchDownloadList,
}, dispatch);


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DownloadFile);
