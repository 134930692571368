import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingParkingList: false,
  isFetchingCardNumbers: false,
  isActivating: false,
  isBlocking: false,
  isCreating: false,
  isUpdating: false,
  isAccessChanging: false,
  isGettingDetail: false,
  isFetchingParkingCardGroups: false,

  parkingCards: [],
  parkingLTCards: [],
  parkingSTCards: [],
  parkingLTCardNumbers: [],
  parkingSTCardNumbers: [],
  cardDetail: {},
  parkingCardGroups: [],

  parkingCardNumbers: {
    LT: [],
    ST: [],
  },

  cardsPaging: {
    LT: {
      pageSize: 5,
      currentPage: 0,
      totalSize: 0,
    },
    ST: {
      pageSize: 5,
      currentPage: 0,
      totalSize: 0,
    },
    pageSize: 5,
    currentPage: 0,
    totalSize: 0,
  },

  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARKING_CARDS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingList: true,
      };
    case types.PARKING_CARDS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingList: false,
        parkingCards: action.payload.parkingCards ? action.payload.parkingCards: [...action.payload.parkingLTCards, action.payload.parkingSTCards],
        parkingLTCards: action.payload.parkingLTCards,
        parkingSTCards: action.payload.parkingSTCards,
        cardsPaging: action.payload.cardsPaging,
      };
    case types.PARKING_CARDS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingList: false,
        errors: action.payload.error,
      };

    case types.CARD_ACTIVATE_REQUEST:
      return {
        ...state,
        isActivating: true,
      };
    case types.CARD_ACTIVATE_SUCCESS:
      return {
        ...state,
        isActivating: false,
        cardDetail: action.payload.cardDetail,
        parkingCards: [...action.payload.parkingLTCards, action.payload.parkingSTCards],
        parkingLTCards: action.payload.parkingLTCards,
        parkingSTCards: action.payload.parkingSTCards,
      };
    case types.CARD_ACTIVATE_FAIL:
      return {
        ...state,
        isActivating: false,
        errors: action.payload.error,
      };

    case types.CARD_BLOCK_REQUEST:
      return {
        ...state,
        isBlocking: true,
      };
    case types.CARD_BLOCK_SUCCESS:
      return {
        ...state,
        isBlocking: false,
        cardDetail: action.payload.cardDetail,
        parkingCards: [...action.payload.parkingLTCards, action.payload.parkingSTCards],
        parkingLTCards: action.payload.parkingLTCards,
        parkingSTCards: action.payload.parkingSTCards,
      };
    case types.CARD_BLOCK_FAIL:
      return {
        ...state,
        isBlocking: false,
        errors: action.payload.error,
      };

    case types.CARD_CREATE_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case types.CARD_CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        parkingCards: [...action.payload.parkingLTCards, action.payload.parkingSTCards],
        parkingLTCards: action.payload.parkingLTCards,
        parkingSTCards: action.payload.parkingSTCards,
      };
    case types.CARD_CREATE_FAIL:
      return {
        ...state,
        isCreating: false,
        errors: action.payload.error,
      };

    case types.CARD_ACCESS_CHANGE_REQUEST:
      return {
        ...state,
        isAccessChanging: true,
      };
    case types.CARD_ACCESS_CHANGE_SUCCESS:
      return {
        ...state,
        isAccessChanging: false,
        cardDetail: action.payload.data,
      };
    case types.CARD_ACCESS_CHANGE_FAIL:
      return {
        ...state,
        isAccessChanging: false,
        errors: action.payload.error,
      };
  
    case types.CARD_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.CARD_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        cardDetail: action.payload.data,
        parkingCards: [...action.payload.parkingLTCards, action.payload.parkingSTCards],
        parkingLTCards: action.payload.parkingLTCards,
        parkingSTCards: action.payload.parkingSTCards,
      };
    case types.CARD_UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.payload.error,
      };

    case types.CARD_DETAIL_REQUEST:
      return {
        ...state,
        isGettingDetail: true,
      };
    case types.CARD_DETAIL_SUCCESS:
      return {
        ...state,
        isGettingDetail: false,
        cardDetail: action.payload.cardDetail,
      };
    case types.CARD_DETAIL_FAIL:
      return {
        ...state,
        cardDetail: {},
        isGettingDetail: false,
        errors: action.payload.error,
      };

    case types.CARD_EDIT_BULK_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.CARD_EDIT_BULK_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        parkingCards: [...action.payload.parkingLTCards, action.payload.parkingSTCards],
        parkingLTCards: action.payload.parkingLTCards,
        parkingSTCards: action.payload.parkingSTCards,
      };
    case types.CARD_EDIT_BULK_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.payload.error,
      };

    case types.PARKING_CARD_NUMBERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingCardNumbers: true,
      };
    case types.PARKING_CARD_NUMBERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingCardNumbers: false,
        parkingCardNumbers: {
          LT: action.payload.parkingLTCardNumbers,
          ST: action.payload.parkingSTCardNumbers,
        },
      };
    case types.PARKING_CARD_NUMBERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingCardNumbers: false,
        errors: action.payload.error,
      };

    case types.PARKING_CARD_GROUPS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingParkingCardGroups: true,
      };
    case types.PARKING_CARD_GROUPS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingParkingCardGroups: false,
        parkingCardGroups: action.payload.parkingCardGroups,
      };
    case types.PARKING_CARD_GROUPS_FETCH_FAIL:
      return {
        ...state,
        isFetchingParkingCardGroups: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
