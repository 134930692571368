import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import {ActionButton} from 'components/common';
import PMCSnackbar from "../../common/Snackbar";
import PosDetail from "./PosDetail";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 600,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    contentGrey: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
        background: theme.palette.base[200],

    },
    root: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    root1: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
        paddingBottom: '24px',
    },
    button: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
}));


function Pos(props) {
    const {
        userUseCases,
        subjects,
        templates,
        save,
        handleClose,
        readOnly,
        snackbarOpen,
        error,
        setSnackbarOpen,
        setError,
        posConfigurationModel,
        connect,
        branches,
        currencies,
        hasCertificate
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updatedPos, setUpdatedPos] = useState();
    const [errorStep1, setErrorStep1] = useState({});
    const [mode, setMode] = useState('COMMON');


    useEffect(() => {
        if (posConfigurationModel.pos) {
            setUpdatedPos(posConfigurationModel.pos);
        }
        setErrorStep1({});
    }, [posConfigurationModel]);


    const [productValues, setProductValues] = useState([]);

    useEffect(() => {
        if (templates) {
            setProductValues(templates.map(a => {
                return {value: a.productTemplateCode, label: a.productTemplateCode, key: a.productTemplateCode}
            }));
        }
    }, [templates]);

    const [subjectValues, setSubjectValues] = useState([]);


    useEffect(() => {
        if (subjects) {
            setSubjectValues(subjects.map(a => {
                return {value: a.subjectId, label: a.name, key: a.subjectId}
            }));
        }
    }, [subjects]);


    const [currencyValues, setCurrencyValues] = useState([]);


    useEffect(() => {
        if (currencies) {
            setCurrencyValues(currencies.map(a => {
                return {value: a.currencyId, label: a.currencyName, key: a.currencyId}
            }));
        }
    }, [currencies]);


    const handleSubject = (role) => {
        handleChange('subjectId')(role.value);
    };

    const handleBranch = (role) => {
        handleChange('branchId')(role.value);
    };

    const handleProduct = (roles) => {
        handleChange('supportedProduct')(roles.map(a => a.value));
    };


    const isValid = () => {
        let ret = true;
        const error = {};
        if (!updatedPos.name || updatedPos.name.trim().length === 0) {
            error.name = 'required';
            ret = false;
        }
        if (!updatedPos.subjectId) {
            error.subjectId = 'required';
            ret = false;
        }

        if (!updatedPos.branchId) {
            error.branchId = 'required';
            ret = false;
        }
        if (!updatedPos.currencyId) {
            error.currencyId = 'required';
            ret = false;
        }
        setErrorStep1(error)
        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const getSubject = (parkingSubject, subjects) => {
        if (parkingSubject) {
            const p = subjects && subjects.find(b => b.value == parkingSubject);
            if (!p) {
                return {label: 'Not Found', value: parkingSubject};
            }
            return p;
        }
        return null;
    }


    const [branchValues, setBranchValues] = useState([]);


    useEffect(() => {
        if (branches) {
            const filBranches = [];
            for (let i = 0; i < branches.length; i++) {
                const a = branches[i];
                if (updatedPos && (updatedPos.subjectId || updatedPos.subjectId == 0)) {
                    if (updatedPos.subjectId === a.subjectId) {
                        filBranches.push({value: a.id, label: a.branchName, key: a.id});
                    }
                } else {
                    filBranches.push({value: a.id, label: a.branchName, key: a.id});
                }
            }
            setBranchValues(filBranches);
        }
    }, [branches, updatedPos]);


    const getBranch = (pos, branchValues) => {
        if (branchValues && pos && pos.branchId) {
            return branchValues.find(a => a.value == pos.branchId);
        }
    }


    const handleNumberChange = (name) => (event) => {
        setUpdatedPos({...updatedPos, [name]: parseInt(event.target.value)});

    }


    const handleChange = (name) => (event) => {
        if (event && event.target) {
            setUpdatedPos({...updatedPos, [name]: event.target.value, 'controlDate': new Date()});
        } else {
            setUpdatedPos({...updatedPos, [name]: event, 'controlDate': new Date()});
        }
    }

 
    return (
        <Dialog
            maxWidth="lg"
            className={classes.container}
            open={posConfigurationModel.posDetailOpen}
            onClose={handleClose}
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
                <span>
                    {t(posConfigurationModel.isNew ? 'CREATE_POS' : 'UPDATE_POS')}
                </span>
            </DialogTitle>


            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={error}/>

            <DialogContent
                className={(mode !== 'COMMON') ? classes.contentGrey : classes.content}>
                <div className={classes.container}>
                    <PosDetail handleChange={handleChange}
                               userUseCases={userUseCases}
                               updatedPos={updatedPos}
                               subjectValues={subjectValues}
                               branchValues={branchValues}
                               productValues={productValues}
                               error={errorStep1}
                               isValid={isValid}
                               subjects={subjects}
                               setMode={setMode}
                               mode={mode}
                               handleSubject={handleSubject}
                               readOnly={readOnly}
                               getSubject={getSubject}
                               getBranch={getBranch}
                               setSnackbarError={setError}
                               setSnackbarOpen={setSnackbarOpen}
                               handleNumberChange={handleNumberChange}
                               handleBranch={handleBranch}
                               handleProduct={handleProduct}
                               currencyValues={currencyValues}
                               hasCertificate={hasCertificate}
                    />

                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>

                    <ActionButton
                        action="close"
                        handleClick={handleClose}
                        className={classes.button}
                    />
                </div>
                <div className={classes.buttonNavigation}>
                    <ActionButton
                        disabled={
                            !updatedPos
                            || readOnly
                        }
                        action="save" handleClick={() => {
                        const ret = isValid();
                        if (ret) {
                            save(updatedPos)
                        }
                    }}
                        className={classes.button}
                    />
                </div>

            </DialogActions>
        </Dialog>

    );
}

Pos.propTypes = {};


const mapStateToProps = (store) => ({

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Pos);


