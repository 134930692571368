import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Box from '@material-ui/core/Box';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import {ArrowUpward, ChevronRight} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import CustomCheckBox from 'components/elements/CustomCheckBox2'

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        
        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    description: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.06px',
        marginLeft: '30px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
}));

function RoleDetail(props) {
    const classes = useStyles();

    const {
        userUseCases,
        useCases,
        updatedRole,
        error,
        readOnly,
    } = props;

    const {t} = useTranslation();

    const handleNothing = () => {
        console.log('handleNothing');
    }

    return (
        <div className={classes.formFields}>
            <form autoComplete='off'>
                <Box mb={2} height="100%" width="100%">
                    <Grid container spacing={2} sm={12}>
                        <Grid container spacing={1} sm={3} style={{marginLeft: "auto", marginRight: "auto", paddingLeft:"20px"}}>
                            <Grid item xs sm={12} spacing={1} >
                                <TextField
                                    style={{height: '80px'}}
                                    autoFocus
                                    helperText={error && error.name
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="name"
                                    value={updatedRole.name || ''}
                                    onChange={handleNothing()}
                                    label={t('NAME')}/>

                                <FormControl fullWidth disabled={false} style={{height: '80px'}}>
                                    <InputLabel id="parking-label">
                                        {t('ROLE_ACTIVE')}
                                    </InputLabel>
                                    <Select
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedRole.active || ''}                                        
                                        id="active"
                                        onChange={handleNothing()}
                                    >
                                        <MenuItem value=''>-</MenuItem>
                                        <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                        <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                    </Select>
                                    <FormHelperText>  {error && error.active && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth disabled={false} style={{height: '80px'}}>
                                    <InputLabel id="parking-label">
                                        {t('ADMIN_ROLE')}
                                    </InputLabel>
                                    <Select
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedRole.admin || ''}
                                        id="admin"
                                        onChange={handleNothing()}
                                    >
                                        <MenuItem value=''>-</MenuItem>
                                        <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                        <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                    </Select>
                                    <FormHelperText>  {error && error.admin && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="stretch" spacing={2} sm={9}
                                style={{marginLeft: "auto", marginRight: "auto"}}>

                            <Typography variant="button" className={classes.description}>
                                {t('ASSIGNED_UC')}
                            </Typography>

                            <div className={classes.editorWrapper}>
                            
                                <MaterialTable
                                    title=""
                                    search="false"
                                    icons={{
                                        SortArrow: ArrowUpward,
                                        DetailPanel: ChevronRight,
                                        Search: Search,
                                        ResetSearch: Clear
                                    }}
                                    data={useCases}

                                    columns={[
                                        { title: t('MODULE'), field: 'module' },
                                        { title: t('UC_CODE'), field: 'code' },
                                        { title: t('UC_NAME'), field: 'description' },
                                    ]}
                                    parentChildData={(row, rows) => rows.find(a => a.useCaseId === row.parentId)}

                                    options={{
                                        showTitle: false, paging:false, showTextRowsSelected:false, 
                                        headerStyle: {
                                            position: "sticky",
                                            top: "0"
                                        },
                                        maxBodyHeight: "400px",
                                        
                                        toolbarButtonAlignment:"left"
                                    }}

                                    actions={[
                                        {
                                            icon: 'checked',
                                            tooltip: 'checked',
                                            onClick: (event, rowData) => {handleNothing()}
                                        },
                                    ]}

                                    localization={{
                                        header: {
                                            actions: ''
                                        },
                                    }}

                                    components={{
                                        Action: props => (
                                          <CustomCheckBox
                                            onClick={(event) => props.action.onClick(event, props.data)}
                                            color={'white'}
                                            iconNumber={props.data.checked}
                                            paddingTop={'0'}
                                            paddingLeft={'0'}
                                            width='5'
                                            height='5'
                                            text={''}
                                            disabled={true}
                                          />
                                        )
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </div>
    )
}

RoleDetail.propTypes = {};
RoleDetail.defaultProps = {};


const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RoleDetail);


