import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {CustomSwitch, CustomTable, Loading} from 'components/elements';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CustomCheckbox from 'components/elements/CustomCheckbox';
import CustomDateTimePicker from 'components/elements/CustomDateTimePicker';
import {fetchProductTemplateParamSelect} from 'redux/actions/products';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ParkingName from '../../common/ParkingName';
import TextInnerField from './TextInnerField';
import DateStep from "../../elements/DateStep";
import {FormControlLabel, Radio} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        background: theme.palette.base.white,
        padding: theme.spacing(0, 1),
        marginTop: theme.spacing(-0.5),
        '& .MuiTableFooter-root': {
            display: 'none !important',
        },
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    parkingHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
}));

const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};


function WizardStep2TableMacro(props) {
    const classes = useStyles();

    const {
        productTemplateParams,
        product,
        handleParamDefaultValueChange,
        handleNameChange,
        handleEditableChange,
        handleVisibleChange,
        fetchProductTemplateParamSelect,
        productTemplateSelectValues,
        error,
        readOnly,
        parkingsValues,
        addChildToMacroSelect,
        removeChildToMacroSelect,
        handleDefaultChange,
        getSubject,
        subjectValues,
        handleMacroParkingSubject,
        handleReferenceChange,
        handleStepperChange,
    } = props;

    const {t} = useTranslation();


    const loadSelectValues = (productTemplateParams, parkings) => {
        const actions = [];
        const actionsNoParking = [];

        for (let i = 0; i < productTemplateParams.length; i++) {
            if (productTemplateParams[i].paramType === 'PRODUCT_TYPE_MACRO_SELECT') {
                actions.push(productTemplateParams[i].paramName);
            }

            if (productTemplateParams[i].paramType === 'PRODUCT_TYPE_SELECT') {
                actionsNoParking.push(productTemplateParams[i].paramName);
            }
        }
        fetchProductTemplateParamSelect(actions, parkings.map(a => a.parkingId), actionsNoParking);
    };


    const [mergedValues, setMergedValues] = useState([]);

    const [loadedSelectValuesForParking, setLoadedSelectValuesForParking] = useState([]);


    useEffect(() => {
        let reloadNeeded = false;
        for (let i = 0; i < product.productParkingsSubjects.length; i++) {
            let index = loadedSelectValuesForParking.findIndex(a => a === product.productParkingsSubjects[i].parkingId);
            if (index === -1) {
                reloadNeeded = true;
                setLoadedSelectValuesForParking(product.productParkingsSubjects.map(a => a.parkingId));
                break;
            }
        }
        if (reloadNeeded) {
            loadSelectValues(productTemplateParams, product.productParkingsSubjects);
        }

    }, [productTemplateParams, product]);


    useEffect(() => {
        if (productTemplateParams && product) {
            const values = [];

            for (let i = 0; i < productTemplateParams.length; i++) {
                const productParamValue = product.productParam.find(a => a.paramName === productTemplateParams[i].paramName);

                if (productParamValue) {
                    const required = productTemplateParams[i].required && productTemplateParams[i].required === true && (!productParamValue.visible
                        || !productParamValue.editable);

                    if (productParamValue.paramType === 'PRODUCT_TYPE_MACRO_SELECT') {
                        values.push({
                            paramLabel: productParamValue.paramLabel,
                            paramType: 'PRODUCT_TYPE_MACRO_SELECT',
                            parent: true,
                            paramName: productParamValue.paramName,
                            productEditable: productParamValue.editable,
                            productVisible: productParamValue.visible,
                            required
                        });
                        if (productParamValue.macroSelect) {
                            let defaultIndex = productParamValue.macroSelect.findIndex(a => a.default);
                            for (let j = 0; j < productParamValue.macroSelect.length; j++) {
                                values.push({
                                    paramLabel: productParamValue.macroSelect[j].label,
                                    paramType: 'PRODUCT_TYPE_MACRO_SELECT',
                                    parent: false,
                                    paramName: productParamValue.paramName,
                                    values: productParamValue.macroSelect[j].values,
                                    index: j,
                                    defaultIndex: defaultIndex,
                                    default: productParamValue.macroSelect[j].default,
                                    required
                                });
                            }
                        }
                    } else {
                        values.push({
                            ...productTemplateParams[i],
                            paramLabel: productParamValue.paramLabel,
                            productValueVarchar: productParamValue.valueVarchar,
                            productValueDate: productParamValue.valueDate,
                            productValueBoolean: productParamValue.valueBoolean,
                            productValueSelect: productParamValue.valueSelect,
                            productEditable: productParamValue.editable,
                            productVisible: productParamValue.visible,
                            precision: productParamValue.precision,
                            stepper: productParamValue.stepper,
                            required
                        });
                    }
                }
            }
            setMergedValues(values);
        }
    }, [product, productTemplateParams]);


    const getSelectValues = (rowData, items, parkingId, index) => {
        if (rowData.paramType === 'PRODUCT_TYPE_MACRO_SELECT') {

            const parkingValue = rowData.values && rowData.values.find(a => a.parkingId === parkingId);
            if (parkingValue) {
                const p = items && items.find(b => b.value == parkingValue.valueId);
                if (!p) {
                    return {label: 'Not Found', value: parkingValue.valueId};
                }
                return p;
            } else {
                return null;
            }

        } else {
            if (rowData.multiValue) {
                return rowData.productValueSelect ?
                    rowData.productValueSelect.map(a => {
                        const p = items && items.find(b => b.value == a);
                        if (!p) {
                            return {label: 'Not Found', value: a};
                        }
                        return p;
                    }) :
                    [];
            } else {
                if (rowData.productValueSelect) {
                    const p = items && items.find(b => b.value == rowData.productValueSelect);
                    if (!p) {
                        return {label: 'Not Found', value: rowData.productValueSelect};
                    }
                    return p;
                }
                return null;
            }
        }
    }


    const
        defaultComponent = (rowData, productTemplateSelectValues, error) => {


            switch (rowData.paramType) {
                case 'PRODUCT_TYPE_NUMBER':
                    console.warn(rowData.paramName, rowData.productValueNumber);
                    return <TextInnerField
                        type="number"
                        required={rowData.required}
                        label={t('DEFAULT')}
                        helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        rowData={rowData}
                        initialValue={rowData.productValueNumber}
                        onBlur={handleParamDefaultValueChange}
                    />;
                case 'PRODUCT_TYPE_TEXT':
                    return <TextInnerField
                        rowData={rowData}
                        value={rowData.productValueVarchar}
                        onBlur={handleParamDefaultValueChange}
                        label={t('DEFAULT')}
                        required={rowData.required}
                        helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}/>;
                case 'PRODUCT_TYPE_DATE':
                    return <DateStep
                        value={rowData.productValueVarchar}
                        label={t('DEFAULT')}
                        required={rowData.required}
                        disabled={readOnly}
                        showMode={true}
                        stepper={rowData.stepper}
                        handleStepperChange={handleStepperChange(rowData.paramName)}
                        handleChange={handleParamDefaultValueChange(rowData.paramName)}
                        helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}>
                    </DateStep>
                case 'PRODUCT_TYPE_BOOLEAN':
                    return <CustomSwitch color="secondary"
                                         disabled={readOnly}
                                         checked={rowData.productValueBoolean === true}
                                         handleSwitch={handleParamDefaultValueChange(rowData.paramName)}/>
                case 'PRODUCT_TYPE_SELECT':
                    const items = productTemplateSelectValues[rowData.paramName];
                    if (items) {
                        return <Autocomplete
                            multiple={rowData.multiValue}
                            limitTags={3}
                            disabled={readOnly}
                            value={getSelectValues(rowData, items)}
                            onChange={(event, newValue) => {
                                handleParamDefaultValueChange(rowData.paramName)(newValue);

                            }}
                            filterSelectedOptions
                            id={rowData.paramName}
                            options={items}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            style={{width: '100%'}}
                            renderInput={(params) => (
                                <TextField {...params} label={t('DEFAULT')} required={rowData.required} fullWidth
                                           helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                                               ? <span
                                                   className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                               : null}

                                />
                            )}
                            size="small"

                        />

                    } else {
                        return <Loading/>
                    }
                case 'PRODUCT_TYPE_MACRO_SELECT':
                    return rowData.parent === false ?
                        <CustomCheckbox
                            disabled={rowData.default}
                            checked={rowData.default}
                            handleChange={handleDefaultChange(rowData.paramName, rowData.index, rowData.defaultIndex)}
                            className={classes.checkbox}
                        /> : <div></div>;
                default:
                    return <div>Not Supported</div>;

            }


        };


    const
        defaultComponentMacro = (rowData, parkingId, productTemplateSelectValues, error) => {
            switch (rowData.paramType) {
                case 'PRODUCT_TYPE_MACRO_SELECT':
                    if (rowData.parent === true) {
                        return <div></div>;
                    } else {
                        const items = productTemplateSelectValues[`${rowData.paramName}${parkingId}`];
                        if (items) {
                            return <Autocomplete
                                disabled={readOnly}
                                value={getSelectValues(rowData, items, parkingId, rowData.index)}
                                onChange={(event, newValue) => {
                                    handleParamDefaultValueChange(rowData.paramName)(newValue, parkingId, rowData.index);

                                }}
                                filterSelectedOptions
                                id={rowData.paramName}
                                options={items}
                                getOptionLabel={(option) => {
                                    return option.label;
                                }}
                                style={{width: '100%'}}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('DEFAULT')} required={rowData.required} fullWidth
                                               helperText={error && error[rowData.paramName + rowData.index + "_" + parkingId] && error[rowData.paramName + rowData.index + "_" + parkingId].defaultValue
                                                   ? <span
                                                       className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                   : null}

                                    />
                                )}
                                size="small"

                            />
                        }
                    }
                default:
                    return <div></div>;
            }


        };


    const [columnsWithParking, setColumnsWithParking] = useState([]);


    useEffect(() => {
        if (product.productParkingsSubjects) {
            const columns = [
                {
                    title: t('NAME'),
                    field: 'name',
                    cellStyle,
                    render: (rowData) => {
                        return <TextInnerField rowData={rowData}
                                               onBlur={handleNameChange}
                                               initialValue={t(rowData.paramLabel)}
                                               addChildToMacroSelect={addChildToMacroSelect}
                                               removeChildToMacroSelect={removeChildToMacroSelect}
                                               disabled={readOnly}
                                               label={t(rowData.paramName)}
                                               helperText={error && error[rowData.paramName + (rowData.paramType === 'PRODUCT_TYPE_MACRO_SELECT' && !rowData.parent ? rowData.index : '')] && error[rowData.paramName + (rowData.paramType === 'PRODUCT_TYPE_MACRO_SELECT' && !rowData.parent ? rowData.index : '')].paramLabel
                                                   ? <span
                                                       className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                   : null}
                        />
                    }
                },
                {
                    title: t('EDITABLE'),
                    field: 'editable',
                    cellStyle,
                    render: (rowData) => {
                        return (rowData.paramType === 'PRODUCT_TYPE_MACRO_SELECT' && rowData.parent === false ?
                                <div></div> :
                                <CustomCheckbox
                                    disabled={readOnly}
                                    className={classes.checkbox}
                                    checked={rowData.productEditable}
                                    handleChange={handleEditableChange(rowData.paramName)}
                                />
                        )
                    }
                },
                {
                    title: t('VISIBLE'),
                    field: 'visible',
                    cellStyle,
                    render: (rowData) => (rowData.paramType === 'PRODUCT_TYPE_MACRO_SELECT' && rowData.parent === false ?
                            <div></div> :
                            <CustomCheckbox
                                disabled={rowData.productEditable || readOnly}
                                className={classes.checkbox}
                                checked={rowData.productVisible}
                                handleChange={handleVisibleChange(rowData.paramName)}
                            />
                    ),
                },
                {
                    title: t('DEFAULT'),
                    field: 'default',
                    cellStyle,
                    render: (rowData) => {
                        return (
                            <Box className={classes.content}>
                                {defaultComponent(rowData, productTemplateSelectValues, error)}
                            </Box>
                        );
                    },
                },
            ];

            for (let j = 0; j < product.productParkingsSubjects.length; j++) {
                const park = parkingsValues.find(a => a.value === product.productParkingsSubjects[j].parkingId);
                columns.push({
                    title: <>
                        <div className={classes.parkingHeader}>
                            <ParkingName parking={park}/>
                            <FormControlLabel
                                value="start"
                                onChange={(event) => {
                                    handleReferenceChange(park.value, event);
                                }}
                                control={<Radio color="primary"
                                                checked={product.productParkingsSubjects[j].reference}/>}
                                label={t('REFERENCE')}
                                labelPlacement="start"
                            />
                        </div>
                        <Autocomplete
                            value={getSubject(product.productParkingsSubjects[j].subjectId, subjectValues)}
                            disabled={readOnly}
                            disableClearable
                            onChange={(event, newValue) => {
                                handleMacroParkingSubject(park.value, newValue);
                            }}
                            forcePopupIcon={false}
                            filterSelectedOptions
                            options={subjectValues}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            style={{width: '100%'}}
                            renderInput={(params) => (
                                <TextField {...params} label={t('SUBJECT')} required fullWidth
                                           helperText={error && error.subjectId && error.subjectId[j]
                                               ? <span
                                                   className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                               : null}
                                />
                            )}
                            size="small"

                        />

                    </>,
                    field: 'default',
                    cellStyle,
                    render: (rowData) => {
                        return (
                            <Box className={classes.content}>
                                {defaultComponentMacro(rowData, park.value, productTemplateSelectValues, error)}
                            </Box>
                        );
                    },
                });
            }
            setColumnsWithParking(columns);
        }
    }, [mergedValues, productTemplateSelectValues, error]);


    const tableOptions = {
        headerStyle: {
            padding: '15px 8px 15px 34px',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
        },
    };


    return (
        <div className={classes.container}>
            <CustomTable
                title=""
                columns={columnsWithParking}
                data={mergedValues}
                options={{
                    ...tableOptions,
                    sorting: false,
                    draggable: false,
                    paging: false,
                    // headerStyle: {position: 'sticky', top: 0},
                }}
                isLoading={false}
                count={mergedValues.length}
                loadAllData={false}
            />
        </div>
    );
}

WizardStep2TableMacro.propTypes = {};

WizardStep2TableMacro.defaultProps = {};

const mapStateToProps = (store) => ({
    productTemplate: store.productsData.productTemplate,
    productTemplateSelectValues: store.productsData.productTemplateSelectValues,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchProductTemplateParamSelect,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep2TableMacro);


