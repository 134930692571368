import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Badge from '@material-ui/core/Badge';
import {blue} from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';

import ReceiptsList from 'components/modals/ReceiptsList';
import DepositWithdrawal from 'components/modals/DepositWithdrawal';

import {makeStyles} from '@material-ui/core/styles';
import {
    cancelReceipt,
    changeCurrency,
    deleteReceiptItem,
    deposit,
    executeProduct, getFiscalizedReceipts,
    payReceipt, sendToEET,
    withdraw
} from 'redux/actions/receipts';
import ZReports from "../../../components/modals/ZReport";
import PMCSnackbar from "../../../components/common/Snackbar";
import {xReportPrint} from 'redux/actions/reports';
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import ReceiptsFiscalizedList from "../../../components/modals/ReceiptsFiscalisedList";
import {fetchAllProductsForCashDesk, fetchAllProductTemplates} from "../../../redux/actions/products";
import {fetchAllCurrencies, fetchAllRates} from "../../../redux/actions/currencies";
import {processAction} from "../../../redux/actions/parkings";


const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
        paddingTop: theme.spacing(4),
        height: '100%',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
        height: '100%'
    },
    cardContent: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'space-around',
        padding: theme.spacing(5),
        background: theme.palette.base[50],
        textAlign: 'center',
        cursor: 'pointer',
    },
    cardTooltip: {
        flex: 0,
        padding: theme.spacing(2),
        background: theme.palette.base[100],
        textAlign: 'center'
    }
}));


const Card = ({title, tooltip, badgeContent, onClick}) => {
    const classes = useStyles();
    const smallTitle = title && title.length > 20;

    return (
        <Grid item xs={12} sm={6} md={3}>
            <div className={classes.card}>
                <Badge badgeContent={badgeContent} color="error" showZero />
                <div className={classes.cardContent} onClick={onClick}>
                    <Typography variant={!!smallTitle ? "h4" : "h3"} display="block">{title}</Typography>
                </div>
                <div className={classes.cardTooltip}>
                    <Tooltip disableFocusListener title={tooltip}>
                        <InfoIcon style={{color: blue[500]}}/>
                    </Tooltip>
                </div>
            </div>
        </Grid>
    );
};


//TODO check rights?
const CashDeskTools = ({
                           userUseCases,
                           deposit,
                           withdraw,
                           xReportPrint,
                           cardNumber,
                           action,
                           cashDeskActiveTab,
                           posStatutes,
                           ...props
                       }) => {
    const [modal, setVisibleModal] = useState();
    const classes = useStyles();
    const {t} = useTranslation();

    const showModal = (type) => () => setVisibleModal(type);
    const hideModal = () => setVisibleModal(null);
    const [selectedReport, setSelectedReport] = useState();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState({});


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const print = () => {

        xReportPrint(localStorage.getItem('pos_id')).then(
            () => {
                setSnackbarOpen(true);
                setMessage({message: "Report was sent to the printer", severity: "success"});
            }
        ).catch((error) => {
            setSnackbarOpen(true);
            setMessage({
                message: getErrorMessage(error, t),
                severity: 'error'
            });
        });
    }

    useEffect(() => {
            if (cardNumber && (cashDeskActiveTab === 1)) {
                setVisibleModal("receiptsList");
                processAction();
            }
        }, [cardNumber, action]
    );

    return (
        <div className={classes.container}>
            <Grid container spacing={2}>

                <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={message.severity}
                             message={message.message}/>


                {isComponentVisibleForUser(UC_TAB_RECEIPTS, userUseCases) &&
                <Card
                    title={t("CASHDESK_TOOL_RECEIPTS")}
                    tooltip={t("CASHDESK_TOOL_RECEIPTS_TOOLTIP")}
                    action={action}
                    onClick={isComponentEnabledForUser(UC_TAB_RECEIPTS, userUseCases) && showModal("receiptsList")}
                />}
                {isComponentVisibleForUser(UC_TAB_X_REPORT, userUseCases) &&
                <Card
                    title={t("CASHDESK_TOOL_X_REPORT")}
                    tooltip={t("CASHDESK_TOOL_X_REPORT_TOOLTIP")}
                    onClick={() => {
                        isComponentEnabledForUser(UC_TAB_X_REPORT, userUseCases) && print()
                    }}
                />
                }
                {/*
                                          <Card
                    title={t("CASHDESK_TOOL_SHIFT_END")}
                    tooltip={t("CASHDESK_TOOL_SHIFT_END_TOOLTIP")}
                />
                }
*/}

                {isComponentVisibleForUser(UC_FISCAL, userUseCases) &&
                    <Card
                        badgeContent={posStatutes && posStatutes.length && posStatutes.length > 0 ? posStatutes[0].nonFiscalizedReceipt : 0 }
                        title={t("CASHDESK_TOOL_NON_FISCAL_RECEIPTS")}
                        tooltip={t("CASHDESK_TOOL_NON_FISCAL_RECEIPTS_TOOLTIP")}
                        onClick={isComponentEnabledForUser(UC_FISCAL, userUseCases) && showModal("non_fiscal")}
                    />
                }
                {isComponentVisibleForUser(UC_TAB_DEPO, userUseCases) &&


                <Card
                    title={t("CASHDESK_TOOL_DEPOSIT")}
                    tooltip={t("CASHDESK_TOOL_DEPOSIT_TOOLTIP")}
                    onClick={isComponentEnabledForUser(UC_TAB_DEPO, userUseCases) && showModal("deposit")}

                />
                }
                {isComponentVisibleForUser(UC_TAB_WITH, userUseCases) &&

                <Card
                    title={t("CASHDESK_TOOL_WITHDRAWAL")}
                    tooltip={t("CASHDESK_TOOL_WITHDRAWAL_TOOLTIP")}
                    onClick={isComponentEnabledForUser(UC_TAB_WITH, userUseCases) && showModal("withdrawal")}
                />
                }
                {isComponentVisibleForUser(UC_TAB_Z_REPORT_LIST, userUseCases) &&

                <Card
                    title={t("CASHDESK_TOOL_Z_REPORT")}
                    tooltip={t("CASHDESK_TOOL_Z_REPORT_TOOLTIP")}
                    onClick={isComponentEnabledForUser(UC_TAB_Z_REPORT_LIST, userUseCases) && showModal("zreport")}
                />
                }
                {/*                <Card
                    title={ t("CASHDESK_TOOL_CASH_REGISTER_DEADLINE") }
                    tooltip={ t("CASHDESK_TOOL_CASH_REGISTER_DEADLINE_TOOLTIP") }
                    />
                */}
            </Grid>


            {/* modal windows */}
            <ReceiptsList cardNumber={cardNumber} open={modal == "receiptsList"} handleClose={hideModal}
                          userUseCases={userUseCases}/>
            <DepositWithdrawal open={modal == "deposit"} handleClose={hideModal} mode="deposit" doAction={deposit}
                               userUseCases={userUseCases}/>
            <DepositWithdrawal open={modal == "withdrawal"} handleClose={hideModal} mode="withdrawal"
                               doAction={withdraw} userUseCases={userUseCases}/>
            <ZReports open={modal == "zreport"} handleClose={hideModal} userUseCases={userUseCases}/>
            <ReceiptsFiscalizedList open={modal == "non_fiscal"} handleClose={hideModal} userUseCases={userUseCases}/>


        </div>
    );
};


const UC_TAB_RECEIPTS = 'UC0501';
const UC_TAB_DEPO = 'UC0505';
const UC_TAB_WITH = 'UC0506';
const UC_TAB_Z_REPORT_LIST = 'UC0507';
const UC_TAB_X_REPORT = 'UC0512';
const UC_FISCAL = 'UC0502';


const mapStateToProps = (store) => ({
    posStatutes: store.parkingsData.posStatutes,
    cardNumber: store.parkingsData.cardNumber,
    action: store.parkingsData.action,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    deposit,
    withdraw,
    xReportPrint,
    processAction,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CashDeskTools);


