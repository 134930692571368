import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from '@material-ui/core/styles';

import {
  CustomTable,
  CustomCheckbox,
  CustomButton,
} from 'components/elements';
import { ActionButton, EventType } from 'components/common';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';

import MobileDevicesToolBar from './MobileDevicesToolBar';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.base.white,
    padding: theme.spacing(3.75, 2),
    boxShadow: `3px 3px 5px ${theme.palette.base[200]}`,
  },
  containerModal: {
    '& .MuiDialog-paper': {
      width: 834,
      maxWidth: 'unset',
      height: 'fit-content',
      padding: theme.spacing(4.25, 3.25),
      boxShadow: theme.palette.shadow.main,
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mr: {
    marginRight: theme.spacing(2.5),
  },
  title: {
    marginBottom: theme.spacing(4.25),
    color: theme.palette.base[500],

    '& .MuiTypography-root': {
      fontSize: 24,
    },
  },
}));

const cellStyle = {
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: '19px',
  color: '#60636B',
  textAlign: 'center',
  padding: '20px 16px 20px 16px',
};

function MobileDevices(props) {
  const classes = useStyles();

  const {
    user,
    isLoading,
    isUpdating,
    mobileDevices,
    activeDevice,
    activeMultipleDevices,
    removeDevice,
    userUseCases,
  } = props;

  const { t } = useTranslation();

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [filteredDevices, setFilteredDevices] = useState(null);
  const [mobileOperator, setMobileOperator] = useState(['']);
  const [device, setDevice] = useState(['']);
  const [deactivatedBy, setDeactivatedBy] = useState(['']);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [confirmModal, setConfirmModal] = useState({
    opened: false,
    rowData: null,
  });
  const handleMobileOperator = (value) => {
    setMobileOperator(value);
  };
  const handleDevice = (value) => {
    setDevice(value);
  };
  const handleDeactivatedBy = (value) => {
    setDeactivatedBy(value);
  };
  const handleSearch = (value) => {
    setSearchKeyword(value);
  };
  const handleModalDelete = (rowData) => {
    setConfirmModal({
      opened: true,
      rowData,
    });
  };
  const handleCloseModalDelete = () => {
    setConfirmModal({
      opened: false,
      rowData: null,
    });
  };
  const filter = useCallback((filterOperator, filterDevice, filterDeactivatedBy, keyword) => {
    let newDevices = mobileDevices;
    if (filterOperator.length > 1) {
      const operatorIds = filterOperator.map((id) => (id || null));
      newDevices = newDevices.filter((device) => operatorIds.indexOf(device.mobileUserId) > -1);
    }

    if (filterDevice.length > 1) {
      const deviceIds = filterDevice.map((id) => (id || null));
      newDevices = newDevices.filter((device) => deviceIds.indexOf(device.mobileDeviceId) > -1);
    }

    if (filterDeactivatedBy.length > 1) {
      const deactivateUserIds = filterDeactivatedBy.map((id) => (id || null));
      newDevices = newDevices.filter((device) => deactivateUserIds.indexOf(device.deactivatedUserId) > -1);
    }

    if (keyword) {
      newDevices = newDevices.filter((device) => {
        const deviceIdStr = device.deviceId.toString().toLowerCase();
        const operatorStr = device.mobileUser.toLowerCase() || '';
        const emailStr = device.registrationEmail.toLowerCase() || '';
        const keywordStr = keyword.toLowerCase();

        return (deviceIdStr.includes(keywordStr) || operatorStr.includes(keywordStr) || emailStr.includes(keywordStr));
      });
    }

    setFilteredDevices(newDevices);
  }, [mobileDevices]);

  useEffect(() => {
    filter(mobileOperator, device, deactivatedBy, searchKeyword);
  }, [filter, searchKeyword, mobileOperator, device, deactivatedBy]);

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = (checked) => {
    if (checked) {
      setSelected((filteredDevices || mobileDevices).map((d) => d.mobileDeviceId));
    } else {
      setSelected([]);
    }
    setSelectAll(checked);
  };
  const handleCheck = (rowData) => () => {
    const selectedIndex = selected.indexOf(rowData.mobileDeviceId);
    if (selectedIndex > -1) {
      const selectedDevices = selected;
      selectedDevices.splice(selectedIndex, 1);

      setSelected(selectedDevices);
    } else {
      setSelected([
        ...selected,
        rowData.mobileDeviceId,
      ]);
    }
    filter(mobileOperator, device, deactivatedBy, searchKeyword || ' ');
  };

  const handleActivate = (rowData) => () => {
    if (isUpdating) {
      return;
    }
    activeDevice({
      ...rowData,
      deactivatedUser: rowData.state === 'A' ? `${user.firstName} ${user.lastName}` : null,
      deactivatedDate: rowData.state === 'A' ? moment().format() : null,
      deactivatedUserId: rowData.state === 'A' ? user.subjectId : null,
      state: rowData.state === 'A' ? 'I' : 'A',
    })
      .then(() => {
        if (!isUnMounted.current) {
          filter(mobileOperator, device, deactivatedBy, searchKeyword || ' ');
        }
      });
  };

  const handleDelete = (rowData) => {
    if (isUpdating) {
      return;
    }

    removeDevice(rowData)
      .then(() => {
        // fetchMobileDevices();
      });
    setConfirmModal({
      opened: false,
      rowData: null,
    });
  };

  const selectedActivate = () => {
    if (mobileDevices.length === 0 || selected.length === 0) {
      return false;
    }
    const activeDevices = mobileDevices.filter((d) => selected.indexOf(d.mobileDeviceId) > -1 && d.state === 'A');
    const inActiveDevices = mobileDevices.filter((d) => selected.indexOf(d.mobileDeviceId) > -1 && d.state !== 'A');
    if (activeDevices.length === mobileDevices.length) {
      return true;
    }
    if (inActiveDevices.length === mobileDevices.length) {
      return false;
    }

    return activeDevices.length >= inActiveDevices.length;
  };

  const handleSelectedActivate = () => {
    if (isUpdating || selected.length === 0) {
      return;
    }
    let selectedDevices = mobileDevices.filter((d) => selected.indexOf(d.mobileDeviceId) > -1);
    selectedDevices = selectedDevices.map((d) => ({
      ...d,
      deactivatedUser: selectedActivate() ? `${user.firstName} ${user.lastName}` : null,
      deactivatedDate: selectedActivate() ? moment().format() : null,
      deactivatedUserId: selectedActivate() ? user.subjectId : null,
      state: selectedActivate() ? 'I' : 'A',
    }));

    activeMultipleDevices(selectedDevices).then(() => {
      if (!isUnMounted.current) {
        filter(mobileOperator, device, deactivatedBy, searchKeyword || ' ');
      }
    });
  };

  const selectedActionColor = selectedActivate() ? 'red' : 'green';

  const columns = [
    {
      title: t('MOBILE_OPERATOR'),
      field: 'mobileUser',
      cellStyle,
    },
    {
      title: t('DEVICE_ID'),
      field: 'deviceId',
      cellStyle,
    },
    {
      title: t('ACTIVE'),
      field: 'state',
      cellStyle,
      render: (rowData) => (
        <EventType type={rowData.state === 'A' ? 'active' : 'inactive'} />
      ),
    },
    {
      title: t('ACTIVATION_EMAIL'),
      field: 'registrationEmail',
      cellStyle,
    },
    {
      title: t('DEACTIVATED_BY'),
      field: 'deactivatedUser',
      cellStyle,
    },
    {
      title: t('DEACTIVATED'),
      field: 'deactivatedDate',
      cellStyle,
      render: (rowData) => (
        <span>{rowData.deactivatedDate &&
        moment(rowData.deactivatedDate).locale(i18next.language).format('L LTS')}
        </span>
      ),
    },
    {
      title: <CustomCheckbox checked={selectAll} handleChange={handleSelectAll} />,
      sorting: false,
      cellStyle,
      headerStyle: {
        padding: '15px 34px 15px 34px',
      },
      render: (rowData) => (
        <CustomCheckbox
          checked={selected.indexOf(rowData.mobileDeviceId) > -1}
          handleChange={handleCheck(rowData)}
        />
      ),
    },
    {
      title: <CustomButton
        label={selectedActivate() ? t('DEACTIVATE') : t('ACTIVATE')}
        width={100}
        height={30}
        color={selected.length === 0 ? 'grey' : selectedActionColor}
        onClick={handleSelectedActivate}
      />,
      sorting: false,
      cellStyle,
      headerStyle: {
        padding: '15px 34px 15px 34px',
      },
      render: (rowData) => {
        return isComponentVisibleForUser(rowData.state === 'A' ? UC_MOBILE_DEACTIVE: UC_MOBILE_ACTIVE, userUseCases) ? <ActionButton
            action={rowData.state === 'A' ? 'deactivate' : 'activate'}
            disabled={!isComponentEnabledForUser(rowData.state === 'A' ? UC_MOBILE_DEACTIVE : UC_MOBILE_ACTIVE, userUseCases)}
            handleClick={handleActivate(rowData)}
        /> : null
      },
    },
    {
      title: '',
      sorting: false,
      cellStyle,
      headerStyle: {
        padding: '15px 34px 15px 34px',
      },
      render: (rowData) => (
        <ActionButton
          action="delete"
          handleClick={() => handleModalDelete(rowData)}
        />
      ),
    },
  ];

  return (
    <>
      <div className={classes.container}>
        {isComponentVisibleForUser(UC_MOBILE_SEARCH, userUseCases) &&
        <MobileDevicesToolBar
            mobileDevices={mobileDevices}
            mobileOperator={mobileOperator}
            device={device}
            deactivatedBy={deactivatedBy}
            handleMobileOperator={handleMobileOperator}
            handleDevice={handleDevice}
            handleDeactivatedBy={handleDeactivatedBy}
            handleSearch={handleSearch}
        />
        }
        <CustomTable
          title=""
          columns={columns}
          data={filteredDevices || mobileDevices}
          options={{
            headerStyle: {
              padding: '15px 8px 15px 34px',
              borderTop: '1px solid #DEE2E5',
              lineHeight: '12px',
              color: '#1B1B28',
              textAlign: 'center',
            },
          }}
          isLoading={isLoading}
          rowsPerPage={20}
        />
        { confirmModal.opened
        && (
          <Dialog
            className={classes.containerModal}
            open={confirmModal.opened}
            onClose={handleCloseModalDelete}
          >
            <DialogTitle className={classes.title}>
              {t('REMOVE_MOBILE_DEVICE_TITLE')}
            </DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('CONFIRM_REMOVE_MOBILE_DEVICE', {
                  mobileDeviceId: confirmModal.rowData.deviceId,
                  userName: confirmModal.rowData.mobileUser,
                })}
              </DialogContentText>
            </DialogContent>

            <DialogActions className={classes.actions}>
              <ActionButton
                action="confirm_remove_user"
                handleClick={() => handleDelete(confirmModal.rowData)}
              />
              <ActionButton
                action="cancel"
                handleClick={handleCloseModalDelete}
              />
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
}

MobileDevices.propTypes = {
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  mobileDevices: PropTypes.array.isRequired,
  activeDevice: PropTypes.func.isRequired,
  activeMultipleDevices: PropTypes.func.isRequired,
  removeDevice: PropTypes.func.isRequired,
  // fetchMobileDevices: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  isUpdating: store.mobilesData.isUpdating,
});

const UC_MOBILE_ACTIVE = 'UC0069';
const UC_MOBILE_DEACTIVE = 'UC0070';
const UC_MOBILE_SEARCH = 'UC0073';


export default connect(
  mapStateToProps,
)(MobileDevices);
