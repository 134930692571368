import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment/min/moment-with-locales';

import { getCardNumber, getEventDescription, isCardEvent } from 'utils';
import {  CustomTable } from 'components/elements';
import { ParkingName } from 'components/common';
import ActionButton from 'components/common/ActionButton';
import EventType from 'components/common/EventType';
import EventsToolbar from 'components/common/ToolBar';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';
import OnlineEventsVirtualizedTable from './OnlineEventsVirtualizedTable';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoDetail from 'components/modals/PhotoDetail';



const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
    background: theme.palette.base.white,
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(-0.5),
  },
  containerSimple: {
    maxWidth: '100%',
    background: theme.palette.base.white,
    padding: '20px 8px',
    marginTop: theme.spacing(-5.0),
  },
  tableWrapperOnline: {
    borderTop: `2px solid ${theme.palette.base[200]}`,
    borderBottom: `3px solid ${theme.palette.base[200]}`,

    '& .MuiTableFooter-root': {
      display: 'none !important',
    },
  },
  tableWrapper: {
    borderTop: `2px solid ${theme.palette.base[200]}`,
    borderBottom: `3px solid ${theme.palette.base[200]}`,
  },
  toolbarInput: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    marginBottom: theme.spacing(2.5),
  },
  select: {
    marginRight: theme.spacing(3),
  },
  hostLink: {
    textDecoration: 'none',
    borderBottom: '1px dotted grey',
    color: '#1B1B28',
    cursor: 'pointer',
  },
  pointer: {
    cursor: "pointer",
  },
}));

const cellStyle = {
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: '19px',
  color: '#1B1B28',
  textAlign: 'center',
};

function OnlineEventsTable(props) {
  const classes = useStyles();

  const {
    parkings,
    isLoading,
    isOnline,
    startTimeStr,
    endTimeStr,
    events,
    hosts,
    filteredEvents,
    subjectEventsPaging,
    subjectEvents,
    parkingNames,
    eventTypes,
    selectedHosts,
    selectedTypes,
    selectedParkingNames,
    selectParking,
    handleOnline,
    handleSelectParkingNames,
    handleSelectHosts,
    handleCreateTask,
    handleSelectTypes,
    handleLPChange,
    handleStartDate,
    handleEndDate,
    handleSearch,
    handleHostNameClick,
    handleCardNameClick,
    handleChangeRowsPerPage,
    handleChangePage,
    simple,
    userUseCases,
  } = props;

  const { t } = useTranslation();

  const [showPhoto, setShowPhoto] = useState(false);
  const [rowData, setRowData] = useState(null);

  const hadlePhotoClick = (rowData) => () => {
    setShowPhoto(true);
    setRowData(rowData);
  }

  const columns = [
    {
      title: t('PARKING_NAME'),
      field: 'parkingId',
      headerStyle: { textAlign: 'left', paddingLeft: 5 },
      cellStyle,
      render: (rowData) => {
        const parking = parkings.find((p) => p.parkingId === rowData.parkingId);
        return (
          <div className={classes.wrapper}>
            <ParkingName parking={parking} handleSymbolClick={selectParking(rowData.parkingId)} />
          </div>
        );
      },
    },
    {
      title: t('PARKING_HOST'),
      field: 'parkingHost',
      cellStyle,
      render: (rowData) => {
        return isComponentVisibleForUser(UC_HOST_DETAIL, userUseCases) ?

            <span
                className={classes.hostLink}
                title={t('OPEN_HOST_DETAIL')}
                onClick={
                  (e) => {
                    e.preventDefault();
                    handleHostNameClick(rowData.parkingHost, rowData.parkingId);
                  }
                }
            >
          {rowData.parkingHost}
        </span> : <>{rowData.parkingHost}</>
      },
    }, 
    {
      title: t('LICENSE_PLATE'),
      field: 'lpn',
      cellStyle,
      render: (rowData) =>   <>{rowData.lpn}</>,
    }, 
    {
      title: t('PHOTO'),
      field: 'photo',
      cellStyle,
      render: (rowData) =>   <>{((rowData.photoKeyPic && rowData.photoKeyPic!=='0000000000000000') || (rowData.photoKeyLpn && rowData.photoKeyLpn!=='0000000000000000')) &&
        <PhotoCameraIcon onClick={hadlePhotoClick(rowData)} className={classes.pointer}/>
      }</>,
    }, {
      title: t('DATE'),
      field: 'date',
      cellStyle,
      render: (rowData) => (
        <>
          {rowData.date ? moment(new Date(rowData.date)).locale(i18next.language).format('L LTS') : ''}
        </>
      ),
    },
    {
      title: t('EVENT'),
      field: 'detail',
      cellStyle,
      render: (rowData) => (
        isCardEvent(rowData.description) && (isComponentVisibleForUser(UC_CARD_DETAIL_ST, userUseCases) || isComponentVisibleForUser(UC_CARD_DETAIL_LT, userUseCases))
          ? (
            <span
              className={classes.hostLink}
              title={t('OPEN_CARD_DETAIL')}
              onClick={
                (e) => {
                  e.preventDefault();
                  handleCardNameClick(rowData.parkingId, getCardNumber(rowData.messageParam), rowData.parkingHost);
                }
              }
            >
              <>{getEventDescription(t(rowData.message && rowData.message.startsWith('message.') ? rowData.message.substring(8, rowData.message.length) : rowData.message), rowData.messageParam)}</>
              {/* <>{getEventDescription(t(rowData.description), rowData.messageParam)}</> */}
            </span>
          )
          // : <>{getEventDescription(t(rowData.description), rowData.messageParam)}</>
          : <>{getEventDescription(t(rowData.message && rowData.message.startsWith('message.') ? rowData.message.substring(8, rowData.message.length) : rowData.message), rowData.messageParam)}</>


      ),
    },
    {
      title: t('MESSAGE_TYPE'),
      field: 'eventType',
      cellStyle,
      render: (rowData) => {
        const descStrs = rowData.category.category.split('_');
        const type = descStrs[descStrs.length - 1].toLowerCase();
        return (
          <EventType type={type} border chipColor={rowData.category.color} />
        );
      },
    },
  ];

  if (isComponentVisibleForUser(UC_TASK_CREATE, userUseCases)) {
    columns.push(    {
      title: t('ACTION'),
      field: 'action',
      cellStyle,
      render: (rowData) => (
          <ActionButton
              disabled={!isComponentEnabledForUser(UC_TASK_CREATE, userUseCases)}
              action="assign"
              handleClick={
                (rowData.action && rowData.action !== 'action')
                    ? null
                    : handleCreateTask(rowData)
              }
          />
      ),
    });
  };


  const tableOptions = {
    headerStyle: {
      padding: '15px 8px 15px 34px',
      lineHeight: '12px',
      color: '#1B1B28',
      textAlign: 'center',
    },
  };

  return (
    <div className={simple ? classes.containerSimple : classes.container}>
      {isComponentVisibleForUser(UC_OFFLINE_EVENTS, userUseCases) &&
      <EventsToolbar
          disabled={!isComponentEnabledForUser(UC_OFFLINE_EVENTS, userUseCases)}
          isOnline={isOnline}
          hosts={hosts}
          selectedHosts={selectedHosts}
          eventTypes={eventTypes}
          selectedTypes={selectedTypes}
          startTimeStr={startTimeStr}
          endTimeStr={endTimeStr}
          handleOnline={handleOnline}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          handleSelectHosts={handleSelectHosts}
          handleSelectTypes={handleSelectTypes}
          handleLPChange={handleLPChange}
          handleSearch={handleSearch}
      />
      }
      {
        isOnline ? <div className={classes.tableWrapperOnline}>
              <OnlineEventsVirtualizedTable userUseCases={userUseCases} simple={simple} handleCreateTask={handleCreateTask} handleCardNameClick={handleCardNameClick} handleHostNameClick={handleHostNameClick} events={filteredEvents || events || []} parkings={parkings} selectParking={selectParking}/>
            </div>
            :
            <div className={classes.tableWrapper}>
              <CustomTable
                  title=""
                  columns={columns}
                  data={subjectEvents}
                  options={tableOptions}
                  isLoading={isLoading}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  rowsPerPage={subjectEventsPaging.pageSize}
                  pageNumber={subjectEventsPaging.currentPage}
                  count={subjectEventsPaging.totalSize}
                  loadAllData={true}
              />
            </div>
      }
      {showPhoto &&
        <PhotoDetail showPhoto={showPhoto} setShowPhoto={setShowPhoto} rowData={rowData} setRowData={setRowData}/>
      }
    </div>
  );
}

OnlineEventsTable.propTypes = {
  parkings: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  hosts: PropTypes.array.isRequired,
  filteredEvents: PropTypes.array,
  parkingNames: PropTypes.array.isRequired,
  eventTypes: PropTypes.array.isRequired,
  selectedHosts: PropTypes.array.isRequired,
  selectedTypes: PropTypes.array.isRequired,
  selectedParkingNames: PropTypes.array.isRequired,
  selectParking: PropTypes.func.isRequired,
  handleSelectParkingNames: PropTypes.func.isRequired,
  handleSelectHosts: PropTypes.func.isRequired,
  handleCreateTask: PropTypes.func.isRequired,
  handleSelectTypes: PropTypes.func.isRequired,
  handleHostNameClick: PropTypes.func.isRequired,
  handleCardNameClick: PropTypes.func.isRequired,
  handleSearch: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  simple: PropTypes.bool.isRequired,
};

OnlineEventsTable.defaultProps = {
  filteredEvents: null,
  startTimeStr: null,
  endTimeStr: null,
  handleSearch: () => {},
};

const UC_OFFLINE_EVENTS = 'UC0041';
const UC_HOST_DETAIL = 'UC0006';
const UC_TASK_CREATE = 'UC0038';
const UC_CARD_DETAIL_LT = 'UC0025';
const UC_CARD_DETAIL_ST = 'UC0033';


export default OnlineEventsTable;
