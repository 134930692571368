import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import {getErrorMessage, numberFormatPriceCustomPositive} from 'utils';
import {ActionButton} from 'components/common';
import {searchUsers} from 'redux/actions/users';
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CancelIcon from "@material-ui/icons/Cancel";
import PMCSnackbar from '../../common/Snackbar';

const bgColor = (props) => props.mode == 'deposit' ? "#50A932" : "#F5894A";

const themeDeposit = createMuiTheme({
    palette: {
        primary: {main: "#50A932"},
    },
});

const themeWithdrawal = createMuiTheme({
    palette: {
        primary: {main: "#F5894A"},
    },
});

const useStyles = makeStyles((theme) => ({
    title: {
        height: 96,
        background: bgColor,
        paddingRight: theme.spacing(2),
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
            '& .MuiButtonBase-root': {
                color: theme.palette.base.white,
            },
            '& input + fieldset': {
                borderColor: theme.palette.base.white
            },
        },
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '75px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '75px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    confirmButton: {
        background: bgColor
    },
    userSelection: {
        display: 'flex',
        width: '50%',
        paddingRight: theme.spacing(1),
    },
    button: {
        width: 200,
        marginLeft: 20,
        marginRight: 20,
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
}));


const UserInput = withStyles((theme) => ({
    input: {
        textAlign: 'left',
    },
}))(TextField);

const PriceInput = withStyles((theme) => ({
    input: {
        fontSize: 18,
        textAlign: 'right',
        color: '#1B1B28',
    },
}))(InputBase);

const MenuPopupState = ({currencies, selectedCurrencies, onMenuItemClick}) => {
    return (
        <PopupState variant="popover" popupId="popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <AddIcon {...bindTrigger(popupState)} style={{fontSize: 40, paddingRight: '5px'}}/>
                    <Menu {...bindMenu(popupState)}>
                        {Array.isArray(currencies) ? currencies.map((item, key) => (
                            <MenuItem onClick={() => {
                                onMenuItemClick(item.code);
                            }} disabled={selectedCurrencies[item.code]} key={item.code}
                                      value={item.code}>{item.code}</MenuItem>)) : null}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
}


const DepositWithdrawalDialog = ({mode, open, handleClose, filterReceipts, receipts, isFetchingList, currencies, searchedUsers, searchUsers, doAction}) => {
    const classes = useStyles({mode});
    const [selectedReceipt, setSelectedReceipt] = useState();
    const [selectedCurrencies, setSelectedCurrencies] = useState({});

    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [autoOpen, setAutoOpen] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [error, setError] = useState({});


    useEffect(() => {
        if (!isFetched) {
            setIsLoading(true);
            try {
                searchUsers().then(() => {
                    setIsFetched(true);
                    setIsLoading(false);
                })
            } catch (e) {
                setIsLoading(false);
                setIsFetched(true);
            }
        }
    }, [searchUsers, isFetched]);

    const convert = (selectedCurrencies) => {
        const converted = Object.keys(selectedCurrencies).map(function (key, index) {
            return {userId: user && user.userId, currency: key, depositValue: selectedCurrencies[key].amount};
        });
        return converted;
    }


    const isValid = (selectedCurrencies) => {
        let ret = true;
        let error = {};
        Object.keys(selectedCurrencies).forEach(function (key, index) {
            if (selectedCurrencies[key].amount <= 0) {
                ret = false;
                error[key] = {"message": "Amount has to be greater than 0"};
            } else {
                delete error[key];
            }
        });
        if (!user || !user.userId) {
            error['userId'] = {"message": t('THIS_FIELD_IS_REQUIRED')};
        } else {
            delete error['userId'];
        }
        setError(error);
        return ret;
    }

    const onMenuItemClick = (key) => {
        const selectedCurrenciesNew = {...selectedCurrencies, [key]: {amount: 0}};
        setSelectedCurrencies(selectedCurrenciesNew);
    }

    const onDeleteItem = (key) => {
        let selectedCurrenciesNew = {...selectedCurrencies, [key]: {amount: 0}};
        delete selectedCurrenciesNew[key];
        setSelectedCurrencies(selectedCurrenciesNew);
    }

    const amountChange = (value, key) => {
        const selectedCurrenciesNew = {...selectedCurrencies, [key]: {amount: value}};
        setSelectedCurrencies(selectedCurrenciesNew);
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [errorAction, setErrorAction] = useState('');
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    const {t} = useTranslation();

    useEffect(() => {
    }, [open]);


    useEffect(() => {
        if (Array.isArray(receipts) && receipts.length == 1) {
            setSelectedReceipt(receipts[0]);
        } else {
            setSelectedReceipt(null);
        }
    }, [receipts]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle className={classes.title}>
                <div>
                    {t(mode == 'deposit' ? 'CASHDESK_TOOL_DEPOSIT' : 'CASHDESK_TOOL_WITHDRAWAL')}
                </div>
                <div className={classes.userSelection}>
                    <Autocomplete
                        value={user}
                        onChange={(event, newValue) => {
                            setUser(newValue);
                        }}
                        style={{
                            width: '100%', color: "white"
                        }}
                        open={autoOpen}
                        onOpen={() => {
                            setAutoOpen(true);
                        }}
                        onClose={() => {
                            setAutoOpen(false);
                        }}
                        getOptionSelected={(option, value) => option.email === value.email}
                        getOptionLabel={(option) => `${option.username} <${option.email}>`}
                        options={searchedUsers}
                        loading={isLoading}
                        renderInput={(params) => (
                            <UserInput
                                {...params}
                                required
                                label={t('USER')}
                                fullWidth={true}
                                InputLabelProps={{
                                    style: {color: 'white'},
                                }}
                                helperText={error && error.userId
                                    ? <span
                                        className={classes.errorMessage}>{error.userId.message}</span>
                                    : null}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {isLoading ? <CircularProgress color="inherit" size={20}/> :
                                                null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <IconButton>
                        <CreateIcon style={{fontSize: 30}}/>
                    </IconButton>
                </div>
            </DialogTitle>


            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={errorAction}/>

            <DialogContent className={classes.content}>
                <Box my={6} align="right">
                    <IconButton>
                        <MenuPopupState currencies={currencies} selectedCurrencies={selectedCurrencies}
                                        onMenuItemClick={onMenuItemClick}></MenuPopupState>
                    </IconButton>
                </Box>
                <Box mb={5}>
                    <ThemeProvider theme={mode == 'deposit' ? themeDeposit : themeWithdrawal}>
                        <Grid container spacing={4}>
                            {Object.keys(selectedCurrencies).map(function (key, index) {
                                return (
                                    <>
                                        <Grid item xs sm={11}>
                                            <TextField
                                                fullWidth={true}
                                                onChange={(e) => {
                                                    amountChange(e.target.value, key);
                                                }}
                                                value={selectedCurrencies[key].amount}
                                                InputProps={{
                                                    endAdornment: <InputAdornment
                                                        position="end">{key}</InputAdornment>,
                                                    inputComponent: numberFormatPriceCustomPositive
                                                }}
                                                helperText={error && error[key]
                                                    ? <span
                                                        className={classes.errorMessage}>{error[key].message}</span>
                                                    : null}
                                            />
                                        </Grid>
                                        <Grid item xs sm={1}>
                                            <CancelIcon style={{fontSize: 30}}
                                                        onClick={(() => onDeleteItem(key))}
                                                        color="error"/>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>


                    </ThemeProvider>
                </Box>
            </DialogContent>


            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>
                    <ActionButton
                        action="close"
                        handleClick={handleClose}
                        className={classes.button}
                    />
                </div>
                <div className={classes.buttonNavigation}>
                    <ActionButton
                        action="ok"
                        disabled={Object.keys(selectedCurrencies).length === 0}
                        handleClick={() => {
                            if (isValid(selectedCurrencies)) {
                                doAction(convert(selectedCurrencies)).then(() => {
                                        handleClose();
                                    }
                                ).catch((error) => {
                                    setSnackbarOpen(true);
                                    setErrorAction(getErrorMessage(error, t));
                                });
                            }
                        }}
                        className={classes.button}
                    />
                </div>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (store) => ({
    currencies: store.currenciesData.currencies,
    searchedUsers: store.usersData.searchedUsers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({searchUsers}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DepositWithdrawalDialog);
