import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {blockCard} from 'redux/actions/cards';
import PMCDialog from "../common/PMCDialog";
import muiTheme from "../../theme";

const useStyles = makeStyles((theme) => ({
    formGroup: {
        marginBottom: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    label: {
        width: theme.spacing(13),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    textField: {
        flex: 1,
        marginLeft: theme.spacing(3),
    },
    errorMessage: {
        color: theme.palette.primary.red,
        fontSize: 13,
    },
}));

function BlockCard(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        event,
        opened,
        handleClose,
        blockCard,
    } = props;

    const [error, setError] = useState(false);

    const [state, setState] = useState({
        blockingReason: '',
    });
    const handleChange = (field) => (change) => {
        setState({
            ...state,
            [field]: change.target.value,
        });
    };

    const handleSubmit = () => {
        if (state.blockingReason) {
            setError(false);
            blockCard(event.cardNo, event.parkingId, !event.blocked, state.blockingReason)
                .then(handleClose);
        } else {
            setError(true);
        }
    };

    return (

        <PMCDialog
            headerColor={event.type === 'ST' ? '#50A933' : '#5985EE'}
            open={opened}
            title={`${t('BLOCK_CARD')} - ${event.cardNo}`}
            onClose={handleClose}
            onSubmit={handleSubmit}
        >
            <Box className={classes.formGroup}>
                <Typography variant="button" className={classes.label}>{t('BLOCKING_REASON')}</Typography>
                <TextField
                    autoFocus
                    required
                    className={classes.textField}
                    hiddenLabel
                    value={state.blockingReason || ''}
                    onChange={handleChange('blockingReason')}
                    helperText={error
                        ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                        : null}
                />
            </Box>

        </PMCDialog>

    );
}

BlockCard.propTypes = {
    event: PropTypes.object.isRequired,
    opened: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    blockCard: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    blockCard,
}, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(BlockCard);
