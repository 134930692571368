import httpService from './http.service';


const getCardNumberParam = (cardNumber)=>{
    if (cardNumber) {
        return `?cardNumber=${cardNumber}`;
    }
    return '';
}

const initProductParam = (product, cardNumber) => httpService
    .post(`/product/run/initParam${getCardNumberParam(cardNumber)}`, product)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const getAllProductsForCashDesk = (computerId) => httpService
    .get(`/product/user?computerId=${computerId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const getAllProducts = (productVersion) => httpService
    .get(`/product?productVersion=${productVersion}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getProduct = (id) => httpService
    .get(`/product/${id}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const getAllProductGroups = () => httpService
    .get('/productgroup')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getAllProductTemplates = () => httpService
    .get('/producttemplate')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const createProduct = (product) => httpService
    .post(`/product`, product)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });


const updateProduct = (product) => httpService
    .put(`/product`, product)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const deleteProduct = (product) => httpService
    .remove(`/product`, product)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));


const getAllProductTemplateParamSelectValues = (paramName, parkingId) => httpService
    .get(`/product/paramvalues?paramName=${paramName}&parkingId=${parkingId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getAllProductTemplateParamSelectValuesNoParking = (paramName) => httpService
    .get(`/product/paramvalues?paramName=${paramName}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    initProductParam,
    createProduct,
    updateProduct,
    deleteProduct,
    getProduct,
    getAllProducts,
    getAllProductGroups,
    getAllProductTemplates,
    getAllProductTemplateParamSelectValues,
    getAllProductTemplateParamSelectValuesNoParking,
    getAllProductsForCashDesk,

};
