import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment/min/moment-with-locales';

import {ParkingName, ActionButton} from 'components/common';
import {Loading} from 'components/elements';
import {fetchTaskImages} from 'redux/actions/tasks';
import i18next from 'i18next';
import PMCDialog from "../common/PMCDialog";

const useStyles = makeStyles((theme) => ({
    imageModalContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(4.25),
        color: theme.palette.base[500],

        '& .MuiTypography-root': {
            fontSize: 24,
        },
    },
    formGroup: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    label: {
        width: theme.spacing(12),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    imageWrapper: {
        width: 400,
        height: 250,
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(4),
    },
    attachment: {
        // width: '100%',
        // height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'cover',
    },
    content: {
        padding: theme.spacing(1, 0),
        flex: 1,
        borderBottom: `1px solid ${theme.palette.base[200]}`,
    },
    actions: {
        marginTop: theme.spacing(5),
        justifyContent: 'center',
    },
    cancel: {
        height: 40,
        marginLeft: theme.spacing(3.75),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        color: theme.palette.base.white,
    },
}));

function TaskDetails(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        opened,
        parkings,
        task,
        image,
        imageModal,
        taskImages,
        handleClose,
        fetchTaskImages,
    } = props;

    const parking = parkings.find((p) => p.parkingId === task.parkingId);
    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    const [isLoading, setIsLoading] = useState(true);
    const [isFetchedImage, setIsFetchedImage] = useState(false);
    const fetchTaskImage = useCallback(() => {
        setIsLoading(true);
        fetchTaskImages([task.taskId])
            .then(() => {
                if (!isUnMounted.current) {
                    setIsFetchedImage(true);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                if (!isUnMounted.current) {
                    setIsFetchedImage(false);
                    setIsLoading(false);
                }
            });
    }, [fetchTaskImages, task.taskId]);

    useEffect(() => {
        if (image && !isFetchedImage) {
            fetchTaskImage();
        }
    }, [image, isFetchedImage, task.taskId, fetchTaskImage]);

    return (
        <PMCDialog
            open={opened}
            title={!imageModal ? `${t('TASK')} ${task.taskId}` : `${t('TASK')} ${task.taskId} - ${task.description}`}
            onClose={handleClose}
            headerColor="#800000"
        >


            {!imageModal ? (
                <>
                    <Box className={classes.formGroup}>
                        <Typography variant="button" className={classes.label}>{t('PARKING_NAME')}</Typography>
                        <Box className={classes.content}>
                            <ParkingName parking={parking}/>
                        </Box>
                        <Typography variant="button" className={classes.label}>{t('PARKING_PHONE')}</Typography>
                        <Box className={classes.content}>
                            <Typography variant="subtitle2">
                                {task.parkingPhone}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.formGroup}>
                        <Typography variant="button" className={classes.label}>{t('CREATED_BY')}</Typography>
                        <Box className={classes.content}>
                            <Typography variant="subtitle2">{task.creationBy}</Typography>
                        </Box>
                        <Typography variant="button" className={classes.label}>{t('CREATION_DATE')}</Typography>
                        <Box className={classes.content}>
                            <Typography variant="subtitle2">
                                {moment(task.creationDateTS).locale(i18next.language).format('L LTS')}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.formGroup}>
                        <Typography variant="button" className={classes.label}>{t('ACCEPT_DATE')}</Typography>
                        <Box className={classes.content}>
                            <Typography variant="subtitle2">
                                {task.acceptanceDateTS
                                    ? moment(task.acceptanceDateTS).locale(i18next.language).format('L LTS')
                                    : t('NOT_ACCEPTED')}
                            </Typography>
                        </Box>
                        <Typography variant="button" className={classes.label}>{t('SOLVED_DATE')}</Typography>
                        <Box className={classes.content}>
                            <Typography variant="subtitle2">
                                {task.solvedDateTS ? moment(task.solvedDateTS).locale(i18next.language).format('L LTS')
                                    : t('NOT_SOLVED')}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.formGroup}>
                        <Typography variant="button" className={classes.label}>{t('DESCRIPTION')}</Typography>
                        <Box className={classes.content}>
                            <Typography variant="subtitle2">{task.description}</Typography>
                        </Box>
                        <Typography variant="button" className={classes.label}>{t('RESOLUTION')}</Typography>
                        <Box className={classes.content}>
                            <Typography variant="subtitle2">{task.resolution}</Typography>
                        </Box>
                    </Box>

                    {image && (
                        <div className={classes.imageWrapper}>
                            {isLoading ? (
                                <Loading/>
                            ) : (
                                <img src={`data:image/png;base64,${taskImages[0].file}`}
                                     className={classes.attachment} alt=""/>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <>
                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <img src={`data:image/png;base64,${taskImages[0].file}`} className={classes.attachment}
                             alt=""/>
                    )}
                </>
            )}
        </PMCDialog>
    );
}

TaskDetails.propTypes = {
    opened: PropTypes.bool.isRequired,
    parkings: PropTypes.array.isRequired,
    task: PropTypes.object.isRequired,
    image: PropTypes.string,
    imageModal: PropTypes.bool,
    taskImages: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    fetchTaskImages: PropTypes.func.isRequired,
};

TaskDetails.defaultProps = {
    image: null,
    imageModal: false,
};

const mapStateToProps = (store) => ({
    taskImages: store.tasksData.taskImages,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTaskImages,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TaskDetails);
