import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Call from '@material-ui/icons/Call';
import CallEnd from '@material-ui/icons/CallEnd';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Keyboard from '@material-ui/icons/Dialpad';
import Pause from '@material-ui/icons/Pause';
import Locked from '@material-ui/icons/Lock';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import PhoneCallback from '@material-ui/icons/PhoneCallback';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import AlertIcon from '@material-ui/icons/NotificationsActive';
import DeleteIcon from '@material-ui/icons/DeleteSweep';

import {ArrowLeft} from '@material-ui/icons';
import ParkingSymbol from './ParkingSymbol';
import {getIcon} from '../../containers/Parking/LocalParking/LocalParkingHosts/Utils';
import NumericKeyboard from './NumericKeyboard';
import SipCustomButton from '../elements/SipCustomButton';
import {isComponentEnabledForUser, isComponentVisibleForUser, SIP_STATUS} from '../../utils';
import {setHostName, setParking} from '../../redux/actions/parkings';


const useStyles = makeStyles((theme) => ({
    rootContainer: {
        width: 500,
        minHeight: 60,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        width: 500,
        height: 60,
        display: 'flex',
        flexDirection: 'row',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'white',
    },
    iconContainer: {
        width: 130,
        height: 60,
        display: 'flex',
        flexDirection: 'row',
        left: 10,
        backgroundColor: (props) => props.color,
        color: 'white',

    },
    statusContainer: {
        width: 370,
        height: 60,
        borderLeftStyle: 'solid',
        borderLeftWidth: '2px',
        borderLeftColor: 'white',

        display: 'flex',
        alignContent: 'right',
        backgroundColor: 'rgb(89, 133, 238)',
    },
    containerKeyboard: {
        width: 500,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgb(247, 247, 247)',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'white',
        padding: 4,

    },
    historyContainer: {
        width: 500,
        maxHeight: 300,
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgb(247, 247, 247)',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'white',
        padding: 4,

    },
    button: {
        margin: theme.spacing(1),
        color: 'green',
        backgroundColor: 'white',

        '&:hover': {
            margin: theme.spacing(1),
            color: 'white',
        },
    },
    noActionButton: {
        margin: theme.spacing(1),
        color: 'green',
        backgroundColor: 'white',
    },
    deleteHistory: {
        margin: theme.spacing(1),
        backgroundColor: 'red',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#F0F90F0',
        '&:hover': {
            margin: theme.spacing(1),
            color: 'red',
        },
    },
    acceptCall: {
        margin: theme.spacing(1),
        color: 'white',
        backgroundColor: 'green',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#F0F90F0',
        '&:hover': {
            margin: theme.spacing(1),
            color: 'white',
        },
    },
    declineCall: {
        margin: theme.spacing(1),
        color: 'white',
        backgroundColor: 'red',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#F0F90F0',
        '&:hover': {
            margin: theme.spacing(1),
            color: 'white',
        },
    },
    buttonInverse: {
        margin: theme.spacing(1),
        color: 'white',
        '&:hover': {
            margin: theme.spacing(1),
            color: 'white',
        },
    },
    image: {
        maxWidth: 30,
        maxHeight: 30,
        margin: 10,
    },
    hostContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    callNumber: {
        margin: theme.spacing(2),
        textAlign: 'center',
    },
    callHistory: {
        display: 'flex',
        justifyContent: 'center',
        height: 70,
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        borderBottomColor: '#FFF',
    },
    callHistoryDelete: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 30,
        backgroundColor: 'rgb(247, 247, 247)',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'white',
        paddingRight: 35,
    },
    callHistoryIcon: {
        margin: theme.spacing(2),
        width: '10%',
        textAlign: 'center',
    },
    callHistoryTime: {
        margin: theme.spacing(2),
        width: '20%',
        textAlign: 'center',
    },
    callHistoryHost: {
        margin: theme.spacing(2),
        width: '60%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

    },
    callHistoryCallBack: {
        width: '15%',
        textAlign: 'right',
    },
}));

function SipCard({
                     userUseCases,
                     sipStatus,
                     sipCall,
                     init,
                     calls,
                     sipHangUp,
                     sipToggleHoldResume,
                     unpauseQueue,
                     pauseQueue,
                     remote,
                     parkings,
                     sseParkingHosts,
                     setHostName,
                     setParking,
                     deleteCalls,
                     reject,
                 }) {
    const classes = useStyles({color: sipStatus.color});
    const {t} = useTranslation();
    const [keyboardShown, setKeyboardShown] = useState(false);
    const [history, callHistory] = useState(false);
    const [destination, setDestination] = useState('');

    function handleClick() {

    }

    function showHideKeyboard() {
        setKeyboardShown(!keyboardShown);
    }

    function keyPressed(value) {
        setDestination(destination + value);
    }

    function removeFromNumber() {
        setDestination(destination.substring(0, destination.length - 1));
    }

    function sipHost(remote) {
        const host = sseParkingHosts && sseParkingHosts.find((a) => a.sipUserName === remote);
        if (host) {
            setParking(host.parkingId);
            setHostName(host.hostName);
        }
    }

    const getHostLink = (remote) => {
        const host = sseParkingHosts && sseParkingHosts.find((a) => (a.sipUserName === remote || a.sipPhoneNumber === remote));
        if (host && isComponentVisibleForUser(UC_HOST_DETAIL, userUseCases)) {
            return (
                <div className={classes.hostContainer}
                     onClick={isComponentEnabledForUser(UC_HOST_DETAIL, userUseCases) ? () => sipHost(remote) : () => {
                     }}>
                    <ParkingSymbol variant="h4"/>
                    <img
                        src={getIcon(host.iconName)}
                        className={classes.image}
                        alt={host.hostName}
                        title={host.hostName}
                    />
                    <Typography variant="h4" gutterBottom>
                        {host.hostName}
                    </Typography>
                </div>
            );
        }
        return (
            <Typography variant="h4" gutterBottom>
                {remote}
            </Typography>
        );
    };
    return (
        <div className={classes.rootContainer}>
            <div className={classes.container}>
                <div className={classes.iconContainer}>
                    {(sipStatus === SIP_STATUS.CONNECTED)
                    && isComponentVisibleForUser(UC_SIP_PAUSE, userUseCases) && (
                        <IconButton
                            className={classes.button}
                            edge="start"
                            color="inherit"
                            disableElevation
                            disabled={!isComponentEnabledForUser(UC_SIP_PAUSE, userUseCases)}
                            onClick={() => {
                                pauseQueue();
                            }}
                        >
                            <Pause/>
                        </IconButton>
                    )}
                    {sipStatus === SIP_STATUS.NEW_CALL
                    && (
                        <IconButton
                            className={classes.noActionButton}
                            edge="start"
                            color="inherit"
                        >
                        <span class="bell">
                          < AlertIcon/>
                        </span>
                        </IconButton>
                    )}
                    {sipStatus === SIP_STATUS.PAUSED
                    && (
                        <IconButton
                            className={classes.button}
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                unpauseQueue();
                            }}
                        >
                            <PlayArrowIcon/>
                        </IconButton>
                    )}
                    {sipStatus !== SIP_STATUS.DISCONNECTED
                    && isComponentVisibleForUser(UC_SIP_KEYBOARD, userUseCases) && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            disabled={!isComponentEnabledForUser(UC_SIP_KEYBOARD, userUseCases)}
                            className={classes.buttonInverse}
                            onClick={showHideKeyboard}
                        >
                            <Keyboard/>
                        </IconButton>
                    )}
                    {sipStatus === SIP_STATUS.DISCONNECTED
                    && isComponentVisibleForUser(UC_SIP_QUEUE_REGISTER, userUseCases) && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            disabled={!isComponentEnabledForUser(UC_SIP_QUEUE_REGISTER, userUseCases)}
                            className={classes.buttonInverse}
                        >
                            <Locked onClick={() => {
                                init();
                            }}/>
                        </IconButton>
                    )}
                </div>
                {sipStatus === SIP_STATUS.NEW_CALL

                && (
                    <div className={classes.statusContainer}>
                        <SipCustomButton
                            label={getHostLink(remote)}
                            color="blue"
                            icon={
                                isComponentVisibleForUser(UC_SIP_DECLINE, userUseCases) ?
                                    [
                                        <IconButton
                                            className={classes.acceptCall}
                                            edge="start"
                                            color="inherit"
                                            disableElevation
                                            onClick={() => {
                                                sipCall('call-audio');
                                            }}
                                        >
                                            <Call/>
                                        </IconButton>,
                                        <IconButton
                                            className={classes.declineCall}
                                            edge="start"
                                            color="inherit"
                                            disableElevation
                                            disabled={!isComponentEnabledForUser(UC_SIP_DECLINE, userUseCases)}
                                            onClick={() => {
                                                reject();
                                            }}
                                        >
                                            <CallEnd/>
                                        </IconButton>] : [<IconButton
                                        className={classes.acceptCall}
                                        edge="start"
                                        color="inherit"
                                        disableElevation
                                        onClick={() => {
                                            sipCall('call-audio');
                                        }}
                                    >
                                        <Call/>
                                    </IconButton>]}
                            width="100%"
                            height="60"
                            size="medium"
                        />
                    </div>
                )}
                {sipStatus === SIP_STATUS.NEW_OUT_GOING_CALL
                && (
                    <div className={classes.statusContainer}>

                        <SipCustomButton
                            label={getHostLink(remote)}
                            color="blue"
                            icon={[<IconButton
                                className={classes.declineCall}
                                edge="start"
                                color="inherit"
                                disableElevation
                                onClick={() => {
                                    sipHangUp({newOutgoingCallDeclined: true});
                                }}
                            >
                                <CallEnd/>
                            </IconButton>]}
                            width="100%"
                            height="60"
                            onClick={() => sipHost(remote)}
                            size="medium"
                        />


                    </div>
                )}
                {sipStatus === SIP_STATUS.CONNECTED
                && (
                    <div className={classes.statusContainer}>
                        <SipCustomButton
                            label={sipStatus.name}
                            color="blue"
                            icon={
                                isComponentVisibleForUser(UC_SIP_HISTORY, userUseCases) ?
                                    [
                                        <IconButton
                                            disabled={!isComponentEnabledForUser(UC_SIP_HISTORY, userUseCases)}
                                            color="inherit"
                                            disableElevation
                                            onClick={() => {
                                                callHistory(!history);
                                            }}
                                        >
                                            {history ? <ArrowLeft/> : <ArrowDropDown/>}
                                        </IconButton>,


                                    ] : []}
                            width="100%"
                            height="60"
                            onClick={handleClick}
                            size="medium"
                        />
                    </div>
                )}
                {sipStatus === SIP_STATUS.IN_CALL
                && (
                    <div className={classes.statusContainer}>
                        <SipCustomButton
                            label={getHostLink(remote)}
                            color="blue"
                            icon={[<IconButton
                                className={classes.declineCall}
                                edge="start"
                                color="inherit"
                                disableElevation
                                onClick={() => {
                                    sipHangUp();
                                }}
                            >
                                <CallEnd/>
                            </IconButton>]}
                            width="100%"
                            height="60"
                            onClick={() => sipHost(remote)}
                            size="medium"
                        />
                    </div>
                )}
                {sipStatus === SIP_STATUS.PAUSED
                && (
                    <div className={classes.statusContainer}>
                        <SipCustomButton
                            label={sipStatus.name}
                            color="blue"
                            icon={[]}
                            width="100%"
                            height="60"
                            size="medium"
                        />
                    </div>
                )}
                {sipStatus === SIP_STATUS.DISCONNECTED
                && (
                    <div className={classes.statusContainer}>
                        <SipCustomButton
                            label={t(sipStatus.name)}
                            color="blue"
                            icon={[]}
                            width="100%"
                            height="60"
                            size="medium"
                        />
                    </div>
                )}
            </div>

            {
                keyboardShown && (
                    <div className={classes.containerKeyboard}>

                        <NumericKeyboard userUseCases={userUseCases} sipCall={sipCall}/>


                    </div>
                )
            }
            {history && calls && calls.length > 0 && (
                <div className={classes.callHistoryDelete}>
                    <DeleteIcon
                        style={{fontSize: 30}}
                        color="error"
                        onClick={() => {
                            deleteCalls();
                        }}
                    />
                </div>
            )}
            <div className={classes.historyContainer}>
                {
                    history && calls.map((c) => (
                        <div className={classes.callHistory}>
                            <div className={classes.callHistoryIcon}>
                                <Typography variant="h4" gutterBottom>
                                    {c.accepted && (
                                        <PhoneCallback style={{fontSize: 30}} color="primary"/>
                                    )}
                                    {
                                        c.out && (
                                            <PhoneForwardedIcon style={{fontSize: 30}} color="primary"/>
                                        )
                                    }
                                    {c.rejected && (
                                        <PhoneDisabledIcon style={{fontSize: 30}} color="primary"/>
                                    )}
                                    {c.missed && (
                                        <PhoneMissedIcon style={{fontSize: 30}} color="error"/>
                                    )}
                                </Typography>
                            </div>
                            <div className={classes.callHistoryTime}>
                                <Typography variant="h4" gutterBottom>
                                    {(c.date.getHours() < 10 ? '0' : '') + c.date.getHours()}
                                    :
                                    {(c.date.getMinutes() < 10 ? '0' : '') + c.date.getMinutes()}
                                    :
                                    {(c.date.getSeconds() < 10 ? '0' : '') + c.date.getSeconds()}
                                </Typography>
                            </div>
                            <div className={classes.callHistoryHost}>
                                {getHostLink(c.number)}
                            </div>
                            <div className={classes.callHistoryCallBack}>
                                <IconButton
                                    className={classes.acceptCall}
                                    edge="start"
                                    color="inherit"
                                    disableElevation
                                    onClick={() => {
                                        sipCall('call-audio', c.number);
                                    }}
                                >
                                    <Call/>
                                </IconButton>
                            </div>

                        </div>
                    ))
                }


            </div>

        </div>
    );
}


const mapStateToProps = (store) => ({
    parkings: store.parkingsData.parkings || [],
    sseParkingHosts: store.parkingsData.parkingHosts || [],
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setHostName,
    setParking,
}, dispatch);


SipCard.propTypes = {
    calls: PropTypes.array,
    status: PropTypes.string,
};

SipCard.defaultProps = {
    calls: [],
    status: undefined,
};

const UC_HOST_DETAIL = 'UC0006';
const UC_SIP_PAUSE = 'UC0050';
const UC_SIP_KEYBOARD = 'UC0055';

const UC_SIP_QUEUE_REGISTER = 'UC0051';
const UC_SIP_HISTORY = 'UC0052';
const UC_SIP_DECLINE = 'UC0054';

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SipCard);
