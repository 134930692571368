import React, { useCallback, useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Parser from 'html-react-parser';
import Typography from '@material-ui/core/Typography/Typography';
import {useTranslation} from 'react-i18next';
import MaterialTable from 'material-table';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0.5, 0.5),
    boxShadow: `3px 3px 5px ${theme.palette.base[200]}`,
    minHeight: "50vh",
  },
  aboutContent:{
	  minHeight: "50vh",
	  padding: theme.spacing(1.5),
	  flexWrap: 'wrap',
  },
  row:{
	  marginTop: theme.spacing(1.5),
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
}));

const cellStyle = {
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: '19px',
  color: '#1B1B28',
};


function AboutPaper(props) {

  const classes = useStyles();
  const {t} = useTranslation();

  const {
    user,
    aboutList,
    fetchAboutList,
    isFetchingAboutList,
  } = props;

  const [rowDetailName, setRowDetailName] = useState({});
  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  //const [isFetchedAboutList, setIsFetchedAboutList] = useState(false);
  //const [isLoadingAboutList, setIsLoadingAboutList] = useState(true);

  const aboutListFce = useCallback(() => {
    if (
      user
      && user.subjectId
      && !isFetchingAboutList
    ) {
      fetchAboutList().then(() => {
        if (!isUnMounted.current) {
          //setIsFetchedAboutList(true);
          // setIsLoadingAboutList(false);
        }
      }).catch(() => {
        if (!isUnMounted.current) {
          //setIsFetchedAboutList(false);
          // setIsLoadingAboutList(false);
        }
      });
    }
  }, [fetchAboutList, isFetchingAboutList, user]);

  useEffect(() => {
    if (!isFetchingAboutList) {
      aboutListFce();
    }
  }, [aboutListFce, isFetchingAboutList, user]);

  const onRowClick = (event, rowId) => {
    console.log('onRowClick');
    console.log(event);
    console.log(rowId);
    if ( rowDetailName!=rowId.versionName) {
      setRowDetailName(rowId.versionName);
    } else {
      setRowDetailName('');
    }
  };

  const renderAbouts = (about) => {
    const rowsNotes = [];
    if (isFetchingAboutList && about || about.messages)  {
      about.messages.forEach((element) => {
        rowsNotes.push(
            <>{Parser(element.message)}</>
        );
      });
    }

    const columns = [
      {  
        title: t('VERSION_NAME'),
        field: 'versionName',
        headerStyle: {textAlign: 'left', paddingLeft: 5},
        cellStyle: {
            ...cellStyle,
            align: 'center',
        },
        render: (rowData) => (
          rowData.versionName
        )
      },
      {   
        title: 'Version change log',
        field: t('CHANGE_LOG'),
        headerStyle: {textAlign: 'left', paddingLeft: 5},
        cellStyle: {
            ...cellStyle,
            align: 'center',
        },
        render: (rowData) => (
          <>
            {rowData.versionName === rowDetailName &&
              Parser(rowData.changeLog)
            }
            {rowData.versionName !== rowDetailName &&
              t('CLICK_FOR_DETAIL')
            }
          </>
        )
      },
    ];

    return (
      <>
        <div className={classes.container}>
          <Paper className={classes.aboutContent}>
            {rowsNotes}
          </Paper>
        </div>
        <Typography variant="h4" className={classes.title}>
          {t('RELEASE_NOTES')}
        </Typography>
        <div className={classes.container}>
          <Paper className={classes.aboutContent}>
            <MaterialTable
                localization={{
                    body: {
                        emptyDataSourceMessage: t('NO_RECORD'),
                    },
                    toolbar: {
                        exportName: 'Export',
                        searchTooltip: t('SEARCH_INNER'),
                        searchPlaceholder: t('SEARCH_INNER'),
                    }
                }}
                columns={columns}
                data={about.versions}
                onRowClick={onRowClick}
                options={{
                    search: false,
                    paging: false,
                    showTitle: false
                }}
                style={{
                    boxShadow: 'none',
                    padding: '0 10px',
                }}
            />          
          </Paper>
        </div>
      </>
    );
  };


  return (
    <>
      {renderAbouts(aboutList)}
    </>
  );
}


AboutPaper.propTypes = {
  user: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  aboutList: PropTypes.array.isRequired,
  fetchAboutList: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  user: store.authData.user,
  // isRO: store.authData.isRO,
  aboutList: store.aboutData.aboutList,
  isUpdating: store.aboutData.isUpdating,
});

export default connect(
  mapStateToProps,
)(AboutPaper);
