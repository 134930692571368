import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { checkAssigned, getState } from 'utils';
import { CustomTable } from 'components/elements';
import { ActionButton, EventType } from 'components/common';
import TasksToolbar from 'containers/Parking/LocalParking/MobileOperatorTasks/ToolBar';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',

    '& button': {
      border: `1px solid ${theme.palette.base.white}`,
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mr: {
    marginRight: theme.spacing(2.5),
  },
  attachment: {
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
}));

const cellStyle = (rowData) => ({
  paddingLeft: 4,
  paddingRight: 4,
  borderRight: '1px solid white',
  lineHeight: '19px',
  color: checkAssigned(rowData) ? '#1B1B28' : '#FFFFFF',
  textAlign: 'center',
});

function TasksTable(props) {
  const classes = useStyles();

  const {
    isLoading,
    parkingTasks,
    tasksPaging,
    taskHosts,
    state,
    hosts,
    createdByName,
    users,
    handleState,
    handleCancel,
    handleRenew,
    handleTaskDetailOpen,
    handleSelectHosts,
    handleUser,
    handleSearch,
    handleAddTask,
    handleChangeRowsPerPage,
    handleChangePage,
    userUseCases,
  } = props;

  const { t } = useTranslation();

  const columns = [
    {
      title: t('CREATION_DATE'),
      field: 'creationDate',
      cellStyle: (data, rowData) => ({
        ...cellStyle(rowData),
        borderLeft: '1px solid white',
      }),
    },
    { title: t('CREATED_BY'), field: 'creationBy', cellStyle: (data, rowData) => cellStyle(rowData) },
    { title: t('ASSIGNED_TO'), field: 'solver', cellStyle: (data, rowData) => cellStyle(rowData) },
    { title: t('HOST'), field: 'parkingHostName', cellStyle: (data, rowData) => cellStyle(rowData) },
    { title: t('DATE_ASSIGNED'), field: 'acceptanceDate', cellStyle: (data, rowData) => cellStyle(rowData) },
    { title: t('DATE_OF_END'), field: 'solvedDate', cellStyle: (data, rowData) => cellStyle(rowData) },
    { title: t('NOTIFICATION'), field: 'description', cellStyle: (data, rowData) => cellStyle(rowData) },
    { title: t('RESOLUTION'), field: 'resolution', cellStyle: (data, rowData) => cellStyle(rowData) },
    {
      title: t('STATE'),
      field: 'status',
      cellStyle: (data, rowData) => cellStyle(rowData),
      render: (rowData) => (
        <EventType type={getState(rowData.status, t)} />
      ),
    },
    {
      title: t('TASK_DETAIL'),
      field: 'details',
      cellStyle: (data, rowData) => cellStyle(rowData),
      render: (rowData) => {
        return isComponentVisibleForUser(UC_TASK_DETAIL, userUseCases) ?
        <ActionButton
            disabled={!isComponentEnabledForUser(UC_TASK_DETAIL, userUseCases)}
            action="details"
            handleClick={handleTaskDetailOpen({
              task: rowData,
              image: rowData.image ? rowData.image.file : null,
              imageModal: false,
            })}
        /> : null
      },
    },
    {
      title: t('ACTION'),
      field: 'action',
      cellStyle: (data, rowData) => cellStyle(rowData),
      render: (rowData) => {
        return  rowData.status === 'CA' && isComponentVisibleForUser(UC_TASK_CANCEL, userUseCases) ? 
        <ActionButton
          disabled={!isComponentEnabledForUser(UC_TASK_CREATE, userUseCases)}
          action={rowData.status === 'CA' ? 'renew' : ''}
          handleClick={rowData.status === 'CA' ? handleRenew(rowData) : null }
        /> 
        : 
        isComponentVisibleForUser(UC_TASK_CREATE, userUseCases) ? 
        <ActionButton
          disabled={!isComponentEnabledForUser(UC_TASK_CANCEL, userUseCases)}
          action={rowData.status === 'CO' || rowData.status === 'CA' ? '' : 'cancel'}
          handleClick={rowData.status === 'CO' || rowData.status === 'CA' ? null : handleCancel(rowData)}
        /> : null
      },
    },
    {
      title: t('ATTACHMENT'),
      field: 'image',
      cellStyle: (data, rowData) => cellStyle(rowData),
      render: (rowData) => (rowData.image ? (
        <img
          src={`data:image/png;base64,${rowData.image.file}`}
          className={classes.attachment}
          onClick={handleTaskDetailOpen({
            task: rowData,
            image: rowData.image.file,
            imageModal: true,
          })}
          alt=""
        />
      ) : null),
    },
  ];

  return (
    <div className={classes.container}>
      <TasksToolbar
        userUseCases={userUseCases}
        taskHosts={taskHosts}
        tasks={parkingTasks}
        state={state}
        selectedHosts={hosts}
        createdByName={createdByName}
        users={users}
        handleState={handleState}
        handleSelectHosts={handleSelectHosts}
        handleUser={handleUser}
        handleSearch={handleSearch}
        handleAddTask={handleAddTask}
      />
      <CustomTable
        title=""
        columns={columns}
        data={parkingTasks}
        options={{
          headerStyle: {
            padding: '15px 8px 15px 34px',
            borderTop: '1px solid #DEE2E5',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
          },
          rowStyle: (data) => ({
            background: checkAssigned(data) ? 'white' : '#F56C5D',
          }),
        }}
        isLoading={isLoading}
        rowsPerPage={tasksPaging.pageSize}
        pageNumber={tasksPaging.currentPage}
        count={tasksPaging.totalSize}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        loadAllData
      />
    </div>
  );
}

TasksTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  parkingTasks: PropTypes.array.isRequired,
  tasksPaging: PropTypes.object.isRequired,
  taskHosts: PropTypes.array.isRequired,
  state: PropTypes.array.isRequired,
  hosts: PropTypes.array.isRequired,
  createdByName: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  handleState: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleRenew: PropTypes.func.isRequired,
  handleTaskDetailOpen: PropTypes.func.isRequired,
  handleSelectHosts: PropTypes.func.isRequired,
  handleUser: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleAddTask: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

const UC_TASK_DETAIL = 'UC0036';
const UC_TASK_CANCEL = 'UC0037';
const UC_TASK_CREATE = 'UC0038';

export default TasksTable;
