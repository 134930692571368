import React, {
    useEffect, useState, useCallback, useRef,
} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import Typography from '@material-ui/core/Typography/Typography';
import {makeStyles} from '@material-ui/core/styles';

import {fetchDownloadList, fetchDownloadFile} from 'redux/actions/download';
import {Loading} from 'components/elements';
import Download from './Download';
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    title: {
        marginBottom: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    container: {
        marginTop: theme.spacing(3.75),
    },
}));

function DownloadManagement(props) {
    const classes = useStyles();

    const {
        history,
        user,
        isRO,
        fetchDownloadList,
        fetchDownloadFile,
        isFetchingDownloadList
    } = props;

    const {t} = useTranslation();

    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    const [isFetchedDownload, setIsFetchedDownload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const downloadList = useCallback(() => {
        if (user) {
            fetchDownloadList()
                .then(() => {
                    if (!isUnMounted.current) {
                        setIsLoading(false);
                        setIsFetchedDownload(true);
                    }
                })
                .catch(() => {
                    if (!isUnMounted.current) {
                        setIsLoading(false);
                        setIsFetchedDownload(false);
                    }
                });
        }
    }, [fetchDownloadList, user]);

    useEffect(() => {
        if (!isFetchedDownload) {
            setIsFetchedDownload(true);
            fetchDownloadList();
        }
    }, [fetchDownloadList, isFetchedDownload, user]);

    if (!user) {
        return (
            <Loading/>
        );
    }

    if (isRO) {
        history.push(`/${process.env.REACT_APP_PATH}/parking`);
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >
            <div className={classes.container}>
                <Typography
                    variant="h4"
                    className={classes.title}
                >
                    {t('DOWNLOAD_WINDOW')}
                </Typography>
                <Download
                    user={user}
                    isLoading={isLoading}
                    downloadList={downloadList}
                    isFetchingDownloadList={isFetchingDownloadList}
                    fetchDownloadList={fetchDownloadList}
                    fetchDownloadFile={fetchDownloadFile}
                />
            </div>
        </PerfectScrollbar>
    );

}

DownloadManagement.propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    isRO: PropTypes.bool.isRequired,
    downloadList: PropTypes.array.isRequired,
    fetchDownloadList: PropTypes.func.isRequired,
    fetchDownloadFile: PropTypes.func.isRequired,
};

DownloadManagement.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    isRO: store.authData.isRO,
    downloadList: store.downloadData.downloadList,
    downloadFile: store.downloadData.downloadFile,
    isFetchingDownloadList: store.downloadData.isFetchingDownloadList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDownloadList, fetchDownloadFile
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DownloadManagement);
