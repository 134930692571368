import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {CustomTable, Loading} from 'components/elements';
import {connect} from 'react-redux';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import {deleteParking} from 'redux/actions/parkings';
import {ActionButton} from "../../../components/common";
import {getErrorMessage, isAdmin, isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from "@material-ui/icons/Cancel";
import PlusIcon from "@material-ui/icons/Add";
import InfoIcon from '@material-ui/icons/Info';
import CertificateIcon from '@material-ui/icons/VerifiedUser';
import SecurityIcon from '@material-ui/icons/Security';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import PMCDialog from 'components/common/PMCDialog';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 20,
    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 20,
    },
    actions: {
        display: 'flex',
        width: '75%',
        justifyContent: 'space-around',
    },
    parkingCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-around',
        margin: 0,
        height: '100%',
        textAlign: 'center',
    },
    yellowPaper: {
        background: theme.palette.secondary.lightYellow,

    },
    expiringPos: {
        backgroundColor: '#e5cfcc',
    },
    grey: {
        backgroundColor: theme.palette.base[100],
    },
    disabled: {
        backgroundImage: 'linear-gradient(135deg, #FFFFFF00 25%, #FFFFFF3F 25%, #FFFFFF3F 50%, #FFFFFF00 50%, #FFFFFF00 75%, #FFFFFF3F 75%, #FFFFFF3F 100%)',
        backgroundSize: '20px 20px',
    },
    root: {
        width: 380,
        height: 40,
        background: theme.palette.base[100],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    state: {
        display: 'flex',
    },
    row1: {
        width: 380,
        height: 90,
        fontSize: 14,
        padding: 5,
        marginTop: -10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        width: 380,
        height: 20,
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    rowColumn2: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 25,
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    green2: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red2: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue2: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '30%',
        marginRight: theme.spacing(2.5),
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 18,
        textAlign: 'center',
        marginLeft: 20
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
        marginLeft: 20
    },
    label2: {
        color: theme.palette.base[400],
        fontSize: 18,
        textAlign: 'center',
    },
    value2: {
        color: theme.palette.base[700],
        fontSize: 24,
        minHeight: 25,
        textAlign: 'center',
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function RoleList(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        role,
        userUseCases,
        isLoading,
        handleRoleDetail,
        handleRoleUpdate,
        handleActivate,
        save,
        refresh,
        setDeletionSnackbarOpen,
        setDeletionSnackbarError,
        confirmModalOpened,
        setConfirmModalOpened,
        table,
        user
    } = props;
    
    const [roleToActive, setRoleToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);

    const saveInner = (role) => {
        setActionInProgress(true);
        save(role).then(
            () => {
                refresh();
                setActionInProgress(false);
            }
        ).catch((error) => {
            setDeletionSnackbarError(getErrorMessage(error, t));
            setActionInProgress(false);
        });
    }

    const renderRoleState = (param) => {
        if (!param) {
            return <Tooltip title={t('ROLE_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        } else {
            if ( param.active )
                return <Tooltip title={t('ROLE_ACTIVE')}><CertificateIcon color="primary" style={{ fontSize: 40 }}/></Tooltip>
            else                
                return <Tooltip title={t('ROLE_INACTIVE')}><SecurityIcon color="error" style={{ fontSize: 40 }}/></Tooltip>
        }
    }

    const renderRoleAdmin = (param) => {
        if (param && param.admin) {
            return <Tooltip title={t('ROLE_ADMIN')}><SecurityIcon color="secondary" style={{ fontSize: 40 }}/></Tooltip>
        }
    }

    const renderRoleAdminTable = (param) => {
        if ( param && param.admin) {
            return <>{t('YES')}</>
        } else {
            return <>{t('NO')}</>
        }
    }

    const columns = [
        {   title: t('STATE'),
            field: 'active',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5
            },
            render: (rowData) => (
                renderRoleState(rowData)
            )
        },
        {
            title: t('ROLE_NAME'),
            field: 'name',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.name
            )
        },
        {
            title: t('UC_COUNT'),
            field: 'ucCount',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.ucCount
            )
        },
        {
            title: t('USER_COUNT'),
            field: 'userCount',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },

            render: (rowData) => (
                rowData.userCount
            ),
        },
        {
            title: t('ROLE_ADMIN'),
            field: 'admin',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderRoleAdminTable(rowData)
            ),
        },
        {
            title: t('SUBJECT'),
            field: 'subjectName',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.subjectName
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC_ROLE_DETAIL, userUseCases) &&
                        <Tooltip title={t('SHOW_ROLE_DETAIL')}>
                            <ActionButton 
                                handleClick={() => {handleRoleDetail(rowData)}}
                                disabled={!isComponentEnabledForUser(UC_ROLE_DETAIL, userUseCases) || actionInProgress}
                                className={classes.green2}
                                action="detail"
                            />
                        </Tooltip>
                    }
                    {isComponentVisibleForUser(UC_ROLE_UPDATE, userUseCases) &&
                        <Tooltip title={t('SHOW_ROLE_EDIT')}>
                            <ActionButton 
                                handleClick={() => {handleRoleUpdate(rowData, false)}}
                                disabled={!isComponentEnabledForUser(UC_ROLE_UPDATE, userUseCases) || actionInProgress}
                                className={classes.red2}
                                action="edit"
                            />
                        </Tooltip>
                    }
                    {isComponentVisibleForUser(UC_ROLE_ACTIVATE, userUseCases) &&
                        <Tooltip title={rowData.active?t('SHOW_ROLE_ACTIVE'):t('SHOW_ROLE_DEACTIVE')}>
                            <ActionButton 
                                handleClick={() => {
                                    setRoleToActive(rowData);
                                    setConfirmModalOpened(true);
                                }}
                                disabled={!isComponentEnabledForUser(UC_ROLE_ACTIVATE, userUseCases) || actionInProgress}
                                className={classes.blue2}
                                action={rowData.active?t('DEACTIVATE'):t('ACTIVATE')}
                            />
                        </Tooltip>
                    }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {confirmModalOpened
            && (    
                <PMCDialog
                    open={confirmModalOpened}
                    title={`${roleToActive.active?t('ROLE_INACTIVATION'):t('ROLE_ACTIVATION')}`}
                    onClose={() => {
                        setConfirmModalOpened(false);
                        setRoleToActive(null);
                    }}
                    size="sm"
                    headerColor='#5985EE'
                    height={300}
                    onSubmit={ () => {handleActivate(roleToActive);}}
                    actionType={roleToActive.active?'roleDeactivate':'roleActivate'}
                >
                    
                    <div className={classes.content}>
                    {roleToActive.active?t('CONFIRM_ROLE_DEACTIVE', {roleName: roleToActive.name}):t('CONFIRM_ROLE_ACTIVE', {roleName: roleToActive.name})}
                    </div>
                </PMCDialog>
            )}


            {!isLoading ?
                table ? <CustomTable
                    title=""
                    columns={columns}
                    data={role}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                />:
                role.map((roleItem, index) => (
                    <div
                        className={classes.parkingCard}>
                        <div className={classes.row1}>
                            <div className={classes.state}>
                                {renderRoleState(roleItem)}
                            </div>
                            <div className={classes.title}>
                                <Tooltip title={t('NAME_SUBJECT')}>
                                    <Typography variant="h4">{roleItem.name} {roleItem.subjectName?' ('+roleItem.subjectName+')' : ''}</Typography>
                                </Tooltip>
                            </div>
                            <div className={classes.actionIcon}>
                                {isComponentVisibleForUser(UC_ROLE_UPDATE, userUseCases) &&
                                <Tooltip title={t('EDIT')}>
                                    <IconButton onClick={() => {
                                            handleRoleUpdate(roleItem, false)
                                        }}
                                        disabled={!isComponentEnabledForUser(UC_ROLE_DETAIL, userUseCases) || ( !isAdmin(user) && user.subjectId != roleItem.subjectId ) }>
                                        <EditIcon color="disable" style={{ fontSize: 30 }}/>
                                    </IconButton>
                                </Tooltip>}
                            </div>
                        </div>
                        <div className={classes.row2}>

                            <div className={classes.rowColumn}>
                                <div className={classes.label}>{t('UC_COUNT')}</div>
                                <div className={classes.value}>{roleItem.ucCount}</div>
                            </div>
                            <div className={classes.rowColumn2}>
                                <Typography variant="h6">
                                    {renderRoleAdmin(roleItem)}
                                </Typography>
                            </div>
                            <div className={classes.rowColumn}>
                                <div className={classes.label2}>{t('USER_COUNT')}</div>
                                <div className={classes.value2}>{roleItem.userCount}</div>
                            </div>
                        </div>
                        <BottomNavigation
                            className={classes.root}
                        >
                            {isComponentVisibleForUser(UC_ROLE_DETAIL, userUseCases) &&
                                <Tooltip title={t('SHOW_ROLE_DETAIL')}>
                                    <BottomNavigationAction onClick={() => {handleRoleDetail(roleItem)}}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC_ROLE_DETAIL, userUseCases)}
                                        className={classes.blue}
                                        label="Detail"
                                        icon={<InfoIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                            {isComponentVisibleForUser(UC_ROLE_ACTIVATE, userUseCases) &&
                                <Tooltip title={roleItem.active?t('SHOW_ROLE_DEACTIVE'):t('SHOW_ROLE_ACTIVE')}>
                                    <BottomNavigationAction onClick={() => {
                                        setRoleToActive(roleItem);
                                        setConfirmModalOpened(true);
                                    }}
                                        disabled={actionInProgress || !isComponentEnabledForUser(UC_ROLE_DETAIL, userUseCases)}
                                        className={classes.orange}
                                        label="Active"
                                        icon={roleItem.active?<CancelIcon className={classes.actionIcon}/>:<PlusIcon className={classes.actionIcon}/>}
                                    />
                                </Tooltip>
                            }
                        </BottomNavigation>
                    </div>
                ))
                :
                <Loading/>
            }
        </div>
    );
}
RoleList.propTypes = {
    parkings: PropTypes.array.isRequired,
};
const UC_ROLE_DETAIL = 'UC0654';
const UC_ROLE_UPDATE = 'UC0652';
const UC_ROLE_ACTIVATE = 'UC0653';

RoleList.defaultProps = {};
const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({deleteParking}, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RoleList);