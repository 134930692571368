import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import aboutData from './about';
import downloadData from './download';
import authData from './auth';
import parkingsData from './parkings';
import eventsData from './events';
import tasksData from './tasks';
import zonesData from './zones';
import hostsData from './hosts';
import cardsData from './cards';
import ownersData from './owners';
import journalsData from './journals';
import paymentData from './payment';
import errorsData from './errors';
import codesData from './codes';
import mobilesData from './mobiles';
import usersData from './users';
import photoData from './photo';
import productsData from './products';
import branchesData from './branches';
import subjectsData from './subjects';
import rolesData from './roles';
import useCasesData from './usecases';
import receiptsData from './receipts';
import currenciesData from './currencies';
import reportsData from './reports';
import fulltextData from './fulltext';
import posData from './pos';
import playListData from './playLists';
import soundsData from './sounds';
import httpserviceData from './httpservice';


export default (history) => combineReducers({
    router: connectRouter(history),
    aboutData,
    downloadData,
    authData,
    parkingsData,
    eventsData,
    tasksData,
    zonesData,
    hostsData,
    cardsData,
    ownersData,
    journalsData,
    paymentData,
    errorsData,
    codesData,
    mobilesData,
    usersData,
    photoData,
    productsData,
    branchesData,
    subjectsData,
    rolesData,
    receiptsData,
    currenciesData,
    reportsData,
    fulltextData,
    posData,
    useCasesData,
    playListData,
    soundsData,
    httpserviceData
});
