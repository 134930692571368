import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {fetchAllProductGroups, fetchAllProductTemplates} from 'redux/actions/products';
import {fetchAllBranches} from 'redux/actions/branches';
import {fetchAllSubjects} from 'redux/actions/subjects';
import {fetchAllRoles} from 'redux/actions/roles';
import RichTextEditor from 'react-rte';
import classnames from 'classnames';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import WizardStep2Table from './WizardStep2Table';
import WizardStep1 from './WizardStep1';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import {ActionButton} from 'components/common';
import WizardStep2TableMacro from "./WizardStep2TableMacro";
import PMCSnackbar from "../../common/Snackbar";
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    contentGrey: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
        background: theme.palette.base[200],

    },
    stepNavigation: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: theme.palette.base.white,
        boxShadow: theme.palette.shadow.main,
        '& .MuiStepIcon-active': {
            color: theme.palette.secondary.main,
        }

    },
    root: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    root1: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
        paddingBottom: '24px',
    },

    desc: {
        width: '100%',
        height: '35px',
        textAlign: 'center',
        color: '#A2A2A2',
        fontSize: '25px',
    },
    descBelow: {
        width: '100%',
        height: '35px',
        display: 'flex',
        flexDirection: 'row',
        color: '#A2A2A2',
        fontSize: '20px',
        justifyContent: 'center'
    },
    descItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: '4px',
    },
    descLabel: {
        color: '#A2A2A2',
        fontSize: '10px',
    },
    descValue: {
        color: '#1B1B28',
        fontSize: '12px',
    },
    button: {
        width: 200,
        height: 55,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 25,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
}));


function Product(props) {
    const {
        userUseCases,
        parkings,
        productGroups,
        productTemplates,
        branches,
        subjects,
        roles,
        fetchAllProductGroups,
        fetchAllProductTemplates,
        fetchAllBranches,
        fetchAllSubjects,
        fetchAllRoles,
        product,
        save,
        opened,
        handleClose,
        readOnly,
        productType,
        snackbarOpen,
        error,
        setSnackbarOpen,
        setError,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const [description, setDescription] = useState(RichTextEditor.createEmptyValue());
    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updatedProduct, setUpdatedProduct] = useState();
    const [errorStep1, setErrorStep1] = useState({});
    const [errorStep2, setErrorStep2] = useState({});
    const [mode, setMode] = useState('COMMON');

    const [productTypeInner, setProductTypeInner] = useState();


    useEffect(() => {
        setProductTypeInner(productType);
    }, [productType]);


    useEffect(() => {
        if (product) {
            setUpdatedProduct(product);
            if (product.description) {
                setDescription(RichTextEditor.createValueFromString(product.description, 'html'));
            } else {
                setDescription(RichTextEditor.createEmptyValue());
            }
            if (product && product.productTemplateId) {
                const template = productTemplates.find(a => a.productTemplateId === product.productTemplateId);
                if (template) {
                    setProductTypeInner(template.productTemplateType);
                    setProductTemplateParams(template.productTemplateParams);
                }
            }
        }
    }, [product]);


    useEffect(() => {
        setActiveStep(0);
    }, [opened]);


    useEffect(() => {
        if (!isFetched) {
            fetchAllProductGroups().then(() => {
                fetchAllProductTemplates().then(() => {
                    fetchAllBranches().then(() => {
                        fetchAllSubjects().then(
                            fetchAllRoles().then(
                            ).catch((err) => {
                                setIsLoading(false);
                            })
                        ).catch((err) => {
                            setIsLoading(false);
                        });
                    }).catch((err) => {
                        setIsLoading(false);
                    });
                }).catch((err) => {
                    setIsLoading(false);
                    setIsFetched(true);
                });
            }).catch((err) => {
                setIsLoading(false)
            });
        }
    }, [fetchAllProductGroups, fetchAllProductTemplates, isFetched]);


    const [parkingsValues, setParkingsValues] = useState([]);

    useEffect(() => {
        if (parkings) {
            setParkingsValues(parkings.map(a => {
                return {
                    value: a.parkingId,
                    label: a.parkingName,
                    key: a.parkingId,
                    parkingName: a.parkingName,
                    parkingColor: a.parkingColor
                }
            }));
        }
    }, [parkings]);


    const [productTemplatesValues, setProductTemplatesValues] = useState([]);

    useEffect(() => {
        if (productTemplates) {
            setProductTemplatesValues(productTemplates.filter(a => (!productTypeInner || a.productTemplateType === productTypeInner)).map(a => {
                return {
                    value: a.productTemplateId,
                    label: a.productTemplateCode ? t(a.productTemplateCode) : a.productTemplateName,
                    key: a.productTemplateId
                }
            }));

            if (updatedProduct && updatedProduct.productTemplateId) {
                const template = productTemplates.find(a => a.productTemplateId === updatedProduct.productTemplateId);
                if (template) {
                    setProductTypeInner(template.productTemplateType);
                    setProductTemplateParams(template.productTemplateParams);
                }
            }
        }
    }, [productTemplates, productTypeInner]);

    const [branchValues, setBranchValues] = useState([]);


    useEffect(() => {
        if (branches) {
            setBranchValues(branches.map(a => {
                return {value: a.id, label: a.branchName, key: a.id}
            }));
        }
    }, [branches]);


    const [rolesValues, setRolesValues] = useState([]);


    useEffect(() => {
        if (roles) {
            setRolesValues(roles.map(a => {
                return {value: a.roleId, label: a.name, key: a.roleId}
            }));
        }
    }, [roles]);


    const [productGroupValues, setProductGroupValues] = useState([]);


    useEffect(() => {
        if (productGroups) {
            setProductGroupValues(productGroups.map(a => {
                return {value: a.productGroupId, label: a.productGroupName, key: a.productGroupId}
            }));
        }
    }, [productGroups]);

    const [subjectValues, setSubjectValues] = useState([]);

    useEffect(() => {
        if (subjects) {
            setSubjectValues(subjects.map(a => {
                return {value: a.subjectId, label: a.name, key: a.subjectId}
            }));
        }
    }, [subjects]);


    const handleAddProductGroup = (chip) => {
        if (chip) {
            if (chip.inputValue) {
                setUpdatedProduct({...updatedProduct, 'productGroupName': chip.inputValue, 'productGroupId': null});
            } else {
                setUpdatedProduct({...updatedProduct, 'productGroupName': chip.label, 'productGroupId': chip.value});
            }
        } else {
            setUpdatedProduct({...updatedProduct, 'productGroupName': undefined, 'productGroupId': undefined});
        }
    };

    const handleSubject = (role) => {
        handleChange('subjectId')(role.value);
    };

    const handleBranch = (role) => {
        handleChange('branchId')(role.value);
    };


    const handleRoles = (roles) => {
        handleChange('rolesId')(roles.map(a => a.value));
    };


    const setProductSystemParam = (systemParamName, systemParamValueType, systemParamType, value) => {
        const updatedProjectSystemParamCopy = updatedProduct.productSystemParam ?
            updatedProduct.productSystemParam.filter(a => a.paramName !== systemParamName) : [];
        let changedProperty = updatedProduct.productSystemParam && updatedProduct.productSystemParam.find(a => a.paramName === systemParamName);
        if (!changedProperty) {
            changedProperty = {};
            changedProperty['paramType'] = systemParamType;
            changedProperty['paramName'] = systemParamName;
        } else {
            changedProperty = {...changedProperty};
        }
        changedProperty[systemParamValueType] = value;
        updatedProjectSystemParamCopy.push(changedProperty);
        const newUpdatedProduct = {...updatedProduct};
        newUpdatedProduct['productSystemParam'] = updatedProjectSystemParamCopy;
        setUpdatedProduct(newUpdatedProduct);
    };

    const getProductSystemParam = (systemParamName, systemParamType) => {
        if (updatedProduct && updatedProduct.productSystemParam) {
            let property = updatedProduct.productSystemParam.find(a => a.paramName === systemParamName);
            if (property) {
                return property[systemParamType];
            }
        }
    };

    const [productTemplateParams, setProductTemplateParams] = useState([]);

    const addChildToMacroSelect = (name) => () => {
        let changedProperty = updatedProduct.productParam.find(a => a.paramName === name);
        const updatedProjectParamCopy = updatedProduct.productParam.filter(a => a.paramName !== name);
        changedProperty = {...changedProperty};
        if (!changedProperty.macroSelect) {
            changedProperty.macroSelect = [];
        }
        let defaultIndex = changedProperty.macroSelect.findIndex(a => a.default);
        changedProperty.macroSelect.push({
            label: name,
            values: [],
            default: false,
        });
        if (defaultIndex === -1) {
            changedProperty.macroSelect[0].default = true;
        }

        updatedProjectParamCopy.push(changedProperty);
        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParam'] = updatedProjectParamCopy;
        setUpdatedProduct(newUpdatedProduct);
    }

    const removeChildToMacroSelect = (name) => (index) => {
        let changedProperty = updatedProduct.productParam.find(a => a.paramName === name);
        const updatedProjectParamCopy = updatedProduct.productParam.filter(a => a.paramName !== name);
        changedProperty = {...changedProperty};
        changedProperty.macroSelect.splice(index, 1);
        let defaultIndex = changedProperty.macroSelect.findIndex(a => a.default);
        if (defaultIndex === -1 && changedProperty.macroSelect.length > 0) {
            changedProperty.macroSelect[0].default = true;
        }
        updatedProjectParamCopy.push(changedProperty);
        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParam'] = updatedProjectParamCopy;
        setUpdatedProduct(newUpdatedProduct);
    }

    const handleReferenceChange = (parkingId) => {
        const parkingSubjects = updatedProduct.productParkingsSubjects ? [...updatedProduct.productParkingsSubjects] : [];
        for (let i = 0; i < parkingSubjects.length; i++) {
            if (parkingSubjects[i].parkingId === parkingId) {
                parkingSubjects[i].reference = true;
            } else {
                parkingSubjects[i].reference = false;
            }
        }

        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParkingsSubjects'] = parkingSubjects;
        setUpdatedProduct(newUpdatedProduct);

    }


    const handleMacroParkingSubject = (parkingId, subject) => {
        const parkingSubjects = updatedProduct.productParkingsSubjects ? [...updatedProduct.productParkingsSubjects] : [];
        for (let i = 0; i < parkingSubjects.length; i++) {
            if (parkingSubjects[i].parkingId === parkingId) {
                parkingSubjects[i].subjectId = subject.value;
            }
        }

        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParkingsSubjects'] = parkingSubjects;
        setUpdatedProduct(newUpdatedProduct);
    }


    const handleMacroParkings = (parkings) => {
        let parkingSubjects;
        if (!parkings || parkings.length === 0) {
            parkingSubjects = [];
        } else {
            parkingSubjects = updatedProduct.productParkingsSubjects ? [...updatedProduct.productParkingsSubjects] : [];
            for (let i = 0; i < parkings.length; i++) {
                let index = parkingSubjects.findIndex(a => a.parkingId === parkings[i].value);
                if (index === -1) {
                    parkingSubjects.push({parkingId: parkings[i].value, reference: false});
                }
            }
            for (let i = parkingSubjects.length - 1; i >= 0; i--) {
                let index = parkings.findIndex(a => a.value === parkingSubjects[i].parkingId);
                if (index === -1) {
                    parkingSubjects.splice(i, 1);
                }
            }
            const refe = parkingSubjects.find(a => a.reference === true);
            if (!refe) {
                parkingSubjects[0].reference = true;
            }
        }
        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParkingsSubjects'] = parkingSubjects;
        setUpdatedProduct(newUpdatedProduct);
    }

    const getSteps = () => {
        return [t('BASIC_INFORMATION'), t('PRODUCT_SETTING')];
    }

    const isValid = (activeStep) => {
        //return true;
        let ret = true;
        const error = {};
        if (activeStep === 0) {
            if (!updatedProduct.productTemplateId) {
                error.productTemplateId = 'required';
                ret = false;
            }
            const name = getProductSystemParam('name', 'valueVarchar');
            if (!name || name.trim().length === 0) {
                error.name = 'required';
                ret = false;
            }

            if (productTypeInner === 'MP') {
                if (!updatedProduct.productParkingsSubjects || updatedProduct.productParkingsSubjects.length === 0) {
                    error.parkingId = 'required';
                    ret = false;
                }
            } else {
                if (!updatedProduct.parkingId && updatedProduct.parkingId != 0) {
                    error.parkingId = 'required';
                    ret = false;
                }
                if (!updatedProduct.subjectId && updatedProduct.subjectId != 0) {
                    error.subjectId = 'required';
                    ret = false;
                }
            }
            if (!updatedProduct.branchId) {
                error.branchId = 'required';
                ret = false;
            }
            if (!updatedProduct.dateFrom) {
                error.dateFrom = 'required';
                ret = false;
            }


            if (!(updatedProduct.productGroupId || updatedProduct.productGroupName)) {
                error.productGroupId = 'required';
                ret = false;
            }
            if (!updatedProduct.rolesId || updatedProduct.rolesId.length === 0) {
                error.rolesId = 'required';
                ret = false;
            }
            setErrorStep1(error)
        } else if (activeStep === 1) {
            if (productTypeInner === 'MP') {
                if (updatedProduct.productParkingsSubjects) {
                    error.subjectId = {};
                    for (let i = 0; i < updatedProduct.productParkingsSubjects.length; i++) {
                        if (!updatedProduct.productParkingsSubjects[i].subjectId && updatedProduct.productParkingsSubjects[i].subjectId != 0) {
                            error.subjectId[i] = 'required';
                            ret = false;
                        }
                    }
                } else {
                    ret = false;
                }
            }
            for (let j = 0; j < updatedProduct.productParam.length; j++) {
                if (!error[updatedProduct.productParam[j].paramName]) {
                    error[updatedProduct.productParam[j].paramName] = {};
                }
                const name = updatedProduct.productParam[j].paramLabel;
                if (!name || name.trim().length === 0) {
                    error[updatedProduct.productParam[j].paramName].paramLabel = 'required';
                    ret = false;
                }

                if (updatedProduct.productParam[j].paramType === 'PRODUCT_TYPE_MACRO_SELECT') {
                    if (updatedProduct.productParam[j].macroSelect) {
                        for (let k = 0; k < updatedProduct.productParam[j].macroSelect.length; k++) {
                            if (!error[updatedProduct.productParam[j].paramName + k]) {
                                error[updatedProduct.productParam[j].paramName + k] = {};
                            }
                            const name = updatedProduct.productParam[j].macroSelect[k].label;
                            if (!name || name.trim().length === 0) {
                                error[updatedProduct.productParam[j].paramName + k].paramLabel = 'required';
                                ret = false;
                            }
                        }
                    }
                }
                const templateParam = productTemplateParams.find(a => a.paramName === updatedProduct.productParam[j].paramName);
                const required = templateParam.required && templateParam.required === true && (!updatedProduct.productParam[j].visible || !updatedProduct.productParam[j].editable);
                if (templateParam && required) {
                    if (templateParam.paramType !== 'PRODUCT_TYPE_MACRO_SELECT') {
                        let value;
                        switch (templateParam.paramType) {
                            case 'PRODUCT_TYPE_NUMBER':
                                value = updatedProduct.productParam[j].valueNumber;
                                break;
                            case 'PRODUCT_TYPE_TEXT':
                                value = updatedProduct.productParam[j].valueVarchar;
                                break;
                            case 'PRODUCT_TYPE_DATE':
                                value = updatedProduct.productParam[j].valueVarchar;
                                if (value) {
                                    const stepCount = value.split(" ");
                                    if (stepCount.length === 1) {
                                        value = undefined;
                                    } else if (stepCount.length === 2) {
                                        if (!((stepCount[0] || stepCount[0] == 0) && stepCount[1])) {
                                            value = undefined;
                                        }
                                    }
                                }
                                break;
                            case 'PRODUCT_TYPE_SELECT':
                                value = updatedProduct.productParam[j].valueSelect;
                                break;
                            case 'PRODUCT_TYPE_BOOLEAN':
                                value = updatedProduct.productParam[j].valueBoolean || 'false';
                                break;
                            default:
                                value = 'TODO';
                                break;
                        }
                        if (Array.isArray(value)) {
                            if (value.length > 0) {
                                value = value[0];
                            } else {
                                value = '';
                            }
                        }
                        if (!value || (value.trim && value.trim().length === 0)) {
                            error[updatedProduct.productParam[j].paramName].defaultValue = 'required';
                            ret = false;
                        }


                    } else {
                        const macro = updatedProduct.productParam[j];
                        if (!macro || !macro.macroSelect || macro.macroSelect.length === 0) {
                            error[updatedProduct.productParam[j].paramName].paramLabel = 'required';
                            ret = false;
                        } else {
                            for (let h = 0; h < updatedProduct.productParam[j].macroSelect.length; h++) {
                                const selectSection = updatedProduct.productParam[j].macroSelect[h];
                                for (let g = 0; g < updatedProduct.productParkingsSubjects.length; g++) {
                                    const found = selectSection && selectSection.values ? selectSection.values.find(a => a.parkingId === updatedProduct.productParkingsSubjects[g].parkingId) : null;
                                    if (!found) {
                                        if (!error[updatedProduct.productParam[j].paramName + h + "_" + updatedProduct.productParkingsSubjects[g].parkingId]) {
                                            error[updatedProduct.productParam[j].paramName + h + "_" + updatedProduct.productParkingsSubjects[g].parkingId] = {};
                                        }
                                        error[updatedProduct.productParam[j].paramName + h + "_" + updatedProduct.productParkingsSubjects[g].parkingId].defaultValue = 'required';
                                        ret = false;
                                    }
                                }
                            }
                        }

                    }

                }
            }
            setErrorStep2(error)
        }
        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const getSubject = (productSubject, subjects) => {
        if (productSubject) {
            const p = subjects && subjects.find(b => b.value == productSubject);
            if (!p) {
                return {label: 'Not Found', value: productSubject};
            }
            return p;
        }
        return null;
    }

    const handleMacroParamPropertyChange = (name, property, value, index) => {
        let changedProperty = updatedProduct.productParam.find(a => a.paramName === name);
        const updatedProjectParamCopy = updatedProduct.productParam.filter(a => a.paramName !== name);
        changedProperty = {...changedProperty};
        changedProperty.macroSelect[index][property] = value;
        updatedProjectParamCopy.push(changedProperty);
        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParam'] = updatedProjectParamCopy;
        setUpdatedProduct(newUpdatedProduct);
    }


    const handleNameChange = (name, index) => (event) => {
        if (index || index === 0) {
            handleMacroParamPropertyChange(name, 'label', event.target.value, index);
        } else {
            handleParamPropertyChange(name, 'paramLabel', event.target.value);
        }
    }

    const handleStepperChange = (name) => (event) => {
        handleParamPropertyChange(name, 'stepper', event);
    }

    const handleDefaultChange = (name, index, prevIndex) => (event) => {
        if (prevIndex !== -1) {
            handleMacroParamPropertyChange(name, 'default', false, prevIndex);
        }
        handleMacroParamPropertyChange(name, 'default', event, index);
    }

    const handleEditableChange = (name) => (event) => {
        handleParamPropertyChange(name, 'editable', event);
    }

    const handleVisibleChange = (name) => (event) => {
        handleParamPropertyChange(name, 'visible', event);
    }

    const handleParamPropertyChange = (name, property, value) => {
        let changedProperty = updatedProduct.productParam.find(a => a.paramName === name);
        const updatedProjectParamCopy = updatedProduct.productParam.filter(a => a.paramName !== name);
        changedProperty = {...changedProperty};
        changedProperty[property] = value;
        if (property === 'editable' && value === true) {
            changedProperty['visible'] = value;
        }
        updatedProjectParamCopy.push(changedProperty);
        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParam'] = updatedProjectParamCopy;
        setUpdatedProduct(newUpdatedProduct);

    }


    const handleParamDefaultValueChange = (name) => (event, parkingId, index) => {
        let changedProperty = updatedProduct.productParam.find(a => a.paramName === name);
        const updatedProjectParamCopy = updatedProduct.productParam.filter(a => a.paramName !== name);
        changedProperty = {...changedProperty};
        switch (changedProperty.paramType) {
            case 'PRODUCT_TYPE_NUMBER':
                changedProperty['valueNumber'] = event.target.value;
                break;
            case 'PRODUCT_TYPE_TEXT':
                changedProperty['valueVarchar'] = event.target.value;
                break;
            case 'PRODUCT_TYPE_DATE':
                changedProperty['valueVarchar'] = event;
                break;
            case 'PRODUCT_TYPE_BOOLEAN':
                changedProperty['valueBoolean'] = event;
                break;
            case 'PRODUCT_TYPE_SELECT':
                if (event) {
                    if (event.target) {
                        changedProperty['valueSelect'] = [event.target.value];
                    } else {
                        if (Array.isArray(event)) {
                            changedProperty['valueSelect'] = event.map(a => a.value);
                        } else {
                            changedProperty['valueSelect'] = [event.value];
                        }
                    }
                } else {
                    delete changedProperty['valueSelect'];
                }
                break;
            case 'PRODUCT_TYPE_MACRO_SELECT':
                changedProperty['macroSelect'][index].values = changedProperty['macroSelect'][index].values ? changedProperty['macroSelect'][index].values.filter(a => a.parkingId !== parkingId) : [];
                changedProperty['macroSelect'][index].values.push({valueId: event.value, parkingId});
                break;
            default:
                changedProperty['valueVarchar'] = event.target.value;
                break;
        }
        updatedProjectParamCopy.push(changedProperty);
        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParam'] = updatedProjectParamCopy;
        setUpdatedProduct(newUpdatedProduct);
    }
    const handleChange = (name) => (event) => {
        if (name === 'description') {
            setUpdatedProduct({...updatedProduct, 'description': event.toString('html'), 'controlDate': new Date()});
            setDescription(event);
        } else if (event && event.target) {
            if (name === 'productTemplateId') {
                if (productTemplates && event) {
                    const template = productTemplates.find(a => a.productTemplateId == event.target.value);
                    if (template) {
                        setProductTemplateParams(template.productTemplateParams);
                        const updatedProjectParamCopy = [];
                        for (let i = 0; i < template.productTemplateParams.length; i++) {
                            updatedProjectParamCopy.push({
                                ...template.productTemplateParams[i],
                                visible: false,
                                editable: false,
                                paramLabel: template.productTemplateParams[i].paramName,
                            });
                        }
                        const newUpdatedProduct = {
                            ...updatedProduct,
                            [name]: event.target.value,
                            'controlDate': new Date()
                        };
                        newUpdatedProduct['productParam'] = updatedProjectParamCopy;
                        setUpdatedProduct(newUpdatedProduct);
                    }
                }
            } else {
                setUpdatedProduct({...updatedProduct, [name]: event.target.value, 'controlDate': new Date()});
            }
        } else {
            setUpdatedProduct({...updatedProduct, [name]: event, 'controlDate': new Date()});

        }
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <WizardStep1 handleChange={handleChange}
                                    userUseCases={userUseCases}
                                    updatedProduct={updatedProduct}
                                    productType={productTypeInner}
                                    getProductSystemParam={getProductSystemParam}
                                    productTemplatesValues={productTemplatesValues}
                                    subjectValues={subjectValues}
                                    branchValues={branchValues}
                                    rolesValues={rolesValues}
                                    parkingsValues={parkingsValues}
                                    productGroupValues={productGroupValues}
                                    description={description}
                                    setProductSystemParam={setProductSystemParam}
                                    error={errorStep1}
                                    isValid={isValid}
                                    handleAddProductGroup={handleAddProductGroup}
                                    handleRoles={handleRoles}
                                    subjects={subjects}
                                    setMode={setMode}
                                    mode={mode}
                                    handleSubject={handleSubject}
                                    handleBranch={handleBranch}
                                    readOnly={readOnly}
                                    handleMacroParkings={handleMacroParkings}
                                    getSubject={getSubject}
                                    setSnackbarError={setError}
                                    setSnackbarOpen={setSnackbarOpen}

                />

            case 1:
                return productTypeInner === 'MP' ?
                    <WizardStep2TableMacro productTemplateParams={productTemplateParams}
                                           product={updatedProduct}
                                           error={errorStep2}
                                           productType={productTypeInner}
                                           handleParamDefaultValueChange={handleParamDefaultValueChange}
                                           handleNameChange={handleNameChange}
                                           handleVisibleChange={handleVisibleChange}
                                           handleEditableChange={handleEditableChange}
                                           readOnly={readOnly}
                                           parkingsValues={parkingsValues}
                                           removeChildToMacroSelect={removeChildToMacroSelect}
                                           addChildToMacroSelect={addChildToMacroSelect}
                                           handleDefaultChange={handleDefaultChange}
                                           getSubject={getSubject}
                                           subjectValues={subjectValues}
                                           handleReferenceChange={handleReferenceChange}
                                           handleMacroParkingSubject={handleMacroParkingSubject}
                                           handleStepperChange={handleStepperChange}
                    /> :
                    <WizardStep2Table productTemplateParams={productTemplateParams}
                                      product={updatedProduct}
                                      error={errorStep2}
                                      productType={productTypeInner}
                                      handleParamDefaultValueChange={handleParamDefaultValueChange}
                                      handleNameChange={handleNameChange}
                                      handleVisibleChange={handleVisibleChange}
                                      handleEditableChange={handleEditableChange}
                                      readOnly={readOnly}
                                      handleStepperChange={handleStepperChange}

                    />
            default:
                return 'Unknown step';
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        if (activeStep === 1) {
            if (isValid(activeStep)) {
                save(updatedProduct);
            }
        } else {
            if (isValid(activeStep)) {
                setActiveStep((activeStep) => activeStep + 1);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1);
    };

    const getProductTemplateName = (product) => {
        if (productTemplates && product && product.productTemplateId) {
            const template1 = productTemplates.find(a => a.productTemplateId === product.productTemplateId);
            return template1.productTemplateName;
        }
    }

    const getParking = (product) => {
        if (parkings && product && (product.parkingId || product.parkingId === 0)) {
            const template1 = parkings.find(a => a.parkingId === product.parkingId);
            return template1.parkingName;
        }
    }


    const getBranch = (product) => {
        if (branches && product && product.branchId) {
            const template1 = branches.find(a => a.id === product.branchId);
            return template1.branchName;
        }
    }

    return (
        <Dialog
            className={classes.container}
            open={opened}
            onClose={handleClose}
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
                <span>
                    {t(product && product.productId === 0 ? 'CREATE_PRODUCT' : 'UPDATE_PRODUCT')}
                </span>
            </DialogTitle>


            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={error}/>

            <div className={classes.stepNavigation}>
                {activeStep === 1 && <div className={classes.desc}>
                    {getProductTemplateName(updatedProduct)}
                </div>}
                <Stepper activeStep={activeStep} color="secondary"
                         classes={{root: activeStep === 1 ? classes.root : classes.root1}}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === 1 && <div className={classes.descBelow}>
                    <div className={classes.descItem}>
                        <div className={classes.descLabel}>
                            {t('BRANCH')}
                        </div>
                        <div className={classes.descValue}>
                            {getBranch(updatedProduct)}
                        </div>
                    </div>
                    <div className={classes.descItem}>
                        <div className={classes.descLabel}>
                            {product.parkingId || product.parkingId === 0 && t('PARKING')}
                        </div>
                        <div className={classes.descValue}>
                            {getParking(updatedProduct)}
                        </div>
                    </div>
                </div>}
            </div>
            <DialogContent
                className={(activeStep === 0 && mode !== 'COMMON') ? classes.contentGrey : classes.content}>
                <div className={classes.container}>
                    {getStepContent(activeStep)}
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>

                    <ActionButton
                        action="close"
                        handleClick={handleClose}
                        className={classes.button}
                    />
                </div>
                <div className={classes.buttonNavigation}>

                    <ActionButton
                        disabled={activeStep === 0} onClick={handleBack} className={classes.button}
                        action="back"
                        handleClick={handleBack}
                    />
                    <ActionButton
                        disabled={readOnly && (activeStep === steps.length - 1)}
                        action={activeStep === steps.length - 1 ? 'finish' : 'next'}
                        handleClick={handleNext}
                        className={classes.button}
                    />
                </div>

            </DialogActions>
        </Dialog>

    );
}

Product.propTypes = {};

const UC_TAB_ACCESS = 'UC0062';
const UC_PRODUCT_ADMINISTRATION = 'UC0083';
const UC_MENU_ADMINISTRATION = 'UC0083';


const mapStateToProps = (store) => ({
    branches: store.branchesData.branches,
    productGroups: store.productsData.productGroups,
    productTemplates: store.productsData.productTemplates,
    subjects: store.subjectsData.subjects,
    parkings: store.parkingsData.parkings,
    roles: store.rolesData.roles,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllProductGroups,
    fetchAllProductTemplates,
    fetchAllBranches,
    fetchAllSubjects,
    fetchAllRoles,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Product);


