import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment/min/moment-with-locales';

import { CustomTable, CustomCheckbox, CustomButton } from 'components/elements';
import { ActionButton, EventType } from 'components/common';
import CardsToolbar from './ToolBar';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';
import {UC_LT_CARD_DEACTIVATE, UC_LT_CARD_BLOCK, UC_ST_CARD_DEACTIVATE, UC_ST_CARD_BLOCK} from 'components/modals/CardDetails/CardParkingDetail/CardJournals';


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
  },
  expired: {
    color: `${theme.palette.primary.red}`,
  },
  valid: {
    color: `${theme.palette.primary.main}`,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mr: {
    marginRight: theme.spacing(2.5),
  },
  cardNo: {
    cursor: 'pointer',
  },
  lock: {
    marginTop: 6,
  },
  inactive: {
    color: '#ADB0B8',
  },
}));

const cellStyle = {
  padding: '4px 4px',
  lineHeight: '19px',
  color: '#1B1B28',
  textAlign: 'center',
};

function CardsTable(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    parking,
    cardType,
    cardData,
    isLoading,
    selectAll,
    selected,
    validTill,
    validity,
    blocked,
    zone,
    cardsPaging,
    parkingCardNumbers,
    handleCreateCardOpen,
    handleCardDetail,
    handleSelectAll,
    handleCheck,
    handleEditCardModal,
    handleActivate,
    handleBlock,
    handleOwner,
    handleValidTill,
    handleValidity,
    handleFilterBlocked,
    handleSearch,
    handleZone,
    handleChangeRowsPerPage,
    handleChangePage,
    userUseCases,
    handleOrderChange,
  } = props;

  const handleOpenCardDetail = (rowData) => () => {
    handleCardDetail(rowData);
  };

  const searchMenuItemClick = (value) => {
    handleCardDetail({
      cardNo: parseInt(value, 10),
      parkingId: parking.parkingId,
      type: cardType,
    });
  };

  const isLT = cardType === 'LT';

  let columnsST = [
    {
      title: t('CARD_NUMBER'),
      field: 'cardNo',
      cellStyle,
      render: (rowData) => (
          isComponentVisibleForUser(UC_ST_CARD_DETAIL, userUseCases) ? (
              <Typography
          variant="button"
          className={classes.cardNo}
          onClick={isComponentEnabledForUser(UC_ST_CARD_DETAIL, userUseCases) ?
              handleOpenCardDetail(rowData): (()=>{})}
        >
          {rowData.cardNo}
        </Typography>): (rowData.cardNo)
      ),
    },
    { title: t('OWNER'), field: 'owner', cellStyle },
    {
      title: t('VALIDITY'),
      field: 'valid',
      cellStyle,
      render: (rowData) => (
        <EventType type={rowData.valid ? 'valid' : 'invalid'} />
      ),
    },
    {
      title: t('BLOCKED'),
      field: 'blocked',
      cellStyle,
      render: (rowData) => (rowData.blocked ? (
        <LockIcon className={classnames(classes.lock, rowData.valid ? '' : classes.inactive)} color="error" />
      ) : (
        <LockOpenIcon className={classnames(classes.lock, rowData.valid ? '' : classes.inactive)} color="primary" />
      )),
    },
    {
      title: t('EXIT_TIME'),
      field: 'exitTimeTS',
      cellStyle,
      render: (rowData) => (
        <>
          <span className={(rowData.exitTimeTS >= new Date().getTime()) ? classes.valid : classes.expired}>
            {rowData.exitTimeTS
              ? moment(new Date(rowData.exitTimeTS)).locale(i18next.language).format('L LTS')
              : ''}
          </span>
        </>
      ),
    },
    {
      title: t('ACTION'),
      field: 'action',
      sorting: false,
      cellStyle: {
        ... cellStyle,
        padding:'12px 4px',
      },
      headerStyle: {
        padding: '18px 34px',
      },
      render: (rowData) => (
          <div className={classes.wrapper}>
            {(isLT || rowData.valid) && isComponentVisibleForUser(UC_ST_CARD_DEACTIVATE, userUseCases) && (
                <ActionButton
                    disabled={!isComponentEnabledForUser(UC_ST_CARD_DEACTIVATE, userUseCases)}
                    className={classes.mr}
                    action={rowData.valid ? 'invalidate' : 'validate'}
                    handleClick={handleActivate(rowData)}
                />
            )}
            {isComponentVisibleForUser(UC_ST_CARD_BLOCK, userUseCases) && (<ActionButton
                disabled={!rowData.valid || !isComponentEnabledForUser(UC_ST_CARD_BLOCK, userUseCases)}
                action={rowData.blocked ? 'unblock' : 'block'}
                handleClick={rowData.valid ? handleBlock(rowData) : null}
            />)
            }
          </div>
      ),
    },
   ];

   let columnsLT =[
    {
      title: t('CARD_NUMBER'),
      field: 'cardNo',
      cellStyle,
      render: (rowData) => (
          isComponentVisibleForUser(UC_LT_CARD_DETAIL, userUseCases) ?
        (<Typography
          variant="button"
          className={classes.cardNo}
          onClick={ isComponentEnabledForUser(UC_LT_CARD_DETAIL, userUseCases)  ?
              handleOpenCardDetail(rowData): (()=>{})}
        >
          {rowData.cardNo}
        </Typography>):  (rowData.cardNo)
      ),
    },
    { title: t('OWNER'), field: 'owner', cellStyle },
    {
      title: t('VALIDITY'),
      field: 'valid',
      cellStyle,
      render: (rowData) => (
        <EventType type={rowData.valid ? 'valid' : 'invalid'} />
      ),
    },
    {
      title: t('BLOCKED'),
      field: 'blocked',
      cellStyle,
      render: (rowData) => (rowData.blocked ? (
        <LockIcon className={classnames(classes.lock, rowData.valid ? '' : classes.inactive)} color="error" />
      ) : (
        <LockOpenIcon className={classnames(classes.lock, rowData.valid ? '' : classes.inactive)} color="primary" />
      )),
    },
    {
      title: t('VALID_TILL'),
      field: 'validDateTS',
      cellStyle,
      render: (rowData) => (
        <>
            {rowData.validDateTS
              ? moment(new Date(rowData.validDateTS)).locale(i18next.language).format('L LTS')
              : ''}
        </>
      ),
    },
      {
        title:  isComponentVisibleForUser(UC_LT_CARD_BULK_EDIT, userUseCases) ? <CustomCheckbox disabled={!isComponentEnabledForUser(UC_LT_CARD_BULK_EDIT, userUseCases)} checked={selectAll} handleChange={handleSelectAll} />: '',
        field: 'allSelect',
        sorting: false,
        cellStyle,
        headerStyle: {
          padding: '0 34px 0 34px',
        },
        render: (rowData) => (
          isComponentVisibleForUser(UC_LT_CARD_BULK_EDIT, userUseCases) ?
          <CustomCheckbox
            className={classes.checkbox}
            checked={selected.indexOf(rowData.cardNo) > -1}
            handleChange={handleCheck(rowData)}
            disabled={!isComponentEnabledForUser(UC_LT_CARD_BULK_EDIT, userUseCases)}
          />: null
        ),
      },
      {
        title: (isComponentVisibleForUser(UC_LT_CARD_BULK_EDIT, userUseCases) ? <CustomButton
          label={t('EDIT_SELECTED_CARDS')}
          width={220}
          height={32}
          color="grey"
          onClick={handleEditCardModal}
          disabled={!isComponentEnabledForUser(UC_LT_CARD_BULK_EDIT, userUseCases)}
        />: ''),
        field: 'action',
        sorting: false,
        cellStyle,
        headerStyle: {
          padding: '0 34px 0 34px',
        },
        render: (rowData) => (
          <div className={classes.wrapper}>
            {isComponentVisibleForUser(UC_LT_CARD_DEACTIVATE, userUseCases) && <ActionButton
              className={classes.mr}
              disabled={!isComponentEnabledForUser(UC_LT_CARD_DEACTIVATE, userUseCases)}
              action={rowData.valid ? 'invalidate' : 'validate'}
              handleClick={handleActivate(rowData)}
            />}
            {isComponentVisibleForUser(UC_LT_CARD_BLOCK, userUseCases) &&
            <ActionButton
                action={rowData.blocked ? 'unblock' : 'block'}
                disabled={!rowData.valid || !isComponentEnabledForUser(UC_LT_CARD_BLOCK, userUseCases)}
                handleClick={rowData.valid ? handleBlock(rowData) : null}
            />}
            </div>

        ),
      },
  ];

  let columns = (isLT) ? columnsLT : columnsST;

  return (
    <div className={classes.container}>
      {((isComponentVisibleForUser(UC_LT_CARD_SEARCH, userUseCases) && cardType === 'LT')
          || (isComponentVisibleForUser(UC_ST_CARD_SEARCH, userUseCases) &&  cardType === 'ST' )) &&
      <CardsToolbar
          parking={parking}
          cardType={cardType}
          validTill={validTill}
          validity={validity}
          blocked={blocked}
          zone={zone}
          searchMenuItemClick={searchMenuItemClick}
          parkingCardNumbers={parkingCardNumbers}
          handleOwner={handleOwner}
          handleValidTill={handleValidTill}
          handleValidity={handleValidity}
          handleBlock={handleFilterBlocked}
          handleSearch={handleSearch}
          handleZone={handleZone}
          handleCreateCardOpen={handleCreateCardOpen}
          userUseCases={userUseCases}
      />
      }

      <CustomTable
        title=""
        columns={columns}
        data={cardData}
        options={{
          headerStyle: {
            padding: '0 8px 0 34px',
            borderTop: '1px solid #DEE2E5',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
          },
        }}
        isLoading={isLoading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        rowsPerPage={cardsPaging.pageSize}
        pageNumber={cardsPaging.currentPage}
        count={cardsPaging.totalSize}
        loadAllData
        handleOrderChange={handleOrderChange}
        selected={selected}
      />
    </div>
  );
}

CardsTable.propTypes = {
  parking: PropTypes.object.isRequired,
  cardType: PropTypes.string.isRequired,
  cardData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectAll: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  validTill: PropTypes.object,
  validity: PropTypes.array.isRequired,
  blocked: PropTypes.array.isRequired,
  zone: PropTypes.array.isRequired,
  cardsPaging: PropTypes.object.isRequired,
  parkingCardNumbers: PropTypes.array.isRequired,
  handleCreateCardOpen: PropTypes.func.isRequired,
  handleCardDetail: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  handleEditCardModal: PropTypes.func.isRequired,
  handleActivate: PropTypes.func.isRequired,
  handleBlock: PropTypes.func.isRequired,
  handleValidTill: PropTypes.func.isRequired,
  handleValidity: PropTypes.func.isRequired,
  handleFilterBlocked: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleZone: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

CardsTable.defaultProps = {
  validTill: null,
};

const UC_LT_CARD_SEARCH = 'UC0021';
const UC_LT_CARD_BULK_EDIT = 'UC0022';

const UC_LT_CARD_DETAIL = 'UC0025';
const UC_LT_CARD_OWNER_CHANGE = 'UC0028';

const UC_ST_CARD_SEARCH = 'UC0030';
const UC_ST_CARD_DETAIL = 'UC0033';



export default CardsTable;
