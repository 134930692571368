import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingSearchList: false,
  isFetchingSubjectList: false,
  isFetchingOwnerDetail: false,
  isUpdating: false,
  isActivating: false,

  subjectSearchOwners: [],
  subjectOwners: [],
  ownerDetail: {},

  ownersPaging: {
    pageSize: 5,
    currentPage: 0,
    totalSize: 0,
  },

  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SUBJECT_OWNERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingSubjectList: true,
      };
    case types.SUBJECT_OWNERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingSubjectList: false,
        subjectOwners: action.payload.subjectOwners,
        ownersPaging: action.payload.paging,
      };
    case types.SUBJECT_OWNERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingSubjectList: false,
        errors: action.payload.error,
      };

    case types.SUBJECT_SEARCH_OWNERS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingSearchList: true,
      };
    case types.SUBJECT_SEARCH_OWNERS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingSearchList: false,
        subjectSearchOwners: action.payload.subjectSearchOwners,
      };
    case types.SUBJECT_SEARCH_OWNERS_FETCH_FAIL:
      return {
        ...state,
        isFetchingSearchList: false,
        errors: action.payload.error,
      };

    case types.OWNER_DETAIL_REQUEST:
      return {
        ...state,
        isFetchingOwnerDetail: true,
      };
    case types.OWNER_DETAIL_SUCCESS:
      return {
        ...state,
        isFetchingOwnerDetail: false,
        ownerDetail: action.payload.ownerDetail,
      };
    case types.OWNER_DETAIL_FAIL:
      return {
        ...state,
        isFetchingOwnerDetail: false,
        errors: action.payload.error,
      };

    case types.NEW_OWNER:
      return {
        ...state,
        ownerDetail: action.payload.ownerDetail,
      };

    case types.OWNER_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.OWNER_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        // subjectOwners: [...state.subjectOwners, action.payload.data],
      };
    case types.OWNER_UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
        errors: action.payload.error,
      };

    case types.OWNER_ACTIVATE_REQUEST:
      return {
        ...state,
        isActivating: true,
      };
    case types.OWNER_ACTIVATE_SUCCESS:
      return {
        ...state,
        isActivating: false,
        subjectOwners: action.payload.subjectOwners,
      };
    case types.OWNER_ACTIVATE_FAIL:
      return {
        ...state,
        isActivating: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
