import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  xaxisLable: {
    fontSize: 13,
    fontWeight: 'bold',
    fontFamily: 'Segoe UI',
  },
}));

const options = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: 1,
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 1,
  },
  toolbar: {
    tools: {
      selection: false,
    },
  },
  markers: {
    size: 3,
    colors: '#EEEEEE',
    strokeColors: '#5985EE',
    strokeWidth: 1,
    hover: {
      size: 6,
    },
  },
  tooltip: {
    enabled: false,
    followCursor: false,
    theme: 'dark',
    x: {
      show: false,
    },
    marker: {
      show: false,
    },
    y: {
      title: {
        formatter() {
          return '';
        },
      },
    },
  },
  colors: ['#5985EE', '#DEE2E5'],
  xaxis: {
    type: 'categories',
    categories: [],
    labels: {
      style: {
        colors: ['#5985EE'],
      },
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    showAlways: true,
    min: 0,
    max: 100,
    tickAmount: 2,
    labels: {
      minWidth: 0,
      formatter: (value) => `${value}%`,
    },
    axisBorder: {
      show: true,
      color: '#7F96A1',
      offsetX: 2,
      offsetY: 0,
    },
  },
  grid: {
    borderColor: 'transparent',
  },
  legend: {
    show: false,
  },
};

const CustomLineChart = (props) => {
  const { name, categories, data } = props;

  const classes = useStyles();

  const colors = categories.map(() => '#880015');
  const xaxislabels = {
    style: {
      cssClass: classes.xaxisLable,
      colors,
    },
  };

  const series = [
    {
      name,
      type: 'line',
      data,
    },
    {
      name,
      type: 'bar',
      data,
    },
  ];

  return (
    <Chart
      type="line"
      width="100%"
      height="150"
      options={{
        ...options,
        xaxis: {
          ...options.xaxis,
          categories,
          labels: xaxislabels,
        },
      }}
      series={series}
    />
  );
};

CustomLineChart.propTypes = {
  name: PropTypes.string,
  categories: PropTypes.array,
  data: PropTypes.array,
};

CustomLineChart.defaultProps = {
  name: 'No Name',
  categories: [],
  data: [],
};

export default CustomLineChart;
