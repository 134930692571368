import currenciesService from 'services/currencies.service';
import types from '../actionTypes';

export const fetchAllCurrencies = () => async (dispatch, getState) => {
    dispatch({
        type: types.CURRENCIES_LIST_REQUEST,
    });

    try {
        const currencies = await currenciesService.getCurrencies();
        dispatch({
            type: types.CURRENCIES_LIST_SUCCESS,
            payload: currencies,
        });
    } catch (error) {
        dispatch({
            type: types.CURRENCIES_LIST_FAIL,
            payload: {error},
        });
    }
};

export const fetchAllRates = () => async (dispatch, getState) => {
    dispatch({
        type: types.RATES_LIST_REQUEST,
    });

    try {
        const currencies = await currenciesService.getRates();
        dispatch({
            type: types.RATES_LIST_SUCCESS,
            payload: currencies,
        });
    } catch (error) {
        dispatch({
            type: types.RATES_LIST_FAIL,
            payload: {error},
        });
    }
};


