import React, {useEffect, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {Link} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment/min/moment-with-locales';
import ClassIcon from '@material-ui/icons/Class';

import {Loading} from 'components/elements';
import {connect} from 'react-redux';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import i18next from "i18next";
import Copy from '@material-ui/icons/FileCopy';
import Close from '@material-ui/icons/Close';
import Create from "@material-ui/icons/Create";
import Tooltip from '@material-ui/core/Tooltip';
import Group from '@material-ui/icons/Adjust';
import Branch from '@material-ui/icons/Store';
import {deleteProduct} from 'redux/actions/products';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {ActionButton} from "../../../components/common";
import {getErrorMessage, getProductName, isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import InfoIcon from "@material-ui/icons/Info";
import {blue} from "@material-ui/core/colors";


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 14,
    },
    productCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    root: {
        width: 380,
        height: 40,
        background: theme.palette.base[100],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row1: {
        width: 380,
        height: 10,
        fontSize: 12,
        padding: 5,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row2: {
        width: 380,
        height: 40,
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    row3: {
        width: 380,
        height: 40,
        fontSize: 16,
        paddingLeft: 3,
        paddingRight: 3,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    row4: {
        width: 380,
        height: 20,
        fontSize: 12,
        textAlign: 'center',
        textOverflow: 'ellipsis',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    empty: {
        width: '100%',
        height: 416,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
    },
    containerModal: {
        '& .MuiDialog-paper': {
            width: 834,
            maxWidth: 'unset',
            height: 'fit-content',
            padding: theme.spacing(4.25, 3.25),
            boxShadow: theme.palette.shadow.main,
        },
    },
    title: {
        marginBottom: theme.spacing(4.25),
        color: theme.palette.base[500],

        '& .MuiTypography-root': {
            fontSize: 24,
        },
    },
    cardTooltip: {
        flex: 0,
        padding: theme.spacing(2),
        background: theme.palette.base[100],
        textAlign: 'center'
    }
}));



function ProductCategories(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        products,
        productCategory,
        userUseCases,
        isLoading,
        handleProductDetail,
        deleteProduct,
        refresh,
        setDeletionSnackbarOpen,
        setDeletionSnackbarError,
    } = props;


    const [productsFilteredByCategory, setProductsFilteredByCategory] = useState([]);
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);
    const [productToDelete, setProductToDelete] = useState();


    useEffect(() => {
        if (products) {
            setProductsFilteredByCategory(products.filter((product) => (productCategory === t('ALL') || (product.productGroupName === productCategory))));
            //setProductsFilteredByCategory(products);
        }
    }, [products, productCategory]);


    const EmptyPage = () => (isLoading ? (
        <div className={classes.empty}>
            <Loading/>
        </div>
    ) : (
        <div className={classes.empty}>
        </div>
    ));


    const deleteProductInner = (product) => {
        deleteProduct(product).then(
            () => {
                setProductToDelete(null);
                setConfirmModalOpened(false);
                refresh()
            }
        ).catch((error) => {
            setDeletionSnackbarOpen(true);
            setDeletionSnackbarError(getErrorMessage(error, t));
        });
    }

    const today = new Date();

    return (
        <div className={classes.container}>


            {confirmModalOpened
            && (
                <Dialog
                    className={classes.containerModal}
                    open={confirmModalOpened}
                    onClose={() => {
                        setConfirmModalOpened(false);
                        setProductToDelete(null);
                    }}
                >
                    <DialogTitle className={classes.title}>
                        {t('REMOVE_PRODUCT_TITLE')}
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('CONFIRM_REMOVE_PRODUCT', {
                                productName: getProductName(productToDelete),

                            })}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className={classes.actions}>
                        <ActionButton
                            action="confirm_remove_user"
                            handleClick={
                                () => {
                                    deleteProductInner(productToDelete);
                                }}
                        />
                        <ActionButton
                            action="cancel"
                            handleClick={() => {
                                setConfirmModalOpened(false);
                                setProductToDelete(null);
                            }}
                        />
                    </DialogActions>
                </Dialog>
            )}


            {productsFilteredByCategory.length > 0 ?
                productsFilteredByCategory.map((product, index) => (
                    <div
                        className={classes.productCard}>
                        <div className={classes.row2}>
                            <div>{getProductName(product)}
                            </div>
                        </div>


                        <div className={classes.row3}>
                            {product.description && product.description.length > 0 &&
                            <div className={classes.cardTooltip}>
                                <Tooltip disableFocusListener
                                         title={<div dangerouslySetInnerHTML={{__html: product.description}}/>}>
                                    <InfoIcon style={{color: blue[500]}}/>
                                </Tooltip>
                            </div>}
                        </div>

                        {/*                      <div className={classes.row3}>
                            <div dangerouslySetInnerHTML={{__html: product.description}}/>
                        </div>*/}
                        <div className={classes.row1}>
                            <div><Tooltip title="Produktová skupina">
                                <div><ClassIcon className={classes.navIcon}/> {product.productTemplateName}</div>
                            </Tooltip></div>

                            {(product.dateFrom > today) &&
                            <div><Tooltip title="Platnost od">
                                <div>{`${t('SINCE')} ${moment(product.dateFrom).locale(i18next.language).format('L')}`}</div>
                            </Tooltip></div>
                            }
                        </div>

                        <div className={classes.row1}>
                            <div><Tooltip title="Produktová skupina">
                                <div><Group className={classes.navIcon}/> {product.productGroupName}</div>
                            </Tooltip></div>
                            <div><Tooltip title="Pobočka">
                                <div><Branch className={classes.navIcon}/> {product.branchAddress}</div>
                            </Tooltip></div>
                        </div>
                        <BottomNavigation
                            className={classes.root}
                        >

                            {isComponentVisibleForUser(product.productTemplateType === 'P' ? UC_PRODUCT_UPDATE :
                                product.productTemplateType === 'D' ? UC_DIS_UPDATE : UC_MAC_PRODUCT_UPDATE
                                , userUseCases) &&


                            <BottomNavigationAction onClick={() => {
                                if ( 
                                    ( product.productTemplateType === 'P' && isComponentEnabledForUser(UC_PRODUCT_UPDATE, userUseCases) )
                                    ||
                                    ( product.productTemplateType === 'D' && isComponentEnabledForUser(UC_DIS_UPDATE, userUseCases) )
                                    || 
                                    ( product.productTemplateType === 'MP' && isComponentEnabledForUser(UC_MAC_PRODUCT_UPDATE, userUseCases) )
                                )
                                    handleProductDetail(product.productId)
                            }}
                                                    className={classes.green}
                                                    label="Update"
                                                    icon={<Create className={classes.actionIcon}/>}/>
                            }

                            {isComponentVisibleForUser(product.productTemplateType === 'P' ? UC_PRODUCT_DELETE :
                                UC_MAC_PRODUCT_DELETE, userUseCases) &&

                            <BottomNavigationAction className={classes.red} label="Close"
                                                    onClick={() => {
                                                        if ( 
                                                            ( product.productTemplateType === 'P' && isComponentEnabledForUser(UC_PRODUCT_DELETE, userUseCases) )
                                                            ||
                                                            ( product.productTemplateType === 'D' && isComponentEnabledForUser(UC_DIS_DELETE, userUseCases) )
                                                            || 
                                                            ( product.productTemplateType === 'MP' && isComponentEnabledForUser(UC_PRODUCT_DELETE, userUseCases) )
                                                        ) {
                                                            setProductToDelete(product);
                                                            setConfirmModalOpened(true);
                                                        }
                                                    }}
                                                    icon={<Close className={classes.actionIcon}/>}/>
                            }
                        </BottomNavigation>
                    </div>
                ))
                :
                <EmptyPage/>
            }
        </div>
    );
}

ProductCategories.propTypes = {
    products: PropTypes.array.isRequired,

};


const UC_PRODUCT_UPDATE = 'UC0612';
const UC_MAC_PRODUCT_UPDATE = 'UC0614';
const UC_DIS_UPDATE = 'UC0616';


const UC_PRODUCT_DELETE = 'UC0620';
const UC_DIS_DELETE = 'UC0621';
const UC_MAC_PRODUCT_DELETE = 'UC0622';


ProductCategories.defaultProps = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({deleteProduct}, dispatch);


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCategories);
