import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { CustomButton } from 'components/elements';
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiDialog-paper': {
      width: 600,
      maxWidth: 'unset',
      height: 'fit-content',
      padding: theme.spacing(4.25, 3.25),
      boxShadow: theme.palette.shadow.main,
    },
  },
  title: {
    marginBottom: theme.spacing(4.25),
    color: theme.palette.base[500],

    '& .MuiTypography-root': {
      fontSize: 24,
    },
  },
  label: {
    width: '100%',
    color: '#A2A2A2',
  },
  actions: {
    marginTop: theme.spacing(5),
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
  cancel: {
    height: 40,
    marginLeft: theme.spacing(3.75),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: theme.palette.primary.red,
    textTransform: 'uppercase',
  },
}));

function ConfirmMultipleCards(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    event,
    opened,
    blockingReason,
    type,
    bulkEditCards,
    handleClose,
  } = props;

  const handleSubmit = () => {
    if (type === 'block') {
      const cards = event.cards.map((card) => ({
        cardNumber: card.cardNumber,
        validFromTS: null,
        validToTS: null,
        note: null,
        zoneId: null,
        zoneReservationId: null,
        block: true,
        valid: null,
        blockingReason,
        cardGroup: null,
      }));

      bulkEditCards(
        event.parkingId,
        cards,
        {
          block: true,
          blockingReason,
        },
        event.cards[0].type,
      ).then(handleClose);
    } else if (type === 'deactivate') {
      const cards = event.cards.map((card) => ({
        cardNumber: card.cardNumber,
        validFromTS: null,
        validToTS: null,
        note: null,
        zoneId: null,
        zoneReservationId: null,
        block: null,
        valid: false,
        blockingReason: null,
        cardGroup: null,
      }));

      bulkEditCards(
        event.parkingId,
        cards,
        {
          valid: false,
        },
        event.cards[0].type,
      ).then(handleClose);
    }
  };

  let title = '';
  let title2 = '';
  if (type === 'block') {
    title = 'BLOCK_CARD';
    title2 = 'BLOCK2';
  } else if (type === 'deactivate') {
    title = 'DEACTIVATE_CARD';
    title2 = 'DEACTIVATE2';
  }

  return (
    <Dialog
      className={classes.container}
      open={opened}
      onClose={handleClose}
    >
      <DialogTitle className={classes.title}>
        {t(title)}
      </DialogTitle>

      <DialogContent>
        <Typography className={classes.label}>
          {t('CONFIRM_SELECT_CARD', {type:t(title2)})}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <CustomButton
          label={t(title.substr(0, title.length - 5))}
          width={180}
          muiIcon={<Check/>}
          color="green"
          onClick={handleSubmit}
        />
        <Button
          className={classes.cancel}
          onClick={handleClose}
        >
          {t('CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmMultipleCards.propTypes = {
  event: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  blockingReason: PropTypes.string,
  type: PropTypes.string.isRequired,
  bulkEditCards: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

ConfirmMultipleCards.defaultProps = {
  blockingReason: null,
};

export default ConfirmMultipleCards;
