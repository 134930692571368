import React, {useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Loading} from 'components/elements';
import {bindActionCreators} from 'redux';
import CashdeskMainContent from './MainContent';
import PerfectScrollbar from "react-perfect-scrollbar";

const Cashdesk = ({user, userUseCases}) => {
    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    if (!user) {
        return (
            <Loading/>
        );
    }

    return (
        <CashdeskMainContent
            user={user}
            userUseCases={userUseCases}
        />
    );
};

Cashdesk.propTypes = {
    user: PropTypes.object,
};

Cashdesk.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Cashdesk);
