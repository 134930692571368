import httpService from './http.service';
import moment from "moment";


const getQuery = (filter, page, size) => {
    let query = '?';
    if (filter) {
        for (let paramName in filter) {
            if (filter[paramName]) {
                query += `${paramName}=${encodeURIComponent((paramName === 'dateFrom' || paramName === 'dateTo') ?
                    moment(filter[paramName]).format('DD.MM.YYYY') : filter[paramName])}&`;
            }
        }
    }
    query += `page=${page}&size=${size}`;
    return query;
};

const getAllSubjects = () => httpService
    .get('/subjects')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const getAllSubjectsParam = (filter, page, size) => httpService
    .get(`/subjects/param${getQuery(filter, page, size)}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const createSubject = (subject) => httpService
    .post(`/subject`, subject)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateSubject = (subject) => httpService
    .put(`/subject`, subject)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));


export default {
    getAllSubjects,
    getAllSubjectsParam,
    updateSubject,
    createSubject,
};
