import React, { useEffect, useState }  from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import moment from 'moment/min/moment-with-locales';
import i18next from 'i18next';
import { ActionButton } from 'components/common';
import { CustomSelect } from 'components/elements';
import {getParkingAccess} from '../../utils';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(1, 0),
        flex: 1,
        overflow: 'hidden',
    
        '& .MuiSelect-select': {
          borderBottom: '1px solid #ADB0B8',
          color: theme.palette.base[800],
          fontSize: 16,
          with: '100%'
        },
    
        '& input': {
          color: theme.palette.base[800],
          fontSize: 16,
          paddingLeft: theme.spacing(1.125),
        },
        '& svg': {
          width: 24,
          height: 24,
          fill: theme.palette.base[300],
          marginTop: 0,
        },
    
        '& > div': {
          display: 'flex',
          flex: 1,
          marginLeft: 0,
        },
    },
    container: {
        // marginTop: theme.spacing(3.75),
    },
    flexWrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding:'5px',
    },

    select: {
        width: '100%',
        '& .MuiSelect-select': {
            fontSize: 24,
            color: theme.palette.base[800],
            lineHeight: '30px',
        },
    },
    
    formGroup: {
        height: 39,
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
          marginBottom: 0,
        },
    },
    labelHead: {
        width: theme.spacing(14),
        marginRight: theme.spacing(2),
        flexShrink: 0,
        fontSize: 24,
        color: theme.palette.base[800],
        textAlign: 'right',
        paddingLeft: '20px'
      },
    label: {
        width: theme.spacing(14),
        marginRight: theme.spacing(2),
        flexShrink: 0,
        fontSize: 14,
        color: theme.palette.base[300],
        textAlign: 'right',
      },
    inactive: {
        '& .MuiSelect-select': {
            color: `${theme.palette.base[300]} !important`,
        },

        '& input': {
            color: `${theme.palette.base[300]} !important`,
        },

        '& textarea': {
            color: `${theme.palette.base[300]} !important`,
        },
    },
    rightLabel: {
        width: theme.spacing(14),
        marginRight: theme.spacing(2),
        flexShrink: 0,
        fontSize: 14,
        color: theme.palette.base[300],
        textAlign: 'right',
    },
    button: {
        width: '100%',
        height: '55px',
        fontSize: 25,
    },
    helperText: {
      fontSize: 14,
      fontStyle: 'normal',
      color: 'red',
      paddingLeft: theme.spacing(1.25),
    },
}));



const CardAccess = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const isLT = false;
    
    const {
        cardDetail,
        parkingAccesses: accesses ,
        hadleSaveAccess,
        showHelper,
    } = props;

    const defaultSetting = {
        cardGroups: [],
        cardNumber: '',
        carNumber: '',
        comment: '',
        blockingReason: '',
        access: '',
        validFrom: '',
        validTo: '',
        entryTime: '',
        exitTime: '',
        credit: '',
        zone: '',
        zoneReservationId: '',
    };

    const getDefaultAccess = () => {
        const parkingAccessId = cardZone.parkingAccessId || null;
        if (parkingAccessId !== null) {
            const selectedAccess = accesses.find((a) => a.parkingAcccesId === parkingAccessId);
            return selectedAccess ? selectedAccess.parkingAccessName : '';
        }
        if (cardZone.prevParkingAccessName) {
            const accessWithSameName = accesses.find((a) => a.value === cardZone.prevParkingAccessName);
            if (accessWithSameName) {
                return cardZone.prevParkingAccessName;
            }
        }
        return '';
    };

    const [setting, setSetting] = useState(defaultSetting);
    const [disabled, setDisabled] = useState(true);
    const [cardGroups, setCardGroups] = useState([]);
    const [cardZone, setCardZone] = useState(null);
    const [accessValue, setAccessValue] = useState(null);

    useEffect(()=>{
        if ( cardDetail && cardDetail.parkings && cardDetail.parkings[0].zones[0] ) {
            setCardZone(cardDetail.parkings[0].zones[0]);
            if (accesses && cardDetail.parkings[0].zones[0].parkingAccessId) {
                let selec = accesses.find(a=> a.parkingAcccesId === cardDetail.parkings[0].zones[0].parkingAccessId);
                if (selec) {
                    setAccessValue(selec.parkingAccessName);
                }
            }
        } else {
          setSetting(defaultSetting);
        }
    }, [cardDetail, accesses]);

    useEffect(()=>{
        if ( cardZone ) {
            const validToTS = cardZone.validToTS ? new Date(cardZone.validToTS).getTime() : new Date();
            const validFromTS = cardZone.validFromTS ? new Date(cardZone.validFromTS).getTime() : new Date();
            setSetting({
                cardNumber: cardZone.cardNumber || '',
                cardGroups: cardZone.cardGroups || [],
                carNumber: cardZone.lpn || '',
                comment: cardZone.note || '',
                blockingReason: cardZone.blockingReason || '',
                access: getDefaultAccess(),
                validFrom: cardZone.validFromTS ? new Date(cardZone.validFromTS) : '',
                validTo: cardZone.validFromTS ? new Date(cardZone.validToTS) : '',
                entryTime: cardZone.validFromTS ? new Date(cardZone.entryTimeTS) : '',
                exitTime: cardZone.validFromTS ? new Date(cardZone.exitTimeTS) : '',
                credit: cardZone.credit || Math.floor((validToTS - validFromTS) / 60000),
                zone: cardZone.zoneName,
                zoneReservationId: cardZone.zoneReservationName,
            });
        } else {
          setSetting(defaultSetting);
        }
    }, [cardZone, accesses]);


    const accessValues = getParkingAccess(accesses);

    const handleChangeAccess = (event) => {
        setAccessValue(event);
    }

    const hadleSaveAccessLoc = () => {
        const tmp = accessValues.find((a) => a.label === accessValue);
        if ( cardDetail && cardDetail.parkings && cardDetail.parkings[0] && cardDetail.parkings[0].zones[0] ) {
            hadleSaveAccess({
                tmp,
                parkingId: cardDetail.parkings[0].parkingId,
                cardId: cardDetail.parkings[0].zones[0].cardId
            });
        }
    }

    return (
        <div className={classes.container}>

        <Grid container style={{height: '100%', width: '100%', paddingBottom: '10px'}}>
          <Grid item xs={12} md={5} className={classes.flexWrapper} >
            <CustomSelect
                autoFocus
                items={accessValues}
                value={accessValue}
                placeholder={t('CHOOSE_NEW_ACCESS')}
                className={classes.select}
                handleChange={handleChangeAccess}
                helperText={
                  <span className={classes.helperText}>
                    { showHelper
                      ? t('CHOOSE_NEW_ACCESS_HELPER')
                      : ''}
                  </span>
                }
            />
          </Grid>
          <Grid item xs={12} md={1} className={classes.flexWrapper}>
            <ActionButton action='save' className={classes.button} handleClick={hadleSaveAccessLoc}/>
          </Grid>
        </Grid>

        <Typography variant="button" className={classes.labelHead}>{t('CARD_DETAIL')}</Typography>
        
        <Grid container spacing={1} style={{height: '100%', width: '100%'}} >
          <Grid item xs={12} md={6} className={classes.flexWrapper}>
            <Typography variant="button" className={classes.label}>{t('CARD_NUMBER')}</Typography>
            <TextField
            className={classes.inactive}
            InputProps={{readOnly: true}}
            hiddenLabel
            value={setting.cardNumber}
            fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6} className={classes.flexWrapper}>
            <Typography variant="button" className={classes.label}>{t('ACCESS')}</Typography>
            <TextField
                className={classes.inactive}
                InputProps={{readOnly: true}}
                hiddenLabel
                value={setting.access}
                fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.flexWrapper}>
            <Typography variant="button" className={classes.rightLabel}>{t('CARD_GROUP')}</Typography>
            <TextField
                className={classes.inactive}
                InputProps={{
                    readOnly: true,
                }}
                hiddenLabel
                fullWidth
                value={
                    setting.cardGroup
                    ? cardGroups.find((cg) => cg.value === setting.cardGroup).label
                    : ''
                }
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.flexWrapper}>
            <Typography variant="button" className={classes.label}>{t('PARKING')}</Typography>
            <TextField
              className={classes.inactive}
              InputProps={{
                  readOnly: true,
              }}
              hiddenLabel
              fullWidth
              value={cardDetail && cardDetail.parkings && cardDetail.parkings[0].zones[0].parkingName}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.flexWrapper}>
            <Typography variant="button" className={classes.rightLabel}>{t('CAR_PLATE_NUMBER')}</Typography>
            <TextField
              required
              fullWidth
              className={classes.inactive}
              hiddenLabel
              InputProps={{
                  readOnly: !isLT || disabled,
              }}
              value={setting.carNumber || ''}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.flexWrapper}>
            <Typography variant="button" className={classes.label}>{t('ENTRY_TIME')}</Typography>
            <TextField
              className={classes.inactive}
              InputProps={{
                  readOnly: true,
              }}
              fullWidth
              hiddenLabel
              value={moment(setting.entryTime).locale(i18next.language).format('L LTS')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.flexWrapper}>
            <Typography variant="button" className={classes.rightLabel}>{t('EXIT_TIME')}</Typography>
            <TextField
              className={classes.inactive}
              InputProps={{
                  readOnly: true,
              }}
              hiddenLabel
              fullWidth
              value={moment(setting.exitTime).locale(i18next.language).format('L LTS')}
            />
          </Grid>
         </Grid>
    </div>
  );
}

export default CardAccess;