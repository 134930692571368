import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { getCodeList, getUsers } from 'utils';
import { fetchParkingUsers } from 'redux/actions/users';
import { fetchParkingTasks } from 'redux/actions/tasks';
import TasksTable from './TasksTable';

let filterParams = {
  createdById: [''],
  hostType: [''],
  state: [''],
  page: 0,
  size: 5,
  keyword: '',
};

function MobileOperatorTasks(props) {
  const { t } = useTranslation();

  const {
    userUseCases,
    active,
    parking,
    allCodeList,
    parkingTasks,
    tasksPaging,
    isCanceling,
    isRenewing,
    parkingUsers,
    handleTaskDetailOpen,
    handleCreateOpen,
    fetchParkingTasks,
    cancelTask,
    renewTask,
    fetchParkingUsers,
  } = props;

  const isUnMounted = useRef(false);

  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [isFetchedTasks, setIsFetchedTasks] = useState(false);
  const [isFetchedUsers, setIsFetchedUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTasks = useCallback((
    filterUser,
    filterHosts,
    filterState,
    rowsPerPage,
    pageNumber,
    keyword,
  ) => {
    setIsLoading(true);
    fetchParkingTasks(
      parking.parkingId,
      {
        createdById: filterUser,
        hostType: filterHosts,
        state: filterState,
        size: rowsPerPage,
        page: pageNumber,
        keyword,
      },
    ).then(() => {
      if (!isUnMounted.current) {
        setIsLoading(false);

        if (
          JSON.stringify(filterParams.createdById) !== JSON.stringify(filterUser)
          || JSON.stringify(filterParams.hostType) !== JSON.stringify(filterHosts)
          || JSON.stringify(filterParams.state) !== JSON.stringify(filterState)
          || filterParams.size !== rowsPerPage
          || filterParams.page !== pageNumber
          || filterParams.keyword !== keyword
        ) {
          setIsFetchedTasks(false);
        } else {
          setIsFetchedTasks(true);
        }
      }
    }).catch(() => {
      if (!isUnMounted.current) {
        setIsLoading(false);
      }
    });
  }, [fetchParkingTasks, parking.parkingId]);

  const fetchUsers = useCallback(() => {
    fetchParkingUsers(parking.parkingId).then(() => {
      if (!isUnMounted.current) {
        setIsFetchedUsers(true);
      }
    });
  }, [fetchParkingUsers, parking.parkingId]);

  useEffect(() => {
    if (active) {
      setIsFetchedTasks(false);
      setIsFetchedUsers(false);
    } else {
      setIsFetchedTasks(true);
      setIsFetchedUsers(true);
    }
  }, [active]);

  useEffect(() => {
    if (!isFetchedUsers && active) {
      fetchUsers();
    }
  }, [active, isFetchedUsers, fetchUsers]);

  const getTaskHosts = (allCodeList) => {
    const hostItems = getCodeList(allCodeList, 'HOST_TYPE');
    return hostItems.length > 0 ? hostItems.map((item) => ({
      value: item.code,
      label: t(item.description),
      key: `host_type_${item.codeListItemId}`,
    })) : [];
  };
  const taskHosts = getTaskHosts(allCodeList);
  const users = getUsers(parkingUsers);

  const [state, setState] = useState(['']);
  const [hosts, setHosts] = useState(['']);
  const [createdByName, setCreatedByName] = useState(['']);
  const [createdById, setCreatedById] = useState(['']);
  const [keyword, setKeyword] = useState('');
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(0);
  const resetPagination = () => {
    filterParams.page = 0;
    setPage(0);
  };
  const handleState = (value) => {
    filterParams.state = value;
    resetPagination();
    setIsFetchedTasks(false);
    setState(value);
  };
  const handleSelectHosts = (value) => {
    filterParams.hostType = value;
    resetPagination();
    setIsFetchedTasks(false);
    setHosts(value);
  };
  const handleUser = (valueSB) => {
    let usersId = [''];

    for ( let j=0; j<valueSB.length; j++) {
      for ( let i=0; i<users.length; i++) {
        if ( users[i].value === valueSB[j]) {
          usersId[j] = users[i].key;
          break;
        }
      }
    }

    filterParams.createdById = valueSB;
    resetPagination();
    setIsFetchedTasks(false);
    setCreatedById(usersId);
    setCreatedByName(valueSB);
  };
  const handleSearch = (value) => {
    filterParams.keyword = value;
    resetPagination();
    setIsFetchedTasks(false);
    setKeyword(value);
  };
  const handleChangeRowsPerPage = (value) => {
    filterParams.size = value;
    setIsFetchedTasks(false);
    setSize(value);
  };
  const handleChangePage = (value) => {
    filterParams.page = value;
    setIsFetchedTasks(false);
    setPage(value);
  };
  const handleAddTask = () => {
    handleCreateOpen({
      parkingId: parking.parkingId,
      taskHosts,
    });
  };

  useEffect(() => {
    if (!isFetchedTasks && active) {
      fetchTasks(createdById, hosts, state, size, page, keyword);
    }
  }, [active, isFetchedTasks, createdById, hosts, state, size, page, keyword, fetchTasks]);

  const handleCancel = (rowData) => () => {
    if (isCanceling) {
      return;
    }

    cancelTask(rowData);
  };

  const handleRenew = (rowData) => () => {
    if (isRenewing) {
      return;
    }

    renewTask(rowData);
  };

  const clearFilters = useCallback(() => {
    filterParams = {
      createdById: [''],
      hostType: [''],
      state: [''],
      page: 0,
      size: 5,
      keyword: '',
    };
    setCreatedById(['']);
    setHosts(['']);
    setState(['']);
    setSize(5);
    setPage(0);
    setKeyword('');
  }, []);

  useEffect(() => {
    clearFilters();
  }, [parking.parkingId, clearFilters]);

  return (
    <TasksTable
      userUseCases={userUseCases}
      isLoading={isLoading}
      parkingTasks={parkingTasks}
      tasksPaging={tasksPaging}
      taskHosts={taskHosts}
      state={state}
      hosts={hosts}
      createdByName={createdByName}
      users={users}
      handleState={handleState}
      handleCancel={handleCancel}
      handleRenew={handleRenew}
      handleSelectHosts={handleSelectHosts}
      handleUser={handleUser}
      handleSearch={handleSearch}
      handleAddTask={handleAddTask}
      handleTaskDetailOpen={handleTaskDetailOpen}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
    />
  );
}

MobileOperatorTasks.propTypes = {
  active: PropTypes.bool.isRequired,
  parking: PropTypes.object.isRequired,
  allCodeList: PropTypes.array.isRequired,
  parkingTasks: PropTypes.array.isRequired,
  tasksPaging: PropTypes.object.isRequired,
  isCanceling: PropTypes.bool.isRequired,
  isRenewing: PropTypes.bool.isRequired,
  parkingUsers: PropTypes.array.isRequired,
  handleTaskDetailOpen: PropTypes.func.isRequired,
  handleCreateOpen: PropTypes.func.isRequired,
  fetchParkingTasks: PropTypes.func.isRequired,
  cancelTask: PropTypes.func.isRequired,
  renewTask: PropTypes.func.isRequired,
  fetchParkingUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  parkingUsers: store.usersData.parkingUsers,
  tasksPaging: store.tasksData.tasksPaging,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchParkingUsers,
  fetchParkingTasks,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileOperatorTasks);
