import httpService from './http.service';

const getCardJournals = (cardNumber) => httpService
  .get(`/cardjournal/card/${cardNumber}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err.response));

export default {
  getCardJournals,
};
