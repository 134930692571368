import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';

import {CustomTable} from 'components/elements';
import ReceiptDetail from 'components/common/ReceiptDetail';


import {cancelReceiptById, duplicateReceipt, filterReceipts} from 'redux/actions/receipts';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionButton} from "../../common";
import i18next from "i18next";
import moment from "moment";
import {getLocale} from "../../../i18n";
import {searchUsers} from 'redux/actions/users';
import {fetchAllBranches} from 'redux/actions/branches';
import {fetchAllPos} from 'redux/actions/pos';
import DateRangePicker from "../../elements/KeyboardDateTimePicker";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Calendar from '@material-ui/icons/CalendarToday';
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import format from "date-fns/format";


const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        background: theme.palette.base[200],
        height: '100%',
        width: '100%',
    },
    actions: {
        padding: 0,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        padding: theme.spacing(1, 1, 1, 1),
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '50px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '100%',
        justifyContent: 'left',
    },
    button: {
        width: 200,
        marginLeft: 20,
        marginRight: 20,
    },
    buttonReceiptActions: {
        width: 30,
    },
    table: {
        width: '100%',
        height: '100%',
    },
}));


const defaultRange = {
    'dateFrom': moment(new Date())
        .subtract(87, "days")
        .toDate(), 'dateTo': new Date()
};

const ReceiptsFilter = ({
                            values,
                            setValues,
                            currencies,
                            paymentTypes,
                            states,
                            branches,
                            pos,
                            searchedUsers,
                            onFilterChange,
                            userUseCases,
                            ...props
                        }) => {
    const {t} = useTranslation();

    const timeoutRef = useRef(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAccept = (startDate, endDate) => {
        setIsModalOpen(false);
        const newValues = {...values, 'dateFrom': startDate, 'dateTo': endDate};
        setValues(newValues);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            onFilterChange && onFilterChange(newValues);
        }, 1000);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const handleChange = (e) => {
        const newValues = {...values, [e.target.name]: e.target.value};
        setValues(newValues);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            onFilterChange && onFilterChange(newValues);
        }, 1000);
    };

    useEffect(() => () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    return (
        <Paper>
            <Box p={1}>
                <Grid container spacing={1}>
                    <Grid item md>
                        <TextField
                            type="search"
                            value={values.cardNumber}
                            name="cardNumber"
                            onChange={handleChange}
                            label={t("CARD_NUMBER")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            type="search"
                            value={values.receiptId}
                            name="receiptId"
                            onChange={handleChange}
                            label={t("RECEIPT_NUMBER")}/>
                    </Grid>
                    <Grid item md>
                        <TextField
                            type="search"
                            value={values.licensePlate}
                            name="licensePlate"
                            onChange={handleChange}
                            label={t("LICENSE_PLATE")}/>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel>{t("CREATED_BY")}</InputLabel>
                            <Select
                                value={values.userId || ""}
                                name="userId"
                                onChange={handleChange}
                            >
                                <MenuItem value="">-</MenuItem>
                                {Array.isArray(searchedUsers) ? searchedUsers.map((item, key) => (
                                    <MenuItem key={item.userId} value={item.userId}>{item.email}</MenuItem>)) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box>
                                {values.dateFrom &&
                                <Typography
                                    variant="caption"
                                    style={{display: "block", textAlign: "center"}}
                                >
                                    {format(values.dateFrom, "do MMMM yyyy", {locale: getLocale(i18next.language)})}
                                </Typography>
                                }
                                <Button
                                    size="small"
                                    variant="outlined"
                                    endIcon={<Calendar/>}
                                    onClick={() => setIsModalOpen(true)} style={{
                                    width: "100%",
                                    height: 'auto',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    fontSize: '14px',
                                    marginTop: '5px',
                                    marginBottom: '5px',

                                }}>
                                    {t("CREATION_DATE")}
                                </Button>
                                {values.dateTo &&
                                <Typography
                                    variant="caption"
                                    style={{display: "block", textAlign: "center"}}
                                >
                                    {format(values.dateTo, "do MMMM yyyy", {locale: getLocale(i18next.language)})}
                                </Typography>
                                }
                            </Box>
                        </Box>
                        <DateRangePicker
                            title={t("CHOOSE_DATES")}
                            initialDateRange={{startDate: values.dateFrom, endDate: values.dateTo}}
                            open={isModalOpen}
                            onAccept={handleAccept}
                            onCancel={handleCancel}
                        />
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel>{t("CURRENCY")}</InputLabel>
                            <Select
                                value={values.currency || ""}
                                name="currency"
                                onChange={handleChange}
                            >
                                <MenuItem value="">-</MenuItem>
                                {Array.isArray(currencies) ? currencies.map((item, key) => (
                                    <MenuItem key={item.code} value={item.code}>{item.code}</MenuItem>)) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel>{t("PAYMENT_TYPE")}</InputLabel>
                            <Select
                                value={values.paymentType || ""}
                                name="paymentType"
                                onChange={handleChange}
                            >
                                <MenuItem value="">-</MenuItem>
                                {Array.isArray(paymentTypes) ? paymentTypes.map((item, key) => (
                                    <MenuItem key={key} value={item.value}>{item.label}</MenuItem>)) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel>{t("RECEIPT_PLACE")}</InputLabel>
                            <Select
                                value={values.branchId || ""}
                                name="branchId"
                                onChange={handleChange}
                            >
                                <MenuItem value="">-</MenuItem>
                                {Array.isArray(branches) ? branches.map((item, key) => (
                                    <MenuItem key={item.id} value={item.id}>{item.branchName}</MenuItem>)) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel>{t("POS_IDENT")}</InputLabel>
                            <Select
                                value={values.posId || ""}
                                name="posId"
                                onChange={handleChange}
                            >
                                <MenuItem value="">-</MenuItem>
                                {Array.isArray(pos) ? pos.map((item, key) => (
                                    <MenuItem key={pos.posId} value={item.posId}>{item.name}</MenuItem>)) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel>{t("FISCALIZED")}</InputLabel>
                            <Select
                                value={values.fiscalized || ""}
                                name="fiscalized"
                                onChange={handleChange}
                            >
                                <MenuItem value="">-</MenuItem>
                                <MenuItem value={true}>{t("YES")}</MenuItem>
                                <MenuItem value={false}>{t("NO")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md>
                        <FormControl fullWidth>
                            <InputLabel>{t("STATE")}</InputLabel>
                            <Select
                                value={values.state || ""}
                                name="state"
                                onChange={handleChange}
                            >
                                <MenuItem value="">-</MenuItem>
                                {Array.isArray(states) ? states.map((item, key) => (
                                    <MenuItem key={key} value={item.value}>{item.label}</MenuItem>)) : null}

                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

const cellStyle = {
    padding: '4px 4px',
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

const ReceiptsList = ({
                          open,
                          handleClose,
                          filterReceipts,
                          duplicateReceipt,
                          cancelReceiptById,
                          receipts,
                          isFetchingList,
                          searchUsers,
                          searchedUsers,
                          currencies,
                          fetchAllPos,
                          pos,
                          fetchAllBranches,
                          branches,
                          userUseCases,
                          cardNumber,
                          action,
                      }) => {
    const classes = useStyles();
    const [selectedReceipt, setSelectedReceipt] = useState();
    const {t} = useTranslation();


    const closeInner = () => {
        setValues({...defaultRange});
        handleClose();

    }


    const states = [
        {
            value: 'NEW',
            label: t('NEW'),
            key: 'new',
        },
        {
            value: 'SENT',
            label: t('SENT'),
            key: 'sent',
        },
        {
            value: 'CLOSED',
            label: t('CLOSED'),
            key: 'closed',
        },
        {
            value: 'PRINTED',
            label: t('PRINTED'),
            key: 'printed',
        },
        {
            value: 'CANCELED',
            label: t('CANCELED'),
            key: 'canceled',
        },
    ];

    const paymentTypes = [
        {
            value: 'CASH',
            label: t('CASH'),
            key: 'cash',
        },
        {
            value: 'CARD',
            label: t('CARD'),
            key: 'card',
        },
        {
            value: 'INVOICE',
            label: t('INVOICE'),
            key: 'invoice',
        },
    ];

    const columns = [
        {
            title: t('RECEIPT_NUMBER'),
            field: 'number',
            cellStyle,
            render: (rowData) => (
                rowData.number
            ),
            customSort: (a, b) => 0,
        },
        {
            customSort: (a, b) => 0,
            title: t('CREATION_DATE'), field: 'date', cellStyle, render: (rowData) => (
                <>
                    {rowData.date
                        ? moment(new Date(rowData.date)).locale(i18next.language).format('L LTS')
                        : ''}
                </>
            ),
        },
        {
            title: t('POS_IDENT'),
            field: 'pos',
            cellStyle,
            customSort: (a, b) => 0,
            render: (rowData) => (
                rowData.pos
            ),
        },
        {
            title: t('CREATED_BY'),
            field: 'createdBy',
            customSort: (a, b) => 0,
            cellStyle,
            render: (rowData) => (
                rowData.createdBy
            ),
        },
        {
            title: t('RECEIPT_PLACE'),
            field: 'branchAddress',
            cellStyle,
            render: (rowData) => (
                rowData.branchName
            ),
            customSort: (a, b) => 0,
        },
        {
            title: t('TOTAL_AMOUNT'),
            field: 'priceWithVAT',
            cellStyle,
            render: (rowData) => (
                rowData.priceWithVAT
            ),
            customSort: (a, b) => 0,
        },
        {
            title: t('NUMBER_OF_COPIES'),
            field: 'copyCount',
            cellStyle,
            render: (rowData) => (
                rowData.copyCount
            ),
            customSort: (a, b) => 0,
        },
        {
            title: t('PAYMENT_TYPE'),
            field: 'paymentType',
            cellStyle,
            render: (rowData) => (
                t(rowData.paymentType)
            ),
            customSort: (a, b) => 0,
        },
        {
            title: t('FISCALIZED'),
            field: 'fik',
            cellStyle,
            render: (rowData) => (
                rowData.fik
            ),
            customSort: (a, b) => 0,
        },
        {
            title: t('STATE'),
            field: 'state',
            cellStyle,
            render: (rowData) => (
                t(rowData.state)
            ),
            customSort: (a, b) => 0,
        },
    ];


    const [values, setValues] = useState({...defaultRange});
    const [size, setSize] = useState(5);
    const [page, setPage] = useState(0);

    const [sortingField, setSortingField] = useState("receiptId");
    const [sortingDesc, setSortingDesc] = useState(false);


    const resetPagination = () => {
        setPage(0);
    };

    const handleChangeRowsPerPage = (value) => {
        resetPagination();
        setSize(value);
        refreshFilter(values, 0, value, sortingField, sortingDesc);
    };
    const handleChangePage = (value) => {
        setPage(value);
        refreshFilter(values, value, size, sortingField, sortingDesc);
    };

    const handleOrderChange = (orderedColumnId, orderDirection) => {
        let sortFieldInner = "receiptId"
        let descInner = false;
        if (orderedColumnId >= 0) {
            sortFieldInner = columns[orderedColumnId].field;
            descInner = 'desc' === orderDirection;
        }
        setSortingField(sortFieldInner);
        setSortingDesc(descInner);
        refreshFilter({...values}, page, size, sortFieldInner, descInner);

    }

    const refreshFilter = (filter, lpage, lsize, sortingField, sortingDesc) => {
        return filterReceipts(filter, lpage || page, lsize || size, sortingField, sortingDesc);
    };

    const cancelReceipt = (selectedReceipt) => {
        cancelReceiptById(selectedReceipt.receiptId, localStorage.getItem('pos_id')).then(() => {
            refreshFilter({...values}, page, size, sortingField, sortingDesc);
        });
    }

    useEffect(() => {
            if (cardNumber) {
                const newValues = {...values, 'cardNumber': cardNumber};
                setValues(newValues);
                refreshFilter(newValues, page, size, sortingField, sortingDesc);
            }
        }, [cardNumber, action]
    );

    useEffect(() => {
        if (!!open) {
            refreshFilter({...values}, page, size, sortingField, sortingDesc).then(() => {
                searchUsers().then(() => {
                    fetchAllPos().then(() => {
                        fetchAllBranches();
                    })
                })
            });
        }
    }, [open]);


    useEffect(() => {
        if (Array.isArray(receipts.receipts) && receipts.receipts.length == 1) {
            setSelectedReceipt(receipts.receipts[0]);
        } else {
            setSelectedReceipt(null);
        }
    }, [receipts]);


    return (
        <Dialog
            open={open}
            onClose={closeInner}
            className={classes.container}
        >
            <DialogTitle className={classes.title}>
              <span>
                    {t('RECEIPTS_LIST')}
              </span>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {/* Filter */}
                <Box my={2}>
                    <ReceiptsFilter onFilterChange={refreshFilter}
                                    paymentTypes={paymentTypes}
                                    states={states}
                                    pos={pos}
                                    currencies={currencies}
                                    branches={branches}
                                    searchedUsers={searchedUsers}
                                    values={values}
                                    setValues={setValues}
                    />
                </Box>

                <Grid container spacing={2} style={{flex: 1, height: '70%'}}>
                    <Grid item md={selectedReceipt ? 9 : 12}>
                        <Paper className={classes.table}>
                            <Box p={3} height={1}>
                                <CustomTable
                                    title=""
                                    columns={columns}
                                    data={receipts.receipts}
                                    options={{
                                        headerStyle: {
                                            padding: '0 8px 0 34px',
                                            borderTop: '1px solid #DEE2E5',
                                            lineHeight: '12px',
                                            color: '#1B1B28',
                                            textAlign: 'center',
                                        },
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedReceipt && selectedReceipt.tableData.id === rowData.tableData.id) ? '#5985EE' : '#FFF'
                                        })
                                    }}
                                    isLoading={isFetchingList}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handleChangePage={handleChangePage}
                                    rowsPerPage={size}
                                    pageNumber={page}
                                    handleOrderChange={handleOrderChange}
                                    count={receipts.paging ? receipts.paging.totalSize : 0}
                                    loadAllData
                                    onRowClick={((evt, selectedReceipt) => {
                                        console.warn(selectedReceipt);
                                        setSelectedReceipt(selectedReceipt);
                                    })}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={3} style={{height: '100%'}}>
                        {!!selectedReceipt ? (
                            <Paper className={classes.table}>
                                <Box p={2} style={{height: '100%'}}>
                                    <ReceiptDetail
                                        userUseCases={userUseCases}
                                        receipt={selectedReceipt}
                                        onCurrencyChange={() => {
                                        }}
                                        currencies={[]}
                                    />
                                </Box>
                                <DialogActions className={classes.actions}>
                                    <div className={classes.buttonNavigation}>
                                        <ActionButton
                                            action="close"
                                            handleClick={() => {
                                                setSelectedReceipt(undefined);
                                            }}
                                            className={classes.buttonReceiptActions}
                                        />
                                        {isComponentVisibleForUser(UC_CANCEL_RECEIPT, userUseCases) &&
                                        <ActionButton
                                            action="cancel"
                                            disabled={(selectedReceipt && selectedReceipt.state === "CANCELED") || !isComponentEnabledForUser(UC_CANCEL_RECEIPT, userUseCases)}
                                            handleClick={() => {
                                                cancelReceipt(selectedReceipt)
                                            }}
                                            className={classes.buttonReceiptActions}
                                        />}
                                        {isComponentVisibleForUser(UC_DUPLICATE_RECEIPT, userUseCases) &&
                                        <ActionButton
                                            action="duplicate"
                                            disabled={!isComponentEnabledForUser(UC_DUPLICATE_RECEIPT, userUseCases)}
                                            handleClick={() => {
                                                duplicateReceipt(selectedReceipt.receiptId, localStorage.getItem('pos_id'))
                                            }}
                                            className={classes.buttonReceiptActions}
                                        />
                                        }
                                    </div>
                                </DialogActions>
                            </Paper>
                        ) : null}
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>

                    <ActionButton
                        action="close"
                        handleClick={closeInner}
                        className={classes.button}
                    />
                </div>

            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (store) => ({
    pos: store.posData.pos,
    branches: store.branchesData.branches,
    receipts: store.receiptsData.receipts,
    isFetchingList: store.receiptsData.isFetchingList,
    parkings: store.parkingsData.parkings,
    roles: store.rolesData.roles,
    currencies: store.currenciesData.currencies,
    searchedUsers: store.usersData.searchedUsers,
});

const UC_DUPLICATE_RECEIPT = 'UC0514';
const UC_CANCEL_RECEIPT = 'UC0513';

const mapDispatchToProps = (dispatch) => bindActionCreators({
    filterReceipts,
    cancelReceiptById,
    duplicateReceipt,
    searchUsers,
    fetchAllBranches,
    fetchAllPos,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReceiptsList);
