import httpService from './http.service';

const getDownloadAll = () => httpService
    .get(`/download/all`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getDownloadFile = (id, onDownloadProgress) => httpService
    .get(`/download/stream/${id}`, {}, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); // you can use this to show user percentage of file downloaded
            console.warn(percentCompleted);
            onDownloadProgress(percentCompleted);
        }
    })
    .then(res => res)
    .catch((err) => Promise.reject(err.response));

export default {
    getDownloadAll,
    getDownloadFile
};
