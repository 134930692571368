import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { fetchParkingZones } from 'redux/actions/zones';
import ZoneCard from './ZoneCard';

const useStyles = makeStyles((theme) => ({
  mr: {
    marginRight: theme.spacing(1),
  },
  mb: {
    marginBottom: theme.spacing(1),
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
  },
  emptyZones: {
    width: '100%',
    height: 416,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
  },
}));

function ParkingZones(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    parking,
    active,
    parkingZones,
    handleEditZoneOpen,
    fetchParkingZones,
    occupancy,
    userUseCases,
  } = props;
  console.warn("ParkingZones", active);

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [fetchedHistory, setFetchedHistory] = useState(false);
  const [occupationHistory, setOccupationHistory] = useState([]);

  const fetchZones = useCallback(() => {
    fetchParkingZones(parking.parkingId);
  }, [fetchParkingZones, parking]);

  useEffect(() => {
    if (!fetchedHistory && active) {
      setOccupationHistory(occupancy);
      setFetchedHistory(true);
    }
  }, [active, fetchedHistory]);

  useEffect(() => {
    if (active) {
      fetchZones();
    }
  }, [active, fetchZones]);

  const getZoneHistory = (zoneId) => {
    if (occupationHistory && occupationHistory.length > 0) {
      return occupationHistory.filter((history) => history.zoneId === zoneId);
    }

    return [];
  };

  const zones = parkingZones
    .filter((zone) => zone.show)
    .map((zone) => ({
      zoneId: zone.zoneId,
      zoneName: zone.zoneName,
      capacity: zone.capacity,
      occupancy: zone.occupancy,
      occupancyReserved: zone.occupancyReserved,
      reserved: zone.reserved,
      freeReserved: zone.capacity === null ? null : (zone.reserved - zone.occupancyReserved),
      others: zone.capacity === null ? null : (zone.capacity >= zone.reserved ? (zone.capacity - zone.reserved) : 0),
      freeOthers: zone.capacity === null ? null :(zone.capacity - zone.reserved - zone.occupancy),
      zoneGmtpId: zone.zoneGmtpId,
      parkingId: zone.parkingId,
      show: zone.show,
      progress: zone.capacity === null ? null : ( zone.capacity > zone.occupancy
        ? Math.floor(((zone.occupancy + (zone.occupancyReserved?zone.occupancyReserved:0)) * 100) / zone.capacity)
        : 100),
      validData: zone.occupancy >= 0 && zone.capacity >= 0,
    }));

  const getCardWidth = (index) => {
    let cardWidth = zones.length > 0
      ? `${100 / (zones.length > 3 ? 3 : zones.length) - 1}%`
      : 0;
    if (zones.length > 3 && zones.length % 3 !== 0) {
      if (zones.length % 3 === 1 && index === zones.length - 1) {
        cardWidth = '99%';
      } else if (index > zones.length - 3) {
        cardWidth = '49%';
      }
    }

    return cardWidth;
  };

  const EmptyPage = () => (
    <div className={classes.emptyZones}>
      {t('NO_RECORDS_TO_DISPLAY')}
    </div>
  );

  return (
    <div>
      {zones.length > 0 ? (
        <div className={classnames(classes.flexContainer, classes.mb)}>
          {zones.map((zone, index) => (
            <ZoneCard
              userUseCases={userUseCases}
              histories={getZoneHistory(zone.zoneId)}
              zone={zone}
              cardWidth={getCardWidth(index)}
              handleEditZoneOpen={handleEditZoneOpen}
              key={`zone_${zone.zoneId}`}
            />
          ))}
        </div>
      ) : (
        <EmptyPage />
      )}
    </div>
  );
}

ParkingZones.propTypes = {
  parking: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  parkingZones: PropTypes.array.isRequired,
  handleEditZoneOpen: PropTypes.func.isRequired,
  fetchParkingZones: PropTypes.func.isRequired,
  occupancy: PropTypes.array.isRequired,
};

const mapStateToProps = (store) => ({
  parkingZones: store.zonesData.parkingZones,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchParkingZones,
}, dispatch);

export default React.memo(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingZones), (o1, o2)=>{return o2.active===false});

