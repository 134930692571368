import photoService from 'services/photo.service';
import types from '../actionTypes';

export const registrePhoto = (parkingHostId, withDetail) => (dispatch, getState) => {
  dispatch({
    type: types.PHOTO_START_FETCH_REQUEST,
  });

  return photoService.registrePhoto(parkingHostId, withDetail)
    .then(() => {
      dispatch({
        type: types.PHOTO_START_FETCH_SUCCESS,
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.PHOTO_START_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const unRegistrePhoto = (parkingHostId) => (dispatch, getState) => {
  dispatch({
    type: types.PHOTO_STOP_FETCH_REQUEST,
  });

  return photoService.unRegistrePhoto(parkingHostId)
    .then(() => {
      dispatch({
        type: types.PHOTO_STOP_FETCH_SUCCESS,
        payload: {},
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.PHOTO_STOP_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const getPhotoDetail = (photoKey, parkingId) => (dispatch, getState) => {
  dispatch({
    type: types.PHOTODETAIL_FETCH_REQUEST,
  });

  return photoService.getPhotoDetail(photoKey, parkingId)
    .then((photo) => {
      dispatch({
        type: types.PHOTODETAIL_FETCH_SUCCESS,
        payload: {photo},
      });

      return photo;
    })
    .catch((error) => {
      dispatch({
        type: types.PHOTODETAIL_FETCH_FAILT,
        payload: { error },
      });

      throw error;
    });
};