import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {LinearProgress, Typography} from '@material-ui/core';
import {DropzoneAreaBase} from 'material-ui-dropzone';
import {ReactSortable} from 'react-sortablejs';
import PerfectScrollbar from "react-perfect-scrollbar";
import {getIcon} from 'containers/Parking/LocalParking/LocalParkingHosts/Utils';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CustomVideo from 'components/elements/CustomVideo';
import { Loading } from 'components/elements';


const useStyles = makeStyles((theme) => ({
    box: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        border: `1px solid ${theme.palette.base[400]}`,
        marginTop: '20px',
        padding: '20px'
    },
    item: {
        height: '490px',
        border: `1px solid ${theme.palette.base[400]}`,
        marginTop: '-10px',
        padding: '20px'
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    myDropZone: {
        minHeight: '130px',
    },
    shortName: {
        height: 24,
        fontSize: 16,
        marginLeft: 3,
        marginRight: 3,
        width: '100%',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    video: {
        width: '150px',
        height: '150px',
        margin: '20px'
    },
    iconChoosed: {
        marginLeft: '-20px',
        color: '#6BBBAE'
    },
    break: {
        flexBasis: '100%',
        height: 0,
    },
    parkingNameMargin: {
        marginTop: '20px',
    }
}));

function PlayListView(props) {
    const classes = useStyles();

    const {
        handleChange,
        updatedPlayList,
        setUpdatedPLayList,
        error,
        readOnly,
        isNew,
        hosts,
        parkings,
        loading,
        saving,
    } = props;

    const {t} = useTranslation();
    const [files, setFiles] = useState([]);
    const [localHosts, setLocalHosts] = useState(null);

    let lastParkingName = '';

    useEffect(() => {
        setUpdatedPLayList({...updatedPlayList, files: files});
    }, [files]);

    useEffect(() => {
        if (hosts && isNew) {
            setLocalHosts([...hosts]);
        }
    }, [hosts]);

    useEffect(() => {
        let tmpPlayList = null;
        if ( !updatedPlayList ) return;

        if (!isNew && updatedPlayList.items && updatedPlayList.items.length > 0 && files.length === 0 && !updatedPlayList.initializedFiles) {
            let tmp = [];
            for (let i = 0; i < updatedPlayList.items.length; i++) {
                tmp.push({
                    id: updatedPlayList.items[i].playListItemId ? updatedPlayList.items[i].playListItemId : updatedPlayList.items[i].fileName,
                    data: 'data:video/mp4;base64,' + updatedPlayList.items[i].fileContent,
                    file: {path: updatedPlayList.items[i].fileName, name: updatedPlayList.items[i].fileName},
                });
            }
            setFiles(tmp);

            if (tmpPlayList === null) tmpPlayList = {...updatedPlayList};
            tmpPlayList.files = tmp;
            tmpPlayList.initializedFiles = true;
            // setUpdatedPLayList({...updatedPlayList, files:tmp, initializedFiles:true});
        }

        if (!readOnly && !isNew && hosts && hosts.length > 0 && !updatedPlayList.initialized) {

            let tmp = clearChoosed(hosts);
            for (let j = 0; j < tmp.length; j++) {
                if (updatedPlayList.hosts && updatedPlayList.hosts.length > 0) {
                    for (let i = 0; i < updatedPlayList.hosts.length; i++) {
                        if (tmp[j].parkingHostId === updatedPlayList.hosts[i].parkingHostId) {
                            tmp[j].choosed = true;
                        }
                    }
                }
            }
            setLocalHosts(tmp);
            if (tmpPlayList === null) tmpPlayList = {...updatedPlayList};
            tmpPlayList.hosts = tmp;
            tmpPlayList.initialized = true;
            // setUpdatedPLayList({...updatedPlayList, initialized:true, hosts:tmp});
        } else if (readOnly && updatedPlayList.hosts && updatedPlayList.hosts.length > 0) {
            let tmp = [];
            for (let i = 0; i < updatedPlayList.hosts.length; i++) {
                for (let j = 0; j < hosts.length; j++) {
                    if (hosts[j].parkingHostId === updatedPlayList.hosts[i].parkingHostId) {
                        tmp.push({...hosts[j], choosed: true});
                    }
                }
            }
            setLocalHosts(tmp);
        } else if (isNew && hosts && hosts.length > 0 && !updatedPlayList.initialized) {
            setLocalHosts(clearChoosed(hosts));
            if (tmpPlayList === null) tmpPlayList = {...updatedPlayList};
            tmpPlayList.initialized = true;
            // setUpdatedPLayList({...updatedPlayList, initialized:true});
        }

        if (tmpPlayList)
            setUpdatedPLayList(tmpPlayList);

    }, [updatedPlayList]);

    const clearChoosed = (innerHosts) => {
        if (innerHosts && innerHosts.length > 0) {
            let tmp = [...innerHosts];
            for (let i = 0; i < innerHosts.length; i++) {
                tmp[i].choosed = false;
            }
        }
        return innerHosts;
    }

    const handleAdd = (newFiles) => {
        newFiles = newFiles.filter(file => !files.find(f => f.data === file.data));
        setFiles([...files, ...newFiles]);
    };


    const handleIconClick = (item) => () => {
        let tmp = [...localHosts]
        for (let i = 0; i < tmp.length; i++) {
            if (tmp[i].parkingHostId === item.parkingHostId) {
                tmp[i].choosed = !tmp[i].choosed;
            }
        }
        setLocalHosts(tmp);
        setUpdatedPLayList({...updatedPlayList, hosts: tmp});
    }

    const getParkingName = (parkingId) => {
        if (parkings && parkings.length > 0) {
            for (let i = 0; i < parkings.length; i++) {
                if (parkings[i].parkingId === parkingId) {
                    return parkings[i].parkingName;
                }
            }
        }
        return '';
    }

    const handleDelete = (file) => () => {
        let newFiles = files.filter(f => f !== file)
        setFiles(newFiles);
        setUpdatedPLayList({...updatedPlayList, files: newFiles});
    }

    return (
        <div className={classes.box}>
            {loading &&
                <Loading />
            }
            { !loading && updatedPlayList &&
                <Grid container spacing={2}>
                    <Grid item xs={2} spacing={1}>
                        <TextField
                            style={{height: '60px'}}
                            autoFocus
                            helperText={error && error.name
                                ?
                                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                : null}
                            fullWidth
                            disabled={readOnly}
                            id="name"
                            value={updatedPlayList.name || ''}
                            onChange={handleChange('name', false)}
                            label={t('NAME')}/>
                    </Grid>
                    <Grid item xs={2} spacing={1}>
                        <FormControl required fullWidth disabled={false} style={{height: '60px'}}>
                            <InputLabel id="active-label">
                                {t('ACTIVE_PLAY_LIST')}
                            </InputLabel>
                            <Select
                                autoWidth
                                disabled={readOnly}
                                value={updatedPlayList.active || ''}
                                id="active"
                                onChange={handleChange('active', true)}
                            >
                                <MenuItem value=''>-</MenuItem>
                                <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                <MenuItem value={'false'}>{t('NO')}</MenuItem>
                            </Select>
                            <FormHelperText>  {error && error.active && (
                                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            )}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8} spacing={1}></Grid>
                    <Grid item xs={6} spacing={1}>
                        <Typography variant='h6'>{t('FILE_LIST')}</Typography>
                    </Grid>
                    <Grid item xs={6} spacing={1}>
                        <Typography variant='h6'>{t('APM_LIST')}</Typography>
                    </Grid>
                    <Grid item xs={6} spacing={1}>
                        <div className={classes.item}>
                            <PerfectScrollbar
                                options={{
                                    suppressScrollX: true,
                                    minScrollbarLength: 50,
                                }}
                            >
                                {!readOnly &&
                                    <DropzoneAreaBase
                                        dropzoneClass={classes.myDropZone}
                                        fileObjects={files}
                                        acceptedFiles={['video/*']}
                                        showPreviewsInDropzone={false}
                                        onAdd={handleAdd}
                                        dropzoneText={t('DROP_ZONE_TEXT')}
                                        maxFileSize={60000000}
                                        filesLimit={10000}
                                        height='30px'
                                    />
                                }
                                <div>
                                    <ReactSortable
                                        animation={200}
                                        delayOnTouchStart={true}
                                        delay={2}
                                        className={classes.container}
                                        list={files}
                                        setList={setFiles}
                                    >

                                        {

                                            files.map((item, index) => {
                                                    return (
                                                        <div key={item.id ? item.id: item.file.name}>
                                                            <CustomVideo
                                                                videoName={item.file.name}
                                                                data={item.data}
                                                                handleDelete={handleDelete}
                                                                showDelete={true}
                                                                file={item}
                                                            />
                                                        </div>
                                                    )

                                                }
                                            )}
                                    </ReactSortable>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </Grid>
                    <Grid item xs={6} spacing={1}>
                        <div className={classes.item}>
                            <PerfectScrollbar
                                options={{
                                    suppressScrollX: true,
                                    minScrollbarLength: 50,
                                }}
                            >
                                <div className={classes.container}>
                                    {localHosts && localHosts.map((item, index) => {
                                        let tmp = getParkingName(item.parkingId);
                                        let shotParkingName = false;
                                        if (lastParkingName !== tmp) {
                                            lastParkingName = tmp;
                                            shotParkingName = true;
                                        }
                                        return (
                                            <>
                                                {shotParkingName &&
                                                    <>
                                                        {index !== 0 &&
                                                            <>
                                                                <div className={classes.break}/>
                                                            </>
                                                        }
                                                        <Typography variant='h4'
                                                                    className={index !== 0 ? classes.parkingNameMargin : ''}>{lastParkingName}</Typography>
                                                        <div className={classes.break}/>
                                                    </>
                                                }
                                                <div key={'host_' + index} onClick={handleIconClick(item)}>
                                                    <img
                                                        src={getIcon(item.iconName)}
                                                        className={classes.image}
                                                        alt={item.hostName}
                                                        title={item.hostName}
                                                    />
                                                    {!item.choosed &&
                                                        <RadioButtonUncheckedIcon className={classes.iconChoosed}/>}
                                                    {item.choosed &&
                                                        <CheckCircleOutlineIcon className={classes.iconChoosed}/>}
                                                    <div className={classes.shortName}>{item.hostName}</div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </Grid>

                                        {saving &&
                        <Grid item xs={12}>
                            <LinearProgress color="secondary" />
                        </Grid>
                    }

                </Grid>
            }
        </div>
    )
}

PlayListView.propTypes = {};
PlayListView.defaultProps = {};


const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PlayListView);


