import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {CustomButton, CustomTable, Loading} from 'components/elements';
import {connect} from 'react-redux';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Close from '@material-ui/icons/Close';
import {deleteParking} from 'redux/actions/parkings';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {ActionButton} from "../../../components/common";
import {getErrorMessage, isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from '@material-ui/icons/Info';
import RestoreIcon from '@material-ui/icons/Restore';
import Divider from '@material-ui/core/Divider';
import InitStateIcon from "@material-ui/icons/UpdateOutlined";
import OKStateIcon from "@material-ui/icons/CheckCircleOutline";
import ProblemStateIcon from "@material-ui/icons/ErrorOutline";
import NotConnectedStateIcon from "@material-ui/icons/CloudOffOutlined";
import UnknownStateIcon from '@material-ui/icons/HelpOutline';
import CertificateIcon from '@material-ui/icons/VerifiedUser';
import SecurityIcon from '@material-ui/icons/Security';
import Tooltip from '@material-ui/core/Tooltip';
import i18next from "i18next";
import moment from 'moment/min/moment-with-locales';
import {blacklist} from "../../../redux/actions/pos";
import classnames from "classnames";
import PMCDialog from 'components/common/PMCDialog';
import RemoveCertificateIcon from '@material-ui/icons/DeleteForever';
import POSBlacklistedIcon from '@material-ui/icons/DesktopAccessDisabled';
import CertIssuedIcon from '@material-ui/icons/PostAdd';
import BlockedIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 20,
    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        fontSize: 20,
    },
    actions: {
        display: 'flex',
        width: '50%',
        justifyContent: 'space-around',
    },

    parkingCard: {
        width: 384,
        height: 200,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    yellowPaper: {
        background: theme.palette.secondary.lightYellow,

    },
    expiringPos: {
        backgroundColor: '#e5cfcc',
    },
    grey: {
        backgroundColor: theme.palette.base[100],
    },
    disabled: {
        backgroundImage: 'linear-gradient(135deg, #FFFFFF00 25%, #FFFFFF3F 25%, #FFFFFF3F 50%, #FFFFFF00 50%, #FFFFFF00 75%, #FFFFFF3F 75%, #FFFFFF3F 100%)',
        backgroundSize: '20px 20px',
    },
    root: {
        width: 380,
        height: 40,
        background: theme.palette.base[100],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    state: {
        display: 'flex',
        marginTop: -60,
    },
    row1: {
        width: 380,
        height: 90,
        fontSize: 12,
        padding: 5,
        marginTop: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-between',
    },
    row11: {
        width: 380,
        height: 90,
        fontSize: 12,
        padding: 5,
        marginTop: 10,
        display: 'inline',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-between',
    },
    divider: {
        width: 380,
        marginTop: 10,
    },

    row2: {
        width: 380,
        height: 20,
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    row3: {
        width: 380,
        height: 40,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    row4: {
        width: 380,
        height: 40,
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-around',
        margin: 0,
        height: '100%',
        textAlign: 'center',
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    white: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.base.black,
        minWidth: '50%',
    },
    brown: {
        backgroundColor: theme.palette.secondary.darkYellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    empty: {
        width: '100%',
        height: 416,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
    },
    containerModal: {
        '& .MuiDialog-paper': {
            width: 834,
            maxWidth: 'unset',
            height: 'fit-content',
            padding: theme.spacing(4.25, 3.25),
            boxShadow: theme.palette.shadow.main,
        },
    },
    title: {
        color: theme.palette.base[700],
        '& .MuiTypography-root': {
            lineHeight: '25px',
        },
        marginLeft: 20
    },
    connected: {
        color: theme.palette.primary.main,
        '& .MuiTypography-root': {
            fontSize: 14,
        },
    },
    disconnected: {
        color: theme.palette.primary.red,
        '& .MuiTypography-root': {
            fontSize: 14,
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 16,
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 18,
    },
}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function PosList(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        pos,
        userUseCases,
        isLoading,
        handlePosDetail,
        blacklist,
        save,
        refresh,
        setDeletionSnackbarOpen,
        setDeletionSnackbarError,
        handlePosHistory,
        table,
    } = props;
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);
    const [posToDelete, setPosToDelete] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);
    const deletePosInner = (parking) => {
        setActionInProgress(true);
        blacklist(parking).then(
            () => {
                setPosToDelete(null);
                setConfirmModalOpened(false);
                refresh()
            }
        ).catch((error) => {
            setDeletionSnackbarOpen(true);
            setDeletionSnackbarError(getErrorMessage(error, t));
        });
    }
    const activate = (pos) => {
        saveInner({...pos, active: true});
    }
    const deactivate = (pos) => {
        saveInner({...pos, active: false});
    }
    const saveInner = (pos) => {
        setActionInProgress(true);
        save(pos).then(
            () => {
                refresh();
                setActionInProgress(false);
            }
        ).catch((error) => {
            setDeletionSnackbarError(getErrorMessage(error, t));
            setActionInProgress(false);
        });
    }

    const getPosCardStyle = (pos) => {
        const timestamp = new Date().getTime() + (30 * 24 * 60 * 60 * 1000);
        //                                      day hour  min  sec  msec
        if (pos.validTo) {
            const validToDate = new Date(pos.validTo);
            if (validToDate < timestamp) {
                return classnames(classes.parkingCard, classes.expiringPos , classes.disabled);
            }
        }
        return classes.parkingCard;
    }

    const renderPOSState = (param) => {
        switch(param) {
            case 'NEW':
            case 'INIT':
                return <Tooltip title={t('POS_STATE_COMMENT_INIT')}><InitStateIcon color="disabled" /></Tooltip>
            case 'CERT_ISSUED':
                return <Tooltip title={t('POS_STATE_COMMENT_ISSUED')}><CertIssuedIcon color="action"/></Tooltip>
            case 'OK':
                    return <Tooltip title={t('POS_STATE_COMMENT_OK')}><OKStateIcon color="primary"/></Tooltip>
            case 'ERROR':
                return <Tooltip title={t('POS_STATE_COMMENT_PROBLEM')}><ProblemStateIcon color="error"/></Tooltip>
            case 'NOT_CONNECTED':
                return <Tooltip title={t('POS_STATE_COMMENT_NOT_CONNECTED')}><NotConnectedStateIcon/></Tooltip>
            default:
                return <Tooltip title={t('POS_STATE_COMMENT_UNKNOWN')}><UnknownStateIcon color="error"/></Tooltip>
        }
    }
    const renderCertificateState = (param) => {

        if (!param) {
            return <Tooltip title={t('CERTIFICATE_INVALID')}><SecurityIcon color="error"/></Tooltip>
        }
        const validTo = (param) ? moment(param).locale(i18next.language).format('lll'): ' ';
        const diff = (param) ? moment(param).diff(moment(Date.now()), 'days'): 0;
        if (diff < 5) {
            return <Tooltip title={t('CERTIFICATE_EXPIRES_SOON')}><Typography variant="h6" ><CertificateIcon color="error" style={{fontStyle: '13px'}} /> {validTo}</Typography></Tooltip>
        } else if (diff < 20) {
            return <><Tooltip title={t('CERTIFICATE_EXPIRES_SOON')}><CertificateIcon color="secondary" style={{fontStyle: '13px'}} /></Tooltip> {validTo}</>
        } else {
            return <><Tooltip title={t('CERTIFICATE_IS_VALID')}><CertificateIcon color="primary" style={{fontStyle: '13px'}} /></Tooltip> {validTo}</>
        }
    }

    const columns = [
        {   title: '',
            field: 'state',
            headerStyle: {textAlign: 'center', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center',
            },
            render: (rowData) => (
                renderPOSState(rowData.state)
            )
        },
        {
            title: t('BRANCH'),
            field: 'branchName',
            headerStyle: {textAlign: 'center', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.branchName
            )
        },
        {
            title: t('NAME'),
            field: 'name',
            headerStyle: {textAlign: 'center', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                rowData.name
            )
        },
        {
            title: t('SUBJECT'),
            field: 'subjectName',
            headerStyle: {textAlign: 'center', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },

            render: (rowData) => (
                rowData.subjectName
            ),
        },
        {
            title: t('CERT_VALIDITY'),
            field: 'validTo',
            headerStyle: {textAlign: 'center', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
            },
            render: (rowData) => (
                renderCertificateState(rowData.validTo)
               
            ),
        },
        {
            title: t('USER'),
            field: 'createdBy',
            headerStyle: {textAlign: 'center', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center',
            },
            render: (rowData) => (
                rowData.createdBy
               
            ),
        },
       
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC_POS_UPDATE, userUseCases) &&
                                <Tooltip title="Upravit parametry POS"><ActionButton handleClick={() => {
                                    handlePosDetail(rowData, false)
                                }}
                                                        disabled={!isComponentEnabledForUser(UC_POS_UPDATE, userUseCases) || actionInProgress}
                                                        className={classes.mr}
                                                        action="edit"
                                                       /></Tooltip>
                            }
                            {isComponentVisibleForUser(
                                    UC_POS_BLACKLIST, userUseCases) && 
                                    !rowData.validTo ? <Tooltip title={t('CERTIFICATE_NOT_ISSUED')}><ActionButton className={`${classes.orange} ${classes.disabled}`} action="certnotissued"
                                    disabled={true}
                                    icon={<BlockedIcon className={classes.actionIcon}/>}/></Tooltip>:
                                    rowData.blackList ?
                                        <Tooltip title={t('BLACKLISTED')}><ActionButton className={`${classes.orange} ${classes.disabled}`} action="blacklisted"
                                                            disabled={true}
                                                            icon={<POSBlacklistedIcon className={classes.actionIcon}/>}/></Tooltip> :

                                        <Tooltip title={t('BLACKLIST')}><ActionButton className={classes.red} action="Blacklist"
                                                            disabled={!isComponentEnabledForUser(UC_POS_BLACKLIST, userUseCases) || actionInProgress}
                                                            handleClick={() => {
                                                                setPosToDelete(rowData);
                                                                setConfirmModalOpened(true);
                                                            }}
                                                            icon={<RemoveCertificateIcon className={classes.actionIcon}/>}/></Tooltip>
                            }
                            {isComponentVisibleForUser(
                                    UC_POS_HISTORY, userUseCases) &&
                                    <Tooltip title={t('HISTORY')}><ActionButton className={classes.blue} action="history"
                                    handleClick={() => {
                                                            handlePosHistory(rowData);
                                                        }}
                                                        disabled={!isComponentEnabledForUser(UC_POS_HISTORY, userUseCases) || actionInProgress}
                                                        icon={<InfoIcon className={classes.actionIcon}/>}/></Tooltip>
                            }
                </div>
            ),
        },

    ];

    return (
        <div className={classes.container}>
            {confirmModalOpened
                && (
                    
                    <PMCDialog
            open={confirmModalOpened}
            title={`${t('REMOVE_POS_TITLE')} ${posToDelete ? ' - '+posToDelete.name : ''}`}
            onClose={() => {
                setConfirmModalOpened(false);
                setPosToDelete(null);
            }}
            headerColor="#000"
            size="md"
            height={300}
            onSubmit={
                () => {
                    deletePosInner(posToDelete);
                }}
                actionType="blacklist"
        >
            <div className={classes.content}>
            {t('CONFIRM_REMOVE_POS', {
                                    posName: posToDelete ? posToDelete.name : '',
                                })}
            </div>
        </PMCDialog>

                )}
            {!isLoading ?
                table ? <CustomTable
                    title=""
                    columns={columns}
                    data={pos}
                    options={tableOptions}
                    isLoading={isLoading}
                    loadAllData={false}
                />:
                pos.map((posItem, index) => (
                    <div
                        className={getPosCardStyle(posItem)}>
                        <div className={classes.row1}>
                            <div className={classes.state}>
                                {renderPOSState(posItem.state)}
                            </div>
                                <div className={classes.title}>
                                    <Tooltip title={t('BRANCH')}><Typography variant="h4">{posItem.branchName}</Typography></Tooltip>
                                    <Tooltip title={t('NAME')}><Typography variant="h4">{posItem.name}</Typography></Tooltip>
                                    <Tooltip title={t('SUBJECT')}><Typography variant="h6">{posItem.subjectName}</Typography></Tooltip>
                                    <Typography variant="h6">
                                        {posItem.lastConnected ? t('CONNECTED_FROM')+': '+moment(posItem.lastConnected).locale(i18next.language).format('lll'): ' - '}
                                    </Typography>
                                </div>
                            <div className={classes.state}>
                                {isComponentVisibleForUser(UC_POS_UPDATE, userUseCases) &&
                                <Tooltip title={t('EDIT')}>
                                    <IconButton onClick={() => {
                                            handlePosDetail(posItem, false)
                                        }}
                                        disabled={!isComponentEnabledForUser(UC_POS_UPDATE, userUseCases)}>
                                        <EditIcon color="disable" style={{ fontSize: 30 }}/>
                                    </IconButton>
                                </Tooltip>
                                }
                            </div>
                        </div>
                        <div className={classes.divider}>
                            <Divider/>
                        </div>
                        <div className={classes.row2}>

                            <div className={classes.rowColumn}>
                                <Typography variant="h6">
                                    {renderCertificateState(posItem.validTo)}
                                </Typography>
                            </div>
                            <div className={classes.rowColumn}>
                                <div className={classes.value}>{posItem.createdBy ? posItem.createdBy : ' '}</div>
                            </div>
                        </div>
                        <BottomNavigation
                            className={classes.root}
                        >
                            {isComponentVisibleForUser(
                                    UC_POS_BLACKLIST, userUseCases) && 
                                    !posItem.validTo ? <Tooltip title={t('CERTIFICATE_NOT_ISSUED')}><BottomNavigationAction className={`${classes.orange} ${classes.disabled}`} label="Close"
                                    disabled={false}
                                    icon={<BlockedIcon className={classes.actionIcon}/>}/></Tooltip>:
                                    posItem.blackList ?
                                        <Tooltip title={t('BLACKLISTED')}><BottomNavigationAction className={`${classes.orange} ${classes.disabled}`} label="Close"
                                                            disabled={false}
                                                            icon={<POSBlacklistedIcon className={classes.actionIcon}/>}/></Tooltip> :

                                        <Tooltip title={t('BLACKLIST')}><BottomNavigationAction className={classes.red} label="Close"
                                                            disabled={actionInProgress || !isComponentEnabledForUser(UC_POS_BLACKLIST, userUseCases)}
                                                            onClick={() => {
                                                                setPosToDelete(posItem);
                                                                setConfirmModalOpened(true);
                                                            }}
                                                            icon={<RemoveCertificateIcon className={classes.actionIcon}/>}/></Tooltip>
                            }
                            {isComponentVisibleForUser(
                                    UC_POS_HISTORY, userUseCases) &&
                                    <Tooltip title={t('HISTORY')}><BottomNavigationAction className={classes.blue} label="Close"
                                                        onClick={() => {
                                                            handlePosHistory(posItem);
                                                        }}
                                                        disabled={actionInProgress || !isComponentEnabledForUser(UC_POS_HISTORY, userUseCases)}
                                                        icon={<InfoIcon className={classes.actionIcon}/>}/></Tooltip>
                            }
                        </BottomNavigation>
                    </div>
                ))
                :
                <Loading/>
            }
        </div>
    );
}
PosList.propTypes = {
    parkings: PropTypes.array.isRequired,
};
const UC_POS_UPDATE = 'UC0641';
const UC_POS_BLACKLIST = 'UC0643';
const UC_POS_HISTORY = 'UC0644';

PosList.defaultProps = {};
const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({deleteParking}, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PosList);