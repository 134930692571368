import productsService from 'services/products.service';
import types from '../actionTypes';



export const initProduct = (product, cardNumber) => (dispatch, getState) => {


    if (getState().productsData.isInitializingProduct) {
        return;
    }

    dispatch({
        type: types.PRODUCT_INIT_REQUEST,
    });

    return productsService.initProductParam(product, cardNumber)
        .then((product) => {
            dispatch({
                type: types.PRODUCT_INIT_SUCCESS,
                payload: {product},
            });

            return product;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_INIT_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchProduct = (id) => (dispatch, getState) => {
    if (id === 0) {
        dispatch({
            type: types.PRODUCT_FETCH_SUCCESS,
            payload: {
                product:
                    {
                        rolesId: [],
                        dateFrom: new Date(),
                        productId: 0,
                        productParam: [],
                    }
            },
        });
        return;
    }

    if (getState().productsData.isFetchingProduct) {
        return;
    }

    dispatch({
        type: types.PRODUCT_FETCH_REQUEST,
    });

    return productsService.getProduct(id)
        .then((product) => {
            dispatch({
                type: types.PRODUCT_FETCH_SUCCESS,
                payload: {product},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchProductTemplateParamSelect = (names, parkingIds, namesNoParking) => (dispatch, getState) => {
    if (getState().productsData.isFetchingProductTemplateParamSelect) {
        return;
    }

    dispatch({
        type: types.PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_REQUEST,
    });
    let actions = [];

    for (let i = 0; i < parkingIds.length; i++) {
        const actionsPerParking = names.map((name) => new Promise((resolve) => {
            productsService.getAllProductTemplateParamSelectValues(name, parkingIds[i])
                .then((productTemplateParamSelectValues) => {
                    resolve({'name': `${name}${parkingIds[i]}`, productTemplateParamSelectValues});
                })
                .catch((error) => {
                    console.log('fetchProductTemplateParamSelect: ', error);
                    resolve({'name': `${name}${parkingIds[i]}`, 'productTemplateParamSelectValues': []});
                });
        }));
        actions = actions.concat(actionsPerParking);
    }
    if (namesNoParking) {
        for (let i = 0; i < namesNoParking.length; i++) {
            actions.push(new Promise((resolve) => {
                productsService.getAllProductTemplateParamSelectValuesNoParking(namesNoParking[i])
                    .then((productTemplateParamSelectValues) => {
                        resolve({'name': namesNoParking[i], productTemplateParamSelectValues});
                    })
                    .catch((error) => {
                        console.log('fetchProductTemplateParamSelect: ', error);
                        resolve({'name': namesNoParking[i], 'productTemplateParamSelectValues': []});
                    });
            }));
        }
    }


    return Promise.all(actions)
        .then((values) => {
            const productTemplateSelectValues = {};
            values.forEach((val) => {
                if (val) {
                    productTemplateSelectValues[val['name']] = val['productTemplateParamSelectValues'] ? val['productTemplateParamSelectValues'].map(a => {
                        return {value: a.id, label: a.paramName, key: a.id}
                    }).filter(a => a.label !== null) : [];
                }
            });

            dispatch({
                type: types.PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_SUCCESS,
                payload: {productTemplateSelectValues},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_TEMPLATE_PARAM_SELECT_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
}

export const fetchAllProducts = (date) => (dispatch, getState) => {
    if (getState().productsData.isFetchingAllList) {
        return;
    }

    dispatch({
        type: types.PRODUCTS_FETCH_REQUEST,
    });

    return productsService.getAllProducts(date)
        .then((products) => {
            dispatch({
                type: types.PRODUCTS_FETCH_SUCCESS,
                payload: {products},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCTS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const fetchAllProductsForCashDesk = (computerId) => (dispatch, getState) => {
    if (getState().productsData.isFetchingAllList) {
        return;
    }

    dispatch({
        type: types.CASHDESK_PRODUCTS_FETCH_REQUEST,
    });

    return productsService.getAllProductsForCashDesk(computerId)
        .then((productsForCashDesk) => {
            dispatch({
                type: types.CASHDESK_PRODUCTS_FETCH_SUCCESS,
                payload: {productsForCashDesk},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CASHDESK_PRODUCTS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const fetchAllProductGroups = () => (dispatch, getState) => {
    if (getState().productsData.isFetchingAllGroupsList) {
        return;
    }

    dispatch({
        type: types.PRODUCT_GROUPS_FETCH_REQUEST,
    });

    return productsService.getAllProductGroups()
        .then((productGroups) => {
            dispatch({
                type: types.PRODUCT_GROUPS_FETCH_SUCCESS,
                payload: {productGroups},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_GROUPS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const fetchAllProductTemplates = () => (dispatch, getState) => {
    if (getState().productsData.isFetchingAllTemplatesList) {
        return;
    }

    dispatch({
        type: types.PRODUCT_TEMPLATES_FETCH_REQUEST,
    });

    return productsService.getAllProductTemplates()
        .then((templates) => {
            dispatch({
                type: types.PRODUCT_TEMPLATES_FETCH_SUCCESS,
                payload: {templates},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_TEMPLATES_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};




export const createProduct = (product) => (dispatch, getState) => {
    if (getState().productsData.isCreating) {
        return;
    }

    dispatch({
        type: types.PRODUCT_CREATE_REQUEST,
    });

    return productsService.createProduct(product)
        .then(() => {
            dispatch({
                type: types.PRODUCT_CREATE_SUCCESS,
                payload: {product},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateProduct = (product) => (dispatch, getState) => {
    if (getState().productsData.isUpdating) {
        return;
    }

    dispatch({
        type: types.PRODUCT_UPDATE_REQUEST,
    });

    return productsService.updateProduct(product)
        .then(() => {
            dispatch({
                type: types.PRODUCT_UPDATE_SUCCESS,
                payload: {product},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PRODUCT_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const deleteProduct = (product) => (dispatch, getState) => {
    return productsService.deleteProduct(product)
        .then(() => {
            return true;
        })
        .catch((error) => {
            throw error;
        });
};


