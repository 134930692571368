import tasksService from 'services/tasks.service';
import types from '../actionTypes';

const sortTasksByAssigned = (tasks) => {
  const checkAssigned = (task) => task.status === 'AS'
    || task.status === 'CO'
    || task.status === 'CA'
    || new Date().getTime() - task.creationDateTS <= 1800000;

  if (tasks.length > 0) {
    tasks.sort((a, b) => {
      const assignedA = checkAssigned(a);
      const assignedB = checkAssigned(b);

      if (assignedA === true && assignedB === false) {
        if (a.creationDateTS > b.creationDateTS) {
          return 3;
        }
        if (a.creationDateTS < b.creationDateTS) {
          return 1;
        }
        if (a.creationDateTS === b.creationDateTS) {
          return 2;
        }
      }
      if (assignedA === false && assignedB === true) {
        if (a.creationDateTS > b.creationDateTS) {
          return -1;
        }
        if (a.creationDateTS < b.creationDateTS) {
          return -3;
        }
        if (a.creationDateTS === b.creationDateTS) {
          return -2;
        }
      }

      return 0;
    });
  }
};

export const fetchParkingTasks = (parkingId, filterParams) => (dispatch, getState) => {
  if (getState().tasksData.isFetchingParkingList) {
    return Promise.reject();
  }

  dispatch({
    type: types.PARKING_TASKS_FETCH_REQUEST,
  });

  return tasksService.getParkingTasks(
    parkingId,
    filterParams.createdById.toString().slice(1),
    filterParams.hostType.toString().slice(1),
    filterParams.state.toString().slice(1),
    filterParams.size,
    filterParams.page,
    filterParams.keyword,
  )
    .then((data) => {
      const parkingTasks = data.tasks;
      sortTasksByAssigned(parkingTasks);

      dispatch({
        type: types.PARKING_TASKS_FETCH_SUCCESS,
        payload: { parkingTasks, tasksPaging: data.paging },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.PARKING_TASKS_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const createTask = (task) => (dispatch, getState) => {
  if (getState().tasksData.isCreating) {
    return;
  }

  dispatch({
    type: types.TASK_CREATE_REQUEST,
  });

  return tasksService.createTask(task)
    .then((newTask) => {
      let { parkingTasks } = getState().tasksData;
      parkingTasks = [newTask, ...parkingTasks];
      sortTasksByAssigned(parkingTasks);

      dispatch({
        type: types.TASK_CREATE_SUCCESS,
        payload: { parkingTasks },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.TASK_CREATE_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const cancelTask = (task) => (dispatch, getState) => {
  if (getState().tasksData.isCanceling) {
    return;
  }

  dispatch({
    type: types.TASK_CANCEL_REQUEST,
  });

  return tasksService.cancelTask(task)
    .then((newTask) => {
      const { parkingTasks } = getState().tasksData;
      const taskIndex = parkingTasks.findIndex((t) => t.taskId === task.taskId);
      parkingTasks[taskIndex] = newTask;
      sortTasksByAssigned(parkingTasks);

      dispatch({
        type: types.TASK_CANCEL_SUCCESS,
        payload: { parkingTasks },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.TASK_CANCEL_FAIL,
        payload: { error },
      });

      throw error;
    });
};

// 
export const renewTask = (task) => (dispatch, getState) => {
  if (getState().tasksData.isRenewing) {
    return;
  }

  dispatch({
    type: types.TASK_RENEW_REQUEST,
  });

  return tasksService.renewTask(task)
    .then((newTask) => {
      const { parkingTasks } = getState().tasksData;
      const taskIndex = parkingTasks.findIndex((t) => t.taskId === task.taskId);
      parkingTasks[taskIndex] = newTask;
      sortTasksByAssigned(parkingTasks);

      dispatch({
        type: types.TASK_RENEW_SUCCESS,
        payload: { parkingTasks },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.TASK_RENEW_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const fetchTaskImages = (taskIds) => (dispatch, getState) => {
  if (getState().tasksData.isFetchingImageList) {
    return Promise.reject();
  }

  dispatch({
    type: types.TASK_IMAGES_FETCH_REQUEST,
  });

  const actions = taskIds.map((taskId) => new Promise((resolve) => {
    tasksService.getTaskImage(taskId)
      .then((taskImages) => {
        resolve(taskImages);
      })
      .catch((error) => {
        console.log('fetchTaskImages: ', error);
        resolve([]);
      });
  }));

  return Promise.all(actions)
    .then((taskImages) => {
      const images = [];
      taskImages.forEach((image) => {
        if (image) {
          images.push(image);
        }
      });

      dispatch({
        type: types.TASK_IMAGES_FETCH_SUCCESS,
        payload: { taskImages: images },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.TASK_IMAGES_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};

export const fetchParkingTasksStatistics = (parkingId, dateFrom, dateTo) => (dispatch, getState) => {
  if (getState().tasksData.isFetchingStatistics) {
    return Promise.reject();
  }

  dispatch({
    type: types.PARKING_TASKS_STATISTICS_FETCH_REQUEST,
  });

  return tasksService.getTasksStatistic(
    parkingId,
    dateFrom,
    dateTo,
  )
    .then((parkingTasksStatistics) => {
      dispatch({
        type: types.PARKING_TASKS_STATISTICS_FETCH_SUCCESS,
        payload: { parkingTasksStatistics },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.PARKING_TASKS_STATISTICS_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};
