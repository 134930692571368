import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import Assignment from '@material-ui/icons/Assignment';
import Payment from '@material-ui/icons/Payment';

import { fetchCardJournals } from 'redux/actions/journals';
import { fetchCardPayment } from 'redux/actions/payment';
import { CustomAccordion, CustomTable, CustomButton } from 'components/elements';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhotoDetail from 'components/modals/PhotoDetail';



const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '55%',
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  wrapper100: {
    width: '100%',
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 1),
    '& .MuiExpansionPanelSummary-root': {
      height: 55,
      background: theme.palette.base[300],
    },
    '& .MuiExpansionPanelSummary-expandIcon .MuiSvgIcon-root': {
      fill: theme.palette.base.white,
    },
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    color: theme.palette.base.white,
  },
  title: {
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
    fontWeight: 'normal',
  },
  cardActions: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(5),

    '& .MuiButton-label': {
      textTransform: 'uppercase',
      fontSize: 16,
    },
  },
  actionButton: {
    marginLeft: theme.spacing(1),

    '& .MuiButton-label': {
      fontSize: 16,
    },
  },
}));

const cellStyle = {
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: '19px',
  color: '#60636B',
  textAlign: 'center',
};

function CardJournals(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isLT,
    selectedCard,
    detailParking,
    cardJournals,
    isFetchingCardJournalList,
    isFetchingCardPaymentList,
    cardPayment,
    handleActivateCard,
    handleBlockCard,
    fetchCardJournals,
    fetchCardPayment,
    userUseCases,
    productMode,
  } = props;

  const [isJournalsLoading, setlsJournalsLoading] = useState(false);
  const [isPaymentsLoading, setIsPaymentsLoading] = useState(false);

  const handleJournalClick = (expanded) => {
    if (isFetchingCardJournalList) {
      return;
    }

    if (
      expanded
      && selectedCard
      && selectedCard.cardId
    ) {
      setlsJournalsLoading(true);
      fetchCardJournals(selectedCard.cardId)
        .then(() => {
          setlsJournalsLoading(false);
        })
        .catch(() => {
          setlsJournalsLoading(false);
        });
    }
  };

  const handlePaymentClick = (expanded) => {
    if (isFetchingCardPaymentList) {
      return;
    }

    if (
      expanded
      && selectedCard
      && selectedCard.cardId
    ) {
      setIsPaymentsLoading(true);
      fetchCardPayment(selectedCard.cardId)
        .then(() => {
          setIsPaymentsLoading(false);
        })
        .catch(() => {
          setIsPaymentsLoading(false);
        });
    }
  };

  const [showPhoto, setShowPhoto] = useState(false);
  const [rowData, setRowData] = useState(null);

  const hadlePhotoClick = (rowData) => () => {
    setShowPhoto(true);
    setRowData(rowData);
  }


  const journalSummary = (
    <div className={classes.summary}>
      <Assignment />
      <h3 className={classes.title}>{t('CARD_JOURNAL')}</h3>
    </div>
  );

  const column = [
    {
      field: 'date',
      title: t('DATE'),
      cellStyle,
    },
    {
      title: t('PHOTO'),
      field: 'photo',
      cellStyle,
      render: (rowData) =>   <>{((rowData.photoKeyPic && rowData.photoKeyPic!=='0000000000000000') || (rowData.photoKeyLpn && rowData.photoKeyLpn!=='0000000000000000')) &&
        <PhotoCameraIcon onClick={hadlePhotoClick(rowData)} className={classes.pointer}/>
      }</>,
    },
    {
      field: 'message',
      title: t('NOTIFICATION'),
      cellStyle,
      render: (rowData) => (
        <>{rowData.message.replace(/\{[^}]*\}/g, (str) => t(str))}</>
      ),
    },
  ];

  const cardJournalsTable = (
    <CustomTable
      title=""
      columns={column}
      data={cardJournals}
      options={{
        maxBodyHeight: 300,
        paging: false,
        pageSize: cardJournals.length,
        headerStyle: {
          padding: '15px 8px 15px 34px',
          lineHeight: '12px',
          color: '#1B1B28',
          textAlign: 'center',
        },
      }}
      isLoading={isJournalsLoading}
    />
  );

  const cardPaymentTable = (
    <CustomTable
      title=""
      columns={column}
      data={cardPayment}
      options={{
        maxBodyHeight: 300,
        paging: false,
        pageSize: cardPayment.length,
        headerStyle: {
          padding: '15px 8px 15px 34px',
          lineHeight: '12px',
          color: '#1B1B28',
          textAlign: 'center',
        },
      }}
      isLoading={isPaymentsLoading}
    />
  );

  const paymentSummary = (
    <div className={classes.summary}>
      <Payment />
      <h3 className={classes.title}>{t('PAYMENT_DETAILS')}</h3>
    </div>
  );

  const { valid, blocked } = detailParking.zones[0];

  return (
    <div className={productMode ? classes.wrapper100 : classes.wrapper}>
      <div className={classes.container}>
        <CustomAccordion
          summary={journalSummary}
          panelDetails={cardJournalsTable}
          handleHeaderClick={handleJournalClick}
        />

        <CustomAccordion
          summary={paymentSummary}
          panelDetails={cardPaymentTable}
          handleHeaderClick={handlePaymentClick}
        />
      </div>

      {!productMode && <div className={classes.actions}>
        <div className={classes.cardActions}>
          {(((isLT && isComponentVisibleForUser(UC_LT_CARD_DEACTIVATE, userUseCases)) || (!isLT && (selectedCard && valid) && isComponentVisibleForUser(UC_ST_CARD_DEACTIVATE, userUseCases)))) && (
            <CustomButton
              className={classes.actionButton}
              label={selectedCard && (valid ? t('DEACTIVATE_CARD') : t('ACTIVATE_CARD'))}
              width={187}
              color={selectedCard && (!valid ? 'green' : 'yellow')}
              onClick={handleActivateCard(valid)}
              disabled={(isLT && !isComponentEnabledForUser(UC_LT_CARD_DEACTIVATE, userUseCases))
              || (!isLT && !isComponentEnabledForUser(UC_ST_CARD_DEACTIVATE, userUseCases))}
            />
          )}
          {((isLT && isComponentVisibleForUser(UC_LT_CARD_BLOCK, userUseCases)) || (!isLT && isComponentVisibleForUser(UC_ST_CARD_BLOCK, userUseCases))) &&
          <CustomButton
                className={classes.actionButton}
                label={selectedCard && (blocked ? t('UNBLOCK_CARD') : t('BLOCK_CARD'))}
                width={187}
                color={selectedCard && (blocked ? 'green' : 'yellow')}
                disabled={!valid || (isLT && !isComponentEnabledForUser(UC_LT_CARD_DEACTIVATE, userUseCases))
                || (!isLT && !isComponentEnabledForUser(UC_ST_CARD_DEACTIVATE, userUseCases))}
                onClick={valid ? handleBlockCard(blocked) : null}
            />
          }
        </div>
      </div>}

      {showPhoto &&
        <PhotoDetail showPhoto={showPhoto} setShowPhoto={setShowPhoto} rowData={rowData} setRowData={setRowData}/>
      }
    </div>
  );
}

CardJournals.propTypes = {
  isLT: PropTypes.bool.isRequired,
  selectedCard: PropTypes.object.isRequired,
  detailParking: PropTypes.object.isRequired,
  cardJournals: PropTypes.array.isRequired,
  cardPayment: PropTypes.array.isRequired,
  isFetchingCardJournalList: PropTypes.bool.isRequired,
  isFetchingCardPaymentList: PropTypes.bool.isRequired,
  handleActivateCard: PropTypes.func.isRequired,
  handleBlockCard: PropTypes.func.isRequired,
  fetchCardJournals: PropTypes.func.isRequired,
  fetchCardPayment: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  cardJournals: store.journalsData.cardJournals,
  isFetchingCardJournalList: store.journalsData.isFetchingCardJournalList,
  cardPayment: store.paymentData.cardPayment,
  isFetchingCardPaymentList: store.paymentData.isFetchingCardPaymentList,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCardJournals,
  fetchCardPayment,
}, dispatch);


export const UC_ST_CARD_BLOCK = 'UC0032';
export const UC_LT_CARD_BLOCK = 'UC0024';

export const UC_ST_CARD_DEACTIVATE = 'UC0031';
export const UC_LT_CARD_DEACTIVATE = 'UC0023';


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CardJournals);
