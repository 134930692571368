import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment/min/moment-with-locales';

import {CustomTable} from 'components/elements';
import {EventType} from 'components/common';
import CardsToolbar from './ToolBar';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CardDetails from "../../CardDetails";


const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
    },
    expired: {
        color: `${theme.palette.primary.red}`,
    },
    valid: {
        color: `${theme.palette.primary.main}`,
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mr: {
        marginRight: theme.spacing(2.5),
    },
    cardNo: {
        cursor: 'pointer',
    },
    lock: {
        marginTop: 6,
    },
    inactive: {
        color: '#ADB0B8',
    },
}));

const cellStyle = {
    padding: '4px 4px',
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

function CardsTable(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        parking,
        cardData,
        isLoading,
        validTill,
        validity,
        blocked,
        cardsPaging,
        handleCardDetail,
        handleSelectAll,
        handleCheck,
        handleActivate,
        handleBlock,
        handleValidTill,
        handleValidity,
        handleFilterBlocked,
        handleChangeRowsPerPage,
        handleChangePage,
        userUseCases,
        selectedCard,
        setSelectedCard,
        lpn,
        handleLPN,
        cardNumber,
        handleCardNumber,
        parkings,
    } = props;


    let columns = [
        {
            title: t('CARD_NUMBER'),
            field: 'cardNo',
            cellStyle,
            render: (rowData) => (
                rowData.cardNo
            ),
        },
        {title: t('OWNER'), field: 'owner', cellStyle},
        {
            title: t('VALIDITY'),
            field: 'valid',
            cellStyle,
            render: (rowData) => (
                <EventType type={rowData.valid ? 'valid' : 'invalid'}/>
            ),
        },
        {
            title: t('BLOCKED'),
            field: 'blocked',
            cellStyle,
            render: (rowData) => (rowData.blocked ? (
                <LockIcon className={classnames(classes.lock, rowData.valid ? '' : classes.inactive)} color="error"/>
            ) : (
                <LockOpenIcon className={classnames(classes.lock, rowData.valid ? '' : classes.inactive)}
                              color="primary"/>
            )),
        },
        {
            title: t('VALID_TILL'),
            field: 'validDateTS',
            cellStyle,
            render: (rowData) => (
                <>
                    {rowData.validDateTS
                        ? moment(new Date(rowData.validDateTS)).locale(i18next.language).format('L LTS')
                        : ''}
                </>
            ),
        },
    ];


    return (
        <div className={classes.container}>
            <Box my={1}>
                <CardsToolbar
                    validTill={validTill}
                    validity={validity}
                    blocked={blocked}
                    handleValidTill={handleValidTill}
                    handleValidity={handleValidity}
                    handleBlock={handleFilterBlocked}
                    userUseCases={userUseCases}
                    lpn={lpn}
                    cardNumber={cardNumber}
                    handleCardNumber={handleCardNumber}
                    handleLPN={handleLPN}
                />
            </Box>

            <Grid container spacing={2} style={{flex: 1, height: '80%'}}>
                <Grid item md={selectedCard ? 7 : 12}>
                    <Paper className={classes.table}>
                        <Box p={3} height={1}>
                            <CustomTable
                                title=""
                                columns={columns}
                                data={cardData}
                                options={{
                                    headerStyle: {
                                        padding: '0 8px 0 34px',
                                        borderTop: '1px solid #DEE2E5',
                                        lineHeight: '12px',
                                        color: '#1B1B28',
                                        textAlign: 'center',
                                    },
                                    rowStyle: rowData => ({
                                        backgroundColor: (selectedCard && selectedCard.tableData.id === rowData.tableData.id) ? '#5985EE' : '#FFF'
                                    })
                                }}
                                isLoading={isLoading}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                handleChangePage={handleChangePage}
                                rowsPerPage={cardsPaging.pageSize}
                                pageNumber={cardsPaging.currentPage}
                                count={cardsPaging.totalSize}
                                loadAllData
                                onRowClick={((evt, selectedCard) => {
                                    setSelectedCard(selectedCard);
                                })}
                            />
                        </Box>
                    </Paper>
                </Grid>
                <Grid item md={5}>
                    {!!selectedCard ? (
                        <Paper className={classes.table}>
                            <Box p={2} style={{height: '100%', width: '100%'}}>
                                <CardDetails card={selectedCard}
                                             parkings={parkings}
                                             productMode={true}
                                             userUseCases={userUseCases}
                                />
                            </Box>
                        </Paper>) : null}
                </Grid>
            </Grid>
        </div>
    );
}

CardsTable.propTypes = {
    parking: PropTypes.object.isRequired,
    cardType: PropTypes.string.isRequired,
    cardData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectAll: PropTypes.bool.isRequired,
    selected: PropTypes.array.isRequired,
    validTill: PropTypes.object,
    validity: PropTypes.array.isRequired,
    blocked: PropTypes.array.isRequired,
    zone: PropTypes.array.isRequired,
    cardsPaging: PropTypes.object.isRequired,
    parkingCardNumbers: PropTypes.array.isRequired,
    handleCreateCardOpen: PropTypes.func.isRequired,
    handleCardDetail: PropTypes.func.isRequired,
    handleSelectAll: PropTypes.func.isRequired,
    handleCheck: PropTypes.func.isRequired,
    handleEditCardModal: PropTypes.func.isRequired,
    handleActivate: PropTypes.func.isRequired,
    handleBlock: PropTypes.func.isRequired,
    handleValidTill: PropTypes.func.isRequired,
    handleValidity: PropTypes.func.isRequired,
    handleFilterBlocked: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleZone: PropTypes.func.isRequired,
    handleChangeRowsPerPage: PropTypes.func.isRequired,
    handleChangePage: PropTypes.func.isRequired,
};

CardsTable.defaultProps = {
    validTill: null,
};

const UC_LT_CARD_SEARCH = 'UC0021';
const UC_LT_CARD_BULK_EDIT = 'UC0022';

const UC_LT_CARD_DETAIL = 'UC0025';
const UC_LT_CARD_OWNER_CHANGE = 'UC0028';

const UC_ST_CARD_SEARCH = 'UC0030';
const UC_ST_CARD_DETAIL = 'UC0033';


export default CardsTable;
