import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import {ActionButton} from 'components/common';
import PMCSnackbar from "../../common/Snackbar";
import ParkingDetail from "./ParkingDetail";
import SettingsInputHdmiIcon from "@material-ui/icons/SettingsInputHdmi";
import {getErrorMessage, renderConnectionStatus} from "../../../utils";
import CancelIcon from '@material-ui/icons/Cancel';
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    contentGrey: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
        background: theme.palette.base[200],

    },
    root: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    root1: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
        paddingBottom: '24px',
    },
    button: {
        width: 200,
        height: 55,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 25,
    },
    buttonC: {
        width: 300,
        height: 55,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 25,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));

const FORM_NOT_CHANGED = 0;
const BASIC_FIELD_CHANGED = 1;
const PROTOCOL_PROPERTY_CHANGED = 2;
const TESTED = 3;


function Parking(props) {
    const {
        userUseCases,
        subjects,
        roles,
        handleClose,
        readOnly,
        parkingConfigurationModel,
        connect,
        parkingUsers,
        terminate,
        sseParkings,
        createParking,
        updateParking,
        setIsFetchedParkings
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updatedParking, setUpdatedParking] = useState();
    const [errorStep1, setErrorStep1] = useState({});
    const [mode, setMode] = useState('COMMON');
    const [connected, setConnected] = useState(true);
    const [terminating, setTerminating] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [saving, setSaving] = useState(false);

    const [saveEnabled, setSaveEnabled] = useState(FORM_NOT_CHANGED);


    const setFormUpdatable = (protocolField) => {
        if (protocolField) {
            setSaveEnabled(PROTOCOL_PROPERTY_CHANGED);
        } else if (saveEnabled != PROTOCOL_PROPERTY_CHANGED) {
            setSaveEnabled(BASIC_FIELD_CHANGED);
        }
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('error');
    const [sseParking, setSseParking] = useState(null);


    const connectInner = (parking) => {
        connect(parking).then((prod) => {
            setConnecting(false);
            setSaveEnabled(TESTED);
            setSeverity("success");
            setSnackbarOpen(true);
            setSnackbarMessage(t('CONNECTION_TEST_SUCC'));

        }).catch((error) => {
            setSnackbarMessage(getErrorMessage(error, t));
            setSaveEnabled(TESTED);
            setSeverity("error");
            setSnackbarOpen(true);
            setConnecting(false);
        });

    }


    const terminateInner = (parking) => {
        terminate(parking).then((prod) => {
            setTerminating(false);
        }).catch((error) => {
            setSnackbarMessage(getErrorMessage(error, t));
            setSeverity("error");
            setSnackbarOpen(true);
            setTerminating(false);
        });

    }

    const saveParking = (parking) => {
        const action = (parking && (parking.parkingId || parking.parkingId == 0)) ? updateParking : createParking;
        action(parking).then((prod) => {
            setSaving(false);
            setIsFetchedParkings(false);
            handleClose();
        }).catch((error) => {
            setSnackbarMessage(getErrorMessage(error, t));
            setSeverity("error");
            setSnackbarOpen(true);
            setSaving(false);
        });
    };


    const actionWithCheck = (action, setActionInProgress, parking) => {
        if (terminating || connecting || saving) {
            return;
        }
        setActionInProgress(true);
        action(parking);
    }


    useEffect(() => {
        if (parkingConfigurationModel.parking) {
            setUpdatedParking(parkingConfigurationModel.parking);
            const sseParking = sseParkings.find(a => a.parkingId === parkingConfigurationModel.parking.parkingId);
            setSseParking(sseParking);
            setConnected(sseParking ? sseParking.communicationStatus === 'OK' : false);

        }
        setErrorStep1({});
        setMode('COMMON');
        setSaveEnabled(FORM_NOT_CHANGED);
        setSeverity("error");
        setSnackbarOpen(false);
        setSnackbarMessage('');

    }, [parkingConfigurationModel]);


    useEffect(() => {
        if (parkingConfigurationModel.parking) {
            const sseParking = sseParkings.find(a => a.parkingId === parkingConfigurationModel.parking.parkingId);
            setSseParking(sseParking);
            setConnected(sseParking ? sseParking.communicationStatus === 'OK' : false);

        }
    }, [sseParkings]);


    const [usersValues, setUsersValues] = useState([]);


    useEffect(() => {
        if (parkingUsers && updatedParking && updatedParking.subjectId) {
            setUsersValues(parkingUsers.filter(a => a.subjectId == updatedParking.subjectId).map(a => {
                return {value: a.keycloakId, label: a.username, key: a.keycloakId}
            }));
        } else {
            setUsersValues([]);
        }
    }, [parkingUsers, updatedParking]);

    const [subjectValues, setSubjectValues] = useState([]);


    useEffect(() => {
        if (subjects) {
            setSubjectValues(subjects.map(a => {
                return {value: a.subjectId, label: a.name, key: a.subjectId}
            }));
        }
    }, [subjects]);


    const getParkingSubject = () => {
        if (updatedParking && subjectValues) {
            const subject = subjectValues.find(a => a.key == updatedParking.subjectId);
            if (subject) {
                return subject.label;
            }
        }
        return '';
    }


    const handleSubject = (role) => {
        handleChange('subjectId')(role.value);
        setFormUpdatable(false);
    };

    const handleUsers = (users) => {
        handleChange('users', false)(users.map(a => a.value));
    };


    const isValid = () => {
        let ret = true;
        const error = {};
        if (!updatedParking.color) {
            error.color = 'required';
            ret = false;
        }
        if (!updatedParking.parkingName || updatedParking.parkingName.trim().length === 0) {
            error.parkingName = 'required';
            ret = false;
        }
        if (!updatedParking.subjectId) {
            error.subjectId = 'required';
            ret = false;
        }
        if (!updatedParking.address || updatedParking.address.trim().length === 0) {
            error.address = 'required';
            ret = false;
        }
        if (!updatedParking.email || updatedParking.email.trim().length === 0) {
            error.email = 'required';
            ret = false;
        }
        if (!updatedParking.phone || updatedParking.phone.trim().length === 0) {
            error.phone = 'required';
            ret = false;
        }
        if (!updatedParking.helpdeskPhone || updatedParking.helpdeskPhone.trim().length === 0) {
            error.helpdeskPhone = 'required';
            ret = false;
        }

        if (updatedParking.values) {
            for (let i = 0; i < updatedParking.values.length; i++) {
                let subEr = {};
                if (!updatedParking.values[i].parkingKey || updatedParking.values[i].parkingKey.length === 0) {
                    subEr.parkingKey = 'required';
                }
                if (!updatedParking.values[i].parkingKey || updatedParking.values[i].parkingKey.length === 0) {
                    subEr.parkingValue = 'required';
                }
                if (subEr.parkingKey || subEr.parkingValue) {
                    if (!error.values) {
                        error.values = {};
                    }
                    error.values[i] = subEr;
                }
            }
        }

        const conRet = isValidForTryConnection(error);
        return ret && conRet;
    }

    const isConnectionTypeSelected = () => {
        return updatedParking.connectionType && updatedParking.connectionType.trim().length >= 0;
    }


    const isValidForTryConnection = (error) => {
        let ret = true;
        if (updatedParking.connectionType) {
            if ( updatedParking.gmtpIdent===undefined || updatedParking.gmtpIdent===null || updatedParking.gmtpIdent < 0) {
                error.gmtpIdent = 'required';
                ret = false;
            }
        }

        if (updatedParking.connectionType === 'GMTP') {
            if (!updatedParking.applicationName || updatedParking.applicationName.trim().length === 0) {
                error.applicationName = 'required';
                ret = false;
            }
            if (!updatedParking.clientIp || updatedParking.clientIp.trim().length === 0) {
                error.clientIp = 'required';
                ret = false;
            }
            if (!updatedParking.logServerIp || updatedParking.logServerIp.trim().length === 0) {
                error.logServerIp = 'required';
                ret = false;
            }
            if (!updatedParking.clientPort || updatedParking.clientPort < 0) {
                error.clientPort = 'required';
                ret = false;
            }
            if (!updatedParking.logServerPort || updatedParking.logServerPort < 0) {
                error.logServerPort = 'required';
                ret = false;
            }
            if (!updatedParking.timeOut || updatedParking.timeOut < 0) {
                error.timeOut = 'required';
                ret = false;
            }
            if (!updatedParking.userName || updatedParking.userName.trim().length === 0) {
                error.userName = 'required';
                ret = false;
            }
            if (!updatedParking.serverIp || updatedParking.serverIp.trim().length === 0) {
                error.serverIp = 'required';
                ret = false;
            }
            if (!updatedParking.userPassword || updatedParking.userPassword.trim().length === 0) {
                error.userPassword = 'required';
                ret = false;
            }
            if (!updatedParking.serverPort || updatedParking.serverPort < 0) {
                error.serverPort = 'required';
                ret = false;
            }
        }
        setErrorStep1(error)
        return ret;
    }


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const getSubject = (parkingSubject, subjects) => {
        if (parkingSubject) {
            const p = subjects && subjects.find(b => b.value == parkingSubject);
            if (!p) {
                return {label: 'Not Found', value: parkingSubject};
            }
            return p;
        }
        return null;
    }

    const handleNumberChange = (name, protocolField) => (event) => {
        // setUpdatedParking({...updatedParking, [name]: parseInt(event.target.value)});
        setUpdatedParking({...updatedParking, [name]: event.target.value});
        setFormUpdatable(protocolField);

    }


    const handleChange = (name, protocolField) => (event) => {
        if (event && event.target) {
            setUpdatedParking({...updatedParking, [name]: event.target.value, 'controlDate': new Date()});
        } else if (event.hex) {
            setUpdatedParking({...updatedParking, color: event.hex, 'controlDate': new Date()});
        } else {
            if ( name === 'subjectId') {
                setUpdatedParking({...updatedParking, [name]: event, 'controlDate': new Date(), 'users':[]});
            } else {
                setUpdatedParking({...updatedParking, [name]: event, 'controlDate': new Date()});
            }
        }
        setFormUpdatable(protocolField);
    }

    const addKeyValue = () => {
        setUpdatedParking({
            ...updatedParking,
            values: [...(updatedParking.values || []), {parkingKey: '', parkingValue: ''}]
        });
        setFormUpdatable(false);
    }

    const removeKeyValue = (index) => {
        const newValues = [...updatedParking.values];
        newValues.splice(index, 1);
        setUpdatedParking({...updatedParking, values: newValues});
        setFormUpdatable(false);
    }

    const updateKeyOrValue = (index, newValue, property) => {
        const newValues = [...updatedParking.values];
        const item = {...updatedParking.values[index]};
        item[property] = newValue;
        newValues[index] = item;
        setUpdatedParking({...updatedParking, values: newValues});
        setFormUpdatable(false);
    }


    return (
        <Dialog
            className={classes.container}
            open={parkingConfigurationModel.parkingDetailOpen}
            onClose={handleClose}
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
                <span>
                    {t(parkingConfigurationModel.isNew ? 'CREATE_PARKING' : 'UPDATE_PARKING')}
                </span>
                <div className={classes.value}>
                    {updatedParking && renderConnectionStatus(updatedParking.connectionType, sseParking ? sseParking.communicationStatus : "NOK", classes, t)}
                </div>
            </DialogTitle>

            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity={severity}
                         message={snackbarMessage}/>

            <DialogContent
                className={(mode !== 'COMMON') ? classes.contentGrey : classes.content}>
                <div className={classes.container}>
                    <ParkingDetail handleChange={handleChange}
                                   userUseCases={userUseCases}
                                   updatedParking={updatedParking}
                                   subjectValues={subjectValues}
                                   usersValues={usersValues}
                                   error={errorStep1}
                                   isValid={isValid}
                                   handleUsers={handleUsers}
                                   subjects={subjects}
                                   setMode={setMode}
                                   mode={mode}
                                   handleSubject={handleSubject}
                                   readOnly={readOnly}
                                   getSubject={getSubject}
                                   setSnackbarError={setSnackbarMessage}
                                   setSnackbarOpen={setSnackbarOpen}
                                   updateKeyOrValue={updateKeyOrValue}
                                   removeKeyValue={removeKeyValue}
                                   addKeyValue={addKeyValue}
                                   handleNumberChange={handleNumberChange}
                                   isConnectionTypeSelected={isConnectionTypeSelected}
                                   connected={connected}
                    />

                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>
                    <div className={classes.wrapper}>

                        <ActionButton
                            action="close"
                            handleClick={handleClose}
                            className={classes.button}
                        />
                    </div>

                </div>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        <ActionButton
                            disabled={!connected || connecting || terminating}
                            muiIcon={<CancelIcon/>}
                            action="terminate"
                            loading={terminating}
                            handleClick={() => {
                                actionWithCheck(terminateInner, setTerminating, updatedParking);
                            }}
                            className={classes.buttonC}
                        />
                        {terminating && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    </div>
                    {updatedParking && updatedParking.connectionType === 'GMTP' &&
                        <div className={classes.wrapper}>
                            <ActionButton
                                disabled={connecting || terminating || connected || !updatedParking || !updatedParking.connectionType || updatedParking.connectionType.trim().length === 0}
                                muiIcon={<SettingsInputHdmiIcon/>}
                                action="connectionTest"
                                handleClick={() => {
                                    const error = {};
                                    const ret = isValidForTryConnection(error);
                                    if (ret) {
                                        actionWithCheck(connectInner, setConnecting, updatedParking);
                                    }
                                }}
                                className={classes.buttonC}
                            />
                            {connecting && <CircularProgress size={24} className={classes.buttonProgress}/>}
                        </div>

                    }
                    <div className={classes.wrapper}>
                        <ActionButton
                            disabled={
                                !updatedParking
                                || readOnly
                                || connecting
                                || terminating
                                || saving
                                || (saveEnabled === PROTOCOL_PROPERTY_CHANGED && updatedParking.connectionType === 'GMTP')
                                || saveEnabled === FORM_NOT_CHANGED
                            }
                            loading={saving}
                            action="save" handleClick={() => {
                            const ret = isValid();
                            if (ret) {
                                actionWithCheck(saveParking, setSaving, updatedParking);
                            }
                        }}
                            className={classes.button}
                        />
                        {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    </div>


                </div>

            </DialogActions>
        </Dialog>

    );
}

Parking.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Parking);


