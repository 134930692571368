import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Done from '@material-ui/icons/Done';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiListItemText-primary': {
      color: (props) => props.color,
      fontSize: 14,
    },
  },
  active: {
    position: 'absolute',
    top: -12,
    fontSize: 12,
  },
}));

const   MuiTheme = (color)=> {
  return createMuiTheme({
    overrides: {
      MuiSelect: {
        icon: {
          fill: '#5985EE',

          '&:hover': {
            fill: '#1F58EE',
          },
        },
      },
      MuiFormControl: {
        root: {
          width: 160,
          minWidth: 160,
          maxWidth: 800,
          marginTop: 16,
          marginLeft: 10,
          // marginRight: 30,
          '& .Mui-disabled svg': {
            fill: '#ADB0B8',

            '&:hover': {
              fill: '#ADB0B8',
            },
          },
        },
      },
      MuiInputBase: {
        root: {
          '& .Mui-disabled label': {
            color: '#ADB0B8',
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: color,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          '& > svg': {
            width: 15,
            height: 15,
            cursor: 'pointer',

            '&:hover': {
              fill: '#1F58EE',
            },
          },
        },
      },
      MuiMenuItem: {
        root: {
          color: '#5985EE',

          '&:hover': {
            color: 'white',
            backgroundColor: '#719DEE',
          },

          '&$selected': {
            color: 'white',
            backgroundColor: '#5985EE',

            '&:hover': {
              backgroundColor: '#719DEE',
            },
          },

          '& > div': {
            flex: 'unset',
            width: 190,
          },

          '& > div span': {
            fontSize: 14,
            whiteSpace: 'initial',
            wordBreak: 'break-all',
          },
        },
      },
    },
  });
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function CustomMultiSelect(props) {
  const {
    items,
    label,
    disabled,
    value,
    className,
    handleChange,
    fromGroupe,
    color
  } = props;
  const classes = useStyles({color: color || '#5985EE'} );

  const { t } = useTranslation();

  const onChange = (event) => {
    handleChange(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        marginTop: 50,
      },
    },
    className: classes.menu,
  };

  const handleSetDefault = (e) => {
    if (disabled) {
      return;
    }

    e.stopPropagation();
    if ( fromGroupe )
      handleChange([]);
    else
      handleChange(['']);
  };

  if (fromGroupe) {
    return (
      <MuiThemeProvider theme={MuiTheme(color || '#5985EE')}>
        <FormControl className={className}>
          <Select
            multiple
            value={value}
            onChange={onChange}
            input={<Input />}
            renderValue={() => (
              <InputLabel>
                {value.length === 1 ? value : t('SELECTED_VALUES')}
                <HighlightOff onClick={(e) => handleSetDefault(e)} />
              </InputLabel>
            )}
            MenuProps={MenuProps}
            disabled={disabled}
          >
            {items.map((item) => (
              <MenuItem
                key={item.key}
                value={item.value}
              >
                <ListItemText primary={item.label} />
                {value.indexOf(item.value) !== -1 && (
                  <Done />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MuiThemeProvider>
    );
  } else {
    return (
        <MuiThemeProvider theme={MuiTheme(color || '#5985EE')}>
        <FormControl className={className}>
          <Select
            multiple
            value={value}
            onChange={onChange}
            input={<Input />}
            renderValue={() => (
              <InputLabel>
                {value.length > 1 && (
                  <div className={classes.active}>{label}</div>
                )}
                {value.length === 1 ? label : t('FILTER_ACTIVE')}
                <HighlightOff onClick={(e) => handleSetDefault(e)} />
              </InputLabel>
            )}
            MenuProps={MenuProps}
            disabled={disabled}
          >
            {items.map((item) => (
              <MenuItem
                key={item.key}
                value={item.value}
              >
                <ListItemText primary={item.label} />
                {value.indexOf(item.value) !== -1 && (
                  <Done />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MuiThemeProvider>
    );
  }

}

CustomMultiSelect.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.array,
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

CustomMultiSelect.defaultProps = {
  items: [],
  label: '',
  disabled: false,
  value: [],
  className: null,
};

export default CustomMultiSelect;
