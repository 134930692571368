import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {CustomSwitch, Loading} from 'components/elements';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import {fetchProductTemplateParamSelect} from 'redux/actions/products';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import CustomDateTimePicker from "../../../elements/CustomDateTimePicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextInnerField from "../TextInnerField";
import DateStep from "../../../elements/DateStep";
import { getLocaleDateTimeString } from 'i18n';
import i18next from 'i18next';


const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    labelPlacementTop: {
        marginLeft: 0,
    },
    root: {
        alignItems: 'flex-start',
    }

}));

const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

function WizardStep3Table(props) {
    const classes = useStyles();

    const {
        withCard,
        cardNumber,
        setCardNumber,
        product,
        productTemplates,
        error,
        fetchProductTemplateParamSelect,
        productTemplateSelectValues,
        handleParamDefaultValueChange,
        readOnly,
        submit
    } = props;

    const {t} = useTranslation();


    const [mergedValues, setMergedValues] = useState();

    const [first, setFirst] = useState(true);

    useEffect(()=>{
        if ( mergedValues && mergedValues.length===0 ) {
            setFirst(false);
            submit();
        }
    }, [mergedValues])

    let paramRefs = [];

    const changeFocus = (index) => {
        if (index === (mergedValues.length - 1)) {
            setFirst(false);
            submit();
            return;
        }
        if (paramRefs[index + 1]) {
            paramRefs[index + 1].focus();
        }
    }

    const loadSelectValues = (productTemplateParams, parkingId, productType) => {
        const actions = [];
        for (let i = 0; i < productTemplateParams.length; i++) {
            if (productTemplateParams[i].paramType === 'PRODUCT_TYPE_SELECT' && productTemplateParams[i].visible) {
                actions.push(productTemplateParams[i].paramName);
            }
        }
        if (productType !== 'MP') {
            fetchProductTemplateParamSelect(actions, [parkingId]);
        } else {
            fetchProductTemplateParamSelect([], [], actions);

        }
    }

    const getProductValueMultiSelect = (productParam) => {
        const options = [];
        if (productParam.paramType === 'PRODUCT_TYPE_MACRO_SELECT') {
            for (let j = 0; j < productParam.macroSelect.length; j++) {
                options.push({
                    label: productParam.macroSelect[j].label,
                    value: productParam.macroSelect[j].productParamMacroId ? productParam.macroSelect[j].productParamMacroId : productParam.macroSelect[j].label,

                });
            }


        }
        return options;
    }


    useEffect(() => {
        if (product && productTemplates) {
            const values = [];
            const template = productTemplates.find(a => a.productTemplateId === product.productTemplateId);
            if (!template) {
                return;
            }
            for (let i = 0; i < product.productParam.length; i++) {
                const templateParam = template.productTemplateParams && template.productTemplateParams.find(a => a.paramName === product.productParam[i].paramName);
                if (!templateParam) {
                    continue;
                }
                const required = templateParam.required && templateParam.required === true;
                if (product.productParam[i].visible) {
                    values.push({
                        paramType: product.productParam[i].paramType,
                        paramLabel: product.productParam[i].paramLabel,
                        paramName: product.productParam[i].paramName,
                        productValueNumber: product.productParam[i].valueNumber,
                        productValueVarchar: product.productParam[i].valueVarchar,
                        productValueDate: product.productParam[i].valueDate,
                        productValueBoolean: product.productParam[i].valueBoolean || false,
                        productValueSelect: product.productParam[i].valueSelect,
                        productValueMultiSelect: product.productParam[i].valueMultiSelect,
                        productEditable: product.productParam[i].editable,
                        productVisible: product.productParam[i].visible,
                        stepper: product.productParam[i].stepper,
                        precision: templateParam.precision,
                        required,
                        multiValue: templateParam.multiValue,
                        order: product.productParam[i].order,
                        parkingId: product.parkingId,
                        productValueMultiSelectOptions: getProductValueMultiSelect(product.productParam[i])

                    });
                }
            }
            loadSelectValues(product.productParam, product.parkingId, template.productTemplateType);
            setMergedValues(values.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)));

        }
    }, [product, productTemplates]);


    const getSelectValues = (rowData, items) => {
        if (rowData.multiValue) {
            return rowData.productValueSelect ?
                rowData.productValueSelect.map(a => {
                    const p = items && items.find(b => b.value == a);
                    if (!p) {
                        return {label: 'Not Found', value: a};
                    }
                    return p;
                }) :
                [];
        } else {
            if (rowData.productValueSelect && rowData.productValueSelect[0]) {
                const p = items && items.find(b => b.value == rowData.productValueSelect[0]);
                if (!p) {
                    return {label: 'Not Found', value: rowData.productValueSelect[0]};
                }
                return p;
            }
            return null;
        }
    }


    const
        defaultComponent = useCallback((rowData, readOnly, index) => {
            switch (rowData.paramType) {
                case 'PRODUCT_TYPE_NUMBER':
                    return <TextInnerField
                        type="number"
                        inputRef={el => (paramRefs = [...paramRefs, el])}
                        required={rowData.required}
                        helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                        label={rowData.paramLabel}
                        rowData={rowData}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                changeFocus(index);
                            }
                        }}
                        disabled={rowData.productEditable === false || readOnly}
                        initialValue={rowData.productValueNumber}
                        onBlur={handleParamDefaultValueChange}
                    />;
                case 'PRODUCT_TYPE_TEXT':
                    return <TextInnerField
                        rowData={rowData}
                        inputRef={el => (paramRefs = [...paramRefs, el])}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                changeFocus(index);
                            }
                        }}
                        disabled={rowData.productEditable === false || readOnly}
                        initialValue={rowData.productValueVarchar}
                        onBlur={handleParamDefaultValueChange}
                        label={rowData.paramLabel}
                        required={rowData.required}
                        helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}/>;
                case 'PRODUCT_TYPE_DATE':
                    if (rowData.stepper) {
                        return <DateStep
                            inputRef={el => (paramRefs = [...paramRefs, el])}
                            onKeyPressHandler={(event) => {
                                if (event.key === 'Enter') {
                                    changeFocus(index);
                                }
                            }}
                            value={rowData.productValueVarchar}
                            label={rowData.paramLabel}
                            required={rowData.required}
                            disabled={rowData.productEditable === false || readOnly}
                            handleChange={handleParamDefaultValueChange(rowData.paramName)}
                            helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                                ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                : null}>
                        </DateStep>
                    } else {
                        return <CustomDateTimePicker inputRef={el => (paramRefs = [...paramRefs, el])}
                                                     onKeyPress={(event) => {
                                                         if (event.key === 'Enter') {
                                                             changeFocus(index);
                                                         }
                                                     }}
                                                     disabled={rowData.productEditable === false || readOnly}
                                                     pure={true}
                                                     required={rowData.required}
                                                     handleChange={handleParamDefaultValueChange(rowData.paramName)}
                                                     fullWidth={true}
                                                     value={rowData.productValueDate || null}
                                                     label={rowData.paramLabel}
                                                     helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                                                         ? <span
                                                             className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                         : null}
                                                         format={getLocaleDateTimeString(i18next.language)}
                        >
                        </CustomDateTimePicker>
                    }
                case 'PRODUCT_TYPE_BOOLEAN':
                    return <FormControlLabel
                        classes={{
                            labelPlacementTop: classes.labelPlacementTop,
                            root: classes.root,
                        }}
                        control={<CustomSwitch
                            inputRef={el => (paramRefs = [...paramRefs, el])}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    changeFocus(index);
                                }
                            }}
                            disabled={rowData.productEditable === false || readOnly}
                            color="secondary" checked={rowData.productValueBoolean === true}
                            handleSwitch={handleParamDefaultValueChange(rowData.paramName)}/>}
                        label={<span
                            style={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: 'rgba(0, 0, 0, 0.54)',
                            }}>{rowData.paramLabel}</span>}
                        labelPlacement="top"
                    />
                case 'PRODUCT_TYPE_SELECT':
                    const items = productTemplateSelectValues[`${rowData.paramName}${rowData.parkingId || rowData.parkingId === 0 ? rowData.parkingId : ''}`];
                    if (items) {
                        return <Autocomplete
                            multiple={rowData.multiValue}
                            disabled={rowData.productEditable === false || readOnly}
                            limitTags={3}
                            value={getSelectValues(rowData, items)}
                            onChange={(event, newValue) => {
                                handleParamDefaultValueChange(rowData.paramName)(newValue);

                            }}
                            filterSelectedOptions
                            id={rowData.paramName}
                            options={items}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            style={{width: '100%'}}
                            renderInput={(params) => (
                                <TextField {...params} label={rowData.paramLabel} required={rowData.required} fullWidth
                                           onKeyPress={(event) => {
                                               if (event.key === 'Enter') {
                                                   changeFocus(index);
                                               }
                                           }}
                                           inputRef={el => (paramRefs = [...paramRefs, el])}
                                           helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                                               ? <span
                                                   className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                               : null}

                                />
                            )}
                            size="small"

                        />

                    } else {
                        return <Loading/>
                    }
                case 'PRODUCT_TYPE_MACRO_SELECT':
                    return <Autocomplete
                        disabled={rowData.productEditable === false || readOnly}
                        value={getSelectValues(rowData, rowData.productValueMultiSelectOptions)}
                        onChange={(event, newValue) => {
                            handleParamDefaultValueChange(rowData.paramName)(newValue);

                        }}
                        filterSelectedOptions
                        id={rowData.paramName}
                        options={rowData.productValueMultiSelectOptions}
                        getOptionLabel={(option) => {
                            return option.label;
                        }}
                        style={{width: '100%'}}
                        renderInput={(params) => (
                            <TextField {...params} label={rowData.paramLabel} required={rowData.required} fullWidth
                                       onKeyPress={(event) => {
                                           if (event.key === 'Enter') {
                                               changeFocus(index);
                                           }
                                       }}
                                       inputRef={el => (paramRefs = [...paramRefs, el])}
                                       helperText={error && error[rowData.paramName] && error[rowData.paramName].defaultValue
                                           ? <span
                                               className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                           : null}

                            />
                        )}
                        size="small"

                    />


                default:
                    return <div>Not Supported</div>;
            }


        }, [product, productTemplateSelectValues, error]);

    useEffect(() => {
        if (first && paramRefs[0]) {
            setFirst(false);
            paramRefs[0] && paramRefs[0].focus();
        }
    })

    return (
        <div className={classes.formFields}>
            <form autoComplete='off'>
                <Box p={3}>
                    <Grid container spacing={3}>
                        {withCard && product && product.productTemplateType === 'MP' &&
                        <Grid item sm={12}>
                            <TextField
                                type="number"
                                id="cardNumber"
                                value={cardNumber || ''}
                                onChange={(event) => {
                                    setCardNumber(event.target.value)
                                }}
                                label={t('CARD_NUMBER')}
                                required
                                helperText={error && error['cardNumber']
                                    ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}

                            />
                        </Grid>
                        }
                        {mergedValues && mergedValues.map((row, index) => {
                                return <Grid item sm={6}>
                                    {defaultComponent(row, readOnly, index)}
                                </Grid>
                            }
                        )}
                    </Grid>
                </Box>
            </form>
        </div>
    );
}

WizardStep3Table.propTypes = {};

WizardStep3Table.defaultProps = {};

const mapStateToProps = (store) => ({
    productTemplateSelectValues: store.productsData.productTemplateSelectValues,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchProductTemplateParamSelect,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WizardStep3Table);


