import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';
import ToolBar from './ToolBar';
import Typography from "@material-ui/core/Typography";
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import PlayList from "./PlayList";
import {fetchPlayLists, createPlayList, updatePlayList, deletePlayList} from "../../../redux/actions/playlists";
import {fetchParkingHostsBySubject} from "../../../redux/actions/hosts";
import {Grid} from '@material-ui/core';
import {ToggleButton} from '@material-ui/lab';
import TocIcon from '@material-ui/icons/Toc';
import PlayListModal from 'components/modals/PlayList';

const HOST_TYPE_APM = 5;

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    title: {
        marginBottom: theme.spacing(3.75),
        marginTop: theme.spacing(3.75),
        textTransform: 'uppercase',
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(4),
    },
}));


function PlayListAdministration(props) {
    const {
        userUseCases,
        parkings,
        playLists, fetchPlayLists, createPlayList, updatePlayList, deletePlayList,
        hosts, fetchParkingHostsBySubject,
    } = props;


    const classes = useStyles();
    const {t} = useTranslation();

    const [isFetchedPlayList, setIsFetchedPlayList] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchedPlayListDetail, setIsFetchedPlayListDetail] = useState(false);

    const [saving, setSaving] = useState(false);
    const [playListConfigModel, setPlayListConfigModel] = useState({
        open: false,
        playList: {active:'true'},
        isNew: true
    });

    useEffect(() => {
        if (!isFetchedPlayList) {
            setIsLoading(true);
            fetchPlayLists().then(()=>{
                setIsFetchedPlayList(true)         
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsFetchedPlayList(false);
            });
            fetchParkingHostsBySubject(HOST_TYPE_APM).then(()=>{
                setIsFetchedPlayList(true)         
            }).catch((err) => {
                console.log(err);
                setIsFetchedPlayList(false);
            });
        }
    }, [fetchPlayLists, isFetchedPlayList, fetchParkingHostsBySubject]);


    const [selectedName, setSelectedName] = useState('');
    const [selectedDeviceName, setSelectedDeviceName] = useState('');
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedParking, setSelectedParking] = useState();
    const [table, setTable] = useState(false);

    const handleSelectedName = (value) => {
        setSelectedName(value.target.value);
    };
    const handleSelectedDeviceName = (value) => {
        setSelectedDeviceName(value.target.value);

    };
    const handleSelectedStatus = (value) => {
        setSelectedStatus(value);
    };
    const handleSelectedParking = (value) => {
        setSelectedParking(value);
    }

    const matchStatus = (playList) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res = ( selectedStatus[i].key === 'ACTIVE' && playList.active===true ) || ( selectedStatus[i].key === 'INACTIVE' && playList.active===false );
            if (res) {
                return true;
            }
        }
        return false;
    }

    const matchDeviceName = (playList) => {
        // pokud host z aktualniho playlistu se shoduje na castecnou shodu jmena
        if ( playList && playList.hosts ) {
            for ( let i=0; i<playList.hosts.length; i++) {
                if ( playList.hosts[i].hostName.toUpperCase().indexOf(selectedDeviceName.toUpperCase()) > -1 ) {
                    return true;
                }
            }
        }
        return false;
    }

    const matchParking = (playList) => {
        // pokud host z aktualniho playlistu je z daneho parkoviste tak shoda
        if ( playList && playList.hosts ) {
            for ( let i=0; i<playList.hosts.length; i++) {
                for ( let j=0; j<selectedParking.length; j++) {
                    if ( playList.hosts[i].parkingId === selectedParking[j].key ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    useEffect(() => {
        const filPlayList = [];
        if ( playLists ) {

            for (let i = 0; i < playLists.length; i++) {
                let matchCriteria = true;
                if (selectedName && selectedName.trim().length > 0) {
                    if ( !playLists[i].name || !playLists[i].name.toLowerCase().includes(selectedName.toLowerCase()) ) {
                        matchCriteria = false;
                    }
                }

                if (selectedDeviceName && selectedDeviceName.trim().length > 0) {
                    const res = matchDeviceName(playLists[i]);
                    if (!res) {
                        matchCriteria = false;
                    }                   
                }

                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(playLists[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if ( selectedParking && selectedParking.length > 0) {
                    const res = matchParking(playLists[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (matchCriteria) {
                    filPlayList.push(playLists[i]);
                }
            }
        }
        setFilteredPlayList(filPlayList);
    }, [playLists, selectedName, selectedDeviceName, selectedStatus, selectedParking]);


    const [filteredPlayList, setFilteredPlayList] = useState([]);
    const [parkingValues, setParkingValues] = useState();
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    useEffect(() => {
        if ( parkings ) {
            setParkingValues(parkings.map(a => {
                return {value: a.parkingId, label: a.parkingName, key: a.parkingId}
            }));
        }
    }, [parkings]);

    const closePlayListDetail = () => {
        setPlayListConfigModel({isNew: false, playList: {}, open: false, readOnly: false});
        setIsFetchedPlayListDetail(false);
    }

    const handlePlayListUpdate = (updatedPlayList, isNew, readOnly) => {
        setPlayListConfigModel({isNew: isNew, playList: updatedPlayList, open: true, readOnly: readOnly});
    };
   
    const handleSavePlayList = (playList) => {
        let playListItems = [], playListHosts = [];
        if ( playList.files && playList.files.length>0 ) {
            for ( let i=0; i<playList.files.length; i++ ) {
                playListItems.push({fileName: playList.files[i].file.path, fileContent: playList.files[i].data});
            }
        }
        if ( playList.hosts && playList.hosts.length>0 ) {
            for ( let i=0; i<playList.hosts.length; i++ ) {
                if ( playList.hosts[i].choosed )
                    playListHosts.push({parkingHostId: playList.hosts[i].parkingHostId})
            }
        }
        let result = {playListId: playList.playListId, name: playList.name, active: playList.active, items: playListItems, hosts: playListHosts}

        if ( playListConfigModel.isNew ) {
            createPlayList(result)
            .then(()=>{
                setIsFetchedPlayList(false);
                setSaving(false);
                closePlayListDetail();
            })
        } else {
            updatePlayList(result)
            .then(()=>{
                setIsFetchedPlayList(false);
                setSaving(false);
                closePlayListDetail();
            })
        }

    }

    const handleDeletePlayList = (playList) => {
        deletePlayList(playList.playListId)
        .then(()=>{
            setIsFetchedPlayList(false);
            setConfirmModalOpened(false);
        })
    }

    return (
        <PerfectScrollbar
            options={{
                suppressScrollX: true,
                minScrollbarLength: 50,
            }}
        >

            <div className={classes.breadcrumb}>
                {isComponentVisibleForUser(UC_ADMINISTRATION, userUseCases) &&

                    <Link style={{textDecoration: 'none'}}
                          to={isComponentEnabledForUser(UC_ADMINISTRATION, userUseCases) ? `/${process.env.REACT_APP_PATH}/administration` : "#"}>

                        <Typography variant="button" className={classnames(classes.activeLink, classes.navLink)}>
                            {t('ADMINISTRATION')}
                        </Typography>
                    </Link>
                }
            </div>

            <Typography variant="h4" className={classes.title}>
                {t('PLAY_LIST')}
            </Typography>


            <div className={classes.container}>
                <ToolBar
                    userUseCases={userUseCases}
                    handlePlayListUpdate={handlePlayListUpdate}

                    selectedName={selectedName}
                    handleSelectedName={handleSelectedName}
                    selectedDeviceName={selectedDeviceName}
                    handleSelectedDeviceName={handleSelectedDeviceName}
                    selectedStatus={selectedStatus}
                    handleSelectedStatus={handleSelectedStatus}
                    selectedParking={selectedParking}
                    handleSelectedParking={handleSelectedParking}

                    parkings={parkingValues}
                />
                <Grid container>
                    <Grid item sm={11}>
                    </Grid>
                    <Grid item sm={1}>
                        <ToggleButton
                            style={{width: '30px', height: '30px'}}
                            value="check"
                            selected={table}
                            onChange={() => {
                                setTable(!table);
                            }}
                        >
                            <TocIcon/>
                        </ToggleButton>
                    </Grid>
                </Grid>
                <PlayList
                    refresh={() => {setIsFetchedPlayList(false);}}
                    table={table}
                    save={handleSavePlayList}
                    userUseCases={userUseCases}
                    playList={filteredPlayList}
                    handlePlayListDetail={handlePlayListUpdate}
                    isLoading={isLoading}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                    parkings={parkings}
                    handleDeletePlayList={handleDeletePlayList}
                />

            </div>

            <PlayListModal
                playListConfigModel={playListConfigModel}
                userUseCases={userUseCases}
                savePlayList={handleSavePlayList}
                handleClose={closePlayListDetail}
                saving={saving}
                setSaving={setSaving}
                hosts={hosts}
                parkings={parkings}
                isFetchedPlayListDetail={isFetchedPlayListDetail}
                setIsFetchedPlayListDetail={setIsFetchedPlayListDetail}
            />

        </PerfectScrollbar>
    );
}

const UC_ADMINISTRATION = 'UC0600';

const mapStateToProps = (store) => ({
    playLists: store.playListData.playLists,
    parkings: store.parkingsData.parkings,
    hosts: store.hostsData.parkingHosts,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPlayLists, createPlayList, updatePlayList, deletePlayList,
    fetchParkingHostsBySubject
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PlayListAdministration);
