import types from '../actionTypes';

const INITIAL_STATE = {
  httpservice: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.API_ERROR:
      return {
        ...state,
        httpserviceError: action.payload,
      };
    case types.API_SUCCESS:
        return {
          ...state,
          httpserviceSuccess: action.payload,
        };
      
    default:
      return state;
  }
};
