/* eslint-disable max-len */
import cardsService from 'services/cards.service';
import types from '../actionTypes';


export const fetchParkingCardsForProductExecution = (parkingId, filterParams) => (dispatch, getState) => {
    if (getState().cardsData.isFetchingParkingList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_CARDS_FETCH_REQUEST,
    });

    return cardsService.getParkingCardsForProductExecution(
        parkingId,
        filterParams.validTill,
        filterParams.validity,
        filterParams.blocked,
        filterParams.lpn,
        filterParams.cardNumber,
        filterParams.size,
        filterParams.page,
    )
        .then((data) => {
            let {parkingLTCards, parkingSTCards, cardsPaging} = getState().cardsData;
            let parkingCards = data.cards;
            cardsPaging = {
                LT: cardsPaging.LT,
                ST: cardsPaging.ST,
                ...data.paging
            };


            dispatch({
                type: types.PARKING_CARDS_FETCH_SUCCESS,
                payload: {cardsPaging, parkingLTCards, parkingSTCards, parkingCards},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_CARDS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchParkingCards = (parkingId, filterParams) => (dispatch, getState) => {
    if (getState().cardsData.isFetchingParkingList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_CARDS_FETCH_REQUEST,
    });

    return cardsService.getParkingCards(
        parkingId,
        filterParams.owner,
        filterParams.validTill,
        filterParams.validity.toString().slice(1),
        filterParams.blocked.toString().slice(1),
        filterParams.zone && filterParams.zone.toString().slice(1),
        filterParams.cardType,
        filterParams.size,
        filterParams.page,
        filterParams.orderedColumnId, 
        filterParams.orderDirection
    )
        .then((data) => {
            let {parkingLTCards, parkingSTCards, cardsPaging} = getState().cardsData;
            let parkingCards;
            if (filterParams.cardType) {
                if (filterParams.cardType === 'LT') {
                    parkingLTCards = data.cards;
                } else {
                    parkingSTCards = data.cards;
                }
            } else {
                parkingCards = data.cards;
            }

            cardsPaging = {
                LT: filterParams.cardType === 'LT' ? data.paging : cardsPaging.LT,
                ST: filterParams.cardType === 'ST' ? data.paging : cardsPaging.ST,
                ...data.paging
            };


            dispatch({
                type: types.PARKING_CARDS_FETCH_SUCCESS,
                payload: {cardsPaging, parkingLTCards, parkingSTCards, parkingCards},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_CARDS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchParkingCardGroups = (parkingId) => (dispatch, getState) => {
    if (getState().cardsData.isFetchingParkingCardGroups) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_CARD_GROUPS_FETCH_REQUEST,
    });

    return cardsService.getCardGroups(parkingId)
        .then((parkingCardGroups) => {
            dispatch({
                type: types.PARKING_CARD_GROUPS_FETCH_SUCCESS,
                payload: {parkingCardGroups},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_CARD_GROUPS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchParkingCardNumbers = (parkingId, cardNumber, cardType) => (dispatch, getState) => {
    if (getState().cardsData.isFetchingCardNumbers) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_CARD_NUMBERS_FETCH_REQUEST,
    });

    return cardsService.getParkingCardNumbers(parkingId, cardNumber, cardType)
        .then((parkingCardNumbers) => {
            let {parkingLTCardNumbers} = getState().cardsData;
            let {parkingSTCardNumbers} = getState().cardsData;

            if (cardType === 'LT') {
                parkingLTCardNumbers = parkingCardNumbers;
            } else {
                parkingSTCardNumbers = parkingCardNumbers;
            }

            dispatch({
                type: types.PARKING_CARD_NUMBERS_FETCH_SUCCESS,
                payload: {parkingLTCardNumbers, parkingSTCardNumbers},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_CARD_NUMBERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const bulkEditCards = (parkingId, cards, setting, type) => (dispatch, getState) => {
    if (getState().cardsData.isActivating) {
        return;
    }

    dispatch({
        type: types.CARD_EDIT_BULK_REQUEST,
    });

    return cardsService.editMultiCards(parkingId, cards)
        .then(() => {
            const {parkingLTCards, parkingSTCards} = getState().cardsData;

            const parkingCards = type === 'LT' ? parkingLTCards : parkingSTCards;

            cards.forEach((card) => {
                const selectedCardIndex = parkingCards.findIndex((c) => c.cardNumber === card.cardNumber);
                if (selectedCardIndex > -1) {
                    Object.keys(setting).forEach((key) => {
                        if (key === 'block') {
                            parkingCards[selectedCardIndex]['blocked'] = setting[key];
                        } else {
                            parkingCards[selectedCardIndex][key] = setting[key];
                        }
                    });
                }
            });

            dispatch({
                type: types.CARD_EDIT_BULK_SUCCESS,
                payload: {
                    parkingLTCards: type === 'LT' ? parkingCards : parkingLTCards,
                    parkingSTCards: type === 'ST' ? parkingCards : parkingSTCards,
                },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CARD_EDIT_BULK_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const activateCard = (cardNumber, parkingId, activate) => (dispatch, getState) => {
    if (getState().cardsData.isActivating) {
        return;
    }

    dispatch({
        type: types.CARD_ACTIVATE_REQUEST,
    });

    return cardsService.setCardActivate(cardNumber, parkingId, activate)
        .then(() => {
            const oldCardDetail = getState().cardsData.cardDetail;
            const parkings = oldCardDetail.parkings
                ? oldCardDetail.parkings.map((parking) => (parking.parkingId === parkingId
                        ? ({
                            ...parking,
                            zones: parking.zones.map((zone) => ({
                                ...zone,
                                valid: activate,
                            })),
                        })
                        : parking
                ))
                : [];
            const newCardDetail = oldCardDetail.parkings ? {
                ...oldCardDetail,
                parkings,
            } : {};

            const {parkingLTCards, parkingSTCards} = getState().cardsData;
            const parkingLTCardIndex = parkingLTCards.findIndex((card) => card.cardNumber === cardNumber && card.parkingId === parkingId);
            const parkingSTCardIndex = parkingSTCards.findIndex((card) => card.cardNumber === cardNumber && card.parkingId === parkingId);
            if (parkingLTCardIndex > -1) {
                parkingLTCards[parkingLTCardIndex].valid = activate;
            } else if (parkingSTCardIndex > -1) {
                parkingSTCards[parkingSTCardIndex].valid = activate;
            }

            dispatch({
                type: types.CARD_ACTIVATE_SUCCESS,
                payload: {
                    cardDetail: newCardDetail,
                    parkingLTCards,
                    parkingSTCards,
                },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CARD_ACTIVATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const blockCard = (cardNumber, parkingId, block, blockingReason) => (dispatch, getState) => {
    if (getState().cardsData.isBlocking) {
        return;
    }

    dispatch({
        type: types.CARD_BLOCK_REQUEST,
    });

    return cardsService.setCardBlock(cardNumber, parkingId, block, blockingReason)
        .then(() => {
            const oldCardDetail = getState().cardsData.cardDetail;
            const parkings = oldCardDetail.parkings
                ? oldCardDetail.parkings.map((parking) => ({
                    ...parking,
                    zones: parking.zones.map((zone) => ({
                        ...zone,
                        blocked: block,
                        blockingReason: blockingReason || null,
                    })),
                }))
                : [];
            const newCardDetail = oldCardDetail.parkings ? {
                ...oldCardDetail,
                parkings,
            } : {};

            const {parkingLTCards, parkingSTCards} = getState().cardsData;
            const parkingLTCardIndex = parkingLTCards.findIndex((card) => card.cardNumber === cardNumber);
            const parkingSTCardIndex = parkingSTCards.findIndex((card) => card.cardNumber === cardNumber);
            if (parkingLTCardIndex > -1) {
                parkingLTCards[parkingLTCardIndex].blocked = block;
            } else if (parkingSTCardIndex > -1) {
                parkingSTCards[parkingSTCardIndex].blocked = block;
            }

            dispatch({
                type: types.CARD_ACTIVATE_SUCCESS,
                payload: {
                    cardDetail: newCardDetail,
                    parkingLTCards,
                    parkingSTCards,
                },
            });

            dispatch({
                type: types.CARD_BLOCK_SUCCESS,
                payload: {
                    cardDetail: newCardDetail,
                    parkingLTCards,
                    parkingSTCards,
                },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CARD_BLOCK_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const createCard = (card) => (dispatch, getState) => {
    if (getState().cardsData.isCreating) {
        return;
    }

    dispatch({
        type: types.CARD_CREATE_REQUEST,
    });

    return cardsService.createCard(card)
        .then(() => {
            let {parkingLTCards, parkingSTCards} = getState().cardsData;
            const cardType = card.parkings[0].zones[0].type;
            const newCard = card.parkings[0].zones[0];

            if (cardType === 'LT') {
                parkingLTCards = [newCard, ...parkingLTCards];
            } else {
                parkingSTCards = [newCard, ...parkingSTCards];
            }

            dispatch({
                type: types.CARD_CREATE_SUCCESS,
                payload: {parkingLTCards, parkingSTCards},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CARD_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const changeAccess = (parkingId, cardId, accessId) => (dispatch, getState) => {
    if (getState().cardsData.isAccessChanging) {
        return;
    }

    dispatch({
        type: types.CARD_ACCESS_CHANGE_REQUEST,
    });

    return cardsService.changeAccess(parkingId, accessId, cardId)
        .then((cardDetail) => {
            dispatch({
                type: types.CARD_ACCESS_CHANGE_SUCCESS,
                payload: {data: cardDetail},
            });

            return true;
        }) 
        .catch((error) => {
            dispatch({
                type: types.CARD_ACCESS_CHANGE_FAIL,
                payload: {error},
            });

            throw error;
        });
}


export const updateCard = (card, parkingId) => (dispatch, getState) => {
    if (getState().cardsData.isUpdating) {
        return;
    }

    dispatch({
        type: types.CARD_UPDATE_REQUEST,
    });

    return cardsService.updateCard(card)
        .then(() => {
            const updatedCard = card.parkings.find((p) => p.parkingId === parkingId).zones[0];

            const {parkingLTCards, parkingSTCards} = getState().cardsData;

            const cardLTIndex = parkingLTCards.findIndex((c) => c.cardZoneId === updatedCard.cardZoneId);
            const cardSTIndex = parkingSTCards.findIndex((c) => c.cardZoneId === updatedCard.cardZoneId);

            if (cardLTIndex > -1) {
                parkingLTCards[cardLTIndex] = updatedCard;
            } else {
                parkingSTCards[cardSTIndex] = updatedCard;
            }

            dispatch({
                type: types.CARD_UPDATE_SUCCESS,
                payload: {data: card, parkingLTCards, parkingSTCards},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CARD_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const getCardDetail = (cardNumber) => (dispatch, getState) => {
    if (getState().cardsData.isGettingDetail) {
        return;
    }

    dispatch({
        type: types.CARD_DETAIL_REQUEST,
    });

    return cardsService.getCardDetail(cardNumber)
        .then((cardDetail) => {
            dispatch({
                type: types.CARD_DETAIL_SUCCESS,
                payload: {cardDetail},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CARD_DETAIL_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const getCardDetailByNumberOrLpn = (cardNumberOrLpn) => (dispatch, getState) => {
    if (getState().cardsData.isGettingDetail) {
        return;
    }

    dispatch({
        type: types.CARD_DETAIL_REQUEST,
    });

    return cardsService.getCardDetailByNumberOrLpn(cardNumberOrLpn)
        .then((cardDetail) => {
            dispatch({
                type: types.CARD_DETAIL_SUCCESS,
                payload: {cardDetail},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CARD_DETAIL_FAIL,
                payload: {error},
            });

        });
};
