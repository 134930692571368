import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import RichTextEditor from 'react-rte';
import classnames from 'classnames';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import WizardStep3Table from "./WizardStep3Table";
import WizardStep2 from "./WizardStep2";
import WizardStep1 from './WizardStep1';
import {ActionButton} from 'components/common';
import ParkingSymbol from "../../../common/ParkingSymbol";
import Typography from "@material-ui/core/Typography";
import {initProduct} from 'redux/actions/products';
import PMCSnackbar from "../../../common/Snackbar";


const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 300,
        color: theme.palette.base[500],
    },
    separator: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    activeLink: {
        color: theme.palette.secondary.main,
    },
    navLink: {
        cursor: 'pointer',
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
    },
    contentGrey: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),
        background: theme.palette.base[200],

    },
    stepNavigation: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: theme.palette.base.white,
        boxShadow: theme.palette.shadow.main,
        '& .MuiStepIcon-active': {
            color: theme.palette.secondary.main,
        }

    },
    root: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
        paddingBottom: '0px',
    },
    root1: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '24px',
        paddingBottom: '24px',
    },
    descBelow: {
        width: '100%',
        height: '40px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    descValue: {
        flex: 1,
        color: '#1B1B28',
        fontSize: '20px',
        textAlign: 'center',
        width: '100%',
        height: '40px',
        lineHeight: '40px',
    },
    button: {
        width: 200,
        height: 75,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 25,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '75px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '75px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    parking: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        flexDirection: 'row',
        justifyContent: 'center'

    },
    name: {
        marginLeft: theme.spacing(1),
    },
}));


function ProductCashDesk(props) {

    const {
        cardNumberRead,
        title,
        aggregated,
        product,
        sameGroupAndTypeProducts,
        opened
    } = props.productDetailModel;

    const withCard = props.productDetailModel.withCard && product && product.productTemplateType !== 'MP';

    const {
        userUseCases,
        save,
        handleClose,
        productTemplates,
        initProduct,
        snackbarOpen,
        setSnackbarOpen,
        error,
        setError,
        productDetailModel,
    } = props;


    const classes = useStyles();

    const {t} = useTranslation();


    const [description, setDescription] = useState(RichTextEditor.createEmptyValue());
    const [isLoading, setIsLoading] = useState(true);
    const [errorStep1, setErrorStep1] = useState({});
    const [errorStep2, setErrorStep2] = useState({});
    const [errorStep3, setErrorStep3] = useState({});
    const [selectedCard, setSelectedCard] = useState(null);
    const [cardNumber, setCardNumber] = useState(null);
    const [updatedProduct, setUpdatedProduct] = useState();
    const [selectedProductParking, setSelectedProductParking] = useState(null);
    const [paramInited, setParamInited] = React.useState(false);


    useEffect(() => {
        setParamInited(false);
    }, [selectedCard, cardNumber]);


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleCloseInner = () => {
        setParamInited(false);
        setUpdatedProduct(null);
        setSelectedProductParking(null);
        setSelectedCard(null);
        setCardNumber(null);
        handleClose();
    }


    const getSteps = () => {
        const steps = [];
        if (aggregated) {
            steps.push(t('PARKING_SELECTION'));
        }
        if (withCard) {
            steps.push(t('CARD_SELECTION'));
        }
        steps.push(t('PRODUCT_SETTING'));
        return steps;
    }

    const isValid = (activeStep) => {
        let ret = true;
        const error = {};
        if (activeStep === 0) {
            setErrorStep1(error)
        } else if (activeStep === 1) {
            setErrorStep2(error)
        } else if (activeStep === 2) {


            for (let j = 0; j < updatedProduct.productParam.length; j++) {
                if (!error[updatedProduct.productParam[j].paramName]) {
                    error[updatedProduct.productParam[j].paramName] = {};
                }
                const template = productTemplates.find(a => a.productTemplateId === updatedProduct.productTemplateId);
                const templateParam = template.productTemplateParams && template.productTemplateParams.find(a => a.paramName === updatedProduct.productParam[j].paramName);
                if (!templateParam) {
                    continue;
                }
                const required = templateParam.required && templateParam.required === true;
                if (templateParam && required) {
                    let value;
                    switch (templateParam.paramType) {
                        case 'PRODUCT_TYPE_NUMBER':
                            value = updatedProduct.productParam[j].valueNumber;
                            break;
                        case 'PRODUCT_TYPE_TEXT':
                            value = updatedProduct.productParam[j].valueVarchar;
                            break;
                        case 'PRODUCT_TYPE_DATE':
                            if (updatedProduct.productParam[j].stepper) {
                                value = updatedProduct.productParam[j].valueVarchar;
                            } else {
                                value = updatedProduct.productParam[j].valueDate;
                            }
                            break;
                        case 'PRODUCT_TYPE_MACRO_SELECT':
                        case 'PRODUCT_TYPE_SELECT':
                            value = updatedProduct.productParam[j].valueSelect;
                            break;
                        case 'PRODUCT_TYPE_BOOLEAN':
                            value = updatedProduct.productParam[j].valueBoolean || 'false';
                            break;
                        default:
                            value = updatedProduct.productParam[j].valueVarchar;
                            break;
                    }
                    if (Array.isArray(value)) {
                        if (value.length > 0) {
                            value = value[0];
                        } else {
                            value = '';
                        }
                    }
                    if ((!value && value !== false) || (value.trim && value.trim().length === 0)) {
                        error[updatedProduct.productParam[j].paramName].defaultValue = 'required';
                        ret = false;
                    }


                }
            }
            if (props.productDetailModel.withCard && product && product.productTemplateType === 'MP') {
                if (!cardNumber || cardNumber.trim && cardNumber.trim().length === 0) {
                    error['cardNumber'] = 'required';
                }

            }
            setErrorStep3(error)
        }
        return ret;
    }

    const handleParamDefaultValueChange = (name) => (event) => {
        let changedProperty = updatedProduct.productParam.find(a => a.paramName === name);
        const updatedProjectParamCopy = updatedProduct.productParam.filter(a => a.paramName !== name);
        changedProperty = {...changedProperty};
        switch (changedProperty.paramType) {
            case 'PRODUCT_TYPE_NUMBER':
                changedProperty['valueNumber'] = event.target.value;
                break;
            case 'PRODUCT_TYPE_TEXT':
                changedProperty['valueVarchar'] = event.target.value;
                break;
            case 'PRODUCT_TYPE_DATE':
                if (changedProperty.stepper) {
                    changedProperty['valueVarchar'] = event;
                } else {
                    changedProperty['valueDate'] = event;
                }
                break;
            case 'PRODUCT_TYPE_BOOLEAN':
                changedProperty['valueBoolean'] = event;
                break;
            case 'PRODUCT_TYPE_MACRO_SELECT':
            case 'PRODUCT_TYPE_SELECT':
                if (event) {
                    if (event.target) {
                        changedProperty['valueSelect'] = [event.target.value];
                    } else {
                        if (Array.isArray(event)) {
                            changedProperty['valueSelect'] = event.map(a => a.value);
                        } else {
                            changedProperty['valueSelect'] = [event.value];
                        }
                    }
                } else {
                    delete changedProperty['valueSelect'];
                }
                break;
            default:
                changedProperty['valueVarchar'] = event.target.value;
                break;
        }
        updatedProjectParamCopy.push(changedProperty);
        const newUpdatedProduct = {...updatedProduct, 'controlDate': new Date()};
        newUpdatedProduct['productParam'] = updatedProjectParamCopy;
        setUpdatedProduct(newUpdatedProduct);
    }

    const onParkingSelected = (product) => {
        for (let i = 0; i < product.productParam.length; i++) {
            product.productParam[i].order = i;
        }
        setSelectedProductParking(product);
        setUpdatedProduct(product);
        handleNext();
    }


    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <WizardStep1
                    sameGroupAndTypeProducts={sameGroupAndTypeProducts}
                    product={updatedProduct}
                    error={errorStep1}
                    isValid={isValid}
                    selectedProductParking={selectedProductParking}
                    onParkingSelected={onParkingSelected}

                />
            case 1:
                return (
                    <WizardStep2
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                        product={updatedProduct}
                        error={errorStep2}
                        isValid={isValid}
                        userUseCases={userUseCases}
                        cardNumber={cardNumber}
                        setCardNumber={setCardNumber}
                    />
                );

            case 2:
                return (
                    <WizardStep3Table
                        handleParamDefaultValueChange={handleParamDefaultValueChange}
                        product={updatedProduct}
                        productTemplates={productTemplates}
                        error={errorStep3}
                        withCard={props.productDetailModel.withCard}
                        paramInited={paramInited}
                        submit={handleNext}
                        cardNumber={cardNumber}
                        setCardNumber={setCardNumber}
                    />
                );
            default:
                return 'Unknown step';
        }
    }


    const [activeStep, setActiveStep] = React.useState(aggregated ? 0 : withCard ? 1 : 2);
    const steps = getSteps();


    useEffect(() => {
        if (product) {
            for (let i = 0; i < product.productParam.length; i++) {
                product.productParam[i].order = i;
            }
        }
        setParamInited(false);
        setUpdatedProduct(product);
        setActiveStep(aggregated ? 0 : withCard ? 1 : 2);
        setSelectedCard(null);
        setSelectedProductParking(null);
        setSnackbarOpen(false);
        setError('');
        if (cardNumberRead) {
            setCardNumber(cardNumberRead);
        } else {
            setCardNumber(null);
        }
    }, [productDetailModel]);

    const handleNext = () => {
        if (activeStep === 2 || nonvisibleparam()) {
            if (isValid(activeStep)) {
                save(updatedProduct, selectedCard ? selectedCard.cardNo : cardNumber);
            }
        } else {
            if (isValid(activeStep)) {
                if (activeStep === 0) {
                    if (withCard) {
                        setActiveStep(1);
                    } else {
                        setActiveStep(2);
                    }
                } else {
                    setActiveStep((activeStep) => activeStep + 1);
                }
            }
        }
    };


    useEffect(() => {
        if (activeStep === 2 && paramInited === false && updatedProduct) {
            initProduct(updatedProduct, selectedCard ? selectedCard.cardNo : cardNumber).then((newProduct) => {
                for (let i = 0; i < newProduct.productParam.length; i++) {
                    newProduct.productParam[i].order = i;
                }
                setParamInited(true)
                setUpdatedProduct(newProduct);
            }).catch((err) => {
                setIsLoading(false)
            });
        }
    }, [activeStep, paramInited, updatedProduct]);


    const handleBack = () => {
        if (activeStep === 2) {
            if (withCard) {
                setActiveStep(1);
            } else {
                setActiveStep(0);
            }
        } else {
            setActiveStep(0);
        }
    };

    const nonvisibleparam = () => {
        if (updatedProduct) {
            const visibleParam = updatedProduct.productParam.find(a => a.visible);
            if (visibleParam) {
                return false;
            }
            return true;
        }
        return true;
    }


    return (
        <Dialog
            className={classes.container}
            open={opened}
            onClose={handleCloseInner}
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
              <span>
                  {title}
              </span>
            </DialogTitle>

            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="error"
                         message={error}/>

            <div className={classes.stepNavigation}>
                <Stepper activeStep={activeStep} color="secondary"
                         classes={{root: ((activeStep >= 1 && aggregated) || (activeStep > 1 && withCard)) ? classes.root : classes.root1}}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {((activeStep >= 1 && aggregated) || (activeStep > 1 && withCard)) && selectedProductParking &&
                <div className={classes.descBelow}>
                    {activeStep >= 1 && (aggregated) &&
                    <div className={classes.parking}>
                        <ParkingSymbol variant="h4" color={selectedProductParking.color}/>
                        <Typography variant="subtitle2" className={classes.name}>
                            {selectedProductParking.parkingName}
                        </Typography>
                    </div>
                    }
                    {activeStep > 1 && (withCard) &&
                    <div className={classes.descValue}>
                        {selectedCard ? selectedCard.cardNo : cardNumber}
                    </div>
                    }
                </div>}
            </div>
            <DialogContent className={(activeStep === 1) ? classes.contentGrey : classes.content}>
                <div className={classes.container}>
                    {getStepContent(activeStep)}
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>

                <div className={classes.buttonNavigationCancel}>

                    <ActionButton
                        action="close"
                        handleClick={handleCloseInner}
                        className={classes.button}
                    />
                </div>
                <div className={classes.buttonNavigation}>

                    <ActionButton
                        disabled={activeStep === (aggregated ? 0 : withCard ? 1 : 2)} handleClick={handleBack}
                        className={classes.button}
                        action="back"
                    />
                    <ActionButton
                        disabled={(activeStep === 1 && withCard && (!selectedCard && !cardNumber)) || (activeStep === 0 && aggregated && !selectedProductParking)}
                        action={(activeStep === 2 || nonvisibleparam()) ? 'insert' : 'next'}
                        handleClick={handleNext}
                        className={classes.button}
                    />
                </div>
            </DialogActions>
        </Dialog>
    );
}

ProductCashDesk.propTypes = {};

const UC_TAB_ACCESS = 'UC0062';
const UC_PRODUCT_ADMINISTRATION = 'UC0083';
const UC_MENU_ADMINISTRATION = 'UC0083';


const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({initProduct}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCashDesk);


