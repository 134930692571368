import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Typography from '@material-ui/core/Typography/Typography';
import { makeStyles } from '@material-ui/core/styles';

import {
  fetchAllMobileDevices, activeDevice, activeMultipleDevices, removeDevice,
} from 'redux/actions/mobiles';
import { Loading } from 'components/elements';
import MobileDevices from './MobileDevices';
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 300,
    color: theme.palette.base[500],
  },
  separator: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  activeLink: {
    color: theme.palette.secondary.main,
  },
  title: {
    marginBottom: theme.spacing(3.75),
    textTransform: 'uppercase',
  },
  container: {
    marginTop: theme.spacing(3.75),
  },
}));

function MobileManagement(props) {
  const classes = useStyles();

  const {
    history,
    user,
    userUseCases,
    mobileDevices,
    fetchAllMobileDevices,
    activeDevice,
    activeMultipleDevices,
    removeDevice,
  } = props;

  const { t } = useTranslation();

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [isFetchedDevices, setIsFetchedDevices] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const fetchMobileDevices = useCallback(() => {
    if (user) {
      fetchAllMobileDevices()
        .then(() => {
          if (!isUnMounted.current) {
            setIsLoading(false);
            setIsFetchedDevices(true);
          }
        })
        .catch(() => {
          if (!isUnMounted.current) {
            setIsLoading(false);
            setIsFetchedDevices(false);
          }
        });
    }
  }, [fetchAllMobileDevices, user]);

  useEffect(() => {
    if (!isFetchedDevices) {
      fetchMobileDevices();
    }
  }, [fetchMobileDevices, isFetchedDevices, user]);

  if (!user) {
    return (
      <Loading />
    );
  }


  return (
      <PerfectScrollbar
          options={{
            suppressScrollX: true,
            minScrollbarLength: 50,
          }}
      >
      <div className={classes.breadcrumb}>
        <Typography variant="button" className={classes.activeLink}>{t('MOBILE_MANAGEMENT_DASHBOARD')}</Typography>
      </div>
      <div className={classes.container}>
        <Typography
          variant="h4"
          className={classes.title}
        >
          {t('MOBILE_DEVICE_MANAGEMENT')}
        </Typography>
        <MobileDevices
          user={user}
          isLoading={isLoading}
          mobileDevices={mobileDevices}
          activeDevice={activeDevice}
          removeDevice={removeDevice}
          activeMultipleDevices={activeMultipleDevices}
          fetchMobileDevices={fetchMobileDevices}
          userUseCases={userUseCases}
        />
      </div>
      </PerfectScrollbar>
  );
}

MobileManagement.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  mobileDevices: PropTypes.array.isRequired,
  fetchAllMobileDevices: PropTypes.func.isRequired,
  activeDevice: PropTypes.func.isRequired,
  activeMultipleDevices: PropTypes.func.isRequired,
  removeDevice: PropTypes.func.isRequired,
};

MobileManagement.defaultProps = {
  user: null,
};

const mapStateToProps = (store) => ({
  user: store.authData.user,
  mobileDevices: store.mobilesData.mobileDevices,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllMobileDevices,
  activeDevice,
  activeMultipleDevices,
  removeDevice,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileManagement);
