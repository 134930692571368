import paymentService from 'services/payment.service';
import types from '../actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchCardPayment = (cardId) => (dispatch, getState) => {
  if (getState().paymentData.isFetchingCardPaymentList) {
    return;
  }

  dispatch({
    type: types.CARD_PAYMENT_FETCH_REQUEST,
  });

  return paymentService.getCardPayment(cardId)
    .then((cardPayment) => {
      dispatch({
        type: types.CARD_PAYMENT_FETCH_SUCCESS,
        payload: { cardPayment },
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.CARD_PAYMENT_FETCH_FAIL,
        payload: { error },
      });

      throw error;
    });
};
