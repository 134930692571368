import usersService from 'services/users.service';
import types from '../actionTypes';


export const searchUsers = (searchString) => (dispatch, getState) => {
    return usersService.searchUsers(searchString)
        .then((searchedUsers) => {
            dispatch({
                type: types.SEARCH_USERS_FETCH_SUCCESS,
                payload: {searchedUsers},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SEARCH_USERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchParkingUsers = (parkingId) => (dispatch, getState) => {
    if (getState().usersData.isFetchingParkingList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_USERS_FETCH_REQUEST,
    });

    return usersService.getParkingUsers(parkingId)
        .then((parkingUsers) => {
            dispatch({
                type: types.PARKING_USERS_FETCH_SUCCESS,
                payload: {parkingUsers},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_USERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchParkingAssignedUsers = (subjectId, parkingId) => (dispatch, getState) => {
    if (getState().usersData.isFetchingParkingAssignedUserList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_ASSIGNED_USERS_FETCH_REQUEST,
    });

    return usersService.getParkingAssignedUsers(subjectId, parkingId)
        .then((parkingAssignedUsers) => {
            dispatch({
                type: types.PARKING_ASSIGNED_USERS_FETCH_SUCCESS,
                payload: {parkingAssignedUsers},
            });

            return parkingAssignedUsers;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_ASSIGNED_USERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchParkingNotAssignedUsers = (subjectId, parkingId) => (dispatch, getState) => {
    if (getState().usersData.isFetchingParkingNotAssignedUserList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_NOT_ASSIGNED_USERS_FETCH_REQUEST,
    });

    return usersService.getParkingNotAssignedUsers(subjectId, parkingId)
        .then((parkingNotAssignedUsers) => {
            dispatch({
                type: types.PARKING_NOT_ASSIGNED_USERS_FETCH_SUCCESS,
                payload: {parkingNotAssignedUsers},
            });

            return parkingNotAssignedUsers;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_NOT_ASSIGNED_USERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const getUsersForSubject = (subjectId) => (dispatch, getState) => {
    if (getState().usersData.isFetchingSubjectUsers) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_SUBJECT_USERS_FETCH_REQUEST,
    });

    return usersService.getUsersForSubject(subjectId)
        .then((parkingUsers) => {
            dispatch({
                type: types.PARKING_SUBJECT_USERS_FETCH_SUCCESS,
                payload: {parkingUsers},
            });

            return parkingUsers;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_SUBJECT_USERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};



export const addParkingIdToUser = (subjectId, userId, parkingId) => (dispatch, getState) => {
    if (getState().usersData.isAddingParkingToUser) {
        return Promise.reject();
    }

    dispatch({
        type: types.ADD_PARKING_TO_USER_REQUEST,
    });

    return usersService.addParkingToUser(subjectId, userId, parkingId)
        .then(() => {
            dispatch({
                type: types.ADD_PARKING_TO_USER_SUCCESS,
                payload: {userId},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ADD_PARKING_TO_USER_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const removeParkingIdFromUser = (subjectId, userId, parkingId) => (dispatch, getState) => {
    if (getState().usersData.isRemovingParkingFromUser) {
        return Promise.reject();
    }

    dispatch({
        type: types.REMOVE_PARKING_FROM_USER_REQUEST,
    });

    return usersService.removeParkingFromUser(subjectId, userId, parkingId)
        .then(() => {
            dispatch({
                type: types.REMOVE_PARKING_FROM_USER_SUCCESS,
                payload: {userId},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.REMOVE_PARKING_FROM_USER_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllUsers = () => (dispatch, getState) => {
    if (getState().usersData.isFetchingParkingList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_USERS_FETCH_ALL_REQUEST,
    });

    return usersService.getUsersAll()
        .then((parkingUsers) => {
            dispatch({
                type: types.PARKING_USERS_FETCH_ALL_SUCCESS,
                payload: {parkingUsers},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_USERS_FETCH_ALL_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllUsersByRole = (roleId) => (dispatch, getState) => {
    if (getState().usersData.isFetchingRoleUser) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_ROLE_FETCH_ALL_REQUEST,
    });

    return usersService.getUsersAllByRole(roleId)
        .then((roleUsers) => {
            dispatch({
                type: types.PARKING_ROLE_FETCH_ALL_SUCCESS,
                payload: {roleUsers: roleUsers},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_ROLE_FETCH_ALL_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const createUser = (user) => (dispatch, getState) => {
    if (getState().usersData.isCreating) {
        return;
    }

    dispatch({
        type: types.PARKING_USER_CREATE_REQUEST,
    });

    return usersService.createUser(user)
        .then(() => {
            dispatch({
                type: types.PARKING_USER_CREATE_SUCCESS,
                payload: {user: user},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_USER_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateUser = (user) => (dispatch, getState) => {
    if (getState().usersData.isUpdating) {
        return;
    }

    dispatch({
        type: types.PARKING_USER_UPDATE_REQUEST,
    });

    return usersService.updateUser(user)
        .then(() => {
            dispatch({
                type: types.PARKING_USER_UPDATE_SUCCESS,
                payload: {user: user},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_USER_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateUserState = (userId, active) => (dispatch, getState) => {
    if (getState().usersData.isUpdating) {
        return Promise.reject();;
    }

    dispatch({
        type: types.PARKING_USER_UPDATE_REQUEST,
    });

    return usersService.updateUserState(userId, active)
        .then(() => {
            dispatch({
                type: types.PARKING_USER_UPDATE_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_USER_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};