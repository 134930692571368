import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import IconButton from "@material-ui/core/IconButton";
import Call from "@material-ui/icons/Call";
import {isComponentEnabledForUser, isComponentVisibleForUser} from 'utils';



const useStyles = theme => ({
    screenContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    inputContainer: {
        background: 'transparent',
        width: 390,
        height: 40,
        fontSize: 25,
    },
    acceptCall: {
        margin: theme.spacing(1),
        color: 'white',
        backgroundColor: 'green',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#F0F90F0',
        '&:hover': {
            margin: theme.spacing(1),
            color: 'white',
        },
    },
});

class NumericKeyboard extends Component {
    state = {
        input: "",
        layoutName: "default",
        buttonTheme: []
    };

    keyboard = React.createRef();


    onKeyPress = button => {
        console.log("Button pressed", button);

    };

    onChange = input => {
        if (isComponentEnabledForUser(UC_SIP_DIAL_NUMBER, this.props.userUseCases)) {
            this.setState({input}, () => console.log("Input changed", input));
        }
    }

    onChangeInput = event => {
        if (isComponentEnabledForUser(UC_SIP_DIAL_NUMBER, this.props.userUseCases)) {
            const input = event.target.value;

            this.setState({input: event.target.value}, () =>
                this.keyboard.setInput(input)
            );
        }
    };

    render() {

        const {
            state: {input, layoutName, buttonTheme},
            onChangeInput,
            onChange,
            onKeyPress
        } = this;

        const {classes, sipCall, userUseCases} = this.props;

        return (
            <div>
                <div className={classes.screenContainer}>
                 <textarea
                     className={classes.inputContainer}
                     value={input}
                     onChange={onChangeInput}
                 />
                    {isComponentVisibleForUser(UC_SIP_MAKE_CALL, userUseCases) &&
                    <IconButton className={classes.acceptCall} edge="start"
                                disabled={input.length < 1 || !isComponentEnabledForUser(UC_SIP_MAKE_CALL, userUseCases)}
                                color="inherit" disableElevation onClick={() => {
                        sipCall('call-audio', input);
                    }}>
                        <Call/>
                    </IconButton>
                    }
                </div>
                { isComponentVisibleForUser(UC_SIP_DIAL_NUMBER, userUseCases) &&
                    <Keyboard
                        stateToIgnore={input}
                        keyboardRef={r => (this.keyboard = r)}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        layoutName={layoutName}
                        buttonTheme={buttonTheme}
                        newLineOnEnter={false}
                        physicalKeyboardHighlight
                        layout={{
                            default: ["1 2 3", "4 5 6", "7 8 9", "* 0 #", "{bksp}"]
                        }}
                        debug
                    />
                }
            </div>
        );
    }
}

const UC_SIP_MAKE_CALL = 'UC0053';
const UC_SIP_DIAL_NUMBER = 'UC0056';


export default withStyles(useStyles)(NumericKeyboard);
