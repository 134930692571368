import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingCardJournalList: false,

  cardJournals: [],

  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CARD_JOURNALS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingCardJournalList: true,
      };
    case types.CARD_JOURNALS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingCardJournalList: false,
        cardJournals: action.payload.cardJournals,
      };
    case types.CARD_JOURNALS_FETCH_FAIL:
      return {
        ...state,
        isFetchingCardJournalList: false,
        errors: action.payload.error,
      };

    default:
      return state;
  }
};
