import httpService from './http.service';


const getCurrencies = () => httpService
    .get(`/currency`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const getRates = () => httpService
    .get(`/exchange`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getCurrencies,
    getRates,
};
