import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import moment from 'moment/min/moment-with-locales';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import InputAdornment from '@material-ui/core/InputAdornment';

import { CustomButton, CustomDateTimePicker, CustomSelect, CustomMultiSelect} from 'components/elements';
import i18next from 'i18next';
import { getAMPM, getLocaleDateTimeString } from '../../../../i18n';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(1, 0),
    flex: 1,
    overflow: 'hidden',

    '& .MuiSelect-select': {
      borderBottom: '1px solid #ADB0B8',
      color: theme.palette.base[800],
      fontSize: 16,
    },

    '& input': {
      color: theme.palette.base[800],
      fontSize: 16,
      paddingLeft: theme.spacing(1.125),
    },
    '& svg': {
      width: 24,
      height: 24,
      fill: theme.palette.base[300],
      marginTop: 0,
    },

    '& > div': {
      display: 'flex',
      flex: 1,
      marginLeft: 0,
    },
  },
  flexWrapper: {
    width: '50%',
    padding: 12,
    display: 'flex',
    alignItems: 'center',
  },
  formGroup: {
    height: 39,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  label: {
    width: theme.spacing(14),
    marginRight: theme.spacing(2),
    flexShrink: 0,
    fontSize: 14,
    color: theme.palette.base[300],
    textAlign: 'right',
  },
  select: {
    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(1.125),
      lineHeight: '25px',
    },
  },
  selectGroupe: {
    color: '#FFF',

    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(1.125),
      lineHeight: '25px',
      color: theme.palette.base[300],

    },
    width: '100%',
  },
  rightLabel: {
    width: theme.spacing(14),
    marginRight: theme.spacing(2),
    flexShrink: 0,
    fontSize: 14,
    color: theme.palette.base[300],
    textAlign: 'right',
  },
  cardZone: {
    marginTop: theme.spacing(1.25),
  },
  helperText: {
    fontSize: 12,
    fontStyle: 'normal',
    color: theme.palette.base[300],
    paddingLeft: theme.spacing(1.25),
  },
  textField: {
    flex: 1,

    '& input': {
      paddingLeft: theme.spacing(1.125),
    },

    '& textarea': {
      paddingLeft: theme.spacing(1.125),
    },
  },
  inactive: {
    '& .MuiSelect-select': {
      color: `${theme.palette.base[300]} !important`,
    },

    '& input': {
      color: `${theme.palette.base[300]} !important`,
    },

    '& textarea': {
      color: `${theme.palette.base[300]} !important`,
    },
  },
  valid: {
    '& .MuiSelect-select': {
      color: `${theme.palette.primary.main}`,
    },

    '& input': {
      color: `${theme.palette.primary.main}`,
    },

    '& textarea': {
      color: `${theme.palette.primary.main}`,
    },
  },
  invalid: {
    '& .MuiSelect-select': {
      color: `${theme.palette.primary.red}`,
    },

    '& input': {
      color: `${theme.palette.primary.red}`,
    },

    '& textarea': {
      color: `${theme.palette.primary.red}`,
    },
  },
  errorMessage: {
    fontSize: 13,
    color: theme.palette.primary.red,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const getStyles = (value, items, theme) => ({
  fontWeight:
    items.indexOf(value) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
});

function CardZoneSettings(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    card,
    error,
    isLT,
    setting,
    cardGroups,
    accesses,
    zones,
    cardZone,
    handleChange,
    handleCalculateCredit,
    selectedParking,
  } = props;


  const isValid = (field) => {
    const validToTS = new Date(setting.validTo).getTime();
    const validFromTS = new Date(setting.validFrom).getTime();
    const currentTime = new Date().getTime();
    switch (field) {
      case 'exitTime':
        return cardZone.exitTimeTS >= cardZone.entryTimeTS && cardZone.exitTimeTS >= currentTime;
      case 'validTo':
        return validToTS >= validFromTS;
      default:
        return false;
    }
  };

  const { valid, blocked } = cardZone;
  const disabled = valid === false || blocked;

  return (
    <>
      <Box className={classes.formGroup}>
        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.label}>{t('ACCESS')}</Typography>
          {isLT ? (
            <Box className={classes.content}>
              <CustomSelect
                autoFocus
                value={setting.access}
                items={accesses}
                disabled={disabled}
                placeholder={t('ACCESS')}
                className={classnames(classes.select, disabled && classes.inactive)}
                handleChange={handleChange('access')}
              />
              {error && error[`access${selectedParking.parkingId}`] && (
                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
              )}
            </Box>
          ) : (
            <TextField
              className={classnames(classes.textField, disabled && classes.inactive)}
              InputProps={{
                  readOnly: true,
              }}
              hiddenLabel
              value={
                setting.access
                  ? accesses.find((t) => t.value === setting.access).label
                  : ''
              }
            />
          )}
        </Box>

        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.rightLabel}>{t('CARD_GROUP')}</Typography>
          {isLT ? (
            <Box className={classes.content}>
              <CustomMultiSelect
                className={classnames(classes.selectGroupe, )}
                items={cardGroups}
                label={t('CARD_GROUP')}
                value={setting.cardGroups}
                handleChange={handleChange('cardGroups')}
                fromGroupe
                color='black'
                disabled= {disabled}
              />
            </Box>
          ) : (
            <TextField
              className={classnames(classes.textField, disabled && classes.inactive)}
              InputProps={{
                  readOnly: true,
              }}
              hiddenLabel
              value={
                setting.cardGroup
                  ? cardGroups.find((cg) => cg.value === setting.cardGroup).label
                  : ''
              }
            />
          )}
        </Box>
      </Box>

      <Box className={classes.formGroup}>
        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.label}>{t('ZONE')}</Typography>
          {isLT ? (
            <Box className={classes.content}>
              <CustomSelect
                disabled={disabled}
                value={setting.zone}
                items={zones}
                placeholder={t('ZONE')}
                className={classnames(classes.select, classes.cardZone, disabled && classes.inactive)}
                helperText={(
                  <span className={classes.helperText}>
                    { cardZone.timeMarkTS
                      ? moment(new Date(cardZone.timeMarkTS)).locale(i18next.language).format('L LTS')
                      : ''}
                  </span>
                )}
                handleChange={handleChange('zone')}
              />
            </Box>
          ) : (
            <TextField
              className={classnames(classes.textField, disabled && classes.inactive)}
              InputProps={{
                  readOnly: true,
              }}
              hiddenLabel
              value={
                setting.zone
                  ? zones.find((z) => z.value === setting.zone).label
                  : ''
              }
            />
          )}
        </Box>
        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.rightLabel}>{t('CAR_PLATE_NUMBER')}</Typography>
          <TextField
            required
            className={classnames(classes.textField, disabled && classes.inactive)}
            hiddenLabel
            InputProps={{
                readOnly: !isLT || disabled,
            }}
            value={setting.carNumber || ''}
            onChange={handleChange('carNumber')}
          />
        </Box>
      </Box>

      <Box className={classes.formGroup}>
        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.label}>{t('VALID_FROM')}</Typography>
          {isLT ? (
            <Box
              className={classnames(
                classes.content,
                disabled && classes.inactive,
                !isValid('validTo') && classes.invalid,
              )}
            >
              <CustomDateTimePicker
                disabled={disabled}
                value={setting.validFrom}
                icon={<ArrowDropDown />}
                handleChange={handleChange('validFrom')}
                ampm={getAMPM(i18next.language)}
                format={getLocaleDateTimeString(i18next.language)}
              />
            </Box>
          ) : (
            <TextField
              className={classnames(classes.textField, disabled && classes.inactive)}
              InputProps={{
                  readOnly:true,
              }}
              hiddenLabel
              value={moment(setting.validFrom).locale(i18next.language).format('L LTS')}
            />
          )}
        </Box>

        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.rightLabel}>{t('VALID_TO')}</Typography>
          {isLT ? (
            <Box
              className={classnames(
                classes.content,
                 disabled && classes.inactive,
                !isValid('validTo') && classes.invalid,
              )}
            >
              <CustomDateTimePicker
                 disabled={disabled}
                value={setting.validTo}
                icon={<ArrowDropDown />}
                format={getLocaleDateTimeString(i18next.language)}
                ampm={getAMPM(i18next.language)}
                handleChange={handleChange('validTo')}
              />
            </Box>
          ) : (
            <TextField
              className={classnames(classes.textField, disabled && classes.inactive)}
              InputProps={{
                  readOnly: true,
              }}
              hiddenLabel
              value={moment(setting.validTo).locale(i18next.language).format('L LTS')}
            />
          )}
        </Box>
      </Box>

      <Box className={classes.formGroup}>
        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.label}>{t('ENTRY_TIME')}</Typography>
          <TextField
            className={classnames(classes.textField, disabled && classes.inactive)}
            InputProps={{
                readOnly: true,
            }}
            hiddenLabel
            value={moment(setting.entryTime).locale(i18next.language).format('L LTS')}
          />
        </Box>

        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.rightLabel}>{t('EXIT_TIME')}</Typography>
          <TextField
            className={classnames(
              classes.textField,
                disabled && classes.inactive,
              isValid('exitTime') ? classes.valid : classes.invalid,
            )}
            InputProps={{
                readOnly: true,
            }}
            hiddenLabel
            value={moment(setting.exitTime).locale(i18next.language).format('L LTS')}
          />
        </Box>
      </Box>

      <Box className={classes.formGroup}>
        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.label}>{t('CREDIT')}</Typography>
          <TextField
            required
            className={classnames(classes.textField, disabled && classes.inactive)}
            hiddenLabel
            InputProps={{
                readOnly: disabled,
            }}
            value={setting.credit || ''}
            onChange={disabled ? null : handleChange('credit')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CustomButton
                    label={t('CALCULATE')}
                    width={100}
                    height={28}
                    disabled={disabled}
                    onClick={handleCalculateCredit}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box className={classes.flexWrapper}>
          <Typography variant="button" className={classes.rightLabel}>{t('ZONE_RESERVATION')}</Typography>
          {isLT ? (
            <Box className={classes.content}>
              <CustomSelect
                value={setting.zoneReservationId}
                items={zones}
                disabled={disabled}
                placeholder={t('ZONE_RESERVATION')}
                className={classnames(classes.select, disabled && classes.inactive)}
                handleChange={handleChange('zoneReservationId')}
                nullable
              />
            </Box>
          ) : (
            <TextField
              className={classnames(classes.textField, disabled && classes.inactive)}
              InputProps={{
                  readOnly: true,
              }}
              hiddenLabel
              value={
                setting.zoneReservationId
                  ? zones.find((z) => z.value === setting.zoneReservationId).label
                  : ''
              }
            />
          )}
        </Box>
      </Box>

      <Box className={classes.formGroup}>
        <Typography variant="button" className={classes.label}>{t('COMMENT')}</Typography>
        <TextField
          required
          multiline
          rowsMax="2"
          className={classnames(classes.textField, disabled && classes.inactive)}
          hiddenLabel
          InputProps={{
              readOnly: !isLT || disabled,
          }}
          value={setting.comment || ''}
          onChange={handleChange('comment')}
        />
      </Box>

      <Box className={classes.formGroup}>
        <Typography variant="button" className={classes.label}>{t('BLOCKING_REASON')}</Typography>
        <TextField
          multiline
          rowsMax="2"
          className={classnames(classes.textField, disabled && classes.inactive)}
          hiddenLabel
          InputProps={{
              readOnly: true,
          }}
          value={setting.blockingReason || ''}
        />
      </Box>
    </>
  );
}

CardZoneSettings.propTypes = {
  card: PropTypes.object.isRequired,
  error: PropTypes.object,
  isLT: PropTypes.bool.isRequired,
  setting: PropTypes.object.isRequired,
  cardGroups: PropTypes.array.isRequired,
  accesses: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  cardZone: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCalculateCredit: PropTypes.func.isRequired,
};

CardZoneSettings.defaultProps = {
  error: null,
};

export default CardZoneSettings;
