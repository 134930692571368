/* eslint-disable max-len, import/prefer-default-export */
import React from 'react';

const svgStyle = {
  width: 40,
  height: 40,
  fill: '#ADB0B8',
};

export const DisappointedIcon = (props) => (
  <svg {...props} style={svgStyle} viewBox="0 0 40 40">
    <path
      d="M21.98,2A20,20,0,1,0,42,22,19.977,19.977,0,0,0,21.98,2ZM22,38A16,16,0,1,1,38,22,16,16,0,0,1,22,38Zm8.36-24.48-2.12,2.12-2.12-2.12L24,15.64l2.12,2.12L24,19.88,26.12,22l2.12-2.12L30.36,22l2.12-2.12-2.12-2.12,2.12-2.12ZM13.64,22l2.12-2.12L17.88,22,20,19.88l-2.12-2.12L20,15.64l-2.12-2.12-2.12,2.12-2.12-2.12-2.12,2.12,2.12,2.12-2.12,2.12ZM22,26a10.992,10.992,0,0,0-10.22,7H32.22A10.992,10.992,0,0,0,22,26Z"
      transform="translate(-2 -2)"
    />
  </svg>
);
