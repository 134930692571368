import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {isComponentEnabledForUser} from "../../../utils";
import Add from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Chip} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 1, 2),
    },
    toolbarInner1: {
        width: '100%',
        border: '1px solid #E0E0E0',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: theme.palette.shadow.main,
        padding: theme.spacing(2, 1, 1, 2),

    },
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(10),
        padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));

function ToolBar(props) {
    const classes = useStyles();

    const {
        selectedName,
        handleSelectedName,
        userUseCases,
        handleParkingDetailBasic,
        protocols,
        selectedProtocol,
        handleSelectProtocol,
        connections,
        selectedConnection,
        handleSelectConnection,
        autoConnects,
        selectedAutoConnect,
        handleSelectAutoConnect,
        handleSelectSubject,
        subjectValues,
        selectedSubject,
    } = props;

    const {t} = useTranslation();


    return (
        <div className={classes.toolbar}>
            <div className={classes.toolbarInner1}>
                <form autoComplete='off'>
                    <Box mb={2} height="100%" width="100%">
                        <Grid container spacing={2} style={{height: '100%', width: '100%'}}>
                            <Grid item sm={2}>
                                <TextField
                                    label={t('NAME')}
                                    fullWidth
                                    value={selectedName || ''}
                                    onChange={handleSelectedName}
                                />

                            </Grid>
                            <Grid item sm={2}>
                                <Autocomplete
                                    multiple
                                    value={selectedProtocol}
                                    onChange={(event, newValue) => {
                                        handleSelectProtocol(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="protocols"
                                    options={protocols}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('PROTOCOL')} fullWidth


                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item sm={2}>
                                <Autocomplete
                                    multiple
                                    value={selectedConnection}
                                    onChange={(event, newValue) => {
                                        handleSelectConnection(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="connections"
                                    options={connections}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('PARKING_STATUS')} fullWidth


                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item sm={2}>
                                <Autocomplete
                                    multiple
                                    value={selectedAutoConnect}
                                    onChange={(event, newValue) => {
                                        handleSelectAutoConnect(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="autoConnect"
                                    options={autoConnects}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('AUTO_CONNECT')} fullWidth


                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item sm={3}>
                                <Autocomplete
                                    multiple
                                    value={selectedSubject}
                                    onChange={(event, newValue) => {
                                        handleSelectSubject(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="subject"
                                    options={subjectValues}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('SUBJECT')} fullWidth


                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item sm={1}>
                                <IconButton
                                    className={classes.addButton}
                                    edge="start"
                                    color="inherit"
                                    aria-label="add parking"
                                    onClick={() => {
                                        handleParkingDetailBasic({color: '#E8E97C', connect: true}, true);
                                    }}
                                    disabled={!isComponentEnabledForUser(UC_PARKING_CREATE, userUseCases)}
                                >
                                    <Add className={classes.addIcon}/>
                                </IconButton>
                            </Grid>

                        </Grid>
                    </Box>
                </form>
            </div>
        </div>

    )
        ;
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

const UC_PARKING_CREATE = 'UC0062';

export default ToolBar;
