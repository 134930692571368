import types from '../actionTypes';

const INITIAL_STATE = {
    isCreating: false,
    isUpdating: false,
    isFetchingHistory: false,
    isFetchingAllList: false,
    errors: '',
    pos: [],
    posHistory: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.POS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                pos: action.payload.pos,
            };
        case types.POS_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.POS_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        case types.POS_HISTORY_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingHistory: false,
                posHistory: action.payload.posHistory,
            };
        case types.POS_HISTORY_FETCH_FAIL:
            return {
                ...state,
                isFetchingHistory: false,
                errors: action.payload.error,
            };
        case types.POS_HISTORY_FETCH_REQUEST:
            return {
                ...state,
                isFetchingHistory: true,
            };
        default:
            return state;
    }
};
